import { reducerWithInitialState } from "typescript-fsa-reducers";
import type { PlainDictionary } from "@sm/types/budget";

import type { ActivityTypePageState, UpdateActivityTypeParams } from "../types";
import {
  loadPageState,
  addActivityType,
  updateActivityType,
  removeActivityType,
} from "../actions";

const initialState: ActivityTypePageState = {
  activityTypes: {
    entities: [],
    byId: {},
  },
  activityTypesData: {},
};

export const activityPageReducer = reducerWithInitialState(initialState)
  .case(loadPageState, load)
  .case(addActivityType, addActivityTypeReducer)
  .case(updateActivityType, updateActivityTypeReducer)
  .case(removeActivityType, removeActivityTypeReducer);

function load(state: ActivityTypePageState, payload: ActivityTypePageState) {
  return {
    ...state,
    ...payload,
  };
}

function addActivityTypeReducer(
  state: ActivityTypePageState,
  activityType: PlainDictionary
) {
  const activityTypes = [...state.activityTypes, activityType];

  return {
    ...state,
    activityTypes,
  };
}

function updateActivityTypeReducer(
  state: ActivityTypePageState,
  params: UpdateActivityTypeParams
) {
  const { id, colorData } = params;

  const activityTypesData = {
    ...state.activityTypesData,
    [params.id]: {
      data: colorData,
      schema: state.activityTypesData[id].schema,
    },
  };

  return {
    ...state,
    activityTypesData,
  };
}

function removeActivityTypeReducer(state: ActivityTypePageState, id: string) {
  const activityTypes = state.activityTypes.filter(
    (activityType) => activityType.id != id
  );

  return {
    ...state,
    activityTypes,
  };
}
