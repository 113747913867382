import { reducerWithInitialState } from "typescript-fsa-reducers";

import { StagesPageState, StagesPageLoadParams } from "../types";
import { loadPageState } from "../actions";

const initialState: StagesPageState = {
  stagesTemplates: [],
};

class Reducer {
  public static load(
    state: StagesPageState,
    payload: StagesPageLoadParams
  ): StagesPageState {
    return {
      ...state,
      ...payload,
    };
  }
}

export const stagesPageReducer = reducerWithInitialState(initialState).case(
  loadPageState,
  Reducer.load
);
