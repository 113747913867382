import * as React from "react";
import { Dispatch, connect } from "react-redux";
import autobind from "autobind-decorator";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { DepartmentAttributes } from "@sm/types/frontend";

import { BriefSchemeCard } from "./BriefSchemeCard";
import { StoreState } from "../../../store";
import { updateScheme } from "../../../store/briefSchemeListPage/actions";
import { getExecutorDepartment } from "../../../store/briefSchemeListPage/selector";
import { UpdateSchemeParams } from "../../../store/briefSchemeListPage/types";
import { BriefApi } from "../../../api";

interface Props
  extends MapProps,
    DispatchProps,
    RouteComponentProps<RouteParams> {
  schemaId: string;
  briefName: string;
  status: string;
  departmentId: string;
  campaignsCount: number;
  onRemoveScheme(id: string): void;
}

interface RouteParams {
  organizationId?: string;
}

interface MapProps {
  department?: DepartmentAttributes;
}

interface DispatchProps {
  updateScheme?: (params: UpdateSchemeParams) => void;
}

interface State {
  isEditOpen: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
class BriefSchemeCardContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditOpen: false,
    };
  }

  public render(): JSX.Element {
    const {
      schemaId,
      briefName,
      status,
      campaignsCount,
      department,
      departmentId,
    } = this.props;

    const menuItems = [
      {
        label: "Привязать к департаменту",
        onClick: this.handleOnSelectDepartmentClick,
      },
      {
        label:
          status == "inactive"
            ? "Перевести в активные"
            : "Перевести в неактивные",
        onClick: this.handleChangeStatus,
      },
      {
        label: "Удалить бриф",
        onClick: this.handleRemove,
        disabled: campaignsCount != 0,
      },
    ];

    const userDepartmentName = departmentId
      ? (department && department.name) || "Не выбрана"
      : "Общая схема";

    return React.createElement(BriefSchemeCard, {
      schemaId,
      status,
      menuItems,
      briefName,
      campaignsCount,
      departmentId,
      userDepartmentName,
      isEditOpen: this.state.isEditOpen,
      toggleIsEditOpen: this.handleOnSelectDepartmentClick,
      onDepartmentSelect: this.handleDepartmentSelect,
    });
  }

  @autobind
  protected handleOnSelectDepartmentClick() {
    this.toggleIsEditOpen();
  }

  @autobind
  protected async handleChangeStatus() {
    const { schemaId } = this.props;
    const status = this.props.status == "inactive" ? "active" : "inactive";

    await BriefApi.updateSchemeStatus(schemaId, status);

    this.props.updateScheme({
      id: schemaId,
      status,
    });
  }

  @autobind
  protected async handleDepartmentSelect(departmentId: string) {
    const { schemaId } = this.props;

    await BriefApi.updateSchemeDepartment(schemaId, departmentId);

    this.props.updateScheme({
      id: schemaId,
      departmentId,
    });

    this.toggleIsEditOpen();
  }

  @autobind
  protected async handleRemove() {
    await BriefApi.deleteScheme(this.props.schemaId);

    this.props.onRemoveScheme(this.props.schemaId);
  }

  private toggleIsEditOpen() {
    this.setState({
      isEditOpen: !this.state.isEditOpen,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    department: getExecutorDepartment(state, ownProps.departmentId),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    updateScheme: (params: UpdateSchemeParams) =>
      dispatch(updateScheme(params)),
  };
}

const WithRouterBriefSchemeCardContainer = withRouter(BriefSchemeCardContainer);
export { WithRouterBriefSchemeCardContainer as BriefSchemeCardContainer };
