import { actionCreatorFactory } from "typescript-fsa";

import type { BudgetLine } from "../../api";

const actionCreator = actionCreatorFactory("CREATIVE_PAGE");

const loadBudgets = actionCreator<BudgetLine[]>("LOAD_BUDGETS");
const resetPageState = actionCreator("RESET_PAGE_STATE");
const setBudgetsFilter = actionCreator<boolean>("SET_BUDGETS_FILTER");

export { loadBudgets, resetPageState, setBudgetsFilter };
