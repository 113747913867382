import * as React from "react";
import { ChromePicker } from "react-color";
import { PlainDictionary } from "@sm/types/budget";

import * as style from "./ActivityTypeForm.scss";
import "./ColorPicker_.scss";

import { Button, ButtonTheme, InputContainer, Heading } from "@sm/ui";

interface Props {
  activityType: PlainDictionary;
  isOnValidation?: boolean;
  optionParams: {
    name: string;
    color: string;
    opened: boolean;
  }[];
  onOptionChange: (name: string, value: string) => void;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  onFocus: (name: string) => void;
  onBlur: (name: string, color: string) => void;
}

interface OptionInput {
  name: string;
  value: string;
  opened: boolean;
  onChange: (name: string, value: string) => void;
  onFocus: (name: string) => void;
  onBlur: (name: string, color: string) => void;
}

export function ActivityTypeForm({
  activityType,
  optionParams,
  onOptionChange,
  onConfirmClick,
  onCancelClick,
  onFocus,
  onBlur,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.titleWrapper}>Редактирование</div>

      <h1 className={style.activityTypeName}>{activityType?.value}</h1>

      {optionParams.map((option) => (
        <div className={style.optionWrapper} key={option.name}>
          <OptionInput
            name={option.name}
            value={option.color}
            opened={option.opened}
            onChange={onOptionChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />

          {!option.opened && (
            <div
              style={{
                backgroundColor: option.color,
                width: "100%",
                height: "20px",
              }}
            />
          )}
        </div>
      ))}

      <div className={style.separateLine} />

      <div className={style.buttonsWrapper}>
        <div className={style.buttonWrapper}>
          <Button theme={ButtonTheme.PrimaryMain} onClick={onConfirmClick}>
            Сохранить
          </Button>
        </div>

        <div className={style.buttonWrapper}>
          <Button theme={ButtonTheme.PrimaryOutline} onClick={onCancelClick}>
            Отменить
          </Button>
        </div>
      </div>
    </div>
  );
}

function OptionInput({
  name,
  value,
  opened,
  onFocus,
  onBlur,
  onChange,
}: OptionInput): JSX.Element {
  return (
    <Heading title={name}>
      <React.Fragment>
        <InputContainer
          value={value}
          placeholder={"цвет"}
          onBlur={(event) => onBlur(name, event.target.value)}
          onFocus={() => onFocus(name)}
        />

        {opened && (
          <ChromePicker
            disableAlpha
            color={value}
            onChangeComplete={(color) => onChange(name, color.hex)}
          />
        )}
      </React.Fragment>
    </Heading>
  );
}
