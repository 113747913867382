import autobind from "autobind-decorator";
import * as React from "react";
import type { RouteComponentProps } from "react-router-router";
import * as lodash from "lodash";

import {
  DepartmentAttributes,
  OrganizationView,
  PermissionResponse,
  PermissionSubjectResponse,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

import {
  BudgetApi,
  DepartmentApi,
  DictionaryApi,
  OrganizationApi,
  PermissionApi,
} from "../../api";

import { RolePage } from "./RolePage";

interface Props extends Partial<RouteComponentProps> {
  pageLabel?: string;
}

interface State {
  preloader: boolean;
  editedItemId: string;
  editModeEnabled: boolean;
  permissions: (PermissionResponse & { id: string })[];
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
}

export class RolePageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: false,
      editModeEnabled: false,
      editedItemId: null,
      permissions: [],
      budgets: {},
      organizations: {},
      departments: {},
      dictionaries: {},
      permissionSubjects: {},
    };
  }

  public componentDidMount() {
    this.update();
  }

  public render() {
    const { pageLabel } = this.props;

    return React.createElement(RolePage, {
      ...this.state,
      pageLabel,
      onCreateButtonClick: this.onCreateButtonClick,
      onRemoveButtonClick: this.onRemoveButtonClick,
      onEditButtonClick: this.onEditButtonClick,
      onCloseEditForm: this.handleCloseEditForm,
      update: this.update,
    });
  }

  @autobind
  protected handleCloseEditForm() {
    this.setState({
      editedItemId: null,
      editModeEnabled: false,
    });
  }

  @autobind
  protected onCreateButtonClick() {
    this.setState({
      editedItemId: null,
      editModeEnabled: true,
    });
  }

  @autobind
  protected onEditButtonClick(id: string) {
    this.setState({
      editedItemId: id,
      editModeEnabled: true,
    });
  }

  @autobind
  protected async onRemoveButtonClick(id: string) {
    await PermissionApi.deleteSubPermission(
      this.state.permissions.find((i) => i.id === id)
    );
    await this.update();
  }

  @autobind
  protected async update() {
    this.setState({
      preloader: true,
      editedItemId: null,
      editModeEnabled: false,
    });

    const permissions = await PermissionApi.getPermissions();
    const dictionaryIds = lodash.uniq(
      [].concat(...permissions.map((i) => i.dictionaryIds))
    );

    const budgets = lodash.keyBy(await BudgetApi.getAll(), "id");
    const organizations = lodash.keyBy(
      await OrganizationApi.getOrganizations(),
      "id"
    );
    const departments = lodash.keyBy(
      await DepartmentApi.getDepartmentList({
        organizationIds: Object.keys(organizations),
      }),
      "id"
    );
    const dictionaries = lodash.keyBy(
      (await DictionaryApi.getDictionariesByIds({ ids: dictionaryIds }))
        .dictionary,
      "id"
    );
    const permissionSubjects = lodash.keyBy(
      await PermissionApi.getPermissionSubjects(),
      "id"
    );

    this.setState({
      preloader: false,
      permissions: permissions.map((i) => ({ ...i, id: lodash.uniqueId() })),
      budgets,
      organizations,
      departments,
      dictionaries,
      permissionSubjects,
    });
  }
}
