import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { UserParams, DepartmentUserAttributes } from "@sm/types/admin";

import { EditUserForm } from "./EditUserForm";
import type { StoreState } from "../../../store";
import {
  loadDepartmentUsers,
  removeAvailableUser,
} from "../../../store/departmentRolePage/actions";
import {
  getAvailableUsers,
  getUserByUserId,
  getSelectedDepartmentId,
} from "../../../store/departmentRolePage/selector";
import { DepartmentApi } from "../../../api";

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
  userId?: number;
  onConfirmClick: () => void;
}

interface MapProps {
  departmentId: string;
  availableUsers: UserParams[];
  departmentUsers: DepartmentUserAttributes[];
  getUserByUserId: (id: number) => UserParams;
}

interface DispatchProps {
  loadDepartmentUsers: (departmentUsers: DepartmentUserAttributes[]) => void;
  removeAvailableUser: (userId: number) => void;
}

interface State {
  userIds: number[];
  departmentUsers: DepartmentUserAttributes[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditUserFormContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userIds: this.props.userId ? [this.props.userId] : [],
      departmentUsers: this.props.departmentUsers || [],
    };
  }

  public render(): JSX.Element {
    const { userId } = this.props;

    return React.createElement(EditUserForm, {
      title: userId
        ? "Редактировать роль пользователя"
        : "Добавление пользователей и назначение роли",
      buttonLabel: userId ? "Сохранить" : "Добавить",
      isOnEdit: Boolean(userId),
      availableUsers: this.getAvailableUsers(),
      departmentUsers: this.state.departmentUsers,
      userIds: this.state.userIds,
      handleCheckboxClick: this.handleCheckboxClick,
      onChange: this.handleChangeUser,
      onSave: this.handleSaveForm,
    });
  }

  @autobind
  protected handleChangeUser(userId: number) {
    this.setState((prevState: State) => ({
      userIds: lodash.xor(prevState.userIds, [userId]),
    }));
  }

  @autobind
  protected handleCheckboxClick(
    userId: number,
    roleId: number,
    title: string,
    isChecked: boolean
  ) {
    const departmentUser = this.createDepartmentUser(userId, roleId, title);
    const user = this.state.departmentUsers.find((user) => user.id == userId);

    const departmentUsers = !!user
      ? this.state.departmentUsers.map((item) =>
          item.id == userId
            ? isChecked
              ? { ...item, roles: [...item.roles, ...departmentUser.roles] }
              : {
                  ...item,
                  roles: item.roles.filter((role) => role.id != roleId),
                }
            : item
        )
      : [...this.state.departmentUsers, departmentUser];

    this.setState({
      departmentUsers,
    });
  }

  @autobind
  protected async handleSaveForm() {
    const updatedUserIds = lodash.uniq(
      lodash
        .xor(this.state.departmentUsers, this.props.departmentUsers)
        .map((item) => item.id)
    );

    await Promise.all(
      updatedUserIds.map(async (userId) => {
        const departmentUser = this.state.departmentUsers.find(
          (item) => item.id == userId
        );
        const roleIds = departmentUser.roles.map((item) => item.id);

        await DepartmentApi.updateUserRole(
          this.props.departmentId,
          roleIds,
          userId
        );
      })
    );

    this.props.loadDepartmentUsers(this.state.departmentUsers);

    for (const id of updatedUserIds) {
      this.props.removeAvailableUser(id);
    }

    this.props.onConfirmClick();
  }

  private getAvailableUsers() {
    return this.props.availableUsers.filter(
      (item) => !lodash.includes(this.state.userIds, item.id)
    );
  }

  private createDepartmentUser(
    userId: number,
    roleId: number,
    roleTitle: string
  ): DepartmentUserAttributes {
    const user = this.props.getUserByUserId(userId);

    return {
      id: userId,
      firstName: user.firstName,
      secondName: user.secondName,
      roles: [
        {
          id: roleId,
          name: roleTitle,
        },
      ],
    };
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    departmentId: getSelectedDepartmentId(state),
    availableUsers: getAvailableUsers(state),
    departmentUsers: state.departmentRolePage.departmentUsers,
    getUserByUserId: (id: number) => getUserByUserId(state, id),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadDepartmentUsers: (departmentUsers: DepartmentUserAttributes[]) =>
      dispatch(loadDepartmentUsers(departmentUsers)),
    removeAvailableUser: (userId: number) =>
      dispatch(removeAvailableUser(userId)),
  };
}
