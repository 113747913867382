import * as React from "react";
import * as lodash from "lodash";
import {
  PlainDictionary,
  ActivityDictionaryType,
  BudgetItemDictionaryType,
  DictionaryStatus,
} from "@sm/types/budget";

import * as styles from "./DictionaryLinksValidationMarker.scss";

interface Props {
  dictionaries: PlainDictionary[];
}

type LinksCache = Record<string, string[]>;

interface DictionariesValidation {
  linksCache: LinksCache;
  linksAreValid: boolean;
}

// Current rules of validation
// 1. Every member of Regionality-Direction-Tool-Item chain must be linked to one another

function linkIsPartOfRegionalityChain(dictionary: PlainDictionary): boolean {
  return (
    dictionary.type === ActivityDictionaryType.Regionality ||
    dictionary.type === BudgetItemDictionaryType.Direction ||
    dictionary.type === BudgetItemDictionaryType.Tool ||
    dictionary.type === BudgetItemDictionaryType.Item
  );
}

function getLinksValidationStatus(
  dictionaries: PlainDictionary[],
  linksCache: LinksCache
): boolean {
  return false;
}

function useDictionaryLinksValidationMarker({ dictionaries }: Props) {
  const [dictionaryValidation, setDictionaryValidation] =
    React.useState<DictionariesValidation>({
      linksCache: {},
      linksAreValid: false,
    });

  React.useEffect(
    function onDictionariesUpdate() {
      const linksCache = dictionaries.reduce(
        (acc, dictionary) => ({
          ...acc,
          [dictionary.id]: lodash.uniq([
            ...(dictionary.parentRefs?.map((ref) => ref.id) || []),
            ...(dictionary.childrenRefs?.map((ref) => ref.id) || []),
          ]),
        }),
        {} as Record<string, string[]>
      );

      console.time();
      const linksAreValid = getLinksValidationStatus(dictionaries, linksCache);
      console.timeEnd();

      setDictionaryValidation({ linksCache, linksAreValid });
    },
    [dictionaries]
  );

  return { dictionaryValidation };
}

export function DictionaryLinksValidationMarker(props: Props): JSX.Element {
  const { dictionaryValidation } = useDictionaryLinksValidationMarker(props);

  return (
    <div className={styles.root}>
      {dictionaryValidation.linksAreValid
        ? "Связи заданы верно"
        : "Связи заданы неверно"}
    </div>
  );
}
