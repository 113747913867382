import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import * as lodash from "lodash";

import { UserParams, DepartmentAttributes } from "@sm/types/admin";

import { StoreState } from "../../";
import { DepartmentRolePageState } from "../types";
import { Utils } from "../../../modules/common/Utils";

import { getDepartmentRolePageState } from "./departmentRolePage";

export const getDepartments = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): DepartmentAttributes[] => {
    return state.departments;
  }
);

export const getDepartment = createCachedSelector(
  getDepartments,
  (state: StoreState, id: string): string => id,
  (departments: DepartmentAttributes[], id: string): DepartmentAttributes => {
    return Utils.getItemById(departments, id);
  }
)((state: StoreState, id: string): string => id);

export const getSelectedDepartmentIds = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): string[] => {
    return state.selectedDepartmentIds;
  }
);

export const getSelectedDepartmentId = createSelector(
  getSelectedDepartmentIds,
  (ids: string[]): string => {
    return lodash.last(ids);
  }
);

export const getSelectedDepartments = createSelector(
  getDepartments,
  getSelectedDepartmentIds,
  (
    departments: DepartmentAttributes[],
    ids: string[]
  ): DepartmentAttributes[] => {
    return ids.reduce((acc, id) => {
      acc.push(Utils.getItemById(departments, id));

      return acc;
    }, []);
  }
);

export const getSelectedDepartmentName = createSelector(
  getDepartments,
  getSelectedDepartmentId,
  (departments: DepartmentAttributes[], id: string): string => {
    const department = Utils.getItemById(departments, id);

    return department && department.name;
  }
);

export const getAvailableUsers = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): UserParams[] => {
    return state.availableUsers;
  }
);
