import * as React from "react";

import { Icon, IconType } from "sber-marketing-ui";
import * as styles from "./styles.scss";

interface Props {
  onClick: () => void;
}

export const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon type={IconType.CROSS} />
    </div>
  );
};
