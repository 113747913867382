import * as React from "react";

import * as style from "./WorkTypeRow.scss";

import {
  Icon,
  IconType,
  InputContainer,
  InputThemes,
  Toggle,
  TogglePosition,
  ToggleThemes,
} from "@sm/ui";
import { ErrorPopup } from "../../common/ErrorPopup";
import { DepartmentsSwitchMenu } from "../DepartmentsSwitchMenu";

interface Props {
  name: string;
  departments: string;
  selectedIds: string[];
  errorMessage: string;
  togglePosition: TogglePosition;
  isDisabledToggle: boolean;
  isErrorPopupOpen: boolean;
  onRemoveClick: () => void;
  onInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClosePopup: () => void;
  onToggleClick: () => void;
  onDepartmentSelect(isChecked: boolean, departmentId: string): any;
}

export function WorkTypeRow({
  name,
  departments,
  errorMessage,
  togglePosition,
  isDisabledToggle,
  isErrorPopupOpen,
  selectedIds,
  onClosePopup,
  onToggleClick,
  onInputBlur,
  onRemoveClick,
  onDepartmentSelect,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      {isErrorPopupOpen && (
        <ErrorPopup message={errorMessage} onCloseClick={onClosePopup} />
      )}

      <div className={style.nameColumnWrapper}>
        <InputContainer
          value={name}
          theme={InputThemes.Simple}
          onBlur={onInputBlur}
        />
      </div>

      <div className={style.departmentColumnWrapper}>
        <div className={style.departmentNames}>{departments}</div>

        <div className={style.departmentSelectWrapper}>
          <DepartmentsSwitchMenu
            selectedIds={selectedIds}
            onDepartmentSelect={onDepartmentSelect}
          />
        </div>
      </div>

      <div className={style.toggleColumnWrapper}>
        <Toggle
          position={togglePosition}
          theme={ToggleThemes.GREY_AND_GREEN}
          disabled={isDisabledToggle}
          onClick={onToggleClick}
        />
      </div>

      <div className={style.removeIconColumn}>
        <div className={style.removeIcon} onClick={onRemoveClick}>
          <Icon type={IconType.CROSS} />
        </div>
      </div>
    </div>
  );
}

export { TogglePosition };
