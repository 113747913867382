import { OperationType, Type } from "./enums";
import { OperationScheme } from "./types";

export const blankSchema: OperationScheme = {
  type: Type.OPERATION,
  operation: OperationType.MULTIPLICATION,
  operands: [
    {
      type: Type.OPERAND,
      selectedItemId: "",
    },
    {
      type: Type.OPERAND,
      selectedItemId: "",
    },
  ],
};
