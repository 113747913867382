import * as React from "react";
import {
  Dropdown as CommonDropdown,
  DropdownList,
  DropdownSearchInput,
  ListItem,
} from "../../common/Dropdown";

export interface Props {
  label: string;
  disabled?: boolean;
  selectedItemId?: string;
  items: ListItem[];
  onItemSelect?: (item: ListItem) => void;
}

export const Dropdown: React.FC<Props> = ({
  label,
  disabled,
  selectedItemId,
  items,
  onItemSelect,
}) => (
  <CommonDropdown
    label={label}
    items={items}
    disabled={disabled}
    selectedItemId={selectedItemId}
    searchInputComponent={DropdownSearchInput}
    listItemsComponent={DropdownList}
    onItemSelect={onItemSelect}
  />
);

export type { ListItem as Item };
