import * as React from "react";
import { NavLink } from "react-router-dom";

import * as style from "./DepartmentCard.scss";

import { Shadow } from "@sm/ui";
import { Utils } from "../../common/Utils";

export interface DepartmentCardProps {
  departmentId: string;
  departmentName: string;
  createTime: Date;
  usersCount: number;
}

export function DepartmentCard({
  departmentId,
  departmentName,
  createTime,
  usersCount = 0,
}: DepartmentCardProps): JSX.Element {
  const userInRightDeclension = Utils.getDeclensionByNumber(usersCount, [
    "пользователь",
    "пользователя",
    "пользователей",
  ]);

  return (
    <div className={style.root}>
      <Shadow>
        <NavLink className={style.link} to={`/department/${departmentId}`}>
          <div className={style.departmentName}>{departmentName}</div>

          <div className={style.bottomLine}>
            <div className={style.property}>
              {Utils.formatDateToDMY(createTime)}
            </div>

            <div className={style.property}>
              {usersCount || 0} {userInRightDeclension}
            </div>
          </div>
        </NavLink>
      </Shadow>
    </div>
  );
}
