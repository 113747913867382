// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__root{width:100%;height:100%;padding-left:8px;display:flex;align-items:center}.src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__button{cursor:pointer}.src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__menu{background-color:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.2)}.src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__item{padding:0 16px;height:46px;font-family:'Open Sans';font-size:14px;line-height:46px;color:#4a4a4a;cursor:pointer}.src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__item:not(:last-of-type){border-bottom:1px solid #dcdcdc}.src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__item:hover{background:rgba(59,130,0,0.11)}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/TableCells/ActionsCell/ActionsCell.scss"],"names":[],"mappings":"AAAA,wEACI,UAAW,CACX,WAAY,CACZ,gBAAiB,CAGjB,YAAa,CACb,kBAAmB,CACtB,0EAGG,cAAe,CAClB,wEAGG,wBAAyB,CACzB,uCAA2C,CAC9C,wEAGG,cAAe,CAEf,WAAY,CAEZ,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,aAAc,CAEd,cAAe,CAVnB,2FAaQ,+BAAgC,CAbxC,8EAiBQ,8BAAkC","sourcesContent":[".root {\n    width: 100%;\n    height: 100%;\n    padding-left: 8px;\n\n\n    display: flex;\n    align-items: center;\n}\n\n.button {\n    cursor: pointer;\n}\n\n.menu {\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);\n}\n\n.item {\n    padding: 0 16px;\n\n    height: 46px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 46px;\n    color: #4a4a4a;\n\n    cursor: pointer;\n\n    &:not(:last-of-type) {\n        border-bottom: 1px solid #dcdcdc;\n    }\n\n    &:hover {\n        background: rgba(59, 130, 0, 0.11);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__root",
	"button": "src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__button",
	"menu": "src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__menu",
	"item": "src-modules-DictionariesPage-TableCells-ActionsCell-_ActionsCell__item"
};
export default ___CSS_LOADER_EXPORT___;
