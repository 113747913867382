import { axios } from "../lib/axios";

import {
  PermissionResponse,
  PermissionSubjectResponse,
} from "@sm/types/backend";

const URL = "/api/permission";

export class PermissionApi {
  public static async getPermissions(): Promise<PermissionResponse[]> {
    const res = await axios.get(`${URL}`);

    return res.data;
  }

  public static async createPermission(
    params: PermissionResponse
  ): Promise<void> {
    await axios.post(`${URL}`, { ...params });
  }

  public static async updatePermission(params: {
    old: PermissionResponse;
    new: PermissionResponse;
  }): Promise<void> {
    await axios.put(`${URL}`, { ...params });
  }

  public static async deleteSubPermission(
    params: PermissionResponse
  ): Promise<void> {
    await axios.post(`${URL}/delete`, { ...params });
  }

  public static async getPermissionSubjects(): Promise<
    PermissionSubjectResponse[]
  > {
    const res = await axios.get(`${URL}/subjects`);

    return res.data;
  }
}
