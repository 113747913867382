import * as React from "react";
import { Button, ButtonTheme, Popup } from "@sm/ui";

import * as style from "./ErrorPopup.scss";

export interface ErrorPopupParams {}

interface ErrorPopupProps extends ErrorPopupParams {
  message: string;
  onCloseClick: () => void;
}

export function ErrorPopup({
  message,
  onCloseClick,
}: ErrorPopupProps): JSX.Element {
  return (
    <div className={style.root}>
      <Popup onOutOfContentClick={onCloseClick}>
        <div className={style.messagePopup}>
          <div className={style.textWrapper}>{message}</div>
          <div className={style.buttonWrapper}>
            <Button theme={ButtonTheme.PrimaryMain} onClick={onCloseClick}>
              Закрыть
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
