import * as React from "react";

import * as styles from "./RolePage.scss";

import {
  PermissionResponse,
  PermissionSubjectResponse,
  OrganizationView,
  DepartmentAttributes,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

import {
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
  Popup,
  Button_redesign as Button,
} from "@sm/ui";
import { Page } from "../common/Page";
import { EditFormContainer } from "./EditFromContainer";
import { RoleContainer } from "./RoleContaner";

interface Props {
  preloader?: boolean;
  pageLabel?: string;
  editModeEnabled: boolean;
  editedItemId: string;
  onCreateButtonClick: () => void;
  onRemoveButtonClick: (id: string) => void;
  onEditButtonClick: (id: string) => void;
  onCloseEditForm: () => void;
  update: () => Promise<void>;
  permissions: (PermissionResponse & { id: string })[];
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
}

interface HeaderProps {
  onCreateButtonClick: () => void;
}

export function RolePage({
  preloader,
  pageLabel,
  editedItemId,
  editModeEnabled,
  onCreateButtonClick,
  onRemoveButtonClick,
  onEditButtonClick,
  onCloseEditForm,
  update,
  permissions,
  budgets,
  organizations,
  departments,
  dictionaries,
  permissionSubjects,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      withoutBackButton
      customFirstLine={() => (
        <Header onCreateButtonClick={onCreateButtonClick} />
      )}
      fixedLeftContent
      customLayout
    >
      <div className={styles.root}>
        <ResponsiveLayout>
          {/* <LeftSidebar fixed collapsable>
                        <div className={styles.filters}>
                            Left sidebar
                        </div>
                    </LeftSidebar> */}

          <CentralContent>
            <div className={styles.content}>
              <RoleContainer
                permissions={permissions}
                budgets={budgets}
                organizations={organizations}
                departments={departments}
                dictionaries={dictionaries}
                permissionSubjects={permissionSubjects}
                onEditButtonClick={onEditButtonClick}
                onRemoveButtonClick={onRemoveButtonClick}
              />
            </div>
          </CentralContent>
        </ResponsiveLayout>

        {editModeEnabled && (
          <Popup>
            <React.Fragment>
              <div className={styles.formWrapper}>
                <EditFormContainer
                  permission={permissions.find(
                    (item) => item.id == editedItemId
                  )}
                  permissions={permissions}
                  budgets={budgets}
                  organizations={organizations}
                  departments={departments}
                  dictionaries={dictionaries}
                  permissionSubjects={permissionSubjects}
                  update={update}
                />
              </div>

              <div className={styles.mask} onClick={onCloseEditForm} />
            </React.Fragment>
          </Popup>
        )}
      </div>
    </Page>
  );
}

const Header = ({ onCreateButtonClick }: HeaderProps) => (
  <div className={styles.headerRoot}>
    <ResponsiveLayout>
      <LeftSidebar />

      <CentralContent>
        <div className={styles.headerContent}>Роли</div>
      </CentralContent>

      <RightSidebar>
        <div className={styles.buttonWrapper}>
          <Button onClick={onCreateButtonClick}>Создать</Button>
        </div>
      </RightSidebar>
    </ResponsiveLayout>
  </div>
);
