import * as React from "react";
import autobind from "autobind-decorator";
import classnames from "classnames";
import { findDOMNode } from "react-dom";
import {
  DragSource,
  DropTarget,
  ContextComponent,
  DragSourceMonitor,
  ConnectDragSource,
  ConnectDropTarget,
  DropTargetMonitor,
} from "react-dnd";
import { Icon, IconType, DynamicIcon } from "sber-marketing-ui";
import { WeightedPlainDictionary, DictionaryStatus } from "@sm/types/budget";

import * as styles from "./DragNDropLinkItem.scss";

interface Props extends WeightedPlainDictionary, DNDProp {
  index: number;
  order: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  addStartStageControls: boolean;
  projectStartStageId: string;
  setProjectStartStageId(projectStartStageId: string): void;
  onRemoveButtonClick(): void;
  onEditButtonClick(): void;
}

interface State {
  isHovered: boolean;
}

interface DNDProp {
  connectDragSource?: ConnectDragSource;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
}

const cardSource = {
  beginDrag(props: Props) {
    return {
      id: props.value,
      index: props.index,
      order: props.order,
    };
  },

  endDrag(
    props: Props,
    monitor: DragSourceMonitor,
    component: DragNDropLinkItem
  ) {
    const item: any = monitor.getItem();

    component.changeBlockOrder(item.id, item.order, item.index + 1);
  },
};

/*tslint:disable*/
const cardTarget = {
  hover(
    props: Props,
    monitor: DropTargetMonitor,
    component: ContextComponent<any, any>
  ): void {
    const item: any = monitor.getItem();
    const dragIndex = item.index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = (
      findDOMNode(component) as Element
    ).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveItem(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex;
  },
};

const DRAG_N_DROP_TARGET = "dictionary";

@(DropTarget(DRAG_N_DROP_TARGET, cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
})) as any)
@(DragSource(DRAG_N_DROP_TARGET, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})) as any)
export class DragNDropLinkItem extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  public changeBlockOrder(value: string, oldOrder: number, newOrder: number) {
    // this.props.changeBlockOrder({
    //     value,
    //     oldOrder,
    //     newOrder
    // });
    // console.log('changing');
  }

  public render(): JSX.Element {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      id,
      value,
      status,
      addStartStageControls,
      projectStartStageId,
      setProjectStartStageId,
    } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div
          className={classnames(styles.link, isDragging && styles.linkDragging)}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <Icon type={IconType.DRAG_N_DROP_ICON} svgSize={10} />

          {addStartStageControls && (
            <Icon
              type={
                projectStartStageId == id
                  ? IconType.CHECKBOX24_CHECKED
                  : IconType.CHECKBOX24_UNCHECKED
              }
              onClick={() => setProjectStartStageId(id)}
              svgSize={24}
            />
          )}

          <div className={styles.linkText}>
            {status === DictionaryStatus.DELETED ? "(Удален) " : ""}
            {value}
          </div>

          {this.state.isHovered && (
            <React.Fragment>
              <Icon
                type={IconType.PROJECT_STAGES_EDIT_ICON}
                svgSize={24}
                className={styles.icon}
                onClick={this.props.onEditButtonClick}
              />

              <DynamicIcon
                common={{
                  svgSize: 16,
                  className: styles.icon,
                  onClick: this.props.onRemoveButtonClick,
                }}
                normal={{ type: IconType.REJECT_ICON_ROUND }}
                hover={{ type: IconType.REJECT_ICON_ROUND_ACTIVE }}
              />
            </React.Fragment>
          )}
        </div>
      )
    );
  }

  @autobind
  private onMouseEnter() {
    this.setState({ isHovered: true });
  }

  @autobind
  private onMouseLeave() {
    this.setState({ isHovered: false });
  }
}
