import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import * as lodash from "lodash";

import { StoreState } from "../../";
import { DepartmentRolePageState, DepartmentUserParams } from "../types";
import {
  DepartmentUserAttributes,
  RoleUser,
  UserParams,
} from "@sm/types/admin";

import { getDepartmentRolePageState } from "./departmentRolePage";

export const getDepartmentUsers = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): DepartmentUserAttributes[] => {
    return state.departmentUsers;
  }
);

export const getRoleListByUserId = createSelector(
  getDepartmentUsers,
  (state: StoreState, id: number): number => id,
  (departmentUsers: DepartmentUserAttributes[], id: number): RoleUser[] => {
    return departmentUsers.find((item) => item.id == id).roles;
  }
);

export const getUserByUserId = createSelector(
  getDepartmentRolePageState,
  (state: StoreState, id: number): number => id,
  (state: DepartmentRolePageState, id: number): UserParams => {
    return state.users.find((item) => item.id == id);
  }
);

export const getDepartmentUser = createCachedSelector(
  getDepartmentUsers,
  (state: StoreState, id: number): number => id,
  (
    departmentUsers: DepartmentUserAttributes[],
    id: number
  ): DepartmentUserParams => {
    const user = lodash.find(departmentUsers, (item) => item.id == id);
    const roleList = departmentUsers
      .filter((item) => item.id == id)
      .map((item) => item.roles)[0];

    return {
      id: user.id,
      firstName: user.firstName,
      secondName: user.secondName,
      roleList,
    };
  }
)((state: StoreState, id: number): number => id);
