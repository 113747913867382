import * as React from "react";
import { Redirect } from "react-router-dom";

import * as style from "./DepartmentListPage.scss";

import type { DepartmentAttributes } from "@sm/types/admin";

import { CardGrid } from "@sm/ui";
import { Page } from "../common/Page";
import { DepartmentCard } from "./DepartmentCard";

const CARD_WIDTH = 240;
const CARD_HEIGHT = 116;

interface Props {
  pageLabel: string;
  previousUrl: string;
  previousLabel: string;
  preloader: boolean;
  departments?: DepartmentAttributes[];
}

export const DepartmentListPage = ({
  preloader,
  previousUrl,
  previousLabel,
  pageLabel,
  departments = [],
}: Props): JSX.Element => {
  return (
    <Page
      preloader={preloader}
      previousUrl={previousUrl}
      previousLabel={previousLabel}
      pageLabel={pageLabel}
      withoutBackButton
    >
      {!preloader && departments.length === 1 ? (
        <Redirect to={`/department/${departments[0].id}`} exact />
      ) : (
        <div className={style.root}>
          <CardGrid
            cards={departments.map((item, index) => (
              <DepartmentCard
                key={index}
                departmentId={item.id}
                departmentName={item.name}
                createTime={item.createTime}
                usersCount={item.userCount}
              />
            ))}
            cardStyle={{
              width: CARD_WIDTH,
              height: CARD_HEIGHT,
              marginHorizontal: 40,
              marginVertical: 40,
            }}
          />
        </div>
      )}
    </Page>
  );
};
