import { ComponentType } from "react";
import { connect } from "react-redux";
import { isNil, uniqBy, includes, isEmpty } from "lodash";

import { DepartmentAttributes } from "@sm/types/admin";
import { SwitchTreeMenuItemParams } from "@sm/ui";

import { StoreState } from "../../../store";
import { getDepartments } from "../../../store/workType/selector";
import {
  DepartmentsSwitchMenuMappedState,
  DepartmentsSwitchMenuTemplateProps,
  DepartmentsSwitchMenuStoreProps,
} from "./types";
import { DepartmentsSwitchMenuTemplate } from "./DepartmentsSwitchMenuTemplate";

const getTopDepartments = (
  departments: DepartmentAttributes[]
): DepartmentAttributes[] =>
  departments.filter(({ parentDepartmentId }) => isNil(parentDepartmentId));

const getNoParentsDepartments = (
  departments: DepartmentAttributes[]
): DepartmentAttributes[] =>
  departments.filter(({ parentDepartmentId }) =>
    isNil(departments.find(({ id }) => id === parentDepartmentId))
  );

const getRootDepartments = (
  departments: DepartmentAttributes[]
): DepartmentAttributes[] =>
  uniqBy(
    [
      ...getTopDepartments(departments),
      ...getNoParentsDepartments(departments),
    ],
    "id"
  );

const mapStateToProps = (
  state: StoreState
): DepartmentsSwitchMenuMappedState => ({
  departments: getDepartments(state),
});

const mapDepartmentsToItems = (
  departments: DepartmentAttributes[],
  potentialChildren: DepartmentAttributes[],
  selectedIds: string[]
): SwitchTreeMenuItemParams[] =>
  departments.map(({ id, name }): SwitchTreeMenuItemParams => {
    const children = potentialChildren.filter(
      ({ parentDepartmentId }) => parentDepartmentId === id
    );
    const childrenIds = children.map((item) => item.id);
    const others = potentialChildren.filter(
      ({ id }) => !includes(childrenIds, id)
    );
    return {
      value: id,
      label: name,
      isSelected: includes(selectedIds, id),
      subItems: isEmpty(children)
        ? []
        : mapDepartmentsToItems(children, others, selectedIds),
    };
  });

const mergeProps = (
  { departments }: DepartmentsSwitchMenuMappedState,
  _: null,
  {
    selectedIds,
    onDepartmentSelect,
    className,
  }: DepartmentsSwitchMenuStoreProps
): DepartmentsSwitchMenuTemplateProps => {
  const roots = getRootDepartments(departments);
  const rootsIds = roots.map(({ id }) => id);
  const potentialChildren = departments.filter(
    ({ id }) => !includes(rootsIds, id)
  );
  return {
    className,
    onDepartmentSelect,
    items: mapDepartmentsToItems(roots, potentialChildren, selectedIds),
  };
};

const withStore = connect(mapStateToProps, null, mergeProps);

/** Departments switch menu */
export const DepartmentsSwitchMenuStore: ComponentType<DepartmentsSwitchMenuStoreProps> =
  withStore(DepartmentsSwitchMenuTemplate);
