import * as React from "react";
import { bindActionCreators } from "redux";
import { Dispatch, connect } from "react-redux";
import autobind from "autobind-decorator";

import {
  EditFieldPropertiesParams,
  CalculationLogicPopupParams,
} from "../../../../../store/brief/types";

import { TextMenu } from "./TextMenu";
import { StoreState } from "../../../../../store";
import {
  editField,
  setCalculationLogicPopupParams,
} from "../../../../../store/brief/actions";
import { getFieldById } from "../../../../../store/brief/selector";

export interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isCalculated?: boolean;
  isBlockCalculation?: boolean;
  isNumeric?: boolean;
  isLatin?: boolean;
  isUrl?: boolean;
  isRange?: boolean;
  isCalendar?: boolean;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
  isFormulaEditable?: boolean;
  hasFormulas?: boolean;
  duplicateToReport?: boolean;
  isInput?: boolean;
  isCalendarDefault?: boolean;
  isMultiple?: boolean;
  isClientHide?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
  setCalculationLogicPopupParams: (params: CalculationLogicPopupParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TextMenuContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      name,
      validationMessage,
      tooltipMessage,
      isRequired,
      isCalculated,
      isBlockCalculation,
      isNumeric,
      isLatin,
      isUrl,
      isRange,
      isCalendar,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      hasFormulas,
      duplicateToReport,
      isInput,
      isCalendarDefault,
      isMultiple,
      isClientHide,
    } = this.props;

    return React.createElement(TextMenu, {
      name,
      validationMessage,
      tooltipMessage,
      isRequired,
      isCalculated,
      isBlockCalculation,
      isNumeric,
      isLatin,
      isUrl,
      isRange,
      isCalendar,
      isInput,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      isCalendarDefault,
      isMultiple,
      hasFormulas,
      duplicateToReport,
      isClientHide,
      onNameChange: this.onNameChange,
      onValidationMessageChange: this.onValidationMessageChange,
      onTooltipMessageChange: this.onTooltipMessageChange,
      onRequirementChange: this.onRequirementChange,
      onNumericChange: this.onNumericChange,
      onLatinChange: this.onLatinChange,
      onUrlChange: this.onUrlChange,
      onRangeChange: this.onRangeChange,
      onCalendarChange: this.onCalendarChange,
      onDuplicateToReportChange: this.onDuplicateToReportChange,
      onTypeChange: this.onTypeChange,
      onCalculatedChange: this.onCalculatedChange,
      onBlockCalculatedChange: this.onBlockCalculatedChange,
      onSumButtonClick: this.onSumButtonClick,
      onCalendarDefaultChange: this.onCalendarDefaultChange,
      onMultipleChange: this.onMultipleChange,
      onClientHideCheckboxChange: this.onClientHideCheckboxChange,
    });
  }

  @autobind
  protected onNameChange(value: string) {
    this.props.editField({
      id: this.props.id,
      name: (value || "").trim(),
    });
  }

  @autobind
  protected onValidationMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      validationMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onTooltipMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      tooltipMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onRequirementChange() {
    this.props.editField({
      id: this.props.id,
      isRequired: !this.props.isRequired,
    });
  }

  @autobind
  protected onClientHideCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isClientHide: !this.props.isClientHide,
    });
  }

  @autobind
  protected onNumericChange() {
    this.props.editField({
      id: this.props.id,
      isNumeric: !this.props.isNumeric,
      isCalendar: false,
      isInput: true,
    });
  }

  @autobind
  protected onLatinChange() {
    this.props.editField({
      id: this.props.id,
      isLatin: !this.props.isLatin,
      isNumeric: false,
      isUrl: false,
      isCalendar: false,
    });
  }

  @autobind
  protected onUrlChange() {
    this.props.editField({
      id: this.props.id,
      isUrl: !this.props.isUrl,
      isNumeric: false,
      isLatin: false,
      isCalendar: false,
      isInput: true,
    });
  }

  @autobind
  protected onRangeChange() {
    this.props.editField({
      id: this.props.id,
      isRange: !this.props.isRange,
      isInput: !this.props.isRange || this.props.isInput,
      isCalendar: false,
      isMultiple: false,
    } as EditFieldPropertiesParams);
  }

  @autobind
  protected onCalendarChange() {
    this.props.editField({
      id: this.props.id,
      isNumeric: false,
      isCalendar: !this.props.isCalendar,
      isCalendarDefault: false,
      isInput: !this.props.isCalendar || this.props.isInput,
      isRange: false,
    } as EditFieldPropertiesParams);
  }

  @autobind
  protected onDuplicateToReportChange() {
    this.props.editField({
      id: this.props.id,
      duplicateToReport: !this.props.duplicateToReport,
    });
  }

  @autobind
  protected onTypeChange(isChecked: boolean) {
    this.props.editField({
      id: this.props.id,
      isInput: isChecked,
    } as EditFieldPropertiesParams);
  }

  @autobind
  protected onCalculatedChange() {
    this.props.editField({
      id: this.props.id,
      isCalculated: !this.props.isCalculated,
      isMultiple: false,
    });
  }

  @autobind
  protected onBlockCalculatedChange() {
    this.props.editField({
      id: this.props.id,
      isBlockCalculation: !this.props.isBlockCalculation,
      isMultiple: false,
    });
  }

  @autobind
  protected onSumButtonClick() {
    const { id } = this.props;

    this.props.setCalculationLogicPopupParams({
      visibility: true,
      selectedFieldId: id,
    });
  }

  @autobind
  protected onCalendarDefaultChange() {
    this.props.editField({
      id: this.props.id,
      isCalendarDefault: !this.props.isCalendarDefault,
    } as EditFieldPropertiesParams);
  }

  @autobind
  protected onMultipleChange() {
    this.props.editField({
      id: this.props.id,
      isMultiple: !this.props.isMultiple,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.id);
  const isFormulaEditable = state.brief.isFormulaEditable;
  const isSchemaActive = state.brief.isSchemeActive;
  const isSchemaEditable = state.brief.isSchemeEditable;

  const {
    name,
    validationMessage,
    tooltipMessage,
    isRequired,
    isCalculated,
    isBlockCalculation,
    isNumeric,
    isLatin,
    isUrl,
    isRange,
    isCalendar,
    formulas,
    duplicateToReport,
    isInput,
    isCalendarDefault,
    isMultiple,
    isClientHide,
    parentFieldId,
  } = field.properties;

  return {
    name,
    validationMessage,
    tooltipMessage,
    isRequired,
    isCalculated,
    isBlockCalculation,
    isNumeric,
    isLatin,
    isUrl,
    isRange,
    isCalendar,
    isSchemaActive: isSchemaActive,
    isSchemaEditable: isSchemaEditable || (isSchemaActive && !parentFieldId),
    isFormulaEditable,
    hasFormulas: Boolean(formulas?.length),
    duplicateToReport,
    isInput,
    isCalendarDefault,
    isMultiple,
    isClientHide,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return bindActionCreators(
    {
      editField,
      setCalculationLogicPopupParams,
    },
    dispatch
  );
}
