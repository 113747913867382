import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import type { DepartmentAttributes } from "@sm/types/admin";
import {
  UpdateSelectedDepartmentParams,
  UpdateStateWithNewDepartmentParams,
  UpdateDepartmentNameParams,
} from "../../../../store/departmentRolePage/types";

import { DepartmentDetail } from "./DepartmentDetail";
import type { StoreState } from "../../../../store";
import {
  setSelectedDepartment,
  updateStateWithNewDepartment,
  addSubDepartment,
  updateDepartmentName,
} from "../../../../store/departmentRolePage/actions";
import {
  getDepartment,
  getSelectedDepartmentId,
} from "../../../../store/departmentRolePage/selector";
import { DepartmentApi, UserApi } from "../../../../api";

interface Props extends MapProps, DispatchProps {
  departmentId: string;
}

interface MapProps {
  department?: DepartmentAttributes;
  selectedId?: string;
}

interface DispatchProps {
  setSelectedDepartment?: (params: UpdateSelectedDepartmentParams) => void;
  updateStateWithNewDepartment?: (
    params: UpdateStateWithNewDepartmentParams
  ) => void;
  addSubDepartment?: (params: DepartmentAttributes) => void;
  updateDepartmentName?: (params: UpdateDepartmentNameParams) => void;
}

interface State {
  isNewSubDepartmentOpen: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DepartmentDetailContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isNewSubDepartmentOpen: false,
    };
  }

  public render(): JSX.Element {
    return React.createElement(DepartmentDetail, {
      departmentId: this.props.departmentId,
      department: this.props.department,
      selectedId: this.props.selectedId,
      isNewSubDepartmentOpen: this.state.isNewSubDepartmentOpen,
      onNameChange: this.handleDepartmentNameChange,
      onAddSubDepartmentClick: this.handleAddSubDepartmentClick,
      onDepartmentSelect: this.handleDepartmentSelected,
      onNewDepartmentInputBlur: this.handleNewSubDepartmentInputBlur,
    });
  }

  @autobind
  protected async handleDepartmentSelected(
    id: string,
    parentDepartmentId: string
  ) {
    const [departments, departmentUsers] = await Promise.all([
      DepartmentApi.getDepartmentList({
        ids: [id],
        embed: "subDepartments",
      }),
      UserApi.getUsers({
        departmentIds: `${id}`,
      }),
    ]);

    this.props.updateStateWithNewDepartment({
      department: departments[0],
      departmentUsers,
    });

    this.props.setSelectedDepartment({ id, parentDepartmentId });
  }

  @autobind
  protected async handleDepartmentNameChange(name: string) {
    await DepartmentApi.updateDepartment(this.props.departmentId, name);

    this.props.updateDepartmentName({
      departmentId: this.props.departmentId,
      parentDepartmentId: this.props.department.parentDepartmentId,
      name,
    });
  }

  @autobind
  protected async handleNewSubDepartmentInputBlur(
    event: React.FocusEvent<HTMLInputElement>
  ) {
    const name = event.currentTarget.value as string;

    if (name) {
      const department = await DepartmentApi.createSubDepartment(
        this.props.departmentId,
        name,
        this.props.department.organizationId
      );

      this.props.addSubDepartment({ ...department, subDepartments: [] });
    }

    this.toggleIsNewSubdepartmentOpen();
  }

  @autobind
  protected async handleAddSubDepartmentClick() {
    this.toggleIsNewSubdepartmentOpen();
  }

  private toggleIsNewSubdepartmentOpen() {
    this.setState({
      isNewSubDepartmentOpen: !this.state.isNewSubDepartmentOpen,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    department: getDepartment(state, ownProps.departmentId),
    selectedId: getSelectedDepartmentId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    setSelectedDepartment: (params: UpdateSelectedDepartmentParams) =>
      dispatch(setSelectedDepartment(params)),
    updateStateWithNewDepartment: (
      params: UpdateStateWithNewDepartmentParams
    ) => dispatch(updateStateWithNewDepartment(params)),
    addSubDepartment: (params: DepartmentAttributes) =>
      dispatch(addSubDepartment(params)),
    updateDepartmentName: (params: UpdateDepartmentNameParams) =>
      dispatch(updateDepartmentName(params)),
  };
}
