import { axios } from "../lib/axios";

export class ImageApi {
  public static async addImage(data: FormData): Promise<string> {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = await axios.put(`/api/image`, data, config);

    return res.data;
  }

  public static async removeImage(imgUrl: string): Promise<void> {
    await axios.delete(`/api/image?imagePath=${imgUrl}`);
  }
}
