import * as React from "react";

import * as style from "./DepartmentNavigation.scss";

import type { DepartmentAttributes } from "@sm/types/admin";

import { DepartmentDetail } from "./DepartmentDetail";

interface Props {
  departments: DepartmentAttributes[];
}

export function DepartmentNavigation({ departments }: Props): JSX.Element {
  return (
    <div className={style.root}>
      {departments.map((item) => (
        <div className={style.department} key={item.id}>
          <DepartmentDetail departmentId={item.id} />
        </div>
      ))}
    </div>
  );
}
