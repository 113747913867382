import * as React from "react";

import type { OperationScheme, OperandScheme } from "../types";
import { OperationType } from "../enums";
import { isOperationScheme, isOperandScheme } from "./utils";

import { Dropdown, DropdownList } from "../../common/Dropdown";
import type { ListItem as Operand } from "../../common/Dropdown";
import { SearchInput } from "./SearchInput";
import { OperationButtons } from "./OperationButtons";

export type Props = {
  schema: OperationScheme;
  operands: Operand[];
  onAddOperands: (item: Operand) => void;
  onSchemaChange: (schema: OperationScheme) => void;
};

const FIRST_OPERAND_INDEX = 0;
const SECOND_OPERAND_INDEX = 1;

export const Operation: React.FC<Props> = ({
  schema,
  operands,
  onAddOperands,
  onSchemaChange,
}) => {
  const firstOperandScheme = schema.operands[FIRST_OPERAND_INDEX];
  const secondOperandScheme = schema.operands[SECOND_OPERAND_INDEX];

  const selectedFirstOperandSchema = Boolean(
    (firstOperandScheme as OperandScheme)?.selectedItemId?.length
  );
  const isFirstOperandTheOperation = isOperationScheme(firstOperandScheme);

  const dropdownItems: Operand[] = operands;

  const onFirstOperandSchemaChange = React.useCallback(
    (operandSchema: OperationScheme) => {
      const operands = [...schema.operands];
      operands[FIRST_OPERAND_INDEX] = operandSchema;

      const newSchema = {
        ...schema,
        operands,
      };

      onSchemaChange(newSchema);
    },
    [schema, onSchemaChange]
  );

  const onSecondOperandSchemaChange = React.useCallback(
    (operandSchema: OperationScheme) => {
      const operands = [...schema.operands];
      operands[SECOND_OPERAND_INDEX] = operandSchema;

      const newSchema = {
        ...schema,
        operands,
      };

      onSchemaChange(newSchema);
    },
    [schema, onSchemaChange]
  );

  const onFirstOperandDropdownSelect = React.useCallback(
    (item: Operand) => {
      const operands = [...schema.operands];

      (operands[FIRST_OPERAND_INDEX] as OperandScheme).selectedItemId = item.id;
      if (item.meta.addParentSelection && item.parentItemId) {
        (operands[FIRST_OPERAND_INDEX] as OperandScheme).parentSelectedItemId =
          item.parentItemId;
      } else {
        delete (operands[FIRST_OPERAND_INDEX] as OperandScheme)
          .parentSelectedItemId;
      }

      if (item?.meta.type === "new_const") {
        onAddOperands(item);
      }

      onSchemaChange({ ...schema, operands });
    },
    [schema, onSchemaChange]
  );

  const onSecondOperandDropdownSelect = React.useCallback(
    (item: Operand) => {
      const operands = [...schema.operands];

      (operands[SECOND_OPERAND_INDEX] as OperandScheme).selectedItemId =
        item.id;
      if (item.meta.addParentSelection && item.parentItemId) {
        (operands[SECOND_OPERAND_INDEX] as OperandScheme).parentSelectedItemId =
          item.parentItemId;
      } else {
        delete (operands[SECOND_OPERAND_INDEX] as OperandScheme)
          .parentSelectedItemId;
      }

      if (item?.meta.type === "new_const") {
        onAddOperands(item);
      }

      onSchemaChange({ ...schema, operands });
    },
    [schema, onSchemaChange]
  );

  const onOperationSelect = React.useCallback(
    (operation: OperationType) => {
      onSchemaChange({
        ...schema,
        operation,
      });
    },
    [schema, onSchemaChange]
  );

  return (
    <>
      {isOperationScheme(firstOperandScheme) && (
        <Operation
          schema={firstOperandScheme}
          operands={operands}
          onAddOperands={onAddOperands}
          onSchemaChange={onFirstOperandSchemaChange}
        />
      )}
      {isOperandScheme(firstOperandScheme) && (
        <Dropdown
          label={"some"}
          key={`first-${firstOperandScheme.selectedItemId}`}
          selectedItemId={firstOperandScheme.selectedItemId}
          selectedItemParentId={firstOperandScheme.parentSelectedItemId}
          items={dropdownItems}
          onItemSelect={onFirstOperandDropdownSelect}
          searchInputComponent={SearchInput}
          listItemsComponent={DropdownList}
        />
      )}

      {isOperationScheme(schema) &&
        (isFirstOperandTheOperation || selectedFirstOperandSchema) && (
          <OperationButtons
            operation={schema.operation}
            onSelect={onOperationSelect}
          />
        )}

      {isOperationScheme(secondOperandScheme) && (
        <Operation
          schema={secondOperandScheme}
          operands={operands}
          onAddOperands={onAddOperands}
          onSchemaChange={onSecondOperandSchemaChange}
        />
      )}
      {isOperandScheme(secondOperandScheme) &&
        (isFirstOperandTheOperation || selectedFirstOperandSchema) && (
          <Dropdown
            label={"some"}
            key={`second-${secondOperandScheme.selectedItemId}`}
            selectedItemId={secondOperandScheme.selectedItemId}
            selectedItemParentId={secondOperandScheme.parentSelectedItemId}
            items={dropdownItems}
            onItemSelect={onSecondOperandDropdownSelect}
            searchInputComponent={SearchInput}
            listItemsComponent={DropdownList}
          />
        )}
    </>
  );
};

export type { Operand };
