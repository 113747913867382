import * as React from "react";
import * as hash from "object-hash";
import * as classNames from "classnames";

import * as style from "./SelectMenu.scss";

import { Icon, IconType } from "@sm/ui";

interface Props {
  items: SelectMenuItemParams[];
  isOpen: boolean;
  openerIcon?: IconType;
  openerClass?: string;
  contentClass?: string;
  onOpenIconClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClick: (func: () => void, event: React.MouseEvent<HTMLDivElement>) => void;
}

export interface SelectMenuItemParams {
  type?: string;
  href?: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

export function SelectMenu({
  isOpen,
  items,
  onOpenIconClick,
  onClick,
  openerIcon = IconType.KEBAB,
  openerClass,
  contentClass,
}: Props): JSX.Element {
  return (
    <div className={classNames(style.root, isOpen && style.opened)}>
      <div
        className={classNames(style.opener, openerClass)}
        onClick={onOpenIconClick}
      >
        <div className={style.iconWrapper}>
          <Icon type={openerIcon} />
        </div>
      </div>

      <div className={classNames(style.content, contentClass)}>
        {items.map((item) =>
          item.type == "LINK" ? (
            <a
              key={hash(item)}
              className={style.menuItem}
              href={item.href}
              download="file"
            >
              {item.label}
            </a>
          ) : (
            <MenuItem key={hash(item)} item={item} handleOnClick={onClick} />
          )
        )}
      </div>

      {isOpen && <div className={style.mask} onClick={onOpenIconClick} />}
    </div>
  );
}

interface MenuItemProps {
  item: SelectMenuItemParams;
  handleOnClick: (
    func: () => void,
    event: React.MouseEvent<HTMLDivElement>
  ) => void;
}

function MenuItem({ item, handleOnClick }: MenuItemProps): JSX.Element {
  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    handleOnClick(item.onClick, event);
  }

  return (
    <div
      className={classNames(
        style.menuItem,
        item.disabled && style.disabledItem
      )}
      onClick={item.disabled ? null : handleClick}
    >
      {item.label}
    </div>
  );
}
