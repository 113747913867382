import { reducerWithInitialState } from "typescript-fsa-reducers";
import { FieldType } from "@sm/types/backend";

import { BlockParams, BriefState } from "../types";
import { ItemType } from "../../../modules/BriefPage/ItemType";

import {
  addBlock,
  editBlock,
  updateParentBlock,
  rerollElementIds,
  changeBlockOrder,
  changeFieldOrder,
  normalizeElementsOrders,
  addField,
  removeField,
  setSelectedElement,
  setVisibilityCalculationLogicPopup,
  setCalculationLogicPopupParams,
  removeBlock,
  editField,
  togglePreviewMode,
  changeBriefName,
  updateBriefPage,
  resetBriefPage,
  showValidationErrors,
  hideValidationErrors,
  removeFieldsByType,
} from "../actions";

import { BriefReducer } from "./BriefReducer";

const elements: BlockParams[] = [];
const selectedElementId: string = null;
const selectedBlockId: string = null;
const selectedFieldItemId: string = null;
const selectedItemType: ItemType | FieldType = null;

const initialState = {
  name: "",
  isNameValid: true,
  briefSchemeId: "",
  elements,
  isSchemeEditable: true,
  isFormulaEditable: true,
  isPreviewModeEnabled: false,
  showValidationErrors: false,
  selectedElementId,
  selectedBlockId,
  selectedFieldItemId,
  selectedItemType,
  calculationLogicPopup: {
    visibility: false,
    selectedFieldId: null,
    selectedFieldLineId: null,
  },
} as BriefState;

export const briefReducer = reducerWithInitialState(initialState)
  .case(addBlock, BriefReducer.addBlock)
  .case(editBlock, BriefReducer.editBlock)
  .case(updateParentBlock, BriefReducer.updateParentBlock)
  .case(rerollElementIds, BriefReducer.rerollElementIds)
  .case(changeBlockOrder, BriefReducer.changeBlockOrder)
  .case(changeFieldOrder, BriefReducer.changeFieldOrder)
  .case(normalizeElementsOrders, BriefReducer.normalizeElementsOrders)
  .case(addField, BriefReducer.addField)
  .case(removeField, BriefReducer.removeField)
  .case(removeFieldsByType, BriefReducer.removeFieldsByType)
  .case(setSelectedElement, BriefReducer.setSelectedElement)
  .case(
    setVisibilityCalculationLogicPopup,
    BriefReducer.setVisibilityCalculationLogicPopup
  )
  .case(
    setCalculationLogicPopupParams,
    BriefReducer.setCalculationLogicPopupParams
  )
  .case(removeBlock, BriefReducer.removeBlock)
  .case(editField, BriefReducer.editField)
  .case(togglePreviewMode, BriefReducer.togglePreviewMode)
  .case(changeBriefName, BriefReducer.changeBriefName)
  .case(updateBriefPage, BriefReducer.updateBriefPage)
  .case(showValidationErrors, BriefReducer.showValidationErrors)
  .case(hideValidationErrors, BriefReducer.hideValidationErrors)
  .case(resetBriefPage, BriefReducer.resetBriefPage);
