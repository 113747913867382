import { FileStorage } from "@sm/types/admin";

import { axios } from "../lib/axios";

const BASE_URL = "/api/file";

export class FileServiceSettingsApi {
  public static async getStorageName(): Promise<FileStorage> {
    const res = await axios.get(BASE_URL);

    return res.data;
  }

  public static async setStorageName(storage: FileStorage): Promise<void> {
    await axios.put(BASE_URL, { storage });
  }
}
