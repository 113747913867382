import { negate, isEmpty } from "lodash";
import { FilterItem } from "sber-marketing-ui";

export interface TableRow {
  id: string | number;
}
export type ColumnKey = string | number;

export type ColumnDescriptors<R extends TableRow, C extends ColumnKey> = Record<
  C,
  ColumnDescriptor<R, C>
>;
export interface ColumnDescriptor<R extends TableRow, C extends ColumnKey> {
  title: string;
  width: number;
  skipFilter?: boolean;
  skipOrdering?: boolean;
}

export type ColumnWidths<C extends ColumnKey> = Record<C, number>;
export type TableFilters<C extends ColumnKey> = Record<C, Set<string>>;
export type TableOrdering<C extends ColumnKey> = {
  column: C;
  order: "asc" | "desc";
};

export interface Props<R extends TableRow, C extends ColumnKey> {
  rows: R[];
  columns: ColumnDescriptors<R, C>;
  externalFilter: (row: R) => boolean;
  cellRenderer: (row: R, column: C) => JSX.Element;
  getFilterItem: (row: R, column: C) => FilterItem;
  filterTableRow: (row: R, column: C, filterValues: Set<string>) => boolean;
  orderTableRow: (row: R, column: C) => string | number;
}

export function getColumns<R extends TableRow, C extends ColumnKey>(
  columns: ColumnDescriptors<R, C>
): C[] {
  return Object.keys(columns) as C[];
}

export function hasAnyFilters<C extends ColumnKey>(filters: TableFilters<C>) {
  return Object.values(filters).some(negate(isEmpty));
}
