import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import autobind from "autobind-decorator";

import { getFieldById } from "../../../../../../../store/brief/selector";
import { editField } from "../../../../../../../store/brief/actions";
import {
  EditFieldPropertiesParams,
  BriefPropertyFile,
} from "../../../../../../../store/brief/types";
import { StoreState } from "../../../../../../../store";
import { FileApi } from "../../../../../../../api";

import { FileUploader } from "./FileUploader";
import { FileAsset } from "api/file-api/types";

interface Props extends MapProps, DispatchProps {
  fieldId: string;
  children: JSX.Element;
  //   onUploadSuccess: (params: any) => void;
  //   onUploadStart: (file: any) => void;
  //   onUploadError: (error: UploadErrorParams, asset: any) => void;
}

interface MapProps {
  userId?: number;
  files?: BriefPropertyFile[];
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileUploaderContainer extends React.PureComponent<Props> {
  protected fileInput: HTMLInputElement;

  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const { children } = this.props;

    return React.createElement(FileUploader, {
      children,
      fileInputRef: this.fileInputRef,
      onAddButtonClick: this.onAddButtonClick,
      onFileAdded: this.onFileAdded,
    });
  }

  @autobind
  protected fileInputRef(element: HTMLInputElement) {
    this.fileInput = element;
  }

  @autobind
  protected onAddButtonClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.fileInput.click();
  }

  @autobind
  protected async onFileAdded() {
    if (this.fileInput.files) {
      const previewFiles: BriefPropertyFile[] = [];
      for (let i = 0; i < this.fileInput.files.length; i++) {
        const fileAsset = FileApi.makeFileAsset({}, this.fileInput.files[i]);
        fileAsset.id = null;
        fileAsset.isLoading = true;
        previewFiles.push(fileAsset);
      }
      this.props.editField({
        id: this.props.fieldId,
        files: [...this.props.files, ...previewFiles],
      });

      const files: BriefPropertyFile[] = [...this.props.files];
      for (let i = 0; i < this.fileInput.files.length; i++) {
        const fileAsset = FileApi.makeFileAsset({}, this.fileInput.files[i]);
        const uploadProcess = FileApi.uploadFileProcess(
          {},
          fileAsset,
          `${this.props.userId}`,
          true
        );
        let assetId: string | null = null;
        let assetName: string;
        let assetFile: FileAsset;
        try {
          for await (const asset of uploadProcess) {
            assetName = `${asset.originName}.${asset.type}`;
            assetId = asset.id;
            assetFile = asset;
          }
          files.push(assetFile);
        } catch (err) {
          console.log(err);
        }
      }

      this.props.editField({
        id: this.props.fieldId,
        files,
      });
    }

    this.clearFileInput();
  }

  private clearFileInput(): void {
    // easiest cross-browser way to clear file input
    // "this.fileInput.value = null" method doesn't work in IE11
    this.fileInput.type = "";
    this.fileInput.type = "file";
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.fieldId);
  const isSchemaEditable = state.brief.isSchemeEditable;
  const isSchemaActive = state.brief.isSchemeActive;

  return {
    files: field ? field.properties?.files || [] : [],
    userId: state.user.id,
    isSchemaEditable,
    isSchemaActive,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    editField: (params: EditFieldPropertiesParams) =>
      dispatch(editField(params)),
  };
}
