import * as React from "react";

import { PageProps } from "../Page";
import { DepartmentListPage } from "../../DepartmentListPage";
import { DepartmentRolePage } from "../../DepartmentRolePage";
import { WorkTypePage } from "../../WorkType";
import { BriefListPage } from "../../BriefListPage";
import { BriefPage } from "../../BriefPage";
import { ActivityTypePage } from "../../ActivityTypePage";
import { DictionariesPage } from "../../DictionariesPage";
import { CalendarSharingPage } from "../../CalendarSharingPage";
import { EditCalendarSharingPage } from "../../EditCalendarSharingPage";
import { TradeMarkPage } from "../../TradeMark";
import { RolePage } from "../../RolePage";
import { BudgetsPage } from "../../BudgetsPage";
import { BudgetAccessPage } from "../../BudgetAccessPage";
import { ContractsPage } from "../../ContractsPage";

export interface Location {
  url: string;
  label?: string;
  sub?: boolean;
  Component?: React.ComponentType<PageProps>;
  previousUrl?: string;
  previousLabel?: string;
}

export const locations: Location[] = [
  {
    url: "/department",
    label: "Департаменты",
    Component: DepartmentListPage,
  },
  {
    url: "/department/:departmentId",
    label: "Название Департамента",
    Component: DepartmentRolePage,
    sub: true,
    previousUrl: "/department",
    previousLabel: "Департаменты",
  },
  {
    url: "/work-type",
    label: "Виды работ",
    Component: WorkTypePage,
  },
  {
    url: "/brief/scheme",
    label: "Шаблоны брифа",
    Component: BriefListPage,
  },
  {
    url: "/brief/scheme/new",
    label: "Создание шаблона брифа",
    Component: BriefPage,
    sub: true,
    previousUrl: "/brief/scheme",
    previousLabel: "Шаблоны брифа",
  },
  {
    url: "/brief/scheme/:briefSchemeId",
    label: "Редактирование шаблона брифа",
    Component: BriefPage,
    sub: true,
    previousUrl: "/brief/scheme",
    previousLabel: "Шаблоны брифа",
  },
  {
    url: "/brief/scheme/:briefSchemeId/:action(copy)",
    label: "Создание копии шаблона брифа",
    Component: BriefPage,
    sub: true,
    previousUrl: "/brief/scheme",
    previousLabel: "Шаблоны брифа",
  },
  {
    url: "/activity-type",
    label: "Тип проекта",
    previousUrl: "/",
    previousLabel: "Организации",
    Component: ActivityTypePage,
  },
  {
    url: "/dictionaries",
    label: "Справочники",
    Component: DictionariesPage,
  },
  {
    url: "/calendar-sharing",
    label: "Доступ к календарям",
    Component: CalendarSharingPage,
  },
  {
    url: "/calendar-sharing-edit/:id",
    Component: EditCalendarSharingPage,
    previousUrl: "/calendar-sharing",
    previousLabel: "Доступ к календарям",
  },
  {
    url: "/trademark",
    label: "Реестр товарных знаков",
    Component: TradeMarkPage,
  },
  {
    url: "/roles",
    label: "Роли",
    Component: RolePage,
  },
  {
    url: "/budgets",
    label: "Управление бюджетом",
    Component: BudgetsPage,
  },
  {
    url: "/budgets/:budgetId/access",
    label: "Доступ к бюджетам",
    Component: BudgetAccessPage,
    sub: true,
    previousUrl: "/budgets",
    previousLabel: "Управление бюджетом",
  },
  {
    url: "/contracts",
    label: "Контракты",
    Component: ContractsPage,
  },
];
