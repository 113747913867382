import * as React from "react";

const HEADER_HEIGHT = 48;

interface Props {
  items: {
    id: string;
    value: string;
  }[];
  bottomMargin?: number;
  rightMargin?: number;
  itemRenderer: (params: {
    id: string;
    text: string;
    ref: React.RefObject<HTMLDivElement>;
  }) => void;
}

interface State {
  itemsVisibility: {
    [id: string]: boolean;
  };
}

export class AutosizeList extends React.PureComponent<Props, State> {
  private itemsRefs: {
    [id: string]: React.RefObject<HTMLDivElement>;
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      itemsVisibility: this.itemsReducer((el) => true),
    };

    this.itemsRefs = this.itemsReducer((el) => React.createRef());
  }

  public componentDidMount(): void {
    const itemsVisibility = this.itemsReducer((el) =>
      this.isItemVisible(el.id)
    );

    this.setState({
      itemsVisibility,
    });
  }

  public render(): JSX.Element {
    const { itemsVisibility } = this.state;
    const { items, itemRenderer } = this.props;

    const nonVisibleItemsCount = items.reduce(
      (acc, elem) => (!itemsVisibility[elem.id] ? acc + 1 : acc),
      0
    );

    return (
      <React.Fragment>
        {items.map((el) => {
          const isVisible = itemsVisibility[el.id];
          const ref = this.itemsRefs[el.id];

          return (
            isVisible &&
            itemRenderer({
              id: el.id,
              ref,
              text: el.value,
            })
          );
        })}

        {!!nonVisibleItemsCount &&
          itemRenderer({
            id: "autosize-list-hidden-elems-bar",
            ref: null,
            text: `+ еще ${nonVisibleItemsCount}`,
          })}
      </React.Fragment>
    );
  }

  private itemsReducer<T>(reducer: (el: { id: string }) => T): {
    [id: string]: T;
  } {
    return this.props.items.reduce(
      (acc, el) => ({
        ...acc,
        [el.id]: reducer(el),
      }),
      {}
    );
  }

  private isItemVisible(id: string): boolean {
    if (!this.itemsRefs[id].current) {
      return false;
    }

    const bottomMargin = this.props.bottomMargin || 0;
    const rightMargin = this.props.rightMargin || 0;

    const rect = this.itemsRefs[id].current.getBoundingClientRect();
    return (
      rect.left + rightMargin <= window.innerWidth &&
      rect.top + bottomMargin + HEADER_HEIGHT <= window.innerHeight
    );
  }
}
