import * as React from "react";

import * as style from "./UnlockableInputMenu.scss";

import {
  LabeledTextarea,
  LabeledInput,
  Checkbox_redesign,
  Icon,
  IconType,
} from "@sm/ui";

const CHECKBOX_ICON_SIZE = 20;

interface Props {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isCalculated: boolean;
  isSchemaActive: boolean;
  isSchemaEditable: boolean;
  isFormulaEditable: boolean;
  hasFormulas: boolean;
  duplicateToReport?: boolean;
  isClientHide?: boolean;
  onNameChange: (value: string) => void;
  onValidationMessageChange: (value: string) => void;
  onRequirementChange: (isChecked: boolean) => void;
  onDuplicateToReportChange: (isChecked: boolean) => void;
  onCalculatedChange: (isChecked: boolean) => void;
  onSumButtonClick: () => void;
  onTooltipMessageChange: (value: string) => void;
  onClientHideCheckboxChange: (isChecked: boolean) => void;
}

export function UnlockableInputMenu({
  name = "Название поля",
  validationMessage = "",
  tooltipMessage = "",
  isRequired,
  isCalculated,
  isSchemaActive,
  isSchemaEditable,
  isFormulaEditable,
  hasFormulas,
  duplicateToReport,
  isClientHide,
  onNameChange,
  onValidationMessageChange,
  onRequirementChange,
  onDuplicateToReportChange,
  onCalculatedChange,
  onSumButtonClick,
  onTooltipMessageChange,
  onClientHideCheckboxChange,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.inputProperty}>
        <LabeledInput
          className={isCalculated ? style.nameInput : null}
          title={"Название поля"}
          value={name}
          onValueChange={onNameChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />

        {isCalculated ? (
          hasFormulas ? (
            <div className={style.sumIconWrapper} onClick={onSumButtonClick}>
              <Icon type={IconType.SUM} />
            </div>
          ) : (
            <div className={style.sumIconWrapper} onClick={onSumButtonClick}>
              <Icon type={IconType.ADD_SUM} svgSize={25} />
            </div>
          )
        ) : null}
      </div>

      <div className={style.inputProperty}>
        <LabeledInput
          title={"Текст ошибки"}
          value={validationMessage}
          onValueChange={onValidationMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Обязательное поле"}
          isChecked={isRequired}
          onSwitch={onRequirementChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Дублировать в отчет"}
          isChecked={duplicateToReport}
          onSwitch={onDuplicateToReportChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Поле входит в расчет калькурятора"}
          isChecked={isCalculated}
          onSwitch={onCalculatedChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isFormulaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>
    </div>
  );
}
