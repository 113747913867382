import { actionCreatorFactory } from "typescript-fsa";

import type { CreativeRequestContract, Dictionary } from "@api";

const actionCreator = actionCreatorFactory("CONTRACTS_PAGE");

const loadContracts =
  actionCreator<CreativeRequestContract[]>("LOAD_CONTRACTS");
const loadLotDictionaries = actionCreator<Dictionary[]>(
  "LOAD_LOT_DICTIONARIES"
);
const resetPageState = actionCreator("RESET_PAGE_STATE");
const setArchivedFilter = actionCreator<boolean>("SET_ARCHIVED_FILTER");

export {
  loadContracts,
  loadLotDictionaries,
  resetPageState,
  setArchivedFilter,
};
