import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as lodash from "lodash";

import { PageState, PageData, SortingParams, Filter } from "./types";

import * as actions from "./actions";

export const initialState: PageState = {
  pageData: {
    budgetAccessLines: [],
    organizations: [],
    dictionaries: [],
    departments: [],
    budgets: [],
    roles: [],
    actions: [],
  },
  filters: [],
  sorting: { columnName: null, orderType: null },
};

export class Reducer {
  public static loadPageData(
    state: PageState,
    payload: Partial<PageData>
  ): PageState {
    return { ...state, pageData: { ...state.pageData, ...payload } };
  }

  public static resetPageState(): PageState {
    return lodash.cloneDeep(initialState);
  }

  public static setTableSorting(
    state: PageState,
    payload: SortingParams
  ): PageState {
    return { ...state, sorting: payload };
  }

  public static setTableFilters(
    state: PageState,
    payload: Filter[]
  ): PageState {
    return { ...state, filters: payload };
  }
}

export const budgetAccessPageReducer = reducerWithInitialState(initialState)
  .case(actions.loadPageData, Reducer.loadPageData)
  .case(actions.resetPageState, Reducer.resetPageState)
  .case(actions.setTableSorting, Reducer.setTableSorting)
  .case(actions.setTableFilters, Reducer.setTableFilters);
