import * as React from "react";

import { Button, ButtonTheme } from "@sm/ui";

import * as style from "./EditBudgetForm.scss";

interface Props {
  canBePlanBudgetAdded: boolean;
  canBeExecutionBudgetAdded: boolean;
  addPlanBudget: () => void;
  addExecutionBudget: () => void;
}

export function EditBudgetForm({
  canBePlanBudgetAdded,
  canBeExecutionBudgetAdded,
  addPlanBudget,
  addExecutionBudget,
}: Props) {
  return (
    <div className={style.root}>
      <div className={style.title}>Добавление бюджета</div>
      <div className={style.buttons}>
        {canBePlanBudgetAdded && (
          <div className={style.button}>
            <Button theme={ButtonTheme.PrimaryMain} onClick={addPlanBudget}>
              Добавить плановый бюджет
            </Button>
          </div>
        )}
        {canBeExecutionBudgetAdded && (
          <div className={style.button}>
            <Button
              theme={ButtonTheme.PrimaryMain}
              onClick={addExecutionBudget}
            >
              Добавить бюджет исполнения
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
