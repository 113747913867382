// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__root{position:relative;border:1px solid #E3E9E5;border-radius:5px;transition:border 75ms ease-out}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__rootActive{border:1px solid #19BB4F}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__searchInput{height:32px !important;padding:0 31px 0 12px !important;color:#1a1a1a !important;font-weight:300}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__arrowIcon{position:absolute;right:6px;top:calc(50% - 7px);pointer-events:none;color:#7E8681;transition:transform 75ms ease-out}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__arrowIconUp{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/FormulasBuilder/Operation/SearchInput/styles.scss"],"names":[],"mappings":"AAAA,iGACI,iBAAkB,CAElB,wBAAyB,CACzB,iBAAkB,CAElB,+BAAgC,CACnC,uGAGG,wBAAyB,CAC5B,wGAGG,sBAAuB,CACvB,gCAAiC,CACjC,wBAAyB,CACzB,eAAgB,CACnB,sGAGG,iBAAkB,CAClB,SAAU,CACV,mBAAoB,CACpB,mBAAoB,CACpB,aAAc,CACd,kCAAmC,CACtC,wGAGG,wBAAyB","sourcesContent":[".root {\n    position: relative;\n\n    border: 1px solid #E3E9E5;\n    border-radius: 5px;\n\n    transition: border 75ms ease-out;\n}\n\n.rootActive {\n    border: 1px solid #19BB4F;\n}\n\n.searchInput {\n    height: 32px !important;\n    padding: 0 31px 0 12px !important;\n    color: #1a1a1a !important;\n    font-weight: 300;\n}\n\n.arrowIcon {\n    position: absolute;\n    right: 6px;\n    top: calc(50% - 7px);\n    pointer-events: none;\n    color: #7E8681;\n    transition: transform 75ms ease-out;\n}\n\n.arrowIconUp {\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__root",
	"rootActive": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__rootActive",
	"searchInput": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__searchInput",
	"arrowIcon": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__arrowIcon",
	"arrowIconUp": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-SearchInput-_styles__arrowIconUp"
};
export default ___CSS_LOADER_EXPORT___;
