import * as React from "react";
import * as classNames from "classnames";

import * as style from "./SwitchGroup.scss";

import { SwitchType } from "@sm/types/frontend";
import { ElementPropertiesParams } from "../../../../../../store/brief/types";
import { SwitchGroupFields } from "./SwitchGroupFields";

import {
  Checkbox_redesign,
  Icon,
  IconType,
  RadioButtonGroup_redesign,
  WithTooltip,
} from "@sm/ui";

const CHECKBOX_ICON_SIZE = 20;

export interface SwitchGroupProps extends ElementPropertiesParams {
  fieldId: string;
  blockId: string;
  order: number;
  isSelected: boolean;
  onToggleSelect: React.MouseEventHandler<HTMLDivElement>;
  onRemoveClick: () => void;
  isHasExtraFields?: boolean;
  isPreviewModeEnabled?: boolean;
}

export function SwitchGroup({
  blockId,
  order,
  isSelected,
  name,
  tooltipMessage,
  switchType,
  switches = [],
  onToggleSelect,
  onRemoveClick,
  isHasExtraFields,
  isPreviewModeEnabled,
  canRemove,
}: SwitchGroupProps): JSX.Element {
  const [selected, setSelect] = React.useState({});
  const hasTooltip = !!tooltipMessage;

  return (
    <div
      className={classNames(
        style.root,
        isSelected && style.onEdit,
        isPreviewModeEnabled && style.previewMode
      )}
      onClick={onToggleSelect}
    >
      <div className={style.title}>{name || "Название поля"}</div>

      {isPreviewModeEnabled && hasTooltip && (
        <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
          <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
        </WithTooltip>
      )}

      <div className={style.switches}>
        {switchType == SwitchType.Checkbox &&
          switches.map((item, index) => (
            <div className={style.switchItem} key={`field ${item.id}`}>
              <Checkbox_redesign
                title={item.name}
                tooltip={isPreviewModeEnabled && item.tooltipMessage}
                readOnly={!isPreviewModeEnabled}
                iconSize={CHECKBOX_ICON_SIZE}
                isChecked={!isPreviewModeEnabled ? null : selected[item.id]}
                onSwitch={(checked) => {
                  setSelect({
                    ...selected,
                    [item.id]: checked,
                  });
                }}
              />

              {isHasExtraFields && (
                <SwitchGroupFields
                  isChecked={selected[item.id]}
                  blockId={blockId}
                  switchPropertyId={item.id}
                  order={order + 1}
                />
              )}
            </div>
          ))}

        {switchType == SwitchType.Radio && (
          <RadioButtonGroup_redesign
            items={switches.map((item) => ({
              title: item.name,
              value: item.id,
              tooltip: isPreviewModeEnabled && item.tooltipMessage,
            }))}
            selectedValue={!isPreviewModeEnabled ? null : selected["radio"]}
            readOnly={!isPreviewModeEnabled}
            onSelection={(id) => {
              setSelect({ radio: id });
            }}
            renderAdditional={(item, index) =>
              isHasExtraFields ? (
                <SwitchGroupFields
                  isChecked={selected["radio"] === switches[index].id}
                  blockId={blockId}
                  switchPropertyId={switches[index].id}
                  order={order + index}
                />
              ) : null
            }
          />
        )}
      </div>

      {canRemove && isSelected && (
        <div className={style.icon} onClick={onRemoveClick}>
          <Icon type={IconType.CROSS_BRIEF} />
        </div>
      )}
    </div>
  );
}
