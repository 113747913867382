import { ComponentType } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { getSelectedFilter } from "../../../store/briefSchemeListPage/selector";
import { StoreState } from "../../../store";
import { setBriefSchemeListFilter } from "../../../store/briefSchemeListPage/actions";
import { BriefSchemeListFilterMode } from "../../../store/briefSchemeListPage/types";
import { FiltersLineMappedState, FiltersLineMappedActions } from "./types";
import {
  AdditionalHeaderMenu,
  AdditionalHeaderMenuProps,
  AdditionalHeaderMenuItem,
} from "../../common/AdditionalHeaderMenu";

const mapStateToProps = (state: StoreState): FiltersLineMappedState => ({
  selectedFilter: getSelectedFilter(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<StoreState>
): FiltersLineMappedActions => ({
  setBriefSchemeListFilter: (filter) =>
    dispatch(setBriefSchemeListFilter(filter)),
});

const mergeProps = (
  { selectedFilter }: FiltersLineMappedState,
  { setBriefSchemeListFilter }: FiltersLineMappedActions
): AdditionalHeaderMenuProps => {
  const items: AdditionalHeaderMenuItem[] = [
    {
      key: BriefSchemeListFilterMode.Actual,
      children: "Актуальные",
      link: "#",
      active: selectedFilter === BriefSchemeListFilterMode.Actual,
      onClick: (event) => {
        event.preventDefault();
        setBriefSchemeListFilter({
          selectedFilter: BriefSchemeListFilterMode.Actual,
        });
      },
    },
    {
      key: BriefSchemeListFilterMode.Inactual,
      children: "Неактуальные",
      link: "#",
      active: selectedFilter === BriefSchemeListFilterMode.Inactual,
      onClick: () => {
        event.preventDefault();
        setBriefSchemeListFilter({
          selectedFilter: BriefSchemeListFilterMode.Inactual,
        });
      },
    },
  ];
  return { items };
};

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** Filters line */
// @ts-ignore
export const FiltersLineStore: ComponentType = withStore(AdditionalHeaderMenu);
