import type { OperationScheme, OperandScheme } from "../types";
import { Type } from "../enums";

export const isOperationScheme = (
  operation: OperationScheme | OperandScheme
): operation is OperationScheme => {
  return operation?.type === Type.OPERATION;
};

export const isOperandScheme = (
  operation: OperationScheme | OperandScheme
): operation is OperandScheme => {
  return operation?.type === Type.OPERAND;
};
