// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__root{height:100%;width:100%;display:flex;align-items:center;justify-content:center;background-color:#ffffff}.src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__link{display:flex;align-items:center;cursor:pointer;text-decoration:none;padding:0 20px;height:32px;font-family:'Open Sans';font-size:14px;line-height:16px;letter-spacing:0.15px;color:#ffffff;background:#2b9ae1;border-radius:6px}.src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__link:hover{background:#1870a8}.src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__link:active{background:#1870a8}\n", "",{"version":3,"sources":["webpack://./src/modules/BudgetsPage/Table/CellTypes/AccessCell/AccessCell.scss"],"names":[],"mappings":"AAAA,sEACI,WAAY,CACZ,UAAW,CAEX,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,wBAAyB,CAC5B,sEAGG,YAAa,CACb,kBAAmB,CACnB,cAAe,CACf,oBAAqB,CACrB,cAAe,CAEf,WAAY,CAEZ,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAEd,kBAAmB,CACnB,iBAAkB,CAhBtB,4EAmBQ,kBAAgC,CAnBxC,6EAuBQ,kBAAgC","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #ffffff;\n}\n\n.link {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    text-decoration: none;\n    padding: 0 20px;\n\n    height: 32px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    color: #ffffff;\n\n    background: #2b9ae1;\n    border-radius: 6px;\n\n    &:hover {\n        background: darken(#2b9ae1, 15%);\n    }\n\n    &:active {\n        background: darken(#2b9ae1, 15%);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__root",
	"link": "src-modules-BudgetsPage-Table-CellTypes-AccessCell-_AccessCell__link"
};
export default ___CSS_LOADER_EXPORT___;
