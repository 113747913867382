import { UserSubordination, UserParams } from "@sm/types/admin";

import { CommonState } from "../types";

export class UserSubordinationReducer {
  public static loadUserSubordination(
    state: CommonState,
    userSubordination: UserSubordination
  ) {
    return {
      ...state,
      userSubordination,
    };
  }

  public static addSubordination(
    state: CommonState,
    subordinationUser: UserParams
  ) {
    const subordination = [
      ...state.userSubordination.subordination,
      subordinationUser,
    ];
    return {
      ...state,
      userSubordination: {
        ...state.userSubordination,
        subordination,
      },
    };
  }

  public static removeSubordination(state: CommonState, userId: number) {
    const subordination = state.userSubordination.subordination.filter(
      (departmentUser) => departmentUser.id != userId
    );
    return {
      ...state,
      userSubordination: {
        ...state.userSubordination,
        subordination,
      },
    };
  }
}
