import { maxBy, uniq } from "lodash";

import { StoreState } from "../";
import { LoadingStatus } from "./types";

/** State self getter. Useful for selectors combination. */
export const getState = (state: StoreState) => state;

/** Type helper for "secondArgument" */
export type SecondArgument<T> = (_: StoreState, arg: T) => T;

/** Second argument getter */
export const secondArgument = <T>(_: StoreState, arg: T): T => arg;

/** Type helper for "secondArrayArgument" */
export type SecondArrayArgument<T> = (_: StoreState, arg: T[]) => T[];

/** Second array argument. Removes duplicate values. */
export const secondArrayArgument = <T>(_: StoreState, arg: T[]): T[] =>
  uniq(arg);

/** Priority indexes for loading statuses */
const LOADING_STATUS_PRIORITIES = {
  [LoadingStatus.NOT_LOADED]: 3,
  [LoadingStatus.LOADING]: 2,
  [LoadingStatus.ERROR]: 1,
  [LoadingStatus.LOADED]: 0,
};

/** Get priority loading status */
export const getPriorityLoadingStatus = (
  ...statuses: LoadingStatus[]
): LoadingStatus =>
  maxBy(
    statuses,
    (status: LoadingStatus): number => LOADING_STATUS_PRIORITIES[status]
  );
