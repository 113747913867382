// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__headerWrapper{margin-bottom:14px}.src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__triggerSelectorWrapper{margin-bottom:12px}.src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__formulaWrapper{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/Calculator/styles.scss"],"names":[],"mappings":"AAAA,+EACI,kBAAmB,CACtB,wFAGG,kBAAmB,CACtB,gFAGG,kBAAmB","sourcesContent":[".headerWrapper {\n    margin-bottom: 14px;\n}\n\n.triggerSelectorWrapper {\n    margin-bottom: 12px;\n}\n\n.formulaWrapper {\n    margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__headerWrapper",
	"triggerSelectorWrapper": "src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__triggerSelectorWrapper",
	"formulaWrapper": "src-modules-BriefPage-CalculationLogicPopup-Calculator-_styles__formulaWrapper"
};
export default ___CSS_LOADER_EXPORT___;
