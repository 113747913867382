// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-common-AdditionalHeaderMenu-_AdditionalHeaderMenu__root{display:flex;width:100%;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/modules/common/AdditionalHeaderMenu/AdditionalHeaderMenu.scss"],"names":[],"mappings":"AAAA,qEACI,YAAa,CAEb,UAAW,CAEX,kBAAmB,CACnB,sBAAuB","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-common-AdditionalHeaderMenu-_AdditionalHeaderMenu__root"
};
export default ___CSS_LOADER_EXPORT___;
