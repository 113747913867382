import * as React from "react";

import { Icon, IconType } from "@sm/ui";
import * as style from "./Tooltip.scss";

interface Props {}

export const Tooltip: React.FC<Props> = () => {
  return (
    <div className={style.root}>
      <div className={style.iconWrapper}>
        <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
      </div>
      <div className={style.message}>
        {/* tslint:disable-next-line:max-line-length */}
        Перед названием опциолнального блока добавляется переключатель. В
        процессе заполнения брифа такой блок можно отключить. Подходит для
        гибких брифов, в которых учитываются подробные ньюансы.
      </div>
    </div>
  );
};
