import { reducerWithInitialState } from "typescript-fsa-reducers";

import * as Organization from "@sm/types/admin";

import {
  load,
  loadCurrentOrganization,
  addOrganization,
  updateOrganization,
  updateCurrentOrganization,
  removeOrganization,
  setCurrentOrganizationId,
  resetCurrentOrganization,
} from "../actions";

import { OrganizationReducer } from "./OrganizationReducer";

const organizations: Organization.OrganizationParams[] = [];
const currentOrganization: Organization.OrganizationParams =
  {} as Organization.OrganizationParams;

const initialState = {
  organizations,
  currentOrganization,
  isFormOpen: false,
  currentOrganizationId: null as string,
};

export const organizationReducer = reducerWithInitialState(initialState)
  .case(load, OrganizationReducer.load)
  .case(loadCurrentOrganization, OrganizationReducer.loadCurrentOrganization)
  .case(addOrganization, OrganizationReducer.addOrganization)
  .case(updateOrganization, OrganizationReducer.updateOrganization)
  .case(
    updateCurrentOrganization,
    OrganizationReducer.updateCurrentOrganization
  )
  .case(removeOrganization, OrganizationReducer.removeOrganization)
  .case(setCurrentOrganizationId, OrganizationReducer.setCurrentOrganizationId)
  .case(resetCurrentOrganization, OrganizationReducer.resetCurrentOrganization);
