import type { BudgetLine } from "@api";
import { ColumnName, ColumnType } from "@store/budgetsPage/types";

import { TextCell, StatusCell, AccessCell } from "./CellTypes";

export interface ColumnParams {
  title: string;
  type: ColumnType;
  defaultWidth: number;
  readOnly?: boolean;
  getValue: (budget: BudgetLine, budgets: BudgetLine[]) => any;
}

export const CellComponentsByColumnType: Record<
  ColumnType,
  {
    cell: React.ClassType<any, any, any>;
    editCell?: React.ClassType<any, any, any>;
  }
> = {
  [ColumnType.Text]: {
    cell: TextCell,
  },
  [ColumnType.Status]: {
    cell: StatusCell,
  },
  [ColumnType.Access]: {
    cell: AccessCell,
  },
};

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
  [ColumnName.SerialNumber]: {
    title: "ID",
    type: ColumnType.Text,
    defaultWidth: 140,
    readOnly: true,
    getValue: (budget: BudgetLine, budgets: BudgetLine[]) =>
      budgets.indexOf(budget) + 1,
  },
  [ColumnName.Year]: {
    title: "Год",
    type: ColumnType.Text,
    defaultWidth: 160,
    readOnly: true,
    getValue: (budget: BudgetLine) => budget.model.year,
  },
  [ColumnName.BudgetType]: {
    title: "Тип бюджета",
    type: ColumnType.Text,
    defaultWidth: 250,
    readOnly: true,
    getValue: (budget: BudgetLine) =>
      budget.model.status === "plan" || budget.model.status === "archive_plan"
        ? "Планирование"
        : "Исполнение",
  },
  [ColumnName.Status]: {
    title: "Статус",
    type: ColumnType.Status,
    defaultWidth: 300,
    readOnly: true,
    getValue: (budget: BudgetLine) => budget.model.status,
  },
  [ColumnName.Access]: {
    title: "Доступ",
    type: ColumnType.Access,
    defaultWidth: 150,
    readOnly: true,
    getValue: (budget: BudgetLine) => budget.model.id,
  },
};

export const tableColumns = [
  ColumnName.SerialNumber,
  ColumnName.Year,
  ColumnName.BudgetType,
  ColumnName.Status,
  ColumnName.Access,
];

export const readOnlyColumns = tableColumns.filter(
  (item) => ColumnsConfig[item].readOnly
);
