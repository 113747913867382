// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-ElementsList-_ElementsList__hidden{display:none}.src-modules-BriefPage-Schema-ElementsList-_ElementsList__root{width:inherit;background-color:#ffffff}.src-modules-BriefPage-Schema-ElementsList-_ElementsList__topBorder{width:100%;height:4px;background-color:#dcdcdc}.src-modules-BriefPage-Schema-ElementsList-_ElementsList__topBorder::after{display:block;width:50%;height:100%;content:'';background-color:#519b0b}.src-modules-BriefPage-Schema-ElementsList-_ElementsList__block{width:100%;height:100px;background-color:#96b9dc}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/BriefPage/Schema/ElementsList/ElementsList.scss"],"names":[],"mappings":"AAYA,iEACI,YAAa,CCZjB,+DACI,aAAc,CAEd,wBAAyB,CAC5B,oEAGG,UAAW,CACX,UAAW,CAEX,wBAAyB,CAJ7B,2EAMQ,aAAc,CAEd,SAAU,CACV,WAAY,CAEZ,UAAW,CAEX,wBDpBO,CCqBV,gEAID,UAAW,CACX,YAAa,CAEb,wBAAyB","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import \"../../../common/mixins/mixin\";\n.root {\n    width: inherit;\n\n    background-color: #ffffff;\n}\n\n.topBorder {\n    width: 100%;\n    height: 4px;\n\n    background-color: #dcdcdc;\n    &::after {\n        display: block;\n\n        width: 50%;\n        height: 100%;\n\n        content: '';\n\n        background-color: $Green;\n    }\n}\n\n.block {\n    width: 100%;\n    height: 100px;\n\n    background-color: #96b9dc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-BriefPage-Schema-ElementsList-_ElementsList__hidden",
	"root": "src-modules-BriefPage-Schema-ElementsList-_ElementsList__root",
	"topBorder": "src-modules-BriefPage-Schema-ElementsList-_ElementsList__topBorder",
	"block": "src-modules-BriefPage-Schema-ElementsList-_ElementsList__block"
};
export default ___CSS_LOADER_EXPORT___;
