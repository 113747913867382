import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import type { WorkTypeParams } from "@sm/types/admin";

import { AddWorkTypeRow } from "./AddWorkTypeRow";
import type { StoreState } from "../../../store";
import { add, setIsOnAdd } from "../../../store/workType/actions";
import { getLoginUser } from "../../../store/user/selector";
import { WorkTypeApi } from "../../../api";

interface Props extends MapProps, DispatchProps {}

interface MapProps {
  organizationId?: string;
}

interface DispatchProps {
  add?: (name: WorkTypeParams) => void;
  setIsOnAdd?: (isOnAdd: boolean) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class AddWorkTypeRowContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return React.createElement(AddWorkTypeRow, {
      name: "",
      onInputBlur: this.handleInputBlur,
    });
  }

  @autobind
  protected async handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { organizationId } = this.props;

    const name: string = event.currentTarget.value;

    if (name != "") {
      const workType = await WorkTypeApi.createWorkType({
        name,
        organizationId,
        enable_rates: false,
      });

      this.props.add(workType);
    }

    this.props.setIsOnAdd(false);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    organizationId: getLoginUser(state).organizationId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    add: (name: WorkTypeParams) => dispatch(add(name)),
    setIsOnAdd: (isOnAdd: boolean) => dispatch(setIsOnAdd(isOnAdd)),
  };
}
