import * as React from "react";

import * as style from "./DatepickerCell.scss";

interface Props {
  title: string;
  customStyle?: any;
}

export const DatepickerCellTemplate = ({
  title,
  customStyle,
}: Props): JSX.Element => {
  return (
    <div className={style.root} style={{ ...customStyle }}>
      {title}
    </div>
  );
};
