import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { ItemType } from "../../../../../ItemType";
import {
  BlockParams,
  FieldType,
  RemoveFieldParams,
} from "../../../../../../../store/brief/types";

import { SwitchGroupFields } from "./SwitchGroupFields";
import { StoreState } from "../../../../../../../store";
import {
  normalizeElementsOrders,
  removeField,
} from "../../../../../../../store/brief/actions";
import { getBlockById } from "../../../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps {
  switchPropertyId: string;
  blockId: string;
  order: number;
  isChecked?: boolean;
}

interface MapProps {
  block?: BlockParams;
  isPreviewModeEnabled?: boolean;
}

interface DispatchProps {
  removeField?: (params: RemoveFieldParams) => void;
  normalizeElementsOrders?: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SwitchGroupFieldsContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {
      switchPropertyId,
      blockId,
      order,
      isPreviewModeEnabled,
      isChecked,
    } = this.props;

    return React.createElement(SwitchGroupFields, {
      switchPropertyId,
      blockId,
      order,
      isPreviewModeEnabled,
      isChecked,
      isNotRequired: this.isNotRequiredBlock,
      fields: this.getFieldsWithoutTypeToggle().sort(
        (field1, field2) => field1.order - field2.order
      ),
      removeField: this.removeField,
    });
  }

  @autobind
  public async removeField(fieldId: string) {
    this.props.removeField({
      blockId: this.props.blockId,
      fieldId,
    });

    this.props.normalizeElementsOrders();
  }

  private get isNotRequiredBlock(): boolean {
    return this.haveFieldsWithTypeToggle();
  }

  private haveFieldsWithTypeToggle(): boolean {
    const { fields } = this.props.block;
    return fields.some(({ type }) => type === FieldType.TOGGLE);
  }

  private getFieldsWithoutTypeToggle() {
    const { switchPropertyId } = this.props;
    const { fields } = this.props.block;
    return fields.filter(
      ({ type, properties = {} }) =>
        type !== FieldType.TOGGLE &&
        properties.switchPropertyId === switchPropertyId
    );
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    block: getBlockById(state, ownProps.blockId),
    isPreviewModeEnabled: state.brief.isPreviewModeEnabled,
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      removeField,
      normalizeElementsOrders,
    },
    dispatch
  );
}
