import * as React from "react";
import { v4 } from "uuid";
import {
  PlainDictionary,
  DictionaryType,
  CalculatorDictionaryType,
} from "@sm/types/budget";
import {
  Popup,
  CloseButton,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
  LabeledInput,
  LabeledSelect,
  Suggest,
  WithTooltip,
  Preloader,
} from "sber-marketing-ui";

import { DictionaryApi, DictionaryDataSchemaApi } from "@api";

import {
  DictionaryFormTypes,
  DictionaryTypes,
  CalcMaterialDictionaryTypes,
  dictionaryIsCalcMaterialDictionary,
} from "../DictionaryTypeNames";

import { SchemaField } from "./SchemaField";
import { SchemaArrayField } from "./SchemaArrayField";

import * as styles from "./EditOrCreateDictionaryForm.scss";

const SCHEME_IDS_TO_SKIP = ["projectStartStageId"];

type UpdateCreateDictionaryParams = PlainDictionary & { data?: any };

interface Props {
  dictionary?: PlainDictionary;
  closeForm: () => void;
  onRequestFinished: () => void;
}

function isDictionaryDataValid(data: any, fields: any) {
  return Object.keys(fields).every((field) => {
    const { required, type, value } = fields[field];

    if (type === "array") {
      const subFields = value?.schema;
      const filled =
        data[field] &&
        data[field].every((arrayValue: any) =>
          isDictionaryDataValid(arrayValue, subFields)
        );
      return required ? filled : data[field] ? filled : true;
    }

    if (type === "number") {
      return required ? parseFloat(data[field]) : true;
    }

    return required ? data[field] : true;
  });
}

function makeDictionaryData(schema: any, data: any) {
  return Object.keys(schema.fields).reduce((acc, field) => {
    let fieldValue = data[field];
    if (schema.fields[field].type === "number" && fieldValue) {
      fieldValue = parseFloat(fieldValue);
    }
    if (schema.fields[field].type === "array" && fieldValue) {
      fieldValue = fieldValue.map((arrayValue: any) => {
        const arraySchema = schema.fields[field].value?.schema;
        return Object.keys(arraySchema || {}).reduce((acc, field) => {
          let fieldValue = arrayValue[field];
          if (arraySchema[field].type === "number" && fieldValue) {
            fieldValue = parseFloat(fieldValue);
          }

          return {
            ...acc,
            [field]: fieldValue,
          };
        }, {});
      });
    }

    return {
      ...acc,
      [field]: fieldValue,
    };
  }, {});
}

function useEditOrCreateDictionaryForm({
  dictionary,
  onRequestFinished,
  closeForm,
}: Props) {
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const [type, setType_] = React.useState(dictionary?.type || null);
  const [subtype, setSubtype_] = React.useState(dictionary?.type || null);
  const [value, setValue] = React.useState(dictionary?.value || "");
  const [code, setCode] = React.useState(dictionary?.code || "");
  const [data, setData] = React.useState<any>(null);
  const [schema, setSchema] = React.useState<any>(null);

  const isInCreateMode = !dictionary;

  function setType(type: DictionaryType) {
    setType_(type);

    if (type === CalculatorDictionaryType.CALC_MATERIAL) {
      setSubtype_(type);
    }

    fetchDictionarySchema(type);
  }

  function setSubtype(subtype: DictionaryType) {
    setSubtype_(subtype);

    fetchDictionarySchema(subtype);
  }

  function onDataChange(key: string, value: any) {
    setData((data: any) => ({
      ...data,
      [key]: value,
    }));
  }

  async function fetchDictionaryData() {
    if (dictionary) {
      try {
        setData((await DictionaryApi.getData(dictionary.id)).data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function fetchDictionarySchema(type: DictionaryType) {
    if (type) {
      try {
        const schema = await DictionaryDataSchemaApi.getSchema({ type });
        setSchema(schema);
        setData({ data: {}, schema });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function onSaveButtonClick() {
    if (schema && !isDictionaryDataValid(data, schema.fields)) {
      console.warn(`Error during schema validation`);
    } else {
      setIsRequestInProgress(true);

      const params: UpdateCreateDictionaryParams = {
        id: isInCreateMode ? v4() : dictionary.id,
        value,
        type: subtype || type,
        code,
      };

      if (schema) {
        params.data = makeDictionaryData(schema, data);
      }

      await (isInCreateMode
        ? DictionaryApi.createNewDictionary(params)
        : DictionaryApi.updateDictionaryItem(params.id, params));

      setIsRequestInProgress(false);

      onRequestFinished();
      closeForm();
    }
  }

  React.useEffect(function onMount() {
    async function worker() {
      await fetchDictionarySchema(type);
      await fetchDictionaryData();
    }

    worker();
  }, []);

  return {
    isInCreateMode,
    isRequestInProgress,
    type,
    setType,
    subtype,
    setSubtype,
    value,
    setValue,
    code,
    setCode,
    onDataChange,
    data,
    schema,
    onSaveButtonClick,
  };
}

export function EditOrCreateDictionaryForm(props: Props): JSX.Element {
  const { closeForm } = props;
  const {
    isInCreateMode,
    isRequestInProgress,
    type,
    setType,
    subtype,
    setSubtype,
    value,
    setValue,
    code,
    setCode,
    onDataChange,
    data,
    schema,
    onSaveButtonClick,
  } = useEditOrCreateDictionaryForm(props);

  const disableSaveButton = !(value && type);

  return (
    <Popup onOutOfContentClick={closeForm}>
      <div className={styles.root}>
        <div className={styles.formTitle}>
          {isInCreateMode ? "Создание словаря" : "Редактирование словаря"}

          <CloseButton onClick={closeForm} />
        </div>

        <div className={styles.formParams}>
          <LabeledInput
            title={"Название словаря"}
            value={value}
            onValueChange={(value) => setValue(value as string)}
          />

          <LabeledInput
            title={"Код словаря"}
            value={code}
            onValueChange={(value) => setCode(value as string)}
          />

          <Suggest
            disabled={!isInCreateMode}
            title={"Тип словаря"}
            onItemSelect={(item) => setType(item.value as DictionaryType)}
            selectedItem={
              type
                ? {
                    value: type,
                    label: DictionaryTypes[type],
                  }
                : null
            }
            items={Object.keys(DictionaryFormTypes).map(
              (type: DictionaryType) => ({
                value: type,
                label: DictionaryTypes[type],
              })
            )}
          />

          {dictionaryIsCalcMaterialDictionary(type) && (
            <Suggest
              disabled={!isInCreateMode}
              title={"Вид характеристик"}
              onItemSelect={(item) => setSubtype(item.value as DictionaryType)}
              selectedItem={
                subtype
                  ? {
                      value: subtype,
                      label: CalcMaterialDictionaryTypes[subtype],
                    }
                  : null
              }
              items={Object.keys(CalcMaterialDictionaryTypes).map((type) => ({
                value: type,
                label: CalcMaterialDictionaryTypes[type],
              }))}
            />
          )}

          {schema?.fields &&
            Object.keys(schema.fields).map((key) =>
              SCHEME_IDS_TO_SKIP.includes(key) ? null : schema.fields[key]
                  .type === "array" ? (
                <SchemaArrayField
                  key={key}
                  type={key}
                  schemaType={schema.type}
                  required={schema.fields[key].required}
                  description={schema.fields[key].description}
                  fields={schema.fields[key].value?.schema}
                  value={data?.[key]}
                  onChange={(value) => onDataChange(key, value)}
                />
              ) : (
                <SchemaField
                  key={key}
                  required={schema.fields[key].required}
                  description={schema.fields[key].description}
                  dictionary={schema.fields[key].dictionary}
                  type={schema.fields[key].type}
                  value={data?.[key]}
                  onChange={(value) => onDataChange(key, value)}
                />
              )
            )}
        </div>

        <WithTooltip
          hidden={!disableSaveButton}
          content={"Запоните название и тип словаря"}
        >
          <div className={styles.formButtonWrapper}>
            <Button
              onClick={onSaveButtonClick}
              theme={ButtonTheme.GhostRounded}
              disabled={disableSaveButton}
            >
              <div className={styles.formButton}>Сохранить</div>
            </Button>
          </div>
        </WithTooltip>

        {isRequestInProgress && <Preloader />}
      </div>
    </Popup>
  );
}
