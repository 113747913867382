import * as React from "react";
import classNames from "classnames";
import * as styles from "./styles.scss";

import {
  Icon,
  IconType,
  Input_redesign,
  InputTheme_redesign,
} from "sber-marketing-ui";

export interface Props {
  value: string;
  label: string;
  isActive: boolean;
  onChange: (value: string) => void;
  onFocus: () => void;
  disabled?: boolean;
}

export const SearchInput: React.FC<Props> = ({
  value,
  label,
  isActive,
  disabled,
  onChange,
  onFocus,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>

      <Input_redesign
        className={styles.searchInput}
        disabled={disabled}
        value={value}
        placeholder={""}
        theme={InputTheme_redesign.Simple}
        onInputChange={onChange}
        onFocus={onFocus}
      />

      {!disabled && (
        <Icon
          className={classNames(
            styles.arrowIcon,
            isActive && styles.arrowIconUp
          )}
          type={IconType.ARROW16}
        />
      )}
    </div>
  );
};
