import { CommonState } from "../types";

export class CommonReducer {
  public static setOnValidation(state: CommonState, onValidation: boolean) {
    return {
      ...state,
      onValidation,
    };
  }

  public static addError(state: CommonState, errorCode: string): CommonState {
    return {
      ...state,
      errorCode,
    };
  }

  public static removeError(state: CommonState): CommonState {
    return {
      ...state,
      errorCode: null,
    };
  }
}
