import * as React from "react";
import { LabeledTextarea, LabeledInput, Checkbox_redesign } from "@sm/ui";

import { Tooltip } from "./Tooltip";

import * as style from "./FileMenu.scss";

const CHECKBOX_ICON_SIZE = 20;

interface Props {
  name: string;
  tooltipMessage?: string;
  isClientHide?: boolean;
  isFileImported?: boolean;
  onNameChange: (value: string) => void;
  onTooltipMessageChange: (value: string) => void;
  onClientHideCheckboxChange: (value: boolean) => void;
  onFileImportedCheckboxChange: (value: boolean) => void;
  isFileInputDisabled: boolean;
}

export function FileMenu({
  name,
  tooltipMessage = "",
  isClientHide,
  isFileImported,
  onNameChange,
  onTooltipMessageChange,
  onClientHideCheckboxChange,
  onFileImportedCheckboxChange,
  isFileInputDisabled,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.nameInput}>
        <LabeledInput
          title={"Название поля"}
          value={name}
          onValueChange={onNameChange}
          disabled={isFileInputDisabled}
        />
      </div>
      <div className={style.nameInput}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={isFileInputDisabled}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Импортировать файлы"}
          isChecked={isFileImported}
          onSwitch={onFileImportedCheckboxChange}
          disabled={isFileInputDisabled}
        />

        {isFileImported && (
          <div className={style.message}>
            Поддерживаемые типы файлов: .docx, .pptx, .xlsx, .pdf
          </div>
        )}
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          disabled={isFileInputDisabled}
        />
      </div>
    </div>
  );
}
