import * as React from "react";
import { DropTarget, DropTargetMonitor, ConnectDropTarget } from "react-dnd";
import { connect, Dispatch } from "react-redux";

import {
  UpdateParentBlockParams,
  ChangeBlockOrderParams,
} from "../../../../../store/brief/types";
import { BLOCK_PARENT_DRAG } from "../../../ItemType";

import { ParentBlockTarget } from "./ParentBlockTarget";
import { StoreState } from "../../../../../store";
import {
  updateParentBlock,
  changeBlockOrder,
  normalizeElementsOrders,
} from "../../../../../store/brief/actions";
import { getBriefSchemeId } from "../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps, DNDProps {
  id?: string;
  parent?: string;
  parents?: string[];
  order: number;
}

interface DNDProps {
  connectDropTarget?: ConnectDropTarget;
  itemType?: string;
  item?: any;
  isOver?: boolean;
}

interface MapProps {
  briefSchemaId?: string;
}

interface DispatchProps {
  updateParentBlock?: (params: UpdateParentBlockParams) => void;
  changeBlockOrder?: (params: ChangeBlockOrderParams) => void;
  normalizeElementsOrders?: () => void;
}

interface State {
  isShow: boolean;
}

const boxTarget = {
  drop(
    props: Props,
    monitor: DropTargetMonitor,
    component: ParentBlockTargetContainer
  ) {
    const itemType = monitor.getItemType();
    const item = monitor.getItem();

    component.updateBlock(item, itemType);
  },
};

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(DropTarget([BLOCK_PARENT_DRAG], boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  itemType: monitor.getItemType(),
  item: monitor.getItem(),
  isOver: monitor.isOver(),
})) as any)
export class ParentBlockTargetContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShow: false,
    };
  }

  public componentWillReceiveProps(newProps: Props) {
    const itemParent = newProps.item?.parent || null;
    const parent = newProps.parent || null;
    this.setState({
      isShow:
        newProps.itemType == BLOCK_PARENT_DRAG &&
        (newProps.item.id !== parent ||
          newProps.item.order !== newProps.order) &&
        !(itemParent === parent && newProps.item.order === newProps.order) &&
        newProps.item.id !== parent &&
        newProps.item.id !== newProps.id &&
        !(newProps.parents || []).find((id) => id === newProps.item.id),
    });
  }

  public render() {
    return React.createElement(ParentBlockTarget, {
      isHidden: !this.state.isShow,
      connectDropTarget: this.props.connectDropTarget,
      isOver: this.props.isOver,
    });
  }

  public async updateBlock(item: any, itemType: string) {
    const briefBlockId = this.props.parent || null;
    const itemParent = this.props.item.parent || null;
    const isReorder = briefBlockId === itemParent;

    if (!isReorder) {
      this.props.updateParentBlock({
        id: this.props.item.id,
        briefBlockId,
        order: this.props.order,
      });
    } else {
      this.props.changeBlockOrder({
        id: this.props.item.id,
        oldOrder: isReorder ? this.props.item.order : 0,
        newOrder:
          isReorder && this.props.order > this.props.item.order
            ? this.props.order - 1
            : this.props.order,
        briefBlockId,
      });
    }

    this.props.normalizeElementsOrders();
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    briefSchemaId: getBriefSchemeId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    updateParentBlock: (params: updateParentBlock) =>
      dispatch(updateParentBlock(params)),
    changeBlockOrder: (params: ChangeBlockOrderParams) =>
      dispatch(changeBlockOrder(params)),
    normalizeElementsOrders: () => dispatch(normalizeElementsOrders()),
  };
}
