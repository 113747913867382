import * as React from "react";

import { Action } from "../enums";
import { actions, buttonsComponentsMap } from "../utils";
import * as styles from "./styles.scss";

export interface Props {
  onButtonClick: (action: Action) => void;
}

export const ActionSelector: React.FC<Props> = ({ onButtonClick }) => {
  return (
    <div className={styles.root}>
      {actions.map((action) =>
        React.createElement(buttonsComponentsMap[action], {
          key: action,
          onClick: () => onButtonClick(action),
        })
      )}
    </div>
  );
};
