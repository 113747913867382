// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefListPage-TopLine-_TopLine__buttons{display:flex;align-items:center;justify-content:flex-end}.src-modules-BriefListPage-TopLine-_TopLine__button{display:block;width:160px;height:24px;text-decoration:none}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefListPage/TopLine/TopLine.scss"],"names":[],"mappings":"AAAA,qDACI,YAAa,CAEb,kBAAmB,CACnB,wBAAyB,CAC5B,oDAGG,aAAc,CAEd,WAAY,CACZ,WAAY,CAEZ,oBAAqB","sourcesContent":[".buttons {\n    display: flex;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.button {\n    display: block;\n\n    width: 160px;\n    height: 24px;\n\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "src-modules-BriefListPage-TopLine-_TopLine__buttons",
	"button": "src-modules-BriefListPage-TopLine-_TopLine__button"
};
export default ___CSS_LOADER_EXPORT___;
