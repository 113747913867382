import * as React from "react";

import * as style from "./TextareaCell.scss";

import { WithTooltip } from "sber-marketing-ui";

interface Props {
  title: string;
  customStyle?: any;
}

export const TextareaCell = ({ title, customStyle }: Props): JSX.Element => {
  return (
    <div className={style.root} style={{ ...customStyle }}>
      <WithTooltip content={title} className={style.tooltipWrapper}>
        <div className={style.title}>{title}</div>
      </WithTooltip>
    </div>
  );
};
