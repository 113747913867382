// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__root{background:#F6F6F6;border-radius:4px;padding:12px;display:grid;grid-template-columns:1fr 20px 1fr;grid-column-gap:11px;align-items:center}.src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__withMoreChildren{grid-template-columns:1fr 20px 1fr}.src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__withOneChildren{grid-template-columns:1fr}.src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__arrowIcon{color:#7E8681;line-height:0;justify-self:center;align-self:self-end;margin-bottom:4px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/TriggerSelector/styles.scss"],"names":[],"mappings":"AAAA,2EACI,kBAAmB,CACnB,iBAAkB,CAClB,YAAa,CAEb,YAAa,CACb,kCAAmC,CACnC,oBAAqB,CACrB,kBAAmB,CACtB,uFAGG,kCAAmC,CACtC,sFAGG,yBAA0B,CAC7B,gFAGG,aAAc,CACd,aAAc,CACd,mBAAoB,CACpB,mBAAoB,CACpB,iBAAkB","sourcesContent":[".root {\n    background: #F6F6F6;\n    border-radius: 4px;\n    padding: 12px;\n\n    display: grid;\n    grid-template-columns: 1fr 20px 1fr;\n    grid-column-gap: 11px;\n    align-items: center;\n}\n\n.withMoreChildren {\n    grid-template-columns: 1fr 20px 1fr;\n}\n\n.withOneChildren {\n    grid-template-columns: 1fr;\n}\n\n.arrowIcon {\n    color: #7E8681;\n    line-height: 0;\n    justify-self: center;\n    align-self: self-end;\n    margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__root",
	"withMoreChildren": "src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__withMoreChildren",
	"withOneChildren": "src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__withOneChildren",
	"arrowIcon": "src-modules-BriefPage-CalculationLogicPopup-TriggerSelector-_styles__arrowIcon"
};
export default ___CSS_LOADER_EXPORT___;
