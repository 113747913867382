import * as React from "react";
import { connect } from "react-redux";
import { PlainDictionary } from "@sm/types/budget";

import { ActivityTypeTable } from "./ActivityTypeTable";
import type { StoreState } from "../../../store";
import { getActivityTypePageState } from "../../../store/activityType/selector";

interface Props extends Partial<MapProps> {}

interface MapProps {
  activityTypes: PlainDictionary[];
}

@(connect(mapStateToProps, null) as any)
export class ActivityTypeTableContainer extends React.Component<Props> {
  public render(): JSX.Element {
    return React.createElement(ActivityTypeTable, {
      activityTypes: this.props.activityTypes,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    activityTypes: getActivityTypePageState(state).activityTypes.entities,
  };
}
