import * as React from "react";
import classNames from "classnames";

import * as style from "./DropdownContent.scss";

import {
  InputContainer,
  InputTheme_redesign,
  AutoFocusedInput,
  Icon,
  IconType,
  CustomScrollbar,
  Scrollbars,
} from "@sm/ui";

const AUTO_HEIGHT_MIN = 44;
const AUTO_HEIGHT_MAX = 320;
const VALUE_MAX_LENGTH = 100;

export interface DropdownContentProps {
  name: string;
  content: {
    id: string;
    value: string;
    hasFormulas?: boolean;
  }[];
  isOpen: boolean;
  isOnAddValue: boolean;
  canAddValue: boolean;
  canChangeValue: boolean;
  displayCalculateButton: boolean;
  customScrollbarRef: (element: Scrollbars) => void;
  onValueChange: (value: string, id: string) => void;
  onNewValueBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onOpenerClick: () => void;
  onAddButtonClick: () => void;
  onAddInputKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onEditInputKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSumButtonClick: (params: { id: string }) => void;
}

export function DropdownContent({
  name,
  content,
  isOpen,
  isOnAddValue,
  canAddValue,
  canChangeValue,
  displayCalculateButton,
  customScrollbarRef,
  onNewValueBlur,
  onValueChange,
  onOpenerClick,
  onAddButtonClick,
  onAddInputKeyUp,
  onEditInputKeyUp,
  onSumButtonClick,
}: DropdownContentProps): JSX.Element {
  return (
    <div className={classNames(style.root, isOpen && style.opened)}>
      <div className={style.opener} onClick={onOpenerClick}>
        <div className={style.title}>{name}</div>
        <div className={style.openerIcon}>
          <Icon
            type={isOpen ? IconType.ARROW24_UP : IconType.ARROW24_DOWN}
            svgSize="24"
          />
        </div>
      </div>

      <div className={style.content}>
        <CustomScrollbar
          scrollRef={customScrollbarRef}
          autoHide
          autoHeight
          autoHeightMin={AUTO_HEIGHT_MIN}
          autoHeightMax={AUTO_HEIGHT_MAX}
        >
          {content.map((item) => (
            <div key={item.id} className={style.inputWrapper}>
              <InputContainer
                name={item.id.toString()}
                value={item.value}
                theme={InputTheme_redesign.Simple}
                maxLength={VALUE_MAX_LENGTH}
                onChange={(value: string) => onValueChange(value, item.id)}
                onKeyUp={onEditInputKeyUp}
                disabled={!canChangeValue}
              />

              {displayCalculateButton ? (
                item.hasFormulas ? (
                  <div
                    className={style.sumIconWrapper}
                    onClick={() => onSumButtonClick({ id: item.id })}
                  >
                    <Icon type={IconType.SUM} />
                  </div>
                ) : (
                  <div
                    className={style.sumIconWrapper}
                    onClick={() => onSumButtonClick({ id: item.id })}
                  >
                    <Icon type={IconType.ADD_SUM} svgSize={25} />
                  </div>
                )
              ) : null}
            </div>
          ))}

          {canAddValue ? (
            isOnAddValue ? (
              <div className={style.addInputWrapper}>
                <AutoFocusedInput
                  name={"inputName"}
                  theme={InputTheme_redesign.Simple}
                  maxLength={VALUE_MAX_LENGTH}
                  onBlur={onNewValueBlur}
                  onKeyUp={onAddInputKeyUp}
                />
              </div>
            ) : (
              <div className={style.buttonWrapper} onClick={onAddButtonClick}>
                Добавить строку
              </div>
            )
          ) : null}
        </CustomScrollbar>
      </div>
    </div>
  );
}
