import {
  BriefScheme,
  BriefSchemeCreateParams,
  BriefSchemeUpdateParams,
} from "@sm/types/admin";

import { AxiosResponse, axios } from "../lib/axios";

export class BriefApi {
  public static async createScheme(
    params?: BriefSchemeCreateParams
  ): Promise<BriefScheme> {
    const res: AxiosResponse<BriefScheme> = await axios.post(
      "/api/brief/scheme",
      params
    );

    return res.data;
  }

  public static async updateScheme(
    id: string,
    params: BriefSchemeUpdateParams
  ): Promise<BriefScheme> {
    const res: AxiosResponse<BriefScheme> = await axios.put(
      `/api/brief/scheme/${id}`,
      params
    );

    return res.data;
  }

  public static async updateSchemeStatus(
    id: string,
    status: string
  ): Promise<void> {
    await axios.post(`/api/brief/scheme/${id}/status`, { status });
  }

  public static async updateSchemeDepartment(
    id: string,
    departmentId: string
  ): Promise<void> {
    await axios.put(`/api/brief/scheme/${id}/department`, { departmentId });
  }

  public static async getScheme(id: string): Promise<BriefScheme> {
    const res: AxiosResponse<BriefScheme> = await axios.get(
      `/api/brief/scheme/${id}`,
      {
        params: { activityCount: true },
      }
    );
    return res.data;
  }

  public static async getSchemes(
    organizationIds?: string
  ): Promise<BriefScheme[]> {
    const res: AxiosResponse<{ schemes: BriefScheme[] }> = await axios.get(
      "/api/brief/scheme",
      {
        params: {
          organizationIds,
          activityCount: true,
        },
      }
    );

    return res.data.schemes;
  }

  public static async deleteScheme(id: string): Promise<void> {
    await axios.delete(`/api/brief/scheme/${id}`);
  }
}
