import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import autobind from "autobind-decorator";

import {
  BriefSchemeBlockParams,
  BriefSchemeUpdateParams,
  BriefSchemeCreateParams,
} from "@sm/types/admin";
import { BlockParams, UpdateBriefPageParams } from "../../../store/brief/types";

import { TopLine } from "./TopLine";
import { StoreState } from "../../../store";
import {
  togglePreviewMode,
  resetBriefPage,
  updateBriefPage,
  rerollElementIds,
  setSelectedElement,
} from "../../../store/brief/actions";
import {
  getSchemeBlocks,
  getSelectedBlockId,
  getSelectedElementId,
} from "../../../store/brief/selector";
import { getLoginUser } from "../../../store/user/selector";
import { BriefApi } from "../../../api";

const DEFAULT_SCHEME_NAME = "Новый шаблон брифа";

interface Props
  extends Partial<MapProps & DispatchProps & RouteComponentProps<RouteParams>> {
  previousUrl: string;
  onSaveStart: () => void;
  onSaveEnd: () => void;
}

interface RouteParams {
  briefSchemeId?: string;
  action?: string;
}

interface MapProps {
  organizationId?: string;
  blocks?: BlockParams[];
  isPreviewModeEnabled?: boolean;
  isSchemeNoActive?: boolean;
  isSchemeActive?: boolean;
  isSchemeEditable?: boolean;
  isFormulaEditable?: boolean;
  briefName?: string;
  blocksDescription?: BriefSchemeBlockParams[];
  selectedBlockId?: string;
  selectedElementId?: string;
}

interface DispatchProps {
  togglePreviewMode: () => void;
  updateBriefPage: (params: UpdateBriefPageParams) => void;
  resetBriefPage: () => void;
  rerollElementIds: () => void;
  unsetSelectedElement: () => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TopLineContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { briefSchemeId, action } = this.props.match.params;

    return (
      <TopLine
        onSaveButtonClick={this.onSaveButtonClick}
        onCopyButtonClick={this.onCopyButtonClick}
        onCancelCopyButtonClick={this.onCancelCopyButtonClick}
        onPreviewModeToggleClick={this.onPreviewModeToggleClick}
        blocks={this.props.blocks}
        isPreviewModeEnabled={this.props.isPreviewModeEnabled}
        isSchemeEditable={this.props.isSchemeEditable}
        isFormulaEditable={this.props.isFormulaEditable}
        selectedBlockId={this.props.selectedBlockId}
        selectedElementId={this.props.selectedElementId}
        showCopyButton={
          briefSchemeId && action != "copy" && !this.props.isPreviewModeEnabled
        }
        showCancelCopyButton={briefSchemeId && action == "copy"}
      />
    );
  }

  @autobind
  protected async onSaveButtonClick(): Promise<void> {
    const { isSchemeActive, isSchemeNoActive } = this.props;
    const { briefSchemeId, action } = this.props.match.params;

    this.props.unsetSelectedElement();

    if (briefSchemeId && isSchemeActive) {
      if (isSchemeNoActive) {
        this.updateBriefScheme(briefSchemeId);
      } else {
        await this.props.rerollElementIds();
        this.createBriefScheme();
      }
    }

    if (briefSchemeId && !isSchemeActive && action != "copy") {
      this.updateBriefScheme(briefSchemeId);
    }

    if (briefSchemeId && action == "copy") {
      await this.props.rerollElementIds();

      this.createBriefScheme();
    }

    if (!briefSchemeId) {
      this.createBriefScheme();
    }
  }

  @autobind
  protected onCopyButtonClick(): void {
    const { briefSchemeId } = this.props.match.params;

    this.props.history.push(`/brief/scheme/${briefSchemeId}/copy`);
  }

  @autobind
  protected onCancelCopyButtonClick(): void {
    const { briefSchemeId } = this.props.match.params;

    this.props.history.push(`/brief/scheme/${briefSchemeId}`);
  }

  @autobind
  protected onPreviewModeToggleClick(): void {
    this.props.togglePreviewMode();
  }

  private async createBriefScheme(): Promise<void> {
    const { briefSchemeId } = this.props.match.params;
    const { blocks, organizationId, isSchemeActive } = this.props;

    const schemeUpdateParams: BriefSchemeCreateParams = {
      parentSchemeId: isSchemeActive ? briefSchemeId : undefined,
      organizationId,
      name: this.props.briefName || DEFAULT_SCHEME_NAME,
      blocks,
    };

    this.props.onSaveStart();

    const scheme = await BriefApi.createScheme(schemeUpdateParams);

    await this.updateBriefScheme(scheme.id);

    if (isSchemeActive) {
      await BriefApi.updateSchemeStatus(scheme.id, "active");

      this.props.updateBriefPage({
        activityCounts: 0,
      });
    }

    this.props.onSaveEnd();

    this.props.history.push(`/brief/scheme/${scheme.id}`);
  }

  private async updateBriefScheme(briefSchemeId: string): Promise<void> {
    const { blocks, organizationId } = this.props;

    const schemeUpdateParams: BriefSchemeUpdateParams = {
      organizationId,
      name: this.props.briefName || DEFAULT_SCHEME_NAME,
      blocks,
    };

    this.props.onSaveStart();

    await BriefApi.updateScheme(briefSchemeId, schemeUpdateParams);

    this.props.onSaveEnd();
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const {
    name,
    isPreviewModeEnabled,
    isSchemeEditable,
    activityCounts,
    isSchemeActive,
    isFormulaEditable,
  } = state.brief;
  const { organizationId } = getLoginUser(state);

  return {
    briefName: name,
    blocks: getSchemeBlocks(state),
    isPreviewModeEnabled,
    isFormulaEditable,
    isSchemeNoActive: activityCounts == 0,
    isSchemeActive,
    isSchemeEditable: isSchemeEditable || isSchemeActive,
    organizationId,
    selectedBlockId: getSelectedBlockId(state),
    selectedElementId: getSelectedElementId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    togglePreviewMode: () => dispatch(togglePreviewMode()),
    updateBriefPage: (params: UpdateBriefPageParams) =>
      dispatch(updateBriefPage(params)),
    resetBriefPage: () => dispatch(resetBriefPage()),
    rerollElementIds: () => dispatch(rerollElementIds()),
    unsetSelectedElement: () =>
      dispatch(
        setSelectedElement({
          selectedBlockId: null,
          selectedItemType: null,
          selectedElementId: null,
        })
      ),
  };
}
