/** Page data loading status */
export const enum LoadingStatus {
  /** Page data not loaded yet */
  NOT_LOADED = "not_loaded",
  /** Page data loading in progress */
  LOADING = "loading",
  /** Page data successfully loaded */
  LOADED = "loaded",
  /** Page data loading ended with error */
  ERROR = "error",
}
