// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__root{width:100%}.src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__propertyTitle{letter-spacing:1px;color:#404040;font-family:'Open Sans';font-size:8px;line-height:12px}.src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__inputProperty{margin-bottom:15px}.src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__dropdownProperty{margin-bottom:15px}.src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__checkboxProperty{margin-bottom:8px}.src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__checkboxProperty:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Menu/ElementMenu/DropdownMenu/DropdownMenu.scss"],"names":[],"mappings":"AAAA,yEACI,UAAW,CACd,kFAGG,kBAAmB,CAEnB,aAAc,CAEd,uBAAwB,CACxB,aAAc,CACd,gBAAiB,CACpB,kFAGG,kBAAmB,CACtB,qFAGG,kBAAmB,CACtB,qFAGG,iBAAkB,CADtB,gGAGQ,eAAgB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.propertyTitle {\n    letter-spacing: 1px;\n\n    color: #404040;\n\n    font-family: 'Open Sans';\n    font-size: 8px;\n    line-height: 12px;\n}\n\n.inputProperty {\n    margin-bottom: 15px;\n}\n\n.dropdownProperty {\n    margin-bottom: 15px;\n}\n\n.checkboxProperty {\n    margin-bottom: 8px;\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__root",
	"propertyTitle": "src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__propertyTitle",
	"inputProperty": "src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__inputProperty",
	"dropdownProperty": "src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__dropdownProperty",
	"checkboxProperty": "src-modules-BriefPage-Menu-ElementMenu-DropdownMenu-_DropdownMenu__checkboxProperty"
};
export default ___CSS_LOADER_EXPORT___;
