import {
  QueryParams,
  WorkTypeParams,
  WorkTypeCreateParams,
} from "@sm/types/admin";

import { axios } from "../lib/axios";

const WORK_TYPE_URL = "/api/work-type";

export class WorkTypeApi {
  public static async getWorkTypes(
    qyeryParams?: QueryParams
  ): Promise<WorkTypeParams[]> {
    const res = await axios.get(`${WORK_TYPE_URL}/`, {
      params: { ...qyeryParams },
    });

    return res.data;
  }

  public static async createWorkType(
    params: WorkTypeCreateParams
  ): Promise<WorkTypeParams> {
    const res = await axios.post(`${WORK_TYPE_URL}/`, { ...params });

    return {
      ...res.data,
      departments: [],
    };
  }

  public static async updateWorkType(
    workTypeId: string,
    name: string
  ): Promise<void> {
    await axios.put(`${WORK_TYPE_URL}/${workTypeId}`, { name });
  }

  public static async remove(workTypeId: string): Promise<{ error?: string }> {
    const res = await axios.delete(`${WORK_TYPE_URL}/${workTypeId}`);

    return res.data;
  }

  public static async addDepartment(
    workTypeId: string,
    departmentId: string
  ): Promise<WorkTypeParams> {
    const res = await axios.post(
      `${WORK_TYPE_URL}/${workTypeId}/department/${departmentId}`
    );

    return res.data;
  }

  public static async removeDepartment(
    workTypeId: string,
    departmentId: string
  ): Promise<WorkTypeParams> {
    const res = await axios.delete(
      `${WORK_TYPE_URL}/${workTypeId}/department/${departmentId}`
    );

    return res.data;
  }

  public static async enableRates(params: {
    workTypeId: string;
  }): Promise<WorkTypeParams> {
    const res = await axios.post(
      `${WORK_TYPE_URL}/${params.workTypeId}/enable-rates`
    );
    return res.data;
  }

  public static async disableRates(params: {
    workTypeId: string;
  }): Promise<WorkTypeParams> {
    const res = await axios.post(
      `${WORK_TYPE_URL}/${params.workTypeId}/disable-rates`
    );
    return res.data;
  }
}
