import * as React from "react";

import * as styles from "./BudgetsPage.scss";

import { Icon, IconType } from "sber-marketing-ui";
import { Page } from "../common/Page";
import { Table } from "./Table";
import { BudgetCreationModal } from "./BudgetCreationModal";

interface Props {
  preloader: boolean;
  pageLabel: string;
  archiveFilterEnabled: boolean;
  displayCreateBudgetModal: boolean;
  nextBudgetYear: number;
  onArchiveButtonClick: () => void;
  onCreateBudgetButtonClick: () => void;
  onModalCloseButtonClick: () => void;
  onCreateBudgetConfirm: () => void;
}

interface HeaderProps {
  archiveFilterEnabled: boolean;
  onArchiveButtonClick: () => void;
}

export function BudgetsPage({
  preloader,
  pageLabel,
  archiveFilterEnabled,
  displayCreateBudgetModal,
  nextBudgetYear,
  onArchiveButtonClick,
  onCreateBudgetButtonClick,
  onModalCloseButtonClick,
  onCreateBudgetConfirm,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      customFirstLine={() => (
        <Header
          archiveFilterEnabled={archiveFilterEnabled}
          onArchiveButtonClick={onArchiveButtonClick}
        />
      )}
      fixedLeftContent
      customLayout
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div
            className={styles.createBudgetButton}
            onClick={onCreateBudgetButtonClick}
          >
            <div className={styles.createBudgetButtonIcon}>
              <Icon type={IconType.PLUS_IN_CIRCLE} svgSize={17} />
            </div>
            Создать новый бюджет
          </div>

          <div className={styles.table}>
            <Table />
          </div>
        </div>

        {displayCreateBudgetModal && (
          <BudgetCreationModal
            year={nextBudgetYear}
            onConfirm={onCreateBudgetConfirm}
            onClose={onModalCloseButtonClick}
          />
        )}
      </div>
    </Page>
  );
}

const Header = ({
  archiveFilterEnabled,
  onArchiveButtonClick,
}: HeaderProps) => (
  <div className={styles.headerRoot}>
    <div className={styles.headerContent}>
      <div className={styles.headerTitle}>Управление бюджетом</div>
    </div>

    <div className={styles.buttonsWrapper}>
      <div className={styles.archiveButton} onClick={onArchiveButtonClick}>
        <div className={styles.archiveButtonIcon}>
          <Icon
            type={
              archiveFilterEnabled ? IconType.EYE_OPENED : IconType.EYE_CLOSED
            }
            svgSize={24}
          />
        </div>

        {archiveFilterEnabled
          ? "Скрыть архивные строки"
          : "Показать архивные строки"}
      </div>
    </div>
  </div>
);
