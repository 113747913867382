// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-UsersTable-_UsersTable__root{display:flex;width:100%}.src-modules-DepartmentRolePage-UsersTable-_UsersTable__cards{display:flex;margin-top:-40px;margin-left:-40px;flex-wrap:wrap}.src-modules-DepartmentRolePage-UsersTable-_UsersTable__card{margin-top:40px;margin-left:40px}\n", "",{"version":3,"sources":["webpack://./src/modules/DepartmentRolePage/UsersTable/UsersTable.scss"],"names":[],"mappings":"AAAA,6DACI,YAAa,CAEb,UAAW,CACd,8DAGG,YAAa,CAEb,gBAAiB,CACjB,iBAAkB,CAElB,cAAe,CAClB,6DAGG,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n}\n\n.cards {\n    display: flex;\n\n    margin-top: -40px;\n    margin-left: -40px;\n\n    flex-wrap: wrap;\n}\n\n.card {\n    margin-top: 40px;\n    margin-left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DepartmentRolePage-UsersTable-_UsersTable__root",
	"cards": "src-modules-DepartmentRolePage-UsersTable-_UsersTable__cards",
	"card": "src-modules-DepartmentRolePage-UsersTable-_UsersTable__card"
};
export default ___CSS_LOADER_EXPORT___;
