// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__root{display:flex;height:70px}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__column{width:50%;height:100%}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__column:first-child{padding-right:28px}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__column:last-child{padding-left:28px;border-left:1px solid #dcdcdc}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__title{color:#4a4a4a;font-size:12px}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__select{height:25px;margin-top:15px;border-bottom:1px solid #dcdcdc}.src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__dropdown{height:41px}\n", "",{"version":3,"sources":["webpack://./src/modules/DepartmentRolePage/EditUserForm/EditUserRow/EditUserRow.scss"],"names":[],"mappings":"AAAA,4EACI,YAAa,CAEb,WAAY,CACf,8EAGG,SAAU,CACV,WAAY,CAFhB,0FAKQ,kBAAmB,CAL3B,yFASQ,iBAAkB,CAElB,6BAA8B,CACjC,6EAID,aAAc,CAEd,cAAe,CAClB,8EAGG,WAAY,CACZ,eAAgB,CAEhB,+BAAgC,CACnC,gFAGG,WAAY","sourcesContent":[".root {\n    display: flex;\n\n    height: 70px;\n}\n\n.column {\n    width: 50%;\n    height: 100%;\n\n    &:first-child {\n        padding-right: 28px;\n    }\n\n    &:last-child {\n        padding-left: 28px;\n\n        border-left: 1px solid #dcdcdc;\n    }\n}\n\n.title {\n    color: #4a4a4a;\n\n    font-size: 12px;\n}\n\n.select {\n    height: 25px;\n    margin-top: 15px;\n\n    border-bottom: 1px solid #dcdcdc;\n}\n\n.dropdown {\n    height: 41px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__root",
	"column": "src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__column",
	"title": "src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__title",
	"select": "src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__select",
	"dropdown": "src-modules-DepartmentRolePage-EditUserForm-EditUserRow-_EditUserRow__dropdown"
};
export default ___CSS_LOADER_EXPORT___;
