import * as React from "react";
import classNames from "classnames";
import * as styles from "./styles.scss";

import { Dropdown, Item } from "./Dropdown";
import { Icon, IconType } from "sber-marketing-ui";

interface Props {
  selectors: Selector[];
}

interface Selector {
  label: string;
  disabled: boolean;
  selectedItemId?: string;
  items: Item[];
  onItemSelect: (item: Item) => void;
}

export const TriggerSelector: React.FC<Props> = ({ selectors }) => {
  const firstSelector = selectors[0];
  const secondSelector = selectors[1];

  return (
    <div
      className={classNames(
        styles.root,
        secondSelector && firstSelector
          ? styles.withMoreChildren
          : styles.withOneChildren
      )}
    >
      {firstSelector && (
        <Dropdown
          label={firstSelector.label}
          items={firstSelector.items}
          selectedItemId={firstSelector.selectedItemId}
          disabled={firstSelector.disabled}
          onItemSelect={firstSelector.onItemSelect}
        />
      )}

      {secondSelector && firstSelector && (
        <Icon
          className={styles.arrowIcon}
          type={IconType.MIDDLE_ARROW}
          svgSize={12}
        />
      )}

      {secondSelector && (
        <Dropdown
          label={secondSelector.label}
          items={secondSelector.items}
          selectedItemId={secondSelector.selectedItemId}
          disabled={secondSelector.disabled}
          onItemSelect={secondSelector.onItemSelect}
        />
      )}
    </div>
  );
};

export type { Selector as SelectorParams, Item };
