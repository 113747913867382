import { reducerWithInitialState } from "typescript-fsa-reducers";

import { LoadingStatus } from "../utils";

import * as actions from "./actions";
import {
  CalendarSharingState as State,
  SavePageDataPayload,
  SelectItemsPayload,
  SharingState,
} from "./types";

class Reducer {
  public static makeInitialState(): State {
    return {
      loadingStatus: LoadingStatus.NOT_LOADED,
      sharings: [],
      existingSharings: [],
      availableActivityTypes: [],
      availableBlocks: [],
    };
  }

  public static setLoadingStatus(
    state: State,
    loadingStatus: LoadingStatus
  ): State {
    return {
      ...state,
      loadingStatus,
    };
  }

  public static savePageData(
    state: State,
    payload: SavePageDataPayload
  ): State {
    const {
      sharings,
      existingSharings,
      availableActivityTypes,
      availableBlocks,
    } = payload;

    return {
      loadingStatus: LoadingStatus.LOADED,
      sharings,
      existingSharings,
      availableActivityTypes,
      availableBlocks,
    };
  }

  public static createSharing(state: State, organizationId: string): State {
    return Reducer.singleSharingReducer(
      state,
      organizationId,
      (sharing): SharingState => ({
        ...sharing,
        isCreated: true,
      })
    );
  }

  public static deleteSharing(state: State, organizationId: string): State {
    return Reducer.singleSharingReducer(
      state,
      organizationId,
      (sharing): SharingState => ({
        ...sharing,
        isCreated: false,
        activityTypes: {
          selected: sharing.activityTypes.selected,
          pending: sharing.activityTypes.selected,
        },
        blocks: {
          selected: sharing.blocks.selected,
          pending: sharing.blocks.selected,
        },
      })
    );
  }

  public static selectActivityTypes(
    state: State,
    payload: SelectItemsPayload
  ): State {
    const { organizationId, itemIds } = payload;

    return Reducer.singleSharingReducer(
      state,
      organizationId,
      (sharing): SharingState => ({
        ...sharing,
        activityTypes: {
          selected: sharing.activityTypes.selected,
          pending: itemIds,
        },
      })
    );
  }

  public static selectBlocks(state: State, payload: SelectItemsPayload): State {
    const { organizationId, itemIds } = payload;

    return Reducer.singleSharingReducer(
      state,
      organizationId,
      (sharing): SharingState => ({
        ...sharing,
        blocks: {
          selected: sharing.blocks.selected,
          pending: itemIds,
        },
      })
    );
  }

  public static transferPendingSharings(state: State): State {
    const { sharings } = state;

    const existingSharings = sharings
      .filter((sharing) => sharing.isCreated)
      .map((sharing) => sharing.organization.id);

    return {
      ...state,
      existingSharings,
    };
  }

  public static deleteSharingEntry(
    state: State,
    organizationId: string
  ): State {
    const { existingSharings } = state;

    const stateWithUpdatedSharing = Reducer.singleSharingReducer(
      state,
      organizationId,
      (sharing): SharingState => ({
        ...sharing,
        isCreated: false,
        activityTypes: {
          selected: [],
          pending: [],
        },
        blocks: {
          selected: [],
          pending: [],
        },
      })
    );

    return {
      ...stateWithUpdatedSharing,
      existingSharings: existingSharings.filter((id) => id !== organizationId),
    };
  }

  public static resetUnsavedSharings(state: State): State {
    const { sharings, existingSharings } = state;

    return {
      ...state,
      sharings: sharings.map((sharing) => ({
        ...sharing,
        isCreated: existingSharings.includes(sharing.organization.id),
        activityTypes: {
          selected: sharing.activityTypes.selected,
          pending: sharing.activityTypes.selected,
        },
        blocks: {
          selected: sharing.blocks.selected,
          pending: sharing.blocks.selected,
        },
      })),
    };
  }

  private static singleSharingReducer(
    state: State,
    organizationId: string,
    reducer: (sharing: SharingState) => SharingState
  ): State {
    const sharings = state.sharings.reduce((acc, sharing) => {
      const updatedSharing =
        sharing.organization.id === organizationId ? reducer(sharing) : sharing;

      return [...acc, updatedSharing];
    }, []);

    return {
      ...state,
      sharings,
    };
  }
}

export const calendarSharingReducer = reducerWithInitialState(
  Reducer.makeInitialState()
)
  .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
  .case(actions.savePageData, Reducer.savePageData)
  .case(actions.createSharing, Reducer.createSharing)
  .case(actions.deleteSharing, Reducer.deleteSharing)
  .case(actions.selectActivityTypes, Reducer.selectActivityTypes)
  .case(actions.selectBlocks, Reducer.selectBlocks)
  .case(actions.transferPendingSharings, Reducer.transferPendingSharings)
  .case(actions.deleteSharingEntry, Reducer.deleteSharingEntry)
  .case(actions.resetUnsavedSharings, Reducer.resetUnsavedSharings);
