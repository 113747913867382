import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import autobind from "autobind-decorator";

import { BriefScheme } from "@sm/types/admin";
import { BriefSchemeListPageLoadParams } from "../../store/briefSchemeListPage/types";

import { BriefSchemeListPage } from "./BriefSchemeListPage";
import { StoreState } from "../../store";
import {
  loadPageState,
  removeScheme,
} from "../../store/briefSchemeListPage/actions";
import { getFilteredBriefSchemeList } from "../../store/briefSchemeListPage/selector";
import { BriefApi, DepartmentApi } from "../../api";

interface Props extends DispatchProps {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
  briefSchemes?: BriefScheme[];
  onRemoveScheme(id: string): void;
}

interface MapProps {
  briefSchemes: BriefScheme[];
}

interface DispatchProps {
  loadPageState: (pageState: BriefSchemeListPageLoadParams) => void;
  removeScheme: (id: string) => void;
}

interface State {
  preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(DragDropContext(MultiBackend(HTML5toTouch)) as any)
export class BriefSchemeListPageContainer extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
    };
  }

  public async componentDidMount() {
    const [briefSchemes, departments] = await Promise.all([
      BriefApi.getSchemes(),
      DepartmentApi.getDepartmentList(),
    ]);

    this.props.loadPageState({
      briefSchemes,
      departments,
    });

    this.setState({
      preloader: false,
    });
  }

  public render(): JSX.Element {
    const { pageLabel, previousUrl, previousLabel } = this.props;

    return React.createElement(BriefSchemeListPage, {
      preloader: this.state.preloader,
      briefSchemes: this.props.briefSchemes,
      onRemoveScheme: this.handleRemoveScheme,
      pageLabel,
      previousUrl,
      previousLabel,
    });
  }

  @autobind
  protected handleRemoveScheme(id: string) {
    this.props.removeScheme(id);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    briefSchemes: getFilteredBriefSchemeList(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadPageState: (pageState: BriefSchemeListPageLoadParams) =>
      dispatch(loadPageState(pageState)),
    removeScheme: (id: string) => dispatch(removeScheme(id)),
  };
}
