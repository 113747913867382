import { actionCreatorFactory } from "typescript-fsa";
import { WorkTypeParams } from "@sm/types/admin";

import {
  WorkTypeLoadParams,
  AddDepartmentParams,
  RemoveDepartmentParams,
  UpdateWorkTypeParams,
  EnableRatesParams,
  DisableRatesParams,
} from "../types";

const actionCreator = actionCreatorFactory("ORGANIZATION_TYPE_PAGE");

const load = actionCreator<WorkTypeLoadParams>("LOAD");
const add = actionCreator<WorkTypeParams>("ADD");
const update = actionCreator<UpdateWorkTypeParams>("UPDATE");
const remove = actionCreator<string>("REMOVE");
const addDepartment = actionCreator<AddDepartmentParams>("ADD_DEPARTMENT");
const removeDepartment =
  actionCreator<RemoveDepartmentParams>("REMOVE_DEPARTMENT");
const setIsOnAdd = actionCreator<boolean>("SET_IS_ON_ADD");
const enableRates = actionCreator<EnableRatesParams>("ENABLE_RATES");
const disableRates = actionCreator<DisableRatesParams>("DISABLE_RATESa");

export {
  load,
  add,
  remove,
  addDepartment,
  removeDepartment,
  update,
  setIsOnAdd,
  enableRates,
  disableRates,
};
