import * as React from "react";

import * as styles from "./Text.scss";

interface Props {
  content: string | number;
}

export function Text({ content }: Props): JSX.Element {
  return (
    <div className={styles.root} title={String(content)}>
      <div className={styles.content}>{content}</div>
    </div>
  );
}
