import * as React from "react";

import {
  PermissionResponse,
  PermissionSubjectResponse,
  OrganizationView,
  DepartmentAttributes,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

import {
  // LabeledInput,
  // LabeledSelect,
  Button_redesign as Button,
  ButtonTheme_redesign,
  Preloader,
  Suggest,
} from "@sm/ui";

import * as styles from "./EditForm.scss";

export const enum Action {
  "EDIT" = "edit",
  "CREATE" = "create",
}

interface State extends PermissionResponse {
  preloader: boolean;
}

interface Props {
  action: Action;
  title: string;
  permission: PermissionResponse;
  preloader: boolean;
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
  onChange<T extends keyof State>(key: T, value: State[T]): void;
  onDictionaryChange: (value: string) => void;
  onSave: () => void;
}

export function EditForm({
  action,
  title,
  permission,
  preloader,
  budgets,
  organizations,
  departments,
  dictionaries,
  permissionSubjects,
  onChange,
  onDictionaryChange,
  onSave,
}: Props): JSX.Element {
  const budget = budgets[permission.budgetId];
  const organization = organizations[permission.organizationId];
  const department = departments[permission.departmentId];
  const dictionaryIds = permission.dictionaryIds;
  const subject = permissionSubjects[permission.subjectId];

  return (
    <div className={styles.root}>
      <>
        <div className={styles.editBlock}>
          <div className={styles.inputWrapper}>
            <Suggest
              title={"Бюджет"}
              onItemSelect={(item) =>
                onChange("budgetId", item.value as string)
              }
              selectedItem={
                budget && {
                  value: budget.id,
                  label: `${
                    organizations[budget.dictionaryOrganizationId].name
                  } | ${budget.year} | ${budget.status}`,
                }
              }
              items={Object.values(budgets).map((item) => ({
                value: item.id,
                label: `${
                  organizations[item.dictionaryOrganizationId].name
                } | ${item.year} | ${item.status}`,
              }))}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Suggest
              title={"Организация"}
              onItemSelect={(item) =>
                onChange("organizationId", item.value as string)
              }
              selectedItem={
                organization && {
                  value: organization.id,
                  label: organization.name,
                }
              }
              items={Object.values(organizations).map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Suggest
              title={"Департамент"}
              onItemSelect={(item) =>
                onChange("departmentId", item.value as string)
              }
              selectedItem={
                department && {
                  value: department.id,
                  label: department.name,
                }
              }
              items={
                !organization
                  ? []
                  : Object.values(departments)
                      .filter((item) => item.organizationId === organization.id)
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
              }
            />
          </div>

          <div className={styles.inputWrapper}>
            <Suggest
              title={"Словари"}
              onItemSelect={(item) => onDictionaryChange(item.value as string)}
              selectedItems={
                dictionaryIds &&
                dictionaryIds.map((id) => ({
                  value: id,
                  label: `${dictionaries[id].value} | ${dictionaries[id].type}`,
                }))
              }
              items={
                !budget
                  ? []
                  : Object.values(dictionaries)
                      .filter(
                        (item) =>
                          item.organizationId ===
                          budget.dictionaryOrganizationId
                      )
                      .map((item) => ({
                        value: item.id,
                        label: `${item.value} | ${item.type}`,
                      }))
              }
              multipleSelect
            />
          </div>

          <div className={styles.inputWrapper}>
            <Suggest
              title={"Субъект"}
              onItemSelect={(item) =>
                onChange("subjectId", item.value as number)
              }
              selectedItem={
                subject && {
                  value: subject.id,
                  label: subject.title,
                }
              }
              items={Object.values(permissionSubjects).map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </div>
        </div>
      </>

      <div className={styles.saveButtonWrapper}>
        <Button
          onClick={onSave}
          theme={ButtonTheme_redesign.Ghost}
          disabled={!permission.subjectId}
        >
          Сохранить
        </Button>
      </div>

      {preloader && <Preloader />}
    </div>
  );
}
