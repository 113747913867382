import { ConnectDragSource } from "react-dnd";

import { PreviewTemplates } from "./PreviewTemplates";

interface Props {
  itemType: string;
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export function ElementPreview({
  itemType,
  isDragging,
  connectDragSource,
}: Props): JSX.Element {
  return connectDragSource
    ? connectDragSource(PreviewTemplates.getPreview(itemType, isDragging))
    : PreviewTemplates.getPreview(itemType, isDragging, true);
}
