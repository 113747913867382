// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-Popup-_styles__root{width:576px;min-height:300px;color:#000000;border:1px solid #ffffff;border-radius:4px;background-color:#ffffff;padding:18px 24px;animation:75ms ease-out src-modules-BriefPage-CalculationLogicPopup-Popup-_styles__sliding-appearance}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/Popup/styles.scss"],"names":[],"mappings":"AAAA,iEACI,WAAY,CACZ,gBAAiB,CAEjB,aAAc,CACd,wBAAyB,CACzB,iBAAkB,CAClB,wBAAyB,CAEzB,iBAAkB,CAElB,qGAA2C","sourcesContent":[".root {\n    width: 576px;\n    min-height: 300px;\n\n    color: #000000;\n    border: 1px solid #ffffff;\n    border-radius: 4px;\n    background-color: #ffffff;\n\n    padding: 18px 24px;\n\n    animation: 75ms ease-out sliding-appearance;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-Popup-_styles__root",
	"sliding-appearance": "src-modules-BriefPage-CalculationLogicPopup-Popup-_styles__sliding-appearance"
};
export default ___CSS_LOADER_EXPORT___;
