import * as React from "react";

import * as style from "./Row.scss";

import { SelectMenu, SelectMenuItemParams } from "../common/SelectMenu";

type Permission = {
  id: string;
  budget: string;
  organization: string;
  department: string;
  dictionaries: string[];
  subject: string;
};
interface Props {
  permission: Permission;
  selectMenuItems: SelectMenuItemParams[];
}

export function Row({ permission, selectMenuItems }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.parentColumn}>
        <div className={style.dictionaryName} title={permission.budget}>
          {permission.budget}
        </div>
      </div>

      <div className={style.childrenColumn}>{permission.organization}</div>

      <div className={style.childrenColumn}>{permission.department}</div>

      <div className={style.childrenColumn}>
        {permission.dictionaries.join(", ")}
      </div>

      <div className={style.childrenColumn}>{permission.subject}</div>

      <div className={style.menuColumn}>
        <SelectMenu
          items={selectMenuItems}
          contentClass={style.selectMenuContent}
        />
      </div>
    </div>
  );
}
