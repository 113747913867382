import * as React from "react";
// import { useSelector } from 'react-redux';

import * as style from "./BudgetCreationModal.scss";

// import type { StoreState } from '../../../store';

import {
  Popup,
  LabeledSelect,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
  Icon,
  IconType,
} from "sber-marketing-ui";
// import { getBudgets } from '../../../store/budgetsPage/selectors';

interface Props {
  year: number;
  onConfirm: () => void;
  onClose: () => void;
}

export const BudgetCreationModal = ({
  year,
  onConfirm,
  onClose,
}: Props): JSX.Element => {
  // const [selectedBudget, setSelectedBudget] = React.useState<string>(null);
  // const budgets = useSelector((state: StoreState) => getBudgets(state));

  // console.log('selectedBudget', selectedBudget);

  // const selectItems = budgets.map(item => {
  //     const status = item.status === 'plan' || item.status === 'archive_plan'
  //         ? 'Планирование'
  //         : 'Исполнение';

  //     return {
  //         label: `${status} ${item.year}`,
  //         value: null
  //     };
  // });

  // selectItems.unshift({
  //     label: 'Без копирования бюджета',
  //     value: null
  // });

  return (
    <Popup onOutOfContentClick={onClose}>
      <div className={style.root}>
        <div className={style.title}>Создание нового бюджета</div>

        <div className={style.description}>
          Будет создан бюджет <b>Планирования {year}</b>
        </div>

        {/* <div className={style.description}>
                    Выберите бюджет, из которого будут скопированы настройки доступа, если это необходимо
                </div>

                <div className={style.budgetSelect}>
                    <LabeledSelect
                        title={'Бюджет'}
                        items={selectItems}
                        selectedValue={selectedBudget}
                        onSelection={value => setSelectedBudget(value as string)}
                        disableSearch
                    />
                </div> */}

        <div className={style.buttons}>
          <div className={style.button}>
            <Button theme={ButtonTheme.GhostRounded} onClick={onClose}>
              Отменить
            </Button>
          </div>

          <div className={style.button}>
            <Button theme={ButtonTheme.MainRounded} onClick={onConfirm}>
              Создать
            </Button>
          </div>
        </div>

        <div className={style.closeButton} onClick={onClose}>
          <Icon type={IconType.CROSS} svgSize={16} />
        </div>
      </div>
    </Popup>
  );
};
