// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-EditCalendarSharingPage-NewSharing-_NewSharing__root{padding:24px 48px 32px 48px;border-radius:4px;background-color:white;box-shadow:0 1px 4px 0 rgba(0,0,0,0.1)}\n", "",{"version":3,"sources":["webpack://./src/modules/EditCalendarSharingPage/NewSharing/NewSharing.scss"],"names":[],"mappings":"AAAA,kEACI,2BAA4B,CAE5B,iBAAkB,CAClB,sBAAuB,CACvB,sCAA0C","sourcesContent":[".root {\n    padding: 24px 48px 32px 48px;\n\n    border-radius: 4px;\n    background-color: white;\n    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-EditCalendarSharingPage-NewSharing-_NewSharing__root"
};
export default ___CSS_LOADER_EXPORT___;
