// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-RolePage-_RolePage__root{height:calc(100vh - 49px)}.src-modules-RolePage-_RolePage__filters{padding:40px 0}.src-modules-RolePage-_RolePage__content{padding:40px 0}.src-modules-RolePage-_RolePage__formWrapper{position:relative;z-index:1}.src-modules-RolePage-_RolePage__mask{position:fixed;z-index:0;top:0;left:0;width:100%;height:100%;cursor:pointer}.src-modules-RolePage-_RolePage__headerRoot{height:48px}.src-modules-RolePage-_RolePage__headerContent{display:flex;height:48px;align-items:center;justify-content:center}.src-modules-RolePage-_RolePage__buttonWrapper{width:136px;height:24px;margin-top:12px;margin-left:auto}\n", "",{"version":3,"sources":["webpack://./src/modules/RolePage/RolePage.scss"],"names":[],"mappings":"AAAA,sCACE,yBAA0B,CAC3B,yCAGC,cAAe,CAChB,yCAGC,cAAe,CAChB,6CAGC,iBAAkB,CAClB,SAAU,CACX,sCAGC,cAAe,CACf,SAAU,CACV,KAAM,CACN,MAAO,CAEP,UAAW,CACX,WAAY,CAEZ,cAAe,CAChB,4CAGC,WAAY,CACb,+CAGC,YAAa,CAEb,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CACxB,+CAGC,WAAY,CACZ,WAAY,CACZ,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n  height: calc(100vh - 49px);\n}\n\n.filters {\n  padding: 40px 0;\n}\n\n.content {\n  padding: 40px 0;\n}\n\n.formWrapper {\n  position: relative;\n  z-index: 1;\n}\n\n.mask {\n  position: fixed;\n  z-index: 0;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n\n  cursor: pointer;\n}\n\n.headerRoot {\n  height: 48px;\n}\n\n.headerContent {\n  display: flex;\n\n  height: 48px;\n\n  align-items: center;\n  justify-content: center;\n}\n\n.buttonWrapper {\n  width: 136px;\n  height: 24px;\n  margin-top: 12px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-RolePage-_RolePage__root",
	"filters": "src-modules-RolePage-_RolePage__filters",
	"content": "src-modules-RolePage-_RolePage__content",
	"formWrapper": "src-modules-RolePage-_RolePage__formWrapper",
	"mask": "src-modules-RolePage-_RolePage__mask",
	"headerRoot": "src-modules-RolePage-_RolePage__headerRoot",
	"headerContent": "src-modules-RolePage-_RolePage__headerContent",
	"buttonWrapper": "src-modules-RolePage-_RolePage__buttonWrapper"
};
export default ___CSS_LOADER_EXPORT___;
