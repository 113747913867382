import * as React from "react";
import { Provider } from "react-redux";

import { Router } from "../Router";
import { ErrorHandler } from "../ErrorHandler";
import { store } from "../../../store";

export function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ErrorHandler>
        <Router />
      </ErrorHandler>
    </Provider>
  );
}
