import * as React from "react";

import * as style from "./TextareaCell.scss";

import {
  Textarea_redesign as Textarea,
  Textarea_theme as TextareaTheme,
  WithTooltip,
} from "sber-marketing-ui";

interface Props {
  title: string;
  placeholder: string;
  customStyle?: any;
  onValueChange: (value: string) => void;
}

export const TextareaCellEdit = ({
  title,
  placeholder,
  customStyle,
  onValueChange,
}: Props): JSX.Element => {
  return (
    <div className={style.root} style={{ ...customStyle }}>
      <WithTooltip content={title} className={style.tooltipWrapper}>
        <Textarea
          value={title}
          placeholder={placeholder}
          theme={TextareaTheme.Borderless}
          onBlur={onValueChange}
          autoFocus
        />
      </WithTooltip>
    </div>
  );
};
