import * as React from "react";
import { DropTarget, DropTargetMonitor, ConnectDropTarget } from "react-dnd";
import { connect, Dispatch } from "react-redux";
import * as lodash from "lodash";

import { FieldType, SwitchType } from "@sm/types/frontend";
import {
  AddFieldParams,
  ElementPropertiesParams,
} from "../../../../../store/brief/types";
import { ItemType } from "../../../ItemType";

import { AddFieldTarget } from "./AddFieldTarget";
import { StoreState } from "../../../../../store";
import { addField } from "../../../../../store/brief/actions";
import { getBriefSchemeId } from "../../../../../store/brief/selector";

const FieldTypes = [
  ItemType.TEXT,
  ItemType.DROPDOWN,
  ItemType.SWITCH_GROUP,
  ItemType.UNLOCKABLE_INPUT,
  ItemType.FILE,
];

interface Props extends MapProps, DispatchProps, DNDProps {
  order: number;
  blockId: string;
  switchPropertyId?: string;
}

interface DNDProps {
  connectDropTarget?: ConnectDropTarget;
  itemType?: string;
  isOver?: boolean;
}

interface MapProps {
  briefSchemaId?: string;
}

interface DispatchProps {
  addField?: (params: AddFieldParams) => void;
}

interface State {
  isShow: boolean;
}

const boxTarget = {
  drop(
    props: Props,
    monitor: DropTargetMonitor,
    component: AddFieldTargetContainer
  ) {
    const itemType = monitor.getItemType() as ItemType | FieldType;

    component.addField(itemType);
  },
};

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(DropTarget(FieldTypes, boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  itemType: monitor.getItemType(),
  isOver: monitor.isOver(),
})) as any)
export class AddFieldTargetContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShow: false,
    };
  }

  public componentWillReceiveProps(newProps: Props) {
    this.setState({
      isShow: lodash.includes(FieldTypes, newProps.itemType),
    });
  }

  public render() {
    return React.createElement(AddFieldTarget, {
      isHidden: !this.state.isShow,
      connectDropTarget: this.props.connectDropTarget,
      isOver: this.props.isOver,
    });
  }

  public async addField(itemType: ItemType | FieldType) {
    this.props.addField({
      blockId: this.props.blockId,
      type: itemType,
      order: this.props.order,
      properties: this.getDefaultPropirties(itemType),
    });
  }

  private getDefaultPropirties(itemType: string): ElementPropertiesParams {
    switch (itemType) {
      case ItemType.TEXT:
        return {
          name: "",
          switchPropertyId: this.props.switchPropertyId,
          validationMessage: "",
        };
      case ItemType.FILE:
        return {
          name: "",
          switchPropertyId: this.props.switchPropertyId,
          validationMessage: "",
        };
      case ItemType.DROPDOWN:
        return {
          name: "",
          switchPropertyId: this.props.switchPropertyId,
          validationMessage: "",
        };
      case ItemType.UNLOCKABLE_INPUT:
        return {
          name: "",
          switchPropertyId: this.props.switchPropertyId,
          validationMessage: "",
        };
      case ItemType.SWITCH_GROUP:
        return {
          name: "",
          switchPropertyId: this.props.switchPropertyId,
          validationMessage: "",
          switchType: SwitchType.Checkbox,
        };
      default:
        return {};
    }
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    briefSchemaId: getBriefSchemeId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    addField: (params: AddFieldParams) => dispatch(addField(params)),
  };
}
