import * as React from "react";

import type { Point } from "../../types";

import * as style from "./CellEditorWrapper.scss";

import { BodyPortal } from "sber-marketing-ui";

export interface Direction {
  horizontal: HorizontalDirection;
  vertical: VerticalDirection;
}

export const enum HorizontalDirection {
  Left = "left",
  Right = "right",
}

export const enum VerticalDirection {
  Up = "up",
  Down = "down",
}

interface Props {
  children: React.ReactNode;
  direction: Direction;
  position: Point;
  rootRef: React.RefObject<HTMLDivElement>;
}

export const CellEditorWrapperTemplate = ({
  children,
  direction,
  position,
  rootRef,
}: Props): JSX.Element => {
  return (
    <div className={style.root} ref={rootRef}>
      {position && (
        <BodyPortal>
          <div
            className={style.wrapper}
            style={{
              top: position.y,
              left: position.x,
              transform: `
                                ${
                                  direction.vertical == VerticalDirection.Up
                                    ? "translateY(-100%)"
                                    : ""
                                }
                                ${
                                  direction.horizontal ==
                                  HorizontalDirection.Left
                                    ? "translateX(-100%)"
                                    : ""
                                }
                            `,
            }}
          >
            {children}
          </div>
        </BodyPortal>
      )}
    </div>
  );
};
