import * as React from "react";
import { connect } from "react-redux";

import { ItemType } from "../../ItemType";

import { BlockMenu } from "./BlockMenu";
import { TextMenu } from "./TextMenu";
import { DropdownMenu } from "./DropdownMenu";
import { SwitchGroupMenu } from "./SwitchGroupMenu";
import { UnlockableInputMenu } from "./UnlockableInputMenu";
import { FileMenu } from "./FileMenu";
import { StoreState } from "../../../../store";
import {
  getSelectedElementId,
  getSelectedBlockId,
  getSelectedElementType,
} from "../../../../store/brief/selector";

export interface Props extends MapProps {}

interface MapProps {
  selectedElementId?: string;
  selectedBlockId?: string;
  selectedItemType?: string;
}

@(connect(mapStateToProps, null) as any)
export class ElementMenuContainer extends React.Component<Props> {
  public render() {
    switch (this.props.selectedItemType) {
      case ItemType.BLOCK:
        return React.createElement(BlockMenu, {
          id: this.props.selectedBlockId,
        });
      case ItemType.TEXT:
        return React.createElement(TextMenu, {
          id: this.props.selectedElementId,
        });
      case ItemType.DROPDOWN:
        return React.createElement(DropdownMenu, {
          id: this.props.selectedElementId,
        });
      case ItemType.SWITCH_GROUP:
        return React.createElement(SwitchGroupMenu, {
          id: this.props.selectedElementId,
        });
      case ItemType.UNLOCKABLE_INPUT:
        return React.createElement(UnlockableInputMenu, {
          id: this.props.selectedElementId,
        });
      case ItemType.FILE:
        return React.createElement(FileMenu, {
          id: this.props.selectedElementId,
        });
      default:
        return null;
    }
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    selectedElementId: getSelectedElementId(state),
    selectedBlockId: getSelectedBlockId(state),
    selectedItemType: getSelectedElementType(state),
  };
}
