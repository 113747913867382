import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import * as lodash from "lodash";

import type { DepartmentAttributes } from "@sm/types/admin";
import type { DepartmentListPageLoadParams } from "../../store/departmentListPage/types";

import { DepartmentListPage } from "./DepartmentListPage";
import type { StoreState } from "../../store";
import { loadPageState } from "../../store/departmentListPage/actions";
import { getRootDepartment } from "../../store/departmentListPage/selector";
import { DepartmentApi } from "../../api";

interface Props extends DispatchProps {
  pageLabel: string;
  previousUrl: string;
  previousLabel: string;
  preloader: boolean;
  departments?: DepartmentAttributes[];
}

interface MapProps {
  departments: DepartmentAttributes[];
}

interface DispatchProps {
  loadPageState: (pageState: DepartmentListPageLoadParams) => void;
}

interface State {
  preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DepartmentListPageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
    };
  }

  public async componentDidMount() {
    const departments = await DepartmentApi.getDepartmentList({
      embed: "subDepartments,userCount",
    });

    const rootDepartment = departments.find(
      (item) => item.parentDepartmentId == null
    );

    this.props.loadPageState({
      departments: departments.filter(
        (item) =>
          (rootDepartment && item.parentDepartmentId == rootDepartment.id) ||
          item.parentDepartmentId == null
      ),
    });

    this.setState({
      preloader: false,
    });
  }

  public render(): JSX.Element {
    const { pageLabel, departments, previousUrl, previousLabel } = this.props;

    return React.createElement(DepartmentListPage, {
      preloader: this.state.preloader,
      previousUrl,
      previousLabel,
      pageLabel,
      departments,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    departments: lodash.compact([getRootDepartment(state)]),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadPageState: (pageState: DepartmentListPageLoadParams) =>
      dispatch(loadPageState(pageState)),
  };
}
