import * as React from "react";
import { OperationType } from "./enums";

import {
  AdditionButton,
  DivisionButton,
  MultiplicationButton,
  SubtractionButton,
} from "./ButtonsGroup";
import type { ButtonProps } from "./ButtonsGroup";

export { EmptyButton } from "./ButtonsGroup";

export const operationTypeNamesMap: Record<OperationType, string> = {
  [OperationType.ADDITION]: "Сложение",
  [OperationType.SUBTRACTION]: "Вычетание",
  [OperationType.MULTIPLICATION]: "Умножение",
  [OperationType.DIVISION]: "Деление",
};

export const operations: OperationType[] = [
  OperationType.ADDITION,
  OperationType.SUBTRACTION,
  OperationType.MULTIPLICATION,
  OperationType.DIVISION,
];

export const buttonsComponentsMap: Record<
  OperationType,
  React.FC<ButtonProps>
> = {
  [OperationType.ADDITION]: AdditionButton,
  [OperationType.SUBTRACTION]: SubtractionButton,
  [OperationType.MULTIPLICATION]: MultiplicationButton,
  [OperationType.DIVISION]: DivisionButton,
};
