import * as React from "react";
import { Popup as PopupUI, PopupTheme, Preloader } from "sber-marketing-ui";

import * as styles from "./styles.scss";

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

export const Popup: React.FC<Props> = ({ loading, children }) => {
  return (
    <PopupUI theme={PopupTheme.bindActivityTheme}>
      <div className={styles.root}>{loading ? <Preloader /> : children}</div>
    </PopupUI>
  );
};
