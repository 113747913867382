import * as React from "react";

import { CancelButton } from "./CancelButton";
import { SaveButton } from "./SaveButton";

import * as styles from "./styles.scss";

export interface Props {
  isDisabledBindButton: boolean;
  onCancelButtonClick(): void;
  onSaveButtonClick(): void;
}

export const Footer: React.FC<Props> = ({
  onCancelButtonClick,
  onSaveButtonClick,
  isDisabledBindButton,
}) => {
  return (
    <div className={styles.root}>
      <CancelButton onClick={onCancelButtonClick} />
      <SaveButton disabled={isDisabledBindButton} onClick={onSaveButtonClick} />
    </div>
  );
};
