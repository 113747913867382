import * as React from "react";
import { scroller } from "react-scroll";
import autobind from "autobind-decorator";

import type { NavItem } from "./ContentNav";
import { ContentNav } from "./ContentNav";

const SCROLL_OFFSET = -90;
const SCROLL_DURATION = 500;

interface Props {
  items?: NavItem[];
}

interface State {
  selectedIndex: number;
}

export class ContentNavContainer extends React.PureComponent<Props, State> {
  private scrollContainer: HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };
  }

  public componentDidMount() {
    this.scrollContainer = document.getElementById("pageContent");

    this.scrollContainer.addEventListener("scroll", this.handleScroll);
  }

  public componentWillUnmount() {
    this.scrollContainer.removeEventListener("scroll", this.handleScroll);
  }

  public render(): JSX.Element {
    return React.createElement(ContentNav, {
      items: this.props.items,
      selectedIndex: this.state.selectedIndex,
      onItemClick: this.handleItemClick,
    });
  }

  @autobind
  protected handleItemClick(id: string) {
    this.scrollTo(`section ${id}`);
  }

  @autobind
  private handleScroll() {
    const newIndex = this.getNewSelectedIndex();

    if (newIndex != this.state.selectedIndex) {
      this.setState({
        selectedIndex: newIndex,
      });
    }
  }

  private getNewSelectedIndex(): number {
    const sections = document.querySelectorAll(".section");

    const sectionsOffsets = Array.from(sections).map(
      (item) => item.getBoundingClientRect().top
    );
    let closestOffset: number = null;

    const result = sectionsOffsets.reduce((acc, item, index) => {
      let result = acc;
      if (
        closestOffset == null ||
        Math.abs(item + SCROLL_OFFSET) <= Math.abs(closestOffset)
      ) {
        closestOffset = item;
        result = index;
      }

      return result;
    }, 0);

    return result;
  }

  private scrollTo(name: string) {
    const options = {
      offset: SCROLL_OFFSET,
      smooth: "easeOutQuad",
      duration: SCROLL_DURATION,
      containerId: "pageContent",
    };

    scroller.scrollTo(name, options);
  }
}
