// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__root{width:100%}.src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__nameInput{width:100%}.src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__checkboxProperty{margin-top:15px;margin-bottom:8px}.src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__checkboxProperty:last-child{margin-bottom:0}.src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__message{letter-spacing:1px;color:#404040;font-family:'Open Sans';font-size:8px;line-height:12px;margin-bottom:8px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Menu/ElementMenu/FileMenu/FileMenu.scss"],"names":[],"mappings":"AAAA,iEACI,UAAW,CACd,sEAGG,UAAW,CACd,6EAGG,eAAgB,CAChB,iBAAkB,CAFtB,wFAIQ,eAAgB,CACnB,oEAID,kBAAmB,CACnB,aAAc,CACd,uBAAwB,CACxB,aAAc,CACd,gBAAiB,CACjB,iBAAkB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.nameInput {\n    width: 100%;\n}\n\n.checkboxProperty {\n    margin-top: 15px;\n    margin-bottom: 8px;\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.message {\n    letter-spacing: 1px;\n    color: #404040;\n    font-family: 'Open Sans';\n    font-size: 8px;\n    line-height: 12px;\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__root",
	"nameInput": "src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__nameInput",
	"checkboxProperty": "src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__checkboxProperty",
	"message": "src-modules-BriefPage-Menu-ElementMenu-FileMenu-_FileMenu__message"
};
export default ___CSS_LOADER_EXPORT___;
