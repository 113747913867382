import * as React from "react";
import * as classnames from "classnames";
import {
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
  SberPageLabel,
} from "@sm/ui";

import * as style from "./CustomFirstLine.scss";

/** "CustomFirstLine" component properties */
export interface CustomFirstLineProps {
  /** Page label */
  pageLabel: string;
  /** Additional CSS class for right element */
  additionalRightCssClass?: string;
  /** Children */
  children?: React.ReactNode;
}

/** Custom first line for header with right additional content */
export const CustomFirstLine: React.SFC<CustomFirstLineProps> = ({
  pageLabel,
  additionalRightCssClass,
  children,
}: CustomFirstLineProps) => (
  <ResponsiveLayout>
    <LeftSidebar />

    <CentralContent>
      <SberPageLabel pageLabel={pageLabel} />
    </CentralContent>

    <RightSidebar>
      <div className={classnames(style.rightContent, additionalRightCssClass)}>
        {children}
      </div>
    </RightSidebar>
  </ResponsiveLayout>
);

CustomFirstLine.displayName = "CustomFirstLine";
