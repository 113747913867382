import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import type { RoleUser } from "@sm/types/admin";
import type { DepartmentUserParams } from "../../../../store/departmentRolePage/types";

import { UserCard } from "./UserCard";
import type { StoreState } from "../../../../store";
import { removeUser } from "../../../../store/departmentRolePage/actions";
import {
  getRoleListByUserId,
  getSelectedDepartmentId,
  getDepartmentUser,
} from "../../../../store/departmentRolePage/selector";
import { DepartmentApi } from "../../../../api";

interface Props extends MapProps, DispatchProps {
  id: number;
}

interface MapProps {
  departmentUser?: DepartmentUserParams;
  roleList?: RoleUser[];
  selectedDepartmentId?: string;
}

interface DispatchProps {
  removeUser?: (id: number) => void;
}

interface State {
  isEditOpen: boolean;
  isEditRoleOpen: boolean;
  subordinateNames: string[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class UserCardContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditOpen: false,
      isEditRoleOpen: false,
      subordinateNames: null,
    };
  }

  public render(): JSX.Element {
    const selectMenuParams = [
      {
        label: "Посмотреть профиль",
        onClick: this.handleEditUserClick,
      },
      {
        label: "Изменить роль",
        onClick: this.handleChangeUserRoleClick,
      },
      {
        label: "Удалить из департамента",
        onClick: this.handleRemoveUserClick,
      },
    ];

    return React.createElement(UserCard, {
      userId: this.props.id,
      fullName: `${this.props.departmentUser.firstName} ${this.props.departmentUser.secondName}`,
      roles: this.props.roleList,
      selectMenuParams,
      isEditOpen: this.state.isEditOpen,
      isEditRoleOpen: this.state.isEditRoleOpen,
      onConfirmChangeRole: this.handleOnChangeUserRole,
      onCancelClick: this.handleCancelClick,
    });
  }

  @autobind
  protected handleCancelClick() {
    this.setState({
      isEditOpen: false,
    });
  }

  @autobind
  protected handleEditUserClick() {
    this.setState({
      isEditOpen: true,
    });
  }

  @autobind
  protected handleChangeUserRoleClick() {
    this.setState({
      isEditRoleOpen: true,
    });
  }

  @autobind
  protected async handleRemoveUserClick() {
    await DepartmentApi.removeUser(
      this.props.selectedDepartmentId,
      this.props.id
    );

    this.props.removeUser(this.props.id);
  }

  @autobind
  protected handleOnChangeUserRole() {
    this.setState({
      isEditRoleOpen: false,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    departmentUser: getDepartmentUser(state, ownProps.id),
    roleList: getRoleListByUserId(state, ownProps.id),
    selectedDepartmentId: getSelectedDepartmentId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    removeUser: (id: number) => dispatch(removeUser(id)),
  };
}
