// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-WorkType-TopLine-_TopLine__buttons{display:flex;width:100%;height:48px;align-items:center;justify-content:flex-end}.src-modules-WorkType-TopLine-_TopLine__button{width:120px;height:24px}\n", "",{"version":3,"sources":["webpack://./src/modules/WorkType/TopLine/TopLine.scss"],"names":[],"mappings":"AAAA,gDACI,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,wBAAyB,CAC5B,+CAGG,WAAY,CACZ,WAAY","sourcesContent":[".buttons {\n    display: flex;\n\n    width: 100%;\n    height: 48px;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.button {\n    width: 120px;\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "src-modules-WorkType-TopLine-_TopLine__buttons",
	"button": "src-modules-WorkType-TopLine-_TopLine__button"
};
export default ___CSS_LOADER_EXPORT___;
