import { DepartmentAttributes, DepartmentQuery } from "@sm/types/admin";

import { axios } from "../lib/axios";

const DEPARTMENT_URL = "/api/departments";

export class DepartmentApi {
  // Department

  public static async createDepartment(
    name: string,
    parentDepartmentId: string
  ): Promise<DepartmentAttributes> {
    const res = await axios.post(`${DEPARTMENT_URL}`, {
      name,
      parentDepartmentId,
    });

    return res.data;
  }

  public static async createSubDepartment(
    departmentId: string,
    name: string,
    organizationId: string
  ): Promise<DepartmentAttributes> {
    const res = await axios.post(
      `${DEPARTMENT_URL}/${departmentId}/sub-departments`,
      { name, organizationId }
    );

    return res.data;
  }

  public static async getDepartmentList(
    query?: DepartmentQuery
  ): Promise<DepartmentAttributes[]> {
    const res = await axios.get(`${DEPARTMENT_URL}`, { params: { ...query } });

    return res.data;
  }

  public static async getDepartment(
    departmentId: string
  ): Promise<DepartmentAttributes> {
    const res = await axios.get(`${DEPARTMENT_URL}/${departmentId}`);

    return res.data;
  }

  public static async updateDepartment(
    departmentId: string,
    name: string
  ): Promise<DepartmentAttributes> {
    const res = await axios.put(`${DEPARTMENT_URL}/${departmentId}`, { name });

    return res.data;
  }

  public static async deleteSubDepartment(
    departmentId: string,
    subDepartmentId: string
  ): Promise<void> {
    await axios.delete(
      `${DEPARTMENT_URL}/${departmentId}/sub-departments/${subDepartmentId}`
    );
  }

  // User

  public static async updateUserRole(
    departmentId: string,
    roleIds: number[],
    userId: number
  ): Promise<void> {
    await axios.put(`${DEPARTMENT_URL}/${departmentId}/user/${userId}/role`, {
      roleIds,
    });
  }

  public static async removeUser(
    departmentId: string,
    userId: number
  ): Promise<void> {
    await axios.delete(`${DEPARTMENT_URL}/${departmentId}/user/${userId}`);
  }
}
