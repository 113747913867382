import { actionCreatorFactory } from "typescript-fsa";
import type { PlainDictionary } from "@sm/types/budget";

import type { ActivityTypePageState, UpdateActivityTypeParams } from "../types";

const actionCreator = actionCreatorFactory("ACTIVITY_TYPE_PAGE");

const loadPageState = actionCreator<ActivityTypePageState>("LOAD");
const addActivityType = actionCreator<PlainDictionary>("ADD_ACTIVITY_TYPE");
const updateActivityType = actionCreator<UpdateActivityTypeParams>(
  "UPDATE_ACTIVITY_TYPE"
);
const removeActivityType = actionCreator<string>("REMOVE_ACTIVITY_TYPE");

export {
  loadPageState,
  addActivityType,
  updateActivityType,
  removeActivityType,
};
