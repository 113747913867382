import * as React from "react";
import { connect } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { StoreState } from "../../../store";
import type {
  SortingParams,
  Filter,
} from "../../../store/budgetAccessPage/types";
import {
  ColumnName,
  ColumnHeaderType,
  ColumnType,
  OrderType,
} from "../../../store/budgetAccessPage/types";
import type {
  CellParams,
  CellPosition,
  ColumnHeaderParams,
  ColumnWidths,
  LineId,
} from "./types";
import { CellEvent } from "./types";

import { TableTemplate } from "./TableTemplate";
import { TableViewModel } from "./TableViewModel";
import { TableView } from "./TableView";
import {
  getPageData,
  getSortingParams,
  getFilters,
} from "../../../store/budgetAccessPage/selectors";
import {
  tableColumns,
  leftFixedColumns,
  rightFixedColumns,
  readOnlyColumns,
  ColumnsConfig,
  ColumnHeaderComponentsByType,
  CellComponentsByColumnType,
  AccessorParams,
} from "./ColumnsConfig";
import {
  MrmClient,
  BudgetAccess,
  BudgetAccessLine,
  BudgetAccessCreateParams,
  BudgetLine,
  Department,
  Organization,
  RoleDomain,
  Dictionary,
  Action,
} from "../../../api";
import { Loader, Saver } from "./modules";

const columnWidths: ColumnWidths = lodash.mapValues(
  ColumnsConfig,
  (item) => item.defaultWidth
);

interface Props extends Partial<MapProps> {
  budgetId: string;
}

interface MapProps {
  budgetAccessLines: BudgetAccessLine[];
  dictionaries: Dictionary[];
  organizations: Organization[];
  departments: Department[];
  budgets: BudgetLine[];
  roles: RoleDomain[];
  actions: Action[];
  sortingParams: SortingParams;
  filters: Filter[];
}

interface State {
  tableLineIds: LineId[];
  newLineCreation: boolean;
}

@(connect(mapStateToProps, null) as any)
export class TableBehaviour extends React.PureComponent<Props, State> {
  private viewModel: TableViewModel;
  private table: TableView;
  private newLineFields: { [columnName: string]: any } = {};
  private loader: Loader;
  private saver: Saver;

  public constructor(props: Props) {
    super(props);

    this.state = {
      tableLineIds: [],
      newLineCreation: false,
    };

    this.viewModel = new TableViewModel({
      makeColumnHeaderParams: this.makeColumnHeaderParams,
      makeCellParams: this.makeCellParams,
    });

    this.loader = Loader.getInstance();
    this.saver = Saver.getInstance();
  }

  public async componentDidMount() {
    await this.loader.init(this.props.budgetId);
    this.loader.subscribeBudgetAccessLinesChanges(
      this.onBudgetAccessDomainUpdate
    );
    this.updateLineIds();
  }

  public componentDidUpdate(prevProps: Props) {
    const sortingParamsChanged =
      this.props.sortingParams !== prevProps.sortingParams;
    const filtersChanged = this.props.filters !== prevProps.filters;

    if (sortingParamsChanged || filtersChanged) {
      this.updateLineIds();
    }
  }

  public render(): JSX.Element {
    const { tableLineIds, newLineCreation } = this.state;

    return React.createElement(TableTemplate, {
      viewModel: this.viewModel,
      tableColumns,
      leftFixedColumns,
      rightFixedColumns,
      readOnlyColumns,
      tableLineIds,
      columnWidths,
      newLineCreation,
      tableRef: this.tableRef,
      onAddButtonClick: this.onAddButtonClick,
      onCellEvent: this.onCellEvent,
    });
  }

  @autobind
  protected tableRef(component: TableView) {
    this.table = component ? (component as any).getInstance() : null;
  }

  @autobind
  protected async onAddButtonClick() {
    this.startNewLineCreation();
  }

  @autobind
  protected async onCreateButtonClick() {
    await this.createBudgetAccessLine();
    await this.loader.loadBudgetAccessLines();
    this.loader.subscribeBudgetAccessLinesChanges(
      this.onBudgetAccessDomainUpdate
    );
    this.updateLineIds();
    this.endNewLineCreation();
  }

  @autobind
  protected async onCancelCreationButtonClick() {
    this.endNewLineCreation();
  }

  @autobind
  protected async onCopyButtonClick(lineId: LineId) {
    this.startNewLineCreation();

    const columns = [
      ColumnName.Budget,
      ColumnName.Organization,
      ColumnName.Department,
      ColumnName.Role,
      ColumnName.RightGroup,
    ];

    columns.forEach((columnName) => {
      this.newLineFields[columnName] = this.getCellValue({
        columnName,
        lineId,
      });

      this.updateCell({ columnName, lineId: "new" }, false);
    });

    this.newLineFields[ColumnName.RightGroup] = this.newLineFields[
      ColumnName.RightGroup
    ]?.map(({ ruleId, ...rightGroupItem }: any) => ({
      ...rightGroupItem,
    }));
  }

  @autobind
  protected async onDeleteConfirm(lineId: LineId) {
    await this.deleteBudgetAccessLine(lineId);
    await this.loader.loadBudgetAccessLines();
    this.loader.subscribeBudgetAccessLinesChanges(
      this.onBudgetAccessDomainUpdate
    );
    this.updateLineIds();
  }

  @autobind
  protected async onSaveButtonClick(lineId: LineId) {
    await this.saver.saveBudgetAccessLine(lineId);
  }

  @autobind
  protected async onCancelEditButtonClick(lineId: LineId) {
    await this.saver.resetBudgetAccessLineChanges(lineId);
  }

  @autobind
  protected onBudgetAccessDomainUpdate(line: Partial<BudgetAccess>) {
    this.updateLine(line.id);
  }

  @autobind
  protected getCellValue(cellPosition: CellPosition) {
    const { lineId, columnName } = cellPosition;

    const lineIsNew = lineId === "new";

    if (lineIsNew) {
      return this.newLineFields[columnName];
    }

    const accessorParams = this.makeAccessorParams(lineId);

    return ColumnsConfig[columnName].getValue(accessorParams);
  }

  @autobind
  protected getCellItems(
    cellPosition: CellPosition
  ): { title: React.ReactText; value: any }[] {
    const { lineId, columnName } = cellPosition;

    const accessorParams = this.makeAccessorParams(lineId);

    return ColumnsConfig[columnName].getItems(accessorParams) || [];
  }

  @autobind
  protected makeValueChangeHandler(
    cellPosition: CellPosition,
    closeEditorOnChange: boolean
  ) {
    return async (value: any) => {
      const { lineId, columnName } = cellPosition;

      const lineIsNew = lineId === "new";

      if (lineIsNew) {
        this.newLineFields[columnName] = value;

        if (ColumnsConfig[columnName].linkedColumns) {
          ColumnsConfig[columnName].linkedColumns.forEach((item) => {
            this.newLineFields[item] = null;
          });
        }

        this.updateLine(lineId);
      } else {
        const accessorParams = this.makeAccessorParams(lineId);

        await ColumnsConfig[columnName].setValue(accessorParams, value);

        if (ColumnsConfig[columnName].linkedColumns) {
          ColumnsConfig[columnName].linkedColumns.forEach(async (item) => {
            const cellPosition = { lineId, columnName: item };

            const defaultValue: any =
              ColumnsConfig[item].type === ColumnType.CheckboxList ? [] : null;

            await ColumnsConfig[item].setValue(accessorParams, defaultValue);

            this.updateCell(cellPosition, false);
          });
        }
      }
    };
  }

  @autobind
  protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
    switch (eventType) {
      case CellEvent.EditStart:
        this.updateCell(position, true);
        break;

      case CellEvent.EditEnd:
        this.updateCell(position, false);
        break;

      case CellEvent.Selection:
        this.updateCell(position, false);
        break;
    }
  }

  @autobind
  private updateLine(lineId: LineId) {
    const allColumns = [
      ...tableColumns,
      ...leftFixedColumns,
      ...rightFixedColumns,
    ];

    allColumns.forEach((columnName) => {
      const cellPosition = { lineId, columnName };

      const cellEditStatus = this.table.getCellEditStatus(cellPosition);
      this.updateCell(cellPosition, cellEditStatus);
    });
  }

  private updateCell(position: CellPosition, edit: boolean) {
    this.viewModel.setCellParams(position, this.makeCellParams(position, edit));
  }

  @autobind
  private makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
    return {
      component: this.getColumnHeaderComponent(columnName),
      columnHeaderProps: this.makeColumnHeaderProps(columnName),
    };
  }

  private getColumnHeaderComponent(
    columnName: ColumnName
  ): React.ClassType<any, any, any> {
    const columnType = ColumnsConfig[columnName].headerType;

    return ColumnHeaderComponentsByType[columnType];
  }

  private makeColumnHeaderProps(columnName: ColumnName): any {
    const headerType = ColumnsConfig[columnName].headerType;

    let cellProps: any;

    switch (headerType) {
      case ColumnHeaderType.Text:
        cellProps = this.makeTextColumnHeaderProps(columnName as ColumnName);
        break;

      case ColumnHeaderType.Filters:
        cellProps = this.makeFiltersColumnHeaderProps(columnName as ColumnName);
        break;
    }

    return cellProps;
  }

  private makeTextColumnHeaderProps(columnName: ColumnName): any {
    return {
      title: ColumnsConfig[columnName].title,
    };
  }

  private makeFiltersColumnHeaderProps(columnName: ColumnName): any {
    return {
      title: ColumnsConfig[columnName].title,
      columnName,
      disableSorting: ColumnsConfig[columnName].disableSorting,
      makeFilterItems: () => this.makeFilterItems(columnName),
    };
  }

  @autobind
  private makeFilterItems(columnName: ColumnName) {
    const { budgetAccessLines, filters } = this.props;

    const filtersToApply = filters.some(
      (item) => item.columnName === columnName
    )
      ? filters.slice(
          0,
          filters.findIndex((item) => item.columnName === columnName)
        )
      : filters;

    let filteredLines = budgetAccessLines;

    filtersToApply.forEach((filter) => {
      filteredLines = filteredLines.filter((line) => {
        const value = this.getCellValue({
          columnName: filter.columnName,
          lineId: line.model.id,
        });

        return lodash.isArray(value)
          ? filter.selectedValues.some((item) => value.includes(item))
          : filter.selectedValues.includes(value);
      });
    });

    const linesValues = filteredLines.map((item) =>
      this.getCellValue({ columnName, lineId: item.model.id })
    );

    const hasLinesWithoutValue = linesValues.some((value) =>
      lodash.isArray(value) ? lodash.isEmpty(value) : !value
    );

    const selectedValues = lodash.uniq(lodash.flatten(linesValues));

    const accessorParams = this.makeAccessorParams(null);

    const possibleItems =
      (
        ColumnsConfig[columnName].gelAllItems ||
        ColumnsConfig[columnName].getItems
      )(accessorParams) || [];

    const filterItems = selectedValues.map((value) => ({
      id: value,
      title:
        possibleItems.find((item) => item.value === value)?.title ||
        "Не найдено",
    }));

    const sortedItems = lodash.sortBy(filterItems, (item) => item.title);

    if (hasLinesWithoutValue) {
      sortedItems.unshift({
        id: null,
        title: "Значение не задано",
      });
    }

    return sortedItems;
  }

  @autobind
  private makeCellParams(
    cellPosition: CellPosition,
    edit: boolean
  ): CellParams {
    return {
      component: this.getCellComponent(cellPosition, edit),
      cellProps: this.makeCellProps(cellPosition, edit),
    };
  }

  private getCellComponent(
    cellPosition: CellPosition,
    edit: boolean
  ): React.ClassType<any, any, any> {
    const { columnName } = cellPosition;

    const columnType = ColumnsConfig[columnName].type;

    return edit
      ? CellComponentsByColumnType[columnType].editCell
      : CellComponentsByColumnType[columnType].cell;
  }

  private makeCellProps(cellPosition: CellPosition, edit: boolean): any {
    const { columnName } = cellPosition;

    const columnType = ColumnsConfig[columnName].type;

    let cellProps: any;

    switch (columnType) {
      case ColumnType.Text:
        cellProps = this.makeTextCellProps(cellPosition);
        break;

      case ColumnType.Select:
        cellProps = this.makeSelectCellProps(cellPosition, edit);
        break;

      case ColumnType.CheckboxList:
        cellProps = this.makeCheckboxListCellProps(cellPosition, edit);
        break;

      case ColumnType.RightGroups:
        cellProps = this.makeRightGroupsCellProps(cellPosition, edit);
        break;

      case ColumnType.Actions:
        cellProps = this.makeActionsCellProps(cellPosition);
        break;
    }

    return cellProps;
  }

  private makeTextCellProps(cellPosition: CellPosition): any {
    return {
      title: this.getCellValue(cellPosition) || "—",
    };
  }

  private makeSelectCellProps(cellPosition: CellPosition, edit: boolean): any {
    const value = this.getCellValue(cellPosition);
    const items = this.getCellItems(cellPosition);

    const title =
      value && items.some((item) => item.value === value)
        ? items.find((item) => item.value === value).title
        : `—`;

    return edit
      ? {
          title,
          items: items,
          selectedValue: value,
          allowSearch: true,
          onValueChange: this.makeValueChangeHandler(cellPosition, true),
        }
      : {
          title,
        };
  }

  private makeCheckboxListCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): any {
    const { columnName } = cellPosition;

    const value: any[] = this.getCellValue(cellPosition);
    const items = this.getCellItems(cellPosition);

    const entityNames = {
      [ColumnName.Budget]: "Бюджеты",
      [ColumnName.Department]: "Департаменты",
    };

    return edit
      ? {
          entityName: entityNames[columnName],
          items,
          selectedValues: value || [],
          emptyListMessage:
            columnName == ColumnName.Department ? "Выберите организацию" : null,
          allowSearch: true,
          onValueChange: this.makeValueChangeHandler(cellPosition, false),
        }
      : {
          entityName: entityNames[columnName],
          items,
          selectedValues: value || [],
        };
  }

  private makeRightGroupsCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): any {
    const { roles, actions } = this.props;
    const { lineId } = cellPosition;

    const value: any[] = this.getCellValue(cellPosition) || [];

    const selectedRoleId = this.getCellValue({
      lineId,
      columnName: ColumnName.Role,
    });
    const selectedRole = roles.find((item) => item.model.id === selectedRoleId);

    const filteredActions = selectedRole
      ? actions.filter((item) => selectedRole.model.actions.includes(item.id))
      : [];

    return edit
      ? {
          actions: filteredActions,
          dictionaries: this.props.dictionaries,
          value,
          onValueChange: this.makeValueChangeHandler(cellPosition, true),
          onClose: () => this.table.setCursorEditStatus(false),
        }
      : {
          value,
        };
  }

  private makeActionsCellProps(cellPosition: CellPosition): any {
    const { lineId } = cellPosition;

    const lineIsNew = lineId === "new";

    const budgetAccessLine = this.props.budgetAccessLines.find(
      (item) => item.model.id === lineId
    );

    const lineHasChanges = !lineIsNew
      ? budgetAccessLine.model.isUnsaved
      : false;

    return {
      lineIsNew,
      lineHasChanges,
      onCreateButtonClick: this.onCreateButtonClick,
      onCancelCreationButtonClick: this.onCancelCreationButtonClick,
      onCopyButtonClick: () => this.onCopyButtonClick(lineId),
      onDeleteConfirm: () => this.onDeleteConfirm(lineId),
      onSaveButtonClick: () => this.onSaveButtonClick(lineId),
      onCancelEditButtonClick: () => this.onCancelEditButtonClick(lineId),
    };
  }

  private updateLineIds() {
    const { budgetAccessLines } = this.props;
    const { newLineCreation } = this.state;

    const filteredLines = this.filterLines(budgetAccessLines);
    const sortedLines = this.sortLines(filteredLines);

    const updatedTableLineIds: React.ReactText[] = sortedLines.map(
      (item) => item.model.id
    );

    if (newLineCreation) {
      updatedTableLineIds.unshift("new");
    }

    this.setState({
      tableLineIds: updatedTableLineIds,
    });
  }

  private filterLines(lines: BudgetAccessLine[]): BudgetAccessLine[] {
    const { filters } = this.props;

    let filteredLines = lines;

    filters.forEach((filter) => {
      filteredLines = filteredLines.filter((line) => {
        const value = this.getCellValue({
          columnName: filter.columnName,
          lineId: line.model.id,
        });

        return lodash.isArray(value)
          ? filter.selectedValues.some(
              (item) =>
                value.includes(item) || (item === null && lodash.isEmpty(value))
            )
          : filter.selectedValues.includes(value);
      });
    });

    return filteredLines;
  }

  private sortLines(lines: BudgetAccessLine[]): BudgetAccessLine[] {
    const { sortingParams } = this.props;

    if (!sortingParams.columnName || !sortingParams.orderType) {
      return lines;
    }

    const sortedItems = lodash.sortBy(lines, (item) => {
      const accessorParams = this.makeAccessorParams(item.model.id);

      return ColumnsConfig[sortingParams.columnName].getSortingValue(
        accessorParams
      ) as unknown;
    });

    if (sortingParams.orderType === OrderType.Desc) {
      sortedItems.reverse();
    }

    return sortedItems;
  }

  private makeAccessorParams(lineId: LineId): AccessorParams {
    const {
      budgetAccessLines,
      organizations,
      departments,
      budgets,
      roles,
      actions,
    } = this.props;

    const line = budgetAccessLines.find((item) => item.model.id === lineId);

    return {
      lineId,
      budgetAccessLine: line,
      allBudgetAccessLines: budgetAccessLines,
      newLineFields: this.newLineFields,
      organizations,
      departments,
      budgets,
      roles,
      actions,
    };
  }

  private startNewLineCreation() {
    if (!this.state.newLineCreation) {
      const updatedTableLineIds = ["new", ...this.state.tableLineIds];

      this.setState({
        tableLineIds: updatedTableLineIds,
        newLineCreation: true,
      });
    }
  }

  private endNewLineCreation() {
    const updatedTableLineIds = lodash.without(this.state.tableLineIds, "new");

    this.setState({
      tableLineIds: updatedTableLineIds,
      newLineCreation: false,
    });

    this.newLineFields = {};
    this.viewModel.clearLineCells("new");
  }

  private async createBudgetAccessLine() {
    const client = await MrmClient.getInstance();

    const params: BudgetAccessCreateParams = {
      budgetIds: this.newLineFields[ColumnName.Budget],
      organizationId: this.newLineFields[ColumnName.Organization],
      departmentIds: this.newLineFields[ColumnName.Department] || [],
      roleId: this.newLineFields[ColumnName.Role],
      accessGroups: (this.newLineFields[ColumnName.RightGroup] || []).map(
        (accessGroup: any) => ({
          ...accessGroup,
          dictionaryTypes: accessGroup.dictionaryTypes || [],
        })
      ),
    };

    const res = await client.api.budgets.createBudgetAccess(params);
  }

  private async deleteBudgetAccessLine(lineId: LineId) {
    const budgetAccessLine = this.props.budgetAccessLines.find(
      (item) => item.model.id === lineId
    );

    await budgetAccessLine.model.delete();
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const {
    budgetAccessLines,
    dictionaries,
    organizations,
    departments,
    budgets,
    roles,
    actions,
  } = getPageData(state);

  return {
    budgetAccessLines,
    dictionaries,
    organizations,
    departments,
    budgets,
    roles,
    actions,
    sortingParams: getSortingParams(state),
    filters: getFilters(state),
  };
}
