import * as React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import autobind from "autobind-decorator";

import {
  UpdateBriefPageParams,
  BlockParams,
  CalculationLogicPopupParams,
} from "../../store/brief/types";

import { ItemType } from "./ItemType";
import { BriefPage } from "./BriefPage";
import { StoreState } from "../../store";
import {
  updateBriefPage,
  resetBriefPage,
  normalizeElementsOrders,
} from "../../store/brief/actions";
import { getCalculationLogicPopupParams } from "../../store/brief/selector";
import { BriefApi } from "../../api";

interface Props
  extends MapProps,
    DispatchProps,
    RouteComponentProps<RouteParams> {
  pageLabel: string;
  previousLabel: string;
  previousUrl: string;
}

interface RouteParams {
  briefSchemeId?: string;
  action?: string;
}

interface MapProps {
  calculationLogicPopup: CalculationLogicPopupParams;
}

interface DispatchProps {
  updateBriefPage: (params: UpdateBriefPageParams) => void;
  resetBriefPage: () => void;
  normalizeElementsOrders: () => void;
}

interface State {
  preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(DragDropContext(MultiBackend(HTML5toTouch)) as any)
export class BriefPageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
    };
  }

  public async componentDidMount() {
    const { briefSchemeId, action } = this.props.match.params;

    if (briefSchemeId) {
      const scheme = await BriefApi.getScheme(briefSchemeId);
      const isCopy = action == "copy";
      const isSchemeHasActivity =
        scheme.activityCounts == 0 &&
        !(scheme.blocks || []).find((block) =>
          block.fields.find((field) => field.properties?.parentFieldId)
        );
      const isSchemeActive =
        !isSchemeHasActivity && !isCopy && !scheme.actualSchemeId;

      const briefPageParams: UpdateBriefPageParams = {
        name: isCopy ? `Копия ${scheme.name}` : scheme.name,
        briefSchemeId,
        activityCounts: scheme.activityCounts,
        isSchemeActive,
        isSchemeEditable:
          (isSchemeHasActivity || isCopy) && !scheme.actualSchemeId,
        elements: scheme.blocks.map((block) => ({
          id: block.id,
          briefBlockId: block.briefBlockId,
          name: block.name,
          tooltipMessage: block.tooltipMessage,
          schemaId: scheme.id,
          order: block.order,
          isRequired: block.isRequired,
          isClientHide: block.isClientHide,
          fields: block.fields.map((field) => ({
            id: field.id,
            blockId: field.briefBlockId,
            type: field.type as ItemType,
            order: field.order,
            properties: isSchemeActive
              ? {
                  parentFieldId:
                    scheme.activityCounts == 0 ? undefined : field.id,
                  ...(field.properties || {}),
                }
              : field.properties,
          })),
        })),
      };

      this.props.updateBriefPage(briefPageParams);
      this.props.normalizeElementsOrders();
    }

    this.setState({
      preloader: false,
    });
  }

  public render(): JSX.Element {
    const { previousUrl } = this.props;

    return React.createElement(BriefPage, {
      preloader: this.state.preloader,
      pageLabel: this.props.pageLabel,
      previousLabel: this.props.previousLabel,
      previousUrl,
      isVisibleCalculationLogicPopup:
        this.props.calculationLogicPopup.visibility,
      onContentWrapperClick: this.onContentWrapperClick,
      onSaveStart: this.onSaveStart,
      onSaveEnd: this.onSaveEnd,
    });
  }

  public async componentWillUnmount() {
    this.props.resetBriefPage();
  }

  @autobind
  protected onContentWrapperClick(event: React.MouseEvent<HTMLElement>): void {
    const userClickedOutside = event.target == event.currentTarget;

    if (userClickedOutside) {
      this.clearSelection();
    }
  }

  @autobind
  protected onSaveStart() {
    this.setState({
      preloader: true,
    });
  }

  @autobind
  protected onSaveEnd() {
    this.setState({
      preloader: false,
    });
  }

  private clearSelection() {
    this.props.updateBriefPage({
      selectedElementId: null,
      selectedBlockId: null,
      selectedFieldItemId: null,
      selectedItemType: null,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    calculationLogicPopup: getCalculationLogicPopupParams(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      updateBriefPage,
      resetBriefPage,
      normalizeElementsOrders,
    },
    dispatch
  );
}
