// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-RolePage-_Role__root{width:100%;height:100%;background-color:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.1)}.src-modules-RolePage-_Role__tableRow{padding:0 28px}.src-modules-RolePage-_Role__errorInfoWrapper{padding:28px 40px}.src-modules-RolePage-_Role__errorInfo{display:flex;height:200px;border-radius:4px;background-color:#ffffff;box-shadow:0 1px 10px 0 rgba(0,0,0,0.1);font-size:14px;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/modules/RolePage/Role.scss"],"names":[],"mappings":"AAAA,kCACE,UAAW,CACX,WAAY,CAEZ,wBAAyB,CACzB,uCAA2C,CAC5C,sCAGC,cAAe,CAChB,8CAGC,iBAAkB,CACnB,uCAGC,YAAa,CAEb,YAAa,CAEb,iBAAkB,CAClB,wBAAyB,CACzB,uCAA2C,CAE3C,cAAe,CAEf,kBAAmB,CACnB,sBAAuB","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n\n  background-color: #ffffff;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n.tableRow {\n  padding: 0 28px;\n}\n\n.errorInfoWrapper {\n  padding: 28px 40px;\n}\n\n.errorInfo {\n  display: flex;\n\n  height: 200px;\n\n  border-radius: 4px;\n  background-color: #ffffff;\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);\n\n  font-size: 14px;\n\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-RolePage-_Role__root",
	"tableRow": "src-modules-RolePage-_Role__tableRow",
	"errorInfoWrapper": "src-modules-RolePage-_Role__errorInfoWrapper",
	"errorInfo": "src-modules-RolePage-_Role__errorInfo"
};
export default ___CSS_LOADER_EXPORT___;
