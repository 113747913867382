import * as React from "react";
import { useSelector } from "react-redux";

import type { StoreState } from "@store";

import { WithClientData } from "./WithClientData";
import { TableBehaviour } from "./TableBehaviour";
import { getArchivedContractsFilter } from "@store/contractsPage/selectors";

interface Props {}

export const TableConnector: React.FC<Props> = ({}) => {
  const tableBehaviourRef = React.useRef(null as TableBehaviour);
  const archivedFilterIsActive = useSelector((state: StoreState) =>
    getArchivedContractsFilter(state)
  );

  return (
    <WithClientData tableRef={tableBehaviourRef}>
      {({
        loading,
        getLine,
        getLines,
        createLine,
        archiveLine,
        restoreLine,
      }) => (
        <TableBehaviour
          ref={tableBehaviourRef}
          loading={loading}
          archivedFilterIsActive={archivedFilterIsActive}
          getLine={getLine}
          getLines={getLines}
          createLine={createLine}
          archiveLine={archiveLine}
          restoreLine={restoreLine}
        />
      )}
    </WithClientData>
  );
};
