// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-_Schema__root{min-height:600px;padding:0 8px 50px;background-color:#ffffff;box-shadow:0 1px 6px 0 rgba(0,0,0,0.1)}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Schema/Schema.scss"],"names":[],"mappings":"AAAA,4CACI,gBAAiB,CACjB,kBAAmB,CAEnB,wBAAyB,CACzB,sCAA0C","sourcesContent":[".root {\n    min-height: 600px;\n    padding: 0 8px 50px;\n\n    background-color: #ffffff;\n    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Schema-_Schema__root"
};
export default ___CSS_LOADER_EXPORT___;
