import { BriefScheme, DepartmentAttributes } from "@sm/types/admin";

export interface BriefSchemeListPageState {
  briefSchemes: BriefScheme[];
  departments: DepartmentAttributes[];
  selectedFilter: BriefSchemeListFilterMode;
}

export interface BriefSchemeListPageSetFilterParams {
  selectedFilter: BriefSchemeListFilterMode;
}

export interface BriefSchemeListPageLoadParams {
  briefSchemes: BriefScheme[];
  departments: DepartmentAttributes[];
}

export interface UpdateSchemeParams {
  id: string;
  status?: string;
  departmentId?: string;
}

export enum BriefSchemeListFilterMode {
  Actual = "active",
  Inactual = "inactive",
}
