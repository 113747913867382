import * as React from "react";
import onClickOutside from "react-onclickoutside";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import { Scrollbars } from "@sm/ui";
import { DropdownContent } from "./DropdownContent";
import { Utils } from "../../../../../common/Utils";

const ENTER_KEY_CODE = 13;

export interface DropdownContentContainerProps {
  name: string;
  content: {
    id: string;
    value: string;
    hasFormulas?: boolean;
  }[];
  canAddValue?: boolean;
  canChangeValue?: boolean;
  displayCalculateButton?: boolean;
  onAddValue: (value: string) => void;
  onChangeValue: (params: { value: string; id: string }) => void;
  onSumButtonClick?: (params: { lineId: string }) => void;
}

interface State {
  isOpen: boolean;
  isOnAddValue: boolean;
}

@(onClickOutside as any)
export class DropdownContentContainer extends React.Component<
  DropdownContentContainerProps,
  State
> {
  protected customScrollbar: Scrollbars;

  constructor(props: DropdownContentContainerProps) {
    super(props);

    this.state = {
      isOpen: false,
      isOnAddValue: false,
    };
  }

  public render() {
    const {
      content,
      name,
      displayCalculateButton,
      canAddValue,
      canChangeValue,
    } = this.props;

    return React.createElement(DropdownContent, {
      content,
      name,
      isOpen: this.state.isOpen,
      isOnAddValue: this.state.isOnAddValue,
      canAddValue,
      canChangeValue,
      displayCalculateButton,
      customScrollbarRef: this.customScrollbarRef,
      onOpenerClick: this.handleOpenerClick,
      onNewValueBlur: this.handleAddValue,
      onValueChange: this.handleChangeValue,
      onAddButtonClick: this.handleAddButtonClick,
      onAddInputKeyUp: this.handleAddInputKeyUp,
      onEditInputKeyUp: this.handleEditInputKeyUp,
      onSumButtonClick: this.onSumButtonClick,
    });
  }

  @autobind
  public handleClickOutside() {
    if (this.state.isOpen) {
      this.setState({
        isOpen: !this.state.isOpen,
        isOnAddValue: false,
      });
    }
  }

  @autobind
  protected customScrollbarRef(element: Scrollbars) {
    this.customScrollbar = element;
  }

  @autobind
  protected handleOpenerClick() {
    this.setState({
      isOpen: !this.state.isOpen,
      isOnAddValue: false,
    });
  }

  @autobind
  protected handleAddButtonClick() {
    this.toggleIsAddClick();
  }

  @autobind
  protected handleChangeValue(value: string, id: string) {
    const valueAlreadyExists = Boolean(
      lodash.find(this.props.content, (item) => item.value == value)
    );

    if (!valueAlreadyExists) {
      const editedContent = Utils.getItemById(this.props.content, id);

      if (editedContent.value != value) {
        this.props.onChangeValue({
          id,
          value,
        });
      }
    }
  }

  @autobind
  protected handleEditInputKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === ENTER_KEY_CODE) {
      const { value, name } = event.target;
      const id = name;

      const editedContent = Utils.getItemById(this.props.content, id);

      if (editedContent.value != value) {
        this.props.onChangeValue({
          id,
          value,
        });
      }
    }
  }

  @autobind
  protected handleAddValue(event: React.FocusEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.processAddValue(value);
  }

  @autobind
  protected handleAddInputKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === ENTER_KEY_CODE) {
      const { value } = event.target;

      this.processAddValue(value);
    }
  }

  @autobind
  protected onSumButtonClick(params: { id: string }) {
    this.props.onSumButtonClick({ lineId: params.id });
  }

  private processAddValue(value: string) {
    const valueAlreadyExists = Boolean(
      lodash.find(this.props.content, (item) => item.value == value)
    );

    if (!valueAlreadyExists && value !== "") {
      this.props.onAddValue(value.trim());
    }

    this.toggleIsAddClick();

    setTimeout(() => {
      this.customScrollbar.scrollToBottom();
    }, 0);
  }

  private toggleIsAddClick() {
    this.setState({
      isOnAddValue: !this.state.isOnAddValue,
    });
  }
}
