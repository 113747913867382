import * as React from "react";
import autobind from "autobind-decorator";

import { PageProps } from "../common/Page";
import { TradeMarkTemplate } from "./TradeMarkTemplate";
import { TrademarkApi } from "../../api/TrademarkApi";

interface Props extends PageProps {}
interface State {
  showErrorPopup: boolean;
}

export class TradeMark extends React.Component<Props, State> {
  private inputElement: HTMLInputElement;
  private isInputDisabled = false;

  public constructor(props: Props) {
    super(props);
    this.state = { showErrorPopup: false };
  }

  public render() {
    const { pageLabel, preloader } = this.props;

    return React.createElement(TradeMarkTemplate, {
      pageLabel,
      preloader,
      isInputDisabled: this.isInputDisabled,
      onInputMount: this.onInputMount,
      onInputClick: this.onInputClick,
      onInputChange: this.onInputChange,
      showErrorPopup: this.state.showErrorPopup,
      hideErrorPopup: this.hideErrorPopup,
    });
  }

  @autobind
  protected onInputMount(inputElement: HTMLInputElement): void {
    this.inputElement = inputElement;
  }

  @autobind
  protected onInputClick(): void {
    if (this.inputElement && !this.isInputDisabled) {
      this.inputElement.click();
    }
  }

  @autobind
  protected onInputChange(): void {
    if (this.inputElement && !this.isInputDisabled) {
      const file: File = this.inputElement.files[0];

      this.onFileAdd(file);

      this.inputElement.value = null;
    }
  }

  @autobind
  protected async onFileAdd(file: File) {
    try {
      await TrademarkApi.uploadFile(file);
    } catch (e) {
      this.setState({ showErrorPopup: true });
    }
  }

  @autobind
  protected hideErrorPopup() {
    this.setState({ showErrorPopup: false });
  }
}
