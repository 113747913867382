import * as React from "react";
import * as lodash from "lodash";
import classnames from "classnames";

import * as style from "./RightGroupsCell.scss";

import type { BudgetAccessGroup } from "../../../../../api";

interface Props {
  value: BudgetAccessGroup[];
}

export const RightGroupsCellTemplate = ({ value }: Props): JSX.Element => {
  const dictionaryCount =
    lodash.sumBy(value, (item) => item?.dictionaryIds?.length) || 0;
  const actionsCount =
    lodash.sumBy(value, (item) => item?.actionIds?.length) || 0;

  return (
    <div className={style.root}>
      {(dictionaryCount !== 0 || actionsCount !== 0) && (
        <div className={style.counters}>
          <div className={classnames(style.counter, style.yellow)}>
            Справочники: {dictionaryCount}
          </div>

          <div className={classnames(style.counter, style.blue)}>
            Действия: {actionsCount}
          </div>
        </div>
      )}

      {dictionaryCount === 0 && actionsCount === 0 && (
        <div className={style.noValueMessage}>Не назначены</div>
      )}
    </div>
  );
};
