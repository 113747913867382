// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-DictionaryLinksValidationMarker-_DictionaryLinksValidationMarker__root{border:1px solid black}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/DictionaryLinksValidationMarker/DictionaryLinksValidationMarker.scss"],"names":[],"mappings":"AAAA,qGACI,sBAAuB","sourcesContent":[".root {\n    border: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DictionariesPage-DictionaryLinksValidationMarker-_DictionaryLinksValidationMarker__root"
};
export default ___CSS_LOADER_EXPORT___;
