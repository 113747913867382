import * as React from "react";
import { connect } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import {
  UserParams,
  DepartmentUserAttributes,
  RoleAttributes,
} from "@sm/types/admin";

import {
  SelectEventProps_redesign,
  SuggestItemParams,
  SelectProps,
} from "@sm/ui";
import { EditUserRow } from "./EditUserRow";
import { StoreState } from "../../../../store";
import { getUserByUserId } from "../../../../store/departmentRolePage/selector";

interface Props extends Partial<MapProps> {
  userId: number;
  availableUsers: UserParams[];
  isOnEdit?: boolean;
  onChange: (userId: React.ReactText) => void;
  departmentUsers: DepartmentUserAttributes[];
  handleCheckboxClick: (
    userId: number,
    id: number,
    title: string,
    isChecked: boolean
  ) => void;
}

interface MapProps {
  user: UserParams;
  roles: RoleAttributes[];
}

@(connect(mapStateToProps, null) as any)
export class EditUserRowContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const userSelectProps = this.makeUserSelectProps();

    return React.createElement(EditUserRow, {
      userSelectProps,
      roles: this.getRoles(),
      userRoles: this.getUserRoles(),
      onItemSelect: this.handleItemSelect,
    });
  }

  @autobind
  protected handleUserChange(option: SelectEventProps_redesign) {
    this.props.onChange(option.value);
  }

  @autobind
  protected handleItemSelect(item: SuggestItemParams) {
    const { userId, departmentUsers } = this.props;

    const selectedUser = departmentUsers.find((item) => item.id == userId);
    const userRoles = selectedUser ? selectedUser.roles : [];
    const isChecked = lodash.includes(
      userRoles.map((item) => item.id),
      item.value
    );

    this.props.handleCheckboxClick(
      this.props.userId,
      item.value as number,
      item.label,
      !isChecked
    );
  }

  private makeUserSelectProps(): SelectProps {
    const { userId, user, availableUsers } = this.props;

    const options = userId
      ? [
          {
            value: userId,
            label: `${user.firstName} ${user.secondName}`,
          },
        ]
      : availableUsers.map((item) => ({
          value: item.id,
          label: this.formatName(
            item.firstName,
            item.secondName,
            item.middleName
          ),
        }));

    return {
      value: userId,
      options,
      onChange: this.handleUserChange,
    };
  }

  private formatName(
    firstName: string,
    secondName: string,
    middleName?: string
  ): string {
    const middle = middleName ? ` ${middleName}` : "";
    return `${firstName}${middle} ${secondName}`;
  }

  private getRoles(): SuggestItemParams[] {
    const { roles } = this.props;

    return roles.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  private getUserRoles(): SuggestItemParams[] {
    const { userId, roles, departmentUsers } = this.props;

    const selectedUser = departmentUsers.find((item) => item.id == userId);
    const userRoles = selectedUser ? selectedUser.roles : [];

    return roles
      .filter((role) =>
        lodash.includes(
          userRoles.map((item) => item.id),
          role.id
        )
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    roles: state.departmentRolePage.roles,
    user: getUserByUserId(state, ownProps.userId),
  };
}
