import * as React from "react";
import { ADT } from "ts-adt";
import { PlainDictionary } from "@sm/types/budget";

interface TableControlsContextProps {
  showDeletedDictionaries: boolean;
  setTableState: (state: TableState) => void;
  restoreDictionary: (dictionaryId: string) => void;
}

export const TableControlsContext =
  React.createContext<TableControlsContextProps>({
    showDeletedDictionaries: false,
    setTableState: () => {},
    restoreDictionary: () => {},
  });

export type TableState = ADT<{
  showTable: {};
  createDictionary: {};
  editDictionaryData: { dictionary: PlainDictionary };
  editDictionaryLinks: { dictionaryIds: string[] };
  deleteDictionary: { dictionary: PlainDictionary };
}>;
export const defaultTableState: TableState = { _type: "showTable" };
