import { axios } from "../lib/axios";

const BASE_URL = "/api/trademark";

export class TrademarkApi {
  public static async uploadFile(file: File): Promise<void> {
    const headers = { "Content-Type": "multipart/form-data" };
    const formData = new FormData();

    formData.append("file", file);

    const res = await axios.post(`${BASE_URL}/upload-file`, formData, {
      headers,
    });

    return res.data;
  }
}
