import * as React from "react";

import * as style from "./ActivityTypePage.scss";

import { Popup } from "@sm/ui";
import { Page } from "../common/Page";
import { ActivityTypeTable } from "./ActivityTypeTable";
import { TopLine } from "./TopLine";

interface Props {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
}

export function ActivityTypePage({
  preloader,
  pageLabel,
  previousUrl,
  previousLabel,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      previousUrl={previousUrl}
      previousLabel={previousLabel}
      withoutBackButton
      customFirstLine={() => <TopLine pageLabel={pageLabel} />}
    >
      <div className={style.root}>
        <ActivityTypeTable />
      </div>
    </Page>
  );
}
