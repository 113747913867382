import * as React from "react";
import * as styles from "./styles.scss";

import type { Formula, OperationScheme, OperandScheme } from "./types";
import { Operation, Operand } from "./Operation";
import { Selector } from "./Selector";
import { Icon, IconType } from "@sm/ui";
import { blankSchema } from "../data";
import { Type } from "../enums";

interface Props {
  schemas: OperationScheme[];
  operands: Operand[];
  onAddOperands: (item: Operand) => void;
  onSchemaAdd: () => void;
  onSchemaChange: (index: number, formula: Formula) => void;
  onSchemaRemove: (index: number) => void;
}

export const FormulasBuilder: React.FC<Props> = ({
  schemas,
  operands,
  onAddOperands,
  onSchemaAdd,
  onSchemaChange: externalSchemaChange,
  onSchemaRemove,
}) => {
  const [selectedSchemaIndex, setSelectedSchemaIndex] = React.useState(0);

  const onSchemaChange = React.useCallback(
    (schema: OperationScheme) => {
      externalSchemaChange(selectedSchemaIndex, { ...schema });
    },
    [selectedSchemaIndex, externalSchemaChange]
  );

  const onSelectorItemClick = React.useCallback((index: number) => {
    setSelectedSchemaIndex(index);
  }, []);

  const onSelectorAddButtonClick = React.useCallback(() => {
    onSchemaAdd();
    setSelectedSchemaIndex(schemas.length);
  }, [schemas, onSchemaAdd]);

  const onSelectorRemoveButtonClick = React.useCallback(() => {
    const lastIndex = schemas.length - 1;

    if (selectedSchemaIndex < lastIndex) {
      onSchemaRemove(selectedSchemaIndex);
    } else {
      setSelectedSchemaIndex(selectedSchemaIndex - 1);
      onSchemaRemove(selectedSchemaIndex);
    }
  }, [schemas, selectedSchemaIndex, onSchemaRemove]);

  const onAddButtonClick = React.useCallback(() => {
    const currentScheme = schemas[selectedSchemaIndex];

    onSchemaChange({
      ...blankSchema,
      operands: [
        {
          ...currentScheme,
        },
        {
          type: Type.OPERAND,
          selectedItemId: "",
        },
      ],
    });
  }, [selectedSchemaIndex, schemas, onSchemaChange]);

  const isAddButtonVisible = schemas[selectedSchemaIndex].operands.every(
    (operand) => {
      return (
        (operand as OperationScheme)?.type === Type.OPERATION ||
        (operand as OperandScheme)?.selectedItemId?.length !== 0
      );
    }
  );

  return (
    <div key={selectedSchemaIndex}>
      <div className={styles.title}>Формула</div>

      <div className={styles.selectorWrapper}>
        <Selector
          items={schemas.map((_, index) => ({ title: String(index + 1) }))}
          selectedItemIndex={selectedSchemaIndex}
          onItemClick={onSelectorItemClick}
          onAddButtonClick={onSelectorAddButtonClick}
          onRemoveButtonClick={onSelectorRemoveButtonClick}
        />
      </div>

      <div className={styles.operationWrapper}>
        <Operation
          key={`${selectedSchemaIndex}-${schemas?.length}`}
          schema={schemas[selectedSchemaIndex]}
          operands={operands}
          onAddOperands={onAddOperands}
          onSchemaChange={onSchemaChange}
        />
      </div>

      {isAddButtonVisible && (
        <div className={styles.addButton} onClick={onAddButtonClick}>
          <Icon type={IconType.PLUS_SIGN} svgSize={10} />
          Добавить действие
        </div>
      )}
    </div>
  );
};

export type { Operand, OperationScheme, OperandScheme };
