import * as React from "react";
import * as lodash from "lodash";

import * as style from "./Table.scss";

import type {
  CellPosition,
  ColumnName,
  ColumnWidths,
  Filter,
  LineId,
} from "./types";

import { CellsStorage, TableView, CellEvent } from "sber-marketing-ui";
import { FiltersList } from "./FiltersList";

interface Props {
  loading: boolean;
  headerCellsStorage: CellsStorage<ColumnName, any>;
  tableCellsStorage: CellsStorage<CellPosition, any>;
  columns: ColumnName[];
  leftFixedColumns: ColumnName[];
  rightFixedColumns: ColumnName[];
  fixedWidthColumns: ColumnName[];
  lineIds: LineId[];
  columnWidths: ColumnWidths;
  filters: Filter[];
  tableHeight: number;
  tableRef: (component: TableView) => void;
  setFilters: (filters: Filter[]) => void;
  onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableTemplate = ({
  loading,
  headerCellsStorage,
  tableCellsStorage,
  columns,
  leftFixedColumns,
  rightFixedColumns,
  fixedWidthColumns,
  lineIds,
  columnWidths,
  filters,
  tableHeight,
  tableRef,
  setFilters,
  onCellEvent,
}: Props): JSX.Element => {
  return (
    <div className={style.root}>
      {!loading && (
        <>
          <div className={style.topLine}>
            {!lodash.isEmpty(filters) && (
              <div className={style.filtersList}>
                <FiltersList
                  filters={filters}
                  setFilters={(filters: Filter[]) => setFilters(filters)}
                />
              </div>
            )}
          </div>

          <div className={style.table}>
            <TableView
              ref={tableRef}
              maxHeight={tableHeight}
              headerCellsStorage={headerCellsStorage}
              tableCellsStorage={tableCellsStorage}
              columns={columns}
              leftFixedColumns={leftFixedColumns}
              rightFixedColumns={rightFixedColumns}
              fixedWidthColumns={fixedWidthColumns}
              lines={lineIds}
              columnWidths={columnWidths}
              lineHeight={50}
              onCellEvent={onCellEvent}
            />
          </div>
        </>
      )}
    </div>
  );
};
