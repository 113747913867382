import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import autobind from "autobind-decorator";
import { PlainDictionary } from "@sm/types/budget";

import type { ActivityType } from "@sm/types/backend";

import { ActivityTypeFormContainer } from "./ActivityTypeFormContainer";
import type { StoreState } from "../../../store";
import { updateActivityType } from "../../../store/activityType/actions";
import { getActivityTypePageState } from "../../../store/activityType/selector";
import {
  ActivityTypeColorData,
  UpdateActivityTypeParams,
} from "../../../store/activityType/types";
import { DictionaryApi } from "../../../api";

interface Props extends Partial<MapProps & DispatchProps> {
  activityType: PlainDictionary;
  onCancelClick?: () => void;
}

interface MapProps {
  activityTypeColorData?: ActivityTypeColorData;
}

interface DispatchProps {
  updateActivityType?: (params: UpdateActivityTypeParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditActivityTypeFormContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const { activityType, activityTypeColorData } = this.props;

    return React.createElement(ActivityTypeFormContainer, {
      activityType,
      activityTypeColorData,
      onConfirmClick: this.handleConfirmClick,
      onCancelClick: this.handleCancelClick,
    });
  }

  @autobind
  protected async handleConfirmClick(colorData: ActivityTypeColorData) {
    const { activityType } = this.props;

    await DictionaryApi.updateDictionaryItem(activityType.id, {
      ...activityType,
      data: { color: colorData },
    });
    this.props.updateActivityType({
      id: activityType.id,
      colorData,
    });
  }

  @autobind
  protected handleCancelClick() {
    this.props.onCancelClick();
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    activityTypeColorData:
      getActivityTypePageState(state).activityTypesData[
        ownProps.activityType.id
      ].data,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    updateActivityType: (params: UpdateActivityTypeParams) =>
      dispatch(updateActivityType(params)),
  };
}
