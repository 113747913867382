import * as React from "react";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import * as classnames from "classnames";

import * as style from "./ElementCard.scss";

export interface ElementCardProps {
  text: string;
  shiftBlockUpward: () => void;
  shiftBlockDownward: () => void;
  enableUpwardArrow: boolean;
  enableDownwardArrow: boolean;
  connectDragSource?: ConnectDragSource;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
}

export function ElementCard({
  text,
  isDragging,
  connectDragSource,
  connectDropTarget,
  shiftBlockUpward,
  shiftBlockDownward,
  enableUpwardArrow,
  enableDownwardArrow,
}: ElementCardProps): JSX.Element {
  const opacity = isDragging ? 0 : 1;

  return connectDragSource(
    connectDropTarget(
      <div className={style.root} style={{ opacity }}>
        <div className={style.text}>{text}</div>

        <div
          className={classnames(
            style.triangleUpward,
            enableUpwardArrow && style.triangle_enabled
          )}
          onClick={enableUpwardArrow ? shiftBlockUpward : null}
        />

        <div
          className={classnames(
            style.triangleDownward,
            enableDownwardArrow && style.triangle_enabled
          )}
          onClick={enableDownwardArrow ? shiftBlockDownward : null}
        />
      </div>
    )
  );
}
