import { FileAsset } from "api/file-api/types";
import { GetPreviewResponse } from "@mrm/videoPreview";

/** "FileAssetWithPreview" component properties */
export interface FileAssetWithPreviewProps extends FileAsset {
  briefId?: string;
  uniqId?: number;
  parentUniqId?: number;
  /** Additional CSS classes */
  className?: string;
  /** If "true", asset can be deleted by use */
  canDelete?: boolean;
  /** If "true" asset can be downloaded */
  disableDownload?: boolean;
  useR7Controls?: boolean;
  /** Remove click handler */
  onRemove?(asset: FileAsset): any;
  /** Gallery click button click handler */
  onGalleryClick?(asset: FileAsset): any;
}

/** File asset border color */
export const enum FileAssetColor {
  BLACK = "#000",
  TRANSPARENT = "transparent",
  GREEN = "#19bb4f",
  RED = "#e63900",
  PINK = "#fe3f57",
  GREY = "#999999",
  BLACK_TRANSLUCENT = "rgba(0, 0, 0, 0.4)",
}

/** "FileAssetWithPreview" container state */
export interface FileAssetWithPreviewState {
  previewWasLoaded: boolean;
  /** Border color */
  borderColor: FileAssetColor;
  /** Remove opacity */
  removeOpacity: number;
  /** Remove button color */
  removeBackgroundColor: FileAssetColor;
  /** Preview shadow opacity */
  previewShadowOpacity: number;
  /** Open gallery shadow element opacity */
  openGalleryShadowOpacity: number;
  /** Open gallery shadow element color */
  openGalleryShadowColor: FileAssetColor;
  /** Download file shadow element opacity */
  downloadFileShadowOpacity: number;
  /** Download file shadow element color */
  downloadFileShadowColor: FileAssetColor;
  /** Is remove asset popup opened */
  isRemovePopupOpen: boolean;
  /** Gallery display value */
  galleryDisplay: string;
  /** Download display value */
  downloadDisplay: "none" | "block";
  /** Gallery opacity */
  galleryOpacity: number;
  /** Download opacity */
  downloadOpacity: number;
  /** Name block color */
  nameColor: FileAssetColor;
  /** Micro type mark file asset color */
  microTypeMarkColor: FileAssetColor;
}

export interface FileAssetWithPreviewTemplateProps
  extends FileAsset,
    FileAssetWithPreviewState {
  /** Additional CSS classes */
  className?: string;
  /** If "true", asset can be deleted by use */
  canDelete?: boolean;
  /** If "true" asset can be downloaded */
  disableDownload?: boolean;
  useR7Controls?: boolean;
  videoPreview?: GetPreviewResponse;
  documentWasEditedInR7?: boolean;
  /** Remove popup open click handler */
  onRemoveClick(): void | Promise<void>;
  /** Download click handler */
  onDownloadClick(): void | Promise<void>;
  /** Remove submit click handler */
  /** Preview mouse over handler */
  onPreviewMouseOver(): void | Promise<void>;
  /** Preview mouse leave handler */
  onPreviewMouseLeave(): void | Promise<void>;
  /** Remove mouse over handler */
  onRemoveMouseOver(): void | Promise<void>;
  /** Remove mouse leave handler */
  onRemoveMouseLeave(): void | Promise<void>;
  /** Gallery mouse over handler */
  onDownloadMouseOver(): void | Promise<void>;
  /** Download mouse leave handler */
  onDownloadMouseLeave(): void | Promise<void>;
}

/** "TypeMark" component properties */
export interface TypeMarkProps {
  /** Type to show */
  type: string;
  /** Preview URL */
  previewUrl?: string;
}
