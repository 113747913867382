// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-TopLine-_TopLine__root{display:flex;align-items:center;justify-content:flex-end}.src-modules-DepartmentRolePage-TopLine-_TopLine__addUserButton{width:200px;height:24px}.src-modules-DepartmentRolePage-TopLine-_TopLine__addUserButton+.src-modules-DepartmentRolePage-TopLine-_TopLine__addUserButton{margin-left:8px}\n", "",{"version":3,"sources":["webpack://./src/modules/DepartmentRolePage/TopLine/TopLine.scss"],"names":[],"mappings":"AAAA,uDACI,YAAa,CAEb,kBAAmB,CACnB,wBAAyB,CAC5B,gEAGG,WAAY,CACZ,WAAY,CAFhB,gIAIQ,eAAgB","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.addUserButton {\n    width: 200px;\n    height: 24px;\n    & + & {\n        margin-left: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DepartmentRolePage-TopLine-_TopLine__root",
	"addUserButton": "src-modules-DepartmentRolePage-TopLine-_TopLine__addUserButton"
};
export default ___CSS_LOADER_EXPORT___;
