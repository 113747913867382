import axios, { AxiosResponse } from "axios";

import { store } from "../../store";
import { addError } from "../../store/common/actions";

const UNAUTHORISED_CODE = 401;
const GATEWAY_TIMEOUT = 504;

const BASE_URL: string =
  typeof window != "undefined" && window["BASE_URL"] ? window["BASE_URL"] : "";

axios.defaults.headers.get["Cache-Control"] =
  "no-cache, no-store, must-reinvalidate";
axios.defaults.headers.get["Pragma"] = "no-cache";
axios.defaults.headers.get["Expires"] = 0;

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == UNAUTHORISED_CODE) {
      window.location.pathname = "/login";
    }

    if (error.response.status === GATEWAY_TIMEOUT) {
      store.dispatch(addError("GatewayTimeout"));

      return Promise.reject(error.message);
    }

    store.dispatch(addError(error.response.data.data.code));

    return Promise.reject(new Error(error.response.data.data.data.message));
  }
);

export { instance, AxiosResponse };
