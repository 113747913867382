import * as React from "react";
import { bindActionCreators } from "redux";
import { Dispatch, connect } from "react-redux";
import autobind from "autobind-decorator";

import {
  EditFieldPropertiesParams,
  CalculationLogicPopupParams,
} from "../../../../../store/brief/types";

import { UnlockableInputMenu } from "./UnlockableInputMenu";
import { StoreState } from "../../../../../store";
import {
  editField,
  setCalculationLogicPopupParams,
} from "../../../../../store/brief/actions";
import { getFieldById } from "../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isCalculated?: boolean;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
  isFormulaEditable?: boolean;
  hasFormulas?: boolean;
  duplicateToReport?: boolean;
  isClientHide?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
  setCalculationLogicPopupParams: (params: CalculationLogicPopupParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class UnlockableInputMenuContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      name,
      validationMessage,
      tooltipMessage,
      isRequired,
      isCalculated,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      hasFormulas,
      duplicateToReport,
      isClientHide,
    } = this.props;

    return React.createElement(UnlockableInputMenu, {
      name,
      validationMessage,
      tooltipMessage,
      isRequired,
      isCalculated,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      hasFormulas,
      duplicateToReport,
      isClientHide,
      onNameChange: this.onNameChange,
      onValidationMessageChange: this.onValidationMessageChange,
      onRequirementChange: this.onRequirementChange,
      onDuplicateToReportChange: this.onDuplicateToReportChange,
      onCalculatedChange: this.onCalculatedChange,
      onSumButtonClick: this.onSumButtonClick,
      onTooltipMessageChange: this.onTooltipMessageChange,
      onClientHideCheckboxChange: this.onClientHideCheckboxChange,
    });
  }

  @autobind
  protected onNameChange(value: string) {
    this.props.editField({
      id: this.props.id,
      name: (value || "").trim(),
    });
  }

  @autobind
  protected onValidationMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      validationMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onTooltipMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      tooltipMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onRequirementChange() {
    this.props.editField({
      id: this.props.id,
      isRequired: !this.props.isRequired,
    });
  }

  @autobind
  protected onClientHideCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isClientHide: !this.props.isClientHide,
    });
  }

  @autobind
  protected onDuplicateToReportChange() {
    this.props.editField({
      id: this.props.id,
      duplicateToReport: !this.props.duplicateToReport,
    });
  }

  @autobind
  protected onCalculatedChange() {
    this.props.editField({
      id: this.props.id,
      isCalculated: !this.props.isCalculated,
    });
  }

  @autobind
  protected onSumButtonClick() {
    const { id } = this.props;

    this.props.setCalculationLogicPopupParams({
      visibility: true,
      selectedFieldId: id,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.id);
  const isFormulaEditable = state.brief.isFormulaEditable;
  const isSchemaEditable = state.brief.isSchemeEditable;
  const isSchemaActive = state.brief.isSchemeActive;

  const {
    name,
    validationMessage,
    tooltipMessage,
    isRequired,
    isCalculated,
    duplicateToReport,
    isClientHide,
    formulas,
    parentFieldId,
  } = field.properties;

  return {
    name,
    validationMessage,
    tooltipMessage,
    isRequired,
    isCalculated,
    isSchemaActive,
    isSchemaEditable: isSchemaEditable || (isSchemaActive && !parentFieldId),
    isFormulaEditable,
    hasFormulas: Boolean(formulas?.length),
    duplicateToReport,
    isClientHide,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return bindActionCreators(
    {
      editField,
      setCalculationLogicPopupParams,
    },
    dispatch
  );
}
