import * as React from "react";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { LineId } from "../types";
import type {
  CreativeRequestContract,
  CreativeRequestContractCreateParams,
} from "@api";

import { MrmClient } from "@api";

interface Table {
  onLineCreate: (lineId: string) => void;
  onLineUpdate: (lineId: string) => void;
  onLineReloaded: (lineId: string) => void;
}

export interface ChildrenProps {
  loading: boolean;
  getLine: (lineId: LineId) => CreativeRequestContract;
  getLines: () => CreativeRequestContract[];
  createLine: (params: CreativeRequestContractCreateParams) => Promise<void>;
  archiveLine: (lineId: string) => Promise<void>;
  restoreLine: (lineId: string) => Promise<void>;
}

interface Props extends ExternalProps {}

interface ExternalProps {
  tableRef: React.MutableRefObject<Table>;
  children: (props: ChildrenProps) => JSX.Element;
}

interface State {
  loading: boolean;
}

export class WithClientData extends React.PureComponent<Props, State> {
  private lines: CreativeRequestContract[] = [];

  public constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  public async componentDidMount() {
    await this.init();
  }

  public render(): JSX.Element {
    const childrenProps = this.makeChildrenProps();

    return this.props.children(childrenProps);
  }

  private makeChildrenProps(): ChildrenProps {
    const { loading } = this.state;

    return {
      loading,
      getLine: this.getLine,
      getLines: this.getLines,
      createLine: this.createLine,
      archiveLine: this.archiveLine,
      restoreLine: this.restoreLine,
    };
  }

  @autobind
  protected async onLineCreate(payload: {
    event: string;
    data: { id: string };
  }) {
    const lineId = payload.data.id;

    await this.loadLine(lineId);

    const line = this.getLine(lineId);

    if (line) {
      line.events.onReloaded(this.onLineReloaded);
      this.subscribeLineChanges(lineId, this.onLineUpdate);
      this.props.tableRef.current.onLineCreate(lineId);
    }
  }

  @autobind
  private async onLineReloaded(newLine: CreativeRequestContract) {
    this.lines
      .filter((item) => item.model.id !== newLine.model.id)
      .push(newLine);

    this.lines = lodash
      .chain(this.lines)
      .filter((item) => item.model.id !== newLine.model.id)
      .push(newLine)
      .sortBy((item) => item.model.createdAt)
      .value();

    newLine.events.onReloaded(this.onLineReloaded);
    this.subscribeLineChanges(newLine.model.id, this.onLineUpdate);

    this.props.tableRef.current.onLineReloaded(newLine.model.id);
  }

  @autobind
  protected onLineUpdate(lineId: LineId) {
    this.props.tableRef.current.onLineUpdate(lineId);
  }

  private async init() {
    await this.loadLines();

    this.subscribeLineCreation(this.onLineCreate);

    this.lines.forEach((line) => {
      line.events.onReloaded(this.onLineReloaded);
      this.subscribeLineChanges(line.model.id, this.onLineUpdate);
    });

    this.setState({
      loading: false,
    });
  }

  private async loadLines(): Promise<void> {
    const client = await MrmClient.getInstance();

    const lines: CreativeRequestContract[] =
      await client.domain.creativeRequests.getContracts({ archived: true });

    this.lines = lodash.sortBy(lines, (item) => item.model.createdAt);
  }

  private async loadLine(lineId: string): Promise<void> {
    const client = await MrmClient.getInstance();

    const line: CreativeRequestContract =
      await client.domain.creativeRequests.getContract({
        id: lineId,
      });

    this.lines.push(line);

    this.lines = lodash.sortBy(this.lines, (item) => item.model.createdAt);
  }

  private subscribeLineChanges(lineId: string, handler: (id: LineId) => void) {
    const line = this.getLine(lineId);

    if (line) {
      line.events.onUpdated(() => handler(lineId));
    }
  }

  private async subscribeLineCreation(
    handler: (payload: { event: string; data: { id: string } }) => void
  ) {
    const client = await MrmClient.getInstance();

    client.events.creativeRequests.on("ContractCreated", handler as any);
  }

  @autobind
  private getLine(lineId: LineId): CreativeRequestContract {
    return this.lines.find((item) => item.model.id === lineId) || null;
  }

  @autobind
  private getLines(): CreativeRequestContract[] {
    return this.lines || [];
  }

  @autobind
  private async createLine(params: CreativeRequestContractCreateParams) {
    const client = await MrmClient.getInstance();

    await client.api.creativeRequests.createContract(params);
  }

  @autobind
  private async archiveLine(lineId: string) {
    const line = this.getLine(lineId);

    await line.model.archive();
  }

  @autobind
  private async restoreLine(lineId: string) {
    const line = this.getLine(lineId);

    await line.model.return();
  }
}
