export enum ItemType {
  BLOCK = "block",
  BLOCK_SORT = "block_sort",
  TEXT = "text",
  DROPDOWN = "dropdown",
  SWITCH_GROUP = "switch_group",
  UNLOCKABLE_INPUT = "unlockable_input",
  FILE = "file",
}

export const BLOCK_PARENT_DRAG = "block_parent";
