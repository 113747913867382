// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-_DepartmentRolePage__root{display:block}.src-modules-DepartmentRolePage-_DepartmentRolePage__sidebar{padding:40px 0}.src-modules-DepartmentRolePage-_DepartmentRolePage__content{padding:40px 0}\n", "",{"version":3,"sources":["webpack://./src/modules/DepartmentRolePage/DepartmentRolePage.scss"],"names":[],"mappings":"AAAA,0DACI,aAAc,CACjB,6DAGG,cAAe,CAClB,6DAGG,cAAe","sourcesContent":[".root {\n    display: block;\n}\n\n.sidebar {\n    padding: 40px 0;\n}\n\n.content {\n    padding: 40px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DepartmentRolePage-_DepartmentRolePage__root",
	"sidebar": "src-modules-DepartmentRolePage-_DepartmentRolePage__sidebar",
	"content": "src-modules-DepartmentRolePage-_DepartmentRolePage__content"
};
export default ___CSS_LOADER_EXPORT___;
