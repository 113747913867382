import * as React from "react";
import { Link } from "react-router-dom";

import { Shadow } from "@sm/ui";

import * as styles from "./CardWithControls.scss";

interface Props {
  cardLink?: string;
  title: string;
  isHovered: boolean;
  leftControls?: JSX.Element;
  rightControls?: JSX.Element;
  hoveredContent?: JSX.Element;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

export function CardWithControls({
  cardLink,
  title,
  isHovered,
  leftControls,
  rightControls,
  hoveredContent,
  onMouseOver,
  onMouseOut,
}: Props): JSX.Element {
  const content = (
    <div className={styles.content}>
      <div className={styles.title} title={title}>
        {title}
      </div>

      {(leftControls || rightControls) && (
        <div className={styles.footer}>
          {leftControls && (
            <div className={styles.controls}>{leftControls}</div>
          )}

          {rightControls && (
            <div className={styles.controls}>{rightControls}</div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={styles.root}
      onMouseOver={hoveredContent ? onMouseOver : null}
      onMouseOut={hoveredContent ? onMouseOut : null}
    >
      <Shadow>
        {cardLink ? (
          <Link to={cardLink} className={styles.link}>
            {content}
          </Link>
        ) : (
          <div className={styles.link}>{content}</div>
        )}
      </Shadow>

      {hoveredContent && isHovered && (
        <div className={styles.hoveredContent}>{hoveredContent}</div>
      )}
    </div>
  );
}
