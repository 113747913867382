import * as React from "react";
import autobind from "autobind-decorator";

import { IconType } from "@sm/ui";
import { SelectMenu, SelectMenuItemParams } from "./SelectMenu";

interface Props {
  items: SelectMenuItemParams[];
  openerIcon?: IconType;
  openerClass?: string;
  contentClass?: string;
  stopMenuClickEventPropagation?: boolean;
}

interface State {
  isOpen: boolean;
}

export class SelectMenuContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public render(): JSX.Element {
    return React.createElement(SelectMenu, {
      items: this.props.items,
      isOpen: this.state.isOpen,
      onOpenIconClick: this.onOpenClick,
      onClick: this.onItemClick,
      openerIcon: this.props.openerIcon,
      openerClass: this.props.openerClass,
      contentClass: this.props.contentClass,
    });
  }

  @autobind
  protected onOpenClick(event: React.MouseEvent<HTMLDivElement>) {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    if (this.props.stopMenuClickEventPropagation) {
      event.stopPropagation();
    }
  }

  @autobind
  protected onItemClick(
    itemOnClick: () => void,
    event: React.MouseEvent<HTMLDivElement>
  ) {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    itemOnClick();

    if (this.props.stopMenuClickEventPropagation) {
      event.stopPropagation();
    }
  }
}
