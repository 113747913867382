import * as React from "react";

import * as styles from "./BudgetAccessPage.scss";

import { Page } from "../common/Page";
import { Table } from "./Table";

interface Props {
  preloader: boolean;
  pageLabel: string;
  budgetId: string;
}

interface HeaderProps {}

export function BudgetAccessPage({
  preloader,
  pageLabel,
  budgetId,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      customFirstLine={() => <Header />}
      fixedLeftContent
      customLayout
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <Table budgetId={budgetId} />
        </div>
      </div>
    </Page>
  );
}

const Header = ({}: HeaderProps) => (
  <div className={styles.headerRoot}>
    <div className={styles.headerContent}>
      <div className={styles.headerTitle}>Доступ к бюджетам</div>
    </div>
  </div>
);
