import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import * as style from "./Text.scss";

import { ElementPropertiesParams } from "../../../../../../store/brief/types";

import {
  LabeledInput,
  InputType,
  LabeledTextarea,
  Icon,
  IconType,
  WithTooltip,
} from "@sm/ui";

export interface TextProps extends ElementPropertiesParams {
  isSelected: boolean;
  onToggleSelect: React.MouseEventHandler<HTMLDivElement>;
  onRemoveClick: () => void;
  isPreviewModeEnabled?: boolean;
}

const initialFields = [{}];

export function Text({
  isSelected,
  name,
  tooltipMessage,
  isInput,
  isRange,
  isCalendar,
  isCalendarDefault,
  isNumeric,
  isBlockCalculation,
  isMultiple,
  canRemove,
  onToggleSelect,
  onRemoveClick,
  isPreviewModeEnabled,
}: TextProps): JSX.Element[] {
  const [fields, setFields] = React.useState(initialFields);
  const hasTooltip = !!tooltipMessage;

  let type = InputType.Input;

  if (isRange) {
    type = InputType.Range;
  }

  if (isCalendar) {
    type = InputType.DatePicker;
  }

  return (isPreviewModeEnabled ? fields : initialFields).map((field, index) => (
    <div
      key={index}
      className={classNames(
        style.root,
        isSelected && style.onEdit,
        isPreviewModeEnabled && style.previewMode,
        isBlockCalculation && style.inputCalculation
      )}
      onClick={onToggleSelect}
    >
      {isInput ? (
        <LabeledInput
          value={isCalendarDefault && isPreviewModeEnabled ? moment() : ""}
          title={name || "Новое текстовое поле"}
          type={type}
          onlyNumbers={isNumeric}
          readOnly={!isPreviewModeEnabled || isBlockCalculation}
        />
      ) : (
        <LabeledTextarea
          value={""}
          title={name || "Новое текстовое поле"}
          readOnly={!isPreviewModeEnabled}
        />
      )}

      {isMultiple && (
        <div
          className={classNames(
            style.iconMultiple,
            isCalendar && style.iconMultipleCalendar
          )}
          onClick={() => isPreviewModeEnabled && setFields([...fields, {}])}
        >
          <Icon type={IconType.PLUS_20} />
        </div>
      )}

      {isPreviewModeEnabled && hasTooltip && (
        <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
          <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
        </WithTooltip>
      )}

      {canRemove && isSelected && (
        <div className={style.icon} onClick={onRemoveClick}>
          <Icon type={IconType.CROSS_BRIEF} />
        </div>
      )}
    </div>
  ));
}
