import * as React from "react";
import { Action } from "./enums";

import { RemoveButton } from "./ButtonsGroup";
import type { ButtonProps } from "./ButtonsGroup";

export const operationTypeNamesMap: Record<Action, string> = {
  [Action.REMOVE]: "Удалить",
};

export const actions: Action[] = [Action.REMOVE];

export const buttonsComponentsMap: Record<Action, React.FC<ButtonProps>> = {
  [Action.REMOVE]: RemoveButton,
};
