import * as React from "react";
import classnames from "classnames";
import {
  CalculatorDictionaryType,
  DictionaryDataSchemaTotalOperations,
} from "@sm/types/budget";

import { OperationType } from "../../BriefPage/CalculationLogicPopup/FormulasBuilder/enums";
import { OperationButtons } from "../../BriefPage/CalculationLogicPopup/FormulasBuilder/Operation/OperationButtons";

import {
  Button_redesign as Button,
  ButtonTheme_redesign,
  Icon,
  IconType,
} from "@sm/ui";

import { SchemaField } from "./SchemaField";

import * as styles from "./EditOrCreateDictionaryForm.scss";

const schemaArrayFields = {
  [CalculatorDictionaryType.CALC_MATERIAL_TOTAL]: TotalSchemaArrayFields,
  [CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY]:
    ConformitySchemaArrayFields,
  [CalculatorDictionaryType.CALC_MATERIAL_RANGE]: RangeSchemaArrayFields,
};

const schemaTotalsOperationsMap = {
  [DictionaryDataSchemaTotalOperations.ADDITION]: OperationType.ADDITION,
  [DictionaryDataSchemaTotalOperations.DIVISION]: OperationType.DIVISION,
  [DictionaryDataSchemaTotalOperations.MULTIPLICATION]:
    OperationType.MULTIPLICATION,
  [DictionaryDataSchemaTotalOperations.SUBTRACTION]: OperationType.SUBTRACTION,
};

const schemaTotalsValuesMap = {
  [OperationType.ADDITION]: DictionaryDataSchemaTotalOperations.ADDITION,
  [OperationType.DIVISION]: DictionaryDataSchemaTotalOperations.DIVISION,
  [OperationType.MULTIPLICATION]:
    DictionaryDataSchemaTotalOperations.MULTIPLICATION,
  [OperationType.SUBTRACTION]: DictionaryDataSchemaTotalOperations.SUBTRACTION,
};

const getTotal = ({ quantity, cost, total }: any) => {
  let value = 0;
  let quantityValue = parseFloat(quantity) || 0;
  let costValue = parseFloat(cost) || 0;

  switch (total) {
    case DictionaryDataSchemaTotalOperations.ADDITION:
      value = quantityValue + costValue;
      break;
    case DictionaryDataSchemaTotalOperations.SUBTRACTION:
      value = quantityValue - costValue;
      break;
    case DictionaryDataSchemaTotalOperations.MULTIPLICATION:
      value = quantityValue * costValue;
      break;
    case DictionaryDataSchemaTotalOperations.DIVISION:
      value = costValue ? quantityValue / costValue : 0;
      break;
    default:
      value = 0;
  }

  return (value || 0).toFixed(2);
};

interface SchemaArrayFieldProps {
  type: string;
  schemaType: string;
  required: boolean;
  description: string;
  value: Array<any>;
  fields?: any;
  onChange: (value: any) => void;
}

export function SchemaArrayField({
  type,
  schemaType,
  required,
  value,
  fields,
  onChange,
}: SchemaArrayFieldProps): JSX.Element {
  const defaultValue = required ? [{ id: "1" }] : [];
  const arrayValue = value && value.length ? value : defaultValue;
  const deleteLength = required ? 1 : 0;
  const hasDelete = arrayValue.length > deleteLength;

  const Fields = schemaArrayFields[schemaType] || SchemaArrayFields;

  return (
    <div className={styles.inputWrapper}>
      {fields &&
        arrayValue.map((itemValue, index) => (
          <div
            key={index}
            className={classnames(
              styles.schemaBlock,
              hasDelete && styles.schemaBlockDelete
            )}
          >
            {hasDelete && (
              <div className={styles.schemeRemoveButtonWrapper}>
                <Button
                  theme={ButtonTheme_redesign.Ghost}
                  onClick={() =>
                    onChange(
                      arrayValue.filter(
                        (value, valueIndex) => index !== valueIndex
                      )
                    )
                  }
                >
                  <span>
                    <Icon type={IconType.CROSS} svgSize={7} /> Удалить
                  </span>
                </Button>
              </div>
            )}

            <Fields
              index={index}
              itemValue={itemValue}
              arrayValue={arrayValue}
              fields={fields}
              onChange={(fieldValue, index, key) => {
                onChange(
                  arrayValue.map((value, valueIndex) =>
                    index === valueIndex
                      ? { ...value, [key]: fieldValue }
                      : value
                  )
                );
              }}
            />
          </div>
        ))}
      <div className={styles.schemeAddButtonWrapper}>
        <Button
          theme={ButtonTheme_redesign.Ghost}
          onClick={() =>
            onChange(
              value
                ? [...value, { id: `${value.length + 1}` }]
                : [...defaultValue, { id: `${defaultValue.length + 1}` }]
            )
          }
        >
          <span>
            <Icon type={IconType.PLUS_20} svgSize={16} /> Добавить
          </span>
        </Button>
      </div>
    </div>
  );
}

interface SchemaArrayFieldsProps {
  index: number;
  itemValue: string;
  arrayValue: Array<any>;
  fields?: any;
  onChange: (value: any, index: number, key: string) => void;
}

function TotalSchemaArrayFields({
  index,
  arrayValue,
  itemValue,
  fields,
  onChange,
}: SchemaArrayFieldsProps): JSX.Element {
  React.useEffect(() => {
    if (!itemValue?.["total"]) {
      onChange(
        DictionaryDataSchemaTotalOperations.MULTIPLICATION,
        index,
        "total"
      );
    }
  }, []);

  return (
    <div>
      <SchemaField
        key={"name"}
        required={fields["name"].required}
        description={fields["name"].description}
        type={fields["name"].type}
        value={itemValue?.["name"]}
        onChange={(fieldValue) => onChange(fieldValue, index, "name")}
      />
      <div className={styles.rangeInputWrapper}>
        <div>
          <SchemaField
            key={"quantity"}
            required={fields["quantity"].required}
            description={fields["quantity"].description}
            type={fields["quantity"].type}
            value={itemValue?.["quantity"]}
            onChange={(fieldValue) => onChange(fieldValue, index, "quantity")}
          />
        </div>
        <div className={classnames(styles.separator, styles.operation)}>
          <OperationButtons
            operation={schemaTotalsOperationsMap[itemValue?.["total"]]}
            onSelect={(value) =>
              onChange(schemaTotalsValuesMap[value], index, "total")
            }
          />
        </div>
        <div>
          <SchemaField
            key={"cost"}
            required={fields["cost"].required}
            description={fields["cost"].description}
            type={fields["cost"].type}
            value={itemValue?.["cost"]}
            onChange={(fieldValue) => onChange(fieldValue, index, "cost")}
          />
        </div>
      </div>
      <div
        className={classnames(styles.inputWrapper, styles.inputWrapperPadding)}
      >
        <b>Итог: {getTotal(arrayValue[index])} ₽</b>
      </div>
    </div>
  );
}

function ConformitySchemaArrayFields({
  index,
  arrayValue,
  itemValue,
  fields,
  onChange,
}: SchemaArrayFieldsProps): JSX.Element {
  return (
    <div>
      <SchemaField
        key={"name"}
        required={fields["name"].required}
        description={fields["name"].description}
        type={fields["name"].type}
        value={itemValue?.["name"]}
        onChange={(fieldValue) => onChange(fieldValue, index, "name")}
      />
      <div className={styles.costInputWrapper}>
        <SchemaField
          key={"cost"}
          required={fields["cost"].required}
          description={fields["cost"].description}
          type={fields["cost"].type}
          value={itemValue?.["cost"]}
          onChange={(fieldValue) => onChange(fieldValue, index, "cost")}
        />
      </div>
    </div>
  );
}

function RangeSchemaArrayFields({
  index,
  arrayValue,
  itemValue,
  fields,
  onChange,
}: SchemaArrayFieldsProps): JSX.Element {
  return (
    <div>
      <SchemaField
        key={"name"}
        required={fields["name"].required}
        description={fields["name"].description}
        type={fields["name"].type}
        value={itemValue?.["name"]}
        onChange={(fieldValue) => onChange(fieldValue, index, "name")}
      />
      <div className={styles.rangeInputWrapper}>
        <div>
          <SchemaField
            key={"from"}
            required={fields["from"].required}
            description={fields["from"].description}
            type={fields["from"].type}
            value={itemValue?.["from"]}
            onChange={(fieldValue) => onChange(fieldValue, index, "from")}
          />
        </div>
        <div className={styles.separator}>—</div>
        <div>
          <SchemaField
            key={"to"}
            required={fields["to"].required}
            description={fields["to"].description}
            type={fields["to"].type}
            value={itemValue?.["to"]}
            onChange={(fieldValue) => onChange(fieldValue, index, "to")}
          />
        </div>
      </div>
      <div className={styles.costInputWrapper}>
        <SchemaField
          key={"cost"}
          required={fields["cost"].required}
          description={fields["cost"].description}
          type={fields["cost"].type}
          value={itemValue?.["cost"]}
          onChange={(fieldValue) => onChange(fieldValue, index, "cost")}
        />
      </div>
    </div>
  );
}

function SchemaArrayFields({
  index,
  arrayValue,
  itemValue,
  fields,
  onChange,
}: SchemaArrayFieldsProps): JSX.Element {
  return (
    <div>
      {Object.keys(fields).map((key) => (
        <SchemaField
          key={key}
          required={fields[key].required}
          description={fields[key].description}
          dictionary={fields[key].dictionary}
          type={fields[key].type}
          value={itemValue?.[key]}
          onChange={(fieldValue) => onChange(fieldValue, index, key)}
        />
      ))}
    </div>
  );
}
