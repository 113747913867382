import { actionCreatorFactory } from "typescript-fsa";

import { DepartmentUserAttributes } from "@sm/types/admin";
import { UpdateUserRoleParams } from "../types";

const actionCreator = actionCreatorFactory("DEPARTMENT_ROLE_PAGE_USERS");

const loadUsers = actionCreator<DepartmentUserAttributes[]>("LOAD_USERS");
const updateUserRole = actionCreator<UpdateUserRoleParams>("UPDATE_USER_ROLE");
const loadDepartmentUsers = actionCreator<DepartmentUserAttributes[]>(
  "LOAD_DEPARTMENT_USERS"
);
const removeUser = actionCreator<number>("REMOVE_USER");
const removeAvailableUser = actionCreator<number>("REMOVE_AVAILABLE_USER");

export {
  loadUsers,
  updateUserRole,
  loadDepartmentUsers,
  removeUser,
  removeAvailableUser,
};
