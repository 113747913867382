// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__link{max-width:100%;height:24px;display:flex;align-items:center;gap:6px}.src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__linkDragging{opacity:0}.src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__linkText{max-width:calc(100% - 50px);overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__icon{cursor:pointer;color:#7F8681}.src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__icon:hover{color:black}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/DictionaryLinksMenu/DragNDropLinkItem/DragNDropLinkItem.scss"],"names":[],"mappings":"AAAA,6FACI,cAAe,CACf,WAAY,CAEZ,YAAa,CACb,kBAAmB,CACnB,OAAQ,CACX,qGAGG,SAAU,CACb,iGAGG,2BAA4B,CAE5B,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CACtB,6FAGG,cAAe,CAEf,aAAc,CAHlB,mGAMQ,WAAY","sourcesContent":[".link {\n    max-width: 100%;\n    height: 24px;\n\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n\n.linkDragging {\n    opacity: 0;\n}\n\n.linkText {\n    max-width: calc(100% - 50px);\n\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.icon {\n    cursor: pointer;\n\n    color: #7F8681;\n\n    &:hover {\n        color: black;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__link",
	"linkDragging": "src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__linkDragging",
	"linkText": "src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__linkText",
	"icon": "src-modules-DictionariesPage-DictionaryLinksMenu-DragNDropLinkItem-_DragNDropLinkItem__icon"
};
export default ___CSS_LOADER_EXPORT___;
