import * as React from "react";
import {
  FilterItem,
  Icon,
  IconType,
  StyledPopup,
  StyledPopupTheme,
  Preloader,
} from "sber-marketing-ui";
import {
  PlainDictionary,
  DictionaryType,
  DictionaryStatus,
} from "@sm/types/budget";
import * as lodash from "lodash";

import { DictionaryApi } from "@api";

import { Page } from "../common/Page";
import { getDeclension } from "@common/Utils";

import { TableControlsContext, TableState, defaultTableState } from "./common";
import { DictionaryTypes } from "./DictionaryTypeNames";

import { Table } from "./Table";
import { TextCell, ActionsCell } from "./TableCells";
import { DictionaryLinksMenu } from "./DictionaryLinksMenu";
import { EditOrCreateDictionaryForm } from "./EditOrCreateDictionaryForm";
import { DictionaryLinksValidationMarker } from "./DictionaryLinksValidationMarker";

import * as styles from "./DictionariesPage.scss";

interface DictionariesState {
  loaded: boolean;
  dictionaries: PlainDictionary[];
}

interface Props {
  pageLabel: string;
}

const enum TableColumns {
  Type = "Type",
  Value = "Value",
  Code = "Code",
  Parents = "Parents",
  Children = "Children",
  Actions = "Actions",
}

const Columns = {
  [TableColumns.Type]: {
    id: TableColumns.Type,
    title: "Тип справочника",
    width: 220,
  },
  [TableColumns.Value]: {
    id: TableColumns.Value,
    title: "Название",
    width: 220,
  },
  [TableColumns.Code]: {
    id: TableColumns.Code,
    title: "Код справочника",
    width: 220,
  },
  [TableColumns.Parents]: {
    id: TableColumns.Parents,
    title: "Родительские справочники",
    width: 240,
    skipFilter: true,
  },
  [TableColumns.Children]: {
    id: TableColumns.Children,
    title: "Дочерние справочники",
    width: 240,
    skipFilter: true,
  },
  [TableColumns.Actions]: {
    id: TableColumns.Actions,
    title: "Действия",
    width: 80,
    skipFilter: true,
    skipOrdering: true,
  },
};

function useTableColumns() {
  const NO_CODE_MARKER = "Не задан";
  const NO_LINKS_MARKER = "—";

  function formatLinksLength(links: any[]): string {
    if (!links?.length) {
      return NO_LINKS_MARKER;
    }

    return `${links.length} ${getDeclension(links.length, [
      "связь",
      "связи",
      "связей ",
    ])}`;
  }

  function cellRenderer(
    dictionary: PlainDictionary,
    cell: TableColumns
  ): JSX.Element {
    switch (cell) {
      case TableColumns.Type:
        return <TextCell content={DictionaryTypes[dictionary.type]} />;
      case TableColumns.Value:
        return <TextCell content={dictionary.value} />;
      case TableColumns.Code:
        return <TextCell content={dictionary.code} />;
      case TableColumns.Parents:
        return <TextCell content={formatLinksLength(dictionary.parentRefs)} />;
      case TableColumns.Children:
        return (
          <TextCell content={formatLinksLength(dictionary.childrenRefs)} />
        );
      case TableColumns.Actions:
        return <ActionsCell dictionary={dictionary} />;
      default:
        return null;
    }
  }

  function getFilterItem(
    dictionary: PlainDictionary,
    cell: TableColumns
  ): FilterItem {
    let value: string;

    switch (cell) {
      case TableColumns.Type:
        const type = DictionaryTypes[dictionary.type] || "";

        return {
          id: type,
          title: type,
        };
      case TableColumns.Value:
        return {
          id: dictionary.value,
          title: dictionary.value,
        };
      case TableColumns.Code:
        value = dictionary.code || NO_CODE_MARKER;
        return {
          id: value,
          title: value,
          order: dictionary.code ? 1 : 0,
        };
      case TableColumns.Parents:
        value = formatLinksLength(dictionary.parentRefs);

        return {
          id: value,
          title: value,
          order: dictionary.parentRefs?.length || 0,
        };
      case TableColumns.Children:
        value = formatLinksLength(dictionary.childrenRefs);

        return {
          id: value,
          title: value,
          order: dictionary.childrenRefs?.length || 0,
        };
      case TableColumns.Actions:
      default:
        return null;
    }
  }

  function filterTableRow(
    dictionary: PlainDictionary,
    cell: TableColumns,
    filterValues: Set<string>
  ): boolean {
    switch (cell) {
      case TableColumns.Type:
        return filterValues.has(DictionaryTypes[dictionary.type]);
      case TableColumns.Value:
        return filterValues.has(dictionary.value);
      case TableColumns.Code:
        if (filterValues.has(NO_CODE_MARKER) && !dictionary.code) {
          return true;
        }

        return filterValues.has(dictionary.code);
      case TableColumns.Parents:
        return filterValues.has(formatLinksLength(dictionary.parentRefs));
      case TableColumns.Children:
        return filterValues.has(formatLinksLength(dictionary.childrenRefs));
      case TableColumns.Actions:
      default:
        return false;
    }
  }

  function orderTableRow(
    dictionary: PlainDictionary,
    cell: TableColumns
  ): string | number {
    switch (cell) {
      case TableColumns.Type:
        return DictionaryTypes[dictionary.type];
      case TableColumns.Value:
        return dictionary.value;
      case TableColumns.Code:
        return dictionary.code || "";
      case TableColumns.Parents:
        return dictionary.parentRefs?.length || 0;
      case TableColumns.Children:
        return dictionary.childrenRefs?.length || 0;
      case TableColumns.Actions:
      default:
        return null;
    }
  }

  return { cellRenderer, getFilterItem, filterTableRow, orderTableRow };
}

function useDictionariesPage() {
  const [dictionariesState, setDictionariesState] =
    React.useState<DictionariesState>({
      loaded: false,
      dictionaries: [],
    });
  const [showDeletedDictionaries, setShowDeletedDictionaries] =
    React.useState(false);
  const [tableState, setTableState] =
    React.useState<TableState>(defaultTableState);
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
  const { cellRenderer, getFilterItem, filterTableRow, orderTableRow } =
    useTableColumns();

  function filterDictionaries(dictionary: PlainDictionary): boolean {
    const typeMatch = !!DictionaryTypes[dictionary.type];
    const statusMatch =
      dictionary.status ===
      (showDeletedDictionaries
        ? DictionaryStatus.DELETED
        : DictionaryStatus.ACTIVE);

    return typeMatch && statusMatch;
  }

  function resetTableState() {
    setTableState(defaultTableState);
  }

  async function fetchPageData() {
    // setDictionaries((await DictionaryApi.getDictionaryList({ treeview: true })).filter(dictionary => dictionary.code?.includes('мультисвязи')));
    setDictionariesState({
      loaded: true,
      dictionaries: await DictionaryApi.getDictionaryList({ treeview: true }),
    });
  }

  async function deleteDictionary() {
    if (tableState._type !== "deleteDictionary") {
      console.warn(
        `Unable to deleteDictionary in non-deleteDictionary tableState`
      );
    } else {
      resetTableState();
      setIsRequestInProgress(true);

      await DictionaryApi.deleteDictionary(tableState.dictionary.id);
      await fetchPageData();
      setIsRequestInProgress(false);
    }
  }

  async function restoreDictionary(id: string) {
    setIsRequestInProgress(true);
    await DictionaryApi.updateDictionaryItem(id, {
      status: DictionaryStatus.ACTIVE,
    });
    await refetchPageData();
    setIsRequestInProgress(false);
  }

  async function refetchPageData() {
    setIsRequestInProgress(true);
    await fetchPageData();
    setIsRequestInProgress(false);
  }

  function pushDictionaryForLinksEdit(dictionary: PlainDictionary) {
    if (tableState._type !== "editDictionaryLinks") {
      console.warn(
        `Unable to pushDictionaryForLinksEdit in non-editDictionaryLinks tableState`
      );
    } else {
      setTableState({
        _type: "editDictionaryLinks",
        dictionaryIds: [...tableState.dictionaryIds, dictionary.id],
      });
    }
  }

  function popDictionaryForLinksEdit() {
    if (tableState._type !== "editDictionaryLinks") {
      console.warn(
        `Unable to pushDictionaryForLinksEdit in non-editDictionaryLinks tableState`
      );
    } else {
      setTableState({
        _type: "editDictionaryLinks",
        dictionaryIds: tableState.dictionaryIds.slice(
          0,
          tableState.dictionaryIds.length - 1
        ),
      });
    }
  }

  React.useEffect(function onMounted() {
    fetchPageData();
  }, []);

  return {
    isRequestInProgress,
    cellRenderer,
    getFilterItem,
    filterTableRow,
    orderTableRow,
    filterDictionaries,
    dictionariesState,
    showDeletedDictionaries,
    setShowDeletedDictionaries,
    tableState,
    setTableState,
    resetTableState,
    refetchPageData,
    deleteDictionary,
    pushDictionaryForLinksEdit,
    popDictionaryForLinksEdit,
    restoreDictionary,
  };
}

export function DictionariesPage({ pageLabel }: Props): JSX.Element {
  const {
    isRequestInProgress,
    cellRenderer,
    getFilterItem,
    filterTableRow,
    orderTableRow,
    filterDictionaries,
    dictionariesState,
    showDeletedDictionaries,
    setShowDeletedDictionaries,
    tableState,
    setTableState,
    resetTableState,
    refetchPageData,
    deleteDictionary,
    pushDictionaryForLinksEdit,
    popDictionaryForLinksEdit,
    restoreDictionary,
  } = useDictionariesPage();

  const showDictionaryLinksMenu = tableState._type === "editDictionaryLinks";
  const showDeleteDictionaryPopup = tableState._type === "deleteDictionary";
  const showCreateDictionaryForm = tableState._type === "createDictionary";
  const showEditDictionaryForm = tableState._type === "editDictionaryData";

  return (
    <Page
      preloader={!dictionariesState.loaded}
      pageLabel={pageLabel}
      withoutBackButton
      customFirstLine={() => (
        <Header
          showDeletedDictionaries={showDeletedDictionaries}
          setShowDeletedDictionaries={setShowDeletedDictionaries}
        />
      )}
      fixedLeftContent
      customLayout
    >
      <div className={styles.root}>
        <div className={styles.rootContent}>
          <div className={styles.rootControls}>
            <div
              className={styles.addDictionaryButton}
              onClick={() => setTableState({ _type: "createDictionary" })}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.16637 7.16637C2.94454 10.3882 2.94454 15.6118 6.16637 18.8336C9.38819 22.0555 14.6118 22.0555 17.8336 18.8336C21.0555 15.6118 21.0555 10.3882 17.8336 7.16637C14.6118 3.94454 9.38819 3.94454 6.16637 7.16637ZM7.43916 8.43916C4.92028 10.958 4.92028 15.042 7.43916 17.5608C9.95804 20.0797 14.042 20.0797 16.5608 17.5608C19.0797 15.042 19.0797 10.958 16.5608 8.43916C14.042 5.92028 9.95804 5.92028 7.43916 8.43916Z"
                  fill="#7E8681"
                />
                <path
                  d="M8.46417 12.1003C7.96712 12.1003 7.56417 12.5032 7.56417 13.0003C7.56417 13.4973 7.96712 13.9003 8.46417 13.9003H11.0996L11.0996 16.5359C11.0996 17.033 11.5025 17.4359 11.9996 17.4359C12.4966 17.4359 12.8996 17.033 12.8996 16.5359V13.9003H15.5352C16.0323 13.9003 16.4352 13.4973 16.4352 13.0003C16.4352 12.5032 16.0323 12.1003 15.5352 12.1003H12.8996V9.46486C12.8996 8.96781 12.4966 8.56486 11.9996 8.56486C11.5025 8.56486 11.0996 8.96781 11.0996 9.46486L11.0996 12.1003L8.46417 12.1003Z"
                  fill="#7E8681"
                />
              </svg>
              Создать новый справочник
            </div>

            {false && (
              <DictionaryLinksValidationMarker
                dictionaries={dictionariesState.dictionaries}
              />
            )}
          </div>

          <div className={styles.table}>
            <TableControlsContext.Provider
              value={{
                showDeletedDictionaries,
                setTableState,
                restoreDictionary,
              }}
            >
              <Table
                columns={Columns}
                rows={dictionariesState.dictionaries}
                externalFilter={filterDictionaries}
                cellRenderer={cellRenderer}
                getFilterItem={getFilterItem}
                filterTableRow={filterTableRow}
                orderTableRow={orderTableRow}
              />
            </TableControlsContext.Provider>
          </div>

          {showDictionaryLinksMenu && (
            <React.Fragment>
              {tableState.dictionaryIds.map((dictionaryId, i) => (
                <DictionaryLinksMenu
                  key={dictionaryId}
                  isActive={i == tableState.dictionaryIds.length - 1}
                  dictionaries={dictionariesState.dictionaries}
                  sourceDictionary={dictionariesState.dictionaries.find(
                    (dictionary) => dictionary.id === dictionaryId
                  )}
                  onLinksUpdated={refetchPageData}
                  onCloseButtonClick={popDictionaryForLinksEdit}
                  onEditChildDictionaryLinksButtonClick={
                    pushDictionaryForLinksEdit
                  }
                />
              ))}
            </React.Fragment>
          )}
        </div>

        {isRequestInProgress && <Preloader />}
      </div>

      {showDeleteDictionaryPopup && (
        <StyledPopup
          theme={StyledPopupTheme.Redesign}
          qaId="dictionariesPageDeleteReferenceModal"
          title={`Вы уверены, что хотите\nудалить словарь со всеми его связями?`}
          firstButtonText="Удалить"
          firstButtonQaId="dictionariesPageDeleteReferenceModalConfirmButton"
          firstButtonHandler={deleteDictionary}
          secondButtonText="Отмена"
          secondButtonQaId="dictionariesPageDeleteReferenceModalCancleButton"
          secondButtonHandler={resetTableState}
        />
      )}

      {showCreateDictionaryForm && (
        <EditOrCreateDictionaryForm
          closeForm={resetTableState}
          onRequestFinished={refetchPageData}
        />
      )}
      {showEditDictionaryForm && (
        <EditOrCreateDictionaryForm
          dictionary={tableState.dictionary}
          closeForm={resetTableState}
          onRequestFinished={refetchPageData}
        />
      )}
    </Page>
  );
}

interface HeaderProps {
  showDeletedDictionaries: boolean;
  setShowDeletedDictionaries: (value: boolean) => void;
}

function Header({
  showDeletedDictionaries,
  setShowDeletedDictionaries,
}: HeaderProps): JSX.Element {
  function ToggleDeletedDictionariesButton(): JSX.Element {
    return (
      <div className={styles.toggleDeletedDictionariesButton}>
        <Icon
          type={
            showDeletedDictionaries ? IconType.EYE_OPENED : IconType.EYE_CLOSED
          }
          svgSize={24}
        />

        {showDeletedDictionaries ? "Показать активные" : "Показать удаленные"}
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerHiddenItem}>
        <ToggleDeletedDictionariesButton />
      </div>
      Справочники
      <div
        className={styles.headerItemClickable}
        onClick={() => setShowDeletedDictionaries(!showDeletedDictionaries)}
      >
        <ToggleDeletedDictionariesButton />
      </div>
    </div>
  );
}
