import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as lodash from "lodash";

import type { BudgetLine } from "../../api";
import type { PageState } from "./types";

import * as actions from "./actions";

export const initialState: PageState = {
  budgets: [],
  displayArchivedBudgets: false,
};

export class Reducer {
  public static loadBudgets(
    state: PageState,
    payload: BudgetLine[]
  ): PageState {
    return { ...state, budgets: payload };
  }

  public static resetPageState(): PageState {
    return lodash.cloneDeep(initialState);
  }

  public static setBudgetsFilter(
    state: PageState,
    payload: boolean
  ): PageState {
    return { ...state, displayArchivedBudgets: payload };
  }
}

export const budgetsPageReducer = reducerWithInitialState(initialState)
  .case(actions.loadBudgets, Reducer.loadBudgets)
  .case(actions.resetPageState, Reducer.resetPageState)
  .case(actions.setBudgetsFilter, Reducer.setBudgetsFilter);
