import { DepartmentRoleAttributes } from "@sm/types/admin";

import { DepartmentRolePageState, UpdateRoleParams } from "../types";

export class RoleReducer {
  public static addRole(
    state: DepartmentRolePageState,
    role: DepartmentRoleAttributes
  ) {
    const roles = [...state.roles, role];
    return {
      ...state,
      roles,
    };
  }

  public static setSelectedRole(
    state: DepartmentRolePageState,
    roleId: number
  ) {
    return {
      ...state,
      selectedRoleId: roleId,
    };
  }

  public static updateRole(
    state: DepartmentRolePageState,
    params: UpdateRoleParams
  ) {
    const roles = state.roles.map((role) =>
      role.id == params.roleId ? { ...role, name: params.name } : role
    );

    return {
      ...state,
      roles,
    };
  }

  public static setIsOnNewRole(
    state: DepartmentRolePageState,
    isOnNewRole: boolean
  ) {
    return {
      ...state,
      isOnNewRole,
    };
  }

  public static updateRight(
    state: DepartmentRolePageState,
    roleRight: string[]
  ) {
    return {
      ...state,
      roleRight,
    };
  }
}
