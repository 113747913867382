import * as React from "react";

import * as style from "./TextMenu.scss";

import {
  LabeledInput,
  LabeledTextarea,
  Checkbox_redesign,
  Icon,
  IconType,
} from "@sm/ui";

const CHECKBOX_ICON_SIZE = 20;

interface Props {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isCalculated: boolean;
  isBlockCalculation: boolean;
  isNumeric?: boolean;
  isLatin?: boolean;
  isUrl?: boolean;
  isRange?: boolean;
  isCalendar?: boolean;
  isSchemaActive: boolean;
  isSchemaEditable: boolean;
  isFormulaEditable: boolean;
  hasFormulas?: boolean;
  duplicateToReport?: boolean;
  isInput?: boolean;
  isCalendarDefault?: boolean;
  isMultiple?: boolean;
  isClientHide?: boolean;
  onNameChange: (value: string) => void;
  onValidationMessageChange: (value: string) => void;
  onTooltipMessageChange: (value: string) => void;
  onRequirementChange: (isChecked: boolean) => void;
  onNumericChange: (isChecked: boolean) => void;
  onLatinChange: (isChecked: boolean) => void;
  onUrlChange: (isChecked: boolean) => void;
  onRangeChange: (isChecked: boolean) => void;
  onCalendarChange: (isChecked: boolean) => void;
  onDuplicateToReportChange: (isChecked: boolean) => void;
  onTypeChange: (isChecked: boolean) => void;
  onSumButtonClick: () => void;
  onCalculatedChange: (isChecked: boolean) => void;
  onBlockCalculatedChange: (isChecked: boolean) => void;
  onCalendarDefaultChange: (isChecked: boolean) => void;
  onMultipleChange: (isChecked: boolean) => void;
  onClientHideCheckboxChange: (isChecked: boolean) => void;
}

export function TextMenu({
  name = "Название поля",
  validationMessage = "",
  tooltipMessage = "",
  isRequired,
  isCalculated,
  isBlockCalculation,
  isNumeric,
  isLatin,
  isUrl,
  isRange,
  isCalendar,
  isSchemaActive,
  isSchemaEditable,
  isFormulaEditable,
  hasFormulas,
  duplicateToReport,
  isInput,
  isCalendarDefault,
  isMultiple,
  isClientHide,
  onNameChange,
  onValidationMessageChange,
  onTooltipMessageChange,
  onRequirementChange,
  onNumericChange,
  onLatinChange,
  onUrlChange,
  onRangeChange,
  onCalendarChange,
  onDuplicateToReportChange,
  onTypeChange,
  onCalculatedChange,
  onBlockCalculatedChange,
  onSumButtonClick,
  onCalendarDefaultChange,
  onMultipleChange,
  onClientHideCheckboxChange,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.inputProperty}>
        <LabeledInput
          className={isCalculated ? style.nameInput : null}
          title={"Название поля"}
          value={name}
          onValueChange={onNameChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />

        {isCalculated ? (
          hasFormulas ? (
            <div className={style.sumIconWrapper} onClick={onSumButtonClick}>
              <Icon type={IconType.SUM} />
            </div>
          ) : (
            <div className={style.sumIconWrapper} onClick={onSumButtonClick}>
              <Icon type={IconType.ADD_SUM} svgSize={25} />
            </div>
          )
        ) : null}
      </div>

      <div className={style.inputProperty}>
        <LabeledInput
          title={"Текст ошибки"}
          value={validationMessage}
          onValueChange={onValidationMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Обязательное поле"}
          isChecked={isRequired}
          onSwitch={onRequirementChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Только цифры"}
          isChecked={isNumeric}
          onSwitch={onNumericChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable || isLatin || isUrl || isCalendar}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Только латинские символы"}
          isChecked={isLatin}
          onSwitch={onLatinChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable || isNumeric || isUrl || isCalendar}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Только ссылки"}
          isChecked={isUrl}
          onSwitch={onUrlChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable || isNumeric || isLatin || isCalendar}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          disabled={
            !isSchemaEditable || isCalendar || isLatin || isUrl || isMultiple
          }
          title={"Диапазон"}
          isChecked={isRange}
          onSwitch={onRangeChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          disabled={!isSchemaEditable || isRange}
          title={"Календарь"}
          isChecked={isCalendar}
          onSwitch={onCalendarChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      {isCalendar ? (
        <div className={style.checkboxProperty}>
          <Checkbox_redesign
            disabled={!isCalendar}
            title={"Текущая дата по умолчанию"}
            isChecked={isCalendarDefault}
            onSwitch={onCalendarDefaultChange}
            iconSize={CHECKBOX_ICON_SIZE}
          />
        </div>
      ) : null}

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Дублировать в отчет"}
          isChecked={duplicateToReport}
          onSwitch={onDuplicateToReportChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={
            !isSchemaEditable || isRange || isCalculated || isBlockCalculation
          }
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          disabled={
            !isSchemaEditable || isRange || isCalendar || isNumeric || isUrl
          }
          title={"Одна строка"}
          isChecked={isInput}
          onSwitch={onTypeChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Множественные значения"}
          disabled={
            !isSchemaEditable || isRange || isCalculated || isBlockCalculation
          }
          isChecked={isMultiple}
          onSwitch={onMultipleChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Поле входит в расчет калькурятора"}
          disabled={
            !isFormulaEditable || !isNumeric || isLatin || isUrl || isMultiple
          }
          isChecked={isCalculated}
          onSwitch={onCalculatedChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Расчет по блоку"}
          disabled={
            !isFormulaEditable || !isNumeric || isLatin || isUrl || isMultiple
          }
          isChecked={isBlockCalculation}
          onSwitch={onBlockCalculatedChange}
          iconSize={CHECKBOX_ICON_SIZE}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>
    </div>
  );
}
