import * as React from "react";

import * as style from "./FileUploader.scss";

interface Props {
  fileInputRef: (element: HTMLInputElement) => void;
  onAddButtonClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFileAdded: () => void;
  children: JSX.Element;
}

const allowedMimeTypes = [".docx", ".xlsx", ".pptx", ".pdf"];

export const FileUploader = ({
  fileInputRef,
  onAddButtonClick,
  onFileAdded,
  children,
}: Props): JSX.Element => {
  return (
    <div className={style.root} onClick={onAddButtonClick}>
      {children}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onFileAdded}
        accept={allowedMimeTypes?.join(", ")}
        multiple
      />
    </div>
  );
};
