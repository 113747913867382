import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import autobind from "autobind-decorator";

import { BlockParams } from "../../../../store/brief/types";

import { ElementsList } from "./ElementsList";
import { StoreState } from "../../../../store";
import { removeBlock } from "../../../../store/brief/actions";
import { getSchemeListBlocks } from "../../../../store/brief/selector";

interface Props
  extends MapProps,
    DispatchProps,
    Partial<RouteComponentProps<RouteParams>> {}

interface RouteParams {
  briefSchemeId: string;
}

interface MapProps {
  blocks?: BlockParams[];
}

interface DispatchProps {
  removeBlock?: (blockId: string) => void;
}

interface State {
  elements: string[];
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ElementsListContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      elements: [],
    };
  }

  public render() {
    return React.createElement(ElementsList, {
      blocks: this.props.blocks,
      onBlockRemoveClick: this.removeBlock,
    });
  }

  @autobind
  protected removeBlock(blockId: string) {
    this.props.removeBlock(blockId);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    blocks: getSchemeListBlocks(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    removeBlock: (blockId: string) => dispatch(removeBlock(blockId)),
  };
}
