import * as React from "react";

import * as style from "./TopLine.scss";

import { Button_redesign, ButtonTheme_redesign, Popup } from "@sm/ui";
import { EditUserForm } from "../EditUserForm";
import { EditBudgetForm } from "../EditBudgetForm";
import { CustomFirstLine } from "../../common/CustomFirstLine";

interface Props {
  pageLabel: string;
  isAddUserOpen: boolean;
  isAddBudgetOpen: boolean;
  canBeUsersAdded: boolean;
  onAddUserButtonClick: () => void;
  onAddBudgetButtonClick: () => void;
  canBePlanBudgetAdded: boolean;
  canBeExecutionBudgetAdded: boolean;
  addPlanBudget: () => void;
  addExecutionBudget: () => void;
}

export function TopLine({
  pageLabel,
  isAddUserOpen,
  isAddBudgetOpen,
  onAddUserButtonClick,
  onAddBudgetButtonClick,
  canBeUsersAdded,
  canBePlanBudgetAdded,
  canBeExecutionBudgetAdded,
  addPlanBudget,
  addExecutionBudget,
}: Props): JSX.Element {
  return (
    <CustomFirstLine pageLabel={pageLabel} additionalRightCssClass={style.root}>
      {isAddUserOpen && (
        <Popup onOutOfContentClick={onAddUserButtonClick}>
          <EditUserForm onConfirmClick={onAddUserButtonClick} />
        </Popup>
      )}

      {isAddBudgetOpen && (
        <Popup onOutOfContentClick={onAddBudgetButtonClick}>
          <EditBudgetForm
            canBePlanBudgetAdded={canBePlanBudgetAdded}
            canBeExecutionBudgetAdded={canBeExecutionBudgetAdded}
            addPlanBudget={addPlanBudget}
            addExecutionBudget={addExecutionBudget}
          />
        </Popup>
      )}

      <div className={style.addUserButton}>
        <Button_redesign
          theme={ButtonTheme_redesign.Main}
          onClick={onAddUserButtonClick}
          disabled={!canBeUsersAdded}
        >
          Добавить пользователя
        </Button_redesign>
      </div>
      {(canBePlanBudgetAdded || canBeExecutionBudgetAdded) && (
        <div className={style.addUserButton}>
          <Button_redesign
            theme={ButtonTheme_redesign.Main}
            onClick={onAddBudgetButtonClick}
          >
            Добавить бюджет
          </Button_redesign>
        </div>
      )}
    </CustomFirstLine>
  );
}
