import * as React from "react";

import * as style from "./UserCard.scss";

import type { RoleUser } from "@sm/types/admin";
import type { SelectMenuItemParams } from "../../../common/SelectMenu";

import { Popup } from "@sm/ui";
import { SelectMenu } from "../../../common/SelectMenu";
import { EditUserForm } from "../../EditUserForm";
import { UserInfo } from "../UserInfo";

interface Props {
  userId: number;
  fullName: string;
  roles: RoleUser[];
  selectMenuParams: SelectMenuItemParams[];
  isEditOpen: boolean;
  isEditRoleOpen: boolean;
  onConfirmChangeRole: () => void;
  onCancelClick: () => void;
}

export function UserCard({
  userId,
  fullName,
  roles,
  selectMenuParams,
  isEditOpen,
  isEditRoleOpen,
  onConfirmChangeRole,
  onCancelClick,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.userName}>{fullName}</div>

      <div className={style.roles}>
        {roles.map((item) => (
          <div className={style.role} key={item.id}>
            {item.name}
          </div>
        ))}
      </div>

      <div className={style.selectMenu}>
        <SelectMenu
          items={selectMenuParams}
          contentClass={style.selectMenuContent}
        />
      </div>

      {isEditRoleOpen && (
        <Popup onOutOfContentClick={onConfirmChangeRole}>
          <EditUserForm userId={userId} onConfirmClick={onConfirmChangeRole} />
        </Popup>
      )}

      {isEditOpen && (
        <Popup onOutOfContentClick={onCancelClick}>
          <UserInfo userId={userId} onCancelClick={onCancelClick} />
        </Popup>
      )}
    </div>
  );
}
