import * as React from "react";
import * as classnames from "classnames";

import * as style from "./ElementsList.scss";

import { BlockParams } from "../../../../store/brief/types";

import { ParentBlockTarget } from "./ParentBlockTarget";
import { AddBlockTarget } from "./AddBlockTarget";
import { Block } from "../Elements/Block";

interface Props {
  blocks: BlockParams[];
  onBlockRemoveClick: (blockId: string) => void;
}

export function ElementsList({
  blocks,
  onBlockRemoveClick,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <AddBlockTarget order={1} />
      <ParentBlockTarget order={1} />

      {blocks.map((item, index) => (
        <div
          className={classnames("section", `section ${item.id}`)}
          key={item.id}
        >
          <Block
            id={item.id}
            parents={[]}
            onBlockRemoveClick={onBlockRemoveClick}
          />
          <AddBlockTarget order={index + 2} />
          <ParentBlockTarget id={item.id} order={index + 2} />
        </div>
      ))}
    </div>
  );
}
