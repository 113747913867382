import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import * as lodash from "lodash";

import { UserSubordination, UserParams } from "@sm/types/admin";

import { StoreState } from "../../";
import { Utils } from "../../../modules/common/Utils";

const getUserSubordinationState = (state: StoreState): UserSubordination =>
  state.common.userSubordination;

export const getUserId = createSelector(
  getUserSubordinationState,
  (userSubordination: UserSubordination): number => {
    return userSubordination.userId;
  }
);

export const getSubordinates = createSelector(
  getUserSubordinationState,
  (userSubordination: UserSubordination): UserParams[] => {
    return userSubordination.subordination || [];
  }
);

export const getSubordinateIds = createSelector(
  getSubordinates,
  (subordinates: UserParams[]): number[] => {
    return subordinates.map((subordinate) => subordinate.id);
  }
);

export const getSubordinateById = createCachedSelector(
  getSubordinates,
  (state: StoreState, id: number): number => id,
  (subordinates: UserParams[], id: number): UserParams => {
    return Utils.getItemById(subordinates, id);
  }
)((state: StoreState, id: number): number => id);

export const getDepartmentUsers = createSelector(
  getUserSubordinationState,
  (userSubordination: UserSubordination): UserParams[] => {
    return userSubordination.departmentUsers || [];
  }
);

export const getAvailableDepartmentUsers = createSelector(
  getDepartmentUsers,
  getSubordinates,
  (departmentUsers: UserParams[], subordinates: UserParams[]): UserParams[] => {
    return lodash.differenceBy(
      departmentUsers,
      subordinates,
      (user) => user.id
    );
  }
);

export const getDepartmentUserIds = createSelector(
  getDepartmentUsers,
  (departmentUsers: UserParams[]): number[] => {
    return departmentUsers.map((departmentUser) => departmentUser.id);
  }
);

export const getDepartmentUserById = createCachedSelector(
  getDepartmentUsers,
  (state: StoreState, id: number): number => id,
  (departmentUsers: UserParams[], id: number): UserParams => {
    return Utils.getItemById(departmentUsers, id);
  }
)((state: StoreState, id: number): number => id);
