// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__root{position:relative;user-select:none}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__selectedAction{display:grid;grid-template-columns:repeat(2, max-content);grid-column-gap:8px;cursor:pointer}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__selectedActionTitle{font-family:'Open Sans';font-size:14px;font-weight:300;color:#000000}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__actionSelectorWrapper{position:absolute;left:0;bottom:calc(100% + 6px);margin-left:-6px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/FormulasBuilder/Operation/OperationButtons/styles.scss"],"names":[],"mappings":"AAAA,sGACI,iBAAkB,CAClB,gBAAiB,CACpB,gHAGG,YAAa,CACb,4CAA6C,CAC7C,mBAAoB,CAEpB,cAAe,CAClB,qHAGG,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAEhB,aAAc,CAEjB,uHAGG,iBAAkB,CAClB,MAAO,CACP,uBAAwB,CACxB,gBAAiB","sourcesContent":[".root {\n    position: relative;\n    user-select: none;\n}\n\n.selectedAction {\n    display: grid;\n    grid-template-columns: repeat(2, max-content);\n    grid-column-gap: 8px;\n\n    cursor: pointer;\n}\n\n.selectedActionTitle {\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 300;\n\n    color: #000000;\n\n}\n\n.actionSelectorWrapper {\n    position: absolute;\n    left: 0;\n    bottom: calc(100% + 6px);\n    margin-left: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__root",
	"selectedAction": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__selectedAction",
	"selectedActionTitle": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__selectedActionTitle",
	"actionSelectorWrapper": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-_styles__actionSelectorWrapper"
};
export default ___CSS_LOADER_EXPORT___;
