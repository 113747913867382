import { bindThunkAction } from "typescript-fsa-redux-thunk";
import { uniqBy, sortBy } from "lodash";

import { CalendarSharingApi, OrganizationApi, DictionaryApi } from "../../api";

import { StoreState } from "../";
import { LoadingStatus } from "../utils";
import { getLoginUser } from "../user/selector";

import * as actions from "./actions";

import {
  getCalendarSharingState,
  getPendingSharings,
  getSharingById,
} from "./selectors";
import { ActivityDictionaryType } from "@sm/types/frontend";

export const loadPageData = bindThunkAction<StoreState, null, void, Error>(
  actions.loadPageDataAsync,
  async (_, dispatch, getState) => {
    const state = getState();
    const userOrganizationId = getLoginUser(state).organizationId;
    const availableActivityTypes = await DictionaryApi.getDictionaryList({
      types: [ActivityDictionaryType.ActivityType],
    });
    const availableBlocks = await DictionaryApi.getDictionaryList({
      types: [ActivityDictionaryType.CalendarGroup],
    });

    if (getCalendarSharingState(state).loadingStatus !== LoadingStatus.LOADED) {
      dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));
      const userOrganizations = await OrganizationApi.getFullOrganizationData(
        userOrganizationId
      );
      const availableOrganizations = uniqBy(
        [...userOrganizations.consumers, ...userOrganizations.contractors],
        (organization) => organization.id
      );

      const availableOrganizationIds = availableOrganizations.map(
        (organization) => organization.id
      );
      const existingSharings = sortBy(
        await CalendarSharingApi.getCalendarSharingList(),
        (sharing) => availableOrganizationIds.indexOf(sharing.organizationId)
      );
      const pendingOrganization = availableOrganizations.filter(
        (organization) =>
          !existingSharings.some(
            (sharing) => sharing.organizationId === organization.id
          )
      );

      dispatch(
        actions.savePageData({
          availableActivityTypes,
          availableBlocks,
          sharings: [
            ...existingSharings.map((sharing) => ({
              isCreated: true,
              organization: availableOrganizations.find(
                (organization) => organization.id === sharing.organizationId
              ),
              activityTypes: {
                selected: sharing.params.typeIds,
                pending: sharing.params.typeIds,
              },
              blocks: {
                selected: sharing.params.calendarGroupIds,
                pending: sharing.params.calendarGroupIds,
              },
            })),
            ...pendingOrganization.map((organization) => ({
              isCreated: false,
              organization: availableOrganizations.find(
                (org) => org.id === organization.id
              ),
              activityTypes: {
                selected: [],
                pending: [],
              },
              blocks: {
                selected: [],
                pending: [],
              },
            })),
          ],
          existingSharings: existingSharings.map(
            (sharing) => sharing.organizationId
          ),
        })
      );
    }
  }
);

export const savePendingSharings = bindThunkAction<
  StoreState,
  null,
  void,
  Error
>(actions.savePendingSharingsAsync, async (_, dispatch, getState) => {
  const state = getState();
  const organizationId = getLoginUser(state).organizationId;
  const pendingSharings = getPendingSharings(state);

  await Promise.all(
    pendingSharings.map((sharing) =>
      CalendarSharingApi.createCalendarSharing({
        organizationId: sharing.organization.id,
        sharedOrganizationId: organizationId,
        params: {
          typeIds: sharing.activityTypes.pending,
          calendarGroupIds: sharing.blocks.pending,
        },
      })
    )
  );

  dispatch(actions.transferPendingSharings());

  window.location.href = "/calendar-sharing";
});

export const updateSharing = bindThunkAction<StoreState, string, void, Error>(
  actions.updateSharingAsync,
  async (id, dispatch, getState) => {
    const state = getState();
    const organizationId = getLoginUser(state).organizationId;
    const sharing = getSharingById(state, id);

    await CalendarSharingApi.createCalendarSharing({
      organizationId: sharing.organization.id,
      sharedOrganizationId: organizationId,
      params: {
        typeIds: sharing.activityTypes.pending,
        calendarGroupIds: sharing.blocks.pending,
      },
    });

    window.location.href = "/calendar-sharing";
  }
);

export const deleteExistingSharing = bindThunkAction<
  StoreState,
  string,
  void,
  Error
>(
  actions.deleteExistingSharingAsync,
  async (organizationId, dispatch, getState) => {
    const state = getState();
    const sharedOrganizationId = getLoginUser(state).organizationId;

    await CalendarSharingApi.deleteCalendarSharing({
      organizationId,
      sharedOrganizationId,
    });

    dispatch(actions.deleteSharingEntry(organizationId));
  }
);
