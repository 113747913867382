import { actionCreatorFactory } from "typescript-fsa";

import { DepartmentAttributes } from "@sm/types/admin";

const actionCreator = actionCreatorFactory(
  "DEPARTMENT_LIST_PAGE_DEPARTMENT_CREATION_BLOCK"
);

const addDepartmentForDepartmentCreationBlock =
  actionCreator<DepartmentAttributes>("ADD_DEPARTMENT");
const updateDepartmentForDepartmentCreationBlock =
  actionCreator<DepartmentAttributes>("UPDATE_DEPARTMENT");
const deleteDepartmentForDepartmentCreationBlock =
  actionCreator<string>("DELETE_DEPARTMENT");
const setIsNewDepartmentAddedForDepartmentCreationBlock =
  actionCreator<boolean>("SET_IS_NEW_DEPARTMENT_ADDED");
const setSelectedDepartmentForDepartmentCreationBlock = actionCreator<string>(
  "SELECT_SELECTED_DEPARTMENT"
);
const resetDepartmentCreationBlock = actionCreator<void>("RESET");

export {
  addDepartmentForDepartmentCreationBlock,
  updateDepartmentForDepartmentCreationBlock,
  deleteDepartmentForDepartmentCreationBlock,
  setIsNewDepartmentAddedForDepartmentCreationBlock,
  setSelectedDepartmentForDepartmentCreationBlock,
  resetDepartmentCreationBlock,
};
