import createCashedSelector from "re-reselect";
import { createSelector } from "reselect";
import { SelectItem } from "@sm/ui";

import { StoreState } from "../";

import { CalendarSharingState } from "./types";

export const getCalendarSharingState = (
  state: StoreState
): CalendarSharingState => state.calendarSharing;

export const getExistingSharings = createSelector(
  getCalendarSharingState,
  (state) => state.sharings.filter((sharing) => sharing.isCreated)
);

export const getPendingSharings = createSelector(
  getCalendarSharingState,
  (state) => {
    const { existingSharings, sharings } = state;

    return sharings.filter(
      (sharing) =>
        !existingSharings.includes(sharing.organization.id) && sharing.isCreated
    );
  }
);

export const getSharingById = createCashedSelector(
  getCalendarSharingState,
  (state: StoreState, id: string): string => id,
  (state, id) =>
    state.sharings.find((sharing) => sharing.organization.id === id)
)((state: StoreState, id: string): string => id);

export const getNonExistingSharingsAsSelectItems = createSelector(
  getCalendarSharingState,
  (state): SelectItem[] => {
    const nonExistingSharings = state.sharings.filter(
      (sharing) => !sharing.isCreated
    );

    return nonExistingSharings.map((sharing) => ({
      value: sharing.organization.id,
      label: sharing.organization.name,
    }));
  }
);
