import * as React from "react";

import * as style from "./AccessCell.scss";

interface Props {
  value: any;
}

export const AccessCellTemplate = ({ value }: Props): JSX.Element => {
  return (
    <div className={style.root}>
      <a className={style.link} href={`/budgets/${value}/access`}>
        Настроить
      </a>
    </div>
  );
};
