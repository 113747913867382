import * as React from "react";

import * as style from "./BriefPage.scss";

import {
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
} from "@sm/ui";
import { Page } from "../common/Page";

import { TopLine } from "./TopLine";

import { Schema } from "./Schema";
import { CustomDragLayer } from "./DragLayer";
import { CalculationLogicPopup } from "./CalculationLogicPopup";

interface Props {
  pageLabel: string;
  preloader: boolean;
  previousLabel: string;
  previousUrl: string;
  isVisibleCalculationLogicPopup: boolean;
  onContentWrapperClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSaveStart: () => void;
  onSaveEnd: () => void;
}

/*tslint:disable:cyclomatic-complexity*/
export function BriefPage({
  preloader,
  pageLabel,
  previousLabel,
  previousUrl,
  isVisibleCalculationLogicPopup,
  onContentWrapperClick,
  onSaveStart,
  onSaveEnd,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      previousLabel={previousLabel}
      previousUrl={previousUrl}
      withoutNavigation
      customLayout
    >
      {!preloader && (
        <div className={style.root} onClick={onContentWrapperClick}>
          <div className={style.topLine}>
            <ResponsiveLayout>
              <CentralContent>
                <TopLine
                  previousUrl={previousUrl}
                  onSaveStart={onSaveStart}
                  onSaveEnd={onSaveEnd}
                />
              </CentralContent>
            </ResponsiveLayout>
          </div>

          <ResponsiveLayout>
            <LeftSidebar />

            <CentralContent>
              <div className={style.content}>
                <Schema />
              </div>
            </CentralContent>

            <RightSidebar />
          </ResponsiveLayout>

          <CustomDragLayer />
        </div>
      )}

      {isVisibleCalculationLogicPopup && (
        <CalculationLogicPopup loading={false} />
      )}
    </Page>
  );
}
