import { UserParams, UsersQuery } from "@sm/types/admin";

import { axios } from "../lib/axios";

const USER_URL = "/api/user";

export class UserApi {
  public static async getUsers(query?: UsersQuery): Promise<UserParams[]> {
    const res = await axios.get(`${USER_URL}`, { params: { ...query } });

    return res.data;
  }
}
