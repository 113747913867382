import * as React from "react";
import { NavLink } from "react-router-dom";

import * as style from "./TopLine.scss";

import { Button_redesign, ButtonTheme_redesign } from "@sm/ui";
import { CustomFirstLine } from "../../common/CustomFirstLine";

export interface Props {
  pageLabel: string;
}

export const TopLine = ({ pageLabel }: Props): JSX.Element => {
  return (
    <CustomFirstLine
      pageLabel={pageLabel}
      additionalRightCssClass={style.buttons}
    >
      <NavLink to="/brief/scheme/new" className={style.button}>
        <Button_redesign theme={ButtonTheme_redesign.Main}>
          Создать шаблон брифа
        </Button_redesign>
      </NavLink>
    </CustomFirstLine>
  );
};
