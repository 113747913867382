import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";

import { UserParams } from "@sm/types/admin";

import { StoreState } from "../../";
import { DepartmentRolePageState } from "../types";
import { Utils } from "../../../modules/common/Utils";

export const getDepartmentRolePageState = (
  state: StoreState
): DepartmentRolePageState => state.departmentRolePage;

export const getIsOnNewRole = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): boolean => {
    return state.isOnNewRole;
  }
);

export const getUsers = createSelector(
  getDepartmentRolePageState,
  (state: DepartmentRolePageState): UserParams[] => {
    return state.users;
  }
);

export const getUser = createCachedSelector(
  getUsers,
  (state: StoreState, id: number): number => id,
  (users: UserParams[], id: number): UserParams => {
    return Utils.getItemById(users, id);
  }
)((state: StoreState, id: number): number => id);

export const canBeUsersAdded = createSelector(
  getDepartmentRolePageState,
  ({ availableUsers }): boolean => !isEmpty(availableUsers)
);
