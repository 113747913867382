import * as React from "react";
import * as classNames from "classnames";
import { ConnectDragSource } from "react-dnd";

import * as style from "../Block.scss";

interface Props {
  id: string;
  order?: number;
  parent?: string;
  content: string;
  itemType: string;
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export function BlockTitleDrag({
  content,
  isDragging,
  connectDragSource,
}: Props): JSX.Element {
  return connectDragSource ? (
    connectDragSource(
      <div
        className={classNames(
          style.blockName,
          isDragging && style.blockNameDrag
        )}
      >
        {content}
      </div>
    )
  ) : (
    <div className={style.blockName}>{content}</div>
  );
}
