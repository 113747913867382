import * as React from "react";
import * as lodash from "lodash";

import * as style from "./Filters.scss";

import type { ColumnName, Filter } from "../../../types";

import {
  FilterMenu,
  FilterItem,
  FilterCheckboxBorderType,
} from "sber-marketing-ui";

interface Props {
  title?: string;
  columnName: ColumnName;
  items: FilterItem[];
  isExpanded?: boolean;
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
}

export const Filters = ({
  title,
  columnName,
  items,
  isExpanded,
  filters,
  setFilters,
}: Props): JSX.Element => {
  const filtersRef = React.useRef<Filter[]>([]);

  React.useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const foundFilter = filters.find((item) => item.columnName === columnName);

  const selectedValues = foundFilter?.selectedValues || [];

  const onItemSelection = React.useCallback(
    (newCheckedItemsIds: React.ReactText[]) => {
      const filters = filtersRef.current;
      let updatedFilters = lodash.cloneDeep(filters);

      let filter = updatedFilters.find(
        (item) => item.columnName === columnName
      );

      if (!filter) {
        filter = {
          columnName,
          selectedValues: [],
        };

        updatedFilters.push(filter);
      }

      const possibleIds = items.map((item) => item.id);

      const updatedIds = lodash.intersection(possibleIds, newCheckedItemsIds);
      const selectedIdsOfOtherFilters = lodash.without(
        filter.selectedValues,
        ...possibleIds
      );

      filter.selectedValues = [...updatedIds, ...selectedIdsOfOtherFilters];

      const filterIsEmpty = lodash.isEmpty(filter.selectedValues);

      if (filterIsEmpty) {
        updatedFilters = lodash.without(updatedFilters, filter);
      }

      setFilters(updatedFilters);
    },
    []
  );

  return (
    <div className={style.root}>
      <FilterMenu
        filterTitle={title || "Отображаемые значения"}
        items={items}
        checkedItemsIds={selectedValues}
        onItemSelection={onItemSelection}
        border={FilterCheckboxBorderType.NONE}
        preserveAllSelectedState
        freezeExpansion={isExpanded === undefined}
        isExpanded={isExpanded !== undefined ? isExpanded : true}
        disableSort
      />
    </div>
  );
};
