import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { isNil } from "lodash";
import autobind from "autobind-decorator";

import {
  AddFieldParams,
  BlockParams,
  EditBlockParams,
  FieldType,
  RemoveFieldsByTypeParams,
} from "../../../../../store/brief/types";

import { BlockMenu } from "./BlockMenu";
import { StoreState } from "../../../../../store";
import {
  addField,
  editBlock,
  removeFieldsByType,
} from "../../../../../store/brief/actions";
import { getBlockById } from "../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  block?: BlockParams;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
}

interface DispatchProps {
  editBlock?: (params: EditBlockParams) => void;
  addField?: (params: AddFieldParams) => void;
  removeFieldsByType?: (params: RemoveFieldsByTypeParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BlockMenuContainer extends React.Component<Props> {
  public render() {
    const { block, isSchemaActive, isSchemaEditable } = this.props;
    const { isRequired, isClientHide } = block;

    const isRequiredTest = isNil(isRequired) ? true : isRequired;

    return React.createElement(BlockMenu, {
      name: this.props.block ? this.props.block.name : "",
      tooltipMessage: this.props.block ? this.props.block.tooltipMessage : "",
      isRequired: isRequiredTest,
      isClientHide: isClientHide,
      isSchemaActive,
      isSchemaEditable,
      onNameInputBlur: this.handleNameInputBlur,
      onNotRequiredCheckboxChange: this.handleNotRequiredChange,
      onClientHideCheckboxChange: this.handleClientHideChange,
      onTooltipMessageChange: this.handleTooltipMessageChange,
    });
  }

  @autobind
  protected handleNameInputBlur(value: string) {
    this.props.editBlock({
      id: this.props.id,
      name: value,
    });
  }

  @autobind
  protected handleTooltipMessageChange(value: string) {
    this.props.editBlock({
      id: this.props.id,
      tooltipMessage: value,
    });
  }

  @autobind
  protected handleClientHideChange(isChecked: boolean) {
    console.log(isChecked);
    this.props.editBlock({
      id: this.props.id,
      isClientHide: isChecked,
    });
  }

  @autobind
  protected handleNotRequiredChange(isChecked: boolean) {
    this.props.editBlock({
      id: this.props.id,
      isRequired: !isChecked,
    });

    if (isChecked) {
      this.props.addField({
        blockId: this.props.id,
        type: FieldType.TOGGLE,
        order: 0,
      });
    } else {
      this.props.removeFieldsByType({
        fieldType: FieldType.TOGGLE,
        blockId: this.props.id,
      });
    }
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const block = getBlockById(state, ownProps.id);
  const isSchemaActive = state.brief.isSchemeActive;
  const isSchemaEditable = state.brief.isSchemeEditable;
  const parentFields = (block.fields || []).find(
    (field) => field.properties?.parentFieldId
  );

  return {
    block: block,
    isSchemaActive: isSchemaActive,
    isSchemaEditable: isSchemaEditable || (isSchemaActive && !parentFields),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    editBlock: (params: EditBlockParams) => dispatch(editBlock(params)),
    addField: (params: AddFieldParams) => dispatch(addField(params)),
    removeFieldsByType: (params: RemoveFieldsByTypeParams) =>
      dispatch(removeFieldsByType(params)),
  };
}
