import * as React from "react";
import * as lodash from "lodash";
import { connect } from "react-redux";
import autobind from "autobind-decorator";
import { PlainDictionary } from "@sm/types/budget";

import type { StoreState } from "../../../store";
import { ActivityTypeColorData } from "../../../store/activityType/types";
import { getActivityTypePageState } from "../../../store/activityType/selector";

import { ActivityTypeRow } from "./ActivityTypeRow";

interface Props extends Partial<MapProps> {
  activityType: PlainDictionary;
}

interface MapProps {
  activityTypeColorData: ActivityTypeColorData;
}

interface State {
  isOnEdit: boolean;
}

@(connect(mapStateToProps) as any)
export class ActivityTypeRowContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOnEdit: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (!lodash.isEqual(this.props, prevProps)) {
      this.setState({
        isOnEdit: false,
      });
    }
  }

  public render(): JSX.Element {
    const { activityType, activityTypeColorData } = this.props;

    return React.createElement(ActivityTypeRow, {
      activityType,
      activityTypeColorData,
      isOnEdit: this.state.isOnEdit,
      toggleOnEdit: this.toggleIsOnEdit,
    });
  }

  @autobind
  protected toggleIsOnEdit() {
    this.setState((state) => ({
      isOnEdit: !state.isOnEdit,
    }));
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    activityTypeColorData:
      getActivityTypePageState(state).activityTypesData[
        ownProps.activityType.id
      ].data,
  };
}
