import { actionCreatorFactory } from "typescript-fsa";

import { DepartmentAttributes } from "@sm/types/admin";
import { UpdateSelectedDepartmentParams } from "../types";

const actionCreator = actionCreatorFactory("DEPARTMENT_ROLE_PAGE_DEPARTMENT");

const addDepartment = actionCreator<DepartmentAttributes>("ADD_DEPARTMENT");
const removeDepartment = actionCreator<string>("REMOVE_DEPARTMENT");
const setSelectedDepartment = actionCreator<UpdateSelectedDepartmentParams>(
  "SET_SELECTED_DEPARTMENT"
);
const addSubDepartment =
  actionCreator<DepartmentAttributes>("ADD_SUB_DEPARTMENT");

export {
  addDepartment,
  removeDepartment,
  setSelectedDepartment,
  addSubDepartment,
};
