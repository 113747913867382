import * as React from "react";
import { useSelector } from "react-redux";
import { LabeledInput, LabeledSelect, SelectItem } from "@sm/ui";

import { WorkTypeApi } from "@api";

import { StoreState } from "@store";
import { getLoginUser } from "@store/user/selector";

import * as styles from "./EditOrCreateDictionaryForm.scss";

interface Props {
  required: boolean;
  description: string;
  value: string;
  dictionary?: string;
  enumItems?: string[];
  type?: string;
  onChange: (value: any) => void;
}

function useFetchDictionarySelectItems(type: string): SelectItem[] {
  const [items, setItems] = React.useState<SelectItem[]>([]);
  const organizationId = useSelector(
    (state: StoreState) => getLoginUser(state).organizationId
  );

  async function fetcher() {
    let result: SelectItem[];

    switch (type) {
      case "worktype":
        result = (await WorkTypeApi.getWorkTypes({ organizationId }))
          .filter((workType) => !workType.isArchived)
          .map((workType) => ({
            value: workType.id,
            label: workType.name,
          }));
        break;
      default:
        console.warn(`Unknownn dictionary type: ${type}`);
        result = [];
        break;
    }

    setItems(result);
  }

  React.useEffect(() => {
    fetcher();
  }, []);

  return items;
}

export function SchemaField({
  required,
  description,
  value,
  dictionary,
  enumItems,
  type,
  onChange,
}: Props): JSX.Element {
  if (dictionary || enumItems) {
    const items = enumItems
      ? enumItems.map((item) => ({
          value: item,
          label: item,
        }))
      : useFetchDictionarySelectItems(dictionary);

    return (
      <div className={styles.inputWrapper}>
        <LabeledSelect
          title={description}
          selectedValue={value}
          items={items}
          onSelection={(item) => onChange(item as string)}
        />
      </div>
    );
  }

  return (
    <div className={styles.inputWrapper}>
      <LabeledInput
        required={required}
        title={description}
        value={value}
        valueType={"float"}
        onValueChange={(value) => {
          let i = value;

          if (type === "number") {
            i = `${value || ""}`.match(/\d*(\.\d{0,2})?/)[0];
          }

          onChange(i);
        }}
      />
    </div>
  );
}
