import * as React from "react";

import * as style from "./SwitchGroupMenu.scss";

import {
  LabeledTextarea,
  LabeledInput,
  LabeledSelect,
  Checkbox_redesign,
} from "@sm/ui";
import { SwitchType, SwitchItem } from "@sm/types/frontend";

import { DropdownContent } from "../DropdownMenu/DropdownContent";

interface Props {
  name: string;
  validationMessage: string;
  tooltipMessage?: string;
  switchType: SwitchType;
  switches: SwitchItem[];
  isRequired: boolean;
  isCalculated: boolean;
  isSchemaActive: boolean;
  isSchemaEditable: boolean;
  isFormulaEditable: boolean;
  duplicateToReport: boolean;
  isHasExtraFields: boolean;
  showSwitchTooltip: boolean;
  isClientHide: boolean;
  onNameChange: (value: string) => void;
  onValidationMessageChange: (value: string) => void;
  onTooltipMessageChange: (value: string) => void;
  onTypeChange: (value: React.ReactText) => void;
  onAddSwitch: (value: string) => void;
  onChangeSwitch: (params: { value: string; id: string }) => void;
  onRequirementChange: (isChecked: boolean) => void;
  onDuplicateToReportChange: (isChecked: boolean) => void;
  onCalculatedChange: (isChecked: boolean) => void;
  onSumButtonClick: (params: { lineId: string }) => void;
  onExtraChange: (isChecked: boolean) => void;
  onShowSwitchTooltipChange: (isChecked: boolean) => void;
  onSwitchTooltipChange: (params: { value: string; id: string }) => void;
  onClientHideCheckboxChange: (isChecked: boolean) => void;
}

export function SwitchGroupMenu({
  name,
  validationMessage,
  tooltipMessage = "",
  switchType,
  switches,
  isRequired,
  isCalculated,
  isSchemaActive,
  isSchemaEditable,
  isFormulaEditable,
  duplicateToReport,
  isHasExtraFields,
  showSwitchTooltip,
  isClientHide,
  onNameChange,
  onValidationMessageChange,
  onTooltipMessageChange,
  onTypeChange,
  onAddSwitch,
  onChangeSwitch,
  onRequirementChange,
  onDuplicateToReportChange,
  onCalculatedChange,
  onSumButtonClick,
  onExtraChange,
  onShowSwitchTooltipChange,
  onSwitchTooltipChange,
  onClientHideCheckboxChange,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.inputProperty}>
        <LabeledInput
          title={"Название поля"}
          value={name}
          onValueChange={onNameChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledInput
          title={"Текст ошибки"}
          value={validationMessage}
          onValueChange={onValidationMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.dropdownProperty}>
        <LabeledSelect
          title={"Тип переключателей"}
          items={[
            {
              label: "Чекбоксы",
              value: SwitchType.Checkbox,
            },
            {
              label: "Радиокнопки",
              value: SwitchType.Radio,
            },
          ]}
          selectedValue={switchType}
          onSelection={onTypeChange}
          disabled={!isSchemaEditable}
          disableSearch
        />
      </div>

      <div className={style.dropdownProperty}>
        <DropdownContent
          name={"Переключатели"}
          content={switches.map((item) => ({
            id: item.id,
            value: item.name,
            hasFormulas: Boolean(item?.formulas?.length) || false,
          }))}
          displayCalculateButton={isCalculated}
          canChangeValue={isSchemaEditable}
          canAddValue={isSchemaEditable}
          onAddValue={onAddSwitch}
          onChangeValue={onChangeSwitch}
          onSumButtonClick={onSumButtonClick}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Выводить дополнительные поля"}
          isChecked={isHasExtraFields}
          onSwitch={onExtraChange}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Обязательное поле"}
          isChecked={isRequired}
          onSwitch={onRequirementChange}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Дублировать в отчет"}
          isChecked={duplicateToReport}
          onSwitch={onDuplicateToReportChange}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Поле входит в расчет калькурятора"}
          isChecked={isCalculated}
          onSwitch={onCalculatedChange}
          iconSize={20}
          disabled={!(isSchemaEditable || isFormulaEditable)}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Подсказки переключателей"}
          isChecked={showSwitchTooltip}
          onSwitch={onShowSwitchTooltipChange}
          disabled={!isSchemaEditable}
        />
      </div>

      {showSwitchTooltip &&
        switches.map((item) => (
          <div key={item.id} className={style.inputProperty}>
            <LabeledInput
              title={`Текст подсказки - ${item.name}`}
              value={item.tooltipMessage || ""}
              onValueChange={(tooltipMessage) =>
                onSwitchTooltipChange({ value: tooltipMessage, id: item.id })
              }
              disabled={!isSchemaEditable}
            />
          </div>
        ))}

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>
    </div>
  );
}
