import * as React from "react";
import { connect } from "react-redux";

import { WorkTypeTable } from "./WorkTypeTable";
import type { StoreState } from "../../../store";
import { getWorkTypeIds, getIsOnAdd } from "../../../store/workType/selector";

interface Props extends MapProps {}

interface MapProps {
  workTypeIds?: string[];
  isOnAdd?: boolean;
}

@(connect(mapStateToProps, null) as any)
export class WorkTypeTableContainer extends React.Component<Props> {
  public render(): JSX.Element {
    return React.createElement(WorkTypeTable, {
      workTypeIds: this.props.workTypeIds,
      isOnAdd: this.props.isOnAdd,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    workTypeIds: getWorkTypeIds(state),
    isOnAdd: getIsOnAdd(state),
  };
}
