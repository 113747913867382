import * as React from "react";

import { OperationType } from "../../enums";
import {
  operationTypeNamesMap,
  buttonsComponentsMap,
  operations,
  EmptyButton,
} from "./utils";
import { useOutsideElementClick } from "../../../common/hooks";

import { ActionSelector } from "./ActionSelector";
import * as styles from "./styles.scss";

interface Props {
  operation: OperationType;
  onSelect: (operation: OperationType) => void;
}

export const OperationButtons: React.FC<Props> = ({ operation, onSelect }) => {
  const [active, setActive] = React.useState(false);

  const onOutsideClick = React.useCallback(() => {
    setActive(false);
  }, []);

  const { ref } = useOutsideElementClick(onOutsideClick);

  const onButtonClick = React.useCallback(() => {
    setActive(!active);
  }, [active]);

  const onSelectorButtonClick = React.useCallback(
    (operationType: OperationType) => {
      setActive(false);
      onSelect(operationType);
    },
    [onSelect, setActive]
  );

  const filteredOperations = operations.filter(
    (currentOperations) => currentOperations !== operation
  );

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.selectedAction}>
        <div>
          {React.createElement(buttonsComponentsMap[operation] || EmptyButton, {
            onClick: onButtonClick,
          })}
        </div>
        <div className={styles.selectedActionTitle}>
          {operationTypeNamesMap[operation]}
        </div>
      </div>

      {active && (
        <div className={styles.actionSelectorWrapper}>
          <ActionSelector
            operations={filteredOperations}
            onButtonClick={onSelectorButtonClick}
          />
        </div>
      )}
    </div>
  );
};
