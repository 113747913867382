import * as React from "react";
import * as classNames from "classnames";
import { connect } from "react-redux";
import { ActivityDictionaryType } from "@sm/types/budget";

import * as style from "./Dropdown.scss";

import { ElementPropertiesParams } from "../../../../../../store/brief/types";

import { LabeledSelect, Icon, IconType, WithTooltip } from "@sm/ui";

import { StoreState } from "../../../../../../store";
import { getLoginUser } from "../../../../../../store/user/selector";
import { DictionaryApi } from "../../../../../../api";

interface MapProps {
  organizationId: string;
}

export interface DropdownProps extends ElementPropertiesParams {
  isSelected: boolean;
  organizationId: string;
  onToggleSelect: React.MouseEventHandler<HTMLDivElement>;
  onRemoveClick: () => void;
  isPreviewModeEnabled?: boolean;
}

function DropdownComponent({
  isSelected,
  name,
  tooltipMessage,
  selectContent = [],
  onToggleSelect,
  onRemoveClick,
  isPreviewModeEnabled,
  isDictionary,
  dictionaryType,
  organizationId,
  isCalculated = false,
  canRemove,
}: DropdownProps): JSX.Element {
  const [dictionary, setDictionary] = React.useState([]);
  const hasTooltip = !!tooltipMessage;

  React.useEffect(() => {
    if (
      isPreviewModeEnabled &&
      isDictionary &&
      dictionaryType === "divisions"
    ) {
      DictionaryApi.getDictionaryList({
        organizationId,
        types: [ActivityDictionaryType.Division],
      }).then((dictionary) => {
        setDictionary(dictionary);
      });
    }
  }, [isPreviewModeEnabled, isDictionary, organizationId]);

  return (
    <div
      className={classNames(
        style.root,
        isSelected && style.onEdit,
        isPreviewModeEnabled && style.previewMode
      )}
      onClick={onToggleSelect}
    >
      <LabeledSelect
        disabled={!isPreviewModeEnabled}
        title={name || "Новый дропдаун"}
        items={(isDictionary ? dictionary : selectContent).map((item) => ({
          value: item.id,
          label: item.value,
        }))}
      />

      {isCalculated && (
        <div className={style.calculateIconWrapper}>
          <Icon type={IconType.SUM} svgSize={10} />
        </div>
      )}

      {canRemove && isSelected && (
        <div className={style.icon} onClick={onRemoveClick}>
          <Icon type={IconType.CROSS_BRIEF} />
        </div>
      )}

      {isPreviewModeEnabled && hasTooltip && (
        <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
          <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
        </WithTooltip>
      )}
    </div>
  );
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    organizationId: getLoginUser(state).organizationId,
  };
}

export const Dropdown = connect(mapStateToProps)(DropdownComponent);
