import {
  FileUploadParams,
  FileDownloadLinkParams,
} from "sber-marketing-types/backend";

import { axios } from "../../lib/axios";
import { FileApiUploadParams, FileAsset } from "./types";

const FILE_MIN_SIZE_TO_FRAGMENTIZE = 1000000;
const FILE_MAX_SIZE_TO_DEVIDE = 1900000000;
const FRAGMENT_MAX_SIZE = 99000000;

export abstract class FileService {
  public abstract async getDownloadLink(
    params: FileApiUploadParams,
    settings: FileDownloadLinkParams
  ): Promise<string>;

  public abstract async makeSharedLink(
    params: FileApiUploadParams,
    settings: FileDownloadLinkParams
  ): Promise<string>;

  public async upload(
    params: FileApiUploadParams,
    fileAsset: FileAsset,
    settings: FileUploadParams
  ): Promise<void> {
    if (fileAsset.file.size <= FILE_MIN_SIZE_TO_FRAGMENTIZE) {
      await this.whollyUpload(params, fileAsset.file, settings);
    } else {
      const chunks = this.separateFileByChunks(fileAsset.file);
      await this.multipartUpload(params, chunks, settings);
    }
  }

  public async *uploadProcess(
    params: FileApiUploadParams,
    fileAsset: FileAsset,
    settings: FileUploadParams
  ): AsyncIterableIterator<FileAsset> {
    if (fileAsset.file.size <= FILE_MIN_SIZE_TO_FRAGMENTIZE) {
      fileAsset.isLoading = true;
      fileAsset.loadingProgress = 0;
      yield fileAsset;
      await this.whollyUpload(params, fileAsset.file, settings);
      fileAsset.loadingProgress = 100;
      yield fileAsset;
    } else {
      fileAsset.isLoading = true;
      fileAsset.loadingProgress = 0;
      yield fileAsset;

      const chunks = this.separateFileByChunks(fileAsset.file);
      const uploadProcess = this.multipartUploadProcess(
        params,
        chunks,
        settings
      );
      for await (const loadingProgress of uploadProcess) {
        fileAsset.loadingProgress = loadingProgress;
        yield fileAsset;
      }
    }
  }

  protected abstract async multipartUpload(
    params: FileApiUploadParams,
    chunks: Blob[],
    settings: FileUploadParams
  ): Promise<void>;

  protected abstract multipartUploadProcess(
    params: FileApiUploadParams,
    chunks: Blob[],
    settings: FileUploadParams
  ): AsyncIterableIterator<number>;

  protected abstract async whollyUpload(
    params: FileApiUploadParams,
    file: File,
    settings: FileUploadParams
  ): Promise<void>;

  protected separateFileByChunks(file: File): Blob[] {
    const fullSize = file.size;
    const fragmentSize = this.getFragmentSize(fullSize);

    const chunks: Blob[] = [];
    let position = 0;
    let currentChunk: Blob = null;

    while (position < fullSize) {
      currentChunk = file.slice(position, position + fragmentSize);
      chunks.push(currentChunk);
      position += fragmentSize;
    }

    return chunks;
  }

  protected async sendFile<Response = any>(
    url: string,
    file: File | Blob,
    data: object
  ): Promise<Response> {
    const headers = { "Content-Type": "multipart/form-data" };
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    formData.append("file", file);

    const res = await axios.post(url, formData, { headers });
    return res.data;
  }

  protected getFragmentSize(fileSize: number): number {
    return fileSize < FILE_MAX_SIZE_TO_DEVIDE
      ? fileSize / 20
      : FRAGMENT_MAX_SIZE;
  }
}
