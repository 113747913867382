import { reducerWithInitialState } from "typescript-fsa-reducers";

import {
  load,
  add,
  remove,
  addDepartment,
  removeDepartment,
  update,
  setIsOnAdd,
  enableRates,
  disableRates,
} from "../actions";

import { WorkTypeState } from "../types";

import { WorkTypeReducer } from "./WorkTypeReducer";

const initialState: WorkTypeState = {
  workType: [],
  department: [],
  isOnAdd: false,
};

export const organizationTypeReducer = reducerWithInitialState<WorkTypeState>(
  initialState
)
  .case(load, WorkTypeReducer.load)
  .case(add, WorkTypeReducer.add)
  .case(update, WorkTypeReducer.update)
  .case(remove, WorkTypeReducer.remove)
  .case(addDepartment, WorkTypeReducer.addDepartment)
  .case(removeDepartment, WorkTypeReducer.removeDepartment)
  .case(setIsOnAdd, WorkTypeReducer.setIsOnAdd)
  .case(enableRates, WorkTypeReducer.enableRates)
  .case(disableRates, WorkTypeReducer.disableRates);
