import { DepartmentAttributes } from "@sm/types/admin";

import {
  DepartmentRolePageState,
  DepartmentRolePageLoadParams,
  UpdateStateWithNewDepartmentParams,
  UpdateDepartmentNameParams,
} from "../types";

export class DepartmentRolePageReducer {
  public static loadPageState(
    state: DepartmentRolePageState,
    {
      roles,
      department,
      selectedDepartmentId,
      rightGroups,
      users,
      availableUsers,
    }: DepartmentRolePageLoadParams
  ) {
    const departments = department;
    const selectedDepartmentIds = [selectedDepartmentId];

    return {
      ...state,
      roles,
      departments,
      selectedDepartmentIds,
      rightGroups,
      users,
      availableUsers,
    };
  }

  public static updateStateWithNewDepartment(
    state: DepartmentRolePageState,
    { department, departmentUsers }: UpdateStateWithNewDepartmentParams
  ) {
    const departments = department
      ? state.departments.some((item) => item.id == department.id)
        ? [...state.departments]
        : [...state.departments, department]
      : state.departments;
    return {
      ...state,
      departments,
      departmentUsers,
    };
  }

  public static setSelectedPage(
    state: DepartmentRolePageState,
    selectedPage: string
  ) {
    return {
      ...state,
      selectedPage,
    } as DepartmentRolePageState;
  }

  public static updateDepartmentName(
    state: DepartmentRolePageState,
    { departmentId, parentDepartmentId, name }: UpdateDepartmentNameParams
  ) {
    const departments = state.departments.map((department) => {
      let updatedDepartment = { ...department };
      if (department.id == departmentId) {
        updatedDepartment = { ...updatedDepartment, name };
      } else if (department.id == parentDepartmentId) {
        updatedDepartment = {
          ...updatedDepartment,
          subDepartments: department.subDepartments.map(
            (subDepartment: DepartmentAttributes) =>
              subDepartment.id == departmentId
                ? { ...subDepartment, name }
                : subDepartment
          ),
        };
      }

      return updatedDepartment;
    });

    return {
      ...state,
      departments,
    };
  }
}
