import * as React from "react";

import { Icon, IconType } from "sber-marketing-ui";
import * as styles from "./styles.scss";

export interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const AdditionButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon className={styles.icon} type={IconType.PLUS_SIGN} svgSize={10} />
    </div>
  );
};

export const SubtractionButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon className={styles.icon} type={IconType.MINUS} svgSize={18} />
    </div>
  );
};

export const MultiplicationButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon
        className={styles.icon}
        type={IconType.MULTUPLICATION_SIGN}
        svgSize={14}
      />
    </div>
  );
};

export const DivisionButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon
        className={styles.icon}
        type={IconType.DIVISION_SIGN}
        svgSize={12}
      />
    </div>
  );
};

export const EmptyButton: React.FC<Props> = ({ onClick }) => {
  return <div className={styles.root} onClick={onClick} />;
};
