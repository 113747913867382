// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-_BriefPage__root{width:100%;height:calc(100vh - 49px)}.src-modules-BriefPage-_BriefPage__topLine{position:fixed;top:49px;right:0;left:0}.src-modules-BriefPage-_BriefPage__content{width:100%;padding-top:55px;padding-bottom:40px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/BriefPage.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,yBAA0B,CAC7B,2CAGG,cAAe,CACf,QAAS,CACT,OAAQ,CACR,MAAO,CACV,2CAGG,UAAW,CACX,gBAAiB,CACjB,mBAAoB","sourcesContent":[".root {\n    width: 100%;\n    height: calc(100vh - 49px);\n}\n\n.topLine {\n    position: fixed;\n    top: 49px;\n    right: 0;\n    left: 0;\n}\n\n.content {\n    width: 100%;\n    padding-top: 55px;\n    padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-_BriefPage__root",
	"topLine": "src-modules-BriefPage-_BriefPage__topLine",
	"content": "src-modules-BriefPage-_BriefPage__content"
};
export default ___CSS_LOADER_EXPORT___;
