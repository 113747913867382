import * as React from "react";
import { LabeledSelect as Select, SelectItem } from "@sm/ui";

import * as styles from "./NewSharing.scss";

interface Props {
  selectItems: SelectItem[];
  onOrganizationSelect: (organizationId: string) => void;
}

export function NewSharing({
  selectItems,
  onOrganizationSelect,
}: Props): JSX.Element {
  return (
    <div className={styles.root}>
      <Select
        disableAutoSelectSingleItem
        title="Получить доступ"
        items={selectItems}
        onSelection={onOrganizationSelect}
      />
    </div>
  );
}
