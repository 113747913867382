import { actionCreatorFactory } from "typescript-fsa";

import { DepartmentAttributes } from "@sm/types/admin";

import {
  DepartmentListPageLoadParams,
  DepartmentListPageSetFilterParams,
} from "../types";

const actionCreator = actionCreatorFactory("DEPARTMENT_LIST_PAGE");

const loadPageState = actionCreator<DepartmentListPageLoadParams>("LOAD");
const setDepartmentListFilter =
  actionCreator<DepartmentListPageSetFilterParams>("SET_FILTER");
const updateDepartments =
  actionCreator<DepartmentAttributes[]>("UPDATE_DEPARTMENTS");

export { loadPageState, setDepartmentListFilter, updateDepartments };
