import * as React from "react";
import {
  WithGlobalPopup,
  AbsoluteLikePositionCalculator,
} from "sber-marketing-ui";
import { PlainDictionary } from "@sm/types/budget";

import { SelectMenu } from "@common/SelectMenu";

import { TableControlsContext } from "../../common";

import * as styles from "./ActionsCell.scss";

interface Props {
  dictionary: PlainDictionary;
}

export function ActionsCell(props: Props): JSX.Element {
  const ref = React.useRef<HTMLDivElement>();

  const [isMenuActive, setIsMenuActive] = React.useState(false);

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.button} onClick={() => setIsMenuActive(true)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10C12.8284 10 13.5 10.6716 13.5 11.5C13.5 12.3284 12.8284 13 12 13C11.1716 13 10.5 12.3284 10.5 11.5C10.5 10.6716 11.1716 10 12 10ZM7.5 10C8.32843 10 9 10.6716 9 11.5C9 12.3284 8.32843 13 7.5 13C6.67157 13 6 12.3284 6 11.5C6 10.6716 6.67157 10 7.5 10ZM16.5 10C17.3284 10 18 10.6716 18 11.5C18 12.3284 17.3284 13 16.5 13C15.6716 13 15 12.3284 15 11.5C15 10.6716 15.6716 10 16.5 10Z"
            fill="#7E8681"
          />
        </svg>
      </div>

      {isMenuActive && (
        <WithGlobalPopup
          container={ref}
          positionCalculator={AbsoluteLikePositionCalculator}
          top={0}
          left={0}
          onMaskClick={() => setIsMenuActive(false)}
        >
          <ActionsMenu {...props} closeMenu={() => setIsMenuActive(false)} />
        </WithGlobalPopup>
      )}
    </div>
  );
}

interface ActionsMenuProps {
  dictionary: PlainDictionary;
  closeMenu: () => void;
}

function ActionsMenu({ dictionary, closeMenu }: ActionsMenuProps): JSX.Element {
  const { showDeletedDictionaries, setTableState, restoreDictionary } =
    React.useContext(TableControlsContext);

  function onRestoreButtonClick() {
    closeMenu();
    restoreDictionary(dictionary.id);
  }

  function onEditDictionaryButtonClick() {
    closeMenu();
    setTableState({ _type: "editDictionaryData", dictionary });
  }

  function onEditLinksButtonClick() {
    closeMenu();
    setTableState({
      _type: "editDictionaryLinks",
      dictionaryIds: [dictionary.id],
    });
  }

  function onRemoveButtonClick() {
    closeMenu();
    setTableState({ _type: "deleteDictionary", dictionary });
  }

  const items = showDeletedDictionaries
    ? [{ label: "Восстановить", onClick: onRestoreButtonClick }]
    : [
        {
          label: "Редактировать словарь",
          onClick: onEditDictionaryButtonClick,
        },
        { label: "Редактировать связи", onClick: onEditLinksButtonClick },
        { label: "Удалить", onClick: onRemoveButtonClick },
      ];

  return (
    <div className={styles.menu}>
      {items.map((item) => (
        <div key={item.label} className={styles.item} onClick={item.onClick}>
          {item.label}
        </div>
      ))}
    </div>
  );
}
