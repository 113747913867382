import * as React from "react";
import { Dictionary, cloneDeep } from "lodash";

import type { TriggersHolder, Trigger, Formula } from "../types";

import { Header } from "../Header";
import { Footer } from "../Footer";
import { FormulasBuilder } from "../FormulasBuilder";
import type { Operand, OperationScheme } from "../FormulasBuilder";
import { TriggerSelector } from "../TriggerSelector";

import { buildSelectorsFromHolder } from "../utils";
import { blankSchema } from "../data";

import * as styles from "./styles.scss";

export interface Props {
  triggersHolder: TriggersHolder;
  operands: Operand[];
  onSave: (triggersHolder: TriggersHolder, operands: Operand[]) => void;
  onClose: () => void;
}

export const Calculator: React.FC<Props> = ({
  triggersHolder,
  operands: initOperands,
  onSave,
  onClose,
}) => {
  const [selectedTriggerId, setTriggerId] = React.useState(
    triggersHolder.selectedTriggerId
  );
  const [triggers, setTriggers] = React.useState<Dictionary<Trigger>>(
    triggersHolder.triggers
  );
  const [operands, setOperands] = React.useState<Operand[]>(initOperands);

  React.useEffect(() => {
    setOperands(initOperands);
  }, [initOperands]);

  const onSelect = React.useCallback((trigger) => {
    setTriggerId(trigger.id);
  }, []);

  const selectors = React.useMemo(
    () => buildSelectorsFromHolder(triggersHolder, { onSelect }),
    [triggersHolder, onSelect]
  );

  const onFormulasBuilderChange = React.useCallback(
    (index: string | number, formula: Formula) => {
      const schemas = [...triggers[selectedTriggerId].schemas];
      schemas[index] = formula;

      setTriggers({
        ...triggers,
        [selectedTriggerId]: {
          ...triggers[selectedTriggerId],
          schemas,
        },
      });
    },
    [selectedTriggerId, triggers]
  );

  const onFormulasBuilderRemove = React.useCallback(
    (selectedIndex: number) => {
      const schemas = [...triggers[selectedTriggerId].schemas];

      setTriggers({
        ...triggers,
        [selectedTriggerId]: {
          ...triggers[selectedTriggerId],
          schemas: schemas.filter(
            (_, currentIndex) => currentIndex !== selectedIndex
          ),
        },
      });
    },
    [selectedTriggerId, triggers]
  );

  const onSchemaAdd = React.useCallback(() => {
    const newSchemas = [...triggers[selectedTriggerId].schemas];
    newSchemas.push(cloneDeep(blankSchema));

    setTriggers({
      ...triggers,
      [selectedTriggerId]: {
        ...triggers[selectedTriggerId],
        schemas: newSchemas,
      },
    });
  }, [triggers, blankSchema, selectedTriggerId]);

  const onAddOperands = React.useCallback(
    (operand: Operand) => {
      setOperands([
        ...operands,
        {
          id: operand.id,
          title: operand.title,
          description: "Числовое значение",
          meta: {
            type: "const",
            value: operand.title,
            order: 3,
          },
        },
      ]);
    },
    [operands]
  );

  const onSaveButtonClick = React.useCallback(() => {
    onSave({ ...triggersHolder, triggers }, operands);
    onClose();
  }, [triggers, operands, onSave]);

  const onCancelButtonClick = React.useCallback(() => onClose(), [onClose]);
  const onCloseButtonClick = React.useCallback(() => onClose(), [onClose]);

  return (
    <div>
      <div className={styles.headerWrapper}>
        <Header onCloseButtonClick={onCloseButtonClick} />
      </div>

      <div className={styles.triggerSelectorWrapper}>
        <TriggerSelector selectors={selectors} />
      </div>

      {selectedTriggerId && (
        <div className={styles.formulaWrapper}>
          <FormulasBuilder
            key={selectedTriggerId}
            schemas={triggers[selectedTriggerId].schemas}
            operands={operands}
            onAddOperands={onAddOperands}
            onSchemaAdd={onSchemaAdd}
            onSchemaChange={onFormulasBuilderChange}
            onSchemaRemove={onFormulasBuilderRemove}
          />
        </div>
      )}

      <Footer
        isDisabledBindButton={false}
        onCancelButtonClick={onCancelButtonClick}
        onSaveButtonClick={onSaveButtonClick}
      />
    </div>
  );
};

export type { OperationScheme };
