import * as React from "react";
import { connect } from "react-redux";

import type { UserParams } from "@sm/types/admin";

import { UserInfo } from "./UserInfo";
import type { StoreState } from "../../../../store";
import { getUser } from "../../../../store/departmentRolePage/selector";

interface Props extends Partial<MapProps> {
  userId: number;
  onCancelClick: () => void;
}

interface MapProps {
  user: UserParams;
}

@(connect(mapStateToProps) as any)
export class UserInfoContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const { user, onCancelClick } = this.props;

    return React.createElement(UserInfo, {
      user,
      onCancelClick,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    user: getUser(state, ownProps.userId),
  };
}
