import * as React from "react";
import * as lodash from "lodash";
import classNames from "classnames";

import * as style from "./CheckboxListCell.scss";

import {
  CustomScrollbar_new as CustomScrollbar,
  Input_redesign as Input,
  InputTheme_redesign as InputTheme,
  Icon,
  IconType,
} from "sber-marketing-ui";
import { CellEditorWrapper } from "../CellEditorWrapper";

const MAX_SCROLLBAR_HEIGHT = 320;

interface Props {
  entityName: string;
  items: CheckboxItem[];
  selectedValues: React.ReactText[];
  emptyListMessage?: string;
  allowSearch?: boolean;
  onValueChange: (value: React.ReactText[]) => void;
}

export interface CheckboxItem {
  title: string;
  value: React.ReactText;
}

export const CheckboxListCellEditTemplate = ({
  entityName,
  items,
  selectedValues = [],
  emptyListMessage,
  allowSearch = false,
  onValueChange,
}: Props): JSX.Element => {
  const [searchString, setSearchString] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [displayTitle, setDisplayTitleStatus] = React.useState(false);

  React.useEffect(() => {
    const newFilteredItems = items.filter((item) =>
      item.title.toLowerCase().includes(searchString.toLowerCase())
    );

    setFilteredItems(newFilteredItems);
  }, [searchString]);

  const onInputBlur = React.useCallback(() => {
    setDisplayTitleStatus(true);
  }, []);

  const itemsToDisplay = allowSearch ? filteredItems : items;

  return (
    <div className={style.root}>
      {(!allowSearch || displayTitle) && (
        <div className={style.title}>
          {makeTitle(entityName, items, selectedValues)}
        </div>
      )}

      {allowSearch && !displayTitle && (
        <div className={style.input}>
          <Input
            value={searchString}
            placeholder={""}
            theme={InputTheme.Borderless}
            onInputChange={(value) => setSearchString(value)}
            onBlur={onInputBlur}
            autofocus
          />
        </div>
      )}

      <div className={classNames(style.openerIcon, style.opened)}>
        <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
      </div>

      <CellEditorWrapper>
        <div className={style.content}>
          {!lodash.isEmpty(itemsToDisplay) && (
            <CustomScrollbar
              maxHeight={MAX_SCROLLBAR_HEIGHT}
              hideScrollX
              freezeScrollX
            >
              {itemsToDisplay.map((item) => (
                <div
                  key={item.value}
                  className={style.item}
                  onClick={() =>
                    onValueChange(lodash.xor(selectedValues, [item.value]))
                  }
                >
                  <div className={style.checkIcon}>
                    <Icon
                      type={
                        selectedValues.includes(item.value)
                          ? IconType.CHECKBOX24_CHECKED
                          : IconType.CHECKBOX24_UNCHECKED
                      }
                      svgSize={22}
                    />
                  </div>

                  {item.title}
                </div>
              ))}
            </CustomScrollbar>
          )}

          {lodash.isEmpty(items) && (
            <div className={style.emptyListMessage}>{emptyListMessage}</div>
          )}

          {!lodash.isEmpty(items) && lodash.isEmpty(itemsToDisplay) && (
            <div className={style.noItemsMessage}>Ничего не найдено</div>
          )}
        </div>
      </CellEditorWrapper>
    </div>
  );
};

function makeTitle(
  entityName: string,
  items: CheckboxItem[],
  selectedValues: React.ReactText[]
) {
  if (selectedValues.length == 0) {
    return "—";
  }

  const selectedItems = items.filter((item) =>
    selectedValues.includes(item.value)
  );

  if (selectedItems.length == 1 || selectedItems.length == 2) {
    return (
      <>
        <div className={style.titleItem}>{selectedItems[0].title}</div>

        {selectedItems.length == 2 && (
          <div className={style.titleItem}>{selectedItems[1].title}</div>
        )}
      </>
    );
  }

  return (
    <div className={style.itemsCount}>
      {entityName}: {selectedItems.length}
    </div>
  );
}
