import * as React from "react";
import { connect } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type {
  TableHeaderCellParams,
  TableBodyCellParams,
  CellPosition,
  ColumnWidths,
  Filter,
  LineId,
  ColumnName,
  SortingParams,
} from "./types";
import type {
  CreativeRequestContract,
  CreativeRequestContractCreateParams,
} from "@api";

import { CellsStorage, TableView, CellEvent } from "sber-marketing-ui";
import { TableTemplate } from "./TableTemplate";
import {
  tableColumns,
  leftFixedColumns,
  rightFixedColumns,
  ColumnsConfig,
  LineType,
  CellType,
} from "./ColumnsConfig";
import { ColumnHeaderFactory, CellsFactory } from "./modules";
import { getArchivedContractsFilter } from "@store/contractsPage/selectors";

const columnWidths: ColumnWidths = lodash.mapValues(
  ColumnsConfig,
  (item) => item.defaultWidth
);

interface Props {
  loading: boolean;
  archivedFilterIsActive: boolean;
  getLine: (lineId: LineId) => CreativeRequestContract;
  getLines: () => CreativeRequestContract[];
  createLine: (params: CreativeRequestContractCreateParams) => Promise<void>;
  archiveLine: (lineId: string) => Promise<void>;
  restoreLine: (lineId: string) => Promise<void>;
}

interface State {
  lineIds: string[];
  leftFixedColumns: ColumnName[];
  columns: ColumnName[];
  sortingParams: SortingParams;
  filters: Filter[];
  tableHeight: number;
}

export class TableBehaviour extends React.PureComponent<Props, State> {
  private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
  private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
  private table: TableView;
  private columnHeaderFactory: ColumnHeaderFactory;
  private cellsFactory: CellsFactory;
  private fixedWidthColumns: ColumnName[];

  public constructor(props: Props) {
    super(props);

    this.state = {
      lineIds: [],
      leftFixedColumns: leftFixedColumns,
      columns: tableColumns,
      sortingParams: { columnName: null, orderType: null },
      filters: [],
      tableHeight: 0,
    };

    this.columnHeaderFactory = new ColumnHeaderFactory({
      getLine: this.props.getLine,
      getLines: this.props.getLines,
      getLeftFixedColumns: this.getLeftFixedColumns,
      setLeftFixedColumns: this.setLeftFixedColumns,
      getArchivedFilter: this.getArchivedFilter,
      getFilters: this.getFilters,
      setFilters: this.setFilters,
    });

    this.cellsFactory = new CellsFactory({
      getLine: this.props.getLine,
      onLineArchive: this.onLineArchiveClick,
      onLineRestore: this.onLineRestoreClick,
      onCellClose: () => this.table.setCursorPosition(null),
    });

    this.headerCellsStorage = new CellsStorage({
      makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
    });

    this.tableCellsStorage = new CellsStorage({
      makeCellParams: this.cellsFactory.makeCellParams,
    });

    this.updateFixedWidthColumns();
  }

  public async componentDidMount() {
    this.updateTableHeight();
    this.updateLineIds();

    window.addEventListener("resize", this.onPageResize);
  }

  public async componentWillUnmount() {
    window.removeEventListener("resize", this.onPageResize);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const sortingParamsChanged =
      this.state.sortingParams !== prevState.sortingParams;
    const archivedFilterChanged =
      this.props.archivedFilterIsActive !== prevProps.archivedFilterIsActive;
    const filtersChanged = this.state.filters !== prevState.filters;
    const loadingFinished = !this.props.loading && prevProps.loading;

    if (sortingParamsChanged || archivedFilterChanged || filtersChanged) {
      this.updateLineIds();
    }

    if (filtersChanged) {
      const columnNames = this.state.filters.map((item) => item.columnName);
      const prevColumnNames = prevState.filters.map((item) => item.columnName);

      const removedColumnsNames = lodash.difference(
        prevColumnNames,
        columnNames
      );
      const addedColumnsNames = lodash.difference(columnNames, prevColumnNames);

      [...removedColumnsNames, ...addedColumnsNames].forEach((columnName) => {
        this.updateHeaderCell(columnName);
      });
    }

    if (loadingFinished) {
      this.updateLineIds();
    }
  }

  public render(): JSX.Element {
    const { loading } = this.props;
    const { lineIds, leftFixedColumns, columns, filters, tableHeight } =
      this.state;

    return React.createElement(TableTemplate, {
      loading,
      headerCellsStorage: this.headerCellsStorage,
      tableCellsStorage: this.tableCellsStorage,
      columns,
      leftFixedColumns,
      rightFixedColumns,
      fixedWidthColumns: this.fixedWidthColumns,
      lineIds,
      columnWidths,
      filters,
      tableHeight,
      tableRef: this.tableRef,
      setFilters: this.setFilters,
      onCellEvent: this.onCellEvent,
    });
  }

  @autobind
  protected onPageResize() {
    this.updateTableHeight();
    this.updateLineIds();
  }

  @autobind
  public onLineCreate(lineId: string) {
    const line = this.props.getLine(lineId);

    if (line) {
      this.updateLineIds();
    }
  }

  @autobind
  public onLineUpdate(lineId: string) {
    this.updateLineCells(lineId);
    this.updateLineIds();
  }

  @autobind
  public onLineReloaded(lineId: string) {
    this.updateLineCells(lineId);
    this.updateLineIds();
  }

  @autobind
  public getLeftFixedColumns(): ColumnName[] {
    return this.state.leftFixedColumns;
  }

  @autobind
  public setLeftFixedColumns(updatedFixedColumns: ColumnName[]) {
    if (updatedFixedColumns !== undefined) {
      const updatedColumns = lodash.without(
        [...leftFixedColumns, ...tableColumns],
        ...updatedFixedColumns
      );

      this.setState({
        leftFixedColumns: updatedFixedColumns,
        columns: updatedColumns,
      });
    }
  }

  @autobind
  public setColumnWidths(columnWidths: ColumnWidths) {
    if (!this.props.loading && columnWidths) {
      this.table.setColumnWidths(columnWidths);
    }
  }

  @autobind
  public setFilterValues(filters: Filter[]) {
    if (lodash.isArray(filters)) {
      this.setState({
        filters,
      });
    }
  }

  @autobind
  public async getTableDataForXLSX(): Promise<{
    columnsWidths: number[];
    headers: string[];
    rows: React.ReactText[][];
  }> {
    const { lineIds, leftFixedColumns } = this.state;

    const columnWidths = this.table.getColumnWidths();

    const columns = [
      ...(leftFixedColumns || []),
      ...tableColumns,
      ...(rightFixedColumns || []),
    ];

    const headers = await Promise.all(
      columns.map(async (columnName) => {
        const params = await this.headerCellsStorage.getCellParams(columnName);

        return params.cellProps.title;
      })
    );

    const rows = await Promise.all(
      lineIds.map(
        async (lineId) =>
          await Promise.all(
            columns.map(async (columnName) => {
              const cellParams = await this.tableCellsStorage.getCellParams({
                lineId,
                columnName,
              });

              const column = ColumnsConfig[columnName];

              const cellType = column.type[LineType.Line];

              let title = cellParams?.cellProps?.title || "";

              if (
                cellType === CellType.LineHeader ||
                cellType === CellType.Text ||
                cellType === CellType.Select
              ) {
                title = title.toString();
              }

              if (
                cellType === CellType.FundsInput ||
                cellType === CellType.FundsSelect
              ) {
                title = title.replaceAll(" ", "");
                title = title.replaceAll(",", ".");
                title = parseFloat(title);

                if (lodash.isNaN(title)) {
                  title = "";
                }
              }

              return title;
            })
          )
      )
    );

    return {
      columnsWidths: columns.map((columnName) => columnWidths[columnName]),
      headers,
      rows,
    };
  }

  @autobind
  protected tableRef(component: TableView) {
    this.table = component || null;
  }

  @autobind
  protected async onLineArchiveClick(lineId: LineId) {
    await this.props.archiveLine(lineId);
    this.table.setCursorPosition(null);
  }

  @autobind
  protected async onLineRestoreClick(lineId: LineId) {
    await this.props.restoreLine(lineId);
    this.table.setCursorPosition(null);
  }

  @autobind
  protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
    switch (eventType) {
      case CellEvent.MouseSelection:
        await this.updateCell(position, true);
        break;

      case CellEvent.SelectionCancel:
        await this.updateCell(position, false);
        break;
    }
  }

  private updateTableHeight() {
    const tableBodyOffsetTop = 117;
    const tableBodyOffsetBottom = 100;

    this.setState({
      tableHeight:
        window.innerHeight - tableBodyOffsetTop - tableBodyOffsetBottom,
    });
  }

  private async updateHeaderCell(columnName: ColumnName) {
    const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(
      columnName
    );

    this.headerCellsStorage.setCellParams(columnName, cellParams);
  }

  private async updateCell(position: CellPosition, edit = false) {
    const cellParams = await this.cellsFactory.makeCellParams(position, edit);

    this.tableCellsStorage.setCellParams(position, cellParams);
  }

  @autobind
  private updateLineCells(lineId: LineId) {
    const { leftFixedColumns, columns } = this.state;

    const allColumns = [...leftFixedColumns, ...columns, ...rightFixedColumns];

    allColumns.forEach((columnName) => {
      const cellPosition = { lineId, columnName };

      const cellEditStatus = this.table.getCellEditStatus(cellPosition);
      this.updateCell(cellPosition, cellEditStatus);
    });
  }

  private updateFixedWidthColumns() {
    this.fixedWidthColumns = lodash
      .keys(ColumnsConfig)
      .filter(
        (columnName) => ColumnsConfig[columnName].disableWidthChange
      ) as ColumnName[];
  }

  @autobind
  private async updateLineIds() {
    const lines = this.props.getLines();

    const filteredLines = await this.filterLines(lines, LineType.Line);

    const lineIds = filteredLines.map((item) => item.model.id);

    this.setState({
      lineIds,
    });
  }

  private async filterLines<T extends CreativeRequestContract>(
    lines: T[],
    lineType: LineType
  ): Promise<T[]> {
    const { archivedFilterIsActive } = this.props;
    const { filters } = this.state;

    let filteredLines = lines;

    if (archivedFilterIsActive) {
      filteredLines = filteredLines.filter(
        (item) => item.model.status === "active"
      );
    }

    for (const filter of filters) {
      const cellsType = lodash.get(ColumnsConfig, [
        filter.columnName,
        "type",
        lineType,
      ]);

      const columnsValues = await Promise.all(
        filteredLines.map((line) =>
          this.cellsFactory.getCellValue({
            columnName: filter.columnName,
            lineId: line.model.id,
          })
        )
      );

      filteredLines = filteredLines.filter((line, index) => {
        const selectedValues = filter.selectedValues;

        if (lodash.isEmpty(selectedValues)) {
          return true;
        }

        let value = columnsValues[index];

        if (
          (cellsType === CellType.Input || cellsType === CellType.Textarea) &&
          value === ""
        ) {
          value = null;
        }

        if (cellsType === CellType.Datepicker && value !== null) {
          value = value.valueOf();
        }

        if (cellsType === CellType.FundsInput) {
          value = roundNumber(value as number);
        }

        return lodash.isArray(value)
          ? selectedValues.some(
              (item) =>
                value.includes(item) || (item === null && lodash.isEmpty(value))
            )
          : selectedValues.includes(value);
      });
    }

    return filteredLines;

    function roundNumber(value: number, digitsAfterComma = 2): string {
      const roundedValue = Math.round(value * 100) / 100;
      const formatedValue = roundedValue.toFixed(digitsAfterComma);

      const [decimalPart, fractionPart] = formatedValue.split(".");

      return `${decimalPart}${fractionPart ? `.${fractionPart}` : ""}`;
    }
  }

  @autobind
  private getArchivedFilter(): boolean {
    return this.props.archivedFilterIsActive;
  }

  @autobind
  private getFilters(): Filter[] {
    return this.state.filters;
  }

  @autobind
  private setFilters(filters: Filter[]) {
    this.setState({
      filters,
    });
  }
}
