import { reducerWithInitialState } from "typescript-fsa-reducers";

import {
  UserParams,
  RoleAttributes,
  DepartmentAttributes,
  DepartmentUserAttributes,
  PermissionListItem,
} from "@sm/types/admin";
import {
  loadPageState,
  addDepartment,
  removeDepartment,
  setSelectedDepartment,
  addRole,
  updateStateWithNewDepartment,
  setSelectedRole,
  addSubDepartment,
  updateRole,
  setIsOnNewRole,
  updateRight,
  setSelectedPage,
  loadUsers,
  updateDepartmentName,
  updateUserRole,
  removeUser,
  loadDepartmentUsers,
  removeAvailableUser,
} from "../actions";

import { DepartmentRolePageReducer } from "./DepartmentRolePageReducer";
import { DepartmentReducer } from "./DepartmentReducer";
import { RoleReducer } from "./RoleReducer";
import { UsersReducer } from "./UsersReducer";

const roles: RoleAttributes[] = [];
const departments: DepartmentAttributes[] = [];
const selectedDepartmentIds: string[] = [];
const rightGroups: PermissionListItem[] = [];
const departmentUsers: DepartmentUserAttributes[] = [];
const users: UserParams[] = [];
const availableUsers: UserParams[] = [];

const initialState = {
  roles,
  departments,
  selectedDepartmentIds,
  rightGroups,
  departmentUsers,
  users,
  availableUsers,
  isOnNewRole: false,
  isOnAddUser: false,
};

export const departmentRolePageReducer = reducerWithInitialState(initialState)
  .case(loadPageState, DepartmentRolePageReducer.loadPageState)
  .case(
    updateStateWithNewDepartment,
    DepartmentRolePageReducer.updateStateWithNewDepartment
  )
  .case(setSelectedPage, DepartmentRolePageReducer.setSelectedPage)
  .case(addDepartment, DepartmentReducer.addDepartment)
  .case(removeDepartment, DepartmentReducer.removeDepartment)
  .case(setSelectedDepartment, DepartmentReducer.setSelectedDepartment)
  .case(addSubDepartment, DepartmentReducer.addSubDepartment)
  .case(addRole, RoleReducer.addRole)
  .case(setSelectedRole, RoleReducer.setSelectedRole)
  .case(updateRole, RoleReducer.updateRole)
  .case(setIsOnNewRole, RoleReducer.setIsOnNewRole)
  .case(updateRight, RoleReducer.updateRight)
  .case(loadUsers, UsersReducer.loadUsers)
  .case(updateUserRole, UsersReducer.updateUserRole)
  .case(removeUser, UsersReducer.removeUser)
  .case(removeAvailableUser, UsersReducer.removeAvailableUser)
  .case(loadDepartmentUsers, UsersReducer.loadDepartmentUsers)
  .case(updateDepartmentName, DepartmentRolePageReducer.updateDepartmentName);
