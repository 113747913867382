import * as React from "react";

import * as style from "./DropdownMenu.scss";

import { SelectItem } from "@sm/types/frontend";

import {
  LabeledTextarea,
  LabeledInput,
  LabeledSelect,
  Checkbox_redesign,
} from "@sm/ui";
import { DropdownContent } from "./DropdownContent";

const CHECKBOX_ICON_SIZE = 20;

export interface Props {
  name: string;
  validationMessage: string;
  tooltipMessage?: string;
  selectContent: SelectItem[];
  isRequired: boolean;
  isCalculated: boolean;
  isSchemaActive: boolean;
  isSchemaEditable: boolean;
  isFormulaEditable: boolean;
  isDictionary: boolean;
  dictionaryType: string;
  duplicateToReport: boolean;
  isClientHide: boolean;
  onNameChange: (value: string) => void;
  onValidationMessageChange: (value: string) => void;
  onTooltipMessageChange: (value: string) => void;
  onRequirementChange: (isChecked: boolean) => void;
  onDuplicateToReportChange: (isChecked: boolean) => void;
  onCalculatedChange: (isChecked: boolean) => void;
  onAddValue: (value: string) => void;
  onChangeValue: (params: { value: string; id: string }) => void;
  onSumButtonClick: (params: { lineId: string }) => void;
  onDictionaryChange: (isChecked: boolean) => void;
  onDictionaryTypeChange: (value: React.ReactText) => void;
  onClientHideCheckboxChange: (isChecked: boolean) => void;
}

export function DropdownMenu({
  name,
  validationMessage,
  tooltipMessage = "",
  selectContent,
  isRequired,
  isCalculated,
  isSchemaActive,
  isSchemaEditable,
  isFormulaEditable,
  isDictionary,
  dictionaryType,
  duplicateToReport,
  isClientHide,
  onNameChange,
  onValidationMessageChange,
  onTooltipMessageChange,
  onRequirementChange,
  onDuplicateToReportChange,
  onCalculatedChange,
  onAddValue,
  onChangeValue,
  onSumButtonClick,
  onDictionaryChange,
  onDictionaryTypeChange,
  onClientHideCheckboxChange,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.inputProperty}>
        <LabeledInput
          title={"Название поля"}
          value={name}
          onValueChange={onNameChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledInput
          title={"Текст ошибки"}
          value={validationMessage}
          onValueChange={onValidationMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.inputProperty}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Выбор из словаря"}
          isChecked={isDictionary}
          onSwitch={onDictionaryChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isFormulaEditable}
        />
      </div>

      {isDictionary ? (
        <div className={style.dropdownProperty}>
          <LabeledSelect
            title={"Словарь"}
            items={[
              {
                label: "Подразделения (МВЗ / Дивизион)",
                value: "divisions",
              },
              {
                label: "ID бюджета",
                value: "budget",
              },
            ]}
            selectedValue={dictionaryType}
            onSelection={onDictionaryTypeChange}
            disabled={!isSchemaEditable}
            disableSearch
            disableAutoSelectSingleItem
          />
        </div>
      ) : (
        <div className={style.dropdownProperty}>
          <DropdownContent
            name={"Содержимое"}
            content={selectContent.map((item) => ({
              id: item.id,
              value: item.name,
              hasFormulas: Boolean(item?.formulas?.length) || false,
            }))}
            displayCalculateButton={isCalculated}
            canAddValue={isSchemaEditable}
            canChangeValue={isSchemaEditable}
            onAddValue={onAddValue}
            onChangeValue={onChangeValue}
            onSumButtonClick={onSumButtonClick}
          />
        </div>
      )}

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Обязательное поле"}
          isChecked={isRequired}
          onSwitch={onRequirementChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Дублировать в отчет"}
          isChecked={duplicateToReport}
          onSwitch={onDuplicateToReportChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Поле входит в расчет калькурятора"}
          isChecked={isCalculated}
          onSwitch={onCalculatedChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isFormulaEditable}
        />
      </div>

      <div className={style.checkboxProperty}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          iconSize={CHECKBOX_ICON_SIZE}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>
    </div>
  );
}
