// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-Elements-Fields-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__extraFields{padding-top:4px;padding-bottom:4px}.src-modules-BriefPage-Schema-Elements-Fields-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fields{width:100%}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Schema/Elements/Fields/SwitchGroup/SwitchGroupFields/SwitchGroupFields.scss"],"names":[],"mappings":"AAAA,4GACI,eAAgB,CAChB,kBAAmB,CACtB,uGAGG,UAAW","sourcesContent":[".extraFields {\n    padding-top: 4px;\n    padding-bottom: 4px;\n}\n\n.fields {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraFields": "src-modules-BriefPage-Schema-Elements-Fields-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__extraFields",
	"fields": "src-modules-BriefPage-Schema-Elements-Fields-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fields"
};
export default ___CSS_LOADER_EXPORT___;
