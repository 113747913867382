// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-ActivityTypePage-ActivityTypeForm-_ColorPicker___chrome-picker{overflow:hidden;width:100% !important;max-height:192px;border-radius:0 !important;box-shadow:none !important;font-family:inherit !important}\n", "",{"version":3,"sources":["webpack://./src/modules/ActivityTypePage/ActivityTypeForm/ColorPicker_.scss"],"names":[],"mappings":"AAAA,4EACI,eAAgB,CAEhB,qBAAsB,CACtB,gBAAiB,CAEjB,0BAA2B,CAC3B,0BAA2B,CAE3B,8BAA+B","sourcesContent":[".chrome-picker {\n    overflow: hidden;\n\n    width: 100% !important;\n    max-height: 192px;\n\n    border-radius: 0 !important;\n    box-shadow: none !important;\n\n    font-family: inherit !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chrome-picker": "src-modules-ActivityTypePage-ActivityTypeForm-_ColorPicker___chrome-picker"
};
export default ___CSS_LOADER_EXPORT___;
