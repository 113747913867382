import * as React from "react";

import * as style from "./EditSchemeDepartment.scss";

import {
  Select,
  SelectProps,
  OpenerIconType,
  Button,
  ButtonTheme,
} from "@sm/ui";

interface Props {
  divisionSelect: SelectProps;
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

export function EditSchemeDepartment({
  divisionSelect,
  onCancelClick,
  onConfirmClick,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.title}>Прязать департамент</div>

      <div className={style.selectTitle}>Департамент</div>

      <div className={style.departmentSelect}>
        <Select
          placeholder={""}
          noResultsText={""}
          clearable={false}
          openerIcon={OpenerIconType.Kebab}
          {...(divisionSelect as any)}
        />
      </div>

      <div className={style.buttonsWrapper}>
        <div className={style.buttonWrapper}>
          <Button theme={ButtonTheme.SecondaryOutline} onClick={onCancelClick}>
            Отмена
          </Button>
        </div>

        <div className={style.buttonWrapper}>
          <Button theme={ButtonTheme.PrimaryMain} onClick={onConfirmClick}>
            Добавить
          </Button>
        </div>
      </div>
    </div>
  );
}
