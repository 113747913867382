export const enum FileAssetType {
  DOC = "doc",
  PDF = "pdf",
  TXT = "txt",
  JPG = "jpg",
  XLS = "xls",
  PNG = "png",
}

export interface UploadParams {
  handleUploadError?: (params: UploadErrorParams) => void;
  handleUploadSuccess?: (params: FileParams) => void;
  timeout?: number;
}

export interface FileApiUploadParams {
  activityId?: string;
  autopilotId?: string;
  briefId?: string;
  fieldId?: string;
  uniqId?: number;
  parentUniqId?: number;
  taskId?: string;
  commentId?: string;
  fileId?: string;
  originName?: string;
  type?: FileAssetType | string;
}

export interface UploadErrorParams {
  type: string;
  message: string;
}

export interface FileParams {
  id?: string;
  name: string;
  originName: string;
  type: string;
  size: number;
  storage?: string;
  createTime?: number | string | Date;
}

/** Image preview data */
export interface ImagePreviewData {
  /** Preview width */
  width: number;
  /** Preview URL */
  url: string;
}

/** Image data */
export interface ImageData {
  /** Image unique identifier */
  id: string;
  /** Image URL template */
  urlTemplate: string;
  /** Image previews data */
  previews: ImagePreviewData;
}

/** Image response */
export interface UploadImageResponse {
  /** Image data */
  image: ImageData;
}

/** Generated image URLs */
export interface ImageUrls {
  /** Original URL */
  original: string;
  /** Preview URL */
  preview: string;
}

/** Type of target where asset attached to */
export const enum FileAssetTarget {
  /** File asset */
  FILE = "file",
  /** Task asset */
  TASK = "task",
  /** Commentary asset */
  COMMENTARY = "commentary",
  /** Brief asset */
  BRIEF = "brief",
  AUTOPILOT = "autopilot",
}

/** File asset */
export interface FileAsset {
  id?: string;
  /** System name */
  name: string;
  /** Original name */
  originName: string;
  /** Creation date */
  createdAt: number;
  /** Type of file */
  type: string;
  /** Type of target - entity where asset was attached */
  targetType: FileAssetTarget;
  /** Identifier of target */
  targetId: string;
  /** Identifier of target's parent (optional, for task comment's and brief's fields) */
  parentTargetId?: string;
  /** Size of file */
  size: number;
  /** Type of storage */
  storage?: string;
  /** Identifier of user, who uploaded this file */
  uploadedBy?: string;
  /** File data */
  file?: File;
  /** File preview URL (for images only) */
  previewUrl?: string;
  /** File full size URL (for images only) */
  fullSizeUrl?: string;
  /** Is file loading in progress */
  isLoading?: boolean;
  /** File asset loading progress in percents */
  loadingProgress?: number;
}
