import * as React from "react";
import { Icon, IconType, FilterMenu } from "@sm/ui";

import { PlainDictionary } from "@sm/types/frontend";

import * as styles from "./SharingCard.scss";

interface Props {
  showDeleteButton: boolean;
  availableBlocks: PlainDictionary[];
  selectedBlocks: string[];
  availableActivityTypes: PlainDictionary[];
  selectedActivityTypes: string[];
  organizationTitle: string;
  onDeleteSharingButtonClick: () => void;
  onActivityTypesSelected: (itemIds: string[]) => void;
  onBlocksSelected: (itemIds: string[]) => void;
}

export function SharingCard({
  showDeleteButton,
  availableBlocks,
  selectedBlocks,
  availableActivityTypes,
  selectedActivityTypes,
  organizationTitle,
  onDeleteSharingButtonClick,
  onActivityTypesSelected,
  onBlocksSelected,
}: Props): JSX.Element {
  return (
    <div className={styles.root}>
      {showDeleteButton && (
        <div
          className={styles.deleteSharingButton}
          onClick={onDeleteSharingButtonClick}
        >
          <Icon type={IconType.PROJECT_STAGED_CLOSE} svgSize={32} />
        </div>
      )}

      <div>
        <div className={styles.organizationTitle}>Получить доступ</div>

        <div className={styles.organization}>{organizationTitle}</div>
      </div>

      <div className={styles.sharingParams}>
        <div className={styles.sharingParameter}>
          <FilterMenu
            filterTitle="Группа в календаре"
            preserveAllSelectedState
            items={availableBlocks.map((block) => ({
              id: block.id,
              title: block.value,
            }))}
            checkedItemsIds={selectedBlocks}
            onItemSelection={onBlocksSelected}
          />
        </div>

        <div className={styles.sharingParameter}>
          <FilterMenu
            filterTitle="Тип проекта"
            preserveAllSelectedState
            items={availableActivityTypes.map((activityType) => ({
              id: activityType.id,
              title: activityType.value,
            }))}
            checkedItemsIds={selectedActivityTypes}
            onItemSelection={onActivityTypesSelected}
          />
        </div>
      </div>
    </div>
  );
}
