import * as React from "react";
import * as lodash from "lodash";
import { Popup } from "@sm/ui";
import { PlainDictionary } from "@sm/types/budget";

import { ActivityTypeColorData } from "../../../store/activityType/types";

import type { SelectMenuItemParams } from "../../common/SelectMenu";
import { SelectMenu } from "../../common/SelectMenu";

import { EditActivityTypeForm } from "../ActivityTypeForm";

import * as style from "./ActivityTypeRow.scss";

interface Props {
  activityType: PlainDictionary;
  activityTypeColorData: ActivityTypeColorData;
  isOnEdit: boolean;
  toggleOnEdit: () => void;
}

export function ActivityTypeRow({
  activityType,
  activityTypeColorData,
  isOnEdit,
  toggleOnEdit,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      {isOnEdit && (
        <Popup onOutOfContentClick={toggleOnEdit}>
          <EditActivityTypeForm
            activityType={activityType}
            onCancelClick={toggleOnEdit}
          />
        </Popup>
      )}

      <div className={style.nameColumn}>
        <div className={style.name}>{activityType.value}</div>
      </div>

      <div className={style.propertiesColumn}>
        {(activityTypeColorData.preparation ||
          activityTypeColorData.realization ||
          activityTypeColorData.hover) && (
          <React.Fragment>
            <div
              className={style.property}
              style={{ color: activityTypeColorData.preparation }}
            >
              preparation
            </div>

            <div
              className={style.property}
              style={{ color: activityTypeColorData.realization }}
            >
              realization
            </div>

            <div
              className={style.property}
              style={{ color: activityTypeColorData.hover }}
            >
              hover
            </div>
          </React.Fragment>
        )}
      </div>

      <div className={style.menuColumn}>
        <SelectMenu
          items={[
            {
              label: "Редактировать",
              onClick: toggleOnEdit,
            },
          ]}
          contentClass={style.selectMenuContent}
        />
      </div>
    </div>
  );
}
