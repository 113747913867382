import * as React from "react";
import classNames from "classnames";

import * as style from "./StatusCell.scss";

import { ConfirmModal } from "./ConfirmModal";

const titleByStatus = {
  ["active"]: "Активный",
  ["archived"]: "Архив",
};

interface Props {
  name: string;
  status: "active" | "archived";
  onArchiveConfirm: () => void;
  onRestoreConfirm: () => void;
}

export const StatusCell = ({
  name,
  status,
  onArchiveConfirm,
  onRestoreConfirm,
}: Props): JSX.Element => {
  const [displayArchiveModal, setArchiveModalStatus] = React.useState(false);
  const [displayRestoreModal, setRestoreModalStatus] = React.useState(false);

  const onArchiveButtonClick = React.useCallback(() => {
    setArchiveModalStatus(true);
  }, []);

  const onRestoreButtonClick = React.useCallback(() => {
    setRestoreModalStatus(true);
  }, []);

  const onArchiveModalConfirm = React.useCallback(() => {
    onArchiveConfirm();
    setArchiveModalStatus(false);
  }, []);

  const onRestoreModalConfirm = React.useCallback(() => {
    onRestoreConfirm();
    setRestoreModalStatus(false);
  }, []);

  const onModalClose = React.useCallback(() => {
    setArchiveModalStatus(false);
    setRestoreModalStatus(false);
  }, []);

  return (
    <div className={style.root}>
      <div className={style.title}>{titleByStatus[status]}</div>

      {status === "active" && (
        <div
          className={classNames(style.button, style.archiveButton)}
          onClick={onArchiveButtonClick}
        >
          В архив
        </div>
      )}

      {status === "archived" && (
        <div
          className={classNames(style.button, style.restoreButton)}
          onClick={onRestoreButtonClick}
        >
          Вернуть
        </div>
      )}

      {displayArchiveModal && (
        <ConfirmModal
          title={"Внимание!"}
          message={
            <>
              Контракт <b>{name}</b> будет перенесен в архив
            </>
          }
          confirmButtonTitle={"В архив"}
          closeButtonTitle={"Отменить"}
          onConfirm={onArchiveModalConfirm}
          onClose={onModalClose}
        />
      )}

      {displayRestoreModal && (
        <ConfirmModal
          title={"Внимание!"}
          message={
            <>
              Контракт <b>{name}</b> будет восстановлен
            </>
          }
          confirmButtonTitle={"Восстановить"}
          closeButtonTitle={"Отменить"}
          onConfirm={onRestoreModalConfirm}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};
