import * as lodash from "lodash";

import {
  ColumnName,
  ColumnHeaderType,
  ColumnType,
} from "../../../store/budgetAccessPage/types";
import type {
  BudgetAccessLine,
  BudgetLine,
  Department,
  Organization,
  RoleDomain,
  Action,
  BudgetAccessGroup,
} from "../../../api";

import { TextColumnHeader, FiltersColumnHeader } from "./ColumnHeaderTypes";
import {
  SelectCell,
  SelectCellEdit,
  CheckboxListCell,
  CheckboxListCellEdit,
  RightGroupsCell,
  RightGroupsCellEdit,
  TextCell,
  ActionsCell,
} from "./CellTypes";
import { LineId } from "./types";

export interface AccessorParams {
  lineId: LineId;
  budgetAccessLine: BudgetAccessLine;
  allBudgetAccessLines: BudgetAccessLine[];
  newLineFields: { [columnName: string]: any };
  organizations: Organization[];
  departments: Department[];
  budgets: BudgetLine[];
  roles: RoleDomain[];
  actions: Action[];
}

export interface ColumnParams {
  title: string;
  headerType: ColumnHeaderType;
  type: ColumnType;
  defaultWidth: number;
  readOnly?: boolean;
  disableSorting?: boolean;
  linkedColumns?: ColumnName[];
  getSortingValue?: (params: AccessorParams) => any;
  getValue?: (params: AccessorParams) => any;
  setValue?: (params: AccessorParams, value: any) => Promise<void>;
  getItems?: (
    params: AccessorParams
  ) => { title: React.ReactText; value: any }[];
  gelAllItems?: (
    params: AccessorParams
  ) => { title: React.ReactText; value: any }[];
}

export const ColumnHeaderComponentsByType: Record<
  ColumnHeaderType,
  React.ClassType<any, any, any>
> = {
  [ColumnHeaderType.Text]: TextColumnHeader,
  [ColumnHeaderType.Filters]: FiltersColumnHeader,
};

export const CellComponentsByColumnType: Record<
  ColumnType,
  {
    cell: React.ClassType<any, any, any>;
    editCell?: React.ClassType<any, any, any>;
  }
> = {
  [ColumnType.Text]: {
    cell: TextCell,
  },
  [ColumnType.Select]: {
    cell: SelectCell,
    editCell: SelectCellEdit,
  },
  [ColumnType.CheckboxList]: {
    cell: CheckboxListCell,
    editCell: CheckboxListCellEdit,
  },
  [ColumnType.RightGroups]: {
    cell: RightGroupsCell,
    editCell: RightGroupsCellEdit,
  },
  [ColumnType.Actions]: {
    cell: ActionsCell,
  },
};

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
  [ColumnName.Budget]: {
    title: "Бюджет",
    headerType: ColumnHeaderType.Filters,
    type: ColumnType.CheckboxList,
    defaultWidth: 275,
    disableSorting: true,
    getValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.budgets.map((item) => item.id),
    setValue: async (params: AccessorParams, value: any) => {
      await params.budgetAccessLine.model.setBudgets({ budgetIds: value });
    },
    getItems: (params: AccessorParams) =>
      params.budgets.map((item) => {
        let budget: string;
        switch (item.model.status) {
          case "plan":
          case "archive_plan":
            budget = "Планирование";
            break;
          case "execution":
          case "archive_execution":
            budget = "Исполнение";
            break;
          default:
            budget = item.model.status;
            break;
        }
        let status: string;
        switch (item.model.status) {
          case "archive_plan":
          case "archive_execution":
            status = "архив";
            break;
          default:
            break;
        }

        return {
          title: `${budget} ${item.model.year}${status ? ` (${status})` : ""}`,
          value: item.model.id,
        };
      }),
  },
  [ColumnName.Organization]: {
    title: "Организация",
    headerType: ColumnHeaderType.Filters,
    type: ColumnType.Select,
    defaultWidth: 275,
    getSortingValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.organization?.name,
    getValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.organization?.id,
    setValue: async (params: AccessorParams, value: any) => {
      await params.budgetAccessLine.model.setOrganization({
        organizationId: value,
      });
    },
    getItems: (params: AccessorParams) =>
      params.organizations.map((item) => ({
        title: item.name,
        value: item.id,
      })),
    linkedColumns: [ColumnName.Department],
  },
  [ColumnName.Department]: {
    title: "Департамент",
    headerType: ColumnHeaderType.Filters,
    type: ColumnType.CheckboxList,
    defaultWidth: 275,
    disableSorting: true,
    getValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.departments.map((item) => item.id),
    setValue: async (params: AccessorParams, value: any) => {
      await params.budgetAccessLine.model.setDepartments({
        departmentIds: value,
      });
    },
    getItems: (params: AccessorParams) => {
      const lineIsNew = params.lineId === "new";

      const organizationId = lineIsNew
        ? params.newLineFields[ColumnName.Organization]
        : params.budgetAccessLine?.model?.organization?.id;

      if (!organizationId) {
        return [];
      }

      const filteredDepartments = params.departments.filter(
        (item) => item.organizationId === organizationId
      );
      const sortedDepartments = lodash.sortBy(filteredDepartments, (item) =>
        item.name.trim()
      );

      return sortedDepartments.map((item) => ({
        title: item.name,
        value: item.id,
      }));
    },
    gelAllItems: (params: AccessorParams) => {
      const sortedDepartments = lodash.sortBy(params.departments, (item) =>
        item.name.trim()
      );

      return sortedDepartments.map((item) => ({
        title: item.name,
        value: item.id,
      }));
    },
  },
  [ColumnName.Role]: {
    title: "Роль",
    headerType: ColumnHeaderType.Filters,
    type: ColumnType.Select,
    defaultWidth: 275,
    getSortingValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.role?.name,
    getValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.role?.id,
    setValue: async (params: AccessorParams, value: any) => {
      await params.budgetAccessLine.model.setRole({ roleId: value });
    },
    getItems: (params: AccessorParams) =>
      params.roles.map((item) => ({
        title: item.model.name,
        value: item.model.id,
      })),
  },
  [ColumnName.RightGroup]: {
    title: "Группа прав",
    headerType: ColumnHeaderType.Text,
    type: ColumnType.RightGroups,
    defaultWidth: 275,
    getValue: (params: AccessorParams) =>
      params.budgetAccessLine.model.accessGroups,
    setValue: async (params: AccessorParams, value: BudgetAccessGroup[]) => {
      const { actions, budgetAccessLine } = params;

      const accessGroups: BudgetAccessGroup[] = value.map((accessGroup) => {
        const actionIds = accessGroup.actionIds.filter((actionId) => {
          const actionParentId = actions.find(
            (action) => action.id === actionId
          )?.parentId;

          return (
            !actionParentId || accessGroup.actionIds.includes(actionParentId)
          );
        });

        return {
          ...accessGroup,
          actionIds,
        };
      });

      await budgetAccessLine.model.setAccessGroups({ accessGroups });
    },
  },
  [ColumnName.Actions]: {
    title: "Действие",
    headerType: ColumnHeaderType.Text,
    type: ColumnType.Actions,
    defaultWidth: 160,
    readOnly: true,
  },
};

export const tableColumns = [
  ColumnName.Budget,
  ColumnName.Organization,
  ColumnName.Department,
  ColumnName.Role,
  ColumnName.RightGroup,
  ColumnName.Actions,
];

export const leftFixedColumns: ColumnName[] = [];

export const rightFixedColumns: ColumnName[] = [];

export const readOnlyColumns = [
  ...tableColumns,
  ...leftFixedColumns,
  ...rightFixedColumns,
].filter((item) => ColumnsConfig[item].readOnly);
