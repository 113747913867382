import { axios } from "../lib/axios";

const BASE_URL = "/api/dictionary-data-schema";

export class DictionaryDataSchemaApi {
  public static async getSchema(params: any): Promise<any> {
    const res = await axios.get(`${BASE_URL}`, { params });

    return res.data;
  }

  public static async createNewSchema(params: any): Promise<void> {
    await axios.post(`${BASE_URL}`, { ...params });
  }
}
