import * as React from "react";
import * as classNames from "classnames";
import {
  Icon,
  IconType,
  Toggle,
  TogglePosition,
  ToggleThemes,
  WithTooltip,
} from "@sm/ui";

import * as style from "./Block.scss";

import { FieldParams, BlockParams } from "../../../../../store/brief/types";
import { AddFieldTarget } from "../../ElementsList/AddFieldTarget";
import { AddBlockTarget } from "../../ElementsList/AddBlockTarget";
import { ParentBlockTarget } from "../../ElementsList/ParentBlockTarget";
import { Field } from "../Fields";
import { BlockContainer } from "./BlockContainer";
import { BlockTitleDrag } from "./BlockTitleDrag";

import { BLOCK_PARENT_DRAG } from "../../../ItemType";

interface Props {
  id: string;
  name: string;
  tooltipMessage?: string;
  order: number;
  parent: string;
  parents: string[];
  isVisibleFields: boolean;
  isSelected: boolean;
  isNotRequired: boolean;
  isToggleButtonDisabled: boolean;
  fields: FieldParams[];
  fieldsLength?: number;
  blocks: BlockParams[];
  canRemove?: boolean;
  removeField: (fieldId: string) => Promise<void>;
  onToggleSelect: () => void;
  onToggleButtonClick: () => void;
  onBlockRemove: () => void;
  onBlockRemoveClick: (blockId: string) => void;
}

export function Block({
  id,
  name,
  tooltipMessage,
  order,
  parent,
  parents,
  isSelected,
  isVisibleFields,
  isNotRequired,
  isToggleButtonDisabled,
  fields,
  fieldsLength = 0,
  blocks,
  canRemove,
  removeField,
  onToggleSelect,
  onToggleButtonClick,
  onBlockRemove,
  onBlockRemoveClick,
}: Props): JSX.Element {
  const hasTooltip = !!tooltipMessage;
  const isCanRemove = !blocks.length && canRemove;

  return (
    <div className={classNames(style.root, isSelected && style.onEdit)}>
      <div className={style.title} onClick={onToggleSelect}>
        {isNotRequired && (
          <div className={style.toggle}>
            <Toggle
              onClick={onToggleButtonClick}
              position={
                isVisibleFields ? TogglePosition.RIGHT : TogglePosition.LEFT
              }
              theme={ToggleThemes.GREY_AND_GREEN_LARGE}
              disabled={isToggleButtonDisabled}
            />
          </div>
        )}

        <BlockTitleDrag
          itemType={BLOCK_PARENT_DRAG}
          id={id}
          parent={parent}
          order={order}
          content={name || "Новый блок"}
        />

        {!isToggleButtonDisabled && hasTooltip && (
          <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
            <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
          </WithTooltip>
        )}

        {isCanRemove && isSelected && (
          <div className={style.iconWrapper} onClick={onBlockRemove}>
            <Icon type={IconType.CROSS_BRIEF} />
          </div>
        )}
      </div>

      <AddFieldTarget blockId={id} order={1} />

      {isVisibleFields && (
        <div className={style.fields}>
          {fields.map((item) => (
            <React.Fragment key={item.id}>
              <div className={style.field}>
                <Field
                  id={item.id}
                  blockId={id}
                  order={item.order}
                  fieldType={item.type}
                  onRemove={removeField}
                />
              </div>
              <AddFieldTarget blockId={id} order={item.order + 1} />
            </React.Fragment>
          ))}
        </div>
      )}

      <div className={style.blocks}>
        <AddBlockTarget parent={id} order={1} />
        <ParentBlockTarget parent={id} parents={parents} order={1} />
        {blocks.map((item, index) => (
          <div
            className={classNames("section", `section ${item.id}`)}
            key={item.id}
          >
            <BlockContainer
              id={item.id}
              parents={[...parents, id]}
              onBlockRemoveClick={onBlockRemoveClick}
            />
            <AddBlockTarget parent={id} order={index + 2} />
            <ParentBlockTarget
              parent={id}
              id={item.id}
              parents={parents}
              order={index + 2}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
