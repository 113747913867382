// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-common-CustomFirstLine-_CustomFirstLine__rightContent{width:100%;height:48px}\n", "",{"version":3,"sources":["webpack://./src/modules/common/CustomFirstLine/CustomFirstLine.scss"],"names":[],"mappings":"AAAA,mEACI,UAAW,CACX,WAAY","sourcesContent":[".rightContent {\n    width: 100%;\n    height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightContent": "src-modules-common-CustomFirstLine-_CustomFirstLine__rightContent"
};
export default ___CSS_LOADER_EXPORT___;
