import { CreativeRequestContract } from "@api";
import { IconType } from "sber-marketing-ui";

export const enum ColumnHeaderType {
  Text = "text",
  Filters = "filters",
}

export const enum LineType {
  Line = "line",
}

export const enum ColumnName {
  Name = "name",
  Lot = "lot",
  StartDate = "startDate",
  EndDate = "endDate",
  CommissionRate = "commissionRate",
  CommissionLimit = "commissionLimit",
  Status = "status",
}

export const enum CellType {
  LineHeader = "lineHeader",
  Text = "text",
  Input = "input",
  Textarea = "textarea",
  FundsInput = "funds_input",
  Select = "select",
  FundsSelect = "fundsSelect",
  Datepicker = "datepicker",
  Status = "status",
}

export interface AccessorParams {
  id: string;
  line: CreativeRequestContract;
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type ValidationAccessor = AccessorWithValue<boolean>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type IconAccessor = Accessor<{
  type: IconType;
  color: string;
  size: number;
}>;

export interface ColumnParams {
  title: string;
  headerType: ColumnHeaderType;
  type: Record<LineType, CellType>;
  disableFilters?: Partial<Record<LineType, boolean>>;
  defaultWidth: number;
  disableWidthChange?: boolean;
  readOnly?: Partial<Record<LineType, boolean>>;
  customStyle?:
    | Partial<Record<LineType, CustomStyleAccessor>>
    | CustomStyleAccessor;
  linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
  getTitle?: Partial<Record<LineType, TitleAccessor>>;
  getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
  setValue?: Partial<Record<LineType, ValueSetter>>;
  getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
  getSuggestItems?:
    | Partial<Record<LineType, SuggestItemsAccessor>>
    | SuggestItemsAccessor;
  getDescription?:
    | Partial<Record<LineType, DescriptionAccessor>>
    | DescriptionAccessor;
  getIcon?: Partial<Record<LineType, IconAccessor>> | IconAccessor;
}

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
  [ColumnName.Name]: {
    title: "Название контракта",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Input,
    },
    defaultWidth: 400,
    disableWidthChange: true,
    getValue: {
      [LineType.Line]: async (params: AccessorParams) => params.line.model.name,
    },
    setValue: {
      [LineType.Line]: async (params: AccessorParams, value: string) => {
        await params.line.model.setName({ name: value });
      },
    },
  },
  [ColumnName.Lot]: {
    title: "ЛОТ",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Text,
    },
    defaultWidth: 100,
    disableWidthChange: true,
    readOnly: {
      [LineType.Line]: true,
    },
    getValue: {
      [LineType.Line]: async (params: AccessorParams) => {
        const lot = await params.line.model.lot;

        return lot.value;
      },
    },
  },
  [ColumnName.StartDate]: {
    title: "Дата начала контракта",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Datepicker,
    },
    defaultWidth: 150,
    disableWidthChange: true,
    getValue: {
      [LineType.Line]: async (params: AccessorParams) =>
        params.line.model.startDate,
    },
    setValue: {
      [LineType.Line]: async (params: AccessorParams, value: any) => {
        await params.line.model.setStartDate({ startDate: value });
      },
    },
  },
  [ColumnName.EndDate]: {
    title: "Дата окончания контракта",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Datepicker,
    },
    defaultWidth: 150,
    disableWidthChange: true,
    getValue: {
      [LineType.Line]: async (params: AccessorParams) =>
        params.line.model.endDate,
    },
    setValue: {
      [LineType.Line]: async (params: AccessorParams, value: any) => {
        await params.line.model.setEndDate({ endDate: value });
      },
    },
  },
  [ColumnName.CommissionRate]: {
    title: "Ставка АК, %",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Input,
    },
    defaultWidth: 150,
    disableWidthChange: true,
    getValue: {
      [LineType.Line]: async (params: AccessorParams) =>
        params.line.model.agencyCommissionRate,
    },
    setValue: {
      [LineType.Line]: async (params: AccessorParams, value: any) => {
        await params.line.model.setAgencyCommissionRate({
          agencyCommissionRate: value,
        });
      },
    },
  },
  [ColumnName.CommissionLimit]: {
    title: "Лимит АК, руб.",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.FundsInput,
    },
    defaultWidth: 150,
    disableWidthChange: true,
    getValue: {
      [LineType.Line]: async (params: AccessorParams) =>
        formatMoney(params.line.model.agencyCommissionLimit),
    },
    setValue: {
      [LineType.Line]: async (params: AccessorParams, value: any) => {
        await params.line.model.setAgencyCommissionLimit({
          agencyCommissionLimit: formatMoneyInput(value),
        });
      },
    },
  },
  [ColumnName.Status]: {
    title: "Статус",
    headerType: ColumnHeaderType.Text,
    type: {
      [LineType.Line]: CellType.Status,
    },
    defaultWidth: 210,
    disableWidthChange: true,
    readOnly: {
      [LineType.Line]: true,
    },
    getValue: {
      [LineType.Line]: async (params: AccessorParams) => {
        const { name, status } = params.line.model;

        return { name, status };
      },
    },
  },
};

export const leftFixedColumns: ColumnName[] = [];
export const rightFixedColumns: ColumnName[] = [ColumnName.Status];

export const tableColumns: ColumnName[] = [
  ColumnName.Name,
  ColumnName.Lot,
  ColumnName.StartDate,
  ColumnName.EndDate,
  ColumnName.CommissionRate,
  ColumnName.CommissionLimit,
  // ColumnName.Status,
];

function formatMoney(value: React.ReactText): number {
  if (!value) {
    return 0;
  }

  const parsedValue = typeof value === "string" ? parseInt(value, 10) : value;

  return parsedValue / 100;
}

function formatMoneyInput(value: React.ReactText): number {
  if (!value) {
    return 0;
  }

  const parsedValue =
    typeof value === "string" ? parseFloat(value.replace(",", ".")) : value;

  return Math.round(parsedValue * 100);
}
