import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import type { RouteComponentProps } from "react-router";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { StoreState } from "../../store";
import type { BudgetLine, BudgetStatus } from "../../api";

import { BudgetsPage } from "./BudgetsPage";
import { loadBudgets, setBudgetsFilter } from "../../store/budgetsPage/actions";
import {
  getArchivedBudgetsFilter,
  getFilteredBudgets,
} from "../../store/budgetsPage/selectors";
import { MrmClient } from "../../api";

interface Props
  extends Partial<MapProps & DispatchProps & RouteComponentProps> {
  pageLabel?: string;
}

interface State {
  preloader: boolean;
  displayCreateBudgetModal: boolean;
}

interface MapProps {
  archiveFilterEnabled: boolean;
  budgets: BudgetLine[];
}

interface DispatchProps {
  loadBudgets: (budgets: BudgetLine[]) => void;
  setBudgetsFilter: (filterEnabled: boolean) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetsPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: false,
      displayCreateBudgetModal: false,
    };
  }

  public async componentDidMount() {
    await this.loadBudgets();
  }

  public render() {
    const { pageLabel, archiveFilterEnabled } = this.props;
    const { preloader, displayCreateBudgetModal } = this.state;

    return React.createElement(BudgetsPage, {
      preloader,
      pageLabel,
      archiveFilterEnabled,
      displayCreateBudgetModal,
      nextBudgetYear: this.makeNextBudgetYear(),
      onArchiveButtonClick: this.onArchiveButtonClick,
      onCreateBudgetButtonClick: this.onCreateBudgetButtonClick,
      onModalCloseButtonClick: this.onModalCloseButtonClick,
      onCreateBudgetConfirm: this.onCreateBudgetConfirm,
    });
  }

  @autobind
  protected onArchiveButtonClick() {
    this.props.setBudgetsFilter(!this.props.archiveFilterEnabled);
  }

  @autobind
  protected onCreateBudgetButtonClick() {
    this.setState({ displayCreateBudgetModal: true });
  }

  @autobind
  protected onModalCloseButtonClick() {
    this.setState({ displayCreateBudgetModal: false });
  }

  @autobind
  protected async onCreateBudgetConfirm() {
    await this.createBudget();
    await this.loadBudgets();
    this.setState({ displayCreateBudgetModal: false });
  }

  private async loadBudgets() {
    const client = await MrmClient.getInstance();

    const budgets = (await client.domain.budgets.listBudget()).filter(
      (budget) => budget.model.status !== "archive"
    );

    const sortedBudgets = lodash.sortBy(budgets, (item) => item.model.year);

    this.props.loadBudgets(sortedBudgets);
  }

  private async createBudget() {
    const client = await MrmClient.getInstance();

    const year = this.makeNextBudgetYear();

    await client.api.budgets.createBudget({ year });
  }

  private makeNextBudgetYear(): number {
    const latestBudget = lodash.maxBy(
      this.props.budgets,
      (item) => item.model.year
    );

    return latestBudget
      ? latestBudget.model.year + 1
      : new Date().getFullYear();
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    archiveFilterEnabled: getArchivedBudgetsFilter(state),
    budgets: getFilteredBudgets(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      loadBudgets,
      setBudgetsFilter,
    },
    dispatch
  );
}
