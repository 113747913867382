import * as React from "react";

import * as style from "./SelectCell.scss";

import { Icon, IconType } from "sber-marketing-ui";

interface Props {
  title: string;
}

export const SelectCellTemplate = ({ title }: Props): JSX.Element => {
  return (
    <div className={style.root}>
      <div className={style.title}>{title}</div>

      <div className={style.openerIcon}>
        <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
      </div>
    </div>
  );
};
