import * as React from "react";

import * as style from "./TopLine.scss";

import { Button_redesign, ButtonTheme_redesign } from "@sm/ui";
import { CustomFirstLine } from "../../common/CustomFirstLine";

interface Props {
  pageLabel: string;
  onNewButtonClick: () => void;
}

export const TopLine: React.SFC<Props> = ({
  pageLabel,
  onNewButtonClick,
}: Props): JSX.Element => (
  <CustomFirstLine
    pageLabel={pageLabel}
    additionalRightCssClass={style.buttons}
  >
    <div className={style.button}>
      <Button_redesign
        theme={ButtonTheme_redesign.Main}
        onClick={onNewButtonClick}
      >
        Новый вид работ
      </Button_redesign>
    </div>
  </CustomFirstLine>
);

TopLine.displayName = "TopLine";
