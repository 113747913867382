import { createSelector } from "reselect";
import {
  BriefState,
  BriefValidation,
  NameValidation,
  FieldValidation,
  SwitchValidation,
  ObjectDictionary,
} from "../types";
import { ItemType } from "../../../modules/BriefPage/ItemType";
import { StoreState } from "../../";
import { isNotNilAndLengthFromThree } from "../lib";
import { cloneDeep, uniq } from "lodash";

/** State getter */
const stateGetter = (state: StoreState): BriefState => state.brief;

/** Initial validation data */
const INITIAL_VALIDATION: BriefValidation = {
  brief: {
    isNameValid: true,
    nameValidationError: "",
  },
  blocks: {},
  switches: {},
  fields: {},
};

/** Returns full brief's validation data */
export const getBriefFullValidation = createSelector(
  stateGetter,
  (state: BriefState): BriefValidation => {
    const result = cloneDeep(INITIAL_VALIDATION);
    result.brief.isNameValid = isNotNilAndLengthFromThree(state.name);
    result.brief.nameValidationError =
      "Длина имени брифа должна быть не меньше трех символов";

    for (const { name, id, fields } of state.elements) {
      result.blocks[id] = {
        isNameValid: isNotNilAndLengthFromThree(name),
        nameValidationError: "Длина имени блока должна не меньше трех символов",
      };
      for (const {
        id,
        type,
        properties: { name, selectContent, switches },
      } of fields) {
        result.fields[id] = {
          isNameValid: isNotNilAndLengthFromThree(name),
          isPlaceholderValid: true, // isNilOrLengthFromThree(placeholder),
          isItemsGroupValid: true,
          itemsGroupValidationError: "",
          nameValidationError:
            "Длина имени поля должна не меньше трех символов",
          placeholderValidationError:
            "Длина плейсхолдера поля должна быть не меньше трех символов",
        };
        if (type === ItemType.DROPDOWN) {
          result.fields[id].isItemsGroupValid = selectContent.length > 0;
          result.fields[id].itemsGroupValidationError =
            "Количество элементов в дропдауне должно быть больше нуля";
        } else if (type === ItemType.SWITCH_GROUP) {
          result.fields[id].isItemsGroupValid = switches.length > 0;
          result.fields[id].itemsGroupValidationError =
            "Количество тогглов в группе тогглов должно быть больше нуля";
          for (const { id, name } of switches) {
            // name, placeholder, isHasTextField } of switches) {
            result.switches[id] = {
              isNameValid: isNotNilAndLengthFromThree(name),
              isPlaceholderValid: true,
              nameValidationError:
                "Длина имени тоггла должна не меньше трех символов",
              placeholderValidationError:
                "Длина плейсхолдера тоггла должна не меньше трех символов",
            };
          }
        }
      }
    }

    return result;
  }
);

/** Returns brief validation data */
export const getBriefValidation = (state: StoreState): NameValidation =>
  getBriefFullValidation(state).brief;

/** Returns block validation data */
export const getBlockValidation = (
  state: StoreState,
  id: string
): NameValidation => getBriefFullValidation(state).blocks[id];

/** Returns field validation data */
export const getFieldValidation = (
  state: StoreState,
  id: string
): FieldValidation => getBriefFullValidation(state).fields[id];

/** Returns switch validation data */
export const getSwitchValidation = (
  state: StoreState,
  id: string
): SwitchValidation => getBriefFullValidation(state).switches[id];

/** Returns switch validation data mapping for concrete field */
export const getSwitchValidationsForField = (
  state: StoreState,
  fieldId: string
): ObjectDictionary<SwitchValidation> => {
  const result: ObjectDictionary<SwitchValidation> = {};
  let switchIds: string[] = [];

  for (const block of state.brief.elements) {
    for (const {
      id,
      type,
      properties: { switches },
    } of block.fields) {
      if (id === fieldId && type === ItemType.SWITCH_GROUP) {
        switchIds = switchIds.concat(switches.map(({ id }) => id));
      }
    }
  }
  switchIds = uniq(switchIds);

  for (const switchId of switchIds) {
    result[switchId] = getSwitchValidation(state, switchId);
  }

  return result;
};

/** Returns number of validation errors */
export const getCountBriefValidationErrors = (state: StoreState): number => {
  const fullValidation: BriefValidation = getBriefFullValidation(state);
  let countErrors = 0;

  if (!fullValidation.brief.isNameValid) {
    countErrors++;
  }

  for (const blockId of Object.keys(fullValidation.blocks)) {
    if (!fullValidation.blocks[blockId].isNameValid) {
      countErrors++;
    }
  }

  for (const fieldId of Object.keys(fullValidation.fields)) {
    if (!fullValidation.fields[fieldId].isNameValid) {
      countErrors++;
    }
    if (!fullValidation.fields[fieldId].isPlaceholderValid) {
      countErrors++;
    }
    if (!fullValidation.fields[fieldId].isItemsGroupValid) {
      countErrors++;
    }
  }

  for (const switchId of Object.keys(fullValidation.switches)) {
    if (!fullValidation.switches[switchId].isNameValid) {
      countErrors++;
    }
    if (!fullValidation.switches[switchId].isPlaceholderValid) {
      countErrors++;
    }
  }

  return countErrors;
};

/** Returns "true" if brief is completely valid */
export const isBriefValid = (state: StoreState): boolean =>
  getCountBriefValidationErrors(state) <= 0;
