import * as React from "react";
import { PlainDictionary } from "@sm/types/budget";

import * as style from "./ActivityTypeTable.scss";

import { ActivityTypeRow } from "../ActivityTypeRow";

interface Props {
  activityTypes: PlainDictionary[];
}

export function ActivityTypeTable({ activityTypes }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.titleRow}>
        <div className={style.nameColumn}>Название</div>

        <div className={style.propertiesColumn}>Свойства</div>
      </div>

      {activityTypes.map((activityType) => (
        <ActivityTypeRow key={activityType.id} activityType={activityType} />
      ))}
    </div>
  );
}
