import { reducerWithInitialState } from "typescript-fsa-reducers";
import { User, UserState } from "../types";
import { loadUser } from "../actions";

const initialState: UserState = null;

function load(state: UserState, payload: User) {
  return { ...payload };
}

export const userReducer = reducerWithInitialState(initialState).case(
  loadUser,
  load
);
