import {
  BriefSchemeListPageLoadParams,
  BriefSchemeListPageSetFilterParams,
  BriefSchemeListPageState,
  UpdateSchemeParams,
} from "../types";
import { Utils } from "../../../modules/common/Utils";

export class BriefSchemeListReducer {
  public static load(
    state: BriefSchemeListPageState,
    payload: BriefSchemeListPageLoadParams
  ) {
    return {
      ...state,
      ...payload,
    };
  }

  public static setFilter(
    state: BriefSchemeListPageState,
    payload: BriefSchemeListPageSetFilterParams
  ): BriefSchemeListPageState {
    return {
      ...state,
      selectedFilter: payload.selectedFilter,
    };
  }

  public static removeScheme(state: BriefSchemeListPageState, id: string) {
    const briefSchemes = Utils.removeItemById(state.briefSchemes, id);

    return {
      ...state,
      briefSchemes,
    };
  }

  public static updateScheme(
    state: BriefSchemeListPageState,
    { id, ...params }: UpdateSchemeParams
  ) {
    const briefSchemes = Utils.updateItemById(state.briefSchemes, id, params);

    return {
      ...state,
      briefSchemes,
    };
  }
}
