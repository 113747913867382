import * as React from "react";
import * as classNames from "classnames";

import * as style from "./UnlockableInput.scss";

import { ElementPropertiesParams } from "../../../../../../store/brief/types";

import { LabeledInput, InputType, Icon, IconType, WithTooltip } from "@sm/ui";

export interface UnlockableInputProps extends ElementPropertiesParams {
  isSelected: boolean;
  onToggleSelect: React.MouseEventHandler<HTMLDivElement>;
  onRemoveClick: () => void;
  isPreviewModeEnabled?: boolean;
}

export function UnlockableInput({
  isSelected,
  name,
  tooltipMessage,
  onToggleSelect,
  onRemoveClick,
  isPreviewModeEnabled,
  canRemove,
}: UnlockableInputProps): JSX.Element {
  const hasTooltip = !!tooltipMessage;

  return (
    <div
      className={classNames(
        style.root,
        isSelected && style.onEdit,
        isPreviewModeEnabled && style.previewMode
      )}
      onClick={onToggleSelect}
    >
      <LabeledInput
        value={null}
        title={name || "Новое поле"}
        type={InputType.Unlockable}
        readOnly={!isPreviewModeEnabled}
      />

      {isPreviewModeEnabled && hasTooltip && (
        <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
          <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
        </WithTooltip>
      )}

      {canRemove && isSelected && (
        <div className={style.icon} onClick={onRemoveClick}>
          <Icon type={IconType.CROSS_BRIEF} />
        </div>
      )}
    </div>
  );
}
