import autobind from "autobind-decorator";
import * as lodash from "lodash";
import * as moment from "moment";

import { TableBodyCellParams, CellPosition, LineId } from "../../types";
import {
  ValueAccessor,
  TitleAccessor,
  ValueSetter,
  ItemsAccessor,
  SuggestItemsAccessor,
  DescriptionAccessor,
  CustomStyleAccessor,
  IconAccessor,
  AccessorParams,
  ColumnsConfig,
  CellType,
  LineType,
} from "../../ColumnsConfig";
import { CreativeRequestContract } from "@api";
import { IconType } from "sber-marketing-ui";

import {
  DatepickerCell,
  DatepickerCellEdit,
  FundsInputCell,
  FundsInputCellEdit,
  FundsSelectCell,
  FundsSelectCellEdit,
  InputCell,
  InputCellEdit,
  LineHeader,
  SelectCell,
  SelectCellEdit,
  StatusCell,
  TextareaCell,
  TextareaCellEdit,
  TextCell,
} from "../../CellTypes";

export const CellComponentsByColumnType: Record<
  CellType,
  {
    cell: React.ClassType<any, any, any>;
    editCell?: React.ClassType<any, any, any>;
  }
> = {
  [CellType.LineHeader]: {
    cell: LineHeader,
  },
  [CellType.Text]: {
    cell: TextCell,
  },
  [CellType.Input]: {
    cell: InputCell,
    editCell: InputCellEdit,
  },
  [CellType.Textarea]: {
    cell: TextareaCell,
    editCell: TextareaCellEdit,
  },
  [CellType.FundsInput]: {
    cell: FundsInputCell,
    editCell: FundsInputCellEdit,
  },
  [CellType.Select]: {
    cell: SelectCell,
    editCell: SelectCellEdit,
  },
  [CellType.FundsSelect]: {
    cell: FundsSelectCell,
    editCell: FundsSelectCellEdit,
  },
  [CellType.Datepicker]: {
    cell: DatepickerCell,
    editCell: DatepickerCellEdit,
  },
  [CellType.Status]: {
    cell: StatusCell,
  },
};

interface Props {
  getLine: (lineId: LineId) => CreativeRequestContract;
  onLineArchive: (lineId: LineId) => void;
  onLineRestore: (lineId: LineId) => void;
  onCellClose: () => void;
}

export class CellsFactory {
  private getLine: (lineId: LineId) => CreativeRequestContract;
  private onLineArchive: (lineId: LineId) => void;
  private onLineRestore: (lineId: LineId) => void;
  private onCellClose: () => void;

  public constructor(props: Props) {
    this.getLine = props.getLine;
    this.onLineArchive = props.onLineArchive;
    this.onLineRestore = props.onLineRestore;
    this.onCellClose = props.onCellClose;
  }

  @autobind
  public async makeCellParams(
    cellPosition: CellPosition,
    edit = false
  ): Promise<TableBodyCellParams> {
    const value = await this.getCellValue(cellPosition);

    return {
      component:
        value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
      cellProps:
        value !== undefined
          ? await this.makeCellProps(cellPosition, edit)
          : null,
      readOnly:
        value !== undefined
          ? await this.checkReadOnlyStatus(cellPosition)
          : true,
      сellBackgroundColor: this.getCellBackgroundColor(cellPosition),
      preventCloseOnClick: this.checkPreventCloseOnClickStatus(
        cellPosition,
        edit
      ),
    };
  }

  @autobind
  public getCellComponent(
    cellPosition: CellPosition,
    edit?: boolean
  ): React.ClassType<any, any, any> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const columnType = lodash.get(ColumnsConfig, [
      columnName,
      "type",
      lineType,
    ]);

    if (!columnType) {
      return null;
    }

    return edit
      ? CellComponentsByColumnType[columnType].editCell
      : CellComponentsByColumnType[columnType].cell;
  }

  @autobind
  public async makeCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const columnType = lodash.get(ColumnsConfig, [
      columnName,
      "type",
      lineType,
    ]);

    if (!columnType) {
      return null;
    }

    let cellProps: any;

    switch (columnType) {
      case CellType.LineHeader:
        cellProps = await this.makeLineHeaderProps(cellPosition);
        break;

      case CellType.Text:
        cellProps = await this.makeTextCellProps(cellPosition);
        break;

      case CellType.Input:
        cellProps = await this.makeInputCellProps(cellPosition, edit);
        break;

      case CellType.Textarea:
        cellProps = await this.makeTextareaCellProps(cellPosition, edit);
        break;

      case CellType.FundsInput:
        cellProps = await this.makeMoneyInputCellProps(cellPosition, edit);
        break;

      case CellType.Datepicker:
        cellProps = await this.makeDatepickerCellProps(cellPosition, edit);
        break;

      case CellType.Select:
        cellProps = await this.makeSelectCellProps(cellPosition, edit);
        break;

      case CellType.FundsSelect:
        cellProps = await this.makeFundsSelectCellProps(cellPosition, edit);
        break;

      case CellType.Status:
        cellProps = await this.makeStatusCellProps(cellPosition, edit);
        break;
    }

    cellProps = await this.applyCustomStyles(cellPosition, cellProps);

    return cellProps;
  }

  @autobind
  public async checkReadOnlyStatus(
    cellPosition: CellPosition
  ): Promise<boolean> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const cellComponent = this.getCellComponent(cellPosition, true);
    const isArchived = this.checkLineArchivedStatus(lineId);

    if (!cellComponent || isArchived) {
      return true;
    }

    return (
      lodash.get(ColumnsConfig, [columnName, "readOnly", lineType]) || false
    );
  }

  @autobind
  public async getCellValue(cellPosition: CellPosition): Promise<any> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const accessorField = lodash.get(ColumnsConfig, [columnName, "getValue"]);

    const accessor: ValueAccessor = lodash.isFunction(accessorField)
      ? accessorField
      : accessorField[lineType];

    if (!accessor) {
      return undefined;
    }

    const params = this.makeAccessorParams(cellPosition);

    return accessor(params);
  }

  private getCellBackgroundColor(cellPosition: CellPosition): string {
    return "#ffffff";
  }

  private checkPreventCloseOnClickStatus(
    cellPosition: CellPosition,
    edit: boolean
  ): boolean {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const columnType = lodash.get(ColumnsConfig, [
      columnName,
      "type",
      lineType,
    ]);

    if (!columnType) {
      return false;
    }

    return (
      edit &&
      [
        CellType.Input,
        CellType.Textarea,
        CellType.FundsInput,
        CellType.Datepicker,
      ].includes(columnType)
    );
  }

  private async makeLineHeaderProps(cellPosition: CellPosition): Promise<any> {
    const { lineId } = cellPosition;

    const lineType = this.getLineType(lineId);
    const isArchived = this.checkLineArchivedStatus(lineId);

    let lineCanBeRestored = true;

    return {
      title: (await this.getCellValue(cellPosition)) || "—",
      lineType,
      lineIsArchived: isArchived,
      lineCanBeRestored,
      onLineArchive: () => this.onLineArchive(lineId),
      onLineRestore: () => this.onLineRestore(lineId),
    };
  }

  private async makeTextCellProps(cellPosition: CellPosition): Promise<any> {
    const value = await this.getCellValue(cellPosition);
    const description = await this.getCellDescription(cellPosition);

    return {
      title: value || "—",
      description,
    };
  }

  private async makeInputCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const value = await this.getCellValue(cellPosition);

    return edit
      ? {
          title: value || "",
          placeholder: "",
          preventCloseOnClick: true,
          suggestItems: await this.getCellSuggestItems(cellPosition),
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title: value || "—",
        };
  }

  private async makeTextareaCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const value = await this.getCellValue(cellPosition);

    return edit
      ? {
          title: value || "",
          placeholder: "",
          preventCloseOnClick: true,
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title: value || "—",
        };
  }

  private async makeMoneyInputCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const value = await this.getCellValue(cellPosition);
    const description = await this.getCellDescription(cellPosition);

    return edit
      ? {
          title: value,
          placeholder: "",
          icon: await this.getCellIcon(cellPosition),
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title: this.formatCurrencyValue(this.roundNumber(value)) || "—",
          description,
          icon: await this.getCellIcon(cellPosition),
        };
  }

  private async makeDatepickerCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const cellValue = await this.getCellValue(cellPosition);

    const momentValue = cellValue ? moment(cellValue) : null;

    return edit
      ? {
          title: momentValue ? momentValue.format("DD.MM.YY") : "—",
          value: momentValue,
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title: cellValue ? moment(cellValue).format("DD.MM.YY") : "—",
        };
  }

  private async makeSelectCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const value = await this.getCellValue(cellPosition);
    const items = await this.getCellItems(cellPosition);
    const description = await this.getCellDescription(cellPosition);

    return edit
      ? {
          title:
            value !== null
              ? items.find((item) => item.value === value)?.title
              : `—`,
          description,
          items,
          selectedValue: value,
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title:
            value !== null
              ? items.find((item) => item.value === value)?.title
              : `—`,
          description,
        };
  }

  private async makeFundsSelectCellProps(
    cellPosition: CellPosition,
    edit: boolean
  ): Promise<any> {
    const value = await this.getCellValue(cellPosition);
    const items = await this.getCellItems(cellPosition);
    let title = await this.getCellTitle(cellPosition);
    const description = await this.getCellDescription(cellPosition);

    if (title === undefined) {
      title =
        value !== null
          ? items.find((item) => item.value === value)?.title
          : `—`;
    } else {
      title =
        this.formatCurrencyValue(this.roundNumber(title as number, 2)) || "—";
    }

    return edit
      ? {
          title,
          description,
          items,
          selectedValue: value,
          onValueChange: this.makeValueChangeHandler(cellPosition),
        }
      : {
          title,
          description,
        };
  }

  private async makeStatusCellProps(cellPosition: CellPosition): Promise<any> {
    const { lineId } = cellPosition;

    const value: { name: string; status: "active" | "archived" } =
      await this.getCellValue(cellPosition);

    const { name, status } = value;

    return {
      name,
      status,
      onArchiveConfirm: () => this.onLineArchive(lineId),
      onRestoreConfirm: () => this.onLineRestore(lineId),
    };
  }

  @autobind
  private async getCellItems(
    cellPosition: CellPosition
  ): Promise<{ title: string; value: any }[]> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const itemsAccessorField = lodash.get(ColumnsConfig, [
      columnName,
      "getItems",
    ]);

    const itemsAccessor: ItemsAccessor = lodash.isFunction(itemsAccessorField)
      ? itemsAccessorField
      : itemsAccessorField[lineType];

    if (!itemsAccessor) {
      return undefined;
    }

    const params = this.makeAccessorParams(cellPosition);

    return itemsAccessor(params);
  }

  private async getCellTitle(
    cellPosition: CellPosition
  ): Promise<React.ReactText> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const titleAccessorField = lodash.get(ColumnsConfig, [
      columnName,
      "getTitle",
    ]);

    if (!titleAccessorField) {
      return undefined;
    }

    const titleAccessor: TitleAccessor = lodash.isFunction(titleAccessorField)
      ? titleAccessorField
      : titleAccessorField[lineType];

    const params = this.makeAccessorParams(cellPosition);

    return titleAccessor(params);
  }

  private async getCellSuggestItems(
    cellPosition: CellPosition
  ): Promise<any[]> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const itemsAccessor: SuggestItemsAccessor = lodash.get(ColumnsConfig, [
      columnName,
      "getSuggestItems",
      lineType,
    ]);

    if (!itemsAccessor) {
      return [];
    }

    const params = this.makeAccessorParams(cellPosition);

    return itemsAccessor(params);
  }

  private async getCellDescription(
    cellPosition: CellPosition
  ): Promise<string> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const descriptionAccessorField = lodash.get(ColumnsConfig, [
      columnName,
      "getDescription",
    ]);

    if (!descriptionAccessorField) {
      return undefined;
    }

    const descriptionAccessor: DescriptionAccessor = lodash.isFunction(
      descriptionAccessorField
    )
      ? descriptionAccessorField
      : descriptionAccessorField[lineType];

    if (!descriptionAccessor) {
      return null;
    }

    const params = this.makeAccessorParams(cellPosition);

    return descriptionAccessor(params);
  }

  private async getCellIcon(
    cellPosition: CellPosition
  ): Promise<{ type: IconType; color: string; size: number }> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const iconAccessor: IconAccessor = lodash.get(ColumnsConfig, [
      columnName,
      "getIcon",
      lineType,
    ]);

    if (!iconAccessor) {
      return null;
    }

    const params = this.makeAccessorParams(cellPosition);

    return iconAccessor(params);
  }

  private getLineType(lineId: LineId): LineType {
    return LineType.Line;
  }

  private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
    const { lineId } = cellPosition;

    const line = this.getLine(lineId);

    return {
      id: lineId,
      line,
    };
  }

  private makeValueChangeHandler(cellPosition: CellPosition) {
    return async (value: any) => {
      const { lineId, columnName } = cellPosition;

      const lineType = this.getLineType(lineId);
      const params = this.makeAccessorParams(cellPosition);

      const valueSetterField = lodash.get(ColumnsConfig, [
        columnName,
        "setValue",
      ]);

      const valueSetter: ValueSetter = lodash.isFunction(valueSetterField)
        ? valueSetterField
        : valueSetterField[lineType];

      await valueSetter(params, value);

      this.onCellClose();
    };
  }

  private async applyCustomStyles(
    cellPosition: CellPosition,
    cellProps: any
  ): Promise<any> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    cellProps.customStyle = {
      ...cellProps.customStyle,
      backgroundColor: this.getCellBackgroundColor(cellPosition),
    };

    const customStyleAccessorField = lodash.get(ColumnsConfig, [
      columnName,
      "customStyle",
    ]);

    if (customStyleAccessorField) {
      const customStyleAccessor: CustomStyleAccessor = lodash.isFunction(
        customStyleAccessorField
      )
        ? customStyleAccessorField
        : customStyleAccessorField[lineType];

      if (customStyleAccessor) {
        const params = this.makeAccessorParams(cellPosition);

        const customStyle = await customStyleAccessor(params);

        cellProps = {
          ...cellProps,
          customStyle: { ...cellProps.customStyle, ...customStyle },
        };
      }
    }

    const isArchived = this.checkLineArchivedStatus(lineId);

    if (isArchived) {
      cellProps.customStyle = {
        ...cellProps.customStyle,
        opacity: "0.6",
      };
    }

    return cellProps;
  }

  private checkLineArchivedStatus(lineId: string): boolean {
    const line = this.getLine(lineId);

    return line.model.status === "archived";
  }

  private roundNumber(value: number, digitsAfterComma = 2): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split(".");

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ""}`;
  }

  private formatCurrencyValue(value: number | string): string {
    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = "";

    if (lodash.first(decimalPart) === "-") {
      decimalPart = decimalPart.substring(1);
      sign = "-";
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(" ");

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ""}`;
  }
}
