import * as React from "react";

import * as style from "./Schema.scss";

import { BriefName } from "./BriefName";
import { ElementsList } from "./ElementsList";

export function Schema(): JSX.Element {
  return (
    <div className={style.root}>
      <BriefName />

      <ElementsList />
    </div>
  );
}
