import * as React from "react";
import * as lodash from "lodash";
import autobind from "autobind-decorator";
import { connect } from "react-redux";

import type { PlainDictionary } from "@sm/types/budget";

import { ActivityTypeForm } from "./ActivityTypeForm";
import { StoreState } from "../../../store";
import { ActivityTypeColorData } from "../../../store/activityType/types";
import { getUserOrganizationId } from "../../../store/user/selector";

const ActivityTypeOption = [
  {
    name: "preparation",
    color: "",
    opened: false,
  },
  {
    name: "realization",
    color: "",
    opened: false,
  },
  {
    name: "hover",
    color: "",
    opened: false,
  },
];

interface Props extends Partial<MapProps> {
  activityType: PlainDictionary;
  activityTypeColorData: ActivityTypeColorData;
  onConfirmClick: (colorData: ActivityTypeColorData) => void;
  onCancelClick: () => void;
}

interface MapProps {
  organizationId: string;
}

interface State {
  optionParams: {
    name: string;
    color: string;
    opened: boolean;
  }[];
}

@(connect(mapStateToProps, null) as any)
export class ActivityTypeFormContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      optionParams: [
        {
          name: "preparation",
          color: this.props.activityTypeColorData?.preparation || "",
          opened: false,
        },
        {
          name: "realization",
          color: this.props.activityTypeColorData?.realization || "",
          opened: false,
        },
        {
          name: "hover",
          color: this.props.activityTypeColorData?.hover || "",
          opened: false,
        },
      ],
    };
  }

  public render(): JSX.Element {
    const { activityType } = this.props;

    return React.createElement(ActivityTypeForm, {
      activityType,
      optionParams: this.state.optionParams,
      onOptionChange: this.onOptionChange,
      onConfirmClick: this.onConfirmClick,
      onCancelClick: this.props.onCancelClick,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    });
  }

  @autobind
  protected onConfirmClick() {
    const optionParamsGrouped = lodash.groupBy(
      this.state.optionParams,
      (option) => option.name
    );

    this.props.onConfirmClick({
      preparation: optionParamsGrouped.preparation?.[0].color,
      realization: optionParamsGrouped.realization?.[0].color,
      hover: optionParamsGrouped.hover?.[0].color,
    });
  }

  @autobind
  protected onBlur(name: string, color: string) {
    this.changeColorOptions(name, color, false);
  }

  @autobind
  protected onFocus(name: string) {
    this.changeColorOptions(name, undefined, true);
  }

  @autobind
  protected onOptionChange(name: string, color: string) {
    this.changeColorOptions(name, color);
  }

  private changeColorOptions(name: string, color?: string, opened?: boolean) {
    this.setState((state) => ({
      optionParams: state.optionParams.map((params) =>
        params.name == name
          ? {
              name: params.name,
              color: color === undefined ? params.color : color,
              opened: opened === undefined ? params.opened : opened,
            }
          : params
      ),
    }));
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    organizationId: getUserOrganizationId(state),
  };
}
