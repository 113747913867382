import * as React from "react";
import classNames from "classnames";

import { Icon, IconType, Input_redesign, InputTheme_redesign } from "@sm/ui";

import * as styles from "./styles.scss";

export interface Props {
  value: string;
  isActive: boolean;
  onChange: (value: string) => void;
  onFocus: () => void;
}

export const SearchInput: React.FC<Props> = ({
  value,
  isActive,
  onChange,
  onFocus,
}) => {
  return (
    <div className={classNames(styles.root, isActive && styles.rootActive)}>
      <Input_redesign
        className={styles.searchInput}
        value={value}
        placeholder={"Выберите поле/справочник или введите численное значение"}
        theme={InputTheme_redesign.Simple}
        onInputChange={onChange}
        onFocus={onFocus}
      />
      <Icon
        className={classNames(styles.arrowIcon, isActive && styles.arrowIconUp)}
        type={IconType.ARROW16}
      />
    </div>
  );
};
