import * as React from "react";
import { DropTarget, DropTargetMonitor, ConnectDropTarget } from "react-dnd";
import { connect, Dispatch } from "react-redux";

import { AddBlockParams } from "../../../../../store/brief/types";
import { ItemType } from "../../../ItemType";

import { AddBlockTarget } from "./AddBlockTarget";
import { StoreState } from "../../../../../store";
import { addBlock } from "../../../../../store/brief/actions";
import { getBriefSchemeId } from "../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps, DNDProps {
  parent?: string;
  order: number;
}

interface DNDProps {
  connectDropTarget?: ConnectDropTarget;
  itemType?: string;
  isOver?: boolean;
}

interface MapProps {
  briefSchemaId?: string;
}

interface DispatchProps {
  addBlock?: (params: AddBlockParams) => void;
}

interface State {
  isShow: boolean;
}

const boxTarget = {
  drop(
    props: Props,
    monitor: DropTargetMonitor,
    component: AddBlockTargetContainer
  ) {
    const itemType = monitor.getItemType();

    component.addItem(itemType);
  },
};

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(DropTarget([ItemType.BLOCK], boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  itemType: monitor.getItemType(),
  isOver: monitor.isOver(),
})) as any)
export class AddBlockTargetContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShow: false,
    };
  }

  public componentWillReceiveProps(newProps: Props) {
    this.setState({
      isShow: newProps.itemType == ItemType.BLOCK,
    });
  }

  public render() {
    return React.createElement(AddBlockTarget, {
      isHidden: !this.state.isShow,
      connectDropTarget: this.props.connectDropTarget,
      isOver: this.props.isOver,
    });
  }

  public async addItem(itemType: string) {
    this.props.addBlock({
      schemaId: this.props.briefSchemaId,
      briefBlockId: this.props.parent || null,
      order: this.props.order,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    briefSchemaId: getBriefSchemeId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    addBlock: (params: AddBlockParams) => dispatch(addBlock(params)),
  };
}
