import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import * as lodash from "lodash";

import { BriefScheme, DepartmentAttributes } from "@sm/types/admin";
import { StoreState } from "../../";
import { BriefSchemeListPageState, BriefSchemeListFilterMode } from "../types";

import { Utils } from "../../../modules/common/Utils";

export const getBriefSchemeListPageState = (
  state: StoreState
): BriefSchemeListPageState => state.briefSchemeListPage;

export const getBriefSchemeList = createSelector(
  getBriefSchemeListPageState,
  (state: BriefSchemeListPageState): BriefScheme[] =>
    lodash.sortBy(state.briefSchemes, "name")
);

export const getSelectedFilter = createSelector(
  getBriefSchemeListPageState,
  (state: BriefSchemeListPageState): BriefSchemeListFilterMode =>
    state.selectedFilter
);

export const getFilteredBriefSchemeList = createSelector(
  [getBriefSchemeList, getSelectedFilter],
  (
    briefSchemes: BriefScheme[],
    selectedFilter: BriefSchemeListFilterMode
  ): BriefScheme[] => {
    switch (selectedFilter) {
      case BriefSchemeListFilterMode.Actual:
        return briefSchemes.filter(
          (schema) => schema.status == BriefSchemeListFilterMode.Actual
        );
      case BriefSchemeListFilterMode.Inactual:
        return briefSchemes.filter(
          (schema) => schema.status == BriefSchemeListFilterMode.Inactual
        );
      default:
        return briefSchemes;
    }
  }
);

export const getExecutorDepartments = createSelector(
  getBriefSchemeListPageState,
  (state: BriefSchemeListPageState): DepartmentAttributes[] => state.departments
);

export const getExecutorDepartment = createCachedSelector(
  getExecutorDepartments,
  (state: StoreState, id: string) => id,
  (departments: DepartmentAttributes[], id: string): DepartmentAttributes =>
    Utils.getItemById(departments, id)
)((state: StoreState, id: string) => id);
