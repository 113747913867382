import autobind from "autobind-decorator";
import { PureComponent, createElement } from "react";
import { isFunction, isEmpty } from "lodash";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";

import { getFieldById } from "../../../../../../../store/brief/selector";
import { editField } from "../../../../../../../store/brief/actions";
import {
  EditFieldPropertiesParams,
  BriefPropertyFile,
} from "../../../../../../../store/brief/types";
import { StoreState } from "../../../../../../../store";
import { FileAsset, FileAssetTarget } from "api/file-api/types";
import { FileApi } from "../../../../../../../api";

import {
  FileAssetColor,
  FileAssetWithPreviewProps as OwnProps,
  FileAssetWithPreviewState as State,
} from "./types";
import { FileAssetWithPreviewTemplate } from "./FileAssetWithPreviewTemplate";

/** "FileAssetWithPreview" container component */

interface MapProps {
  files?: BriefPropertyFile[];
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
}

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {
  fieldId: string;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileAssetWithPreviewContainer extends PureComponent<Props, State> {
  public static readonly displayName: string = "FileAssetWithPreviewContainer";

  public state: State = {
    borderColor: FileAssetColor.TRANSPARENT,
    removeOpacity: 0,
    removeBackgroundColor: FileAssetColor.GREY,
    previewShadowOpacity: 0,
    openGalleryShadowColor: FileAssetColor.GREEN,
    downloadFileShadowColor: FileAssetColor.GREEN,
    openGalleryShadowOpacity: 0,
    downloadFileShadowOpacity: 0,
    isRemovePopupOpen: false,
    galleryDisplay: "none",
    downloadDisplay: "none",
    galleryOpacity: 0.8,
    downloadOpacity: 0.8,
    nameColor: FileAssetColor.GREEN,
    microTypeMarkColor: FileAssetColor.BLACK_TRANSLUCENT,
    previewWasLoaded: false,
  };

  public render(): JSX.Element {
    const {
      className,
      isSchemaActive,
      isSchemaEditable,
      disableDownload,
      useR7Controls,
    } = this.props;
    return createElement(FileAssetWithPreviewTemplate, {
      ...this.originAsset,
      ...this.state,
      className,
      canDelete: isSchemaActive || isSchemaEditable,
      disableDownload,
      useR7Controls,
      onRemoveClick: this.onRemoveClick,
      onDownloadClick: this.onDownloadClick,
      onPreviewMouseOver: this.onPreviewMouseOver,
      onPreviewMouseLeave: this.revertStyles,
      onRemoveMouseOver: this.onRemoveMouseOver,
      onRemoveMouseLeave: this.revertStyles,
      onDownloadMouseOver: this.onDownloadMouseOver,
      onDownloadMouseLeave: this.revertStyles,
    });
  }

  private get originAsset(): FileAsset {
    const {
      name,
      originName,
      createdAt,
      type,
      targetType,
      targetId,
      parentTargetId,
      size,
      storage,
      uploadedBy,
      file,
      previewUrl,
      fullSizeUrl,
      isLoading,
      loadingProgress,
    } = this.props;
    return {
      name,
      originName,
      createdAt,
      type,
      targetType,
      targetId,
      parentTargetId,
      size,
      storage,
      uploadedBy,
      file,
      previewUrl,
      fullSizeUrl,
      isLoading,
      loadingProgress,
    };
  }

  /** Download click handler */
  @autobind
  private async onDownloadClick(): Promise<void> {
    const { disableDownload, name } = this.props;
    if (!disableDownload) {
      await FileApi.downloadFile(this.getUploadParams(), name);
    }
  }

  /** Remove submit click handler */
  @autobind
  private onRemoveClick(): void {
    this.props.editField({
      id: this.props.fieldId,
      files: this.props.files.filter((file) => file.id !== this.props.id),
    });
  }

  /** Preview mouse over handler */
  @autobind
  private onPreviewMouseOver() {
    this.setState(() => ({
      borderColor: FileAssetColor.GREY,
      removeOpacity: 1,
      removeBackgroundColor: FileAssetColor.GREY,
      previewShadowOpacity: 0.5,
      microTypeMarkColor: FileAssetColor.TRANSPARENT,
    }));
  }

  /** Remove mouse over handler */
  @autobind
  private onRemoveMouseOver(): void {
    this.setState(() => ({
      borderColor: FileAssetColor.PINK,
      removeOpacity: 1,
      removeBackgroundColor: FileAssetColor.PINK,
      previewShadowOpacity: 0.5,
      openGalleryShadowColor: FileAssetColor.PINK,
      downloadFileShadowColor: FileAssetColor.PINK,
      openGalleryShadowOpacity: 0.5,
      downloadFileShadowOpacity: 0.5,
      galleryDisplay: "block",
      downloadDisplay: "block",
      nameColor: FileAssetColor.PINK,
      microTypeMarkColor: FileAssetColor.TRANSPARENT,
    }));
  }

  /** Gallery mouse over handler */
  @autobind
  private onGalleryMouseOver(): void {
    this.setState(() => ({
      borderColor: FileAssetColor.GREEN,
      removeOpacity: 1,
      removeBackgroundColor: FileAssetColor.GREY,
      previewShadowOpacity: 0.5,
      openGalleryShadowOpacity: 0.7,
      galleryDisplay: "block",
      downloadDisplay: "block",
      galleryOpacity: 1,
      nameColor: FileAssetColor.GREEN,
      microTypeMarkColor: FileAssetColor.TRANSPARENT,
    }));
  }

  /** Download mouse over handler */
  @autobind
  private onDownloadMouseOver(): void {
    this.setState(() => ({
      borderColor: FileAssetColor.GREEN,
      removeOpacity: 1,
      removeBackgroundColor: FileAssetColor.GREY,
      previewShadowOpacity: 0.5,
      downloadFileShadowOpacity: 0.7,
      galleryDisplay: "block",
      downloadDisplay: "block",
      downloadOpacity: 1,
      microTypeMarkColor: FileAssetColor.TRANSPARENT,
    }));
  }

  @autobind
  private revertStyles(): void {
    this.setState(() => ({
      borderColor: FileAssetColor.TRANSPARENT,
      removeOpacity: 0,
      removeBackgroundColor: FileAssetColor.GREY,
      previewShadowOpacity: 0,
      openGalleryShadowOpacity: 0,
      downloadFileShadowOpacity: 0,
      galleryDisplay: "none",
      downloadDisplay: "none",
      galleryOpacity: 0.8,
      downloadOpacity: 0.8,
      nameColor: FileAssetColor.GREEN,
      openGalleryShadowColor: FileAssetColor.GREEN,
      downloadFileShadowColor: FileAssetColor.GREEN,
      microTypeMarkColor: FileAssetColor.BLACK_TRANSLUCENT,
    }));
  }

  private getUploadParams(): FileAsset {
    const { id, fileId, originName, type } = this.props;

    return {
      id,
      fileId,
      originName,
      type,
    };
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.fieldId);
  const isSchemaEditable = state.brief.isSchemeEditable;
  const isSchemaActive = state.brief.isSchemeActive;

  return {
    files: field ? field.properties?.files || [] : [],
    isSchemaEditable,
    isSchemaActive,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    editField: (params: EditFieldPropertiesParams) =>
      dispatch(editField(params)),
  };
}
