// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ActionSelector-_styles__sliding-appearance{0%{opacity:0;transform:translateY(5px)}100%{opacity:1;transform:translateY(0px)}}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ActionSelector-_styles__root{padding:4px 6px;display:grid;grid-column-gap:6px;grid-auto-flow:column;grid-auto-columns:auto;background:#FFFFFF;box-shadow:0 2px 6px rgba(0,0,0,0.15);border-radius:4px;animation:src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ActionSelector-_styles__sliding-appearance 75ms ease-out}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/FormulasBuilder/Operation/OperationButtons/ActionSelector/styles.scss"],"names":[],"mappings":"AAAA,6IACI,GACI,SAAU,CACV,yBAA0B,CAG9B,KACI,SAAU,CACV,yBAA0B,CAAA,CAIlC,qHACI,eAAgB,CAEhB,YAAa,CACb,mBAAoB,CACpB,qBAAsB,CACtB,sBAAuB,CAEvB,kBAAmB,CACnB,qCAAyC,CACzC,iBAAkB,CAElB,yJAA2C","sourcesContent":["@keyframes sliding-appearance {\n    0% {\n        opacity: 0;\n        transform: translateY(5px);\n    }\n\n    100% {\n        opacity: 1;\n        transform: translateY(0px);\n    }\n}\n\n.root {\n    padding: 4px 6px;\n\n    display: grid;\n    grid-column-gap: 6px;\n    grid-auto-flow: column;\n    grid-auto-columns: auto;\n\n    background: #FFFFFF;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);\n    border-radius: 4px;\n\n    animation: sliding-appearance 75ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ActionSelector-_styles__root",
	"sliding-appearance": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ActionSelector-_styles__sliding-appearance"
};
export default ___CSS_LOADER_EXPORT___;
