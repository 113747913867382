import {
  CalendarSharing,
  CreateSharingParams,
  DeleteSharingParams,
} from "@mrm/project";

import { AxiosResponse, axios } from "../lib/axios";

const BASE_URL = "/api/calendar/sharing";

export class CalendarSharingApi {
  public static async getCalendarSharingList(): Promise<CalendarSharing[]> {
    const res: AxiosResponse = await axios.get<CalendarSharing[]>(BASE_URL);

    return res.data;
  }

  public static async createCalendarSharing(
    params: CreateSharingParams
  ): Promise<void> {
    await axios.post<void>(BASE_URL, params);
  }

  public static async deleteCalendarSharing(
    params: DeleteSharingParams
  ): Promise<void> {
    await axios.delete(BASE_URL, { params });
  }
}
