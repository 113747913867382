import * as React from "react";
import {
  NavigationMenu,
  InternalLink,
  IconType,
  InfoGroupButton,
} from "@sm/ui";

import { AuthApi } from "../../../api";

const iconColor = "#4a4a4a";
const activeIconColor = "#19bb4f";

const ITEMS = [
  <InternalLink
    key="dictionariesLink"
    title="Справочники"
    url="/dictionaries"
    icon={IconType.NAVIGATION_DICTIONARIES}
    activeIcon={IconType.NAVIGATION_DICTIONARIES}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="budgetsLink"
    title="Бюджеты"
    url="/budgets"
    icon={IconType.BUDGET_ACCESS}
    activeIcon={IconType.BUDGET_ACCESS}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="departmentsLink"
    title="Департаменты"
    url="/department"
    icon={IconType.NAVIGATION_DEPARTMENT}
    activeIcon={IconType.NAVIGATION_DEPARTMENT}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="workTypeLink"
    title="Виды работ"
    url="/work-type"
    icon={IconType.NAVIGATION_WORK_TYPE}
    activeIcon={IconType.NAVIGATION_WORK_TYPE}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="briefSchemeLink"
    title="Шаблоны брифа"
    url="/brief/scheme"
    icon={IconType.NAVIGATION_BRIEF_SCHEME}
    activeIcon={IconType.NAVIGATION_BRIEF_SCHEME}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="activityTypeLink"
    title="Типы проекта"
    url="/activity-type"
    icon={IconType.NAVIGATION_ACTIVITY_TYPE}
    activeIcon={IconType.NAVIGATION_ACTIVITY_TYPE}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="calendarSharingLink"
    title="Доступ к календарям"
    url="/calendar-sharing"
    icon={IconType.CALENDAR_SHARING_ICON}
    activeIcon={IconType.CALENDAR_SHARING_ICON}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="trademarkLink"
    title="Реестр товарных знаков"
    url="/trademark"
    icon={IconType.NAVIGATION_TRADEMARK}
    activeIcon={IconType.NAVIGATION_TRADEMARK}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
  <InternalLink
    key="contractsLink"
    title="Контракты"
    url="/contracts"
    icon={IconType.NAVIGATION_BRIEF_SCHEME}
    activeIcon={IconType.NAVIGATION_BRIEF_SCHEME}
    iconColor={iconColor}
    activeIconColor={activeIconColor}
  />,
];

export class MainNavigationContainer extends React.PureComponent {
  public render() {
    return (
      <NavigationMenu
        externalLinks={EXTERNAL_LINKS}
        internalItems={ITEMS}
        bottomInternalItems={[<InfoGroupButton key="infoGroupButton" />]}
        onLogoutButtonClick={AuthApi.logout}
      />
    );
  }
}
