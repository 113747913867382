import * as React from "react";
import classNames from "classnames";

import * as style from "./StatusCell.scss";

import { ConfirmModal } from "./ConfirmModal";

const titleByStatus = {
  ["plan"]: "Активный",
  ["execution"]: "Активный",
  ["archive_plan"]: "Архив",
  ["archive_execution"]: "Архив",
};

interface Props {
  value: any;
  year: number;
  onExecutionConfirm: () => void;
  onArchiveConfirm: () => void;
}

export const StatusCell = ({
  value,
  year,
  onExecutionConfirm,
  onArchiveConfirm,
}: Props): JSX.Element => {
  const [displayExecutionModal, setExecutionModalStatus] =
    React.useState(false);
  const [displayArchiveModal, setArchiveModalStatus] = React.useState(false);

  const onExecutionButtonClick = React.useCallback(() => {
    setExecutionModalStatus(true);
  }, []);

  const onArchiveButtonClick = React.useCallback(() => {
    setArchiveModalStatus(true);
  }, []);

  const onExecutionModalConfirm = React.useCallback(() => {
    onExecutionConfirm();
    setExecutionModalStatus(false);
  }, []);

  const onArchiveModalConfirm = React.useCallback(() => {
    onArchiveConfirm();
    setArchiveModalStatus(false);
  }, []);

  const onModalClose = React.useCallback(() => {
    setExecutionModalStatus(false);
    setArchiveModalStatus(false);
  }, []);

  return (
    <div className={style.root}>
      <div>{titleByStatus[value]}</div>

      {value === "plan" && (
        <div
          className={classNames(style.button, style.executionButton)}
          onClick={onExecutionButtonClick}
        >
          В исполнение
        </div>
      )}

      {value === "execution" && (
        <div
          className={classNames(style.button, style.archiveButton)}
          onClick={onArchiveButtonClick}
        >
          В архив
        </div>
      )}

      {/* {value === 'archive_execution' &&
                <div className={classNames(style.button, style.archiveButton)}>
                    Вернуть
                </div>
            } */}

      {displayExecutionModal && (
        <ConfirmModal
          title={"Внимание!"}
          message={`Бюджет Планирование ${year} будет переведен в Исполнение ${year}`}
          confirmButtonTitle={"Перевести"}
          closeButtonTitle={"Отменить"}
          onConfirm={onExecutionModalConfirm}
          onClose={onModalClose}
        />
      )}

      {displayArchiveModal && (
        <ConfirmModal
          title={"Внимание!"}
          message={`Бюджет Исполнение ${year} будет перенесен в архив`}
          confirmButtonTitle={"Перевести"}
          closeButtonTitle={"Отменить"}
          onConfirm={onArchiveModalConfirm}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};
