import * as React from "react";

import { useOutsideElementClick } from "../../../common/hooks";
import { ActionSelector } from "./ActionSelector";

import { Action } from "./enums";
import * as styles from "./styles.scss";

interface Props {
  disable: boolean;
  onClick: (action: Action) => void;
}

export const ActionsSelector: React.FC<Props> = ({
  disable,
  children,
  onClick,
}) => {
  const [active, setActive] = React.useState(false);

  const onOutsideClick = React.useCallback(() => {
    setActive(false);
  }, []);

  const { ref } = useOutsideElementClick(onOutsideClick);

  const onButtonClick = React.useCallback(() => {
    if (!disable) {
      setActive(!active);
    }
  }, [disable, active]);

  const onSelectorButtonClick = React.useCallback(
    (action: Action) => {
      setActive(false);
      onClick(action);
    },
    [onClick]
  );

  return (
    <div className={styles.root} ref={ref}>
      <div onClick={onButtonClick}>{children}</div>

      {active && (
        <div className={styles.actionSelectorWrapper}>
          <ActionSelector onButtonClick={onSelectorButtonClick} />
        </div>
      )}
    </div>
  );
};

export { Action };
