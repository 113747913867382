import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  CardGrid,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
} from "sber-marketing-ui";

import { SharingState } from "../../store/calendarSharing";

import { CustomFirstLine } from "../common/CustomFirstLine";
import { Page, PageProps } from "../common/Page";

import { SharingCard } from "./SharingCard";

import * as styles from "./CalendarSharingPage.scss";

interface Props extends PageProps {
  sharings: SharingState[];
}

export function CalendarSharingPage({
  preloader,
  pageLabel,
  previousUrl,
  previousLabel,
  sharings,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      previousUrl={previousUrl}
      previousLabel={previousLabel}
      customFirstLine={CalendarSharingCustomFirstLine}
    >
      <div className={styles.root}>
        <CardGrid
          cards={sharings.map((sharing) => (
            <SharingCard key={sharing.organization.id} {...sharing} />
          ))}
          cardStyle={{
            width: 288,
            height: 102,
            marginHorizontal: 32,
            marginVertical: 32,
          }}
        />
      </div>
    </Page>
  );
}

function CalendarSharingCustomFirstLine({ pageLabel }: PageProps): JSX.Element {
  return (
    <CustomFirstLine
      pageLabel={pageLabel}
      additionalRightCssClass={styles.buttons}
    >
      <NavLink to="/calendar-sharing-edit/new" className={styles.button}>
        <Button theme={ButtonTheme.MainRoundedUpdated}>
          Создать новый доступ
        </Button>
      </NavLink>
    </CustomFirstLine>
  );
}
