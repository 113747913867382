import * as React from "react";
import type { RouteComponentProps } from "react-router";

import { BudgetAccessPage } from "./BudgetAccessPage";

interface Props extends Partial<RouteComponentProps<RouteParams>> {
  pageLabel?: string;
}

interface State {
  preloader: boolean;
}

interface RouteParams {
  budgetId?: string;
}

export class BudgetAccessPageContainer extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: false,
    };
  }

  public async componentDidMount() {}

  public render() {
    const { pageLabel } = this.props;
    const { preloader } = this.state;

    return React.createElement(BudgetAccessPage, {
      preloader,
      pageLabel,
      budgetId: this.getBudgetId(),
    });
  }

  private getBudgetId() {
    const { budgetId } = this.props.match.params;

    return budgetId;
  }
}
