import * as React from "react";
import { SwitchTreeMenu } from "@sm/ui";

import { DepartmentsSwitchMenuTemplateProps } from "./types";

/** "DepartmentsSwitchMenu" template component */
export const DepartmentsSwitchMenuTemplate: React.SFC<DepartmentsSwitchMenuTemplateProps> =
  ({
    onDepartmentSelect,
    items,
    className,
  }: DepartmentsSwitchMenuTemplateProps): JSX.Element => (
    <SwitchTreeMenu
      className={className}
      items={items}
      onSwitch={onDepartmentSelect}
    />
  );

DepartmentsSwitchMenuTemplate.displayName =
  "businessAdmin::WorkType::DepartmentsSwitchMenu::Template";
