import * as React from "react";

import { CloseButton } from "./CloseButton";
import { Title } from "./Title";
import * as styles from "./styles.scss";

interface Props {
  onCloseButtonClick(): void;
}

export const Header: React.FC<Props> = ({ onCloseButtonClick }) => {
  return (
    <div className={styles.root}>
      <Title text={"Создать формулу расчета"} />
      <CloseButton onClick={onCloseButtonClick} />
    </div>
  );
};
