// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-Elements-Fields-FileBlock-FileUploader-_FileUploader__root{display:flex;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Schema/Elements/Fields/FileBlock/FileUploader/FileUploader.scss"],"names":[],"mappings":"AAAA,yFACI,YAAa,CACb,cAAe","sourcesContent":[".root {\n    display: flex;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Schema-Elements-Fields-FileBlock-FileUploader-_FileUploader__root"
};
export default ___CSS_LOADER_EXPORT___;
