import { reducerWithInitialState } from "typescript-fsa-reducers";
import { v4 as uuid } from "uuid";
import * as lodash from "lodash";
import arrayMove from "array-move";

import {
  ChangedStageOrder,
  InputError,
  Stage,
  StageTemplateName,
  TemplateEditPageLoadParams,
  TemplateEditPageState,
  ValueError,
} from "../types";
import {
  addCreatedStage,
  changeStageOrder,
  editListStage,
  loadPageState,
  removeListStage,
  resetPageState,
  resetCreatedStageName,
  setName,
  setCreatedStageName,
  setRequestError,
  checkCreatedStage,
} from "../actions";

const ALLOWED_NUMBER_OF_CHARACTERS = 3;

const initialState: TemplateEditPageState = {
  name: {
    value: "",
    valid: false,
    hasRequestError: false,
  },
  stages: [],
  createdStage: {
    id: uuid(),
    value: "",
    warning: false,
    valid: true,
  },
  viewOnly: false,
};

class Reducer {
  public static loadPageState(
    state: TemplateEditPageState,
    payload: TemplateEditPageLoadParams
  ): TemplateEditPageState {
    return {
      ...state,
      ...payload,
    };
  }

  public static resetPageState(): TemplateEditPageState {
    return initialState;
  }

  public static resetCreatedStageName(
    state: TemplateEditPageState
  ): TemplateEditPageState {
    return {
      ...state,
      stages: checkStage(state.stages),
      createdStage: {
        ...state.createdStage,
        value: "",
        warning: false,
        valid: true,
      },
    };
  }

  public static addCreatedStage(
    state: TemplateEditPageState
  ): TemplateEditPageState {
    const { stages, createdStage } = state;

    const newStages = [...stages, createdStage];

    const isWarningCreatedStage = lodash.includes(
      getWarningStageIds(newStages),
      createdStage.id
    );
    const isValidCreatedStage = Boolean(
      getValidStageIds([createdStage]).length
    );

    if (isWarningCreatedStage || !isValidCreatedStage) {
      return {
        ...state,
        createdStage: {
          ...createdStage,
          warning: isWarningCreatedStage,
          valid: isValidCreatedStage,
        },
        stages: lodash.reject(checkStage(newStages), { id: createdStage.id }),
      };
    }

    return {
      ...state,
      createdStage: {
        id: uuid(),
        value: "",
        warning: false,
        valid: true,
      },
      stages: checkStage([
        ...stages,
        {
          ...createdStage,
          valid: true,
          warning: false,
        },
      ]),
    };
  }

  public static checkCreatedStage(
    state: TemplateEditPageState
  ): TemplateEditPageState {
    const { stages, createdStage } = state;

    const newStages = [...stages, createdStage];

    const isWarningCreatedStage = lodash.includes(
      getWarningStageIds(newStages),
      createdStage.id
    );
    const isValidCreatedStage = Boolean(
      getValidStageIds([createdStage]).length
    );

    if (isWarningCreatedStage || !isValidCreatedStage) {
      return {
        ...state,
        createdStage: {
          ...createdStage,
          warning: isWarningCreatedStage,
          valid: isValidCreatedStage,
        },
        stages: lodash.reject(checkStage(newStages), { id: createdStage.id }),
      };
    }

    return {
      ...state,
      createdStage: {
        ...createdStage,
        warning: isWarningCreatedStage,
        valid: isValidCreatedStage,
      },
      stages: checkStage(stages),
    };
  }

  public static setName(
    state: TemplateEditPageState,
    payload: StageTemplateName
  ): TemplateEditPageState {
    return {
      ...state,
      name: payload,
    };
  }

  public static setCreatedStageName(
    state: TemplateEditPageState,
    payload: string
  ): TemplateEditPageState {
    return {
      ...state,
      createdStage: {
        ...state.createdStage,
        value: payload,
      },
    };
  }

  public static setRequestError(
    state: TemplateEditPageState,
    payload: boolean
  ): TemplateEditPageState {
    const { name } = state;

    return {
      ...state,
      name: {
        ...name,
        hasRequestError: payload,
        valid: false,
      },
    };
  }

  public static changeStageOrder(
    state: TemplateEditPageState,
    payload: ChangedStageOrder
  ): TemplateEditPageState {
    return {
      ...state,
      stages: arrayMove(state.stages, payload.oldIndex, payload.newIndex),
    };
  }

  public static editListStage(
    state: TemplateEditPageState,
    payload: Stage
  ): TemplateEditPageState {
    const { stages, createdStage } = state;

    const editedStages = stages.map((stage) =>
      stage.id === payload.id ? { ...stage, ...payload } : stage
    );
    const haveValueCreatedStage = Boolean(createdStage.value.length);

    const checkedStages = checkStage(
      haveValueCreatedStage ? [...editedStages, createdStage] : editedStages
    );

    return {
      ...state,
      createdStage: haveValueCreatedStage
        ? lodash.find(checkedStages, { id: createdStage.id })
        : createdStage,
      stages: lodash.reject(checkedStages, { id: createdStage.id }),
    };
  }

  public static removeListStage(
    state: TemplateEditPageState,
    payload: number
  ): TemplateEditPageState {
    return {
      ...state,
      stages: lodash.reject(state.stages, (_, index) => index === payload),
    };
  }
}

function checkStage(stages: Stage[]): Stage[] {
  const warningStageIds = getWarningStageIds(stages);
  const validStageIds = getValidStageIds(stages);

  const editedStagesWithWarning = stages.map((stage) => {
    return lodash.includes(warningStageIds, stage.id)
      ? { ...stage, warning: true }
      : { ...stage, warning: false };
  });

  const editedStageWithValid = editedStagesWithWarning.map((stage) => {
    return lodash.includes(validStageIds, stage.id)
      ? { ...stage, valid: true }
      : { ...stage, valid: false };
  });

  return editedStageWithValid;
}

function getWarningStageIds(stages: Stage[]): string[] {
  const stageIds: string[] = [];

  stages.forEach((stage) => {
    const inputErrors = checkDuplicateValues(stage, stages);
    inputErrors.forEach((inputError) => {
      stageIds.push(inputError.payload);
    });
  });

  return lodash.uniq(stageIds);
}

function getValidStageIds(stages: Stage[]): string[] {
  const stageIds: string[] = [];

  stages.forEach((stage) => {
    try {
      checkAcceptableLength(stage);
      stageIds.push(stage.id);
    } catch (_) {}
  });

  return lodash.uniq(stageIds);
}

function checkAcceptableLength(stage: Stage): void {
  if (stage.value.length < ALLOWED_NUMBER_OF_CHARACTERS) {
    throw {
      value: ValueError.NOT_ENOUGH_CHARACTERS,
      payload: null,
    };
  }
}

function checkDuplicateValues(stage: Stage, stages: Stage[]): InputError[] {
  const inputErrors: InputError[] = [];

  const stagesWithoutTargetStage = stages.filter(
    (currentStage) => currentStage.id !== stage.id
  );
  stagesWithoutTargetStage.forEach((currentStage) => {
    if (stage.value === currentStage.value) {
      inputErrors.push({
        value: ValueError.DUPLICATE_VALUE,
        payload: currentStage.id,
      });
    }
  });

  return inputErrors;
}

export const templateEditPageReducer = reducerWithInitialState(initialState)
  .case(loadPageState, Reducer.loadPageState)
  .case(resetPageState, Reducer.resetPageState)
  .case(resetCreatedStageName, Reducer.resetCreatedStageName)
  .case(addCreatedStage, Reducer.addCreatedStage)
  .case(setName, Reducer.setName)
  .case(setRequestError, Reducer.setRequestError)
  .case(setCreatedStageName, Reducer.setCreatedStageName)
  .case(changeStageOrder, Reducer.changeStageOrder)
  .case(editListStage, Reducer.editListStage)
  .case(removeListStage, Reducer.removeListStage)
  .case(checkCreatedStage, Reducer.checkCreatedStage);
