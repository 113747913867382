import * as React from "react";
import * as lodash from "lodash";
import autobind from "autobind-decorator";

import type {
  Action,
  BudgetAccessGroup,
  Dictionary,
  DictionaryType,
} from "../../../../../../api";

import { RightGroupsEditorTemplate } from "./RightGroupsEditorTemplate";

interface Props {
  actions: Action[];
  dictionaries: Dictionary[];
  value: BudgetAccessGroup[];
  onValueChange: (value: BudgetAccessGroup[]) => void;
  onClose: () => void;
}

interface State {
  groups: BudgetAccessGroup[];
}

export class RightGroupsEditorBehaviour extends React.PureComponent<
  Props,
  State
> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      groups: lodash.isEmpty(props.value)
        ? [
            {
              groupId: 0,
              dictionaryIds: [],
              actionIds: [],
              dictionaryTypes: [],
            },
          ]
        : lodash.cloneDeep(props.value),
    };
  }

  public render(): JSX.Element {
    const { actions, dictionaries } = this.props;
    const { groups } = this.state;

    return React.createElement(RightGroupsEditorTemplate, {
      actions,
      dictionaries,
      groups,
      onAddGroupButtonClick: this.onAddGroupButtonClick,
      onConfirm: this.onConfirm,
      onClose: this.props.onClose,
      onDictionariesChange: this.onDictionariesChange,
      onGroupActionsChange: this.onGroupActionsChange,
      onGroupDictionaryTypeChange: this.onGroupDictionaryTypeChange,
      onDeleteGroupButtonClick: this.onDeleteGroupButtonClick,
    });
  }

  @autobind
  protected async onAddGroupButtonClick() {
    let newGroupId = 0;

    while (this.state.groups.some((item) => item.groupId === newGroupId)) {
      newGroupId += 1;
    }

    this.setState({
      groups: [
        ...this.state.groups,
        {
          groupId: newGroupId,
          dictionaryIds: [],
          actionIds: [],
          dictionaryTypes: [],
        },
      ],
    });
  }

  @autobind
  protected async onConfirm() {
    this.props.onValueChange(this.state.groups);
    this.props.onClose();
  }

  @autobind
  protected async onDictionariesChange(groupId: number, value: string[]) {
    const dictionaryTypes = lodash.uniq(
      this.props.dictionaries
        .filter((dictionary) => value.includes(dictionary.id))
        .map((dictionary) => dictionary.type)
    );

    const updatedGroups = lodash.clone(this.state.groups);
    const changedGroup = updatedGroups.find((item) => item.groupId === groupId);

    changedGroup.dictionaryIds = value;
    changedGroup.dictionaryTypes = dictionaryTypes;

    this.setState({
      groups: updatedGroups,
    });
  }

  @autobind
  protected async onGroupActionsChange(groupId: number, value: number[]) {
    const updatedGroups = lodash.clone(this.state.groups);
    const changedGroup = updatedGroups.find((item) => item.groupId === groupId);

    changedGroup.actionIds = value;

    this.setState({
      groups: updatedGroups,
    });
  }

  @autobind
  protected onGroupDictionaryTypeChange(
    groupId: number,
    dictionaryTypes: DictionaryType[]
  ) {
    const updatedGroups = lodash.clone(this.state.groups);
    const changedGroup = updatedGroups.find((item) => item.groupId === groupId);

    changedGroup.dictionaryTypes = dictionaryTypes;

    this.setState({ groups: updatedGroups });
  }

  @autobind
  protected async onDeleteGroupButtonClick(groupId: number) {
    this.setState({
      groups: this.state.groups.filter((item) => item.groupId !== groupId),
    });
  }
}
