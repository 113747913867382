// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__root{width:100%;max-width:984px;margin:0 auto;padding:40px 28px;background-color:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.1)}.src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__titleRow{display:flex;height:44px;color:#4a4a4a;font-size:12px;align-items:center}.src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__nameColumn{width:calc(100% - 405px);padding:0 14px}.src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__propertiesColumn{width:369px;padding:0 14px}\n", "",{"version":3,"sources":["webpack://./src/modules/ActivityTypePage/ActivityTypeTable/ActivityTypeTable.scss"],"names":[],"mappings":"AAAA,yEACI,UAAW,CACX,eAAgB,CAChB,aAAc,CACd,iBAAkB,CAElB,wBAAyB,CACzB,uCAA2C,CAC9C,6EAGG,YAAa,CAEb,WAAY,CAEZ,aAAc,CAEd,cAAe,CAEf,kBAAmB,CACtB,+EAGG,wBAAyB,CACzB,cAAe,CAClB,qFAGG,WAAY,CACZ,cAAe","sourcesContent":[".root {\n    width: 100%;\n    max-width: 984px;\n    margin: 0 auto;\n    padding: 40px 28px;\n\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n.titleRow {\n    display: flex;\n\n    height: 44px;\n\n    color: #4a4a4a;\n\n    font-size: 12px;\n\n    align-items: center;\n}\n\n.nameColumn {\n    width: calc(100% - 405px);\n    padding: 0 14px;\n}\n\n.propertiesColumn {\n    width: 369px;\n    padding: 0 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__root",
	"titleRow": "src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__titleRow",
	"nameColumn": "src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__nameColumn",
	"propertiesColumn": "src-modules-ActivityTypePage-ActivityTypeTable-_ActivityTypeTable__propertiesColumn"
};
export default ___CSS_LOADER_EXPORT___;
