import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import * as style from "./SortingMenu.scss";

import type { StoreState } from "../../../../../../store";
import {
  ColumnName,
  OrderType,
  SortingParams,
} from "../../../../../../store/budgetAccessPage/types";

import { Icon, IconType } from "sber-marketing-ui";
import { setTableSorting } from "../../../../../../store/budgetAccessPage/actions";
import { getSortingParams } from "../../../../../../store/budgetAccessPage/selectors";

interface Props {
  columnName: ColumnName;
}

export const SortingMenu = ({ columnName }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const sortingParams = useSelector((state: StoreState) =>
    getSortingParams(state)
  );

  const sortingParamsRef = React.useRef<SortingParams>({
    columnName: null,
    orderType: null,
  });

  React.useEffect(() => {
    sortingParamsRef.current = sortingParams;
  }, [sortingParams]);

  const onSelection = React.useCallback(
    (orderType: OrderType) => {
      const sortingParams = sortingParamsRef.current;

      const newSortingParams =
        columnName === sortingParams.columnName &&
        orderType === sortingParams.orderType
          ? { columnName: null, orderType: null }
          : { columnName, orderType };

      dispatch(setTableSorting(newSortingParams));
    },
    [dispatch, sortingParamsRef]
  );

  return (
    <div className={style.root}>
      <div className={style.title}>Сортировка</div>

      <div className={style.items}>
        <SortingItem
          orderType={OrderType.Asc}
          isSelected={
            columnName === sortingParams.columnName &&
            sortingParams.orderType === OrderType.Asc
          }
          onSelection={onSelection}
        />

        <SortingItem
          orderType={OrderType.Desc}
          isSelected={
            columnName === sortingParams.columnName &&
            sortingParams.orderType === OrderType.Desc
          }
          onSelection={onSelection}
        />
      </div>
    </div>
  );
};

interface SortingItemProps {
  orderType: OrderType;
  isSelected: boolean;
  onSelection: (orderType: OrderType) => void;
}

function SortingItem({ orderType, isSelected, onSelection }: SortingItemProps) {
  let from: string;
  let to: string;

  switch (orderType) {
    case OrderType.Asc:
      from = "А";
      to = "Я";
      break;
    case OrderType.Desc:
      from = "Я";
      to = "А";
      break;
  }

  const onClick = React.useCallback(() => {
    onSelection(orderType);
  }, [orderType]);

  return (
    <div className={style.item} onClick={onClick}>
      <div className={style.itemIcon}>
        <Icon
          type={
            isSelected ? IconType.RADIOBUTTON_CHECKED : IconType.RADIOBUTTON
          }
          svgSize={14}
        />
      </div>

      <div className={style.itemTitle}>
        {from}

        <div className={style.arrowIcon}>
          <svg
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3708 0.121796L12.4404 0.181942L15.8192 3.56075C16.0398 3.78129 16.0598 4.12639 15.8794 4.36957L15.8192 4.43925L12.4404 7.81806C12.1978 8.06065 11.8045 8.06065 11.5619 7.81806C11.3414 7.59752 11.3214 7.25242 11.5018 7.00924L11.5619 6.93957L13.85 4.65H0.65C0.291015 4.65 0 4.35898 0 4C0 3.67365 0.240508 3.40347 0.553948 3.35705L0.65 3.35L13.851 3.349L11.5619 1.06043C11.3414 0.839898 11.3214 0.494796 11.5018 0.251613L11.5619 0.181942C11.7825 -0.0385936 12.1276 -0.0586423 12.3708 0.121796Z"
              fill="#7E8681"
            />
          </svg>
        </div>

        {to}
      </div>
    </div>
  );
}
