import * as React from "react";

import * as style from "./EditUserRow.scss";

import type { SelectProps, SuggestItemParams } from "@sm/ui";

import { Select_redesign, OpenerIconType_redesign, Suggest } from "@sm/ui";

interface Props {
  userSelectProps: SelectProps;
  roles: SuggestItemParams[];
  userRoles: SuggestItemParams[];
  isOnEdit?: boolean;
  onItemSelect: (item: SuggestItemParams) => void;
}

export function EditUserRow({
  isOnEdit,
  userSelectProps,
  roles,
  userRoles,
  onItemSelect,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.column}>
        <div className={style.title}>Фамилия Имя Отчество</div>

        <div className={style.select}>
          <Select_redesign
            clearable={false}
            disabled={isOnEdit}
            placeholder={"Введите ФИО"}
            openerIcon={OpenerIconType_redesign.Arrow24}
            {...(userSelectProps as any)}
          />
        </div>
      </div>

      <div className={style.column}>
        <div className={style.title}>Роль пользователя</div>

        <div className={style.dropdown}>
          <Suggest
            title={"Выберите роль"}
            items={roles}
            selectedItems={userRoles}
            onItemSelect={onItemSelect}
            multipleSelect
          />
        </div>
      </div>
    </div>
  );
}
