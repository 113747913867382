import * as React from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import * as moment from "moment";

import * as style from "./ContractCreationModal.scss";

import type { CreativeRequestContractCreateParams } from "@api";
import type { StoreState } from "@store";

import {
  Popup,
  LabeledInput,
  LabeledSelect,
  InputType,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
  Icon,
  IconType,
} from "sber-marketing-ui";
import { getLotDictionaries } from "@store/contractsPage/selectors";

interface Props {
  onConfirm: (params: CreativeRequestContractCreateParams) => void;
  onClose: () => void;
}

export const ContractCreationModal = ({
  onConfirm,
  onClose,
}: Props): JSX.Element => {
  const [contractName, setContractName] = React.useState<string>(null);
  const [contractLotId, setContractLotId] = React.useState<string>(null);
  const [contractStartDate, setContractStartDate] =
    React.useState<moment.Moment>(null);
  const [contractEndDate, setContractEndDate] =
    React.useState<moment.Moment>(null);

  const lotDictionaries = useSelector((state: StoreState) =>
    getLotDictionaries(state)
  );

  const onConfirmClick = React.useCallback(() => {
    const params: CreativeRequestContractCreateParams = {
      id: uuid(),
      name: contractName,
      lotId: contractLotId,
      startDate: contractStartDate.toDate(),
      endDate: contractEndDate.toDate(),
    };

    onConfirm(params);
  }, [contractName, contractLotId, contractStartDate, contractEndDate]);

  const confirmButtonDisabled =
    !contractName || !contractLotId || !contractStartDate || !contractEndDate;

  const lotSelectItems = lotDictionaries.map((item) => ({
    label: item.value,
    value: item.id,
  }));

  return (
    <Popup onOutOfContentClick={onClose}>
      <div className={style.root}>
        <div className={style.title}>Создание нового контракта</div>

        <div className={style.fields}>
          <div className={style.field}>
            <LabeledInput
              title={"Название контракта *"}
              value={contractName}
              onValueChange={(value) => setContractName(value as string)}
            />
          </div>

          <div className={style.field}>
            <LabeledSelect
              title={"Лот *"}
              items={lotSelectItems}
              selectedValue={contractLotId}
              onSelection={(value) => setContractLotId(value as string)}
              disableSearch
            />
          </div>

          <div className={style.field}>
            <div className={style.smallField}>
              <LabeledInput
                type={InputType.DatePicker}
                title={"Дата начала контракта *"}
                maxDate={contractEndDate}
                value={contractStartDate}
                onValueChange={(value) =>
                  setContractStartDate(value as moment.Moment)
                }
              />
            </div>

            <div className={style.smallField}>
              <LabeledInput
                type={InputType.DatePicker}
                title={"Дата окончания контракта *"}
                minDate={contractStartDate}
                value={contractEndDate}
                onValueChange={(value) =>
                  setContractEndDate(value as moment.Moment)
                }
              />
            </div>
          </div>
        </div>

        <div className={style.buttons}>
          <div className={style.button}>
            <Button theme={ButtonTheme.GhostRounded} onClick={onClose}>
              Отменить
            </Button>
          </div>

          <div className={style.button}>
            <Button
              theme={ButtonTheme.MainRounded}
              onClick={onConfirmClick}
              disabled={confirmButtonDisabled}
            >
              Создать
            </Button>
          </div>
        </div>

        <div className={style.closeButton} onClick={onClose}>
          <Icon type={IconType.CROSS} svgSize={16} />
        </div>
      </div>
    </Popup>
  );
};
