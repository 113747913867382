import { createSelector } from "reselect";

import type { StoreState } from "../";
import type { PageData, PageState, SortingParams, Filter } from "./types";

export const getBudgetAccessPageState = (state: StoreState): PageState =>
  state.budgetAccessPage;

export const getPageData = createSelector(
  getBudgetAccessPageState,
  (state: PageState): PageData => {
    return state.pageData;
  }
);

export const getSortingParams = createSelector(
  getBudgetAccessPageState,
  (state: PageState): SortingParams => {
    return state.sorting;
  }
);

export const getFilters = createSelector(
  getBudgetAccessPageState,
  (state: PageState): Filter[] => {
    return state.filters;
  }
);
