import * as React from "react";
import { StyledPopup } from "@sm/ui";

import * as styles from "./ErrorHandler.scss";

interface ErrorHandlerProps {
  errorCode: string;
  onFirstButtonClick: () => void;
  children?: React.ReactNode;
}

const PopupParams = {
  Validation: {
    title: "Неправильный формат данных",
    fullMessage:
      "Напишите в чат технической поддержки в правом нижнем углу экрана, " +
      "либо по адресу: support@sbermarketing.ru",
  },
  CvValidation: {}, // same as Validation
  PermissionDenied: {
    title: "Недостаточно прав для выполнения действия",
    fullMessage:
      "Напишите в чат технической поддержки в правом нижнем углу экрана, " +
      "либо по адресу: support@sbermarketing.ru",
  },
  Forbidden: {}, // same as PermisionDenied
  NotFound: {
    title: "Запрашиваемые данные не найдены",
    fullMessage:
      "Напишите в чат технической поддержки в правом нижнем углу экрана, " +
      "либо по адресу: support@sbermarketing.ru",
  },
  BadRequest: {
    title: "Произошла неизвестная ошибка",
    fullMessage:
      "Напишите в чат технической поддержки в правом нижнем углу экрана, " +
      "либо по адресу: support@sbermarketing.ru",
  },
  GatewayTimeout: {
    title: "Сервер не отвечает",
    fullMessage:
      "Проверьте соединение с интернетом. Если с ним все в порядке, " +
      "напишите в чат технической поддержки в правом нижнем углу экрана, либо по адресу: " +
      "support@sbermarketing.ru",
  },
  InternalServer: {
    title: "Произошла неизвестная ошибка",
    fullMessage:
      "Напишите в чат технической поддержки в правом нижнем углу экрана, " +
      "либо по адресу: support@sbermarketing.ru",
  },
};
PopupParams.CvValidation = PopupParams.Validation;
PopupParams.Forbidden = PopupParams.PermissionDenied;

/* tslint:enable no-magic-numbers */

export function ErrorHandler({
  errorCode,
  onFirstButtonClick,
  children,
}: ErrorHandlerProps): JSX.Element {
  return (
    <div className={styles.root}>
      {errorCode && (
        <StyledPopup
          {...PopupParams[errorCode]}
          firstButtonText="ОК"
          firstButtonHandler={onFirstButtonClick}
        />
      )}

      <div className={errorCode && styles.contentHidden}>{children}</div>
    </div>
  );
}
