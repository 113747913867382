import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import { DepartmentAttributes } from "@sm/types/admin";
import { StoreState } from "../../";

import { getDepartmentListPageState } from "./departmentListPage";
import {
  DepartmentListPageState,
  DepartmentListPageDepartmentCreationBlockState,
} from "../types";
import { Utils } from "../../../modules/common/Utils";

export const getDepartmentCreationBlockState = createSelector(
  getDepartmentListPageState,
  (
    state: DepartmentListPageState
  ): DepartmentListPageDepartmentCreationBlockState => state.departmentCreation
);

export const getDepartmentsFromDepartmentCreationBlock = createSelector(
  getDepartmentCreationBlockState,
  ({
    departments,
  }: DepartmentListPageDepartmentCreationBlockState): DepartmentAttributes[] =>
    departments
);

export const getSelectedDepartmentsIdsFromDepartmentCreationBlock =
  createSelector(
    getDepartmentCreationBlockState,
    ({
      selectedDepartmentsIds,
    }: DepartmentListPageDepartmentCreationBlockState): string[] =>
      selectedDepartmentsIds
  );

export const getIsNewDepartmentAddedForDepartmentCreationBlock = createSelector(
  getDepartmentCreationBlockState,
  ({
    isNewDepartmentAdded,
  }: DepartmentListPageDepartmentCreationBlockState): boolean =>
    isNewDepartmentAdded
);

export const getDepartmentFromDepartmentCreationBlock = createCachedSelector(
  getDepartmentsFromDepartmentCreationBlock,
  (state: StoreState, id: string): string => id,
  (departments: DepartmentAttributes[], id: string): DepartmentAttributes =>
    Utils.getItemById(departments, id)
)((state: StoreState, id: string): string => id);

export const getDepartmentNameFromDepartmentCreationBlock =
  createCachedSelector(
    getDepartmentFromDepartmentCreationBlock,
    (state: StoreState, id: string): string => id,
    (department: DepartmentAttributes, id: string): string => department.name
  )((state: StoreState, id: string): string => id);

export const getSubDepartmentsFromDepartmentCreationBlock =
  createCachedSelector(
    getDepartmentsFromDepartmentCreationBlock,
    (state: StoreState, parentId: string): string => parentId,
    (
      departments: DepartmentAttributes[],
      parentId: string
    ): DepartmentAttributes[] =>
      departments.filter(
        (department) => department.parentDepartmentId === parentId
      )
  )((state: StoreState, parentId: string): string => parentId);

export const getSubDepartmentsIdsFromDepartmentCreationBlock =
  createCachedSelector(
    getSubDepartmentsFromDepartmentCreationBlock,
    (state: StoreState, parentId: string): string => parentId,
    (departments: DepartmentAttributes[], parentId: string): string[] =>
      departments.map((department) => department.id)
  )((state: StoreState, parentId: string): string => parentId);

export const getSiblingDepartmentsNamesFromDepartmentCreationBlock =
  createCachedSelector(
    getDepartmentsFromDepartmentCreationBlock,
    (state: StoreState, parentId: string, id: string): string => parentId,
    (state: StoreState, parentId: string, id: string): string => id,
    (
      departments: DepartmentAttributes[],
      parentId: string,
      id: string
    ): string[] => {
      const exactDepartment = departments.find(
        (department) => department.id === id
      );

      return (
        exactDepartment
          ? departments.filter(
              (department) =>
                department.parentDepartmentId === parentId &&
                department.id !== id
            )
          : departments.filter(
              (department) => department.parentDepartmentId === parentId
            )
      ).map((department) => department.name);
    }
  )(
    (state: StoreState, parentId: string, id: string): string =>
      `${parentId} ${id}`
  );

export const hasDepartmentSubDepartmentsInDepartmentCreationBlock =
  createCachedSelector(
    getDepartmentsFromDepartmentCreationBlock,
    (state: StoreState, id: string): string => id,
    (departments: DepartmentAttributes[], id: string): boolean =>
      !!departments.find((department) => department.parentDepartmentId === id)
  )((state: StoreState, id: string): string => id);

export const isDepartmentSelectedInDepartmentCreationBlock =
  createCachedSelector(
    getSelectedDepartmentsIdsFromDepartmentCreationBlock,
    (state: StoreState, id: string): string => id,
    (selectedDepartmentsIds: string[], id: string): boolean =>
      !!selectedDepartmentsIds.find(
        (selectedDepartmentId) => selectedDepartmentId === id
      )
  )((state: StoreState, id: string): string => id);

export const getIsNewSubDepartmentAddedForDepartmentCreationBlock =
  createCachedSelector(
    getDepartmentCreationBlockState,
    (state: StoreState, id: string): string => id,
    (
      departmentCreation: DepartmentListPageDepartmentCreationBlockState,
      id: string
    ): boolean =>
      departmentCreation.isNewDepartmentAdded &&
      departmentCreation.selectedDepartmentsIds.slice(-1)[0] === id
  )((state: StoreState, id: string): string => id);
