import * as React from "react";

import type { Point } from "../../../types";

import * as style from "./ContextMenuWrapper.scss";

import { BodyPortal } from "sber-marketing-ui";

interface Props {
  children: React.ReactNode;
}

export const ContextMenuWrapper = ({ children }: Props): JSX.Element => {
  const [position, setPosition] = React.useState<Point>({ x: 0, y: 0 });

  const root = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const { x, y, width, height } = root.current.getBoundingClientRect();

    setPosition({
      x: x + width - 35,
      y: y + height - 45,
    });
  }, []);

  return (
    <div className={style.root} ref={root}>
      <BodyPortal>
        <div
          className={style.wrapper}
          style={{
            top: position.y,
            left: position.x,
          }}
        >
          {children}
        </div>
      </BodyPortal>
    </div>
  );
};
