import * as React from "react";

import type { TriggersHolder, OperationScheme, Operand } from "./types";

import { Popup } from "./Popup";
import { Calculator } from "./Calculator";

export interface Props {
  loading: boolean;
  triggersHolder: TriggersHolder;
  operands: Operand[];
  onSave: (triggersHolder: TriggersHolder, operands: Operand[]) => void;
  onClose: () => void;
}

export const CalculationLogicPopup: React.FC<Props> = ({
  loading,
  triggersHolder,
  operands,
  onSave,
  onClose,
}) => {
  return (
    <Popup loading={loading}>
      <Calculator
        triggersHolder={triggersHolder}
        operands={operands}
        onSave={onSave}
        onClose={onClose}
      />
    </Popup>
  );
};

export type { OperationScheme };
