// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__hidden{display:none}.src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__root{display:flex;width:100%;height:100%;align-items:center}.src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__valueWrapper{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color:#3b8200;font-size:12px}.src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__iconWrapper{margin-left:12px;cursor:pointer;color:#3b8200;font-size:16px}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/DepartmentRolePage/EditNameInput/EditNameInput.scss"],"names":[],"mappings":"AAYA,qEACI,YAAa,CCXjB,mEACI,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACtB,2EAGG,eAAgB,CAEhB,kBAAmB,CACnB,sBAAuB,CAEvB,aDhBe,CCkBf,cAAe,CAClB,0EAGG,gBAAiB,CAEjB,cAAe,CAEf,aD1Be,CC4Bf,cAAe","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import '../../common/mixins/mixin';\n\n.root {\n    display: flex;\n\n    width: 100%;\n    height: 100%;\n\n    align-items: center;\n}\n\n.valueWrapper {\n    overflow: hidden;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    color: $DeepGreen;\n\n    font-size: 12px;\n}\n\n.iconWrapper {\n    margin-left: 12px;\n\n    cursor: pointer;\n\n    color: $DeepGreen;\n\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__hidden",
	"root": "src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__root",
	"valueWrapper": "src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__valueWrapper",
	"iconWrapper": "src-modules-DepartmentRolePage-EditNameInput-_EditNameInput__iconWrapper"
};
export default ___CSS_LOADER_EXPORT___;
