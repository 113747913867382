import * as React from "react";
import classNames from "classnames";
import * as lodash from "lodash";

import * as style from "./SelectCell.scss";

import {
  Icon,
  IconType,
  Input_redesign as Input,
  InputTheme_redesign as InputTheme,
  CustomScrollbar_new as CustomScrollbar,
} from "sber-marketing-ui";
import { CellEditorWrapper } from "../CellEditorWrapper";

const MAX_SCROLLBAR_HEIGHT = 320;

interface Props {
  title: string;
  items: SelectItem[];
  selectedValue: React.ReactText;
  allowSearch?: boolean;
  onValueChange: (value: React.ReactText) => void;
}

interface SelectItem {
  title: string;
  value: React.ReactText;
}

export const SelectCellEditTemplate = ({
  title,
  items,
  selectedValue,
  allowSearch = false,
  onValueChange,
}: Props): JSX.Element => {
  const [searchString, setSearchString] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [displayTitle, setDisplayTitleStatus] = React.useState(false);

  React.useEffect(() => {
    const newFilteredItems = items.filter((item) =>
      item.title.toLowerCase().includes(searchString.toLowerCase())
    );

    setFilteredItems(newFilteredItems);
  }, [searchString]);

  const onInputBlur = React.useCallback(() => {
    setDisplayTitleStatus(true);
  }, []);

  const itemsToDisplay = allowSearch ? filteredItems : items;

  return (
    <div className={style.root}>
      {(!allowSearch || displayTitle) && (
        <div className={style.title}>{title}</div>
      )}

      {allowSearch && !displayTitle && (
        <div className={style.input}>
          <Input
            value={searchString}
            placeholder={""}
            theme={InputTheme.Borderless}
            onInputChange={(value) => setSearchString(value)}
            onBlur={onInputBlur}
            autofocus
          />
        </div>
      )}

      <div className={classNames(style.openerIcon, style.opened)}>
        <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
      </div>

      <CellEditorWrapper>
        <div className={style.content}>
          {!lodash.isEmpty(itemsToDisplay) && (
            <CustomScrollbar
              maxHeight={MAX_SCROLLBAR_HEIGHT}
              hideScrollX
              freezeScrollX
            >
              {itemsToDisplay.map((item) => (
                <div
                  key={item.value}
                  className={style.item}
                  onClick={() => onValueChange(item.value)}
                >
                  {item.title}

                  {item.value === selectedValue && (
                    <div className={style.checkIcon}>
                      <Icon type={IconType.CHECK_ICON} svgSize={10} />
                    </div>
                  )}
                </div>
              ))}
            </CustomScrollbar>
          )}

          {lodash.isEmpty(itemsToDisplay) && (
            <div className={style.noItemsMessage}>Ничего не найдено</div>
          )}
        </div>
      </CellEditorWrapper>
    </div>
  );
};
