import { isNil } from "lodash";

/** Default minimal length */
export const DEFAULT_MIN_LINE_LENGTH = 3;

/**
 * Returns "true" if string value not "null", not "undefined",
 * and it's length equals "minLength" ("DEFAULT_MIN_LINE_LENGTH" by default)
 * or bigger
 */
export function isNotNilAndLengthFromThree(
  line: string | null | undefined,
  minLength?: number
): boolean {
  const min: number = isNil(minLength) ? DEFAULT_MIN_LINE_LENGTH : minLength;
  return !isNil(line) && line.length >= min;
}

/**
 * Returns "true" if string value or "null", or "undefined",
 * or it's length equals "minLength" ("DEFAULT_MIN_LINE_LENGTH" by default)
 * or bigger
 */
export function isNilOrLengthFromThree(
  line: string | null | undefined,
  minLength?: number
): boolean {
  const min: number = isNil(minLength) ? DEFAULT_MIN_LINE_LENGTH : minLength;
  return isNil(line) || line.length >= min;
}
