import * as React from "react";
import { Tooltip } from "./Tooltip";

interface Props {}
interface State {}

export class TooltipContainer extends React.Component<Props, State> {
  public render() {
    return React.createElement(Tooltip);
  }
}
