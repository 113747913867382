import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import autobind from "autobind-decorator";

import { SwitchType, SwitchItem } from "@sm/types/frontend";
import {
  EditFieldPropertiesParams,
  CalculationLogicPopupParams,
} from "../../../../../store/brief/types";

import { SwitchGroupMenu } from "./SwitchGroupMenu";
import { getFieldById } from "../../../../../store/brief/selector";
import {
  editField,
  setCalculationLogicPopupParams,
} from "../../../../../store/brief/actions";
import { StoreState } from "../../../../../store";

interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  switchType?: SwitchType;
  switches?: SwitchItem[];
  isRequired?: boolean;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
  isFormulaEditable?: boolean;
  isCalculated?: boolean;
  duplicateToReport?: boolean;
  isHasExtraFields?: boolean;
  showSwitchTooltip?: boolean;
  isClientHide?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
  setCalculationLogicPopupParams: (params: CalculationLogicPopupParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SwitchGroupMenuContainer extends React.Component<Props> {
  public render() {
    const {
      name,
      validationMessage,
      tooltipMessage,
      switchType,
      switches = [],
      isRequired,
      isCalculated,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      duplicateToReport,
      isHasExtraFields,
      showSwitchTooltip,
      isClientHide,
    } = this.props;

    return React.createElement(SwitchGroupMenu, {
      name,
      validationMessage,
      tooltipMessage,
      switchType,
      switches,
      isRequired,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      isCalculated,
      duplicateToReport,
      isHasExtraFields,
      showSwitchTooltip,
      isClientHide,
      onNameChange: this.onNameChange,
      onValidationMessageChange: this.onValidationMessageChange,
      onTooltipMessageChange: this.onTooltipMessageChange,
      onTypeChange: this.onTypeChange,
      onAddSwitch: this.addDropdownValue,
      onChangeSwitch: this.changeDropdownValue,
      onRequirementChange: this.onRequirementChange,
      onDuplicateToReportChange: this.onDuplicateToReportChange,
      onCalculatedChange: this.onCalculatedChange,
      onSumButtonClick: this.onSumButtonClick,
      onExtraChange: this.onExtraChange,
      onShowSwitchTooltipChange: this.onShowSwitchTooltipChange,
      onSwitchTooltipChange: this.onSwitchTooltipChange,
      onClientHideCheckboxChange: this.onClientHideCheckboxChange,
    });
  }

  @autobind
  protected onNameChange(value: string) {
    this.props.editField({
      id: this.props.id,
      name: value,
    });
  }

  @autobind
  protected onValidationMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      validationMessage: value,
    });
  }

  @autobind
  protected onTooltipMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      tooltipMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onTypeChange(value: SwitchType) {
    this.props.editField({
      id: this.props.id,
      switchType: value,
    });
  }

  @autobind
  protected onClientHideCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isClientHide: !this.props.isClientHide,
    });
  }

  @autobind
  protected onRequirementChange() {
    this.props.editField({
      id: this.props.id,
      isRequired: !this.props.isRequired,
    });
  }

  @autobind
  protected onDuplicateToReportChange() {
    this.props.editField({
      id: this.props.id,
      duplicateToReport: !this.props.duplicateToReport,
    });
  }

  @autobind
  protected addDropdownValue(value: string) {
    const { switches = [] } = this.props;

    const newContent = [
      ...switches,
      {
        id: uuid(),
        name: value,
      },
    ];

    this.props.editField({
      id: this.props.id,
      switches: newContent,
    });
  }

  @autobind
  protected changeDropdownValue(params: { value: string; id: string }) {
    const { switches = [] } = this.props;

    const newContent =
      params.value == ""
        ? switches.filter((item) => item.id != params.id)
        : switches.map((item) =>
            item.id == params.id
              ? {
                  ...item,
                  id: item.id,
                  name: params.value,
                }
              : item
          );

    this.props.editField({
      id: this.props.id,
      switches: newContent,
    });
  }

  @autobind
  protected onCalculatedChange() {
    this.props.editField({
      id: this.props.id,
      isCalculated: !this.props.isCalculated,
    });
  }

  @autobind
  protected onSumButtonClick(params: { lineId: string }) {
    const { id } = this.props;

    this.props.setCalculationLogicPopupParams({
      visibility: true,
      selectedFieldId: id,
      selectedFieldLineId: params.lineId,
    });
  }

  @autobind
  protected onExtraChange() {
    this.props.editField({
      id: this.props.id,
      isHasExtraFields: !this.props.isHasExtraFields,
    });
  }

  @autobind
  protected onShowSwitchTooltipChange() {
    this.props.editField({
      id: this.props.id,
      showSwitchTooltip: !this.props.showSwitchTooltip,
    });
  }

  @autobind
  protected onSwitchTooltipChange(params: { value: string; id: string }) {
    const { switches = [] } = this.props;

    const newContent = switches.map((item) =>
      item.id == params.id
        ? {
            ...item,
            id: item.id,
            tooltipMessage: params.value,
          }
        : item
    );

    this.props.editField({
      id: this.props.id,
      switches: newContent,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.id);
  const isFormulaEditable = state.brief.isFormulaEditable;
  const isSchemaActive = state.brief.isSchemeActive;
  const isSchemaEditable = state.brief.isSchemeEditable;

  const {
    name,
    validationMessage,
    tooltipMessage,
    switchType,
    switches,
    isRequired,
    isCalculated,
    duplicateToReport,
    isHasExtraFields,
    showSwitchTooltip,
    isClientHide,
    parentFieldId,
  } = field.properties;

  return {
    name,
    validationMessage,
    tooltipMessage,
    switchType,
    switches,
    isRequired,
    isCalculated,
    isSchemaActive,
    isSchemaEditable: isSchemaEditable || (isSchemaActive && !parentFieldId),
    isFormulaEditable,
    duplicateToReport,
    isHasExtraFields,
    showSwitchTooltip,
    isClientHide,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return bindActionCreators(
    {
      editField,
      setCalculationLogicPopupParams,
    },
    dispatch
  );
}
