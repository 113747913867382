export interface TemplateEditPageLoadParams {
  name: StageTemplateName;
  stages: Stage[];
  viewOnly: boolean;
}

export interface TemplateEditPageState {
  name: StageTemplateName;
  stages: Stage[];
  createdStage: Stage;
  viewOnly: boolean;
}

export interface StageTemplateName {
  value: string;
  valid: boolean;
  hasRequestError: boolean;
}

export type Stage = {
  id: string;
  value: string;
  warning: boolean;
  valid: boolean;
};

export interface InputError {
  value: ValueError;
  payload?: string;
}

export const enum ValueError {
  NOT_ENOUGH_CHARACTERS = "NotEnoughCharacters",
  DUPLICATE_VALUE = "DuplicateValue",
}

export interface ChangedStageOrder {
  oldIndex: number;
  newIndex: number;
}
