import * as React from "react";

import * as style from "./RightGroupsEditor.scss";

import type {
  Action,
  BudgetAccessGroup,
  DictionaryType,
} from "../../../../../../api";

import {
  Popup,
  // CustomScrollbar_new as CustomScrollbar,
  Icon,
  IconType,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
} from "sber-marketing-ui";
import { RightGroup } from "./RightGroup";

// const MAX_SCROLLBAR_HEIGHT = 800;

interface Props {
  groups: BudgetAccessGroup[];
  actions: Action[];
  dictionaries: any;
  onAddGroupButtonClick: () => void;
  onConfirm: () => void;
  onClose: () => void;
  onDictionariesChange: (groupId: number, value: string[]) => void;
  onGroupActionsChange: (groupId: number, value: number[]) => void;
  onGroupDictionaryTypeChange: (
    groupId: number,
    dictionaryTypes: string[]
  ) => void;
  onDeleteGroupButtonClick: (groupId: number) => void;
}

export const RightGroupsEditorTemplate = ({
  groups,
  actions,
  dictionaries,
  onAddGroupButtonClick,
  onConfirm,
  onClose,
  onDictionariesChange,
  onGroupActionsChange,
  onGroupDictionaryTypeChange,
  onDeleteGroupButtonClick,
}: Props): JSX.Element => {
  return (
    <Popup onOutOfContentClick={onClose}>
      <div className={style.root}>
        <div className={style.title}>Настройка группы прав</div>

        <div className={style.addGroupButton} onClick={onAddGroupButtonClick}>
          <div className={style.addGroupButtonIcon}>
            <Icon type={IconType.PLUS_20} svgSize={20} />
          </div>
          Новая группа
        </div>

        <div className={style.rightGroups}>
          {/* <CustomScrollbar maxHeight={MAX_SCROLLBAR_HEIGHT} hideScrollX freezeScrollX> */}
          {groups.map((item) => (
            <div className={style.rightGroup} key={item.groupId}>
              <RightGroup
                group={item}
                actions={actions}
                dictionaries={dictionaries}
                canBeDeleted={groups.length > 1}
                onDictionariesChange={onDictionariesChange}
                onGroupActionsChange={onGroupActionsChange}
                onGroupDictionaryTypeChange={onGroupDictionaryTypeChange}
                onDeleteGroupButtonClick={onDeleteGroupButtonClick}
              />
            </div>
          ))}
          {/* </CustomScrollbar> */}
        </div>

        <div className={style.buttons}>
          <div className={style.button}>
            <Button theme={ButtonTheme.GhostRounded} onClick={onClose}>
              Отменить
            </Button>
          </div>

          <div className={style.button}>
            <Button theme={ButtonTheme.MainRounded} onClick={onConfirm}>
              Создать
            </Button>
          </div>
        </div>

        <div className={style.closeButton} onClick={onClose}>
          <Icon type={IconType.CROSS} svgSize={14} />
        </div>
      </div>
    </Popup>
  );
};
