import * as React from "react";
import { FixedSizeList } from "react-window";
import autobind from "autobind-decorator";

import {
  PermissionResponse,
  PermissionSubjectResponse,
  OrganizationView,
  DepartmentAttributes,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

import { Role } from "./Role";

const HEADER_HEIGHT = 49;
const PAGE_PADDING = 40;

interface Props {
  permissions: (PermissionResponse & { id: string })[];
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
  onEditButtonClick: (id: string) => void;
  onRemoveButtonClick: (id: string) => void;
}

interface State {
  windowHeight: number;
}

export class RoleContainer extends React.PureComponent<Props, State> {
  private listRef = React.createRef<FixedSizeList>();

  constructor(props: Props) {
    super(props);

    this.state = {
      windowHeight: 0,
    };
  }

  public componentDidMount() {
    this.setWindowSize();

    window.addEventListener("resize", this.setWindowSize);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.setWindowSize);
  }

  public render() {
    const {
      permissions,
      budgets,
      organizations,
      departments,
      dictionaries,
      permissionSubjects,
      onEditButtonClick,
      onRemoveButtonClick,
    } = this.props;
    const { windowHeight } = this.state;

    return React.createElement(Role, {
      permissions,
      budgets,
      organizations,
      departments,
      dictionaries,
      permissionSubjects,
      tableHeight: windowHeight,
      onEditButtonClick,
      onRemoveButtonClick,
      onTableScroll: this.onTableScroll,
      listRef: this.listRef,
    });
  }

  @autobind
  protected setWindowSize() {
    const size = document.body.getBoundingClientRect();

    this.setState({
      windowHeight: size.height - HEADER_HEIGHT - PAGE_PADDING * 2,
    });
  }

  @autobind
  protected onTableScroll(event: any) {
    const { scrollTop } = event.target;

    this.listRef.current.scrollTo(scrollTop);
  }
}
