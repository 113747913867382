import { RoleAttributes } from "@sm/types/admin";

import { axios } from "../lib/axios";

const ROLE_URL = "/api/role";

export class RoleApi {
  public static async getBusinessRoleList(): Promise<RoleAttributes[]> {
    const res = await axios.get(`${ROLE_URL}/business`);

    return res.data;
  }
}
