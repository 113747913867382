import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DepartmentAttributes } from "@sm/types/admin";
import {
  DepartmentListPageState,
  DepartmentListPageLoadParams,
  DepartmentListPageSetFilterParams,
  DepartmentListPageDepartmentCreationBlockState,
} from "../types";

import { DepartmentCreationBlockReducer } from "./DepartmentCreationBlockReducer";
import {
  loadPageState,
  setDepartmentListFilter,
  updateDepartments,
  addDepartmentForDepartmentCreationBlock,
  updateDepartmentForDepartmentCreationBlock,
  deleteDepartmentForDepartmentCreationBlock,
  setIsNewDepartmentAddedForDepartmentCreationBlock,
  setSelectedDepartmentForDepartmentCreationBlock,
  resetDepartmentCreationBlock,
} from "../actions";

const departments: DepartmentAttributes[] = [];
const DepartmentCreationBlockInitial: DepartmentListPageDepartmentCreationBlockState =
  {
    departments: [],
    selectedDepartmentsIds: [],
    isNewDepartmentAdded: true,
  };

const initialState: DepartmentListPageState = {
  departments,
  selectedFilter: "name",
  departmentCreation: DepartmentCreationBlockInitial,
};

export const departmentListPageReducer = reducerWithInitialState(initialState)
  .case(loadPageState, load)
  .case(setDepartmentListFilter, setFilter)
  .case(updateDepartments, updateDepartmentsReducer)
  .case(
    setSelectedDepartmentForDepartmentCreationBlock,
    DepartmentCreationBlockReducer.setSelectedDepartment
  )
  .case(
    addDepartmentForDepartmentCreationBlock,
    DepartmentCreationBlockReducer.addDepartment
  )
  .case(
    updateDepartmentForDepartmentCreationBlock,
    DepartmentCreationBlockReducer.updateDepartment
  )
  .case(
    deleteDepartmentForDepartmentCreationBlock,
    DepartmentCreationBlockReducer.deleteDepartment
  )
  .case(
    setIsNewDepartmentAddedForDepartmentCreationBlock,
    DepartmentCreationBlockReducer.setIsNewDepartmentAdded
  )
  .case(resetDepartmentCreationBlock, DepartmentCreationBlockReducer.reset);

function load(
  state: DepartmentListPageState,
  payload: DepartmentListPageLoadParams
) {
  return {
    ...state,
    ...payload,
  };
}

function setFilter(
  state: DepartmentListPageState,
  payload: DepartmentListPageSetFilterParams
): DepartmentListPageState {
  return {
    ...state,
    selectedFilter: payload.selectedFilter,
  };
}

function updateDepartmentsReducer(
  state: DepartmentListPageState,
  departments: DepartmentAttributes[]
): DepartmentListPageState {
  return {
    ...state,
    departments,
  };
}
