import * as React from "react";
import { connect } from "react-redux";
import autobind from "autobind-decorator";

import { DepartmentAttributes } from "@sm/types/admin";

import { SelectEventProps } from "@sm/ui";
import { EditSchemeDepartment } from "./EditSchemeDepartment";
import { StoreState } from "../../../store";
import { getExecutorDepartments } from "../../../store/briefSchemeListPage/selector";

interface Props extends MapProps {
  departmentId: string;
  onDepartmentSelect: (id: string) => void;
  onCancelClick: () => void;
}

interface MapProps {
  departments?: DepartmentAttributes[];
}

interface State {
  departmentId: string;
}

@(connect(mapStateToProps, null) as any)
export class EditSchemeDepartmentContainer extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      departmentId: this.props.departmentId || "",
    };
  }

  public render(): JSX.Element {
    const { onCancelClick } = this.props;

    const divisionSelect = {
      value: this.state.departmentId,
      options: this.makeOptionsParams(),
      onChange: this.handleDivisionSelect,
    };

    return React.createElement(EditSchemeDepartment, {
      divisionSelect,
      onCancelClick,
      onConfirmClick: this.handleConfirmClick,
    });
  }

  @autobind
  protected handleDivisionSelect(event: SelectEventProps) {
    this.setState({
      departmentId: event.value as string,
    });
  }

  @autobind
  protected handleConfirmClick() {
    const { departmentId, onCancelClick, onDepartmentSelect } = this.props;
    if (this.state.departmentId != departmentId) {
      onDepartmentSelect(this.state.departmentId);
    } else {
      onCancelClick();
    }
  }

  private makeOptionsParams() {
    const options = this.props.departments.map((department) => ({
      value: department.id,
      label: department.name,
    }));

    return [
      {
        value: 0,
        label: "Общая схема",
      },
      ...options,
    ];
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    departments: getExecutorDepartments(state),
  };
}
