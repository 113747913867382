import * as React from "react";
import * as classNames from "classnames";
import * as hash from "object-hash";

import * as style from "./ContentNav.scss";

const ITEM_HEIGHT = 20;
const ITEM_MARGIN = 12;

interface Props {
  items: NavItem[];
  selectedIndex: number;
  onItemClick: (name: string) => void;
}

export interface NavItem {
  id: string;
  title: string;
  parent: string;
  order: number;
}

function sortItems(itemsRoot: any[], items: any[], level: number): any[] {
  let itemsSorted: any[] = [];

  itemsRoot.forEach((item) => {
    itemsSorted.push({ ...item, level });
    const subItems = sortItems(
      items.filter((subItem) => subItem.parent === item.id) || [],
      items,
      level + 1
    );
    itemsSorted = [...itemsSorted, ...subItems];
  });

  return itemsSorted;
}

export const ContentNav = ({
  items,
  selectedIndex,
  onItemClick,
}: Props): JSX.Element => {
  const itemSorted = sortItems(
    items.filter((item) => !item.parent),
    items,
    0
  );

  const thumbPosition = items
    .slice(0, selectedIndex)
    .reduce((acc) => acc + ITEM_HEIGHT + ITEM_MARGIN, 0);

  return (
    items.length > 1 && (
      <div className={style.root}>
        {itemSorted.map((item, index) => (
          <div
            className={classNames(
              style.item,
              index == selectedIndex && style.selected
            )}
            key={hash(item) + index}
            onClick={() => onItemClick(item.id)}
            style={{ paddingLeft: 7 + item.level * ITEM_MARGIN }}
          >
            <div className={style.title}>{item.title}</div>
          </div>
        ))}

        <div
          className={style.thumb}
          style={{ top: thumbPosition, height: ITEM_HEIGHT }}
        />
      </div>
    )
  );
};
