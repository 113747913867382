import * as React from "react";

import { OperationType } from "../enums";
import { buttonsComponentsMap } from "../utils";
import * as styles from "./styles.scss";

export interface Props {
  operations: OperationType[];
  onButtonClick: (operationType: OperationType) => void;
}

export const ActionSelector: React.FC<Props> = ({
  operations,
  onButtonClick,
}) => {
  return (
    <div className={styles.root}>
      {operations.map((action) =>
        React.createElement(buttonsComponentsMap[action], {
          key: action,
          onClick: () => onButtonClick(action),
        })
      )}
    </div>
  );
};
