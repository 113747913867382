import * as React from "react";

import * as style from "./CheckboxListCell.scss";

import type { CheckboxItem } from "./";

import { Icon, IconType } from "sber-marketing-ui";

interface Props {
  entityName: string;
  items: CheckboxItem[];
  selectedValues: React.ReactText[];
}

export const CheckboxListCellTemplate = ({
  entityName,
  items = [],
  selectedValues,
}: Props): JSX.Element => {
  const [] = selectedValues.map((item) => selectedValues);

  return (
    <div className={style.root}>
      <div className={style.title}>
        {makeTitle(entityName, items, selectedValues)}
      </div>

      <div className={style.openerIcon}>
        <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
      </div>
    </div>
  );
};

function makeTitle(
  entityName: string,
  items: CheckboxItem[],
  selectedValues: React.ReactText[]
) {
  if (selectedValues.length == 0) {
    return "—";
  }

  const selectedItems = items.filter((item) =>
    selectedValues.includes(item.value)
  );

  if (selectedItems.length == 1 || selectedItems.length == 2) {
    return (
      <>
        <div className={style.titleItem}>{selectedItems[0].title}</div>

        {selectedItems.length == 2 && (
          <div className={style.titleItem}>{selectedItems[1].title}</div>
        )}
      </>
    );
  }

  return (
    <div className={style.itemsCount}>
      {entityName}: {selectedItems.length}
    </div>
  );
}
