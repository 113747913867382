import * as React from "react";

import * as style from "./CustomDragLayer.scss";

import { ItemType, BLOCK_PARENT_DRAG } from "../ItemType";

import { ElementDragPreview } from "../ElementPreview";
import { BlockTitleDragPreview } from "../Schema/Elements/Block/BlockTitleDrag";

interface Props extends DNDProps {
  elementStyle: object;
  itemType?: string;
  item?: any;
}

interface DNDProps {
  isDragging?: boolean;
}

export function CustomDragLayer({
  isDragging,
  itemType,
  item,
  elementStyle,
  ...props
}: Props): JSX.Element {
  if (!isDragging) {
    return null;
  }

  return (
    <div className={style.root}>
      <div style={elementStyle}>
        {itemType !== ItemType.BLOCK_SORT && itemType === BLOCK_PARENT_DRAG ? (
          <BlockTitleDragPreview content={item.content} />
        ) : (
          <ElementDragPreview itemType={itemType} />
        )}
      </div>
    </div>
  );
}
