import * as React from "react";

import * as style from "./SwitchGroupFields.scss";

import { FieldParams } from "../../../../../../../store/brief/types";
import { AddFieldTarget } from "../../../../ElementsList/AddFieldTarget";
import { Field } from "../../../Fields";

interface Props {
  switchPropertyId: string;
  blockId: string;
  order: number;
  isPreviewModeEnabled?: boolean;
  isChecked?: boolean;
  isNotRequired?: boolean;
  fields: FieldParams[];
  removeField: (fieldId: string) => Promise<void>;
}

export function SwitchGroupFields({
  isChecked,
  isPreviewModeEnabled,
  switchPropertyId,
  blockId,
  order,
  fields,
  removeField,
}: Props): JSX.Element {
  return !isPreviewModeEnabled || isChecked ? (
    <div className={style.extraFields}>
      <AddFieldTarget
        blockId={blockId}
        switchPropertyId={switchPropertyId}
        order={order}
      />

      <div className={style.fields}>
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <div className={style.field}>
              <Field
                id={item.id}
                blockId={blockId}
                order={order}
                fieldType={item.type}
                onRemove={removeField}
              />
            </div>

            <AddFieldTarget
              blockId={blockId}
              switchPropertyId={switchPropertyId}
              order={item.order + 1}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : null;
}
