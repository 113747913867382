import * as lodash from "lodash";
import { item } from "sber-marketing-ui/src/components/ContentNav/ContentNav.scss";
import { v4 as uuid } from "uuid";

import {
  BriefState,
  FieldType,
  BlockParams,
  FieldParams,
  EditBlockParams,
  UpdateParentBlockParams,
  ChangeBlockOrderParams,
  ChangeFieldOrderParams,
  AddBlockParams,
  AddFieldParams,
  RemoveFieldParams,
  ChangeSelectedElementParams,
  EditFieldPropertiesParams,
  ElementPropertiesParams,
  ChangeBriefNameParams,
  UpdateBriefPageParams,
  RemoveFieldsByTypeParams,
  CalculationLogicPopupParams,
} from "../types";

import { updateFormulasOperandIds } from "./utils";

interface BriefElement {
  briefBlockId?: string;
  order: number;
}

export class BriefReducer {
  public static addBlock(
    state: BriefState,
    { schemaId, order, briefBlockId }: AddBlockParams
  ): BriefState {
    const id = uuid();
    const blockParams: BlockParams = {
      schemaId,
      briefBlockId,
      order,
      id,
      name: "Новый блок",
      properties: {},
      fields: [],
      isNew: true,
      isRequired: true,
    };

    let blocks = state.elements;

    if (!briefBlockId) {
      if (blocks.filter((item) => !item.briefBlockId).length + 1 != order) {
        blocks = blocks.map((item) =>
          !item.briefBlockId && item.order >= order
            ? { ...item, order: item.order + 1 }
            : item
        );
      }
    } else {
      blocks = blocks.map((item) =>
        item.briefBlockId === briefBlockId &&
        item.id !== id &&
        item.order >= order
          ? { ...item, order: item.order + 1 }
          : item
      );
    }

    const elements = [...blocks, blockParams];

    return {
      ...state,
      elements,
    };
  }

  public static editBlock(
    state: BriefState,
    blockParams: EditBlockParams
  ): BriefState {
    const elements = state.elements.map((item) =>
      item.id == blockParams.id ? { ...item, ...blockParams } : item
    );

    return {
      ...state,
      elements,
    };
  }

  public static updateParentBlock(
    state: BriefState,
    blockParams: UpdateParentBlockParams
  ): BriefState {
    const elements = state.elements.map((item) =>
      item.id == blockParams.id ? { ...item, ...blockParams } : item
    );

    return {
      ...state,
      elements,
    };
  }

  public static rerollElementIds(state: BriefState): BriefState {
    const isSchemeActive = state.isSchemeActive;
    const changedBlockIds: { [key in string]: string } = {};
    const changedIds: { [key in string]: string } = {};

    const updatedBlocksWithoutUpdatedFormulas: BlockParams[] =
      state.elements.map((block) => {
        const newBlockId = uuid();
        changedBlockIds[block.id] = newBlockId;
        return {
          ...block,
          id: newBlockId,
          fields: block.fields.map((field) => {
            const newFieldId = uuid();
            changedIds[field.id] = newFieldId;

            return {
              ...field,
              id: newFieldId,
            };
          }),
        };
      });

    const updatedBlocksWithUpdatedFormulas: BlockParams[] =
      updatedBlocksWithoutUpdatedFormulas.map((block) => {
        return {
          ...block,
          briefBlockId: changedBlockIds[block.briefBlockId],
          fields: block.fields.map((field) => {
            switch (field.type) {
              case FieldType.DROPDOWN:
                return {
                  ...field,
                  properties: field.properties?.selectContent?.length
                    ? {
                        ...field.properties,
                        selectContent: field.properties.selectContent.map(
                          (select) =>
                            select?.formulas?.length
                              ? {
                                  ...select,
                                  formulas: updateFormulasOperandIds({
                                    formulas: select.formulas,
                                    changedIdsMap: changedIds,
                                  }),
                                }
                              : select
                        ),
                      }
                    : field.properties,
                };
              case FieldType.TEXT:
                return {
                  ...field,
                  properties: field.properties?.formulas?.length
                    ? {
                        ...field.properties,
                        formulas: updateFormulasOperandIds({
                          formulas: field.properties.formulas,
                          changedIdsMap: changedIds,
                        }),
                      }
                    : field.properties,
                };
              case FieldType.UNLOCKABLE_INPUT:
                return {
                  ...field,
                  properties: field.properties?.formulas?.length
                    ? {
                        ...field.properties,
                        formulas: updateFormulasOperandIds({
                          formulas: field.properties.formulas,
                          changedIdsMap: changedIds,
                        }),
                      }
                    : field.properties,
                };
              case FieldType.SWITCH_GROUP:
                return {
                  ...field,
                  properties: field.properties?.switches?.length
                    ? {
                        ...field.properties,
                        switches: field.properties.switches.map((switchItem) =>
                          switchItem?.formulas?.length
                            ? {
                                ...switchItem,
                                formulas: updateFormulasOperandIds({
                                  formulas: switchItem.formulas,
                                  changedIdsMap: changedIds,
                                }),
                              }
                            : switchItem
                        ),
                      }
                    : field.properties,
                };
              default:
                return field;
            }
          }),
        };
      });

    return { ...state, elements: updatedBlocksWithUpdatedFormulas };
  }

  public static changeBlockOrder(
    state: BriefState,
    blockParams: ChangeBlockOrderParams
  ): BriefState {
    const elements: BlockParams[] = state.elements.map((item) =>
      item.id == blockParams.id
        ? { ...item, order: blockParams.newOrder }
        : (
            blockParams.briefBlockId
              ? item.briefBlockId === blockParams.briefBlockId
              : !item.briefBlockId
          )
        ? BriefReducer.changeOrder(
            item,
            blockParams.oldOrder,
            blockParams.newOrder
          )
        : item
    );

    return {
      ...state,
      elements,
    };
  }

  public static changeFieldOrder(
    state: BriefState,
    fieldParams: ChangeFieldOrderParams
  ): BriefState {
    const elements = state.elements.map((block: BlockParams): BlockParams => {
      if (block.id === fieldParams.blockId) {
        const fields = block.fields.map((field: FieldParams): FieldParams => {
          return field.id === fieldParams.id
            ? {
                ...field,
                order: fieldParams.newOrder,
              }
            : BriefReducer.changeOrder(
                field,
                fieldParams.oldOrder,
                fieldParams.newOrder
              );
        });

        return {
          ...block,
          fields,
        };
      }

      return block;
    });

    return {
      ...state,
      elements,
    };
  }

  public static normalizeElementsOrders(state: BriefState): BriefState {
    const blocks = state.elements;

    const blocksWithNormalizedFields = blocks.map((item) => ({
      ...item,
      fields: BriefReducer.normalizeElementsOrder(item.fields),
    }));

    const normalizedBlocks = BriefReducer.normalizeElementsOrder(
      blocksWithNormalizedFields
    );

    return {
      ...state,
      elements: normalizedBlocks,
    };
  }

  public static addField(
    state: BriefState,
    { blockId, ...field }: AddFieldParams
  ): BriefState {
    const newField: FieldParams = {
      ...field,
      blockId,
      isNew: true,
      id: uuid(),
    };

    if (!newField.properties) {
      newField.properties = {};
    }

    const { fields } = state.elements.find((block) => block.id == blockId);

    if (field.type === FieldType.TOGGLE) {
      const blocks = state.elements.map((block) =>
        block.id == blockId
          ? { ...block, fields: [...fields, newField] }
          : block
      );

      return {
        ...state,
        elements: blocks,
      };
    }

    const fieldsBeforeNewField = fields.slice(0, newField.order - 1);
    const fieldsAfterNewField = fields
      .slice(newField.order - 1)
      .map((item) => ({ ...item, order: item.order + 1 }));

    const blocks = state.elements.map((block) =>
      block.id == blockId
        ? {
            ...block,
            fields: [...fieldsBeforeNewField, newField, ...fieldsAfterNewField],
          }
        : block
    );

    return {
      ...state,
      elements: blocks,
    };
  }

  public static removeBlock(state: BriefState, blockId: string): BriefState {
    const elements = state.elements.filter((item) => item.id != blockId);

    return {
      ...state,
      elements,
    };
  }

  public static removeField(
    state: BriefState,
    { blockId, fieldId }: RemoveFieldParams
  ): BriefState {
    const { fields } = state.elements.find((block) => block.id == blockId);

    const newFields = fields.filter((field) => field.id != fieldId);

    newFields.forEach((item, index) => (item.order = index + 1));

    const elements = state.elements.map((item) =>
      item.id == blockId ? { ...item, fields: newFields } : item
    );

    return {
      ...state,
      elements,
    };
  }

  public static removeFieldsByType(
    state: BriefState,
    { blockId, fieldType }: RemoveFieldsByTypeParams
  ): BriefState {
    const { fields } = state.elements.find((block) => block.id == blockId);

    const newFields = fields.filter((field) => field.type != fieldType);

    newFields.forEach((item, index) => (item.order = index + 1));

    const elements = state.elements.map((item) =>
      item.id == blockId ? { ...item, fields: newFields } : item
    );

    return {
      ...state,
      elements,
    };
  }

  public static setSelectedElement(
    state: BriefState,
    params: ChangeSelectedElementParams
  ): BriefState {
    const {
      selectedBlockId,
      selectedElementId,
      selectedItemType,
      selectedFieldItemId,
    } = params;

    return {
      ...state,
      selectedBlockId: selectedElementId ? null : selectedBlockId,
      selectedElementId: selectedBlockId ? null : selectedElementId,
      selectedItemType,
      selectedFieldItemId: selectedFieldItemId || null,
    } as BriefState;
  }

  public static setVisibilityCalculationLogicPopup(
    state: BriefState,
    params: boolean
  ): BriefState {
    return {
      ...state,
      calculationLogicPopup: {
        ...state.calculationLogicPopup,
        visibility: params,
      },
    };
  }

  public static setCalculationLogicPopupParams(
    state: BriefState,
    params: CalculationLogicPopupParams
  ): BriefState {
    return {
      ...state,
      calculationLogicPopup: params,
    };
  }

  public static editField(
    state: BriefState,
    { id, ...properties }: EditFieldPropertiesParams
  ): BriefState {
    const elements = state.elements.map((block) => {
      const field = block.fields.find((field) => field.id == id);

      return field
        ? {
            ...block,
            fields: BriefReducer.makeFieldProperties(
              block.fields,
              id,
              properties as any
            ),
          }
        : block;
    });

    return {
      ...state,
      elements,
    };
  }

  public static togglePreviewMode(state: BriefState): BriefState {
    return {
      ...state,
      isPreviewModeEnabled: !state.isPreviewModeEnabled,
      selectedElementId: null,
      selectedBlockId: null,
      selectedItemType: null,
    };
  }

  public static changeBriefName(
    state: BriefState,
    params: ChangeBriefNameParams
  ): BriefState {
    return {
      ...state,
      name: params.newName,
    };
  }

  public static updateBriefPage(
    state: BriefState,
    params: UpdateBriefPageParams
  ): BriefState {
    return {
      ...state,
      ...params,
    };
  }

  public static resetBriefPage(state: BriefState): BriefState {
    return {
      name: "",
      briefSchemeId: null,
      elements: [],
      selectedElementId: null,
      selectedBlockId: null,
      selectedFieldItemId: null,
      selectedItemType: null,
      isPreviewModeEnabled: false,
      isSchemeEditable: true,
      isFormulaEditable: true,
      showValidationErrors: false,
      calculationLogicPopup: {
        visibility: false,
        selectedFieldId: null,
        selectedFieldLineId: null,
      },
    };
  }

  public static showValidationErrors(state: BriefState): BriefState {
    const result = lodash.cloneDeep(state);
    result.showValidationErrors = true;
    return result;
  }

  public static hideValidationErrors(state: BriefState): BriefState {
    const result = lodash.cloneDeep(state);
    result.showValidationErrors = false;
    return result;
  }

  private static makeFieldProperties(
    fields: FieldParams[],
    fieldId: string,
    properties: ElementPropertiesParams
  ): FieldParams[] {
    return fields.map((item) => {
      let result = item;

      if (item.id === fieldId) {
        result = {
          ...item,
          properties: {
            ...item.properties,
            ...properties,
          },
        };
      }

      return result;
    });
  }

  private static changeOrder(
    currentItem: { order: number },
    oldOrder: number,
    newOrder: number
  ): any {
    let block;
    const { order } = currentItem;

    if (oldOrder < newOrder && order > oldOrder && order <= newOrder) {
      block = { ...currentItem, order: order - 1 };
    } else if (oldOrder > newOrder && order >= newOrder && order < oldOrder) {
      block = { ...currentItem, order: order + 1 };
    } else {
      block = { ...currentItem };
    }

    return block;
  }

  private static normalizeElementsOrder<T extends BriefElement>(
    elements: T[]
  ): T[] {
    const sortedElements = lodash.sortBy(elements, ["briefBlockId", "order"]);
    const orders = {
      root: 0,
    };

    const normalizedElements = sortedElements.map((item) => {
      const orderKey = item.briefBlockId || "root";
      orders[orderKey] = (orders[orderKey] || 0) + 1;
      return {
        ...(item as any),
        order: orders[orderKey],
      };
    });

    return normalizedElements;
  }
}
