import * as React from "react";
import * as lodash from "lodash";
import { bindActionCreators } from "redux";
import autobind from "autobind-decorator";
import { connect, Dispatch } from "react-redux";
import { cloneDeep, compact, flatMap, keyBy, uniqBy, values } from "lodash";
import {
  CalculatorDictionaryType,
  BudgetItemDictionaryType,
} from "@sm/types/frontend";

import { StoreState } from "../../../store";
import {
  CalculationLogicPopupParams,
  EditFieldPropertiesParams,
  FieldParams,
  FieldType,
} from "../../../store/brief/types";
import {
  Formula,
  FunctionExpression,
  OperandScheme,
  OperationScheme,
  SelectItem,
  SwitchItem,
  Trigger,
  TriggersHolder,
  Dictionary,
} from "./types";
import { operationByOperationType, operationByPresetFunction } from "./utils";
import {
  DictionaryStatus,
  ExpressionType,
  OperationType,
  PresetFunction,
  Type,
} from "./enums";
import { blankSchema } from "./data";

import {
  editField,
  setVisibilityCalculationLogicPopup,
} from "../../../store/brief/actions";
import {
  getCalculationLogicPopupParams,
  getFieldById,
  getSchemeFields,
} from "../../../store/brief/selector";

import { CalculationLogicPopup } from "./CalculationLogicPopup";
import { Operand } from "./FormulasBuilder";

import { DictionaryApi } from "../../../api";

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
  calculationLogicPopup: CalculationLogicPopupParams;
  field: FieldParams;
  fields: FieldParams[];
}

interface State {
  dictionaries: Dictionary[];
  loading: boolean;
}

interface DispatchProps {
  setVisibilityCalculationLogicPopup: (visibility: boolean) => void;
  editField: (filed: EditFieldPropertiesParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CalculationLogicPopupContainer extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dictionaries: [],
      loading: true,
    };
  }

  public async componentDidMount() {
    const dictionaries = (
      await DictionaryApi.getDictionaryList({
        types: [
          CalculatorDictionaryType.CALC_GROUP,
          CalculatorDictionaryType.CALC_MATERIAL,
          CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY,
          CalculatorDictionaryType.CALC_MATERIAL_RANGE,
          CalculatorDictionaryType.CALC_MATERIAL_TOTAL,
        ],
      })
    ).filter((dictionary) => dictionary.status !== DictionaryStatus.DELETED);
    const groupedDictionaries = lodash.groupBy(
      dictionaries,
      (dictionary) => dictionary.id
    );

    const dictionariesWithCost = (
      await DictionaryApi.getDataList(
        dictionaries.map((dictionary) => dictionary.id)
      )
    ).map((data) => {
      const dictionary = groupedDictionaries[data.dictionaryId][0];

      return {
        ...dictionary,
        data: data?.data,
        cost: data?.data?.cost || null,
      };
    });

    this.setState({
      dictionaries: dictionariesWithCost,
      loading: false,
    });
  }

  public render() {
    const { loading } = this.state;

    return React.createElement(CalculationLogicPopup, {
      loading,
      triggersHolder: this.buildTriggersHolder(),
      operands: this.buildOperands(),
      onSave: this.onSave,
      onClose: this.onClose,
    });
  }

  @autobind
  private onSave(triggersHolder: TriggersHolder, operands: Operand[]): void {
    const { field } = this.props;

    switch (field.type) {
      case FieldType.DROPDOWN:
        this.saveFieldOfDropdown(triggersHolder, operands);
        break;
      case FieldType.SWITCH_GROUP:
        this.saveFieldOfSwitchGroup(triggersHolder, operands);
        break;
      case FieldType.UNLOCKABLE_INPUT:
        this.saveFieldOfUnlockableInput(triggersHolder, operands);
        break;
      case FieldType.TEXT:
        this.saveFieldOfText(triggersHolder, operands);
        break;
    }
  }

  private saveFieldOfDropdown(
    triggersHolder: TriggersHolder,
    operands: Operand[]
  ): void {
    const { field, editField } = this.props;
    const triggers = values(triggersHolder.triggers);

    const selectContent = field.properties.selectContent;

    const updatedSelectedContent = selectContent.map((selectContent) => {
      const trigger = triggers.find(
        (trigger) => selectContent.id === trigger.id
      );

      return trigger?.schemas.length !== 0
        ? {
            ...selectContent,
            formulas: compact(
              trigger.schemas.map((schema, index) => {
                const currentFormula = selectContent?.formulas?.length
                  ? selectContent.formulas[index]
                  : null;

                const schemaHaveEmptyOperands =
                  this.isSchemaHaveEmptyOperands(schema);
                const currentSchemaHaveFormula = Boolean(currentFormula);

                if (schemaHaveEmptyOperands && currentSchemaHaveFormula) {
                  return currentFormula;
                }

                if (schemaHaveEmptyOperands && !currentSchemaHaveFormula) {
                  return null;
                }

                return this.convertFromOperationSchemeToFormula(
                  schema,
                  operands
                );
              })
            ),
          }
        : { ...selectContent };
    });

    editField({ id: field.id, selectContent: updatedSelectedContent });
  }

  private saveFieldOfSwitchGroup(
    triggersHolder: TriggersHolder,
    operands: Operand[]
  ): void {
    const { field, editField } = this.props;
    const triggers = values(triggersHolder.triggers);

    const switches = field.properties.switches;

    const updatedSwitches = switches.map((switchItem) => {
      const trigger = triggers.find((trigger) => switchItem.id === trigger.id);

      return trigger?.schemas.length !== 0
        ? {
            ...switchItem,
            formulas: compact(
              trigger.schemas.map((schema, index) => {
                const currentFormula = switchItem?.formulas?.length
                  ? switchItem.formulas[index]
                  : null;

                const schemaHaveEmptyOperands =
                  this.isSchemaHaveEmptyOperands(schema);
                const currentSchemaHaveFormula = Boolean(currentFormula);

                if (schemaHaveEmptyOperands && currentSchemaHaveFormula) {
                  return currentFormula;
                }

                if (schemaHaveEmptyOperands && !currentSchemaHaveFormula) {
                  return null;
                }

                return this.convertFromOperationSchemeToFormula(
                  schema,
                  operands
                );
              })
            ),
          }
        : { ...switchItem };
    });

    editField({ id: field.id, switches: updatedSwitches });
  }

  private saveFieldOfUnlockableInput(
    triggersHolder: TriggersHolder,
    operands: Operand[]
  ): void {
    const { field, editField } = this.props;
    const triggers = values(triggersHolder.triggers);

    const formulas = flatMap(
      triggers.map((trigger) =>
        compact(
          trigger.schemas.map((schema, index) => {
            const currentFormula = field?.formulas?.length
              ? field.formulas[index]
              : null;

            const schemaHaveEmptyOperands =
              this.isSchemaHaveEmptyOperands(schema);
            const currentFormulaHaveFormula = Boolean(currentFormula);

            if (schemaHaveEmptyOperands && currentFormulaHaveFormula) {
              return currentFormula;
            }

            if (schemaHaveEmptyOperands && !currentFormulaHaveFormula) {
              return null;
            }

            return this.convertFromOperationSchemeToFormula(schema, operands);
          })
        )
      )
    ) as Formula[];

    editField({ id: field.id, formulas });
  }

  private saveFieldOfText(
    triggersHolder: TriggersHolder,
    operands: Operand[]
  ): void {
    const { field, editField } = this.props;
    const triggers = values(triggersHolder.triggers);

    const formulas = flatMap(
      triggers.map((trigger) =>
        compact(
          trigger.schemas.map((schema, index) => {
            const currentFormula = field?.formulas?.length
              ? field.formulas[index]
              : null;

            const schemaHaveEmptyOperands =
              this.isSchemaHaveEmptyOperands(schema);
            const currentFormulaHaveFormula = Boolean(currentFormula);

            if (schemaHaveEmptyOperands && currentFormulaHaveFormula) {
              return currentFormula;
            }

            if (schemaHaveEmptyOperands && !currentFormulaHaveFormula) {
              return null;
            }

            return this.convertFromOperationSchemeToFormula(schema, operands);
          })
        )
      )
    ) as Formula[];

    editField({ id: field.id, formulas });
  }

  @autobind
  private onClose(): void {
    this.props.setVisibilityCalculationLogicPopup(false);
  }

  private buildTriggersHolder(): TriggersHolder {
    const { field } = this.props;
    const { calculationLogicPopup } = this.props;

    return {
      id: field.id,
      name:
        field.type === FieldType.DROPDOWN ||
        field.type === FieldType.SWITCH_GROUP
          ? field?.properties?.name || "Название отсутствует"
          : "",
      selectedTriggerId:
        calculationLogicPopup?.selectedFieldLineId ||
        calculationLogicPopup?.selectedFieldId ||
        null,
      triggers: keyBy(this.buildTriggersFromField(field), "id"),
    };
  }

  private buildTriggersFromField(field: FieldParams): Trigger[] {
    switch (field.type) {
      case FieldType.DROPDOWN:
        return this.buildTriggersFromDropdownProperties(
          field.properties.selectContent
        );
      case FieldType.SWITCH_GROUP:
        return this.buildTriggersFromSwitchGroupProperties(
          field.properties.switches
        );
      case FieldType.UNLOCKABLE_INPUT:
        return this.buildTriggersFromUnlockableInputField(field);
      case FieldType.TEXT:
        return this.buildTriggersFromTextField(field);
    }
  }

  private buildTriggersFromDropdownProperties(
    selectContent: SelectItem[]
  ): Trigger[] {
    return selectContent.map(({ id, name, formulas }) => ({
      id,
      name: name || "Название не задано",
      schemas: formulas?.length
        ? formulas.map((formula) =>
            this.convertFromFormulaToOperationScheme(
              formula as FunctionExpression
            )
          )
        : [cloneDeep(blankSchema)],
    }));
  }

  private buildTriggersFromSwitchGroupProperties(
    switches: SwitchItem[]
  ): Trigger[] {
    return switches.map(({ id, name, formulas }) => ({
      id,
      name: name || "Название не задано",
      schemas: formulas?.length
        ? formulas.map((formula) =>
            this.convertFromFormulaToOperationScheme(
              formula as FunctionExpression
            )
          )
        : [cloneDeep(blankSchema)],
    }));
  }

  private buildTriggersFromUnlockableInputField(field: FieldParams): Trigger[] {
    return [
      {
        id: field.id || "some_id",
        name: field.properties.name || "Название не задано",
        schemas: field.properties?.formulas?.length
          ? field.properties.formulas.map((formula) =>
              this.convertFromFormulaToOperationScheme(
                formula as FunctionExpression
              )
            )
          : [cloneDeep(blankSchema)],
      },
    ];
  }

  private buildTriggersFromTextField(field: FieldParams): Trigger[] {
    return [
      {
        id: field.id || "some_id",
        name: field?.properties?.name || "Название не задано",
        schemas: field.properties?.formulas?.length
          ? field.properties.formulas.map((formula) =>
              this.convertFromFormulaToOperationScheme(
                formula as FunctionExpression
              )
            )
          : [
              {
                type: Type.OPERATION,
                operation: OperationType.MULTIPLICATION,
                operands: [
                  {
                    type: Type.OPERAND,
                    selectedItemId: field.id,
                  },
                  {
                    type: Type.OPERAND,
                    selectedItemId: "",
                  },
                ],
              },
            ],
      },
    ];
  }

  private buildOperands(): Operand[] {
    const { field } = this.props;

    return uniqBy(
      [
        ...this.buildOperandsFromFields(),
        ...this.buildOperandsFromDictionaries(),
        ...this.buildOperandsFromFormulas(this.getFormulasFromBrief(field)),
      ],
      (operand) => operand.id
    );
  }

  private getFormulasFromBrief(field: FieldParams): FunctionExpression[] {
    switch (field.type) {
      case FieldType.DROPDOWN:
        return this.getFormulasFromDropdownBrief(field);
      case FieldType.SWITCH_GROUP:
        return this.getFormulasFromSwitchGroupBrief(field);
      case FieldType.UNLOCKABLE_INPUT:
        return this.getFormulasFromUnlockableInputBrief(field);
      default:
        return this.getFormulasFromTextInputBrief(field);
    }
  }

  private getFormulasFromDropdownBrief(
    field: FieldParams
  ): FunctionExpression[] {
    return compact(
      flatMap(
        field?.properties?.selectContent?.map(
          (selectContent) => selectContent?.formulas
        )
      )
    );
  }

  private getFormulasFromSwitchGroupBrief(
    field: FieldParams
  ): FunctionExpression[] {
    return compact(
      flatMap(
        field?.properties?.switches?.map((switcItem) => switcItem?.formulas)
      )
    );
  }

  private getFormulasFromUnlockableInputBrief(
    field: FieldParams
  ): FunctionExpression[] {
    return compact(field?.properties?.formulas);
  }

  private getFormulasFromTextInputBrief(
    field: FieldParams
  ): FunctionExpression[] {
    return compact(field?.properties?.formulas);
  }

  private buildOperandsFromFields(): Operand[] {
    const { fields } = this.props;

    const filedForCalculator = fields.filter((field) => {
      const isTextType = field.type === FieldType.TEXT;
      const isNumeric = field.properties?.isNumeric || false;
      const isCalculated = field.properties?.isCalculated || false;

      return isTextType && isNumeric && isCalculated;
    });

    return filedForCalculator.map((field) => ({
      id: field.id,
      title: field.properties?.name || "Значение не указано",
      description: "Поле брифа",
      meta: {
        type: "brief_field",
        value: field.id,
        order: 2,
      },
    }));
  }

  private buildOperandsFromDictionaries(): Operand[] {
    const { dictionaries } = this.state;

    const dictionaryCalcMaterialTypes = [
      CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY,
      CalculatorDictionaryType.CALC_MATERIAL_RANGE,
      CalculatorDictionaryType.CALC_MATERIAL_TOTAL,
    ];
    const dictionaryTypes = [
      ...dictionaryCalcMaterialTypes,
      CalculatorDictionaryType.CALC_GROUP,
    ];

    return dictionaries.map((dictionary) => {
      const res: Operand = {
        id: dictionary.id,
        title: dictionary.value || "Значение не указано",
        description: dictionaryTypes.includes(dictionary.type)
          ? "Справочник"
          : `${dictionary.cost || "Цена не указана"}`,
        meta: {
          type: "expense_item",
          value: dictionary.id,
          order: 3,
        },
      };

      if (dictionaryCalcMaterialTypes.includes(dictionary.type)) {
        switch (dictionary.type) {
          case CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY:
            res.meta.type = "calc_material_conformity";
            res.meta.childrenItems = dictionary.data?.conformities?.map(
              (conformity) => ({
                id: conformity.id,
                title: conformity.name,
                parentItemId: dictionary.id,
                meta: {
                  type: "calc_material_conformity",
                  addParentSelection: true,
                },
              })
            );

            break;
          case CalculatorDictionaryType.CALC_MATERIAL_TOTAL:
            res.meta.type = "calc_material_total";
            res.meta.childrenItems = dictionary.data?.totals?.map((total) => ({
              id: total.id,
              title: total.name,
              parentItemId: dictionary.id,
              meta: {
                type: "calc_material_total",
                addParentSelection: true,
              },
            }));

            break;
          default:
            break;
        }
      } else {
        res.meta.childrenItems = dictionary?.children?.map(
          (childDictionary) => ({
            id: childDictionary.id,
            title:
              dictionaries.find(
                (dictionary) => dictionary.id === childDictionary.id
              )?.value || "Значение не найдено",
            parentItemId: dictionary.id,
            meta: {
              type: "expense_item",
            },
          })
        );
      }

      return res;
    });
  }

  private buildOperandsFromFormulas(formulas: FunctionExpression[]): Operand[] {
    return compact(
      flatMap(
        formulas.map((formula) =>
          this.buildOperandFromOperationFormula(formula as FunctionExpression)
        )
      )
    );
  }

  private buildOperandFromOperationFormula(
    formula: FunctionExpression
  ): Operand[] {
    return flatMap(
      formula.operands.map((operand) =>
        operand.type === ExpressionType.Func
          ? this.buildOperandFromOperationFormula(operand as FunctionExpression)
          : [this.buildOperandFromFormula(operand as Formula)]
      )
    );
  }

  private buildOperandFromFormula(formula: Formula): Operand {
    if (formula.type === "const") {
      return {
        id: String(formula?.value),
        title: formula?.value || "не указанно",
        description: "Числовое значение",
        meta: {
          type: "const",
          value: String(formula?.value),
          order: 1,
        },
      };
    }

    return null;
  }

  private convertFromOperationSchemeToFormula(
    schema: OperationScheme,
    operands: Operand[]
  ): FunctionExpression {
    return {
      func: operationByOperationType[schema.operation] as PresetFunction,
      type: ExpressionType.Func,
      operands: schema.operands.map((operand) => {
        return operand.type === Type.OPERATION
          ? this.convertFromOperationSchemeToFormula(
              operand as OperationScheme,
              operands
            )
          : this.convertFromOperandSchemeToFormula(
              operand as OperandScheme,
              operands
            );
      }),
    };
  }

  private convertFromOperandSchemeToFormula(
    schema: OperandScheme,
    operands: Operand[]
  ): Formula {
    const idToFetch = schema.parentSelectedItemId || schema.selectedItemId;
    const operand = operands.find(({ id }) => id === idToFetch);

    switch (operand.meta.type) {
      case "calc_material_conformity":
      case "calc_material_total":
        return {
          type: "expense_item",
          dictionaryId: schema.parentSelectedItemId,
          dictionaryPropertyId: schema.selectedItemId,
        } as Formula;
      case "expense_item":
        return {
          type: "expense_item",
          dictionaryId: operand.meta.value,
        } as Formula;
      case "brief_field":
        return {
          type: "brief_field",
          fieldId: operand.meta.value,
        } as Formula;
      default:
        return {
          type: "const",
          value: Number(operand.meta.value),
        } as Formula;
    }
  }

  private convertFromFormulaToOperationScheme(
    formula: FunctionExpression
  ): OperationScheme {
    return {
      type: Type.OPERATION,
      operation: operationByPresetFunction[formula.func] as OperationType,
      operands: formula.operands.map((operand) => {
        return operand.type === ExpressionType.Func
          ? this.convertFromFormulaToOperationScheme(
              operand as FunctionExpression
            )
          : this.convertFromFormulaToOperandScheme(operand as Formula);
      }),
    };
  }

  private convertFromFormulaToOperandScheme(formula: Formula): OperandScheme {
    const res: OperandScheme = {
      type: Type.OPERAND,
      selectedItemId: String(
        formula.dictionaryId || formula.fieldId || formula.value
      ),
    };

    if (formula.dictionaryPropertyId) {
      const selectedItemId = res.selectedItemId;
      res.selectedItemId = formula.dictionaryPropertyId;
      res.parentSelectedItemId = selectedItemId;
    }

    return res;
  }

  private isSchemaHaveEmptyOperands(schema: OperationScheme): boolean {
    return schema.operands
      .map((operand) =>
        operand.type === Type.OPERAND
          ? operand.selectedItemId.length === 0
          : this.isSchemaHaveEmptyOperands(operand)
      )
      .some((isOperandHaveEmptyValue) => isOperandHaveEmptyValue);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const calculationLogicPopup = getCalculationLogicPopupParams(state);

  return {
    calculationLogicPopup,
    field: getFieldById(state, calculationLogicPopup.selectedFieldId),
    fields: getSchemeFields(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return bindActionCreators(
    {
      setVisibilityCalculationLogicPopup,
      editField,
    },
    dispatch
  );
}
