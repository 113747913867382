import * as React from "react";
import {
  MenuItem,
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
} from "@sm/ui";

import * as style from "./AdditionalHeaderMenu.scss";
import { AdditionalHeaderMenuProps } from "./types";

/** "AdditionalHeaderMenu" template component */
export const AdditionalHeaderMenu: React.SFC<AdditionalHeaderMenuProps> = ({
  items,
}): JSX.Element => (
  <ResponsiveLayout>
    <LeftSidebar />

    <CentralContent>
      <div className={style.root}>
        {items.map((item) => (
          <MenuItem {...item} />
        ))}
      </div>
    </CentralContent>

    <RightSidebar />
  </ResponsiveLayout>
);

AdditionalHeaderMenu.displayName = "AdditionalHeaderMenu";
