import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { FieldType } from "@sm/types/backend";
import type {
  FieldParams,
  ChangeSelectedElementParams,
} from "../../../../../store/brief/types";
import { ItemType } from "../../../ItemType";
import { Text } from "./Text";
import { Dropdown } from "./Dropdown";
import { SwitchGroup } from "./SwitchGroup";
import { UnlockableInput } from "./UnlockableInput";
import { FileBlock } from "./FileBlock";
import type { StoreState } from "../../../../../store";
import { setSelectedElement } from "../../../../../store/brief/actions";
import {
  getFieldById,
  getSelectedElementId,
  getSelectedFieldItemId,
} from "../../../../../store/brief/selector";

interface FieldContainerProps extends MapProps, DispatchProps {
  id: string;
  blockId: string;
  order: number;
  fieldType: ItemType | FieldType;
  onRemove: (id: string) => Promise<void>;
}

interface MapProps {
  field?: FieldParams;
  selectedElementId?: string;
  selectedFieldItemId?: string;
  isPreviewModeEnabled?: boolean;
  isSchemeActive?: boolean;
  isSchemaEditable?: boolean;
}

interface DispatchProps {
  setSelectedElement?: (params: ChangeSelectedElementParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FieldContainer extends React.Component<FieldContainerProps> {
  public render() {
    const {
      id,
      field,
      blockId,
      order,
      isSchemaEditable,
      isPreviewModeEnabled,
    } = this.props;
    const isHide = isPreviewModeEnabled && field?.properties?.isClientHide;
    const isSelected = this.props.selectedElementId == this.props.id;

    if (isHide) {
      return null;
    }

    switch (this.props.fieldType) {
      case ItemType.TEXT:
        return React.createElement(Text, {
          isSelected,
          isPreviewModeEnabled: this.props.isPreviewModeEnabled,
          canRemove: isSchemaEditable,
          onToggleSelect: this.handleToggleSelect,
          onRemoveClick: this.handleRemoveClick,
          ...this.props.field.properties,
        });
      case ItemType.DROPDOWN:
        return React.createElement(Dropdown, {
          isSelected,
          isPreviewModeEnabled: this.props.isPreviewModeEnabled,
          canRemove: isSchemaEditable,
          onToggleSelect: this.handleToggleSelect,
          onRemoveClick: this.handleRemoveClick,
          ...this.props.field.properties,
        });
      case ItemType.SWITCH_GROUP:
        return React.createElement(SwitchGroup, {
          fieldId: id,
          order,
          blockId,
          isSelected,
          isPreviewModeEnabled: this.props.isPreviewModeEnabled,
          canRemove: isSchemaEditable,
          onToggleSelect: this.handleToggleSelect,
          onRemoveClick: this.handleRemoveClick,
          ...this.props.field.properties,
        });
      case ItemType.UNLOCKABLE_INPUT:
        return React.createElement(UnlockableInput, {
          isSelected,
          isPreviewModeEnabled: this.props.isPreviewModeEnabled,
          canRemove: isSchemaEditable,
          onToggleSelect: this.handleToggleSelect,
          onRemoveClick: this.handleRemoveClick,
          ...this.props.field.properties,
        });
      case ItemType.FILE:
        return React.createElement(FileBlock, {
          fieldId: id,
          isSelected,
          isPreviewModeEnabled: this.props.isPreviewModeEnabled,
          canRemove: isSchemaEditable,
          onToggleSelect: this.handleToggleSelect,
          onRemoveClick: this.handleRemoveClick,
          ...this.props.field.properties,
        });
      default:
        return null;
    }
  }

  @autobind
  protected handleToggleSelect(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (!this.props.isPreviewModeEnabled) {
      const isSelected = this.props.selectedElementId == this.props.id;

      this.props.setSelectedElement({
        selectedBlockId: null,
        selectedElementId: isSelected ? null : this.props.id,
        selectedItemType: isSelected ? null : this.props.fieldType,
      });
    }
  }

  @autobind
  protected handleToggleSelectFieldItem(selectedId: string) {
    if (!this.props.isPreviewModeEnabled) {
      const isSelected = this.props.selectedFieldItemId == selectedId;

      this.props.setSelectedElement({
        selectedElementId: this.props.id,
        selectedBlockId: null,
        selectedItemType: this.props.fieldType,
        selectedFieldItemId: isSelected ? null : selectedId,
      });
    }
  }

  @autobind
  protected async handleRemoveClick() {
    this.props.setSelectedElement({
      selectedElementId: null,
      selectedBlockId: null,
      selectedItemType: null,
      selectedFieldItemId: null,
    });

    this.props.onRemove(this.props.id);
  }
}

function mapStateToProps(
  state: StoreState,
  ownProps: FieldContainerProps
): MapProps {
  const field = getFieldById(state, ownProps.id);
  const isSchemeActive =
    state.brief.isSchemeActive && !field.properties?.parentFieldId;
  const isSchemaEditable = state.brief.isSchemeEditable || isSchemeActive;

  return {
    field,
    selectedElementId: getSelectedElementId(state),
    selectedFieldItemId: getSelectedFieldItemId(state),
    isPreviewModeEnabled: state.brief.isPreviewModeEnabled,
    isSchemeActive,
    isSchemaEditable,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<FieldContainerProps>
): DispatchProps {
  return {
    setSelectedElement: (params: ChangeSelectedElementParams) =>
      dispatch(setSelectedElement(params)),
  };
}
