import type { BudgetAccessLine } from "../../../../api";
import type { LineId } from "../types";

import { store } from "../../../../store";
import { getPageData } from "../../../../store/budgetAccessPage/selectors";
// import { MrmClient } from '../../../../api';

interface StoreProps {
  budgetAccessLines: BudgetAccessLine[];
}

export class Saver {
  private static instance: Saver;

  public static getInstance(): Saver {
    if (!Saver.instance) {
      Saver.instance = new Saver();
    }
    return Saver.instance;
  }

  public async saveBudgetAccessLine(lineId: LineId) {
    const { budgetAccessLines } = this.getStoreProps();

    const budgetAccessLine = budgetAccessLines.find(
      (item) => item.model.id === lineId
    );
    await budgetAccessLine.model.save();
  }

  public async resetBudgetAccessLineChanges(lineId: LineId) {
    const { budgetAccessLines } = this.getStoreProps();

    const budgetAccessLine = budgetAccessLines.find(
      (item) => item.model.id === lineId
    );
    await budgetAccessLine.model.cancel();
  }

  private getStoreProps(): StoreProps {
    const storeState = store.getState();

    const { budgetAccessLines } = getPageData(storeState);

    return {
      budgetAccessLines,
    };
  }
}
