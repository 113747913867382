import { reducerWithInitialState } from "typescript-fsa-reducers";

import {
  setOnValidation,
  loadUserSubordination,
  addSubordination,
  removeSubordination,
  addError,
  removeError,
} from "../actions";

import { CommonReducer } from "./CommonReducer";
import { UserSubordinationReducer } from "./UserSubordinationReducer";

const initialState = {
  onValidation: false,
  userSubordination: {},
};

export const commonReducer = reducerWithInitialState(initialState)
  .case(setOnValidation, CommonReducer.setOnValidation)
  .case(loadUserSubordination, UserSubordinationReducer.loadUserSubordination)
  .case(addSubordination, UserSubordinationReducer.addSubordination)
  .case(removeSubordination, UserSubordinationReducer.removeSubordination)
  .case(addError, CommonReducer.addError)
  .case(removeError, CommonReducer.removeError);
