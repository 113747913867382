import * as React from "react";
import * as classNames from "classnames";

import * as style from "./ElementPreview.scss";

import { Icon, IconType } from "@sm/ui";
import { ItemType } from "../ItemType";

const CARD_OPACITY_ON_DRAG = 0.5;

const PREVIEW_TITLES = {
  [ItemType.BLOCK]: "Блок",
  [ItemType.TEXT]: "Текстовое поле",
  [ItemType.DROPDOWN]: "Дропдаун",
  [ItemType.SWITCH_GROUP]: "Группа переключателей",
  [ItemType.UNLOCKABLE_INPUT]: "Переключатель с текстом",
  [ItemType.FILE]: "Файлы",
};

export class PreviewTemplates {
  public static getPreview(
    itemType: string,
    isDragging: boolean,
    isPreview?: boolean
  ): JSX.Element {
    return (
      <div
        className={classNames(style.root, isPreview && style.dragging)}
        style={{ opacity: isDragging ? CARD_OPACITY_ON_DRAG : 1 }}
      >
        <div className={style.title}>{PREVIEW_TITLES[itemType]}</div>

        <div className={style.icon}>
          <Icon type={IconType.PLUS_24} />
        </div>

        {isPreview && <div className={style.iconWrapper}>+</div>}
      </div>
    );
  }
}
