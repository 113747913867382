import { actionCreatorFactory } from "typescript-fsa";

import {
  BriefState,
  AddBlockParams,
  EditBlockParams,
  UpdateParentBlockParams,
  ChangeBlockOrderParams,
  ChangeFieldOrderParams,
  AddFieldParams,
  RemoveFieldParams,
  RemoveFieldsByTypeParams,
  ChangeSelectedElementParams,
  EditFieldPropertiesParams,
  ChangeBriefNameParams,
  UpdateBriefPageParams,
  CalculationLogicPopupParams,
} from "../types";

const actionCreator = actionCreatorFactory("BRIEF");

const load = actionCreator<BriefState>("LOAD");
const addBlock = actionCreator<AddBlockParams>("ADD_BLOCK");
const editBlock = actionCreator<EditBlockParams>("EDIT_BLOCK");
const updateParentBlock = actionCreator<UpdateParentBlockParams>(
  "UPDATE_PARENT_BLOCK"
);
const rerollElementIds = actionCreator("REROLL_ELEMENT_IDS");
const changeBlockOrder =
  actionCreator<ChangeBlockOrderParams>("CHANGE_BLOCK_ORDER");
const changeFieldOrder =
  actionCreator<ChangeFieldOrderParams>("CHANGE_FIELD_ORDER");
const normalizeElementsOrders = actionCreator("NORMALIZE_ELEMENTS_ORDERS");
const addField = actionCreator<AddFieldParams>("ADD_FIELD");
const removeBlock = actionCreator<string>("REMOVE_BLOCK");
const removeField = actionCreator<RemoveFieldParams>("REMOVE_FIELD");
const removeFieldsByType = actionCreator<RemoveFieldsByTypeParams>(
  "REMOVE_FIELDS_BY_TYPE"
);
const setSelectedElement = actionCreator<ChangeSelectedElementParams>(
  "SET_SELECTEDD_ELEMENT"
);
const setVisibilityCalculationLogicPopup = actionCreator<boolean>(
  "SET_VISIBILITY_CALCULATION_LOGIC_POPUP"
);
const setCalculationLogicPopupParams =
  actionCreator<CalculationLogicPopupParams>(
    "SET_CALCULATION_LOGIC_POPUP_PARAMS"
  );
const editField = actionCreator<EditFieldPropertiesParams>("UPDATE_FIELD");
const togglePreviewMode = actionCreator("TOGGLE_PREVIEW_MODE");
const changeBriefName =
  actionCreator<ChangeBriefNameParams>("CHANGE_BRIEF_NAME");
const updateBriefPage =
  actionCreator<UpdateBriefPageParams>("UPDATE_BRIEF_PAGE");
const resetBriefPage = actionCreator("RESET_BRIEF_PAGE");
const showValidationErrors = actionCreator("SHOW_VALIDATION_ERRORS");
const hideValidationErrors = actionCreator("HIDE_VALIDATION_ERRORS");

export {
  load,
  addBlock,
  editBlock,
  updateParentBlock,
  rerollElementIds,
  changeBlockOrder,
  changeFieldOrder,
  normalizeElementsOrders,
  addField,
  removeField,
  removeFieldsByType,
  removeBlock,
  setSelectedElement,
  setVisibilityCalculationLogicPopup,
  setCalculationLogicPopupParams,
  editField,
  togglePreviewMode,
  changeBriefName,
  updateBriefPage,
  resetBriefPage,
  showValidationErrors,
  hideValidationErrors,
};
