import * as React from "react";
import classNames from "classnames";

import * as styles from "./styles.scss";

export interface Props {
  disabled: boolean;
  onClick(): void;
}

export const SaveButton: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <div
      className={classNames(styles.root, disabled && styles.disabled)}
      onClick={!disabled ? onClick : null}
    >
      Сохранить
    </div>
  );
};
