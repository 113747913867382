// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__root{width:100%;height:calc(100vh - 49px);padding:32px 0;font-family:'Open Sans'}.src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__sharingCard{padding-bottom:32px}.src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__sidebar{width:100%}\n", "",{"version":3,"sources":["webpack://./src/modules/EditCalendarSharingPage/EditCalendarSharingPage.scss"],"names":[],"mappings":"AAAA,oEACI,UAAW,CACX,yBAA0B,CAC1B,cAAe,CAEf,uBAAwB,CAC3B,2EAGG,mBAAoB,CACvB,uEAGG,UAAW","sourcesContent":[".root {\n    width: 100%;\n    height: calc(100vh - 49px);\n    padding: 32px 0;\n\n    font-family: 'Open Sans';\n}\n\n.sharingCard {\n    padding-bottom: 32px;\n}\n\n.sidebar {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__root",
	"sharingCard": "src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__sharingCard",
	"sidebar": "src-modules-EditCalendarSharingPage-_EditCalendarSharingPage__sidebar"
};
export default ___CSS_LOADER_EXPORT___;
