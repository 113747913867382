import * as React from "react";

import * as style from "./ElementsOrder.scss";

import { ElementCard } from "./ElementCard";

interface Props {
  cards: CardParams[];
  isBlockSelected: boolean;
  parent: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

export interface CardParams {
  id: string;
  order: number;
  text: string;
  isAtTheTop: boolean;
  isAtTheBottom: boolean;
}

export function ElementsOrder({
  cards,
  isBlockSelected,
  parent,
  moveCard,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      {!!cards.length && (
        <div className={style.title}>
          Упорядочение {isBlockSelected ? "блоков" : "полей"}
        </div>
      )}

      <div className={style.cards} style={{ height: cards.length * 52 }}>
        {cards.map((item, index) => (
          <div className={style.card} key={item.id}>
            <ElementCard
              index={index}
              id={item.id}
              text={item.text}
              parent={parent}
              order={item.order}
              isAtTheTop={item.isAtTheTop}
              isAtTheBottom={item.isAtTheBottom}
              moveCard={moveCard}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
