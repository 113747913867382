import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import type { RouteComponentProps } from "react-router";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { StoreState } from "../../store";
import type {
  CreativeRequestContract,
  CreativeRequestContractCreateParams,
  Dictionary,
} from "@api";

import { ContractsPageTemplate } from "./ContractsPageTemplate";
import {
  loadContracts,
  loadLotDictionaries,
  setArchivedFilter,
} from "../../store/contractsPage/actions";
import { getArchivedContractsFilter } from "../../store/contractsPage/selectors";
import { MrmClient } from "@api";

interface Props
  extends Partial<MapProps & DispatchProps & RouteComponentProps> {
  pageLabel?: string;
}

interface State {
  preloader: boolean;
  displayContractCreationModal: boolean;
}

interface MapProps {
  archiveFilterEnabled: boolean;
}

interface DispatchProps {
  loadContracts: (budgets: CreativeRequestContract[]) => void;
  loadLotDictionaries: (dictionaries: Dictionary[]) => void;
  setArchivedFilter: (filterEnabled: boolean) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ContractsPageBehaviour extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: false,
      displayContractCreationModal: false,
    };
  }

  public async componentDidMount() {
    await Promise.all([this.loadContracts(), this.loadLotDictionaries()]);
  }

  public render() {
    const { pageLabel, archiveFilterEnabled } = this.props;
    const { preloader, displayContractCreationModal } = this.state;

    return React.createElement(ContractsPageTemplate, {
      preloader,
      pageLabel,
      archiveFilterEnabled,
      displayContractCreationModal,
      onArchiveFilterClick: this.onArchiveFilterClick,
      onCreateBudgetButtonClick: this.onCreateBudgetButtonClick,
      onModalCloseButtonClick: this.onModalCloseButtonClick,
      onCreateBudgetConfirm: this.onCreateBudgetConfirm,
    });
  }

  @autobind
  protected onArchiveFilterClick() {
    this.props.setArchivedFilter(!this.props.archiveFilterEnabled);
  }

  @autobind
  protected onCreateBudgetButtonClick() {
    this.setState({ displayContractCreationModal: true });
  }

  @autobind
  protected onModalCloseButtonClick() {
    this.setState({ displayContractCreationModal: false });
  }

  @autobind
  protected async onCreateBudgetConfirm(
    params: CreativeRequestContractCreateParams
  ) {
    await this.createContract(params);
    await this.loadContracts();

    this.setState({ displayContractCreationModal: false });
  }

  private async loadContracts() {
    const client = await MrmClient.getInstance();

    const contracts = await client.domain.creativeRequests.getContracts({
      archived: true,
    });

    const sortedContracts = lodash.sortBy(
      contracts,
      (item) => item.model.createdAt
    );

    this.props.loadContracts(sortedContracts);
  }

  private async loadLotDictionaries() {
    const client = await MrmClient.getInstance();

    const lotDictionaries = await client.Dictionary.getByType("lot" as any);

    const sortedLotDictionaries = lodash.sortBy(
      lotDictionaries,
      (item) => item.value
    );

    this.props.loadLotDictionaries(sortedLotDictionaries);
  }

  private async createContract(params: CreativeRequestContractCreateParams) {
    const client = await MrmClient.getInstance();

    await client.api.creativeRequests.createContract(params);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    archiveFilterEnabled: getArchivedContractsFilter(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      loadContracts,
      loadLotDictionaries,
      setArchivedFilter,
    },
    dispatch
  );
}
