// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-DepartmentNavigation-_DepartmentNavigation__root{display:flex;flex-direction:column}.src-modules-DepartmentRolePage-DepartmentNavigation-_DepartmentNavigation__department{margin-bottom:24px}.src-modules-DepartmentRolePage-DepartmentNavigation-_DepartmentNavigation__department:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/modules/DepartmentRolePage/DepartmentNavigation/DepartmentNavigation.scss"],"names":[],"mappings":"AAAA,iFACI,YAAa,CACb,qBAAsB,CACzB,uFAGG,kBAAmB,CADvB,kGAIQ,eAAgB","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n}\n\n.department {\n    margin-bottom: 24px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DepartmentRolePage-DepartmentNavigation-_DepartmentNavigation__root",
	"department": "src-modules-DepartmentRolePage-DepartmentNavigation-_DepartmentNavigation__department"
};
export default ___CSS_LOADER_EXPORT___;
