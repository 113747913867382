import * as React from "react";
import classNames from "classnames";

import * as style from "./FiltersColumnHeader.scss";

import { ColumnName } from "store/budgetAccessPage/types";

import {
  Icon,
  IconType,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
  BodyPortal,
  FilterItem,
} from "sber-marketing-ui";
import { ColumnHeaderEditorWrapper } from "../ColumnHeaderEditorWrapper";
import { SortingMenu } from "./SortingMenu";
import { Filters } from "./Filters";

interface Props {
  title: string;
  columnName: ColumnName;
  disableSorting: boolean;
  makeFilterItems: () => FilterItem[];
}

export const FiltersColumnHeader = ({
  title,
  columnName,
  disableSorting,
  makeFilterItems,
}: Props): JSX.Element => {
  const [isOpened, setOpenedStatus] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState([]);

  React.useEffect(() => {
    if (isOpened) {
      setFilterItems(makeFilterItems());
    }
  }, [isOpened]);

  const onOpenerClick = React.useCallback(() => {
    setOpenedStatus(!isOpened);
  }, [isOpened]);

  const onMaskClick = React.useCallback(() => {
    setOpenedStatus(false);
  }, []);

  return (
    <div className={style.root}>
      <div className={style.opener} onClick={onOpenerClick}>
        {title}

        <div className={style.openerIcon}>
          <Icon type={isOpened ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} />
        </div>
      </div>

      {isOpened && (
        <ColumnHeaderEditorWrapper>
          <div className={style.content}>
            <div className={style.title}>{title}</div>

            <div className={style.separator} />

            {!disableSorting && (
              <>
                <SortingMenu columnName={columnName} />

                <div className={style.separator} />
              </>
            )}

            <Filters columnName={columnName} items={filterItems} />

            {/* <div className={style.separator} />

                        <div className={style.applyButton}>
                            <Button
                                theme={ButtonTheme.GhostRounded}
                                onClick={() => {}}
                            >
                                <>
                                    <div className={style.applyButtonIcon}>
                                        <Icon type={IconType.PLAY_FILLED} svgSize={10} />
                                    </div>

                                    Применить
                                </>
                            </Button>
                        </div> */}
          </div>
        </ColumnHeaderEditorWrapper>
      )}

      {isOpened && (
        <BodyPortal>
          <div className={style.mask} onClick={onMaskClick} />
        </BodyPortal>
      )}
    </div>
  );
};
