import { actionCreatorFactory } from "typescript-fsa";
import { UserSubordination, UserParams } from "@sm/types/admin";

const actionCreator = actionCreatorFactory("USER_PAGE");

const setOnValidation = actionCreator<boolean>("SET_ON_VALIDATION");
const loadUserSubordination = actionCreator<UserSubordination>(
  "LOAD_USER_SUBORDINATION"
);
const addSubordination = actionCreator<UserParams>("ADD_SUBORDINATION");
const removeSubordination = actionCreator<number>("REMOVE_SUBORDINATION");
const addError = actionCreator<string>("ADD_ERROR");
const removeError = actionCreator("REMOVE_ERROR");
const resetStore = actionCreator("RESET_STORE");

export {
  setOnValidation,
  loadUserSubordination,
  addSubordination,
  removeSubordination,
  addError,
  removeError,
  resetStore,
};
