import * as React from "react";
import { List } from "react-virtualized";
import { uniq, compact, xor } from "lodash";
import {
  CustomScrollbar_new as CustomScrollbar,
  WithGlobalPopup,
  AbsoluteLikePositionCalculator,
} from "sber-marketing-ui";

import {
  Props,
  TableRow,
  ColumnKey,
  ColumnDescriptors,
  ColumnDescriptor,
  TableFilters,
  ColumnWidths,
  getColumns,
} from "./common";
import { useFilters, useColumnWidths, useVirtualizedScrollbar } from "./hooks";

import { TableHeaderCell } from "./TableHeaderCell";
import { FiltersDescription } from "./FiltersDescription";

import * as styles from "./Table.scss";

const FILTERS_HEIGHT = 48;
const ROW_HEIGHT = 48;

function getVirtualizedListHeight(
  containerRect: DOMRect,
  filteredItemsCount: number
): number {
  if (!containerRect) {
    return window.innerHeight;
  }

  const totalItemsHeight = filteredItemsCount * ROW_HEIGHT;
  const maximumHeight = containerRect.height - FILTERS_HEIGHT * 2;

  return Math.min(totalItemsHeight, maximumHeight);
}

export function Table<R extends TableRow, C extends ColumnKey>(
  props: Props<R, C>
): JSX.Element {
  const { columns, rows, cellRenderer, getFilterItem } = props;
  const {
    filters,
    setFilters,
    ordering,
    setOrdering,
    rowsToDisplay,
    filterRows,
  } = useFilters(props);
  const { columnWidths, setColumnWidths, totalWidth } = useColumnWidths(props);
  const { containerRect, rootRef, listRef, scrollbarRef, onScroll } =
    useVirtualizedScrollbar();

  const listWidth = totalWidth || window.innerWidth;
  const listHeight = getVirtualizedListHeight(
    containerRect,
    rowsToDisplay.length
  );

  return (
    <div ref={rootRef} className={styles.root}>
      <FiltersDescription
        columnDescriptors={columns}
        filters={filters}
        setFilters={setFilters}
      />

      <div className={styles.table}>
        <CustomScrollbar
          ref={scrollbarRef}
          maxHeight={containerRect?.height}
          onScroll={onScroll}
        >
          <div className={styles.header} style={{ width: `${listWidth}px` }}>
            {getColumns(columns).map((column) => (
              <TableHeaderCell
                key={column as string}
                columnDescriptor={columns[column]}
                column={column}
                rows={rows}
                filters={filters}
                setFilters={setFilters}
                ordering={ordering}
                setOrdering={setOrdering}
                columnWidths={columnWidths}
                setColumnWidths={setColumnWidths}
                getFilterItem={getFilterItem}
                filterRows={filterRows}
              />
            ))}
          </div>

          <List
            ref={listRef}
            style={{
              overflowX: "visible",
              overflowY: "visible",
              outline: "none",
            }}
            width={listWidth}
            height={listHeight}
            rowCount={rowsToDisplay.length}
            rowHeight={ROW_HEIGHT}
            rowRenderer={(rowProps) => (
              <div key={rowProps.key} style={rowProps.style}>
                <Row
                  row={rowsToDisplay[rowProps.index]}
                  columns={columns}
                  columnWidths={columnWidths}
                  cellRenderer={cellRenderer}
                />
              </div>
            )}
          />
        </CustomScrollbar>
      </div>
    </div>
  );
}

interface RowProps<R extends TableRow, C extends ColumnKey> {
  row: R;
  columns: ColumnDescriptors<R, C>;
  columnWidths: ColumnWidths<C>;
  cellRenderer: (row: R, column: C) => JSX.Element;
}

function Row<R extends TableRow, C extends ColumnKey>({
  row,
  columns,
  columnWidths,
  cellRenderer,
}: RowProps<R, C>): JSX.Element {
  return (
    <div className={styles.row}>
      {getColumns(columns).map((column) => (
        <div
          key={column as string}
          className={styles.contentCell}
          style={{ width: `${columnWidths[column]}px` }}
        >
          {cellRenderer(row, column)}
        </div>
      ))}
    </div>
  );
}
