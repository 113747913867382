import { bindActionCreators } from "redux";
import * as lodash from "lodash";

import {
  Client,
  MrmClient,
  BudgetAccessData,
  BudgetAccessLine,
  DictionaryType,
} from "../../../../api";

import { store } from "../../../../store";
import { loadPageData } from "../../../../store/budgetAccessPage/actions";
import { getPageData } from "../../../../store/budgetAccessPage/selectors";

interface StoreProps {
  budgetAccessLines: BudgetAccessLine[];
}

export class Loader {
  private static instance: Loader;
  private budgetId: string;
  private client: Client<"budgets" | "organizations" | "rbac">;

  private dispatch = bindActionCreators(
    {
      loadPageData,
    },
    store.dispatch
  );

  public static getInstance(): Loader {
    if (!Loader.instance) {
      Loader.instance = new Loader();
    }
    return Loader.instance;
  }

  public async init(budgetId: string) {
    this.budgetId = budgetId;

    this.client = await MrmClient.getInstance();

    await this.initPageData();
  }

  public async initPageData() {
    await Promise.all([
      this.loadBudgetAccessLines(),
      this.loadDictionaries(),
      this.loadOrganizations(),
      this.loadDepartments(),
      this.loadBudgets(),
      this.loadRoles(),
      this.loadActions(),
    ]);
  }

  public async loadBudgetAccessLines(): Promise<void> {
    const budgetAccessLines =
      (await this.client.domain.budgets.listBudgetAccess({
        budgetId: this.budgetId,
      })) || [];

    this.dispatch.loadPageData({ budgetAccessLines });
  }

  public async loadOrganizations(): Promise<void> {
    const organizations =
      await this.client.api.organizations.listOrganization();

    this.dispatch.loadPageData({ organizations });
  }

  public async loadDictionaries(): Promise<void> {
    const dictionaryTypes = [
      "activity_type",
      "block",
      "channel",
      "cost_center",
      "cost_direction",
      "direction",
      "division",
      "functional_block",
      "ifkv",
      "item",
      "location_driver",
      "product",
      "regionality",
      "resource",
      "resource_usage",
      "segment",
      "subcategory",
      "territory",
      "tool",
    ];

    const dictionaries = lodash.flatten(
      await Promise.all(
        dictionaryTypes.map((item) =>
          this.client.Dictionary.getByType(item as DictionaryType)
        )
      )
    );

    this.dispatch.loadPageData({ dictionaries });
  }

  public async loadDepartments(): Promise<void> {
    const departments = await this.client.api.organizations.listDepartment({});

    this.dispatch.loadPageData({ departments });
  }

  public async loadBudgets(): Promise<void> {
    const budgets = (await this.client.domain.budgets.listBudget()).filter(
      (budget) => budget.model.status !== "archive"
    );

    this.dispatch.loadPageData({ budgets });
  }

  public async loadRoles(): Promise<void> {
    const roles = await this.client.domain.rbac.listRole();

    this.dispatch.loadPageData({ roles });
  }

  public async loadActions(): Promise<void> {
    const actions = await this.client.api.rbac.listAction();

    this.dispatch.loadPageData({ actions });
  }

  public subscribeBudgetAccessLinesChanges(
    handler: (data: Partial<BudgetAccessData>) => void
  ): void {
    const { budgetAccessLines } = this.getStoreProps();

    budgetAccessLines.forEach((line) => {
      line.events.onUpdated(handler);
    });
  }

  private getStoreProps(): StoreProps {
    const storeState = store.getState();

    const { budgetAccessLines } = getPageData(storeState);

    return {
      budgetAccessLines,
    };
  }
}
