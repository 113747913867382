// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-CalendarSharingPage-_CalendarSharingPage__root{padding:32px 32px 0 32px;font-family:\"Open Sans\"}.src-modules-CalendarSharingPage-_CalendarSharingPage__buttons{display:flex;padding-right:4px;justify-content:flex-end}.src-modules-CalendarSharingPage-_CalendarSharingPage__button{width:203px;height:24px;text-decoration:none;align-self:center}\n", "",{"version":3,"sources":["webpack://./src/modules/CalendarSharingPage/CalendarSharingPage.scss"],"names":[],"mappings":"AAAA,4DACI,wBAAyB,CAEzB,uBAAwB,CAC3B,+DAGG,YAAa,CAEb,iBAAkB,CAElB,wBAAyB,CAC5B,8DAGG,WAAY,CACZ,WAAY,CAEZ,oBAAqB,CAErB,iBAAkB","sourcesContent":[".root {\n    padding: 32px 32px 0 32px;\n\n    font-family: \"Open Sans\";\n}\n\n.buttons {\n    display: flex;\n\n    padding-right: 4px;\n\n    justify-content: flex-end;\n}\n\n.button {\n    width: 203px;\n    height: 24px;\n\n    text-decoration: none;\n\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-CalendarSharingPage-_CalendarSharingPage__root",
	"buttons": "src-modules-CalendarSharingPage-_CalendarSharingPage__buttons",
	"button": "src-modules-CalendarSharingPage-_CalendarSharingPage__button"
};
export default ___CSS_LOADER_EXPORT___;
