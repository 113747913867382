import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import autobind from "autobind-decorator";
import { v4 as uuid } from "uuid";

import type { Budget } from "@mrm/budget";
import { BudgetStatus } from "@mrm/budget";
import type { DepartmentRolePageLoadParams } from "../../store/departmentRolePage/types";

import { DepartmentRolePage } from "./DepartmentRolePage";
import type { StoreState } from "../../store";
import { getLoginUser } from "../../store/user/selector";
import { loadPageState } from "../../store/departmentRolePage/actions";
import {
  getSelectedDepartmentName,
  canBeUsersAdded,
} from "../../store/departmentRolePage/selector";
import { DepartmentApi, UserApi, RoleApi, BudgetApi } from "../../api";

interface Props
  extends MapProps,
    DispatchProps,
    RouteComponentProps<RouteParams> {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
  canBeUsersAdded: boolean;
}

interface MapProps {
  organizationId: string;
  selectedDepartmentName: string;
  canBeUsersAdded: boolean;
}

interface DispatchProps {
  loadPageState: (pageState: DepartmentRolePageLoadParams) => void;
}

interface RouteParams {
  departmentId?: string;
}

interface State {
  preloader: boolean;
  budgets: Budget[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DepartmentRolePageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
      budgets: [],
    };
  }

  public async componentDidMount() {
    const { organizationId } = this.props;
    const { departmentId } = this.props.match.params;

    try {
      const [roles, department, users, budgets] = await Promise.all([
        RoleApi.getBusinessRoleList(),
        DepartmentApi.getDepartmentList({
          ids: [departmentId],
          embed: "subDepartments",
        }),
        UserApi.getUsers({ organizationIds: organizationId }),
        BudgetApi.getBudgetList(),
      ]);

      const availableUsers = users.filter((user) => !user.departmentId);

      this.props.loadPageState({
        roles,
        department,
        users,
        availableUsers,
        selectedDepartmentId: departmentId,
      });

      this.setState(() => ({
        preloader: false,
        budgets,
      }));
    } catch (error) {
      this.props.history.push(`/department`);
    }
  }

  public render(): JSX.Element {
    const { selectedDepartmentName, pageLabel, previousUrl, previousLabel } =
      this.props;

    return React.createElement(DepartmentRolePage, {
      previousLabel,
      previousUrl,
      preloader: this.state.preloader,
      pageLabel: selectedDepartmentName || pageLabel,
      canBeUsersAdded: this.props.canBeUsersAdded,
      canBePlanBudgetAdded: this.canBePlanBudgetAdded(),
      canBeExecutionBudgetAdded: this.canBeExecutionBudgetAdded(),
      addPlanBudget: this.addPlanBudget,
      addExecutionBudget: this.addExecutionBudget,
    });
  }

  @autobind
  protected canBePlanBudgetAdded() {
    return !this.state.budgets.some(
      (budget) => budget.status === BudgetStatus.Plan
    );
  }

  @autobind
  protected canBeExecutionBudgetAdded() {
    return !this.state.budgets.some(
      (budget) => budget.status === BudgetStatus.Execution
    );
  }

  @autobind
  protected async addPlanBudget() {
    this.setState({ preloader: true });
    const { organizationId } = this.props;
    const id = uuid();
    const year = new Date().getFullYear();
    await BudgetApi.createBudget({
      id,
      year: year + 1,
      status: BudgetStatus.Plan,
      organizationIds: [organizationId],
      dictionaryOrganizationId: organizationId,
    });
    this.setState({
      preloader: false,
      budgets: await BudgetApi.getBudgetList(),
    });
  }

  @autobind
  protected async addExecutionBudget() {
    this.setState({ preloader: true });
    const id = uuid();
    const { organizationId } = this.props;
    const year = new Date().getFullYear();
    await BudgetApi.createBudget({
      id,
      year,
      status: BudgetStatus.Execution,
      organizationIds: [organizationId],
      dictionaryOrganizationId: organizationId,
    });
    this.setState({
      preloader: false,
      budgets: await BudgetApi.getBudgetList(),
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    selectedDepartmentName: getSelectedDepartmentName(state),
    organizationId: getLoginUser(state).organizationId,
    canBeUsersAdded: canBeUsersAdded(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadPageState: (pageState: DepartmentRolePageLoadParams) =>
      dispatch(loadPageState(pageState)),
  };
}
