import * as React from "react";

import * as style from "./BriefSchemeListPage.scss";

import { BriefScheme } from "@sm/types/admin";

import { CardGrid } from "@sm/ui";
import { Page } from "../common/Page";
import { TopLine } from "./TopLine";
import { BriefSchemeCard } from "./BriefSchemeCard";
import { FiltersLine } from "./FiltersLine";

const CARD_WIDTH = 240;
const CARD_HEIGHT = 116;

interface Props {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
  briefSchemes?: BriefScheme[];
  onRemoveScheme(id: string): void;
}

export const BriefSchemeListPage: React.SFC<Props> = ({
  preloader,
  pageLabel,
  previousUrl,
  previousLabel,
  briefSchemes = [],
  onRemoveScheme,
}: Props): JSX.Element => (
  <Page
    preloader={preloader}
    pageLabel={pageLabel}
    previousUrl={previousUrl}
    previousLabel={previousLabel}
    withoutBackButton
    additionalContent={() => <FiltersLine />}
    customFirstLine={() => <TopLine pageLabel={pageLabel} />}
  >
    <div className={style.root}>
      <CardGrid
        cards={briefSchemes.map((item) => (
          <BriefSchemeCard
            key={item.id}
            schemaId={item.id}
            status={item.status}
            briefName={item.name}
            campaignsCount={item.activityCounts}
            departmentId={item.executorDepartmentId}
            onRemoveScheme={onRemoveScheme}
          />
        ))}
        cardStyle={{
          width: CARD_WIDTH,
          height: CARD_HEIGHT,
          marginHorizontal: 40,
          marginVertical: 40,
        }}
      />
    </div>
  </Page>
);

BriefSchemeListPage.displayName = "BriefSchemeListPage";
