// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-Table-Cells-Text-_Text__root{width:100%;height:100%;padding:0 8px;display:flex;align-items:center}.src-modules-DictionariesPage-Table-Cells-Text-_Text__content{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-family:'Open Sans';font-weight:400;font-size:14px;letter-spacing:0.075px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/Table/Cells/Text/Text.scss"],"names":[],"mappings":"AAAA,2DACI,UAAW,CACX,WAAY,CACZ,aAAc,CAEd,YAAa,CACb,kBAAmB,CACtB,8DAGG,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CAEnB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,sBAAuB,CACvB,aAAc","sourcesContent":[".root {\n    width: 100%;\n    height: 100%;\n    padding: 0 8px;\n\n    display: flex;\n    align-items: center;\n}\n\n.content {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    letter-spacing: 0.075px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DictionariesPage-Table-Cells-Text-_Text__root",
	"content": "src-modules-DictionariesPage-Table-Cells-Text-_Text__content"
};
export default ___CSS_LOADER_EXPORT___;
