import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { WorkTypeLoadParams } from "../../store/workType/types";

import { WorkTypePage } from "./WorkTypePage";
import { load, setIsOnAdd } from "../../store/workType/actions";
import { OrganizationApi, WorkTypeApi, DepartmentApi } from "../../api";
import type { StoreState } from "../../store";
import { getLoginUser } from "../../store/user/selector";
import type { User } from "../../store/user/types";

interface Props extends MapProps, DispatchProps {
  pageLabel: string;
  previousUrl: string;
  previousLabel: string;
  preloader: boolean;
}

interface MapProps {
  user: User;
}

interface DispatchProps {
  load: (pageState: WorkTypeLoadParams) => void;
  setIsOnAdd: (isOnAdd: boolean) => void;
}

interface State {
  preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WorkTypePageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
    };
  }

  public async componentDidMount() {
    const { organizationId } = this.props.user;
    const organization = await OrganizationApi.getOrganization(organizationId);

    const [workType, department] = await Promise.all([
      WorkTypeApi.getWorkTypes({ organizationId }),
      DepartmentApi.getDepartmentList({
        organizationIds: [organizationId, ...organization.executorIds],
      }), // Get organizations
    ]);

    this.props.load({ workType, department });

    this.setState({
      preloader: false,
    });
  }

  public render(): JSX.Element {
    const { pageLabel, previousUrl, previousLabel } = this.props;

    return React.createElement(WorkTypePage, {
      preloader: this.state.preloader,
      onAddButtonClick: this.handleAddButtonClick,
      pageLabel,
      previousUrl,
      previousLabel,
    });
  }

  @autobind
  protected handleAddButtonClick() {
    this.props.setIsOnAdd(true);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    user: getLoginUser(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    load: (pageState: WorkTypeLoadParams) => dispatch(load(pageState)),
    setIsOnAdd: (isOnAdd: boolean) => dispatch(setIsOnAdd(isOnAdd)),
  };
}
