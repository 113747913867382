import * as React from "react";

import * as style from "./TextCell.scss";

interface Props {
  title: string;
  description?: string;
  customStyle?: any;
}

export const TextCellTemplate = ({
  title,
  description,
  customStyle,
}: Props): JSX.Element => {
  return (
    <div className={style.root} style={{ ...customStyle }}>
      <div className={style.title}>{title}</div>

      {description && <div className={style.description}>{description}</div>}
    </div>
  );
};
