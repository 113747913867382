import * as React from "react";
import { DragLayer } from "react-dnd";

import { CustomDragLayer } from "./CustomDragLayer";

const SCROLL_SPEED = 5;
const SCROLL_START_OFFSET = 20;

interface Props extends DNDProps {
  item?: any;
  itemType?: string;
  initialOffset?: any;
  currentOffset?: {
    x: number;
    y: number;
  };
  clientOffset?: {
    x: number;
    y: number;
  };
}

interface DNDProps {
  isDragging?: boolean;
}

@(DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset(),
})) as any)
export class CustomDragLayerContainer extends React.Component<Props> {
  public scrollInterval: any;

  public componentWillReceiveProps(newProps: Props) {
    if (newProps.clientOffset) {
      this.scrollWindow(newProps.clientOffset.x, newProps.clientOffset.y);
    } else {
      this.stopScroll();
    }
  }

  public render() {
    const { item, itemType, isDragging } = this.props;

    return React.createElement(CustomDragLayer, {
      isDragging,
      item,
      itemType,
      elementStyle: this.getItemStyles(),
    });
  }

  protected getItemStyles() {
    const { initialOffset, currentOffset } = this.props;

    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  }

  protected scrollWindow(x: number, y: number) {
    if (y < SCROLL_START_OFFSET && window.pageYOffset > 0) {
      this.startScroll();
    } else {
      this.stopScroll();
    }
  }

  private startScroll() {
    if (!this.scrollInterval) {
      this.scrollInterval = setInterval(
        () => window.scrollTo(window.pageXOffset, window.pageYOffset - 1),
        SCROLL_SPEED
      );
    }
  }

  private stopScroll() {
    clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }
}
