import { create, Client, CookiesAuth } from "@sbermarketing/mrm-metacom-client";
import type {
  Budget,
  BudgetAccess,
  BudgetAccessData,
  BudgetAccessGroup,
  BudgetParams as BudgetCreateParams,
  BudgetAccessParams as BudgetAccessCreateParams,
  Organization,
  Department,
  Role,
  Action,
  DictionaryType,
  Dictionary,
  CreativeRequestContract,
  CreativeRequestContractCreateParams,
} from "@sbermarketing/mrm-metacom-client";

export type BudgetStatus =
  | "plan"
  | "execution"
  | "archive_plan"
  | "archive_execution"
  | "archive";

export type BudgetAccessLine = BudgetAccess;
export type BudgetLine = Budget;
export type RoleDomain = Role;

export class MrmClient {
  private static client: Promise<
    Client<"budgets" | "organizations" | "rbac" | "creativeRequests">
  >;

  public static async getInstance() {
    if (!this.client) {
      this.client = MrmClient.init();
    }

    return await this.client;
  }

  private static async init() {
    return await create({
      url: MRM_CLIENT_URL,
      app: "business-admin",
      modules: ["budgets", "organizations", "rbac", "creativeRequests"],
      debug: true,
      dictionary: true,
      authStrategy: (client) => new CookiesAuth(client),
    });
  }
}

export type {
  Client,
  Budget,
  BudgetAccess,
  BudgetAccessData,
  BudgetAccessGroup,
  BudgetCreateParams,
  BudgetAccessCreateParams,
  Organization,
  Department,
  Role,
  Action,
  DictionaryType,
  Dictionary,
  CreativeRequestContract,
  CreativeRequestContractCreateParams,
};
