import { Formula } from "sber-marketing-types/frontend";

interface UpdateFormulasOperandIds {
  (params: UpdateFormulasOperandIdsParams): Formula[];
}

interface UpdateFormulasOperandIdsParams {
  changedIdsMap: { [key in string]: string };
  formulas: Formula[];
}

export const updateFormulasOperandIds: UpdateFormulasOperandIds = ({
  formulas,
  changedIdsMap,
}) => {
  return formulas.map((formula) => {
    return {
      ...formula,
      operands: updateOperands({
        formula,
        changedIdsMap,
      }),
    };
  });
};

interface UpdateFormulaIds {
  (params: UpdateFormulaIdsParams): Formula;
}

interface UpdateFormulaIdsParams {
  changedIdsMap: { [key in string]: string };
  formula: Formula;
}

export const updateOperands: UpdateFormulaIds = ({
  formula,
  changedIdsMap,
}) => {
  return formula.operands.map((operand) => {
    if (operand.type === "func") {
      return {
        ...operand,
        operands: updateOperands({ formula: operand, changedIdsMap }),
      };
    }

    if (operand.type === "brief_field") {
      return {
        ...operand,
        fieldId: changedIdsMap[operand.fieldId],
      };
    }

    return operand;
  });
};
