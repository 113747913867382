import * as React from "react";
import autobind from "autobind-decorator";

import { CardWithControls } from "./CardWithControls";

interface Props {
  cardLink?: string;
  title: string;
  leftControls?: JSX.Element;
  rightControls?: JSX.Element;
  hoveredContent?: JSX.Element;
}

interface State {
  isHovered: boolean;
}

export class CardWithControlsContainer extends React.PureComponent<
  Props,
  State
> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  public render(): JSX.Element {
    const { cardLink, title, leftControls, rightControls, hoveredContent } =
      this.props;
    const { isHovered } = this.state;

    return (
      <CardWithControls
        cardLink={cardLink}
        title={title}
        leftControls={leftControls}
        rightControls={rightControls}
        hoveredContent={hoveredContent}
        isHovered={isHovered}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      />
    );
  }

  @autobind
  private onMouseOver(): void {
    this.setState({
      isHovered: true,
    });
  }

  @autobind
  private onMouseOut(): void {
    this.setState({
      isHovered: false,
    });
  }
}
