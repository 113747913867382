import { reducerWithInitialState } from "typescript-fsa-reducers";

import { FileStorage } from "@sm/types/admin";
import { FileServiceState } from "./types";

import { setStorageName } from "./actions";

const initialState = {
  storage: null as FileStorage,
};

class Reducer {
  public static setStorageName(state: FileServiceState, storage: FileStorage) {
    return { ...state, storage };
  }
}

export const fileServiceReducer = reducerWithInitialState(initialState).case(
  setStorageName,
  Reducer.setStorageName
);
