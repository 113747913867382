import * as React from "react";
import * as lodash from "lodash";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import { ActivityDictionaryType } from "@sm/types/budget";

import { DictionaryApi } from "../../api";

import { ActivityTypePage } from "./ActivityTypePage";
import type { StoreState } from "../../store";
import { loadPageState } from "../../store/activityType/actions";
import { ActivityTypePageState } from "../../store/activityType/types";
import { getLoginUser } from "../../store/user/selector";

interface Props extends Partial<MapProps & DispatchProps> {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
}

interface MapProps {
  organizationId: string;
}

interface DispatchProps {
  loadPageState: (pageState: ActivityTypePageState) => void;
}

interface State {
  preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityTypePageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preloader: true,
    };
  }

  public async componentDidMount() {
    const state = await this.loadPageStateData();

    this.props.loadPageState(state);

    this.setState({
      preloader: false,
    });
  }

  public render(): JSX.Element {
    const { pageLabel, previousUrl, previousLabel } = this.props;

    return React.createElement(ActivityTypePage, {
      ...this.state,
      pageLabel,
      previousUrl,
      previousLabel,
    });
  }

  private async loadPageStateData(): Promise<ActivityTypePageState> {
    const { organizationId } = this.props;
    const entities = lodash.sortBy(
      (
        await DictionaryApi.getDictionaryList({
          types: [ActivityDictionaryType.ActivityType],
        })
      ).filter(
        (activityType) => activityType.organizationId === organizationId
      ),
      (dictionary) => dictionary.value
    );

    const byId = entities.reduce((acc, activityType) => {
      acc[activityType.id] = activityType;

      return acc;
    }, {});

    const activityTypesData = (
      await DictionaryApi.getDataList(
        entities.map((dictionary) => dictionary.id)
      )
    ).reduce((acc, activityTypeData) => {
      acc[activityTypeData.dictionaryId] = {
        data: activityTypeData.data.color ? activityTypeData.data.color : {},
        schema: activityTypeData.schema,
      };

      return acc;
    }, {});

    return {
      activityTypes: {
        entities,
        byId,
      },
      activityTypesData,
    };
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    organizationId: getLoginUser(state).organizationId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadPageState: (pageState: ActivityTypePageState) =>
      dispatch(loadPageState(pageState)),
  };
}
