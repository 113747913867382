import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as lodash from "lodash";

import type { CreativeRequestContract, Dictionary } from "@api";
import type { PageState } from "./types";

import * as actions from "./actions";

export const initialState: PageState = {
  contracts: [],
  lotDictionaries: [],
  displayArchivedContracts: false,
};

export class Reducer {
  public static loadContracts(
    state: PageState,
    payload: CreativeRequestContract[]
  ): PageState {
    return { ...state, contracts: payload };
  }

  public static loadLotDictionaries(
    state: PageState,
    payload: Dictionary[]
  ): PageState {
    return { ...state, lotDictionaries: payload };
  }

  public static resetPageState(): PageState {
    return lodash.cloneDeep(initialState);
  }

  public static setArchivedFilter(
    state: PageState,
    payload: boolean
  ): PageState {
    return { ...state, displayArchivedContracts: payload };
  }
}

export const contractsPageReducer = reducerWithInitialState(initialState)
  .case(actions.loadContracts, Reducer.loadContracts)
  .case(actions.loadLotDictionaries, Reducer.loadLotDictionaries)
  .case(actions.resetPageState, Reducer.resetPageState)
  .case(actions.setArchivedFilter, Reducer.setArchivedFilter);
