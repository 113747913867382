import * as React from "react";
import { connect, Dispatch } from "react-redux";

import { StoreState } from "../../store";
import { LoadingStatus } from "../../store/utils";
import {
  SharingState,
  loadPageData,
  getCalendarSharingState,
  getExistingSharings,
} from "../../store/calendarSharing";

import { PageProps } from "../common/Page";

import { CalendarSharingPage } from "./CalendarSharingPage";

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends PageProps {}

interface MapProps {
  preloader: boolean;
  sharings: SharingState[];
}

interface DispatchProps {
  loadPageData: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CalendarSharingPageContainer extends React.PureComponent<Props> {
  public componentDidMount(): void {
    this.props.loadPageData();
  }

  public render(): JSX.Element {
    const { pageLabel, previousUrl, previousLabel, preloader, sharings } =
      this.props;

    return (
      <CalendarSharingPage
        pageLabel={pageLabel}
        previousUrl={previousUrl}
        previousLabel={previousLabel}
        preloader={preloader}
        sharings={sharings}
      />
    );
  }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
  const { loadingStatus } = getCalendarSharingState(state);

  return {
    preloader: ownProps.preloader || loadingStatus !== LoadingStatus.LOADED,
    sharings: getExistingSharings(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return {
    loadPageData: () => dispatch(loadPageData(null)),
  };
}
