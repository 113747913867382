// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-BriefName-_BriefName__root{width:100%;height:72px;padding:16px 8px 0}.src-modules-BriefPage-Schema-BriefName-_BriefName__briefName{overflow:hidden;width:100%;text-align:center;white-space:nowrap;text-overflow:ellipsis;font-family:'Open Sans';font-size:17px;font-weight:600;line-height:24px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Schema/BriefName/BriefName.scss"],"names":[],"mappings":"AAAA,yDACI,UAAW,CACX,WAAY,CACZ,kBAAmB,CACtB,8DAGG,eAAgB,CAEhB,UAAW,CAEX,iBAAkB,CAClB,kBAAmB,CACnB,sBAAuB,CAEvB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n    width: 100%;\n    height: 72px;\n    padding: 16px 8px 0;\n}\n\n.briefName {\n    overflow: hidden;\n\n    width: 100%;\n\n    text-align: center;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    font-family: 'Open Sans';\n    font-size: 17px;\n    font-weight: 600;\n    line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Schema-BriefName-_BriefName__root",
	"briefName": "src-modules-BriefPage-Schema-BriefName-_BriefName__briefName"
};
export default ___CSS_LOADER_EXPORT___;
