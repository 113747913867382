import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { FileMenu } from "./FileMenu";
import { getFieldById } from "../../../../../store/brief/selector";
import { editField } from "../../../../../store/brief/actions";
import {
  FieldParams,
  EditFieldPropertiesParams,
} from "../../../../../store/brief/types";
import { StoreState } from "../../../../../store";

interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  file?: FieldParams;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileMenuContainer extends React.Component<Props> {
  public render() {
    const { file, isSchemaActive, isSchemaEditable } = this.props;
    const { properties } = file;

    return React.createElement(FileMenu, {
      name: properties ? properties.name : "",
      tooltipMessage: properties?.tooltipMessage,
      isClientHide: properties?.isClientHide,
      isFileImported: properties?.isFileImported,
      onNameChange: this.handleNameChange,
      onTooltipMessageChange: this.onTooltipMessageChange,
      onClientHideCheckboxChange: this.onClientHideCheckboxChange,
      onFileImportedCheckboxChange: this.onFileImportedCheckboxChange,
      isFileInputDisabled: !isSchemaEditable && !isSchemaActive,
    });
  }

  @autobind
  protected handleNameChange(value: string) {
    this.props.editField({
      id: this.props.id,
      name: value,
    });
  }

  @autobind
  protected onTooltipMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      tooltipMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onClientHideCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isClientHide: !this.props.file.properties?.isClientHide,
    });
  }

  @autobind
  protected onFileImportedCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isFileImported: !this.props.file.properties?.isFileImported,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const isSchemaEditable = state.brief.isSchemeEditable;
  const isSchemaActive = state.brief.isSchemeActive;

  return {
    file: getFieldById(state, ownProps.id),
    isSchemaEditable,
    isSchemaActive,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    editField: (params: EditFieldPropertiesParams) =>
      dispatch(editField(params)),
  };
}
