import * as React from "react";
import { compact, flatMap, includes } from "lodash";
import classNames from "classnames";
import * as styles from "./styles.scss";

export interface Props {
  items: Item[];
  onSelect: (item: Item) => void;
}

export interface Item {
  parentItemId?: string;
  id: string;
  title: string;
  description?: string;
  order?: number;
  childrenItems?: Item[];
  meta?: {
    type?:
      | "func"
      | "expense_item"
      | "brief_field"
      | "const"
      | "new"
      | "new_const"
      | "calc_material_conformity"
      | "calc_material_total";
    addParentSelection?: boolean;
    order?: number;
    value?: string | number;
    childrenItems?: Item[];
  };
}

export const List: React.FC<Props> = ({ items, onSelect }) => {
  const formattedItems = items.map((item) => {
    if (item?.meta?.childrenItems?.length) {
      return {
        ...item,
        childrenItems: compact(item.meta.childrenItems),
      };
    }
    return item;
  });

  return (
    <div className={classNames(styles.root, styles.scrollBlock)}>
      {formattedItems.map((item) => (
        <ListItem key={item.id} item={item} onClick={onSelect} />
      ))}
    </div>
  );
};

interface ItemProps {
  item: Item;
  onClick: (item: Item) => void;
}

const ListItem: React.FC<ItemProps> = ({ item, onClick }) => {
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  const [visible, setVisible] = React.useState(false);

  const onMouseEnter = React.useCallback((event) => {
    setVisible(true);
    const coords = event.target.getBoundingClientRect();

    setPosition({ left: coords.left + coords.width, top: coords.top });
  }, []);

  const onMouseLeave = React.useCallback((event) => {
    setVisible(false);
  }, []);

  const hasChildren = Boolean(item.childrenItems?.length);

  return (
    <div
      key={item.id}
      className={styles.item}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => !hasChildren && onClick(item)}
    >
      <div className={styles.title}>{item.title}</div>
      <div className={styles.text}>{item.description}</div>

      {hasChildren && visible && (
        <div
          className={styles.childrenListWrapper}
          style={{
            position: "fixed",
            left: position.left,
            top: position.top,
          }}
        >
          <div className={classNames(styles.childrenList, styles.scrollBlock)}>
            {item.childrenItems.map((item) => (
              <div
                key={item.id}
                className={styles.item}
                onClick={() => onClick(item)}
              >
                <div className={styles.title}>{item.title}</div>
                <div className={styles.text}>{item.description}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
