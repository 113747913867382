import * as React from "react";
import { Dispatch, connect } from "react-redux";

import { PlainDictionary } from "@sm/types/frontend";
import { StoreState } from "../../../store";
import {
  SharingState,
  getCalendarSharingState,
  deleteSharing,
  selectActivityTypes,
  selectBlocks,
} from "../../../store/calendarSharing";

import { SharingCard } from "./SharingCard";

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends SharingState {
  canRemove: boolean;
}

interface MapProps {
  availableActivityTypes: PlainDictionary[];
  availableBlocks: PlainDictionary[];
}

interface DispatchProps {
  deleteSharing: () => void;
  selectActivityTypes: (itemIds: string[]) => void;
  selectBlocks: (itemIds: string[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SharingCardContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      canRemove,
      availableActivityTypes,
      availableBlocks,
      organization: { name },
      activityTypes: { pending: selectedActivityTypes },
      blocks: { pending: selectedBlocks },
      deleteSharing,
      selectActivityTypes,
      selectBlocks,
    } = this.props;

    return (
      <SharingCard
        showDeleteButton={canRemove}
        availableActivityTypes={availableActivityTypes}
        selectedActivityTypes={selectedActivityTypes}
        availableBlocks={availableBlocks}
        selectedBlocks={selectedBlocks}
        organizationTitle={name}
        onDeleteSharingButtonClick={deleteSharing}
        onActivityTypesSelected={selectActivityTypes}
        onBlocksSelected={selectBlocks}
      />
    );
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const { availableActivityTypes, availableBlocks } =
    getCalendarSharingState(state);

  return {
    availableActivityTypes,
    availableBlocks,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<StoreState>,
  ownProps: OwnProps
): DispatchProps {
  const organizationId = ownProps.organization.id;

  return {
    deleteSharing: () => dispatch(deleteSharing(organizationId)),
    selectActivityTypes: (itemIds: string[]) =>
      dispatch(
        selectActivityTypes({
          organizationId,
          itemIds,
        })
      ),
    selectBlocks: (itemIds: string[]) =>
      dispatch(
        selectBlocks({
          organizationId,
          itemIds,
        })
      ),
  };
}
