import thunk from "redux-thunk";
import { isFunction } from "lodash";
import {
  combineReducers,
  createStore,
  Store,
  applyMiddleware,
  Middleware,
  compose,
} from "redux";

import { userReducer } from "./user/reducers";
import { UserState } from "./user/types";
import { userPageReducer } from "./userPage/reducers";
import { UserPageState } from "./userPage/types";
import { departmentListPageReducer } from "./departmentListPage/reducers";
import { DepartmentListPageState } from "./departmentListPage/types";
import { departmentRolePageReducer } from "./departmentRolePage/reducers";
import { DepartmentRolePageState } from "./departmentRolePage/types";
import { commonReducer } from "./common/reducers";
import { CommonState } from "./common/types";
import { organizationTypeReducer } from "./workType/reducers";
import { WorkTypeState } from "./workType/types";
import { briefReducer } from "./brief/reducers";
import { BriefState } from "./brief/types";
import { briefSchemeListPageReducer } from "./briefSchemeListPage/reducers";
import { BriefSchemeListPageState } from "./briefSchemeListPage/types";
import { StagesPageState } from "./stagesPage/types";
import { stagesPageReducer } from "./stagesPage/reducers";
import { TemplateEditPageState } from "./templateEditPage/types";
import { templateEditPageReducer } from "./templateEditPage/reducers";
import { organizationReducer } from "./organization/reducers";
import { OrganizationState } from "./organization/types";
import { activityPageReducer } from "./activityType/reducers";
import { ActivityTypePageState } from "./activityType/types";
import { FileServiceState } from "./fileService/types";
import { fileServiceReducer } from "./fileService/reducers";
import {
  calendarSharingReducer,
  CalendarSharingState,
} from "./calendarSharing";
import { PageState as BudgetPageState } from "./budgetsPage/types";
import { budgetsPageReducer } from "./budgetsPage/reducers";
import { PageState as BudgetAccessPageState } from "./budgetAccessPage/types";
import { budgetAccessPageReducer } from "./budgetAccessPage/reducers";
import { PageState as ContractsPageState } from "./contractsPage/types";
import { contractsPageReducer } from "./contractsPage/reducers";

export interface StoreState {
  user?: UserState;
  userPage?: UserPageState;
  departmentListPage?: DepartmentListPageState;
  departmentRolePage?: DepartmentRolePageState;
  common?: CommonState;
  workType?: WorkTypeState;
  brief?: BriefState;
  briefSchemeListPage?: BriefSchemeListPageState;
  stagesPage?: StagesPageState;
  templateEditPage?: TemplateEditPageState;
  organization?: OrganizationState;
  activityType?: ActivityTypePageState;
  fileService?: FileServiceState;
  calendarSharing?: CalendarSharingState;
  budgetsPage?: BudgetPageState;
  budgetAccessPage?: BudgetAccessPageState;
  contractsPage?: ContractsPageState;
}

const reducer = combineReducers({
  user: userReducer,
  userPage: userPageReducer,
  departmentListPage: departmentListPageReducer,
  departmentRolePage: departmentRolePageReducer,
  common: commonReducer,
  workType: organizationTypeReducer,
  brief: briefReducer,
  templateEditPage: templateEditPageReducer,
  stagesPage: stagesPageReducer,
  briefSchemeListPage: briefSchemeListPageReducer,
  organization: organizationReducer,
  activityType: activityPageReducer,
  fileService: fileServiceReducer,
  calendarSharing: calendarSharingReducer,
  budgetsPage: budgetsPageReducer,
  budgetAccessPage: budgetAccessPageReducer,
  contractsPage: contractsPageReducer,
});

const env = process.env.NODE_ENV;

const middlewares: Middleware[] = [thunk];

const composeEnhancers =
  env != "production" &&
  isFunction(window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"])
    ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
    : compose;

export const store: Store<StoreState> = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
