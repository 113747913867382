import { createSelector } from "reselect";

import type { BudgetLine } from "../../api";
import type { StoreState } from "../";
import type { PageState } from "./types";

export const getBudgetsPageState = (state: StoreState): PageState =>
  state.budgetsPage;

export const getBudgets = createSelector(
  getBudgetsPageState,
  (state: PageState): BudgetLine[] => {
    return state.budgets;
  }
);

export const getArchivedBudgetsFilter = createSelector(
  getBudgetsPageState,
  (state: PageState): boolean => state.displayArchivedBudgets
);

export const getFilteredBudgets = createSelector(
  getBudgets,
  getArchivedBudgetsFilter,
  (budgets: BudgetLine[], archivedFilter: boolean): BudgetLine[] =>
    archivedFilter
      ? budgets
      : budgets.filter(
          (item) =>
            item.model.status === "plan" || item.model.status === "execution"
        )
);
