import * as React from "react";

import * as style from "./FiltersList.scss";

import { Icon, IconType } from "sber-marketing-ui";

import { ColumnName, Filter } from "../types";
import { ColumnsConfig } from "../ColumnsConfig";

interface Props {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
}

export const FiltersList = ({ filters, setFilters }: Props): JSX.Element => {
  const onFilterRemove = React.useCallback(
    (columnName: ColumnName) => {
      let updatedFilters = filters.filter(
        (item) => item.columnName !== columnName
      );

      setFilters(updatedFilters);
    },
    [filters]
  );

  return (
    <div className={style.root}>
      <div className={style.title}>Фильтры по столбцам:</div>

      <div className={style.filtersWrapper}>
        <div className={style.filters}>
          {filters.map((item) => (
            <div className={style.filter} key={item.columnName}>
              <div className={style.filterName}>
                {ColumnsConfig[item.columnName]?.title}&nbsp;
                <span className={style.filterCounter}>
                  {item.selectedValues.length}
                </span>
              </div>

              <div
                className={style.removeFilterButton}
                onClick={() => onFilterRemove(item.columnName)}
              >
                <Icon type={IconType.CROSS} svgSize={7} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
