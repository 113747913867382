import * as React from "react";
import autobind from "autobind-decorator";

import { EditNameInput } from "./EditNameInput";

interface Props {
  value: string;
  onInputBlur: (value: string) => void;
}

interface State {
  value: string;
  isOnEdit: boolean;
}

export class EditNameInputContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.value,
      isOnEdit: false,
    };
  }

  public render(): JSX.Element {
    return React.createElement(EditNameInput, {
      isOnEdit: this.state.isOnEdit,
      value: this.state.value,
      onEditClick: this.handleEditClick,
      onChange: this.handleInputChange,
      onBlur: this.handleInputBlur,
    });
  }

  @autobind
  protected handleEditClick() {
    this.toggleOnEdit();
  }

  @autobind
  protected async handleInputChange(value: string) {
    this.setState({
      value,
    });
  }

  @autobind
  protected async handleInputBlur() {
    if (this.state.value != this.props.value) {
      this.props.onInputBlur(this.state.value);
    }

    this.toggleOnEdit();
  }

  private toggleOnEdit() {
    this.setState({
      isOnEdit: !this.state.isOnEdit,
    });
  }
}
