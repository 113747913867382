// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__root{position:relative}.src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__input{position:relative}.src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__inputLabel{position:absolute;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__listWrapper{position:absolute;z-index:1;width:100%;top:calc(100% + 10px)}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/common/Dropdown/styles.scss"],"names":[],"mappings":"AAMA,2EACI,iBAAkB,CACrB,4EAGG,iBAAkB,CACrB,iFAGG,iBAAkB,CAdlB,kBAAmB,CACnB,eAAgB,CAChB,sBAAuB,CAc1B,kFAGG,iBAAkB,CAClB,SAAU,CACV,UAAW,CACX,qBAAsB","sourcesContent":["@mixin short-text {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.root {\n    position: relative;\n}\n\n.input {\n    position: relative;\n}\n\n.inputLabel {\n    position: absolute;\n    @include short-text;\n}\n\n.listWrapper {\n    position: absolute;\n    z-index: 1;\n    width: 100%;\n    top: calc(100% + 10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__root",
	"input": "src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__input",
	"inputLabel": "src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__inputLabel",
	"listWrapper": "src-modules-BriefPage-CalculationLogicPopup-common-Dropdown-_styles__listWrapper"
};
export default ___CSS_LOADER_EXPORT___;
