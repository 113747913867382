import * as React from "react";
import { sum, orderBy } from "lodash";
import { List } from "react-virtualized";
import { ScrollValues } from "sber-marketing-ui";

import {
  Props,
  TableRow,
  ColumnKey,
  TableFilters,
  TableOrdering,
  ColumnWidths,
  getColumns,
  hasAnyFilters,
} from "./common";

export function useFilters<R extends TableRow, C extends ColumnKey>({
  rows,
  columns,
  orderTableRow,
  filterTableRow,
  externalFilter,
}: Props<R, C>) {
  const columnKeys = getColumns(columns);

  function filterRows(
    rows: R[],
    filters: TableFilters<C>,
    ordering: TableOrdering<C>
  ): R[] {
    function rowIsVisible(row: R): boolean {
      return columnKeys.every((columnKey) => {
        const column = columns[columnKey];
        const filterValues = filters[columnKey];

        const skipFilter = column.skipFilter || !filterValues.size;

        if (skipFilter) {
          return true;
        }

        return filterTableRow(row, columnKey, filterValues);
      });
    }

    const externalyFilteredRows = rows.filter(externalFilter);

    return orderBy(
      !hasAnyFilters(filters)
        ? externalyFilteredRows
        : externalyFilteredRows.filter(rowIsVisible),
      (row) => orderTableRow(row, ordering.column),
      [ordering.order]
    );
  }

  const [filters, setFilters] = React.useState<TableFilters<C>>(
    getColumns(columns).reduce(
      (acc, column) => ({
        ...acc,
        [column]: new Set<string>(),
      }),
      {} as TableFilters<C>
    )
  );

  const [ordering, setOrdering] = React.useState<TableOrdering<C>>({
    column: getColumns(columns)[0],
    order: "asc",
  });

  const rowsToDisplay = filterRows(rows, filters, ordering);

  return {
    filters,
    setFilters,
    ordering,
    setOrdering,
    rowsToDisplay,
    filterRows,
  };
}

export function useColumnWidths<R extends TableRow, C extends ColumnKey>({
  columns,
}: Props<R, C>) {
  const [columnWidths, setColumnWidths] = React.useState<ColumnWidths<C>>(
    getColumns(columns).reduce(
      (acc, column) => ({
        ...acc,
        [column]: columns[column].width,
      }),
      {} as ColumnWidths<C>
    )
  );

  const totalWidth = sum(
    Object.values(columnWidths).map((columnWidths: number) => columnWidths + 2)
  );

  return {
    columnWidths,
    setColumnWidths,
    totalWidth,
  };
}

export function useVirtualizedScrollbar() {
  const [containerRect, setContainerRect] = React.useState<DOMRect>(null);

  const rootRef = React.useRef<HTMLDivElement>();
  const listRef = React.useRef<List>();
  const scrollbarRef = React.useRef<any>();

  React.useEffect(function generateContainerRect() {
    setContainerRect(rootRef.current?.getBoundingClientRect());
  }, []);

  function onScroll(scrollValues: ScrollValues, prevValues: ScrollValues) {
    const valuesHaveChanged = scrollValues.scrollTop !== prevValues.scrollTop;

    if (valuesHaveChanged && listRef.current && scrollbarRef.current) {
      const { scrollTop, scrollLeft } = scrollValues;

      listRef.current.Grid.handleScrollEvent({
        scrollTop,
        scrollLeft,
      });

      scrollbarRef.current.scrollbar.scrollTo(scrollTop, scrollLeft);
    }
  }

  return {
    containerRect,
    rootRef,
    listRef,
    scrollbarRef,
    onScroll,
  };
}
