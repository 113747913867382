import * as React from "react";

import { Icon, IconType } from "@sm/ui";
import { Page, PageProps } from "../common/Page";
import * as style from "./TradeMark.scss";

interface Props extends PageProps {
  isInputDisabled: boolean;
  showErrorPopup: boolean;
  hideErrorPopup: () => void;
  onInputMount(inputElement: HTMLInputElement): void;
  onInputClick(): void;
  onInputChange(): void;
}

export const TradeMarkTemplate: React.FunctionComponent<Props> = ({
  pageLabel,
  preloader,
  isInputDisabled,
  onInputClick,
  onInputMount,
  onInputChange,
  showErrorPopup,
  hideErrorPopup,
}) => {
  return (
    <Page pageLabel={pageLabel} preloader={preloader}>
      <div className={style.root}>
        <div className={style.content}>
          Загрузить файл
          <div className={style.download} onClick={onInputClick}>
            <div className={style.download__icon}>
              <Icon
                svgSize={22}
                color="transparent"
                type={
                  isInputDisabled
                    ? IconType.ATTACH_DISABLED
                    : IconType.ATTACH_NORMAL
                }
              />
            </div>

            <input
              className={style.download__input}
              type="file"
              ref={onInputMount}
              onChange={onInputChange}
            />
          </div>
          {showErrorPopup && (
            <div className={style.error__wrapper}>
              <div className={style.error}>
                <div className={style.error__message}>
                  Не удалось распарсить файл :(
                </div>
                <div className={style.error__button} onClick={hideErrorPopup}>
                  OK
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};
