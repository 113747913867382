import * as React from "react";
import classnames from "classnames";

import {
  TableRow,
  ColumnKey,
  ColumnDescriptors,
  TableFilters,
  getColumns,
} from "../common";

import * as styles from "./FiltersDescription.scss";

interface Props<R extends TableRow, C extends ColumnKey> {
  columnDescriptors: ColumnDescriptors<R, C>;
  filters: TableFilters<C>;
  setFilters: (filters: TableFilters<C>) => void;
}

export function FiltersDescription<R extends TableRow, C extends ColumnKey>({
  columnDescriptors,
  filters,
  setFilters,
}: Props<R, C>): JSX.Element {
  const columns = getColumns(columnDescriptors);

  function onCloseButtonClick(column: C) {
    setFilters({
      ...filters,
      [column]: new Set<string>(),
    });
  }

  return (
    <div className={styles.root}>
      {columns.map((column) => {
        const shouldRender = filters[column].size;

        return shouldRender ? (
          <div key={column} className={styles.filterDescription}>
            {columnDescriptors[column].title}&nbsp;
            <span className={styles.colorGrey}>{filters[column].size}</span>
            <div
              className={styles.fitlerDescriptionCloseButton}
              onClick={() => onCloseButtonClick(column)}
            >
              <svg
                width="7"
                height="8"
                viewBox="0 0 7 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M1.58178 0.771357C1.21998 0.409548 0.633367 0.409548 0.271558 0.771357C-0.0902516 1.13317 -0.0902516 1.71977 0.271558 2.08158L2.18987 3.9999L0.271356 5.91841C-0.0904519 6.28022 -0.0904524 6.86683 0.271356 7.22864C0.633166 7.59045 1.21977 7.59045 1.58158 7.22864L3.5001 5.31013L5.41862 7.22864C5.78043 7.59045 6.36703 7.59045 6.72884 7.22864C7.09065 6.86683 7.09065 6.28022 6.72884 5.91841L4.81033 3.9999L6.72864 2.08158C7.09045 1.71977 7.09045 1.13317 6.72864 0.771357C6.36683 0.409548 5.78022 0.409548 5.41842 0.771357L3.5001 2.68967L1.58178 0.771357Z"
                  fill="#7E8681"
                />
              </svg>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}
