import * as React from "react";

import * as style from "./SchemeMenu.scss";

import { ElementPreview } from "../../ElementPreview";
import { ItemType } from "../../ItemType";

export function SchemeMenu(): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.menuTitle}>Составляющие элементы брифа</div>

      <div className={style.elementPreviews}>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.BLOCK} />
        </div>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.TEXT} />
        </div>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.DROPDOWN} />
        </div>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.SWITCH_GROUP} />
        </div>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.UNLOCKABLE_INPUT} />
        </div>
        <div className={style.elementPreview}>
          <ElementPreview itemType={ItemType.FILE} />
        </div>
      </div>
    </div>
  );
}
