import * as React from "react";
import classnames from "classnames";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import { ADT } from "ts-adt";
import {
  Dictionary,
  debounce,
  uniq,
  uniqBy,
  groupBy,
  orderBy,
  values,
  flatten,
  chunk,
} from "lodash";
import {
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
  Input_redesign as Input,
  InputTheme_redesign as InputTheme,
  FilterMenu,
  FilterItem,
  CustomScrollbar_new as CustomScrollbar,
  Icon,
  IconType,
  CloseButton,
  Popup,
  Preloader,
  WithTooltip,
} from "sber-marketing-ui";
import {
  PlainDictionary,
  DictionaryType,
  ActivityDictionaryType,
  WeightedPlainDictionary,
  ActivityBudgetStatus,
  DictionaryStatus,
} from "@sm/types/budget";

import { DictionaryApi } from "@api";

import { getDeclension } from "@common/Utils";

import { DictionaryTypes } from "../DictionaryTypeNames";

import { DragNDropLinkItem } from "./DragNDropLinkItem";

import * as styles from "./DictionaryLinksMenu.scss";

interface Props {
  isActive: boolean;
  dictionaries: PlainDictionary[];
  sourceDictionary: PlainDictionary;
  onCloseButtonClick: () => void;
  onLinksUpdated: () => void;
  onEditChildDictionaryLinksButtonClick(dictionary: PlainDictionary): void;
}

type Links = Dictionary<WeightedPlainDictionary[]>;

type LinksOrderMenuState = ADT<{
  Hidden: {};
  ParentLinksOrder: { dictionaryType: DictionaryType };
  ChildrenLinksOrder: { dictionaryType: DictionaryType };
}>;
const defaultLinksOrderState: LinksOrderMenuState = { _type: "Hidden" };

function linksDeclension(value: number) {
  return getDeclension(value, ["связь", "связи", "связей"]);
}

function useLinks(
  dictionaries: PlainDictionary[],
  existingLinks: { id: string; weight: number }[]
) {
  const [links, setLinks_] = React.useState<Links>(
    groupBy(
      orderBy(existingLinks, (link) => link.weight, "desc").map((link) => ({
        ...dictionaries.find((dictionary) => dictionary.id === link.id),
        weight: link.weight,
      })) || [],
      (dictionary) => dictionary.type
    )
  );

  function setLinks(links: Links) {
    setLinks_(
      Object.keys(links).reduce((acc, dictionaryType: DictionaryType) => {
        if (links[dictionaryType].length) {
          return {
            ...acc,
            [dictionaryType]: links[dictionaryType],
          };
        }

        return acc;
      }, {})
    );
  }

  return [links, setLinks] as [Links, (links: Links) => void];
}

async function updateLinks(
  childrenLinks: Links,
  parentLinks: Links,
  dictionaries: PlainDictionary[],
  sourceDictionary: PlainDictionary
) {
  const removedParentRefs = sourceDictionary.parentRefs?.filter((parentRef) => {
    const refDictionary = dictionaries.find(
      (dictionary) => dictionary.id === parentRef.id
    );

    return !parentLinks[refDictionary?.type]?.some(
      (dictionary) => dictionary.id === refDictionary.id
    );
  });

  const requestParams: {
    dictionaryId: string;
    references: {
      parentId: string;
      childId: string;
      weight: number;
    }[];
  }[] = [];

  requestParams.push({
    dictionaryId: sourceDictionary.id,
    references: [
      ...flatten(values(childrenLinks)).map((childDictionary) => ({
        parentId: sourceDictionary.id,
        childId: childDictionary.id,
        weight: childDictionary.weight,
      })),
    ],
  });

  requestParams.push(
    ...flatten(values(parentLinks)).map((parentDictionary) => ({
      dictionaryId: parentDictionary.id,
      references: uniqBy(
        [
          ...(
            dictionaries.find(
              (dictionary) => dictionary.id === parentDictionary.id
            )?.childrenRefs || []
          ).map((childRef) => ({
            parentId: parentDictionary.id,
            childId: childRef.id,
            weight: childRef.weight,
          })),
          {
            parentId: parentDictionary.id,
            childId: sourceDictionary.id,
            weight: parentDictionary.weight,
          },
        ],
        (reference) => reference.childId
      ),
    }))
  );

  requestParams.push(
    ...removedParentRefs.map((parentRef) => ({
      dictionaryId: parentRef.id,
      references:
        dictionaries
          .find((dictionary) => dictionary.id === parentRef.id)
          .childrenRefs?.filter(
            (childRef) => childRef.id !== sourceDictionary.id
          )
          ?.map((ref) => ({
            parentId: parentRef.id,
            childId: ref.id,
            weight: ref.weight,
          })) || [],
    }))
  );

  for (let i = 0; i !== requestParams.length; i++) {
    const params = requestParams[i];

    await DictionaryApi.updateDictionaryReference(params.dictionaryId, {
      rootPath: [params.dictionaryId],
      references: params.references,
    });
  }
}

function useDictionaryLinksMenu({
  sourceDictionary,
  dictionaries,
  onLinksUpdated,
  onCloseButtonClick,
}: Props) {
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
  const [dictionaryData, setDictionaryData] = React.useState<any>({});
  const [linksOrderMenuState, setLinksOrderMenuState] =
    React.useState<LinksOrderMenuState>(defaultLinksOrderState);
  const [parentLinks, setParentLinks] = useLinks(
    dictionaries,
    sourceDictionary.parentRefs
  );
  const [childrenLinks, setChildrenLinks] = useLinks(
    dictionaries,
    sourceDictionary.childrenRefs
  );

  const projectStartStageId = dictionaryData?.projectStartStageId;
  function setProjectStartStageId(projectStartStageId: string) {
    setDictionaryData((data: any) => ({
      ...data,
      projectStartStageId,
    }));
  }

  async function onSaveButtonClick() {
    setIsRequestInProgress(true);

    await updateLinks(
      childrenLinks,
      parentLinks,
      dictionaries,
      sourceDictionary
    );

    if (sourceDictionary.type === ActivityDictionaryType.StageTemplate) {
      await DictionaryApi.updateDictionaryItem(sourceDictionary.id, {
        ...sourceDictionary,
        data: dictionaryData,
      });
    }

    setIsRequestInProgress(false);

    onLinksUpdated();
    onCloseButtonClick();
  }

  React.useEffect(function onMount() {
    async function worker() {
      setDictionaryData(
        (await DictionaryApi.getData(sourceDictionary.id))?.data || {}
      );
    }

    worker();
  }, []);

  return {
    isRequestInProgress,
    projectStartStageId,
    setProjectStartStageId,
    linksOrderMenuState,
    setLinksOrderMenuState,
    parentLinks,
    setParentLinks,
    childrenLinks,
    setChildrenLinks,
    onSaveButtonClick,
  };
}

export function DictionaryLinksMenu(props: Props): JSX.Element {
  const {
    isActive,
    sourceDictionary,
    dictionaries,
    onCloseButtonClick,
    onEditChildDictionaryLinksButtonClick,
  } = props;
  const {
    isRequestInProgress,
    projectStartStageId,
    setProjectStartStageId,
    linksOrderMenuState,
    setLinksOrderMenuState,
    parentLinks,
    setParentLinks,
    childrenLinks,
    setChildrenLinks,
    onSaveButtonClick,
  } = useDictionaryLinksMenu(props);

  const totalLinksCount =
    sourceDictionary.childrenRefs?.length +
    sourceDictionary?.parentRefs?.length;
  const linksInfo = totalLinksCount
    ? `Содержит ${totalLinksCount} ${linksDeclension(totalLinksCount)}`
    : "Не содержит связей";

  const displayDefaultContent = linksOrderMenuState._type === "Hidden";
  const displayLinksOrderMenu =
    linksOrderMenuState._type === "ParentLinksOrder" ||
    linksOrderMenuState._type === "ChildrenLinksOrder";

  return isActive ? (
    <Popup onOutOfContentClick={onCloseButtonClick}>
      <React.Fragment>
        {displayDefaultContent && (
          <div className={styles.root}>
            <div className={styles.titleRow}>
              <div className={styles.titleName}>{sourceDictionary.value}</div>
              &nbsp;&nbsp;
              <div className={styles.titleSubInfo}>{linksInfo}</div>
              <div className={styles.closeButton}>
                <CloseButton onClick={onCloseButtonClick} />
              </div>
            </div>

            <div className={classnames(styles.titleType, styles.titleSubInfo)}>
              {DictionaryTypes[sourceDictionary.type]}
            </div>

            <LinksEditor
              title="Родительские справочники"
              dictionaries={dictionaries}
              links={parentLinks}
              setLinks={setParentLinks}
              openEditLinksOrderMenu={(dictionaryType) =>
                setLinksOrderMenuState({
                  _type: "ParentLinksOrder",
                  dictionaryType,
                })
              }
            />

            <LinksEditor
              title="Дочерние справочники"
              dictionaries={dictionaries}
              links={childrenLinks}
              setLinks={setChildrenLinks}
              openEditLinksOrderMenu={(dictionaryType) =>
                setLinksOrderMenuState({
                  _type: "ChildrenLinksOrder",
                  dictionaryType,
                })
              }
            />

            <div className={styles.controlButtons}>
              <div className={styles.buttonWrapper}>
                <Button
                  theme={ButtonTheme.GhostRounded}
                  onClick={onCloseButtonClick}
                >
                  <div className={styles.button}>Отменить</div>
                </Button>
              </div>

              <div className={styles.buttonWrapper}>
                <Button
                  theme={ButtonTheme.GhostRoundedBlack}
                  onClick={onSaveButtonClick}
                >
                  <div className={styles.buttonWhite}>Сохранить</div>
                </Button>
              </div>
            </div>

            {isRequestInProgress && <Preloader />}
          </div>
        )}

        {displayLinksOrderMenu && (
          <LinksOrderMenu
            allLinks={
              linksOrderMenuState._type === "ChildrenLinksOrder"
                ? childrenLinks
                : parentLinks
            }
            dictionaryType={linksOrderMenuState.dictionaryType}
            sourceDictionary={sourceDictionary}
            projectStartStageId={projectStartStageId}
            setProjectStartStageId={setProjectStartStageId}
            onCloseButtonClick={() =>
              setLinksOrderMenuState(defaultLinksOrderState)
            }
            setLinks={
              linksOrderMenuState._type === "ChildrenLinksOrder"
                ? setChildrenLinks
                : setParentLinks
            }
            onEditChildDictionaryLinksButtonClick={
              onEditChildDictionaryLinksButtonClick
            }
          />
        )}
      </React.Fragment>
    </Popup>
  ) : null;
}

interface LinksEditorProps {
  title: string;
  dictionaries: PlainDictionary[];
  links: Links;
  setLinks: (links: Links) => void;
  openEditLinksOrderMenu: (dictionaryType: DictionaryType) => void;
}

function LinksEditor({
  title,
  dictionaries,
  links,
  setLinks,
  openEditLinksOrderMenu,
}: LinksEditorProps): JSX.Element {
  const [inputValue, setInputValue] = React.useState<string>("");
  const setInputValueValueDebounced = debounce(setInputValue, 150);

  const [isInputFocused, setIsInputFocused] = React.useState(false);

  return (
    <React.Fragment>
      <div className={styles.linksEditor}>
        <div className={styles.linksEditorTitle}>{title}</div>

        <div
          className={classnames(
            styles.linksEditorInputWrapper,
            isInputFocused &&
              styles.linksEditorInputWrapperWithFilteredResultsOpened
          )}
        >
          <Input
            className={styles.linksEditorInput}
            placeholder="Введите тип словаря, название или код"
            theme={InputTheme.Simple}
            value={inputValue}
            onInputChange={setInputValueValueDebounced}
            onFocus={() => setIsInputFocused(true)}
          />

          {isInputFocused && (
            <LinksEditorFilteredResults
              dictionaries={dictionaries}
              inputValue={inputValue}
              links={links}
              setLinks={setLinks}
            />
          )}
        </div>

        <LinkGroups
          links={links}
          openEditLinksOrderMenu={openEditLinksOrderMenu}
        />
      </div>

      {isInputFocused && (
        <div
          className={styles.linksEditorMask}
          onClick={() => setIsInputFocused(false)}
        />
      )}
    </React.Fragment>
  );
}

interface LinksEditorFilteredResultsProps {
  dictionaries: PlainDictionary[];
  inputValue: string;
  links: Links;
  setLinks: (links: Links) => void;
}

function LinksEditorFilteredResults({
  dictionaries,
  links,
  inputValue,
  setLinks,
}: LinksEditorFilteredResultsProps) {
  const inputValueRegExp = new RegExp(
    inputValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    "i"
  );

  const itemGroups: Dictionary<FilterItem[]> = React.useMemo(
    () =>
      dictionaries.reduce((acc, dictionary) => {
        if (!acc[dictionary.type]) {
          acc[dictionary.type] = [];
        }

        acc[dictionary.type].push({
          id: dictionary.id,
          title: `${
            dictionary.status === DictionaryStatus.DELETED ? "(Удален) " : ""
          }${dictionary.value}`,
          description: dictionary.code ? `Код: ${dictionary.code}` : "",
        });

        return acc;
      }, {} as Dictionary<FilterItem[]>),
    [dictionaries]
  );

  function filterItem(item: FilterItem): boolean {
    return !!(
      item.title.match(inputValueRegExp) ||
      item.description?.match(inputValueRegExp)
    );
  }

  function onFilterMenuChange(
    dictionaryType: DictionaryType,
    selectedItemIds: React.ReactText[]
  ) {
    const updatedItems = normalizeDictionaryWieghts(
      selectedItemIds.map((dictionaryId) =>
        dictionaries.find((dictionary) => dictionary.id === dictionaryId)
      )
    );

    setLinks({
      ...links,
      [dictionaryType]: updatedItems,
    });
  }

  return (
    <div className={styles.linksEditorFilteredResults}>
      <CustomScrollbar hideScrollX freezeScrollX maxHeight={360}>
        {Object.keys(itemGroups).map((dictionaryType: DictionaryType) => {
          const items = itemGroups[dictionaryType];
          const filteredItems = inputValue ? items.filter(filterItem) : items;
          const selectedItems =
            links[dictionaryType]?.map((link) => link.id) || [];

          const shouldRenderGroup =
            filteredItems.length && DictionaryTypes[dictionaryType];

          return shouldRenderGroup ? (
            <div
              key={dictionaryType}
              className={styles.linksEditorFilteredResultsItem}
            >
              <FilterMenu
                preserveAllSelectedState
                filterTitle={DictionaryTypes[dictionaryType]}
                items={filteredItems}
                checkedItemsIds={selectedItems}
                onItemSelection={(selectedItemIds) =>
                  onFilterMenuChange(dictionaryType, selectedItemIds)
                }
              />
            </div>
          ) : null;
        })}
      </CustomScrollbar>
    </div>
  );
}

interface LinkGroupsProps {
  links: Dictionary<PlainDictionary[]>;
  openEditLinksOrderMenu: (dictionaryType: DictionaryType) => void;
}

function LinkGroups({
  links,
  openEditLinksOrderMenu,
}: LinkGroupsProps): JSX.Element {
  return (
    <div className={styles.linksEditorLinkGroups}>
      {Object.keys(links).map((dictionaryType: DictionaryType) => {
        const group = links[dictionaryType];

        return (
          <WithTooltip
            key={dictionaryType}
            content={<LinkGroupTooltipProps links={group} />}
          >
            <div
              className={styles.linksEditorLinkGroup}
              onClick={() => openEditLinksOrderMenu(dictionaryType)}
            >
              <div className={styles.linksEditorLinkGroupTitle}>
                {DictionaryTypes[group[0].type]}
              </div>

              <div className={styles.linksEditorLinkGroupInfo}>
                {group.length} {linksDeclension(group.length)}
              </div>
            </div>
          </WithTooltip>
        );
      })}
    </div>
  );
}

interface LinkGroupTooltipProps {
  links: PlainDictionary[];
}

function LinkGroupTooltipProps({ links }: LinkGroupTooltipProps): JSX.Element {
  const visibleLinks = links.slice(0, 15);
  const remLinksCount = links.length - visibleLinks.length;

  return (
    <div className={styles.linkGroupTooltip}>
      {visibleLinks.map((link) => (
        <span key={link.id}>
          • {link.status === "deleted" ? "(Удален)" : ""} {link.value}
        </span>
      ))}

      {remLinksCount
        ? `И еще ${remLinksCount} ${linksDeclension(remLinksCount)}`
        : null}
    </div>
  );
}

interface LinksOrderMenuProps {
  allLinks: Links;
  dictionaryType: DictionaryType;
  sourceDictionary: PlainDictionary;
  projectStartStageId: string;
  setProjectStartStageId(projectStartStageId: string): void;
  onCloseButtonClick(): void;
  setLinks: (links: Links) => void;
  onEditChildDictionaryLinksButtonClick(dictinary: PlainDictionary): void;
}

const LinksOrderMenu: React.ComponentType<LinksOrderMenuProps> =
  DragDropContext(MultiBackend(HTML5toTouch))(
    React.forwardRef(function LinksOrderMenu({
      allLinks,
      dictionaryType,
      sourceDictionary,
      projectStartStageId,
      setProjectStartStageId,
      onCloseButtonClick,
      setLinks,
      onEditChildDictionaryLinksButtonClick,
    }: LinksOrderMenuProps): JSX.Element {
      const linksToEdit = allLinks[dictionaryType];

      const [sortedLinks, setSortedLinks] =
        React.useState<WeightedPlainDictionary[]>(linksToEdit);

      const addStartStageControls =
        sourceDictionary.type === ActivityDictionaryType.StageTemplate &&
        dictionaryType === ActivityDictionaryType.Stage;

      function onItemsOrderChange(dragIndex: number, hoverIndex: number) {
        setSortedLinks((links) => {
          const updLinks = [...links];
          updLinks[dragIndex] = links[hoverIndex];
          updLinks[hoverIndex] = links[dragIndex];

          return normalizeDictionaryWieghts(updLinks);
        });
      }

      function onSaveButtonClick() {
        setLinks({
          ...allLinks,
          [dictionaryType]: sortedLinks,
        });
        onCloseButtonClick();
      }

      function onRemoveLinkButtonClick(linkId: string) {
        const sortedLinks = linksToEdit.filter((link) => link.id !== linkId);

        setSortedLinks(sortedLinks);
        setLinks({
          ...allLinks,
          [dictionaryType]: sortedLinks,
        });

        if (linksToEdit?.length === 1) {
          onCloseButtonClick();
        }
      }

      return (
        <div className={styles.root}>
          <div className={styles.titleRow}>
            <div className={styles.titleName}>
              Редактирование связей {DictionaryTypes[dictionaryType]}
            </div>

            <div className={styles.closeButton}>
              <CloseButton onClick={onCloseButtonClick} />
            </div>
          </div>

          {addStartStageControls && (
            <div className={styles.editLinksOrderStageTemplateToStageInfo}>
              Выберите этап, на котором начнется проведение проекта
            </div>
          )}

          {sortedLinks.map((link, i) => (
            <DragNDropLinkItem
              key={link.id}
              {...link}
              index={i}
              order={link.weight}
              addStartStageControls={addStartStageControls}
              projectStartStageId={projectStartStageId}
              setProjectStartStageId={setProjectStartStageId}
              moveItem={onItemsOrderChange}
              onRemoveButtonClick={() => onRemoveLinkButtonClick(link.id)}
              onEditButtonClick={() =>
                onEditChildDictionaryLinksButtonClick(link)
              }
            />
          ))}

          {!sortedLinks.length && <Preloader />}

          <div
            className={classnames(
              styles.buttonWrapper,
              styles.linksOrderMenuButton
            )}
          >
            <Button
              theme={ButtonTheme.GhostRoundedBlack}
              onClick={onSaveButtonClick}
            >
              <div className={styles.buttonWhite}>Сохранить</div>
            </Button>
          </div>
        </div>
      );
    })
  );

function normalizeDictionaryWieghts(
  dictionaries: (PlainDictionary | WeightedPlainDictionary)[]
): WeightedPlainDictionary[] {
  return dictionaries.map((dictionary, i) => ({
    ...dictionary,
    weight: dictionaries.length - i,
  }));
}
