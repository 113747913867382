import { createSelector } from "reselect";

import type { CreativeRequestContract, Dictionary } from "@api";
import type { StoreState } from "../";
import type { PageState } from "./types";

export const getContractsPageState = (state: StoreState): PageState =>
  state.contractsPage;

export const getContracts = createSelector(
  getContractsPageState,
  (state: PageState): CreativeRequestContract[] => {
    return state.contracts;
  }
);

export const getLotDictionaries = createSelector(
  getContractsPageState,
  (state: PageState): Dictionary[] => {
    return state.lotDictionaries;
  }
);

export const getArchivedContractsFilter = createSelector(
  getContractsPageState,
  (state: PageState): boolean => state.displayArchivedContracts
);
