import * as React from "react";

import { LabeledTextarea, Checkbox_redesign, LabeledInput } from "@sm/ui";
import { Tooltip } from "./Tooltip";

import * as style from "./BlockMenu.scss";

interface Props {
  name: string;
  tooltipMessage?: string;
  isRequired: boolean;
  isClientHide: boolean;
  isSchemaActive: boolean;
  isSchemaEditable: boolean;
  onNameInputBlur: (value: string) => void;
  onTooltipMessageChange: (value: string) => void;
  onNotRequiredCheckboxChange: (isChecked: boolean) => void;
  onClientHideCheckboxChange: (isChecked: boolean) => void;
}

export function BlockMenu({
  name,
  tooltipMessage,
  isRequired,
  isClientHide,
  isSchemaActive,
  isSchemaEditable,
  onNameInputBlur,
  onNotRequiredCheckboxChange,
  onClientHideCheckboxChange,
  onTooltipMessageChange,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.nameInput}>
        <LabeledInput
          title={"Название блока"}
          value={name}
          onValueChange={onNameInputBlur}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.checkboxWithTooltip}>
        <Checkbox_redesign
          title={"Опциональный блок"}
          isChecked={!isRequired}
          onSwitch={onNotRequiredCheckboxChange}
          iconSize={16}
          disabled={!isSchemaEditable && !isSchemaActive}
        />

        <Tooltip />
      </div>

      <div className={style.nameInput}>
        <LabeledTextarea
          title={"Текст подсказки"}
          value={tooltipMessage}
          onValueChange={onTooltipMessageChange}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>

      <div className={style.checkboxWithTooltip}>
        <Checkbox_redesign
          title={"Не выводить в клиентской части"}
          isChecked={isClientHide}
          onSwitch={onClientHideCheckboxChange}
          iconSize={16}
          disabled={!isSchemaEditable && !isSchemaActive}
        />
      </div>
    </div>
  );
}
