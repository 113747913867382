import * as React from "react";
import * as lodash from "lodash";
import classnames from "classnames";

import * as style from "./RightGroup.scss";

import type { Action, BudgetAccessGroup, Dictionary } from "@api";

import { LabeledSelect, Icon, IconType } from "sber-marketing-ui";

export const enum DictionaryType {
  ActivityType = "activity_type",
  Block = "block",
  Channel = "channel",
  CostCenter = "cost_center",
  CostDirection = "cost_direction",
  Direction = "direction",
  Division = "division",
  FunctionalBlock = "functional_block",
  IFKV = "ifkv",
  Item = "item",
  LocationDriver = "location_driver",
  Product = "product",
  Regionality = "regionality",
  Resource = "resource",
  ResourceUsage = "resource_usage",
  Segment = "segment",
  Subcategory = "subcategory",
  Territory = "territory",
  Tool = "tool",
}

export const DictionaryNames = {
  [DictionaryType.ActivityType]: "Тип проекта",
  [DictionaryType.Block]: "Блок",
  [DictionaryType.Division]: "Дивизион",
  [DictionaryType.Product]: "Продукт",
  [DictionaryType.Segment]: "Сегмент",
  [DictionaryType.Territory]: "Территория",
  [DictionaryType.Regionality]: "ЦА/ТБ",
  [DictionaryType.Channel]: "Канал",
  [DictionaryType.CostCenter]: "Наименование ЦЗ",
  [DictionaryType.CostDirection]: "Направление затрат",
  [DictionaryType.Direction]: "Направление",
  [DictionaryType.FunctionalBlock]: "Функциональный блок",
  [DictionaryType.IFKV]: "ИФКВ",
  [DictionaryType.Item]: "Статья",
  [DictionaryType.LocationDriver]: "Драйвер аллокации",
  [DictionaryType.Resource]: "Ресурс",
  [DictionaryType.ResourceUsage]: "Порядок использования ресурсов",
  [DictionaryType.Subcategory]: "Подкатегория",
  [DictionaryType.Tool]: "Инструмент",
};

interface DictionaryGroup {
  dictionaryType: string;
  dictionaryItems: { label: string; value: string }[];
  selectedIds: string[];
}

interface Props {
  group: BudgetAccessGroup;
  actions: Action[];
  dictionaries: Dictionary[];
  canBeDeleted: boolean;
  onDictionariesChange: (groupId: number, value: string[]) => void;
  onGroupActionsChange: (groupId: number, value: number[]) => void;
  onGroupDictionaryTypeChange: (
    groupId: number,
    dictionaryTypes: string[]
  ) => void;
  onDeleteGroupButtonClick: (groupId: number) => void;
}

export const RightGroupTemplate = ({
  group,
  actions,
  dictionaries,
  canBeDeleted,
  onDictionariesChange,
  onGroupActionsChange,
  onGroupDictionaryTypeChange,
  onDeleteGroupButtonClick,
}: Props): JSX.Element => {
  const [dictionaryGroups, setDictionaryGroups] = React.useState<
    DictionaryGroup[]
  >(makeDictionaryGroups(group, dictionaries));

  const onDictionaryAddButtonClick = React.useCallback(() => {
    const newDictionaryGroup: DictionaryGroup = {
      dictionaryType: null,
      dictionaryItems: [],
      selectedIds: [],
    };

    setDictionaryGroups([...dictionaryGroups, newDictionaryGroup]);
  }, [dictionaryGroups]);

  const onDictionaryDeleteButtonClick = React.useCallback(
    (dictionaryGroup: DictionaryGroup) => {
      const updatedDictionaryGroups = lodash.without(
        dictionaryGroups,
        dictionaryGroup
      );
      const allSelectedIds = lodash.flatMap(
        updatedDictionaryGroups,
        (item) => item.selectedIds
      );

      setDictionaryGroups(updatedDictionaryGroups);
      onDictionariesChange(group.groupId, allSelectedIds);
    },
    [dictionaryGroups]
  );

  const onDictionaryTypeSelection = React.useCallback(
    (dictionaryGroup: DictionaryGroup, dictionaryType: string) => {
      const updatedDictionaryGroups = lodash.clone(dictionaryGroups);

      dictionaryGroup.dictionaryType = dictionaryType;
      dictionaryGroup.dictionaryItems = makeDictionaryItemsByType(
        dictionaries,
        dictionaryType
      );

      setDictionaryGroups(updatedDictionaryGroups);
      onGroupDictionaryTypeChange(
        group.groupId,
        lodash.uniq(
          updatedDictionaryGroups.map((group) => group.dictionaryType)
        )
      );
    },
    [dictionaryGroups]
  );

  const onDictionaryIdsSelection = React.useCallback(
    (dictionaryGroup: DictionaryGroup, selectedIds: string[]) => {
      const updatedDictionaryGroups = lodash.clone(dictionaryGroups);

      dictionaryGroup.selectedIds = selectedIds;

      setDictionaryGroups(updatedDictionaryGroups);

      const allSelectedIds = lodash.flatMap(
        dictionaryGroups,
        (item) => item.selectedIds
      );

      onDictionariesChange(group.groupId, allSelectedIds);
    },
    [dictionaryGroups]
  );

  const disableAddButton = dictionaryGroups.some(
    (item) => item.dictionaryType == null
  );

  const dictionaryTypes = lodash
    .uniqBy(dictionaries, (item) => item.type)
    .map((item) => item.type);

  const dictionaryTypeItems: { label: string; value: string }[] =
    dictionaryTypes.map((item) => ({
      label: DictionaryNames[item] || item,
      value: item,
    }));

  return (
    <div className={style.root}>
      <div className={style.column}>
        <div className={style.columnTitle}>Справочники</div>

        <div className={style.dictionaries}>
          {dictionaryGroups.map((item, index) => (
            <React.Fragment key={item.dictionaryType}>
              <div className={style.dictionaryItem}>
                <div className={style.dictionaryTypeSelect}>
                  <LabeledSelect
                    title={"Справочник"}
                    items={lodash.sortBy(
                      dictionaryTypeItems,
                      (item) => item.label
                    )}
                    selectedValue={item.dictionaryType}
                    onSelection={(value) =>
                      onDictionaryTypeSelection(item, value as string)
                    }
                  />
                </div>

                <div className={style.dictionariesSelect}>
                  <LabeledSelect
                    title={"Значение справочника"}
                    items={item.dictionaryItems}
                    selectedValue={item.selectedIds}
                    onSelection={(value) =>
                      onDictionaryIdsSelection(item, value as string[])
                    }
                    allowMultipleItems
                  />
                </div>

                <div
                  className={style.deleteDictionaryGroupButton}
                  onClick={() => onDictionaryDeleteButtonClick(item)}
                >
                  <div className={style.deleteDictionaryGroupButtonIcon}>
                    <Icon type={IconType.CROSS_BRIEF} svgSize={16} />
                  </div>
                  Удалить
                </div>
              </div>

              {index < dictionaryGroups.length - 1 && (
                <div className={style.separator}>И</div>
              )}
            </React.Fragment>
          ))}

          {lodash.isEmpty(dictionaryGroups) && (
            <div className={style.emptyDictionariesMessage}>
              Справочники не выбраны.
              <br />
              Действия будут распространяться на все строки указанных бюджетов.
            </div>
          )}
        </div>

        <div
          className={classnames(
            style.addDictionaryButton,
            disableAddButton && style.disabled
          )}
          onClick={disableAddButton ? null : onDictionaryAddButtonClick}
        >
          Добавить справочник
        </div>
      </div>

      <div className={style.column}>
        <div className={style.columnTitle}>Действия</div>

        {!lodash.isEmpty(actions) && (
          <div className={style.actions}>
            {actions.map((item) => (
              <div
                key={item.id}
                className={style.action}
                onClick={() =>
                  onGroupActionsChange(
                    group.groupId,
                    lodash.xor(group.actionIds, [item.id])
                  )
                }
              >
                <div className={style.checkIcon}>
                  <Icon
                    type={
                      group.actionIds.includes(item.id)
                        ? IconType.CHECKBOX24_CHECKED
                        : IconType.CHECKBOX24_UNCHECKED
                    }
                    svgSize={22}
                  />
                </div>

                {item.title}
              </div>
            ))}
          </div>
        )}

        {lodash.isEmpty(actions) && (
          <div className={style.emptyActionsMessage}>
            Не выбрана роль.
            <br />
            Пожалуйста, выберите роль, чтобы настроить группу прав.
          </div>
        )}
      </div>

      {canBeDeleted && (
        <div
          className={style.deleteGroupButton}
          onClick={() => onDeleteGroupButtonClick(group.groupId)}
        >
          <div className={style.deleteGroupButtonIcon}>
            <Icon type={IconType.THIN_CROSS} svgSize={16} />
          </div>
          Удалить
        </div>
      )}
    </div>
  );
};

function makeDictionaryGroups(
  group: BudgetAccessGroup,
  dictionaries: Dictionary[]
): DictionaryGroup[] {
  const selectedDictionaries = group.dictionaryIds.map((id) =>
    dictionaries.find((item) => item.id === id)
  );

  const groupedSelectedDictionaries = lodash.groupBy(
    selectedDictionaries,
    (item) => item.type
  );

  return (
    group.dictionaryTypes?.map((dictionaryType) => ({
      dictionaryType,
      dictionaryItems: makeDictionaryItemsByType(dictionaries, dictionaryType),
      selectedIds:
        groupedSelectedDictionaries[dictionaryType]?.map((item) => item.id) ||
        [],
    })) || []
  );
}

function makeDictionaryItemsByType(
  dictionaries: Dictionary[],
  type: string
): { label: string; value: string }[] {
  const items = dictionaries
    .filter((item) => item.type === type)
    .map((item) => ({
      label: item.value,
      value: item.id,
    }));

  return lodash.sortBy(items, (item) => item.label);
}
