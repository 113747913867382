import * as React from "react";

import * as styles from "./ContractsPage.scss";

import type { CreativeRequestContractCreateParams } from "../../api";

import { Icon, IconType } from "sber-marketing-ui";
import { Page } from "../common/Page";
import { Table } from "./Table";
import { ContractCreationModal } from "./ContractCreationModal";

interface Props {
  preloader: boolean;
  pageLabel: string;
  archiveFilterEnabled: boolean;
  displayContractCreationModal: boolean;
  onArchiveFilterClick: () => void;
  onCreateBudgetButtonClick: () => void;
  onModalCloseButtonClick: () => void;
  onCreateBudgetConfirm: (params: CreativeRequestContractCreateParams) => void;
}

interface HeaderProps {
  archiveFilterEnabled: boolean;
  onArchiveButtonClick: () => void;
}

export function ContractsPageTemplate({
  preloader,
  pageLabel,
  archiveFilterEnabled,
  displayContractCreationModal,
  onArchiveFilterClick,
  onCreateBudgetButtonClick,
  onModalCloseButtonClick,
  onCreateBudgetConfirm,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      customFirstLine={() => (
        <Header
          archiveFilterEnabled={archiveFilterEnabled}
          onArchiveButtonClick={onArchiveFilterClick}
        />
      )}
      fixedLeftContent
      customLayout
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div
            className={styles.createBudgetButton}
            onClick={onCreateBudgetButtonClick}
          >
            <div className={styles.createBudgetButtonIcon}>
              <Icon type={IconType.PLUS_IN_CIRCLE} svgSize={17} />
            </div>
            Создать новый контракт
          </div>

          <div className={styles.table}>
            <Table />
          </div>
        </div>

        {displayContractCreationModal && (
          <ContractCreationModal
            onConfirm={onCreateBudgetConfirm}
            onClose={onModalCloseButtonClick}
          />
        )}
      </div>
    </Page>
  );
}

const Header = ({
  archiveFilterEnabled,
  onArchiveButtonClick,
}: HeaderProps) => (
  <div className={styles.headerRoot}>
    <div className={styles.headerContent}>
      <div className={styles.headerTitle}>Управление контрактами</div>
    </div>

    <div className={styles.buttonsWrapper}>
      <div className={styles.archiveButton} onClick={onArchiveButtonClick}>
        <div className={styles.archiveButtonIcon}>
          <Icon
            type={
              archiveFilterEnabled ? IconType.EYE_OPENED : IconType.EYE_CLOSED
            }
            svgSize={24}
          />
        </div>

        {archiveFilterEnabled
          ? "Показать контракты в архиве"
          : "Скрыть контракты в архиве"}
      </div>
    </div>
  </div>
);
