import * as React from "react";
import * as lodash from "lodash";
import { FixedSizeList } from "react-window";

import * as styles from "./Role.scss";

import {
  PermissionResponse,
  PermissionSubjectResponse,
  OrganizationView,
  DepartmentAttributes,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

// import { CustomScrollbar } from '@sm/ui';
import { RowContainer } from "./RowContainer";

interface Props {
  permissions: (PermissionResponse & { id: string })[];
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
  tableHeight: number;
  listRef: React.RefObject<FixedSizeList>;
  onEditButtonClick: (id: string) => void;
  onRemoveButtonClick: (id: string) => void;
  onTableScroll: (event: any) => void;
}

// const ITEM_HEIGHT = 50;
// const LIST_PADDING = 28;

export function Role({
  permissions,
  budgets,
  organizations,
  departments,
  dictionaries,
  permissionSubjects,
  tableHeight,
  listRef,
  onEditButtonClick,
  onRemoveButtonClick,
  onTableScroll,
}: Props): JSX.Element {
  return (
    <div className={styles.root}>
      {lodash.isEmpty(permissions) ? (
        <div className={styles.errorInfoWrapper}>
          <div className={styles.errorInfo}>Ничего не найдено</div>
        </div>
      ) : (
        // : (
        //     <CustomScrollbar
        //         hideTracksWhenNotNeeded
        //         renderView={(props: any) => <div {...props} className={null} />}
        //         onScroll={onTableScroll}
        //         autoHeight
        //         autoHeightMax={'100%'}
        //     >
        //         <FixedSizeList
        //             itemSize={ITEM_HEIGHT}
        //             itemCount={permissions.length + 1}
        //             height={tableHeight}
        //             width={'100%'}
        //             ref={listRef}
        //             style={{ overflow: 'unset' }}
        //         >
        //             {({ index, style }) => renderListContent(index, style)}
        //         </FixedSizeList>
        //     </CustomScrollbar>
        // )
        permissions.map(renderListContent)
      )}
    </div>
  );

  function renderListContent(
    item: PermissionResponse & { id: string },
    index: number
    // style: React.CSSProperties
  ) {
    // const item = permissions[index];
    // const paddedTop = Number(style.top) + LIST_PADDING;

    return (
      <div
        className={styles.tableRow}
        // style={{ ...style, top: paddedTop }}
        key={item ? item.id : lodash.uniqueId()}
      >
        {item ? (
          <RowContainer
            permission={{
              id: item.id,
              budget: `${
                organizations[budgets[item.budgetId].dictionaryOrganizationId]
                  .name
              } | ${budgets[item.budgetId].year} | ${
                budgets[item.budgetId].status
              }`,
              organization: `${organizations[item.organizationId].name}`,
              department: `${
                departments[item.departmentId]
                  ? departments[item.departmentId].name
                  : ""
              }`,
              dictionaries: !item.dictionaryIds
                ? []
                : item.dictionaryIds.map((id) =>
                    dictionaries[id]
                      ? `${dictionaries[id].value} | ${dictionaries[id].type}`
                      : ""
                  ),
              subject: `${permissionSubjects[item.subjectId].title}`,
            }}
            onEditButtonClick={onEditButtonClick}
            onRemoveButtonClick={onRemoveButtonClick}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}
