import * as React from "react";

import * as style from "./WorkTypeTable.scss";

import { WorkTypeRow } from "../WorkTypeRow";
import { AddWorkTypeRow } from "../AddWorkTypeRow";

interface Props {
  workTypeIds: string[];
  isOnAdd: boolean;
}

export function WorkTypeTable({ isOnAdd, workTypeIds }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.titleRow}>
        <div className={style.nameColumnWrapper}>Название</div>

        <div className={style.departmentColumnWrapper}>Исполнители</div>

        <div className={style.toggleColumnWrapper}>Оценка задач</div>
      </div>

      {isOnAdd && <AddWorkTypeRow />}

      {workTypeIds.map((id) => (
        <WorkTypeRow id={id} key={id} />
      ))}
    </div>
  );
}
