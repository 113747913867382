import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as lodash from "lodash";

import * as style from "./FiltersList.scss";

import { Icon, IconType } from "sber-marketing-ui";
import type { StoreState } from "../../../store";
import { ColumnName, Filter } from "../../../store/budgetAccessPage/types";

import { setTableFilters } from "../../../store/budgetAccessPage/actions";
import { getFilters } from "../../../store/budgetAccessPage/selectors";
import { ColumnsConfig } from "../Table/ColumnsConfig";

interface Props {}

export const FiltersList = ({}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const filters = useSelector((state: StoreState) => getFilters(state));

  const filtersRef = React.useRef<Filter[]>([]);

  React.useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const onFilterRemove = React.useCallback(
    (columnName: ColumnName) => {
      const filters = filtersRef.current;

      let updatedFilters = filters.filter(
        (item) => item.columnName !== columnName
      );

      dispatch(setTableFilters(updatedFilters));
    },
    [dispatch]
  );

  if (lodash.isEmpty(filters)) {
    return null;
  }

  return (
    <div className={style.root}>
      <div className={style.title}>Фильтры по столбцам:</div>

      <div className={style.filtersWrapper}>
        <div className={style.filters}>
          {filters.map((item) => (
            <div className={style.filter} key={item.columnName}>
              <div className={style.filterName}>
                {ColumnsConfig[item.columnName]?.title}&nbsp;
                <span className={style.filterCounter}>
                  {item.selectedValues.length}
                </span>
              </div>

              <div
                className={style.removeFilterButton}
                onClick={() => onFilterRemove(item.columnName)}
              >
                <Icon type={IconType.CROSS} svgSize={7} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
