import * as React from "react";

import type { Point } from "../../types";

import {
  CellEditorWrapperTemplate,
  Direction,
  HorizontalDirection,
  VerticalDirection,
} from "./CellEditorWrapperTemplate";

const CELL_BORDER_WIDTH = 2;

interface Props {
  children: React.ReactNode;
  rootCustomStyle?: any;
  wrapperCustomStyle?: any;
}

interface State {
  direction: Direction;
  position: Point;
}

export class CellEditorWrapperBehaviour extends React.PureComponent<
  Props,
  State
> {
  private rootRef: React.RefObject<HTMLDivElement>;

  public constructor(props: Props) {
    super(props);

    this.state = {
      direction: null,
      position: null,
    };

    this.rootRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    const direction = this.getDirection();
    const position = this.getPosition(direction);

    this.setState({
      direction,
      position,
    });
  }

  public render(): JSX.Element {
    const { children, rootCustomStyle, wrapperCustomStyle } = this.props;
    const { direction, position } = this.state;

    return React.createElement(CellEditorWrapperTemplate, {
      children,
      direction,
      position,
      rootRef: this.rootRef,
      rootCustomStyle,
      wrapperCustomStyle,
    });
  }

  private getDirection(): Direction {
    if (!this.rootRef.current) {
      return null;
    }

    const { x, y, width, height } =
      this.rootRef.current.getBoundingClientRect();

    const cellCenterX = x + width / 2;
    const cellCenterY = y + height / 2;

    return {
      horizontal:
        cellCenterX > window.innerWidth / 2
          ? HorizontalDirection.Left
          : HorizontalDirection.Right,
      vertical:
        cellCenterY > window.innerHeight / 2
          ? VerticalDirection.Up
          : VerticalDirection.Down,
    };
  }

  private getPosition(direction: Direction): Point {
    if (!this.rootRef.current) {
      return null;
    }

    const { x, y, width, height } =
      this.rootRef.current.getBoundingClientRect();

    return {
      x:
        direction.horizontal === HorizontalDirection.Left
          ? x + width + CELL_BORDER_WIDTH
          : x - CELL_BORDER_WIDTH,
      y:
        direction.vertical === VerticalDirection.Down
          ? y + height + CELL_BORDER_WIDTH
          : y - CELL_BORDER_WIDTH,
    };
  }
}
