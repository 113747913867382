import * as React from "react";
import * as classNames from "classnames";

import * as style from "./FileBlock.scss";

import type { CardStyle } from "sber-marketing-ui";
import { ElementPropertiesParams } from "../../../../../../store/brief/types";

import { CardGrid, Icon, IconType, WithTooltip, Preloader } from "@sm/ui";
import { FileAssetWithPreview } from "./FileAssetWithPreview";
import { FileUploader } from "./FileUploader";
import { FileAsset } from "../../../../../../api/file-api";

const CARD_STYLE: CardStyle = {
  width: 120,
  height: 154,
  marginHorizontal: 18,
  marginVertical: 16,
};

export interface FileBlockProps extends ElementPropertiesParams {
  fieldId: string;
  isSelected?: boolean;
  onToggleSelect: React.MouseEventHandler<HTMLDivElement>;
  onRemoveClick: React.MouseEventHandler<HTMLDivElement>;
  isPreviewModeEnabled?: boolean;
}

export function FileBlock({
  fieldId,
  name = "Название",
  tooltipMessage,
  isSelected,
  onToggleSelect,
  onRemoveClick,
  isPreviewModeEnabled,
  canRemove,
  isFileImported,
  files,
}: FileBlockProps): JSX.Element {
  const hasTooltip = !!tooltipMessage;

  function renderFileCard(file: FileAsset, index: number) {
    return (
      <div
        className={style.fileCard}
        key={file.id}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={style.filePreview}>
          <FileAssetWithPreview {...(file as any)} fieldId={fieldId} />
        </div>
      </div>
    );
  }

  const uploader = (
    <div className={style.uploader}>
      <div className={style.uploaderText}>Загрузить файл</div>

      <div className={style.uploaderIcon}>
        <Icon type={IconType.PLUS_20} />
      </div>
    </div>
  );
  console.log("render", files);
  return (
    <div
      className={classNames(style.root, isSelected && style.selected)}
      onClick={onToggleSelect}
    >
      <div className={style.topLine}>
        <div className={style.name}>{name || "Новое поле файлов"}</div>

        {isPreviewModeEnabled || !isFileImported ? (
          uploader
        ) : (
          <FileUploader fieldId={fieldId}>{uploader}</FileUploader>
        )}

        {canRemove && isSelected && (
          <div className={style.removeIcon} onClick={onRemoveClick}>
            <Icon type={IconType.CROSS_BRIEF} />
          </div>
        )}

        {isPreviewModeEnabled && hasTooltip && (
          <WithTooltip content={tooltipMessage} className={style.iconTooltip}>
            <Icon type={IconType.INFO_CIRCLE} svgSize={"16"} />
          </WithTooltip>
        )}
      </div>

      <div className={style.fileList}>
        {files && files.length > 0 && (
          <CardGrid cards={files.map(renderFileCard)} cardStyle={CARD_STYLE} />
        )}
      </div>
    </div>
  );
}
