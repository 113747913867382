import { OrganizationParams } from "@sm/types/admin";
import { OrganizationView } from "sber-marketing-types/backend";

import { axios } from "../lib/axios";

const BASE_URL = "/api/organization/";

export class OrganizationApi {
  public static async getOrganization(
    organizationId: string
  ): Promise<OrganizationParams> {
    const { data } = await axios.get<OrganizationParams>(
      `${BASE_URL}${organizationId}`
    );

    return data;
  }

  public static async getFullOrganizationData(
    id: string
  ): Promise<OrganizationView> {
    const { data } = await axios.post<OrganizationView>(`${BASE_URL}find`, {
      id,
    });

    return data;
  }

  public static async getOrganizations(): Promise<OrganizationView[]> {
    const { data } = await axios.post<OrganizationView[]>(`${BASE_URL}list`);

    return data;
  }
}
