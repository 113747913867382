// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-ElementsOrder-_ElementsOrder__root{width:100%}.src-modules-BriefPage-ElementsOrder-_ElementsOrder__title{margin-bottom:8px;user-select:none;letter-spacing:1px;text-transform:uppercase;color:#404040;font-family:'Open Sans';font-size:8px;line-height:12px}.src-modules-BriefPage-ElementsOrder-_ElementsOrder__cards{height:auto}.src-modules-BriefPage-ElementsOrder-_ElementsOrder__card{margin-bottom:16px}.src-modules-BriefPage-ElementsOrder-_ElementsOrder__card:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/ElementsOrder/ElementsOrder.scss"],"names":[],"mappings":"AAAA,0DACI,UAAW,CACd,2DAGG,iBAAkB,CAElB,gBAAiB,CACjB,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,uBAAwB,CACxB,aAAc,CACd,gBAAiB,CACpB,2DAGG,WAAY,CACf,0DAGG,kBAAmB,CADvB,qEAIQ,eAAgB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.title {\n    margin-bottom: 8px;\n\n    user-select: none;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n\n    color: #404040;\n\n    font-family: 'Open Sans';\n    font-size: 8px;\n    line-height: 12px;\n}\n\n.cards {\n    height: auto;\n}\n\n.card {\n    margin-bottom: 16px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-ElementsOrder-_ElementsOrder__root",
	"title": "src-modules-BriefPage-ElementsOrder-_ElementsOrder__title",
	"cards": "src-modules-BriefPage-ElementsOrder-_ElementsOrder__cards",
	"card": "src-modules-BriefPage-ElementsOrder-_ElementsOrder__card"
};
export default ___CSS_LOADER_EXPORT___;
