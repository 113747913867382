// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-common-ErrorPopup-_ErrorPopup__hidden{display:none}.src-modules-common-ErrorPopup-_ErrorPopup__root{position:relative}.src-modules-common-ErrorPopup-_ErrorPopup__messagePopup{display:flex;flex-direction:column;width:320px;height:180px;background-color:#ffffff;align-items:center;justify-content:center}.src-modules-common-ErrorPopup-_ErrorPopup__textWrapper{width:80%;margin-bottom:24px;text-align:center;color:#d0021b;font-size:16px}.src-modules-common-ErrorPopup-_ErrorPopup__buttonWrapper{width:100px;height:24px;font-size:16px}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/common/ErrorPopup/ErrorPopup.scss"],"names":[],"mappings":"AAYA,mDACI,YAAa,CCZjB,iDACI,iBAAkB,CACrB,yDAGG,YAAa,CACb,qBAAsB,CAEtB,WAAY,CACZ,YAAa,CAEb,wBAAyB,CAEzB,kBAAmB,CACnB,sBAAuB,CAC1B,wDAGG,SAAU,CACV,kBAAmB,CAEnB,iBAAkB,CAElB,aDhBe,CCkBf,cAAe,CAClB,0DAGG,WAAY,CACZ,WAAY,CAEZ,cAAe","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import '../mixins/mixin';\n.root {\n    position: relative;\n}\n\n.messagePopup {\n    display: flex;\n    flex-direction: column;\n\n    width: 320px;\n    height: 180px;\n\n    background-color: #ffffff;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.textWrapper {\n    width: 80%;\n    margin-bottom: 24px;\n\n    text-align: center;\n\n    color: $Cancelled;\n\n    font-size: 16px;\n}\n\n.buttonWrapper {\n    width: 100px;\n    height: 24px;\n\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-common-ErrorPopup-_ErrorPopup__hidden",
	"root": "src-modules-common-ErrorPopup-_ErrorPopup__root",
	"messagePopup": "src-modules-common-ErrorPopup-_ErrorPopup__messagePopup",
	"textWrapper": "src-modules-common-ErrorPopup-_ErrorPopup__textWrapper",
	"buttonWrapper": "src-modules-common-ErrorPopup-_ErrorPopup__buttonWrapper"
};
export default ___CSS_LOADER_EXPORT___;
