import {
  DictionaryListQuery,
  PlainDictionary,
  UpdateWeightedDictionaryReferencesParams,
} from "@sm/types/budget";

import { axios } from "../lib/axios";

const BASE_URL = "/api/dictionary";

export class DictionaryApi {
  public static async getDictionaryList(
    params?: DictionaryListQuery
  ): Promise<PlainDictionary[]> {
    const res = await axios.get(`${BASE_URL}`, { params: { ...params } });

    return res.data;
  }

  public static async createNewDictionary(
    params: PlainDictionary & { data?: any }
  ): Promise<void> {
    await axios.post(`${BASE_URL}`, { ...params });
  }

  public static async updateDictionaryItem(
    id: string,
    params: Partial<PlainDictionary> & { data?: any }
  ): Promise<void> {
    await axios.put(`${BASE_URL}/${id}`, { ...params });
  }

  public static async updateDictionaryReference(
    id: string,
    params: UpdateWeightedDictionaryReferencesParams
  ): Promise<void> {
    await axios.put(`${BASE_URL}/${id}/reference`, { ...params });
  }

  public static async deleteDictionary(id: string): Promise<void> {
    await axios.delete(`${BASE_URL}/${id}`);
  }

  public static async getData(
    id: string
  ): Promise<{ dictionaryId: string; data: any; schema: any }> {
    const res = await axios.get(`${BASE_URL}/${id}/data`);

    return res.data;
  }

  public static async getDataList(
    ids: string[]
  ): Promise<{ dictionaryId: string; data: any; schema: any }[]> {
    const res = await axios.post(`${BASE_URL}/data/list`, { ids });

    return res.data;
  }

  public static async getDictionariesByIds(params: {
    ids: string[];
  }): Promise<{ dictionary: PlainDictionary[] }> {
    const res = await axios.post(`${BASE_URL}/list`, { ...params });

    return res.data;
  }
}
