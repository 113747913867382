import * as React from "react";
import { findDOMNode } from "react-dom";
import {
  DragSource,
  DropTarget,
  ContextComponent,
  DragSourceMonitor,
  ConnectDragSource,
  ConnectDropTarget,
  DropTargetMonitor,
} from "react-dnd";
import { connect } from "react-redux";
import autobind from "autobind-decorator";

import { ChangeBlockOrderParams } from "../../../../store/brief/types";
import { ItemType } from "../../ItemType";

import { ElementCard } from "./ElementCard";
import { StoreState } from "../../../../store";
import {
  changeBlockOrder,
  changeFieldOrder,
  normalizeElementsOrders,
} from "../../../../store/brief/actions";
import {
  getSelectedBlockId,
  getSelectedElementId,
  getFieldById,
} from "../../../../store/brief/selector";

export interface Props extends MapProps, DispatchProps, DNDProp {
  index: number;
  id: string;
  parent: string;
  order: number;
  text: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  isAtTheTop: boolean;
  isAtTheBottom: boolean;
}

interface DNDProp {
  connectDragSource?: ConnectDragSource;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
}

interface MapProps {
  isBlockSelected?: boolean;
  selectedBlock?: string;
}

interface DispatchProps {
  changeBlockOrder?: (params: ChangeBlockOrderParams) => void;
  normalizeElementsOrders?: () => void;
}

const cardSource = {
  beginDrag(props: Props) {
    return {
      id: props.id,
      index: props.index,
      order: props.order,
    };
  },

  endDrag(
    props: Props,
    monitor: DragSourceMonitor,
    component: ElementCardContainer
  ) {
    const item: any = monitor.getItem();

    component.changeBlockOrder(item.id, item.order, item.index + 1);
  },
};

/*tslint:disable*/
const cardTarget = {
  hover(
    props: Props,
    monitor: DropTargetMonitor,
    component: ContextComponent<any, any>
  ): void {
    const item: any = monitor.getItem();
    const dragIndex = item.index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = (
      findDOMNode(component) as Element
    ).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex;
  },
};

@(connect(mapStateToProps, null, mergeProps) as any)
@(DropTarget(ItemType.BLOCK_SORT, cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
})) as any)
@(DragSource(ItemType.BLOCK_SORT, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})) as any)
export class ElementCardContainer extends React.Component<Props> {
  public changeBlockOrder(id: string, oldOrder: number, newOrder: number) {
    this.props.changeBlockOrder({
      id,
      briefBlockId: this.props.parent,
      oldOrder,
      newOrder,
    });

    this.props.normalizeElementsOrders();
  }

  public render() {
    const {
      text,
      isDragging,
      connectDragSource,
      connectDropTarget,
      isAtTheTop,
      isAtTheBottom,
    } = this.props;

    return React.createElement(ElementCard, {
      text,
      isDragging,
      connectDragSource,
      connectDropTarget,
      enableUpwardArrow: !isAtTheTop,
      enableDownwardArrow: !isAtTheBottom,
      shiftBlockUpward: this.shiftBlockUpward,
      shiftBlockDownward: this.shiftBlockDownward,
    });
  }

  @autobind
  protected shiftBlockUpward(): void {
    this.changeBlockOrder(
      this.props.id,
      this.props.order,
      this.props.order - 1
    );
  }

  @autobind
  protected shiftBlockDownward(): void {
    this.changeBlockOrder(
      this.props.id,
      this.props.order,
      this.props.order + 1
    );
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const selectedBlockId = getSelectedBlockId(state);
  const selectedElementId = getSelectedElementId(state);

  return {
    isBlockSelected: !selectedBlockId && !selectedElementId,
    selectedBlock:
      selectedBlockId ||
      (selectedElementId
        ? getFieldById(state, selectedElementId).blockId
        : null),
  };
}

function mergeProps(stateProps: MapProps, dispatchProps: any, ownProps: Props) {
  const { isBlockSelected, selectedBlock } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...ownProps,
    changeBlockOrder:
      isBlockSelected || ownProps.parent
        ? (params: ChangeBlockOrderParams) => dispatch(changeBlockOrder(params))
        : (params: ChangeBlockOrderParams) => {
            dispatch(
              changeFieldOrder({
                ...params,
                blockId: selectedBlock,
              })
            );
          },
    normalizeElementsOrders: () => dispatch(normalizeElementsOrders()),
  };
}
