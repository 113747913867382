// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-Table-_Table__root{height:100%;display:flex;flex-direction:column}.src-modules-DictionariesPage-Table-_Table__table{max-height:calc(100% - 48px);width:100%;background:#FFFFFF;border:1px solid #E6EDF1;box-shadow:0px 2px 6px rgba(0,0,0,0.1);border-radius:6px}.src-modules-DictionariesPage-Table-_Table__row{height:48px;display:flex}.src-modules-DictionariesPage-Table-_Table__contentCell{height:100%;background:#FFFFFF;border-style:solid;border-color:#E6EDF1;border-width:0 1px 1px 1px}.src-modules-DictionariesPage-Table-_Table__header{display:flex}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/Table/Table.scss"],"names":[],"mappings":"AAAA,iDACI,WAAY,CACZ,YAAa,CACb,qBAAsB,CACzB,kDAGG,4BAA6B,CAC7B,UAAW,CAEX,kBAAmB,CACnB,wBAAyB,CACzB,sCAA0C,CAC1C,iBAAkB,CACrB,gDAGG,WAAY,CAEZ,YAAa,CAChB,wDAGG,WAAY,CAEZ,kBAAmB,CAEnB,kBAAmB,CACnB,oBAAqB,CACrB,0BAA2B,CAC9B,mDAGG,YAAa","sourcesContent":[".root {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.table {\n    max-height: calc(100% - 48px);\n    width: 100%;\n\n    background: #FFFFFF;\n    border: 1px solid #E6EDF1;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n}\n\n.row {\n    height: 48px;\n\n    display: flex;\n}\n\n.contentCell {  \n    height: 100%;\n\n    background: #FFFFFF;\n\n    border-style: solid;\n    border-color: #E6EDF1;\n    border-width: 0 1px 1px 1px;\n}\n\n.header {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DictionariesPage-Table-_Table__root",
	"table": "src-modules-DictionariesPage-Table-_Table__table",
	"row": "src-modules-DictionariesPage-Table-_Table__row",
	"contentCell": "src-modules-DictionariesPage-Table-_Table__contentCell",
	"header": "src-modules-DictionariesPage-Table-_Table__header"
};
export default ___CSS_LOADER_EXPORT___;
