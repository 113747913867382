import * as React from "react";

import * as style from "./WorkTypePage.scss";

import { Page } from "../common/Page";
import { WorkTypeTable } from "./WorkTypeTable";
import { TopLine } from "./TopLine";

export interface WorkTypePageProps {
  pageLabel: string;
  previousUrl: string;
  previousLabel: string;
  preloader: boolean;
  onAddButtonClick: () => void;
}

export const WorkTypePage: React.SFC<WorkTypePageProps> = ({
  preloader,
  pageLabel,
  previousUrl,
  previousLabel,
  onAddButtonClick,
}: WorkTypePageProps): JSX.Element => (
  <Page
    preloader={preloader}
    pageLabel={pageLabel}
    previousUrl={previousUrl}
    previousLabel={previousLabel}
    withoutBackButton
    customFirstLine={() => (
      <TopLine pageLabel={pageLabel} onNewButtonClick={onAddButtonClick} />
    )}
  >
    <div className={style.root}>
      <WorkTypeTable />
    </div>
  </Page>
);

WorkTypePage.displayName = "WorkTypePage";
