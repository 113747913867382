import * as React from "react";

import * as styles from "./BriefName.scss";

import { Input, InputTheme_redesign } from "@sm/ui";

const MAX_NAME_LENGTH = 255;

interface Props {
  name: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBlur: () => void;
  disabled: boolean;
  isPreviewModeEnabled?: boolean;
}

export function BriefName({
  name,
  onInputChange,
  onInputBlur,
  disabled,
  isPreviewModeEnabled,
}: Props): JSX.Element {
  return (
    <div className={styles.root}>
      {!isPreviewModeEnabled && (
        <Input
          value={name}
          placeholder="Введите название брифа"
          theme={InputTheme_redesign.BriefTitle}
          onChange={onInputChange}
          onBlur={onInputBlur}
          disabled={disabled}
          maxLength={MAX_NAME_LENGTH}
        />
      )}
    </div>
  );
}
