import type { BudgetLine } from "../../api";

export interface PageState {
  budgets: BudgetLine[];
  displayArchivedBudgets: boolean;
}

export const enum ColumnName {
  SerialNumber = "serialNumber",
  Year = "year",
  BudgetType = "budgetType",
  Status = "status",
  Access = "access",
}

export const enum ColumnType {
  Text = "text",
  Status = "status",
  Access = "access",
}
