import {
  FieldType,
  SelectItem,
  SwitchItem,
  SwitchType,
  Formula,
  FunctionExpression,
} from "@sm/types/frontend";
import { ItemType } from "../../../modules/BriefPage/ItemType";

export interface BriefState {
  name: string;
  briefSchemeId: string;
  elements: BlockParams[];
  selectedElementId: string;
  selectedBlockId: string;
  selectedFieldItemId: string;
  selectedItemType: ItemType;
  isPreviewModeEnabled: boolean;
  activityCounts?: number;
  isSchemeActive?: boolean;
  isSchemeEditable: boolean;
  isFormulaEditable: boolean;
  showValidationErrors: boolean;
  calculationLogicPopup: CalculationLogicPopupParams;
}

export interface CalculationLogicPopupParams {
  visibility: boolean;
  selectedFieldId: string;
  selectedFieldLineId?: string;
}

export interface SchemaParams {
  id: string;
  name: string;
}

export interface BlockParams {
  id: string;
  name: string;
  tooltipMessage?: string;
  schemaId: string;
  order: number;
  properties?: ElementPropertiesParams;
  fields: FieldParams[];
  isNew?: boolean;
  isRequired?: boolean;
  isClientHide?: boolean;
  briefBlockId?: string;
}

export interface FieldParams {
  id: string;
  blockId: string;
  type: ItemType | FieldType;
  order: number;
  properties?: ElementPropertiesParams;
  isNew?: boolean;
}

export interface ElementPropertiesParams {
  name?: string;
  parentFieldId?: string;
  switchPropertyId?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isNumeric?: boolean;
  isLatin?: boolean;
  isUrl?: boolean;
  isRange?: boolean;
  isCalendar?: boolean;
  isInput?: boolean;
  isCalculated?: boolean;
  isBlockCalculation?: boolean;
  canRemove?: boolean;
  duplicateToReport?: boolean;
  selectContent?: SelectItem[];
  switches?: SwitchItem[];
  switchType?: SwitchType;
  showSwitchTooltip?: boolean;
  formulas?: Formula[];
  isHasExtraFields?: boolean;
  isCalendarDefault?: boolean;
  isDictionary?: boolean;
  dictionaryType?: string;
  isMultiple?: boolean;
  isClientHide?: boolean;
  isFileImported?: boolean;
  files?: BriefPropertyFile[];
}

export enum TogglePosition {
  LEFT = "left",
  RIGHT = "right",
}

export interface AddBlockParams {
  schemaId: string;
  briefBlockId: string;
  order: number;
}

export interface UpdateParentBlockParams {
  id: string;
  briefBlockId?: string;
  order?: number;
}

export interface EditBlockParams {
  id: string;
  name?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isClientHide?: boolean;
}

export interface EditFieldPropertiesParams {
  id: string;
  blockId?: string;
  switchPropertyId?: string;
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  isRequired?: boolean;
  isNumeric?: boolean;
  isLatin?: boolean;
  isUrl?: boolean;
  isRange?: boolean;
  isCalendar?: boolean;
  isCalculated?: boolean;
  isBlockCalculation?: boolean;
  isInput?: boolean;
  duplicateToReport?: boolean;
  selectContent?: SelectItem[];
  switches?: SwitchItem[];
  switchType?: SwitchType;
  showSwitchTooltip?: boolean;
  formulas?: Formula[];
  isHasExtraFields?: boolean;
  isCalendarDefault?: boolean;
  isDictionary?: boolean;
  dictionaryType?: string;
  isMultiple?: boolean;
  isClientHide?: boolean;
  isFileImported?: boolean;
  files?: BriefPropertyFile[];
}

export interface BriefPropertyFile {
  id?: string;
  fileId?: string;
  name?: string;
  type?: string;
  storage?: string;
  category?: string;
  originName?: string;
}

export interface ChangeBlockOrderParams {
  id: string;
  briefBlockId?: string;
  oldOrder: number;
  newOrder: number;
}

export interface ChangeFieldOrderParams extends ChangeBlockOrderParams {
  blockId: string;
}

export interface AddFieldParams {
  blockId: string;
  type: ItemType | FieldType;
  order: number;
  properties?: ElementPropertiesParams;
}

export interface RemoveFieldParams {
  fieldId: string;
  blockId: string;
}

export interface RemoveFieldsByTypeParams {
  fieldType: FieldType;
  blockId: string;
}

export interface ChangeSelectedElementParams {
  selectedElementId?: string;
  selectedBlockId?: string;
  selectedItemType: ItemType | FieldType;
  selectedFieldItemId?: string;
}

export interface ChangeBriefNameParams {
  newName: string;
}

export interface UpdateBriefPageParams {
  name?: string;
  briefSchemeId?: string;
  activityCounts?: number;
  isSchemeActive?: boolean;
  isSchemeEditable?: boolean;
  elements?: BlockParams[];
  selectedElementId?: string;
  selectedBlockId?: string;
  selectedFieldItemId?: string;
  selectedItemType?: ItemType;
}

export const enum ExpressionType {
  Func = "func",
  Const = "const",
}

export const enum PresetFunction {
  Add = "+",
  Sub = "-",
  Mul = "*",
  Div = "/",
}

export { FieldType };
export type { Formula, FunctionExpression };
