import * as React from "react";
import { connect } from "react-redux";

import type { DepartmentAttributes } from "@sm/types/admin";

import { DepartmentNavigation } from "./DepartmentNavigation";
import type { StoreState } from "../../../store";
import { getSelectedDepartments } from "../../../store/departmentRolePage/selector";

interface Props extends MapProps {}

interface MapProps {
  departments?: DepartmentAttributes[];
}

@(connect(mapStateToProps, null) as any)
export class DepartmentNavigationContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return React.createElement(DepartmentNavigation, {
      departments: this.props.departments,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    departments: getSelectedDepartments(state),
  };
}
