// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__hidden{display:none}.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__root{width:744px;padding:40px 28px;background-color:#ffffff}.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__title{width:100%;margin-bottom:32px;color:#3b8200;font-size:18px;font-weight:500}.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__buttons{display:flex}.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__button{width:240px;height:24px}.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__button+.src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__button{margin-left:24px}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/DepartmentRolePage/EditBudgetForm/EditBudgetForm.scss"],"names":[],"mappings":"AAYA,uEACI,YAAa,CCXjB,qEACI,WAAY,CACZ,iBAAkB,CAElB,wBAAyB,CAC5B,sEAGG,UAAW,CACX,kBAAmB,CAEnB,aDZe,CCcf,cAAe,CACf,eAAgB,CACnB,wEAGG,YAAa,CAChB,uEAGG,WAAY,CACZ,WAAY,CAFhB,8IAKQ,gBAAiB","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import '../../common/mixins/mixin';\n\n.root {\n    width: 744px;\n    padding: 40px 28px;\n\n    background-color: #ffffff;\n}\n\n.title {\n    width: 100%;\n    margin-bottom: 32px;\n\n    color: $DeepGreen;\n\n    font-size: 18px;\n    font-weight: 500;\n}\n\n.buttons {\n    display: flex;\n}\n\n.button {\n    width: 240px;\n    height: 24px;\n\n    & + & {\n        margin-left: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__hidden",
	"root": "src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__root",
	"title": "src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__title",
	"buttons": "src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__buttons",
	"button": "src-modules-DepartmentRolePage-EditBudgetForm-_EditBudgetForm__button"
};
export default ___CSS_LOADER_EXPORT___;
