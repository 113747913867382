import { DepartmentAttributes } from "@sm/types/admin";
import {
  DepartmentListPageState,
  DepartmentListPageDepartmentCreationBlockState,
} from "../types";

export class DepartmentCreationBlockReducer {
  public static reset(state: DepartmentListPageState): DepartmentListPageState {
    const departmentCreation: DepartmentListPageDepartmentCreationBlockState = {
      departments: [],
      selectedDepartmentsIds: [],
      isNewDepartmentAdded: true,
    };

    return {
      ...state,
      departmentCreation,
    };
  }

  public static addDepartment(
    state: DepartmentListPageState,
    newDepartment: DepartmentAttributes
  ): DepartmentListPageState {
    const departmentCreation = { ...state.departmentCreation };
    departmentCreation.departments = [
      ...departmentCreation.departments,
      newDepartment,
    ];
    departmentCreation.isNewDepartmentAdded = false;

    return {
      ...state,
      departmentCreation,
    };
  }

  public static updateDepartment(
    state: DepartmentListPageState,
    departmentToUpdate: DepartmentAttributes
  ): DepartmentListPageState {
    const departmentCreation = { ...state.departmentCreation };

    departmentCreation.departments = departmentCreation.departments.map(
      (department) =>
        department.id === departmentToUpdate.id
          ? departmentToUpdate
          : department
    );

    return {
      ...state,
      departmentCreation,
    };
  }

  public static deleteDepartment(
    state: DepartmentListPageState,
    id: string
  ): DepartmentListPageState {
    const departmentCreation = { ...state.departmentCreation };
    const selectedDepartmentsIdsInitialLength = 1;

    departmentCreation.departments = departmentCreation.departments.filter(
      (department) => department.id !== id
    );
    departmentCreation.selectedDepartmentsIds =
      departmentCreation.selectedDepartmentsIds.slice(0, -1);
    departmentCreation.isNewDepartmentAdded =
      departmentCreation.selectedDepartmentsIds.length ==
      selectedDepartmentsIdsInitialLength
        ? true
        : false;

    return {
      ...state,
      departmentCreation,
    };
  }

  public static setIsNewDepartmentAdded(
    state: DepartmentListPageState,
    value: boolean
  ): DepartmentListPageState {
    const departmentCreation = { ...state.departmentCreation };
    departmentCreation.isNewDepartmentAdded = value;

    return {
      ...state,
      departmentCreation,
    };
  }

  public static setSelectedDepartment(
    state: DepartmentListPageState,
    id: string
  ): DepartmentListPageState {
    const departmentCreation = { ...state.departmentCreation };
    const parentId = departmentCreation.departments.find(
      (department) => department.id === id
    ).parentDepartmentId;
    const selectedIndex =
      departmentCreation.selectedDepartmentsIds.indexOf(parentId);

    departmentCreation.selectedDepartmentsIds =
      departmentCreation.selectedDepartmentsIds.slice(0, selectedIndex + 1);
    departmentCreation.selectedDepartmentsIds.push(id);

    return {
      ...state,
      departmentCreation,
    };
  }
}
