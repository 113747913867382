import * as React from "react";

import * as style from "./DepartmentRolePage.scss";

import { ResponsiveLayout, LeftSidebar, CentralContent } from "@sm/ui";
import { TopLine } from "./TopLine";
import { Page } from "../common/Page";
import { DepartmentNavigation } from "./DepartmentNavigation";
import { UsersTable } from "./UsersTable";

interface Props {
  pageLabel: string;
  preloader: boolean;
  previousUrl: string;
  previousLabel: string;
  canBeUsersAdded: boolean;
  canBePlanBudgetAdded: boolean;
  canBeExecutionBudgetAdded: boolean;
  addPlanBudget: () => void;
  addExecutionBudget: () => void;
}

export function DepartmentRolePage({
  preloader,
  pageLabel,
  previousUrl,
  previousLabel,
  canBeUsersAdded,
  canBePlanBudgetAdded,
  canBeExecutionBudgetAdded,
  addPlanBudget,
  addExecutionBudget,
}: Props): JSX.Element {
  return (
    <Page
      preloader={preloader}
      pageLabel={pageLabel}
      previousLabel={previousLabel}
      previousUrl={previousUrl}
      customFirstLine={() => (
        <TopLine
          pageLabel={pageLabel}
          canBeUsersAdded={canBeUsersAdded}
          canBePlanBudgetAdded={canBePlanBudgetAdded}
          canBeExecutionBudgetAdded={canBeExecutionBudgetAdded}
          addPlanBudget={addPlanBudget}
          addExecutionBudget={addExecutionBudget}
        />
      )}
      customLayout
    >
      {!preloader && (
        <div className={style.root}>
          <ResponsiveLayout>
            <LeftSidebar fixed collapsable>
              <div className={style.sidebar}>
                <DepartmentNavigation />
              </div>
            </LeftSidebar>

            <CentralContent>
              <div className={style.content}>
                <UsersTable />
              </div>
            </CentralContent>
          </ResponsiveLayout>
        </div>
      )}
    </Page>
  );
}
