import * as React from "react";
import { Button, ButtonTheme, CustomScrollbar } from "@sm/ui";

import { BlockParams } from "../../../store/brief/types";

import { SchemeMenu, ElementMenu } from "../Menu";
import { ElementsOrder } from "../ElementsOrder";
import { ContentNav } from "../ContentNav";

import * as style from "./TopLine.scss";

interface Props {
  onSaveButtonClick: () => void;
  onCopyButtonClick: () => void;
  onCancelCopyButtonClick: () => void;
  onPreviewModeToggleClick: () => void;
  blocks: BlockParams[];
  isPreviewModeEnabled: boolean;
  isSchemeEditable: boolean;
  isFormulaEditable: boolean;
  showCopyButton: boolean;
  showCancelCopyButton: boolean;
  selectedBlockId: string;
  selectedElementId: string;
}

export function TopLine({
  onSaveButtonClick,
  onCopyButtonClick,
  onCancelCopyButtonClick,
  onPreviewModeToggleClick,
  blocks,
  isPreviewModeEnabled,
  isSchemeEditable,
  isFormulaEditable,
  showCopyButton,
  showCancelCopyButton,
  selectedBlockId,
  selectedElementId,
}: Props): JSX.Element {
  const leftSidebarIsEmpty =
    blocks.length == 0 ||
    (selectedBlockId &&
      blocks.filter((item) => item.briefBlockId === selectedBlockId).length ==
        0 &&
      blocks.find((item) => item.id === selectedBlockId).fields.length == 0);

  return (
    <div className={style.root}>
      <div className={style.leftSidebar}>
        <div className={style.buttonBlock}>
          {(isSchemeEditable || isFormulaEditable) && !isPreviewModeEnabled && (
            <div className={style.button}>
              <Button
                theme={ButtonTheme.PrimaryMain}
                onClick={onSaveButtonClick}
              >
                Сохранить
              </Button>
            </div>
          )}

          {showCopyButton && (
            <div className={style.button}>
              <Button
                theme={ButtonTheme.PrimaryOutline}
                onClick={onCopyButtonClick}
              >
                В копию
              </Button>
            </div>
          )}

          {showCancelCopyButton && (
            <div className={style.button}>
              <Button
                theme={ButtonTheme.PrimaryOutline}
                onClick={onCancelCopyButtonClick}
              >
                Отмена
              </Button>
            </div>
          )}
        </div>

        {!leftSidebarIsEmpty && (
          <div className={style.leftSidebarButtons}>
            {isSchemeEditable && !isPreviewModeEnabled && (
              <div className={style.cards}>
                <CustomScrollbar
                  hideTracksWhenNotNeeded
                  renderView={(props) => (
                    <div {...props} className={style.scrollView} />
                  )}
                >
                  <ElementsOrder />
                  <ElementsOrder subBlocks />
                </CustomScrollbar>
              </div>
            )}

            {isPreviewModeEnabled && blocks.length > 1 && (
              <ContentNav
                items={blocks.map((item) => ({
                  id: item.id,
                  parent: item.briefBlockId,
                  order: item.order,
                  title: item.name || "Новый блок",
                }))}
              />
            )}
          </div>
        )}
      </div>

      <div className={style.rightSidebar}>
        <div className={style.rightSidebarButton}>
          <Button
            theme={ButtonTheme.PrimaryMain}
            onClick={onPreviewModeToggleClick}
          >
            {isPreviewModeEnabled ? "Редактирование" : "Предпросмотр"}
          </Button>
        </div>

        {(isSchemeEditable || isFormulaEditable) &&
          !isPreviewModeEnabled &&
          (selectedBlockId || selectedElementId ? (
            <ElementMenu />
          ) : (
            <SchemeMenu />
          ))}
      </div>
    </div>
  );
}
