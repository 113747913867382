import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import * as lodash from "lodash";

import {
  BriefState,
  BlockParams,
  FieldParams,
  ElementPropertiesParams,
  CalculationLogicPopupParams,
} from "../types";
import { StoreState } from "../../";
import { Utils } from "../../../modules/common/Utils";

const getBriefState = (state: StoreState): BriefState => state.brief;

export const getBriefSchemeId = createSelector(
  getBriefState,
  (state: BriefState): string => state.briefSchemeId || null
);

export const getSchemeBlocks = createSelector(
  getBriefState,
  (state: BriefState): BlockParams[] => {
    return lodash.sortBy(state.elements, "order");
  }
);

export const getSchemeListBlocks = createSelector(
  getBriefState,
  (state: BriefState): BlockParams[] => {
    const elements = state.elements.filter((item) => !item.briefBlockId);
    return lodash.sortBy(elements, "order");
  }
);

export const getSchemeFields = createSelector(
  getSchemeBlocks,
  (blocks: BlockParams[]): FieldParams[] => {
    const fields = lodash.flatMap(blocks, (item) => item.fields);

    return lodash.sortBy(fields, "order");
  }
);

export const getBlockById = createCachedSelector(
  getSchemeBlocks,
  (state: StoreState, blockId: string): string => blockId,
  (blocks: BlockParams[], blockId: string): BlockParams => {
    return blocks.find((item) => item.id === blockId);
  }
)((state: StoreState, blockId: string): string => blockId);

export const getSubBlocksById = createCachedSelector(
  getSchemeBlocks,
  (state: StoreState, blockId: string): string => blockId,
  (blocks: BlockParams[], blockId: string): BlockParams[] => {
    return blocks.filter((item) => item.briefBlockId === blockId);
  }
)((state: StoreState, blockId: string): string => blockId);

export const getFieldsByBlockId = createCachedSelector(
  getBlockById,
  (state: StoreState, blockId: string): string => blockId,
  (block: BlockParams, blockId: string): FieldParams[] => {
    return lodash.sortBy(block.fields, "order");
  }
)((state: StoreState, blockId: string): string => blockId);

export const getFieldById = createCachedSelector(
  getSchemeBlocks,
  (state: StoreState, fieldId: string): string => fieldId,
  (blocks: BlockParams[], fieldId: string): FieldParams => {
    return lodash
      .flatMap(blocks, (item) => item.fields)
      .find((item) => item.id === fieldId);
  }
)((state: StoreState, fieldId: string): string => fieldId);

export const getCalculationLogicPopupParams = createSelector(
  getBriefState,
  (state: BriefState): CalculationLogicPopupParams =>
    state.calculationLogicPopup || null
);

export const getSelectedBlockId = createSelector(
  getBriefState,
  (state: BriefState): string => {
    return state.selectedBlockId;
  }
);

export const getSelectedElementId = createSelector(
  getBriefState,
  (state: BriefState): string => {
    return state.selectedElementId;
  }
);

export const getSelectedFieldItemId = createSelector(
  getBriefState,
  (state: BriefState): string => {
    return state.selectedFieldItemId;
  }
);

export const getSelectedElementType = createSelector(
  getBriefState,
  (state: BriefState): string => {
    return state.selectedItemType;
  }
);

export const getBlockProperties = createCachedSelector(
  getSchemeBlocks,
  (state: StoreState, blockId: string): string => blockId,
  (blocks: BlockParams[], blockId: string): ElementPropertiesParams => {
    const block = Utils.getItemById(blocks, blockId);

    return block.properties;
  }
)((state: StoreState, blockId: string): string => blockId);

export const getFieldProperties = createCachedSelector(
  getFieldById,
  (field: FieldParams): ElementPropertiesParams => {
    return field.properties;
  }
)(
  (state: StoreState, blockId: string, fieldId: string): string =>
    `${blockId} ${fieldId}`
);

export const isValidationErrorShowed = createSelector(
  getBriefState,
  (state: BriefState): boolean => {
    return state.showValidationErrors;
  }
);
