import * as React from "react";

import * as style from "./AddWorkTypeRow.scss";

import { AutoFocusedInput, InputThemes } from "@sm/ui";

interface Props {
  name: string;
  onInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function AddWorkTypeRow({ name, onInputBlur }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.nameColumnWrapper}>
        <AutoFocusedInput
          value={name}
          onBlur={onInputBlur}
          theme={InputThemes.Simple}
        />
      </div>
    </div>
  );
}
