import * as React from "react";
import autobind from "autobind-decorator";

import {
  PermissionResponse,
  PermissionSubjectResponse,
  OrganizationView,
  DepartmentAttributes,
} from "@sm/types/backend";
import { BudgetDTO } from "@mrm/budget/Budget";
import type { PlainDictionary } from "@sm/types/budget";

import { EditForm, Action } from "./EditForm";

import { PermissionApi } from "../../api";

interface Props {
  permission: PermissionResponse & { id: string };
  permissions: (PermissionResponse & { id: string })[];
  budgets: { [id: string]: BudgetDTO };
  organizations: { [id: string]: OrganizationView };
  departments: { [id: string]: DepartmentAttributes };
  dictionaries: { [id: string]: PlainDictionary };
  permissionSubjects: { [id: number]: PermissionSubjectResponse };
  update: () => Promise<void>;
}

interface State extends PermissionResponse {
  preloader: boolean;
}

const EDIT_TITLE = "Редактирование";
const CREATE_TITLE = "Создание нового";

export class EditFormContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { permission } = this.props;

    this.state = {
      preloader: false,
      budgetId: permission ? permission.budgetId : "",
      organizationId: permission ? permission.organizationId : "",
      departmentId: permission ? permission.departmentId : "",
      dictionaryIds: permission ? permission.dictionaryIds : [],
      subjectId: permission ? permission.subjectId : 0,
    };
  }

  public render() {
    const {
      budgets,
      organizations,
      departments,
      dictionaries,
      permissionSubjects,
    } = this.props;
    const { preloader, ...state } = this.state;

    return React.createElement(EditForm, {
      action: this.props.permission ? Action.EDIT : Action.CREATE,
      title: this.props.permission ? EDIT_TITLE : CREATE_TITLE,
      permission: {
        ...state,
      },
      preloader,
      permissionSubjects,
      budgets,
      organizations,
      departments,
      dictionaries,
      onChange: this.onChange,
      onDictionaryChange: this.onDictionaryChange,
      onSave: this.handleSave,
    });
  }

  public async componentDidMount() {}

  @autobind
  protected onChange<T extends keyof State>(key: T, value: State[T]) {
    this.setState((state) => ({
      ...state,
      [key]: value,
    }));
  }

  @autobind
  protected onDictionaryChange(value: string) {
    this.setState((state) => {
      const { dictionaryIds } = state;
      const included = dictionaryIds.includes(value);
      const ids = !included
        ? [...dictionaryIds, value]
        : dictionaryIds.filter((id) => id !== value);

      return {
        ...state,
        dictionaryIds: ids,
      };
    });
  }

  @autobind
  protected async handleSave() {
    this.setState({
      preloader: true,
    });

    await this.save();

    this.setState({
      preloader: false,
    });
  }

  private async save() {
    if (this.props.permission) {
      await this.update();
    } else {
      await this.create();
    }

    this.props.update();
  }

  private async create() {
    const { preloader, ...state } = this.state;

    await PermissionApi.createPermission({ ...state });
  }

  private async update() {
    const { id, ...permission } = this.props.permission;
    const { preloader, ...state } = this.state;

    await PermissionApi.updatePermission({
      old: { ...permission },
      new: { ...state },
    });
  }
}
