export { PresetFunction, ExpressionType } from "../../../store/brief/types";

export const enum OperationType {
  ADDITION = "Addition",
  SUBTRACTION = "Subtraction",
  MULTIPLICATION = "Multiplication",
  DIVISION = "Division",
}

export const enum Type {
  OPERAND = "Operand",
  OPERATION = "Operation",
}

export const enum DictionaryStatus {
  ACTIVE = "active",
  DELETED = "deleted",
}
