import { createSelector } from "reselect";

import { StoreState } from "../../";
import { CommonState } from "../types";

const getCommonState = (state: StoreState): CommonState => state.common;

export const getOnValidation = createSelector(
  getCommonState,
  (state: CommonState): boolean => {
    return state.onValidation;
  }
);

export const getErrorCode = createSelector(
  getCommonState,
  (state: CommonState): string => state.errorCode
);
