import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { ItemType } from "../../../ItemType";
import {
  BlockParams,
  ChangeSelectedElementParams,
  FieldType,
  RemoveFieldParams,
} from "../../../../../store/brief/types";

import { Block } from "./Block";
import { StoreState } from "../../../../../store";
import {
  normalizeElementsOrders,
  removeField,
  setSelectedElement,
} from "../../../../../store/brief/actions";
import {
  getBlockById,
  getSelectedBlockId,
  getSubBlocksById,
} from "../../../../../store/brief/selector";

interface Props extends MapProps, DispatchProps {
  id: string;
  parents: string[];
  onBlockRemoveClick: (blockId: string) => void;
}

interface MapProps {
  block?: BlockParams;
  blocks?: BlockParams[];
  selectedBlockId?: string;
  isSchemeActive?: boolean;
  isSchemeEditable?: boolean;
  isPreviewModeEnabled?: boolean;
}

interface DispatchProps {
  removeField?: (params: RemoveFieldParams) => void;
  setSelectedElement?: (params: ChangeSelectedElementParams) => void;
  normalizeElementsOrders?: () => void;
}

interface State {
  isBlockOnDrag: false;
  isFieldOnDrag: false;
  isVisibleFields: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BlockContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBlockOnDrag: false,
      isFieldOnDrag: false,
      isVisibleFields: true,
    };
  }

  public componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ) {
    if (
      !nextProps.isPreviewModeEnabled &&
      this.state.isVisibleFields === false
    ) {
      this.setState({
        isVisibleFields: true,
      });
    }
  }

  public render() {
    const {
      id,
      parents,
      block,
      blocks,
      selectedBlockId,
      isPreviewModeEnabled,
      isSchemeEditable,
      isSchemeActive,
      onBlockRemoveClick,
    } = this.props;

    const { isVisibleFields } = this.state;

    const isHide = isPreviewModeEnabled && block?.isClientHide;

    if (isHide) {
      return null;
    }

    return React.createElement(Block, {
      id,
      parents,
      isSelected: selectedBlockId == id,
      canRemove:
        isSchemeEditable ||
        (isSchemeActive &&
          !(this.props.block.fields || []).find(
            (field) => field.properties?.parentFieldId
          )),
      isNotRequired: this.isNotRequiredBlock,
      isVisibleFields,
      isToggleButtonDisabled: !isPreviewModeEnabled,
      name: block.name,
      tooltipMessage: block.tooltipMessage,
      parent: block.briefBlockId,
      order: block.order,
      blocks,
      fields: this.getFieldsWithoutTypeToggle().sort(
        (field1, field2) => field1.order - field2.order
      ),
      fieldsLength: this.props.block.fields.length,
      removeField: this.removeField,
      onToggleSelect: this.handleToggleSelect,
      onToggleButtonClick: this.handleToggleButtonClick,
      onBlockRemove: this.removeBlock,
      onBlockRemoveClick: onBlockRemoveClick,
    });
  }

  @autobind
  public async removeField(fieldId: string) {
    this.props.removeField({
      blockId: this.props.id,
      fieldId,
    });

    this.props.normalizeElementsOrders();
  }

  @autobind
  public async removeBlock() {
    this.props.onBlockRemoveClick(this.props.id);

    this.props.setSelectedElement({
      selectedElementId: null,
      selectedBlockId: null,
      selectedItemType: null,
    });

    this.props.normalizeElementsOrders();
  }

  @autobind
  protected handleToggleSelect() {
    if (!this.props.isPreviewModeEnabled) {
      const isSelected = this.props.selectedBlockId == this.props.id;

      this.props.setSelectedElement({
        selectedBlockId: isSelected ? null : this.props.id,
        selectedElementId: null,
        selectedItemType: isSelected ? null : ItemType.BLOCK,
      });
    }
  }

  @autobind
  protected handleToggleButtonClick(): void {
    if (this.props.isPreviewModeEnabled) {
      this.setState((state) => ({
        isVisibleFields: !state.isVisibleFields,
      }));
    }
  }

  private get isNotRequiredBlock(): boolean {
    return this.haveFieldsWithTypeToggle();
  }

  private haveFieldsWithTypeToggle(): boolean {
    const { fields } = this.props.block;
    return fields.some(({ type }) => type === FieldType.TOGGLE);
  }

  private getFieldsWithoutTypeToggle() {
    const { fields } = this.props.block;
    return fields.filter(
      ({ type, properties = {} }) =>
        type !== FieldType.TOGGLE && !properties.switchPropertyId
    );
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    block: getBlockById(state, ownProps.id),
    blocks: getSubBlocksById(state, ownProps.id),
    selectedBlockId: getSelectedBlockId(state),
    isSchemeActive: state.brief.isSchemeActive,
    isSchemeEditable: state.brief.isSchemeEditable,
    isPreviewModeEnabled: state.brief.isPreviewModeEnabled,
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      removeField,
      setSelectedElement,
      normalizeElementsOrders,
    },
    dispatch
  );
}
