import * as React from "react";

import * as style from "./TextCell.scss";

interface Props {
  title: string;
}

export const TextCellTemplate = ({ title }: Props): JSX.Element => {
  return <div className={style.root}>{title}</div>;
};
