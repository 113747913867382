import { actionCreatorFactory } from "typescript-fsa";

import { LoadingStatus } from "../utils";

import { SavePageDataPayload, SelectItemsPayload } from "./types";

const actionCreator = actionCreatorFactory("CALENDAR_SHARING");

const loadPageDataAsync = actionCreator.async<null, void, Error>(
  "LOAD_PAGE_DATA_ASYNC"
);
const savePendingSharingsAsync = actionCreator.async<null, void, Error>(
  "SAVE_PENDING_SHARINGS_ASYNC"
);
const deleteExistingSharingAsync = actionCreator.async<string, void, Error>(
  "DELETE_EXISTING_SHARING_ASYNC"
);
const updateSharingAsync = actionCreator.async<string, void, Error>(
  "UPDATE_SHARING_ASYNC"
);

const setLoadingStatus = actionCreator<LoadingStatus>("SET_LOADING_STATUS");
const savePageData = actionCreator<SavePageDataPayload>("SAVE_PAGE_DATA");
const createSharing = actionCreator<string>("CREATE_SHARING");
const deleteSharing = actionCreator<string>("DELETE_SHARING");
const selectActivityTypes = actionCreator<SelectItemsPayload>(
  "SELECT_ACTIVITY_TYPES"
);
const selectBlocks = actionCreator<SelectItemsPayload>("SELECT_BLOCKS");
const transferPendingSharings = actionCreator("TRANSFER_PENDING_SHARINGS");
const deleteSharingEntry = actionCreator<string>("DELETE_SHARING_ENTRY");
const resetUnsavedSharings = actionCreator("RESET_UNSAVED_SHARINGS");

export {
  loadPageDataAsync,
  savePendingSharingsAsync,
  deleteExistingSharingAsync,
  updateSharingAsync,
  setLoadingStatus,
  savePageData,
  createSharing,
  deleteSharing,
  selectActivityTypes,
  selectBlocks,
  transferPendingSharings,
  deleteSharingEntry,
  resetUnsavedSharings,
};
