import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";

import { ChangeBriefNameParams } from "../../../../store/brief/types";

import { BriefName } from "./BriefName";
import { StoreState } from "../../../../store";
import { changeBriefName } from "../../../../store/brief/actions";

interface Props extends MapProps, DispatchProps {}

interface MapProps {
  name?: string;
  isPreviewModeEnabled?: boolean;
  isSchemeEditable?: boolean;
}

interface DispatchProps {
  saveBriefName?: (params: ChangeBriefNameParams) => void;
}

interface State {
  name: string;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefNameContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      name: props.name,
    };
  }

  public render(): JSX.Element {
    return (
      <BriefName
        name={this.state.name}
        onInputChange={this.onInputChange}
        onInputBlur={this.onInputBlur}
        disabled={
          this.props.isPreviewModeEnabled || !this.props.isSchemeEditable
        }
        isPreviewModeEnabled={this.props.isPreviewModeEnabled}
      />
    );
  }

  @autobind
  protected onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      name: event.target.value,
    });
  }

  @autobind
  protected onInputBlur(): void {
    this.props.saveBriefName({
      newName: this.state.name,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    name: state.brief.name,
    isPreviewModeEnabled: state.brief.isPreviewModeEnabled,
    isSchemeEditable:
      state.brief.isSchemeEditable || state.brief.isSchemeActive,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    saveBriefName: (params) => dispatch(changeBriefName(params)),
  };
}
