import { reducerWithInitialState } from "typescript-fsa-reducers";

import { BriefScheme, DepartmentAttributes } from "@sm/types/admin";
import { BriefSchemeListPageState, BriefSchemeListFilterMode } from "../types";

import {
  loadPageState,
  setBriefSchemeListFilter,
  removeScheme,
  updateScheme,
} from "../actions";

import { BriefSchemeListReducer } from "./BriefSchemeListReducer";

const initialState: BriefSchemeListPageState = {
  briefSchemes: [] as BriefScheme[],
  departments: [] as DepartmentAttributes[],
  selectedFilter: BriefSchemeListFilterMode.Actual,
};

export const briefSchemeListPageReducer = reducerWithInitialState(initialState)
  .case(loadPageState, BriefSchemeListReducer.load)
  .case(setBriefSchemeListFilter, BriefSchemeListReducer.setFilter)
  .case(removeScheme, BriefSchemeListReducer.removeScheme)
  .case(updateScheme, BriefSchemeListReducer.updateScheme);
