import * as React from "react";
import {
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
  Button_redesign as Button,
  ButtonTheme_redesign as ButtonTheme,
} from "@sm/ui";

import { SharingState } from "../../store/calendarSharing";

import { Page, PageProps } from "../common/Page";

import { NewSharing } from "./NewSharing";
import { SharingCard } from "./SharingCard";

import * as styles from "./EditCalendarSharingPage.scss";

interface Props extends PageProps {
  pendingSharings: SharingState[];
  displayNewSharingCard: boolean;
  isInEditMode?: boolean;
  onSaveButtonClick: () => void;
}

export function EditCalendarSharingPage({
  preloader,
  previousLabel,
  previousUrl,
  isInEditMode,
  pendingSharings,
  displayNewSharingCard,
  onSaveButtonClick,
}: Props): JSX.Element {
  const disableSaveButton = !pendingSharings.length;

  return (
    <Page
      pageLabel={isInEditMode ? "Редактирование доступа" : "Создание доступа"}
      preloader={preloader}
      previousLabel={previousLabel}
      previousUrl={previousUrl}
      withoutNavigation
      customLayout
    >
      {!preloader && (
        <div className={styles.root}>
          <ResponsiveLayout>
            <LeftSidebar />

            <CentralContent>
              <React.Fragment>
                {pendingSharings.map((sharing) => (
                  <div
                    key={sharing.organization.id}
                    className={styles.sharingCard}
                  >
                    <SharingCard {...sharing} canRemove={!isInEditMode} />
                  </div>
                ))}

                {displayNewSharingCard && <NewSharing />}
              </React.Fragment>
            </CentralContent>

            <RightSidebar>
              <div className={styles.sidebar}>
                <Button
                  theme={ButtonTheme.MainRoundedUpdated}
                  disabled={disableSaveButton}
                  onClick={onSaveButtonClick}
                >
                  Сохранить
                </Button>
              </div>
            </RightSidebar>
          </ResponsiveLayout>
        </div>
      )}
    </Page>
  );
}
