import * as React from "react";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { WorkTypeParams, DepartmentAttributes } from "@sm/types/admin";

import { WorkTypeRow, TogglePosition } from "./WorkTypeRow";
import {
  addDepartment,
  removeDepartment,
  remove,
  update,
  enableRates,
  disableRates,
} from "../../../store/workType/actions";
import { getWorkType, getDepartments } from "../../../store/workType/selector";
import type {
  AddDepartmentParams,
  RemoveDepartmentParams,
  UpdateWorkTypeParams,
  EnableRatesParams,
  DisableRatesParams,
} from "../../../store/workType/types";
import type { StoreState } from "../../../store";
import { WorkTypeApi } from "../../../api";

interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  workType?: WorkTypeParams;
  departments?: DepartmentAttributes[];
}

interface DispatchProps {
  isEnabledRates?: boolean;
  addDepartment?: (params: AddDepartmentParams) => void;
  removeDepartment?: (params: RemoveDepartmentParams) => void;
  remove?: (id: string) => void;
  update?: (params: UpdateWorkTypeParams) => void;
  enableRates?: (params: EnableRatesParams) => void;
  disableRates?: (params: DisableRatesParams) => void;
}

interface State {
  errorMessage: string;
  isErrorPopupOpen: boolean;
  isDisabledToggle: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WorkTypeRowContainer extends React.Component<Props> {
  public state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: "",
      isErrorPopupOpen: false,
      isDisabledToggle: false,
    };
  }

  public render(): JSX.Element {
    const departments = this.getDepartmentNames();

    return React.createElement(WorkTypeRow, {
      departments,
      errorMessage: this.state.errorMessage,
      isErrorPopupOpen: this.state.isErrorPopupOpen,
      name: this.props.workType.name,
      togglePosition: this.isEnabledRates
        ? TogglePosition.RIGHT
        : TogglePosition.LEFT,
      isDisabledToggle: this.state.isDisabledToggle,
      selectedIds: this.props.workType ? this.props.workType.departmentIds : [],
      onRemoveClick: this.handleRemoveClick,
      onInputBlur: this.handleInputBlur,
      onClosePopup: this.handleCloseErrorPopup,
      onToggleClick: this.onToggleClick,
      onDepartmentSelect: this.handleDepartmentCheckboxChange,
    });
  }

  @autobind
  protected handleCloseErrorPopup() {
    this.setState({
      errorMessage: "",
      isErrorPopupOpen: false,
    });
  }

  @autobind
  protected onToggleClick() {
    if (this.isEnabledRates) {
      this.disableRates();
    } else {
      this.enableRates();
    }
  }

  private async enableRates(): Promise<void> {
    try {
      this.setState({ isDisabledToggle: true });
      this.props.enableRates({ workTypeId: this.props.workType.id });
      await WorkTypeApi.enableRates({ workTypeId: this.props.workType.id });
    } catch (_) {
      this.props.disableRates({ workTypeId: this.props.workType.id });
    } finally {
      this.setState({ isDisabledToggle: false });
    }
  }

  private async disableRates(): Promise<void> {
    try {
      this.setState({ isDisabledToggle: true });
      this.props.disableRates({ workTypeId: this.props.workType.id });
      await WorkTypeApi.disableRates({ workTypeId: this.props.workType.id });
    } catch (_) {
      this.props.enableRates({ workTypeId: this.props.workType.id });
    } finally {
      this.setState({ isDisabledToggle: false });
    }
  }

  @autobind
  protected async handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    const name = event.currentTarget.value as string;

    await WorkTypeApi.updateWorkType(this.props.id, name);

    this.props.update({
      id: this.props.id,
      name,
    });
  }

  @autobind
  protected handleDepartmentCheckboxChange(
    isChecked: boolean,
    departmentId: string
  ) {
    if (isChecked) {
      this.processAddDepartment(departmentId);
    } else {
      this.processRemoveDepartment(departmentId);
    }
  }

  @autobind
  protected async handleRemoveClick() {
    const { error } = await WorkTypeApi.remove(this.props.id);

    if (error) {
      this.setState({
        errorMessage: error,
        isErrorPopupOpen: true,
      });
    } else {
      this.props.remove(this.props.id);
    }
  }

  private getDepartmentNames(): string {
    const {
      departments,
      workType: { departmentIds },
    } = this.props;

    return departments
      .filter(
        (department) =>
          !!lodash.find(departmentIds, (id) => id == department.id)
      )
      .map((item) => item.name)
      .join(", ");
  }

  private async processAddDepartment(departmentId: string) {
    const { id } = this.props;

    await WorkTypeApi.addDepartment(id, departmentId);

    this.props.addDepartment({
      id,
      departmentId,
    });
  }

  private async processRemoveDepartment(departmentId: string) {
    await WorkTypeApi.removeDepartment(this.props.id, departmentId);

    this.props.removeDepartment({
      workTypeId: this.props.id,
      departmentId,
    });
  }

  private get isEnabledRates(): boolean {
    const { workType } = this.props;
    return workType.enable_rates;
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  return {
    workType: getWorkType(state, ownProps.id),
    departments: getDepartments(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    addDepartment: (params: AddDepartmentParams) =>
      dispatch(addDepartment(params)),
    removeDepartment: (params: RemoveDepartmentParams) =>
      dispatch(removeDepartment(params)),
    remove: (id: string) => dispatch(remove(id)),
    update: (params: UpdateWorkTypeParams) => dispatch(update(params)),
    enableRates: (params) => dispatch(enableRates(params)),
    disableRates: (params) => dispatch(disableRates(params)),
  };
}
