import * as React from "react";

import { Row } from "./Row";

type Permission = {
  id: string;
  budget: string;
  organization: string;
  department: string;
  dictionaries: string[];
  subject: string;
};

interface Props {
  permission: Permission;
  onEditButtonClick: (id: string) => void;
  onRemoveButtonClick: (id: string) => void;
}

export class RowContainer extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return React.createElement(Row, {
      permission: this.props.permission,
      selectMenuItems: this.getSelectMenuItems(),
    });
  }

  private getSelectMenuItems() {
    const {
      permission: { id },
      onEditButtonClick,
      onRemoveButtonClick,
    } = this.props;

    return [
      {
        label: "Редактировать",
        onClick: () => onEditButtonClick(id),
      },
      {
        label: "Удалить",
        onClick: () => onRemoveButtonClick(id),
      },
    ];
  }
}
