import * as lodash from "lodash";

import { DepartmentAttributes } from "@sm/types/admin";
import {
  DepartmentRolePageState,
  UpdateSelectedDepartmentParams,
} from "../types";

export class DepartmentReducer {
  public static addDepartment(
    state: DepartmentRolePageState,
    newDepartment: DepartmentAttributes
  ) {
    const departments = lodash.some(
      state.departments,
      (department) => department.id == newDepartment.id
    )
      ? [...state.departments]
      : [...state.departments, newDepartment];
    return {
      ...state,
      departments,
    };
  }

  public static removeDepartment(
    state: DepartmentRolePageState,
    departmentId: string
  ) {
    const departments = state.departments.filter(
      (department) => department.id != departmentId
    );
    return {
      ...state,
      departments,
    };
  }

  public static setSelectedDepartment(
    state: DepartmentRolePageState,
    params: UpdateSelectedDepartmentParams
  ) {
    const selectedIndex = state.selectedDepartmentIds.indexOf(
      params.parentDepartmentId
    );
    const selectedDepartmentIds = state.selectedDepartmentIds.slice(
      0,
      selectedIndex == -1 ? 0 : selectedIndex + 1
    );
    selectedDepartmentIds.push(params.id);
    return {
      ...state,
      selectedDepartmentIds,
    };
  }

  public static addSubDepartment(
    state: DepartmentRolePageState,
    subDepartment: DepartmentAttributes
  ) {
    const departments = state.departments.map((department) =>
      department.id == subDepartment.parentDepartmentId
        ? {
            ...department,
            subDepartments: [...department.subDepartments, subDepartment],
          }
        : department
    );

    return {
      ...state,
      departments: [...departments, subDepartment],
    };
  }
}
