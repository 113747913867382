import * as React from "react";
import { DragSource, ConnectDragSource, ConnectDragPreview } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { ElementPreview } from "./ElementPreview";

interface Props extends DNDProps {
  itemType: string;
}

interface DNDProps {
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  isDragging?: boolean;
}

const boxSource = {
  beginDrag(props: Props) {
    return { type: props.itemType };
  },
};

@(DragSource(
  (props: Props) => props.itemType,
  boxSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })
) as any)
export class ElementPreviewContainer extends React.Component<Props> {
  public componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
  }

  public render() {
    const { itemType, connectDragSource, isDragging } = this.props;

    return React.createElement(ElementPreview, {
      itemType,
      connectDragSource,
      isDragging,
    });
  }
}
