// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__root{width:20px;height:20px;background:#EBEEEC;border-radius:4px;display:flex;justify-content:center;align-items:center;cursor:pointer;transition:background-color 75ms ease-out}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__root:hover{background-color:#1ABA4F}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__root:hover .src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__icon{color:#ffffff}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__root:active{background-color:#18ad48}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__icon{color:#979797}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/FormulasBuilder/Operation/OperationButtons/ButtonsGroup/styles.scss"],"names":[],"mappings":"AAAA,mHACI,UAAW,CACX,WAAY,CACZ,kBAAmB,CACnB,iBAAkB,CAElB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAEnB,cAAe,CAEf,yCAA0C,CAZ9C,yHAeQ,wBAAyB,CAfjC,4OAkBY,aAAc,CAlB1B,0HAuBQ,wBAAyB,CAC5B,mHAID,aAAc","sourcesContent":[".root {\n    width: 20px;\n    height: 20px;\n    background: #EBEEEC;\n    border-radius: 4px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n\n    transition: background-color 75ms ease-out;\n\n    &:hover {\n        background-color: #1ABA4F;\n\n        .icon {\n            color: #ffffff;\n        }\n    }\n\n    &:active {\n        background-color: #18ad48;\n    }\n}\n\n.icon {\n    color: #979797;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__root",
	"icon": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Operation-OperationButtons-ButtonsGroup-_styles__icon"
};
export default ___CSS_LOADER_EXPORT___;
