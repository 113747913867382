import * as React from "react";
import * as lodash from "lodash";

import * as style from "./InputCell.scss";

import { CellEditorWrapper } from "../CellEditorWrapper";

import {
  Input_redesign as Input,
  InputTheme_redesign as InputTheme,
  WithTooltip,
} from "sber-marketing-ui";

interface Props {
  title: string;
  placeholder: string;
  suggestItems?: string[];
  customStyle?: any;
  onValueChange: (value: string) => void;
}

export const InputCellEditTemplate = ({
  title,
  placeholder,
  suggestItems,
  customStyle,
  onValueChange,
}: Props): JSX.Element => {
  const [hoveredItem, setHoveredItem] = React.useState<string>(null);

  const onInputBlur = React.useCallback(
    (event, value) => {
      const newValue = hoveredItem !== null ? hoveredItem : (value as string);

      onValueChange(newValue);
    },
    [hoveredItem]
  );

  return (
    <div className={style.root} style={{ ...customStyle }}>
      <WithTooltip content={title} className={style.tooltipWrapper}>
        <Input
          value={title}
          placeholder={placeholder}
          theme={InputTheme.Borderless}
          onBlur={onInputBlur}
          autofocus
        />
      </WithTooltip>

      {!lodash.isEmpty(suggestItems) && (
        <CellEditorWrapper>
          <div className={style.content}>
            {suggestItems.map((item) => (
              <div
                key={item}
                className={style.item}
                onMouseEnter={() => setHoveredItem(item)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {item}
              </div>
            ))}
          </div>
        </CellEditorWrapper>
      )}
    </div>
  );
};
