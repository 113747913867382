import * as React from "react";

import * as style from "./FundsSelectCell.scss";

import { Icon, IconType } from "sber-marketing-ui";

interface Props {
  title: string;
  description?: string;
  customStyle?: any;
}

export const FundsSelectCellTemplate = ({
  title,
  description,
  customStyle,
}: Props): JSX.Element => {
  return (
    <div className={style.root} style={{ ...customStyle }}>
      <div className={style.title}>
        {title}

        <div className={style.currencySign}>₽</div>
      </div>

      {description && <div className={style.description}>{description}</div>}

      <div className={style.openerIcon}>
        <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
      </div>
    </div>
  );
};
