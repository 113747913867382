import { actionCreatorFactory } from "typescript-fsa";

import {
  BriefSchemeListPageLoadParams,
  BriefSchemeListPageSetFilterParams,
  UpdateSchemeParams,
} from "../types";

const actionCreator = actionCreatorFactory("BRIEF_SCHEME_LIST_PAGE");

const loadPageState = actionCreator<BriefSchemeListPageLoadParams>("LOAD");
const setBriefSchemeListFilter =
  actionCreator<BriefSchemeListPageSetFilterParams>("SET_FILTER");
const removeScheme = actionCreator<string>("REMOVE_SCHEME");
const updateScheme = actionCreator<UpdateSchemeParams>("UPDATE_SCHEME");

export { loadPageState, setBriefSchemeListFilter, removeScheme, updateScheme };
