import * as React from "react";
import * as styles from "./styles.scss";

import type { SearchInputProps } from "./SearchInput";
import type { ListProps, Item } from "./List";

import { useDropdown } from "./hooks";

export interface Props {
  label: string;
  items: Item[];
  disabled?: boolean;
  selectedItemId?: string;
  selectedItemParentId?: string;
  searchInputComponent: React.FC<SearchInputProps>;
  listItemsComponent: React.FC<ListProps>;
  onItemSelect?: (Item: Item) => void;
}

export const Dropdown: React.FC<Props> = ({
  label,
  items,
  selectedItemId,
  selectedItemParentId,
  disabled,
  searchInputComponent,
  listItemsComponent,
  onItemSelect,
}) => {
  const {
    ref,
    isActive,
    searchInputValue,
    listItems,
    onSearchInputChange,
    onSearchInputFocus,
    onListItemSelect,
  } = useDropdown({
    items,
    selectedItemId,
    selectedItemParentId,
    onItemSelect,
  });

  return (
    <div className={styles.root} ref={ref}>
      <div>
        {React.createElement(searchInputComponent, {
          value: searchInputValue,
          label,
          isActive,
          disabled,
          onChange: onSearchInputChange,
          onFocus: onSearchInputFocus,
        })}
      </div>

      {isActive && (
        <div className={styles.listWrapper}>
          {React.createElement(listItemsComponent, {
            items: listItems,
            onSelect: onListItemSelect,
          })}
        </div>
      )}
    </div>
  );
};

export type { SearchInputProps, ListProps };
