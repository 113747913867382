import * as React from "react";
import * as lodash from "lodash";
import autobind from "autobind-decorator";

import type { CellPosition, ColumnName, LineId } from "../../types";
import { CellEvent } from "../../types";

import { CursorTemplate } from "./CursorTemplate";

const enum Keycode {
  Enter = "Enter",
  Escape = "Escape",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
}

interface Props {
  position: CellPosition;
  columns: ColumnName[];
  allColumns: ColumnName[];
  readOnlyColumns: ColumnName[];
  lines: LineId[];
  getCellParams: (
    position: CellPosition,
    onCellParamsUpdateHandler: (cellProps: any) => void
  ) => {
    component: React.ClassType<any, any, any>;
    cellProps: any;
  };
  selectCell: (position: CellPosition) => void;
  onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

interface State {
  component: React.ClassType<any, any, any>;
  cellProps: any;
  editEnabled: boolean;
}

export class CursorBehaviour extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    const { component, cellProps } = props.getCellParams(
      props.position,
      this.onCellParamsUpdate
    );

    this.state = {
      component,
      cellProps,
      editEnabled: false,
    };
  }

  public componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  public render(): JSX.Element {
    const { editEnabled, component, cellProps } = this.state;

    return React.createElement(CursorTemplate, {
      editEnabled,
      component,
      cellProps,
      onCellEvent: this.onCellEvent,
      onMaskClick: this.onMaskClick,
    });
  }

  public getEditStatus(): boolean {
    return this.state.editEnabled;
  }

  public setEditStatus(status: boolean) {
    this.setState({
      editEnabled: status,
    });
  }

  @autobind
  public onMaskClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.disableEdit();
  }

  @autobind
  protected onCellParamsUpdate(cellParams: {
    component: React.ClassType<any, any, any>;
    cellProps: any;
  }) {
    const { component, cellProps } = cellParams;

    this.setState({
      component,
      cellProps,
    });
  }

  @autobind
  protected onCellEvent(eventType: CellEvent) {
    const { position } = this.props;

    switch (eventType) {
      case CellEvent.Click:
        this.onCellClick();
        break;
    }

    this.props.onCellEvent(eventType, position);
  }

  @autobind
  protected onCellClick() {
    this.enableEdit();
  }

  @autobind
  private onKeyDown(event: KeyboardEvent) {
    const keyCode = event.code;

    switch (keyCode) {
      case Keycode.Enter:
        event.stopPropagation();
        this.toggleEdit();
        break;

      case Keycode.Escape:
        event.stopPropagation();
        this.disableEdit();
        break;

      case Keycode.ArrowUp:
        event.stopPropagation();
        event.preventDefault();
        this.disableEdit();
        this.moveCursorUp();
        break;

      case Keycode.ArrowDown:
        event.stopPropagation();
        event.preventDefault();
        this.disableEdit();
        this.moveCursorDown();
        break;

      case Keycode.ArrowLeft:
        event.stopPropagation();
        event.preventDefault();
        this.disableEdit();
        this.moveCursorLeft();
        break;

      case Keycode.ArrowRight:
        event.stopPropagation();
        event.preventDefault();
        this.disableEdit();
        this.moveCursorRight();
        break;
    }
  }

  private moveCursorUp() {
    const { position, lines } = this.props;

    if (position) {
      const indexY = lines.indexOf(position.lineId);

      if (indexY > 0) {
        const newPosition = {
          lineId: lines[indexY - 1],
          columnName: position.columnName,
        };

        this.updateCellParams(newPosition);
        this.props.selectCell(newPosition);
      }
    }
  }

  private moveCursorDown() {
    const { position, lines } = this.props;

    if (position) {
      const indexY = lines.indexOf(position.lineId);

      if (indexY < lines.length - 1) {
        const newPosition = {
          lineId: lines[indexY + 1],
          columnName: position.columnName,
        };

        this.updateCellParams(newPosition);
        this.props.selectCell(newPosition);
      }
    }
  }

  private moveCursorLeft() {
    const { position, allColumns, readOnlyColumns } = this.props;

    if (position) {
      const { columnName, lineId } = position;

      const selectableColumns = lodash.without(allColumns, ...readOnlyColumns);

      const canMoveLeft = lodash.first(selectableColumns) !== columnName;

      if (canMoveLeft) {
        const indexX = selectableColumns.indexOf(columnName);

        const newPosition = {
          columnName: selectableColumns[indexX - 1],
          lineId,
        };

        this.updateCellParams(newPosition);
        this.props.selectCell(newPosition);
      }
    }
  }

  private moveCursorRight() {
    const { position, allColumns, readOnlyColumns } = this.props;

    if (position) {
      const { columnName, lineId } = position;

      const selectableColumns = lodash.without(allColumns, ...readOnlyColumns);

      const canMoveRight = lodash.last(selectableColumns) !== columnName;

      if (canMoveRight) {
        const indexX = allColumns.indexOf(columnName);

        const newPosition = {
          columnName: allColumns[indexX + 1],
          lineId,
        };

        this.updateCellParams(newPosition);
        this.props.selectCell(newPosition);
      }
    }
  }

  private updateCellParams(position: CellPosition) {
    const { component, cellProps } = this.props.getCellParams(
      position,
      this.onCellParamsUpdate
    );

    this.setState({
      component,
      cellProps,
    });
  }

  private toggleEdit() {
    if (this.state.editEnabled) {
      this.disableEdit();
    } else {
      this.enableEdit();
    }
  }

  private enableEdit() {
    const { position } = this.props;

    if (!this.state.editEnabled) {
      this.setState(
        {
          editEnabled: true,
        },
        () => {
          this.props.onCellEvent(CellEvent.EditStart, position);
        }
      );
    }
  }

  private disableEdit() {
    const { position } = this.props;

    if (this.state.editEnabled) {
      this.setState(
        {
          editEnabled: false,
        },
        () => {
          this.props.onCellEvent(CellEvent.EditEnd, position);
        }
      );
    }
  }
}
