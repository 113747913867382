// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__root{width:100%}.src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__menuTitle{user-select:none;letter-spacing:1px;text-transform:uppercase;color:#404040;font-family:'Open Sans';font-size:8px;line-height:12px}.src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__elementPreviews{margin-top:8px}.src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__elementPreview{margin-bottom:16px}.src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__elementPreview:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Menu/SchemeMenu/SchemeMenu.scss"],"names":[],"mappings":"AAAA,yDACI,UAAW,CACd,8DAGG,gBAAiB,CACjB,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,uBAAwB,CACxB,aAAc,CACd,gBAAiB,CACpB,oEAGG,cAAe,CAClB,mEAGG,kBAAmB,CADvB,8EAGQ,eAAgB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.menuTitle {\n    user-select: none;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n\n    color: #404040;\n\n    font-family: 'Open Sans';\n    font-size: 8px;\n    line-height: 12px;\n}\n\n.elementPreviews {\n    margin-top: 8px;\n}\n\n.elementPreview {\n    margin-bottom: 16px;\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__root",
	"menuTitle": "src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__menuTitle",
	"elementPreviews": "src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__elementPreviews",
	"elementPreview": "src-modules-BriefPage-Menu-SchemeMenu-_SchemeMenu__elementPreview"
};
export default ___CSS_LOADER_EXPORT___;
