import * as React from "react";

import { EditBudgetForm } from "./EditBudgetForm";

interface Props {
  canBePlanBudgetAdded: boolean;
  canBeExecutionBudgetAdded: boolean;
  addPlanBudget: () => void;
  addExecutionBudget: () => void;
}

export class EditBudgetFormContainer extends React.Component<Props> {
  public render() {
    const {
      canBePlanBudgetAdded,
      canBeExecutionBudgetAdded,
      addPlanBudget,
      addExecutionBudget,
    } = this.props;

    return React.createElement(EditBudgetForm, {
      canBePlanBudgetAdded,
      canBeExecutionBudgetAdded,
      addPlanBudget,
      addExecutionBudget,
    });
  }
}
