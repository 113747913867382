// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__root{width:20px;height:20px;display:flex;justify-content:center;align-items:center;cursor:pointer;transition:background-color 75ms ease-out}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__root:hover .src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__icon{color:#f50010}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__root:active{color:#c40009}.src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__icon{color:#979797}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/FormulasBuilder/Selector/ActionsSelector/ButtonsGroup/styles.scss"],"names":[],"mappings":"AAAA,iHACI,UAAW,CACX,WAAY,CAEZ,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAEnB,cAAe,CAEf,yCAA0C,CAV9C,wOAcY,aAAc,CAd1B,wHAmBQ,aAAc,CACjB,iHAID,aAAc","sourcesContent":[".root {\n    width: 20px;\n    height: 20px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n\n    transition: background-color 75ms ease-out;\n\n    &:hover {\n        .icon {\n            color: #f50010;\n        }\n    }\n\n    &:active {\n        color: #c40009;\n    }\n}\n\n.icon {\n    color: #979797;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__root",
	"icon": "src-modules-BriefPage-CalculationLogicPopup-FormulasBuilder-Selector-ActionsSelector-ButtonsGroup-_styles__icon"
};
export default ___CSS_LOADER_EXPORT___;
