import { actionCreatorFactory } from "typescript-fsa";

import { DepartmentRoleAttributes } from "@sm/types/admin";

import { UpdateRoleParams } from "../types";

const actionCreator = actionCreatorFactory("DEPARTMENT_ROLE_PAGE_ROLE");

const addRole = actionCreator<DepartmentRoleAttributes>("ADD_ROLE");
const setSelectedRole = actionCreator<number>("SET_SELECTED_ROLE");
const updateRole = actionCreator<UpdateRoleParams>("UPDATE_ROLE");
const setIsOnNewRole = actionCreator<boolean>("SET_ON_NEW_PRODUCT");
const updateRight = actionCreator<string[]>("UPDATE_RIGHT");

export { addRole, setSelectedRole, updateRole, setIsOnNewRole, updateRight };
