// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__root{height:24px;margin:12px 0;display:flex;flex-wrap:wrap;gap:10px}.src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__filterDescription{height:24px;padding:4px 9px 4px 11px;border:1.3px solid #417EC6;border-radius:30px;display:flex;align-items:center;gap:6px;font-family:'Open Sans';font-weight:400;font-size:12px;letter-spacing:0.1px;color:#000000}.src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__colorGrey,.src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__fitlerDescriptionCloseButton{color:#7E8681}.src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__fitlerDescriptionCloseButton{cursor:pointer;opacity:0.7}\n", "",{"version":3,"sources":["webpack://./src/modules/DictionariesPage/Table/FiltersDescription/FiltersDescription.scss"],"names":[],"mappings":"AAAA,iFACI,WAAY,CACZ,aAAc,CAEd,YAAa,CACb,cAAe,CACf,QAAS,CACZ,8FAGG,WAAY,CACZ,wBAAyB,CAEzB,0BAA2B,CAC3B,kBAAmB,CAEnB,YAAa,CACb,kBAAmB,CACnB,OAAQ,CAER,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,oBAAqB,CACrB,aAAc,CACjB,+LAGG,aAAc,CACjB,yGAKG,cAAe,CACf,WAAY","sourcesContent":[".root {\n    height: 24px;\n    margin: 12px 0;\n\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.filterDescription {\n    height: 24px;\n    padding: 4px 9px 4px 11px;\n\n    border: 1.3px solid #417EC6;\n    border-radius: 30px;\n\n    display: flex;\n    align-items: center;\n    gap: 6px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    letter-spacing: 0.1px;\n    color: #000000;\n}\n\n.colorGrey {\n    color: #7E8681;\n}\n\n.fitlerDescriptionCloseButton {\n    @extend .colorGrey;\n\n    cursor: pointer;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__root",
	"filterDescription": "src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__filterDescription",
	"colorGrey": "src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__colorGrey",
	"fitlerDescriptionCloseButton": "src-modules-DictionariesPage-Table-FiltersDescription-_FiltersDescription__fitlerDescriptionCloseButton"
};
export default ___CSS_LOADER_EXPORT___;
