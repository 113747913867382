import * as React from "react";
import { SelectItem } from "@sm/ui";
import { Dispatch, connect } from "react-redux";

import { StoreState } from "../../../store";
import {
  createSharing,
  getNonExistingSharingsAsSelectItems,
} from "../../../store/calendarSharing";

import { NewSharing } from "./NewSharing";

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
  selectItems: SelectItem[];
}

interface DispatchProps {
  onOrganizationSelect: (organizationId: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class NewSharingContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { selectItems, onOrganizationSelect } = this.props;

    return (
      <NewSharing
        selectItems={selectItems}
        onOrganizationSelect={onOrganizationSelect}
      />
    );
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    selectItems: getNonExistingSharingsAsSelectItems(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return {
    onOrganizationSelect: (organizationId: string) =>
      dispatch(createSharing(organizationId)),
  };
}
