import * as React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { JivoSiteCurrentPageUpdater } from "@sm/ui";

import { RouteContainer } from "./RouteContainer";
import { locations } from "./locations";
import { NotFound } from "../../notFound";
import { AuthErrorPage } from "../../AuthErrorPage";
import { JivoSiteApi } from "../../../api";

export function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Redirect exact from="/" to="/dictionaries" />

          <Route exact path="/auth-error" component={AuthErrorPage} />

          {locations.map((location) => (
            <RouteContainer
              exact
              key={location.url}
              path={location.url}
              component={location.Component}
              pageLabel={location.label}
              previousUrl={location.previousUrl}
              previousLabel={location.previousLabel}
            />
          ))}

          <Route component={NotFound} />
        </Switch>

        <JivoSiteCurrentPageUpdater appName={JivoSiteApi.appName} />
      </React.Fragment>
    </BrowserRouter>
  );
}
