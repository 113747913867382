import * as React from "react";

import * as style from "./UsersTable.scss";

import { UserCard } from "./UserCard";

interface Props {
  ids: number[];
}

export function UsersTable({ ids }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.cards}>
        {ids.map((id) => (
          <div className={style.card} key={id}>
            <UserCard id={id} />
          </div>
        ))}
      </div>
    </div>
  );
}
