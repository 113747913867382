import * as React from "react";
import { transformProps, SberHeader, SberHeaderProps } from "@sm/ui";

import * as style from "./Header.scss";
import { HeaderProps, HeaderData } from "./types";

const transformer = ({
  pageLabel,
  withoutBackButton,
  previousLabel,
  previousUrl,
  customFirstLine,
  additionalContent,
}: HeaderProps): SberHeaderProps => {
  const data: HeaderData = {
    pageLabel,
    withoutBackButton,
    previousLabel,
    previousUrl,
  };

  return {
    pageLabel,
    previousLabel,
    previousUrl,
    withoutBackButton,
    shouldRender: true,
    className: style.root,
    customFirstLine: customFirstLine && (customFirstLine(data) as any),
    additionalContent: additionalContent && (additionalContent(data) as any),
  };
};

/** Standard header for pages */
export const Header: React.ComponentType<HeaderProps> =
  transformProps(transformer)(SberHeader);
