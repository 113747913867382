import * as React from "react";

import * as style from "./TopLine.scss";

// import { Button_redesign, ButtonTheme_redesign } from '@sm/ui';
import { CustomFirstLine } from "../../common/CustomFirstLine";

interface Props {
  pageLabel: string;
}

export function TopLine({ pageLabel }: Props): JSX.Element {
  return (
    <CustomFirstLine
      pageLabel={pageLabel}
      additionalRightCssClass={style.buttonBlock}
    >
      {/* <div className={style.buttonWrapper}>
                <Button_redesign theme={ButtonTheme_redesign.Main} onClick={onNewActivityTypeButtonClick}>
                    Новый тип активности
                </Button_redesign>
            </div> */}
    </CustomFirstLine>
  );
}
