import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Dispatch, connect } from "react-redux";

import { StoreState } from "../../store";
import { LoadingStatus } from "../../store/utils";
import {
  SharingState,
  getPendingSharings,
  getSharingById,
  getNonExistingSharingsAsSelectItems,
  getCalendarSharingState,
  loadPageData,
  savePendingSharings,
  updateSharing,
  resetUnsavedSharings,
} from "../../store/calendarSharing";

import { PageProps } from "../common/Page";

import { EditCalendarSharingPage } from "./EditCalendarSharingPage";

const NEW_PAGE_MARKER = "new";

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends PageProps, RouteComponentProps<RouteProps> {}

interface RouteProps {
  id?: string;
}

interface MapProps {
  preloader: boolean;
  pendingSharings: SharingState[];
  isInEditMode: boolean;
  displayNewSharingCard: boolean;
}

interface DispatchProps {
  loadPageData: () => void;
  saveSharings: () => void;
  resetUnsavedSharings: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditCalendarSharingPageContainer extends React.PureComponent<Props> {
  public componentDidMount(): void {
    this.props.loadPageData();
  }

  public componentWillUnmount(): void {
    this.props.resetUnsavedSharings();
  }

  public render(): JSX.Element {
    const {
      preloader,
      previousLabel,
      previousUrl,
      isInEditMode,
      pendingSharings,
      displayNewSharingCard,
      saveSharings,
    } = this.props;

    return (
      <EditCalendarSharingPage
        preloader={preloader}
        previousLabel={previousLabel}
        previousUrl={previousUrl}
        isInEditMode={isInEditMode}
        pendingSharings={pendingSharings}
        displayNewSharingCard={displayNewSharingCard}
        onSaveButtonClick={saveSharings}
      />
    );
  }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
  const { id } = ownProps.match.params;
  const isInEditMode = id !== NEW_PAGE_MARKER;

  const { loadingStatus } = getCalendarSharingState(state);
  const pendingSharings = isInEditMode
    ? [getSharingById(state, id)]
    : getPendingSharings(state);

  return {
    preloader: ownProps.preloader || loadingStatus !== LoadingStatus.LOADED,
    pendingSharings,
    isInEditMode,
    displayNewSharingCard:
      !isInEditMode && !!getNonExistingSharingsAsSelectItems(state).length,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<StoreState>,
  ownProps: OwnProps
): DispatchProps {
  const { id } = ownProps.match.params;
  const isInEditMode = id !== NEW_PAGE_MARKER;

  return {
    loadPageData: () => dispatch(loadPageData(null)),
    saveSharings: () =>
      dispatch(isInEditMode ? updateSharing(id) : savePendingSharings(null)),
    resetUnsavedSharings: () => dispatch(resetUnsavedSharings()),
  };
}
