import * as React from "react";
import * as classnames from "classnames";

import * as style from "./DepartmentDetail.scss";

import type { DepartmentAttributes } from "@sm/types/admin";

import { Button, ButtonTheme, AutoFocusedInput, InputThemes } from "@sm/ui";
import { EditNameInput } from "../../EditNameInput";

interface Props {
  departmentId: string;
  department: DepartmentAttributes;
  selectedId: string;
  isNewSubDepartmentOpen: boolean;
  onNameChange: (name: string) => void;
  onAddSubDepartmentClick: () => void;
  onDepartmentSelect: (id: string, parentDepartmentId: string) => void;
  onNewDepartmentInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface SubDepartmentProps {
  id: string;
  parentDepartmentId: string;
  selectedId: string;
  name: string;
  onDepartmentSelect: (id: string, parentDepartmentId: string) => void;
}

export function DepartmentDetail({
  departmentId,
  department,
  selectedId,
  isNewSubDepartmentOpen,
  onNameChange,
  onAddSubDepartmentClick,
  onNewDepartmentInputBlur,
  onDepartmentSelect,
}: Props): JSX.Element {
  const { name, parentDepartmentId, subDepartments = [] } = department;

  const isSelected = selectedId == departmentId;

  return (
    <div className={style.root}>
      <div
        className={classnames(style.department, isSelected && style.selected)}
      >
        {isSelected ? (
          <div className={style.departmentName} title={name}>
            <EditNameInput value={name} onInputBlur={onNameChange} />
          </div>
        ) : (
          <div
            className={style.departmentName}
            title={name}
            onClick={() => onDepartmentSelect(departmentId, parentDepartmentId)}
          >
            {name}
          </div>
        )}
      </div>

      <div className={style.subDepartments}>
        {subDepartments.map((item) => (
          <SubNavigation
            key={item.id}
            id={item.id}
            name={item.name}
            selectedId={selectedId}
            parentDepartmentId={item.parentDepartmentId}
            onDepartmentSelect={onDepartmentSelect}
          />
        ))}
      </div>

      <div className={style.newDepartment}>
        {isNewSubDepartmentOpen ? (
          <AutoFocusedInput
            value={""}
            theme={InputThemes.Simple}
            onBlur={onNewDepartmentInputBlur}
          />
        ) : (
          <Button
            theme={ButtonTheme.PrimaryOutline}
            onClick={onAddSubDepartmentClick}
          >
            Добавить поддепартамент
          </Button>
        )}
      </div>
    </div>
  );
}

const SubNavigation = ({
  id,
  parentDepartmentId,
  selectedId,
  name,
  onDepartmentSelect,
}: SubDepartmentProps): JSX.Element => {
  return (
    <div
      className={classnames(
        style.subDepartmentName,
        selectedId == id && style.selected
      )}
      title={name}
      onClick={() => onDepartmentSelect(id, parentDepartmentId)}
    >
      {name}
    </div>
  );
};
