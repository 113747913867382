import * as React from "react";
import inlineSvgSprite from "inline-svg-sprite";
import * as classnames from "classnames";
import {
  CustomScrollbar,
  OpacityTransition,
  ResponsiveLayout,
  LeftSidebar,
  CentralContent,
  RightSidebar,
  PagePreloader,
} from "@sm/ui";
import { isFunction } from "lodash";

import "sber-marketing-ui/public/ui-components.css";
import "minireset.css/minireset.css";

import * as style from "./Page.scss";
import { Header, HeaderRenderProperty } from "../Header";
import { MainNavigation } from "../MainNavigation";

/** "Page" component base properties (without any render props) */
export interface PageBaseProps {
  preloader?: boolean;
  pageLabel?: string;
  withoutHeader?: boolean;
  withoutNavigation?: boolean;
  previousUrl?: string;
  previousLabel?: string;
  withoutBackButton?: boolean;
}

/** Render property signature for "Page" component */
export type PageRenderProperty = (baseProps?: PageBaseProps) => React.ReactNode;

/** "Page" component properties */
export interface PageProps extends PageBaseProps {
  customFirstLine?: HeaderRenderProperty;
  additionalContent?: HeaderRenderProperty;
  leftContent?: PageRenderProperty;
  rightContent?: PageRenderProperty;
  fixedLeftContent?: boolean;
  fixedRightContent?: boolean;
  customLayout?: boolean;
  children?: React.ReactNode;
  onPageScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

export function Page({
  children,
  pageLabel,
  withoutHeader,
  withoutNavigation,
  withoutBackButton,
  previousUrl,
  previousLabel,
  customFirstLine,
  additionalContent,
  leftContent,
  rightContent,
  fixedLeftContent = false,
  fixedRightContent = false,
  customLayout = false,
  preloader = false,
  onPageScroll,
}: PageProps): JSX.Element {
  const baseProps: PageBaseProps = {
    preloader,
    pageLabel,
    withoutHeader,
    withoutNavigation,
    withoutBackButton,
    previousLabel,
    previousUrl,
  };
  const doubleHeader = isFunction(additionalContent);
  const contentClassName = classnames(
    style.contentWrapper,
    doubleHeader && style.contentWrapper_doubleHeader,
    withoutHeader && style.contentWrapper_noHeader,
    preloader && style.contentWrapper_preloader
  );
  const scrollWrapperClassName = classnames(
    style.scrollWrapper,
    withoutNavigation && style.scrollWrapper_withoutNavigation
  );
  const scrollContentClassName = classnames(
    style.scrollContent,
    doubleHeader && style.scrollContent_doubleHeader,
    withoutHeader && style.scrollContent_noHeader
  );

  const hasLeftContent = isFunction(leftContent);
  const hasRightContent = isFunction(rightContent);

  return (
    <div className={style.root}>
      {!withoutHeader && (
        <Header
          pageLabel={pageLabel}
          withoutBackButton={withoutBackButton}
          previousLabel={previousLabel}
          previousUrl={previousUrl}
          customFirstLine={customFirstLine}
          additionalContent={additionalContent}
        />
      )}

      {!withoutNavigation && <MainNavigation />}

      <div className={contentClassName}>
        <div className={scrollWrapperClassName}>
          <CustomScrollbar
            hideTracksWhenNotNeeded
            renderView={(props) => (
              <div {...props} className={style.scrollView} id={"pageContent"} />
            )}
            onScroll={onPageScroll}
          >
            <OpacityTransition start={!preloader}>
              <div className={scrollContentClassName}>
                {customLayout ? (
                  children
                ) : (
                  <ResponsiveLayout>
                    {hasLeftContent && (
                      <LeftSidebar fixed={fixedLeftContent}>
                        {leftContent(baseProps)}
                      </LeftSidebar>
                    )}

                    <CentralContent>{children}</CentralContent>

                    {hasRightContent && (
                      <RightSidebar fixed={fixedRightContent}>
                        {rightContent(baseProps)}
                      </RightSidebar>
                    )}
                  </ResponsiveLayout>
                )}
              </div>
            </OpacityTransition>
          </CustomScrollbar>
        </div>

        {preloader && <PagePreloader />}
      </div>

      <div
        className={style.inlineIcons}
        dangerouslySetInnerHTML={{ __html: inlineSvgSprite }}
      />
    </div>
  );
}
