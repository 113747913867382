import { values } from "lodash";

import type { Trigger, TriggersHolder } from "./types";
import { OperationType, PresetFunction } from "./enums";
import type { SelectorParams, Item } from "./TriggerSelector";

export const buildSelectorsFromHolder = (
  holder: TriggersHolder,
  { onSelect }: { onSelect: (item: Item) => void }
): SelectorParams[] => {
  if (holder?.name?.length) {
    return [
      {
        label: "Поле брифа",
        disabled: true,
        selectedItemId: "custom_id",
        items: [
          {
            id: "custom_id",
            title: holder.name || "Не указано",
            description: "",
          },
        ],
        onItemSelect: onSelect,
      },
      {
        label: "Строка",
        disabled: false,
        selectedItemId: holder.selectedTriggerId,
        items:
          values(holder.triggers)?.map(({ id, name }) => ({
            id,
            title: name || "Имя отсутствует",
            text: "",
          })) || [],
        onItemSelect: onSelect,
      },
    ];
  }

  return [
    null,
    {
      label: "Поле",
      disabled: true,
      selectedItemId: holder.selectedTriggerId,
      items:
        values(holder.triggers)?.map(({ id, name }) => ({
          id,
          title: name || "Имя отсутствует",
          text: "",
        })) || [],
      onItemSelect: onSelect,
    },
  ];
};

export const getSelectedTriggerFromHolder = (
  holder: TriggersHolder,
  selectedId = ""
): Trigger => {
  return values(holder.triggers).find(({ id }) => id === selectedId);
};

export const operationByOperationType: Record<OperationType, PresetFunction> = {
  [OperationType.ADDITION]: PresetFunction.Add,
  [OperationType.SUBTRACTION]: PresetFunction.Sub,
  [OperationType.MULTIPLICATION]: PresetFunction.Mul,
  [OperationType.DIVISION]: PresetFunction.Div,
};

export const operationByPresetFunction: Record<PresetFunction, OperationType> =
  {
    [PresetFunction.Add]: OperationType.ADDITION,
    [PresetFunction.Sub]: OperationType.SUBTRACTION,
    [PresetFunction.Mul]: OperationType.MULTIPLICATION,
    [PresetFunction.Div]: OperationType.DIVISION,
  };
