import * as React from "react";

import * as style from "./UserInfo.scss";

import type { UserParams } from "@sm/types/admin";

import { Button, ButtonTheme, Heading } from "@sm/ui";

interface Props {
  user: UserParams;
  onCancelClick(): void;
}

export function UserInfo({ user, onCancelClick }: Props): JSX.Element {
  return (
    <div className={style.root}>
      <div className={style.title}>Профиль пользователя</div>

      <TitleInput title="Фамилия" value={user.secondName} />
      <TitleInput title="Имя" value={user.firstName} />
      <TitleInput title="Отчество" value={user.middleName} />

      <div className={style.separateLine} />

      <TitleInput title="Электронная почта" value={user.email} />
      <TitleInput title="Номер телефона" value={user.phoneNumber} />

      <div className={style.buttons}>
        <div className={style.button}>
          <Button theme={ButtonTheme.PrimaryOutline} onClick={onCancelClick}>
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  );
}

interface TitleInputProps {
  title: string;
  value: string;
}

function TitleInput({ title, value }: TitleInputProps): JSX.Element {
  return (
    value && (
      <div className={style.inputBlockWrapper}>
        <Heading title={title}>
          <div className={style.inputBlockValueWrapper}>{value}</div>
        </Heading>
      </div>
    )
  );
}
