import * as React from "react";
import * as lodash from "lodash";
import classnames from "classnames";

import * as style from "./RightGroupsCell.scss";

import type { Action, BudgetAccessGroup, Dictionary } from "../../../../../api";

import { RightGroupsEditor } from "./RightGroupsEditor";

interface Props {
  actions: Action[];
  dictionaries: Dictionary[];
  value: BudgetAccessGroup[];
  onValueChange: (value: BudgetAccessGroup[]) => void;
  onClose: () => void;
}

export const RightGroupsCellEditTemplate = ({
  actions,
  dictionaries,
  value,
  onValueChange,
  onClose,
}: Props): JSX.Element => {
  const dictionaryCount =
    lodash.sumBy(value, (item) => item?.dictionaryIds?.length) || 0;
  const actionsCount =
    lodash.sumBy(value, (item) => item?.actionIds?.length) || 0;

  return (
    <div className={style.root}>
      {(dictionaryCount !== 0 || actionsCount !== 0) && (
        <div className={style.counters}>
          <div className={classnames(style.counter, style.yellow)}>
            Справочники: {dictionaryCount}
          </div>

          <div className={classnames(style.counter, style.blue)}>
            Действия: {actionsCount}
          </div>
        </div>
      )}

      {dictionaryCount === 0 && actionsCount === 0 && (
        <div className={style.noValueMessage}>Не назначены</div>
      )}

      <RightGroupsEditor
        actions={actions}
        dictionaries={dictionaries}
        value={value}
        onValueChange={onValueChange}
        onClose={onClose}
      />
    </div>
  );
};
