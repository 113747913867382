// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__root{position:relative;width:100%;height:0;padding:0 8px}.src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__line{height:0;border-radius:1.5px;box-shadow:0 0 0 1px #dbdbdb}.src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__dropBox{position:absolute;z-index:1;top:-20px;left:0;width:100%;height:40px}.src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__hidden{display:none}.src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__hovered .src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__line{box-shadow:0 0 0 1px #19bb4f}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Schema/ElementsList/AddBlockTarget/AddBlockTarget.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB,CAElB,UAAW,CACX,QAAS,CACT,aAAc,CACjB,gFAGG,QAAS,CAET,mBAAoB,CACpB,4BAA6B,CAChC,mFAGG,iBAAkB,CAClB,SAAU,CACV,SAAU,CACV,MAAO,CAEP,UAAW,CACX,WAAY,CACf,kFAGG,YAAa,CAChB,mKAIO,4BAA6B","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    height: 0;\n    padding: 0 8px;\n}\n\n.line {\n    height: 0;\n\n    border-radius: 1.5px;\n    box-shadow: 0 0 0 1px #dbdbdb;\n}\n\n.dropBox {\n    position: absolute;\n    z-index: 1;\n    top: -20px;\n    left: 0;\n\n    width: 100%;\n    height: 40px;\n}\n\n.hidden {\n    display: none;\n}\n\n.hovered {\n    .line {\n        box-shadow: 0 0 0 1px #19bb4f;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__root",
	"line": "src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__line",
	"dropBox": "src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__dropBox",
	"hidden": "src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__hidden",
	"hovered": "src-modules-BriefPage-Schema-ElementsList-AddBlockTarget-_AddBlockTarget__hovered"
};
export default ___CSS_LOADER_EXPORT___;
