import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import autobind from "autobind-decorator";
import * as lodash from "lodash";

import type { BudgetLine } from "../../../api";
import type { StoreState } from "../../../store";
import { ColumnName, ColumnType } from "../../../store/budgetsPage/types";
import type {
  CellParams,
  CellPosition,
  ColumnHeaderParams,
  ColumnWidths,
  LineId,
} from "./types";

import { TableTemplate } from "./TableTemplate";
import { TableViewModel } from "./TableViewModel";
import { loadBudgets } from "../../../store/budgetsPage/actions";
import { getFilteredBudgets } from "../../../store/budgetsPage/selectors";
import {
  tableColumns,
  readOnlyColumns,
  ColumnsConfig,
  CellComponentsByColumnType,
} from "./ColumnsConfig";
import { MrmClient } from "../../../api";

const columnWidths: ColumnWidths = lodash.mapValues(
  ColumnsConfig,
  (item) => item.defaultWidth
);

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
  budgets: BudgetLine[];
}

interface DispatchProps {
  loadBudgets: (budgets: BudgetLine[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableBehaviour extends React.PureComponent<Props> {
  private viewModel: TableViewModel;

  public constructor(props: Props) {
    super(props);

    this.viewModel = new TableViewModel({
      makeColumnHeaderParams: this.makeColumnHeaderParams,
      makeCellParams: this.makeCellParams,
    });
  }

  public async componentDidUpdate(prevProps: Props) {
    const budgetsChanged = this.props.budgets !== prevProps.budgets;

    if (budgetsChanged) {
      this.updateSerialNumbers();
    }
  }

  public render(): JSX.Element {
    const { budgets } = this.props;

    return React.createElement(TableTemplate, {
      viewModel: this.viewModel,
      columns: tableColumns,
      readOnlyColumns,
      lineIds: budgets.map((item) => item.model.id),
      columnWidths,
    });
  }

  @autobind
  protected async onExecutionConfirm(cellPosition: CellPosition) {
    const { lineId } = cellPosition;

    await this.moveBudgetToExecution(lineId);
    await this.loadBudgets();
    this.updateLine(lineId);
  }

  @autobind
  protected async onArchiveConfirm(cellPosition: CellPosition) {
    const { lineId } = cellPosition;

    await this.moveBudgetToArchive(lineId);
    await this.loadBudgets();
    this.updateLine(lineId);
  }

  @autobind
  protected getCellValue(cellPosition: CellPosition) {
    const { budgets } = this.props;
    const { lineId, columnName } = cellPosition;

    const budget = budgets.find((item) => item.model.id === lineId);

    return ColumnsConfig[columnName].getValue(budget, budgets);
  }

  @autobind
  private makeColumnHeaderParams(columnName: string): ColumnHeaderParams {
    return {
      title: ColumnsConfig[columnName].title,
    };
  }

  @autobind
  private async updateLine(lineId: LineId) {
    tableColumns.forEach((columnName) => {
      const cellPosition = { lineId, columnName };

      this.updateCell(cellPosition);
    });
  }

  private updateCell(position: CellPosition) {
    this.viewModel.setCellParams(position, this.makeCellParams(position));
  }

  @autobind
  private makeCellParams(cellPosition: CellPosition): CellParams {
    return {
      component: this.getCellComponent(cellPosition),
      cellProps: this.makeCellProps(cellPosition),
    };
  }

  private getCellComponent(
    cellPosition: CellPosition
  ): React.ClassType<any, any, any> {
    const { columnName } = cellPosition;

    const columnType = ColumnsConfig[columnName].type;

    return CellComponentsByColumnType[columnType].cell;
  }

  private makeCellProps(cellPosition: CellPosition): any {
    const { columnName } = cellPosition;

    const columnType = ColumnsConfig[columnName].type;

    let cellProps: any;

    switch (columnType) {
      case ColumnType.Text:
        cellProps = this.makeTextCellProps(cellPosition);
        break;

      case ColumnType.Status:
        cellProps = this.makeStatusCellProps(cellPosition);
        break;

      case ColumnType.Access:
        cellProps = this.makeAccessCellProps(cellPosition);
        break;
    }

    return cellProps;
  }

  private makeTextCellProps(cellPosition: CellPosition): any {
    return {
      title: this.getCellValue(cellPosition) || "—",
    };
  }

  private makeStatusCellProps(cellPosition: CellPosition): any {
    const { budgets } = this.props;
    const { lineId } = cellPosition;

    const budget = budgets.find((item) => item.model.id === lineId);

    return {
      value: this.getCellValue(cellPosition),
      year: budget.model.year,
      onExecutionConfirm: () => this.onExecutionConfirm(cellPosition),
      onArchiveConfirm: () => this.onArchiveConfirm(cellPosition),
    };
  }

  private makeAccessCellProps(cellPosition: CellPosition): any {
    return {
      value: this.getCellValue(cellPosition),
    };
  }

  private updateSerialNumbers() {
    this.props.budgets.forEach((item) => {
      const position = {
        columnName: ColumnName.SerialNumber,
        lineId: item.model.id,
      };

      this.updateCell(position);
    });
  }

  private async moveBudgetToExecution(budgetId: string) {
    const budget = this.props.budgets.find(
      (item) => item.model.id === budgetId
    );

    await budget.model.toExecution();
  }

  private async moveBudgetToArchive(budgetId: string) {
    const budget = this.props.budgets.find(
      (item) => item.model.id === budgetId
    );

    await budget.model.toArchive();
  }

  private async loadBudgets() {
    const client = await MrmClient.getInstance();

    const budgets = await client.domain.budgets.listBudget();

    const sortedBudgets = lodash.sortBy(budgets, (item) => item.model.year);

    this.props.loadBudgets(sortedBudgets);
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    budgets: getFilteredBudgets(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
  return bindActionCreators(
    {
      loadBudgets,
    },
    dispatch
  );
}
