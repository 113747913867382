import { PureComponent, createElement } from "react";
import { AuthErrorPage, AuthErrorPageProps } from "@sm/ui";
import { parse } from "qs";
import { isNil } from "lodash";

import { AuthApi } from "../../api";
import { AuthErrorPageContainerProps } from "./types";

/** "AuthErrorPage" container component */
export class AuthErrorPageContainer extends PureComponent<AuthErrorPageContainerProps> {
  public render() {
    return createElement(AuthErrorPage, this.getRenderProps());
  }

  public async componentDidMount() {
    const { userFirstName, userSecondName, loadUser } = this.props;
    if (isNil(userFirstName) || isNil(userSecondName)) {
      try {
        const { user } = await AuthApi.getAuthorizedUser();
        loadUser(user);
      } catch (err) {}
    }
  }

  private getRenderProps(): AuthErrorPageProps {
    const { userFirstName, userSecondName } = this.props;
    return {
      userFirstName,
      userSecondName,
      type: this.getType(),
      onLogoutClick: AuthApi.logout,
    };
  }

  private getType(): "not-authorized" | "no-access" | "other" | undefined {
    const {
      location: { search },
    } = this.props;
    const query = parse(search.startsWith("?") ? search.substr(1) : search);
    return query.type;
  }
}
