import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "react-redux";
import * as lodash from "lodash";

import type { DepartmentUserAttributes } from "@sm/types/admin";

import { UsersTable } from "./UsersTable";
import type { StoreState } from "../../../store";
import { loadUsers } from "../../../store/departmentRolePage/actions";
import {
  getSelectedDepartmentId,
  getDepartmentUsers,
} from "../../../store/departmentRolePage/selector";
import { UserApi } from "../../../api";

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
  selectedDepartmentId: string;
  departmentUsers: DepartmentUserAttributes[];
}

interface DispatchProps {
  loadUsers: (params: DepartmentUserAttributes[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class UsersTableContainer extends React.Component<Props> {
  public async componentDidMount() {
    const departmentUsers = await UserApi.getUsers({
      departmentIds: `${this.props.selectedDepartmentId}`,
    });

    this.props.loadUsers(departmentUsers as DepartmentUserAttributes[]);
  }

  public render(): JSX.Element {
    return React.createElement(UsersTable, {
      ids: lodash.uniq(this.props.departmentUsers.map((item) => item.id)),
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  return {
    selectedDepartmentId: getSelectedDepartmentId(state),
    departmentUsers: getDepartmentUsers(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return {
    loadUsers: (params: DepartmentUserAttributes[]) =>
      dispatch(loadUsers(params)),
  };
}
