import type {
  BudgetAccess,
  Organization,
  Department,
  BudgetLine,
  Dictionary,
  BudgetAccessLine,
  RoleDomain,
  Action,
} from "../../api";

export interface PageState {
  pageData: PageData;
  filters: Filter[];
  sorting: SortingParams;
}

export interface PageData {
  budgetAccessLines: BudgetAccessLine[];
  organizations: Organization[];
  dictionaries: Dictionary[];
  departments: Department[];
  budgets: BudgetLine[];
  roles: RoleDomain[];
  actions: Action[];
}

export const enum ColumnName {
  Budget = "budget",
  Organization = "organization",
  Department = "department",
  Role = "role",
  RightGroup = "rightGroup",
  Actions = "actions",
}

export const enum ColumnHeaderType {
  Text = "text",
  Filters = "filters",
}

export const enum ColumnType {
  Text = "text",
  Select = "select",
  CheckboxList = "checkboxList",
  RightGroups = "rightGroups",
  Actions = "actions",
}

export interface Filter {
  columnName: ColumnName;
  selectedValues: React.ReactText[];
}

export interface SortingParams {
  columnName: ColumnName;
  orderType: OrderType;
}

export const enum OrderType {
  Asc = "asc",
  Desc = "desc",
}

export type { BudgetAccess };
