import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, Dispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import autobind from "autobind-decorator";

import { SelectItem } from "@sm/types/frontend";
import {
  EditFieldPropertiesParams,
  CalculationLogicPopupParams,
} from "../../../../../store/brief/types";

import { DropdownMenu } from "./DropdownMenu";
import { StoreState } from "../../../../../store";
import {
  editField,
  setCalculationLogicPopupParams,
} from "../../../../../store/brief/actions";
import { getFieldById } from "../../../../../store/brief/selector";

export interface Props extends MapProps, DispatchProps {
  id: string;
}

interface MapProps {
  name?: string;
  validationMessage?: string;
  tooltipMessage?: string;
  selectContent?: SelectItem[];
  isRequired?: boolean;
  isCalculated?: boolean;
  isSchemaActive?: boolean;
  isSchemaEditable?: boolean;
  isFormulaEditable?: boolean;
  duplicateToReport?: boolean;
  isDictionary?: boolean;
  dictionaryType?: string;
  isClientHide?: boolean;
}

interface DispatchProps {
  editField?: (params: EditFieldPropertiesParams) => void;
  setCalculationLogicPopupParams: (params: CalculationLogicPopupParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DropdownMenuContainer extends React.Component<Props> {
  public render() {
    const {
      name,
      validationMessage,
      tooltipMessage,
      selectContent = [],
      isRequired,
      isCalculated,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      duplicateToReport,
      isDictionary,
      dictionaryType,
      isClientHide,
    } = this.props;

    return React.createElement(DropdownMenu, {
      name,
      validationMessage,
      tooltipMessage,
      selectContent,
      isRequired,
      isCalculated,
      isSchemaActive,
      isSchemaEditable,
      isFormulaEditable,
      duplicateToReport,
      isDictionary,
      dictionaryType,
      isClientHide,
      onNameChange: this.onNameChange,
      onValidationMessageChange: this.onValidationMessageChange,
      onTooltipMessageChange: this.onTooltipMessageChange,
      onRequirementChange: this.onRequirementChange,
      onDuplicateToReportChange: this.onDuplicateToReportChange,
      onCalculatedChange: this.onCalculatedChange,
      onAddValue: this.addDropdownValue,
      onChangeValue: this.setDropdownItemName,
      onSumButtonClick: this.onSumButtonClick,
      onDictionaryChange: this.onDictionaryChange,
      onDictionaryTypeChange: this.onDictionaryTypeChange,
      onClientHideCheckboxChange: this.onClientHideCheckboxChange,
    });
  }

  @autobind
  protected onNameChange(value: string) {
    this.props.editField({
      id: this.props.id,
      name: value,
    });
  }

  @autobind
  protected onValidationMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      validationMessage: value,
    });
  }

  @autobind
  protected onTooltipMessageChange(value: string) {
    this.props.editField({
      id: this.props.id,
      tooltipMessage: (value || "").trim(),
    });
  }

  @autobind
  protected onRequirementChange() {
    this.props.editField({
      id: this.props.id,
      isRequired: !this.props.isRequired,
    });
  }

  @autobind
  protected onClientHideCheckboxChange() {
    this.props.editField({
      id: this.props.id,
      isClientHide: !this.props.isClientHide,
    });
  }

  @autobind
  protected onDuplicateToReportChange() {
    this.props.editField({
      id: this.props.id,
      duplicateToReport: !this.props.duplicateToReport,
    });
  }

  @autobind
  protected onCalculatedChange() {
    this.props.editField({
      id: this.props.id,
      isCalculated: !this.props.isCalculated,
    });
  }

  @autobind
  protected onSumButtonClick(params: { lineId: string }) {
    const { id } = this.props;

    this.props.setCalculationLogicPopupParams({
      visibility: true,
      selectedFieldId: id,
      selectedFieldLineId: params.lineId,
    });
  }

  @autobind
  protected onDictionaryChange() {
    this.props.editField({
      id: this.props.id,
      isDictionary: !this.props.isDictionary,
      dictionaryType: null,
    });
  }

  @autobind
  protected onDictionaryTypeChange(value: string) {
    this.props.editField({
      id: this.props.id,
      dictionaryType: value,
    });
  }

  @autobind
  protected addDropdownValue(value: string) {
    const { selectContent = [] } = this.props;

    const newContent = [
      ...selectContent,
      {
        id: uuid(),
        name: value,
      },
    ];

    this.props.editField({
      id: this.props.id,
      selectContent: newContent,
    });
  }

  @autobind
  protected setDropdownItemName(params: { value: string; id: string }) {
    const { selectContent = [] } = this.props;

    const newContent =
      params.value == ""
        ? selectContent.filter((item) => item.id != params.id)
        : selectContent.map((item) =>
            item.id == params.id
              ? {
                  ...item,
                  id: item.id,
                  name: params.value,
                }
              : item
          );

    this.props.editField({
      id: this.props.id,
      selectContent: newContent,
    });
  }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
  const field = getFieldById(state, ownProps.id);
  const isFormulaEditable = state.brief.isFormulaEditable;
  const isSchemaActive = state.brief.isSchemeActive;
  const isSchemaEditable = state.brief.isSchemeEditable;

  const {
    name,
    validationMessage,
    tooltipMessage,
    selectContent,
    isRequired,
    isCalculated,
    duplicateToReport,
    isDictionary,
    dictionaryType,
    isClientHide,
    parentFieldId,
  } = field.properties;

  return {
    name,
    validationMessage,
    tooltipMessage,
    selectContent,
    isRequired,
    isCalculated,
    isSchemaActive,
    isSchemaEditable: isSchemaEditable || (isSchemaActive && !parentFieldId),
    isFormulaEditable,
    duplicateToReport,
    isDictionary,
    dictionaryType,
    isClientHide,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
  return bindActionCreators(
    {
      editField,
      setCalculationLogicPopupParams,
    },
    dispatch
  );
}
