import * as React from "react";
import { NavLink } from "react-router-dom";
import { Popup, Shadow, IconType } from "@sm/ui";
import * as Dotdotdot from "react-dotdotdot";

import * as style from "./BriefSchemeCard.scss";

import { SelectMenu, SelectMenuItemParams } from "../../common/SelectMenu";
import { EditSchemeDepartment } from "../EditSchemeDepartment";
import { Utils } from "../../common/Utils";

interface Props {
  schemaId: string;
  briefName: string;
  status: string;
  departmentId: string;
  campaignsCount: number;
  isEditOpen: boolean;
  userDepartmentName: string;
  menuItems: SelectMenuItemParams[];
  toggleIsEditOpen: () => void;
  onDepartmentSelect: (id: string) => void;
}

const MAX_NAME_LENGTH = 3;

export function BriefSchemeCard({
  schemaId,
  briefName,
  menuItems,
  userDepartmentName,
  campaignsCount,
  isEditOpen,
  departmentId,
  toggleIsEditOpen,
  onDepartmentSelect,
}: Props): JSX.Element {
  const companyInRightDeclension = Utils.getDeclensionByNumber(campaignsCount, [
    "кампания",
    "кампании",
    "кампаний",
  ]);

  return (
    <div className={style.root}>
      {isEditOpen && (
        <Popup onOutOfContentClick={toggleIsEditOpen}>
          <EditSchemeDepartment
            onDepartmentSelect={onDepartmentSelect}
            onCancelClick={toggleIsEditOpen}
            departmentId={departmentId}
          />
        </Popup>
      )}

      <Shadow>
        <NavLink className={style.link} to={`/brief/scheme/${schemaId}`}>
          <div className={style.content}>
            <div className={style.topLine}>
              <div className={style.userDepartmentName}>
                {userDepartmentName}
              </div>
            </div>

            <div className={style.briefInfo}>
              <div className={style.briefName}>
                <Dotdotdot clamp={MAX_NAME_LENGTH}>{briefName}</Dotdotdot>
              </div>

              <div className={style.usersCount}>
                {campaignsCount} {companyInRightDeclension}
              </div>
            </div>
          </div>
        </NavLink>

        <div className={style.selectMenuWrapper}>
          <div className={style.selectMenu}>
            <SelectMenu
              items={menuItems}
              openerIcon={IconType.KEBAB_HORIZONTAL}
              contentClass={style.selectMenuContent}
            />
          </div>
        </div>
      </Shadow>
    </div>
  );
}
