import * as React from "react";
import autobind from "autobind-decorator";
import { connect } from "react-redux";

import { CardParams, ElementsOrder } from "./ElementsOrder";
import { StoreState } from "../../../store";
import {
  getFieldById,
  getSchemeListBlocks,
  getSubBlocksById,
  getSchemeFields,
  getSelectedBlockId,
  getSelectedElementId,
} from "../../../store/brief/selector";
import {
  BlockParams,
  FieldParams,
  FieldType,
} from "../../../store/brief/types";

export interface Props extends MapProps {}

type ElementType = BlockParams | FieldParams;

interface MapProps {
  selectedBlockId?: string;
  selectedElementId?: string;
  blocks?: BlockParams[];
  fields?: FieldParams[];
  elements?: ElementType[];
  subBlocks?: boolean;
  parent?: string;
}

interface State {
  cards: CardParams[];
}

@(connect(mapStateToProps) as any)
export class ElementsOrderContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cards: this.formatCards(
        this.props.elements,
        Boolean(this.props.selectedBlockId || this.props.selectedElementId)
      ),
    };
  }

  public componentWillReceiveProps(newProps: Props) {
    this.setState({
      cards: this.formatCards(
        newProps.elements,
        Boolean(newProps.selectedBlockId || newProps.selectedElementId)
      ),
    });
  }

  public render() {
    return React.createElement(ElementsOrder, {
      cards: this.state.cards,
      isBlockSelected:
        !this.props.selectedBlockId && !this.props.selectedElementId,
      parent: this.props.parent,
      moveCard: this.moveCard,
    });
  }

  @autobind
  protected moveCard(dragIndex: number, hoverIndex: number): void {
    const cards = [...this.state.cards];

    cards[dragIndex] = this.state.cards[hoverIndex];
    cards[hoverIndex] = this.state.cards[dragIndex];

    this.setState({
      cards,
    });
  }

  private formatCards(
    elements: ElementType[],
    elementSelected: boolean
  ): CardParams[] {
    return elements.map((item, index) => ({
      id: item.id,
      order: item.order,
      text: elementSelected
        ? item.properties.name || "Новое поле"
        : (<BlockParams>item).name || "Новый блок",
      isAtTheTop: index == 0,
      isAtTheBottom: index == elements.length - 1,
    }));
  }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
  const isSubBlocks = props.subBlocks;
  const selectedBlockId = getSelectedBlockId(state);
  const selectedElementId = getSelectedElementId(state);
  const blocks = selectedBlockId
    ? getSubBlocksById(state, selectedBlockId)
    : getSchemeListBlocks(state);
  const fields = getSchemeFields(state).filter(
    ({ type, properties = {} }) =>
      type !== FieldType.TOGGLE && !properties.switchPropertyId
  );

  let elements: ElementType[];

  if (isSubBlocks) {
    elements = selectedBlockId ? blocks : [];
  } else {
    if (!selectedBlockId && !selectedElementId) {
      elements = blocks;
    }

    if (selectedBlockId) {
      elements = fields.filter((item) => item.blockId === selectedBlockId);
    }

    if (selectedElementId) {
      const parentBlockId = getFieldById(state, selectedElementId).blockId;

      elements = fields.filter((item) => item.blockId === parentBlockId);
    }
  }

  return {
    selectedBlockId: isSubBlocks ? null : selectedBlockId,
    selectedElementId: isSubBlocks ? null : selectedElementId,
    parent: isSubBlocks ? selectedBlockId : null,
    blocks,
    fields,
    elements,
  };
}
