import { WorkTypeParams } from "@sm/types/admin";

import {
  WorkTypeState,
  WorkTypeLoadParams,
  AddDepartmentParams,
  RemoveDepartmentParams,
  UpdateWorkTypeParams,
  EnableRatesParams,
  DisableRatesParams,
} from "../types";

export class WorkTypeReducer {
  public static load(
    state: WorkTypeState,
    { workType, department }: WorkTypeLoadParams
  ) {
    return {
      ...state,
      workType,
      department,
    };
  }

  public static add(state: WorkTypeState, params: WorkTypeParams) {
    const workType = [params, ...state.workType];

    return {
      ...state,
      workType,
    };
  }

  public static remove(state: WorkTypeState, id: string) {
    const workType = state.workType.filter((workType) => workType.id != id);

    return {
      ...state,
      workType,
    };
  }

  public static update(
    state: WorkTypeState,
    { id, name }: UpdateWorkTypeParams
  ) {
    const workType = state.workType.map((workType) =>
      workType.id != id ? workType : { ...workType, name }
    );

    return {
      ...state,
      workType,
    };
  }

  public static addDepartment(
    state: WorkTypeState,
    params: AddDepartmentParams
  ) {
    const workType = state.workType.map((workType) =>
      workType.id == params.id
        ? {
            ...workType,
            departmentIds: [...workType.departmentIds, params.departmentId],
          }
        : workType
    );

    return {
      ...state,
      workType,
    };
  }

  public static removeDepartment(
    state: WorkTypeState,
    params: RemoveDepartmentParams
  ) {
    const workType = state.workType.map((workType) =>
      workType.id == params.workTypeId
        ? {
            ...workType,
            departmentIds: workType.departmentIds.filter(
              (id: string) => id !== params.departmentId
            ),
          }
        : workType
    );

    return {
      ...state,
      workType,
    };
  }

  public static setIsOnAdd(state: WorkTypeState, isOnAdd: boolean) {
    return {
      ...state,
      isOnAdd,
    };
  }

  public static enableRates(state: WorkTypeState, params: EnableRatesParams) {
    const workType = state.workType.map((workType) =>
      workType.id == params.workTypeId
        ? {
            ...workType,
            enable_rates: true,
          }
        : workType
    );

    return {
      ...state,
      workType,
    };
  }

  public static disableRates(state: WorkTypeState, params: EnableRatesParams) {
    const workType = state.workType.map((workType) =>
      workType.id == params.workTypeId
        ? {
            ...workType,
            enable_rates: false,
          }
        : workType
    );

    return {
      ...state,
      workType,
    };
  }
}
