import * as React from "react";

import * as style from "./Table.scss";

import type { ColumnName, ColumnWidths, LineId } from "./types";

import { TableViewModel } from "./TableViewModel";
import { TableView } from "./TableView";

interface Props {
  viewModel: TableViewModel;
  columns: ColumnName[];
  readOnlyColumns: ColumnName[];
  lineIds: LineId[];
  columnWidths: ColumnWidths;
}

export const TableTemplate = ({
  viewModel,
  columns,
  readOnlyColumns,
  lineIds,
  columnWidths,
}: Props): JSX.Element => {
  return (
    <div className={style.root}>
      <TableView
        viewModel={viewModel}
        columns={columns}
        readOnlyColumns={readOnlyColumns}
        lines={lineIds}
        columnWidths={columnWidths}
      />
    </div>
  );
};
