import autobind from "autobind-decorator";
import * as lodash from "lodash";
import * as moment from "moment";

import {
  CellPosition,
  TableHeaderCellParams,
  ColumnName,
  Filter,
  LineId,
} from "../../types";
import {
  ValueAccessor,
  AccessorParams,
  ColumnsConfig,
  LineType,
  ColumnHeaderType,
  CellType,
  ItemsAccessor,
} from "../../ColumnsConfig";
import type { CreativeRequestContract } from "@api";

import { TextColumnHeader, FiltersColumnHeader } from "../../ColumnHeaderTypes";

export const ColumnHeaderComponentsByType: Record<
  ColumnHeaderType,
  React.ClassType<any, any, any>
> = {
  [ColumnHeaderType.Text]: TextColumnHeader,
  [ColumnHeaderType.Filters]: FiltersColumnHeader,
};

interface Props {
  getLine: (lineId: LineId) => CreativeRequestContract;
  getLines: () => CreativeRequestContract[];
  getLeftFixedColumns: () => ColumnName[];
  setLeftFixedColumns: (fixedColumns: ColumnName[]) => void;
  getArchivedFilter: () => boolean;
  getFilters: () => Filter[];
  setFilters: (filters: Filter[]) => void;
}

export class ColumnHeaderFactory {
  private getLine: (lineId: LineId) => CreativeRequestContract;
  private getLines: () => CreativeRequestContract[];
  private getLeftFixedColumns: () => ColumnName[];
  private setLeftFixedColumns: (fixedColumns: ColumnName[]) => void;
  private getArchivedFilter: () => boolean;
  private getFilters: () => Filter[];
  private setFilters: (filters: Filter[]) => void;

  public constructor(props: Props) {
    this.getLine = props.getLine;
    this.getLines = props.getLines;
    this.getLeftFixedColumns = props.getLeftFixedColumns;
    this.setLeftFixedColumns = props.setLeftFixedColumns;
    this.getArchivedFilter = props.getArchivedFilter;
    this.getFilters = props.getFilters;
    this.setFilters = props.setFilters;
  }

  @autobind
  public async makeColumnHeaderParams(
    columnName: ColumnName
  ): Promise<TableHeaderCellParams> {
    return {
      component: this.getColumnHeaderComponent(columnName),
      cellProps: await this.makeColumnHeaderProps(columnName),
    };
  }

  private getColumnHeaderComponent(
    columnName: ColumnName
  ): React.ClassType<any, any, any> {
    const columnType = ColumnsConfig[columnName].headerType;

    return ColumnHeaderComponentsByType[columnType];
  }

  private async makeColumnHeaderProps(columnName: ColumnName): Promise<any> {
    const headerType = ColumnsConfig[columnName].headerType;

    let cellProps: any;

    switch (headerType) {
      case ColumnHeaderType.Text:
        cellProps = await this.makeTextColumnHeaderProps(
          columnName as ColumnName
        );
        break;

      case ColumnHeaderType.Filters:
        cellProps = await this.makeFiltersColumnHeaderProps(
          columnName as ColumnName
        );
        break;
    }

    return cellProps;
  }

  private async makeTextColumnHeaderProps(
    columnName: ColumnName
  ): Promise<any> {
    return {
      title: ColumnsConfig[columnName].title,
    };
  }

  private async makeFiltersColumnHeaderProps(
    columnName: ColumnName
  ): Promise<any> {
    return {
      title: ColumnsConfig[columnName].title,
      columnName,
      getFixedColumns: this.getLeftFixedColumns,
      setFixedColumns: this.setLeftFixedColumns,
      makeFilterItems: () => this.makeFilterItems(columnName),
      getFilters: this.getFilters,
      setFilters: this.setFilters,
    };
  }

  @autobind
  private async makeFilterItems(
    columnName: ColumnName
  ): Promise<Record<LineType, { id: string; title: string }[]>> {
    return {
      [LineType.Line]: await this.makeFilterItemsByLineType(
        columnName,
        LineType.Line
      ),
    };
  }

  @autobind
  private async makeFilterItemsByLineType(
    columnName: ColumnName,
    lineType: LineType
  ): Promise<
    {
      id: string;
      title: string;
    }[]
  > {
    const filtersAreDisabled = lodash.get(ColumnsConfig, [
      columnName,
      "disableFilters",
      lineType,
    ]);

    if (filtersAreDisabled) {
      return null;
    }

    const filteredLines = await this.makeFilteredLines(columnName, lineType);

    const columnValues = await Promise.all(
      filteredLines.map((line) =>
        this.getCellValue({ columnName, lineId: line.model.id })
      )
    );

    let selectedValues = lodash.uniqBy(lodash.flatten(columnValues), (item) =>
      moment.isDate(item) ? moment(item).valueOf() : item
    );

    const hasLinesWithoutValue = columnValues.some((value) =>
      lodash.isArray(value) ? lodash.isEmpty(value) : value === null
    );

    selectedValues = selectedValues.filter(
      (item) => item !== null && item !== ""
    );

    const cellsType = lodash.get(ColumnsConfig, [columnName, "type", lineType]);

    const filterItems = await Promise.all(
      selectedValues.map(async (value) => {
        let id: string = value;
        let title: string = value;

        if (cellsType === CellType.Datepicker) {
          id = value.valueOf();
          title = moment(value).format("DD.MM.YY");
        }

        if (
          cellsType === CellType.Select ||
          cellsType === CellType.FundsSelect
        ) {
          const itemsAccessorField = lodash.get(ColumnsConfig, [
            columnName,
            "getItems",
          ]);

          const itemsAccessor: ItemsAccessor = lodash.isFunction(
            itemsAccessorField
          )
            ? itemsAccessorField
            : itemsAccessorField[lineType];

          if (!itemsAccessor) {
            return undefined;
          }

          const possibleItems = await itemsAccessor({
            dictionariesByType: this.getDictionaries(),
          } as any);

          title =
            possibleItems
              .find((item) => item.value === value)
              ?.title.toString() || "Не найдено";
        }

        if (cellsType === CellType.FundsInput) {
          const roundedValue = this.roundNumber(value as number);

          id = roundedValue;
          title = `${this.formatCurrencyValue(roundedValue)} ₽`;
        }

        return {
          id,
          title,
        };
      })
    );

    const sortedItems =
      cellsType !== CellType.FundsInput
        ? lodash.sortBy(filterItems, (item) => item.title)
        : lodash.sortBy(filterItems, (item) => parseFloat(item.id));

    if (hasLinesWithoutValue) {
      sortedItems.unshift({
        id: null,
        title: "Значение не задано",
      });
    }

    return sortedItems;
  }

  private async makeFilteredLines(
    columnName: ColumnName,
    lineType: LineType
  ): Promise<{ model: { id: LineId; status: "active" | "archived" } }[]> {
    let filteredLines: {
      model: { id: LineId; status: "active" | "archived" };
    }[];

    filteredLines = this.getLines();

    const archivedFilterIsActive = this.getArchivedFilter();

    if (archivedFilterIsActive) {
      filteredLines = filteredLines.filter(
        (item) => item.model.status === "active"
      );
    }

    const filters = this.getFilters() || [];

    const filtersToApply = filters.some(
      (item) => item.columnName === columnName
    )
      ? filters.slice(
          0,
          filters.findIndex((item) => item.columnName === columnName)
        )
      : filters;

    for (const filter of filtersToApply) {
      const cellsType = lodash.get(ColumnsConfig, [
        filter.columnName,
        "type",
        lineType,
      ]);

      const selectedValues = filter.selectedValues;

      if (!lodash.isEmpty(selectedValues)) {
        const columnsValues = await Promise.all(
          filteredLines.map((line) =>
            this.getCellValue({
              columnName: filter.columnName,
              lineId: line.model.id,
            })
          )
        );

        filteredLines = filteredLines.filter((line, index) => {
          let value = columnsValues[index];

          if (cellsType === CellType.Datepicker && value !== null) {
            value = value.valueOf();
          }

          return lodash.isArray(value)
            ? selectedValues.some(
                (item) =>
                  value.includes(item) ||
                  (item === null && lodash.isEmpty(value))
              )
            : selectedValues.includes(value);
        });
      }
    }

    return filteredLines;
  }

  private getCellValue(cellPosition: CellPosition): Promise<any> {
    const { lineId, columnName } = cellPosition;

    const lineType = this.getLineType(lineId);

    const accessor: ValueAccessor = lodash.get(ColumnsConfig, [
      columnName,
      "getValue",
      lineType,
    ]);

    if (!accessor) {
      return undefined;
    }

    const params = this.makeAccessorParams(cellPosition);

    return accessor(params);
  }

  private getLineType(lineId: LineId): LineType {
    return LineType.Line;
  }

  private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
    const { lineId } = cellPosition;

    const line = this.getLine(lineId).model;

    return {
      id: lineId,
      line,
    };
  }

  private roundNumber(value: number, digitsAfterComma = 2): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split(".");

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ""}`;
  }

  private formatCurrencyValue(value: number | string): string {
    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = "";

    if (lodash.first(decimalPart) === "-") {
      decimalPart = decimalPart.substring(1);
      sign = "-";
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(" ");

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ""}`;
  }
}
