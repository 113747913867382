import { actionCreatorFactory } from "typescript-fsa";

import {
  DepartmentRolePageLoadParams,
  UpdateStateWithNewDepartmentParams,
  UpdateDepartmentNameParams,
} from "../types";

const actionCreator = actionCreatorFactory("DEPARTMENT_ROLE_PAGE");

const loadPageState = actionCreator<DepartmentRolePageLoadParams>("LOAD");
const updateStateWithNewDepartment =
  actionCreator<UpdateStateWithNewDepartmentParams>("UPDATE_STATE");
const setSelectedPage = actionCreator<string>("SET_SELECTED_PAGE");
const updateDepartmentName = actionCreator<UpdateDepartmentNameParams>(
  "UPDATE_DEPARTMENT_NAME"
);

export {
  loadPageState,
  updateStateWithNewDepartment,
  setSelectedPage,
  updateDepartmentName,
};
