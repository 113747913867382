import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import { DepartmentAttributes, WorkTypeParams } from "@sm/types/admin";
import { StoreState } from "../../";
import { WorkTypeState } from "../types";

import { Utils } from "../../../modules/common/Utils";

const getOrganizationTypeState = (state: StoreState): WorkTypeState =>
  state.workType;

export const getWorkTypes = createSelector(
  getOrganizationTypeState,
  (state: WorkTypeState): WorkTypeParams[] => {
    return state.workType;
  }
);

export const getWorkTypeIds = createSelector(
  getWorkTypes,
  (workTypes: WorkTypeParams[]): string[] => {
    return workTypes.map((workType) => workType.id);
  }
);

export const getWorkType = createCachedSelector(
  getWorkTypes,
  (state: StoreState, id: string): string => id,
  (workTypes: WorkTypeParams[], id: string): WorkTypeParams => {
    return Utils.getItemById(workTypes, id);
  }
)((state: StoreState, id: string): string => id);

export const getDepartments = createSelector(
  getOrganizationTypeState,
  (state: WorkTypeState): DepartmentAttributes[] => {
    return state.department;
  }
);

export const getIsOnAdd = createSelector(
  getOrganizationTypeState,
  (state: WorkTypeState): boolean => {
    return state.isOnAdd;
  }
);
