// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-ActivityTypePage-_ActivityTypePage__root{padding:40px 0}\n", "",{"version":3,"sources":["webpack://./src/modules/ActivityTypePage/ActivityTypePage.scss"],"names":[],"mappings":"AAAA,sDACI,cAAe","sourcesContent":[".root {\n    padding: 40px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-ActivityTypePage-_ActivityTypePage__root"
};
export default ___CSS_LOADER_EXPORT___;
