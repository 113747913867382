// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-CalculationLogicPopup-Footer-_styles__root{display:grid;justify-content:space-between;grid-template-columns:1fr 1fr;grid-column-gap:16px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/CalculationLogicPopup/Footer/styles.scss"],"names":[],"mappings":"AAAA,kEACI,YAAa,CAEb,6BAA8B,CAE9B,6BAA8B,CAC9B,oBAAqB","sourcesContent":[".root {\n    display: grid;\n\n    justify-content: space-between;\n\n    grid-template-columns: 1fr 1fr;\n    grid-column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-CalculationLogicPopup-Footer-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
