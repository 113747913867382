import { actionCreatorFactory } from "typescript-fsa";

import {
  TemplateEditPageLoadParams,
  Stage,
  ChangedStageOrder,
  StageTemplateName,
} from "../types";

const actionCreator = actionCreatorFactory("TEMPLATE_EDIT_PAGE");

const loadPageState = actionCreator<TemplateEditPageLoadParams>("LOAD");
const resetPageState = actionCreator("RESET_PAGE_STATE");
const resetCreatedStageName = actionCreator("RESET_CREATED_STAGE_NAME");
const addCreatedStage = actionCreator("ADD_CREATED_STAGE");
const setCreatedStageName = actionCreator<string>("SET_CREATED_STAGE_NAME");
const setName = actionCreator<StageTemplateName>("SET_NAME");
const setRequestError = actionCreator<boolean>("SET_REQUEST_ERROR");
const changeStageOrder = actionCreator<ChangedStageOrder>("CHANGE_STAGE_ORDER");
const editListStage = actionCreator<Stage>("EDIT_LIST_STAGE");
const removeListStage = actionCreator<number>("REMOVE_LIST_STAGE");
const checkCreatedStage = actionCreator("CHECK_CREATED_STAGE");

export {
  loadPageState,
  resetPageState,
  resetCreatedStageName,
  addCreatedStage,
  setName,
  setCreatedStageName,
  setRequestError,
  changeStageOrder,
  editListStage,
  removeListStage,
  checkCreatedStage,
};
