import * as React from "react";
import * as classnames from "classnames";
import * as Dotdotdot from "react-dotdotdot";
import { Icon, IconType, Popup } from "sber-marketing-ui";

import { PlainDictionary } from "@sm/types/frontend";
import { CardWithControls } from "../../common/CardWithControls";
import { AutosizeList } from "./AutosizeList";

import * as styles from "./SharingCard.scss";

export interface Props {
  showDeleteSharingPopup: boolean;
  showActivityTypesHover: boolean;
  showBlocksHover: boolean;
  title: string;
  availableBlocks: PlainDictionary[];
  selectedBlocks: string[];
  availableActivityTypes: PlainDictionary[];
  selectedActivityTypes: string[];
  onEditButtonClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onDeleteButtonClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  closePopup: () => void;
  deleteSharing: () => void;
  onActivityTypesIconMouseEnter: () => void;
  onActivityTypesIconMouseLeave: () => void;
  onBlocksIconMouseEnter: () => void;
  onBlocksIconMouseLeave: () => void;
}

export function SharingCard({
  showDeleteSharingPopup,
  showActivityTypesHover,
  showBlocksHover,
  title,
  availableBlocks,
  selectedBlocks,
  availableActivityTypes,
  selectedActivityTypes,
  onEditButtonClick,
  onDeleteButtonClick,
  closePopup,
  deleteSharing,
  ...iconsCalbacks
}: Props): JSX.Element {
  return (
    <React.Fragment>
      <CardWithControls
        title={title}
        leftControls={
          <LeftControls
            selectedBlocks={selectedBlocks}
            selectedActivityTypes={selectedActivityTypes}
            {...iconsCalbacks}
          />
        }
        rightControls={
          <RightControls
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
          />
        }
        hoveredContent={
          (showActivityTypesHover || showBlocksHover) && (
            <HoveredContent
              showActivityTypesHover={showActivityTypesHover}
              showBlocksHover={showBlocksHover}
              availableActivityTypes={availableActivityTypes}
              selectedActivityTypes={selectedActivityTypes}
              availableBlocks={availableBlocks}
              selectedBlocks={selectedBlocks}
            />
          )
        }
      />

      {showDeleteSharingPopup && (
        <Popup onOutOfContentClick={closePopup}>
          <div className={styles.popup}>
            Удалить привязку?
            <div className={styles.popupButtons}>
              <div className={styles.popupButton} onClick={deleteSharing}>
                Да
              </div>

              <div className={styles.popupButton} onClick={closePopup}>
                Нет
              </div>
            </div>
          </div>
        </Popup>
      )}
    </React.Fragment>
  );
}

type LeftControlsProps = Pick<
  Props,
  | "selectedActivityTypes"
  | "selectedBlocks"
  | "onActivityTypesIconMouseEnter"
  | "onActivityTypesIconMouseLeave"
  | "onBlocksIconMouseEnter"
  | "onBlocksIconMouseLeave"
>;

function LeftControls({
  selectedActivityTypes,
  selectedBlocks,
  onActivityTypesIconMouseEnter,
  onActivityTypesIconMouseLeave,
  onBlocksIconMouseEnter,
  onBlocksIconMouseLeave,
}: LeftControlsProps): JSX.Element {
  const blocksCount = selectedBlocks.length;
  const activityTypesCount = selectedActivityTypes.length;

  return (
    <React.Fragment>
      {!!blocksCount && (
        <div
          className={styles.control}
          onMouseEnter={onBlocksIconMouseEnter}
          onMouseLeave={onBlocksIconMouseLeave}
        >
          <Icon
            className={styles.controlIcon}
            type={IconType.BLOCK_ICON}
            svgSize={24}
          />

          {blocksCount}
        </div>
      )}

      {!!activityTypesCount && (
        <div
          className={styles.control}
          onMouseEnter={onActivityTypesIconMouseEnter}
          onMouseLeave={onActivityTypesIconMouseLeave}
        >
          <Icon
            className={styles.controlIcon}
            type={IconType.ACTIVITY_TYPE_ICON}
            svgSize={24}
          />

          {activityTypesCount}
        </div>
      )}
    </React.Fragment>
  );
}

type RightControlsProps = Pick<
  Props,
  "onEditButtonClick" | "onDeleteButtonClick"
>;

function RightControls({
  onEditButtonClick,
  onDeleteButtonClick,
}: RightControlsProps): JSX.Element {
  return (
    <React.Fragment>
      <div
        className={classnames(styles.button, styles.buttonEdit)}
        title="Редактировать"
        onClick={onEditButtonClick}
      >
        <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={24} />
      </div>

      <div
        className={classnames(styles.button, styles.buttonDelete)}
        title="Удалить"
        onClick={onDeleteButtonClick}
      >
        <Icon type={IconType.DELETE_V2} svgSize={24} />
      </div>
    </React.Fragment>
  );
}

type HoveredContentProps = Pick<
  Props,
  | "availableBlocks"
  | "selectedBlocks"
  | "showActivityTypesHover"
  | "availableActivityTypes"
  | "selectedActivityTypes"
  | "showBlocksHover"
>;

function HoveredContent({
  availableBlocks,
  selectedBlocks,
  availableActivityTypes,
  selectedActivityTypes,
  showActivityTypesHover,
  showBlocksHover,
}: HoveredContentProps): JSX.Element {
  let availableElements: {
    id: string;
    value: string;
  }[];
  let selectedElements: string[];
  let title: string;
  if (showActivityTypesHover) {
    availableElements = availableActivityTypes;
    selectedElements = selectedActivityTypes;
    title = "Доступ к проектам";
  } else if (showBlocksHover) {
    availableElements = availableBlocks;
    selectedElements = selectedBlocks;
    title = "Доступ к группам";
  }

  const elementsCount = selectedElements.length;
  const elementsToDisplay = selectedElements.map((id) =>
    availableElements.find((el) => el.id === id)
  );

  return (
    <div className={styles.hoveredContent}>
      <div className={styles.titleRow}>
        {title} ({elementsCount})
      </div>

      <AutosizeList items={elementsToDisplay} itemRenderer={AutosizeListItem} />
    </div>
  );
}

interface AutosizeListItemProps {
  id: string;
  text: string;
  ref?: React.RefObject<HTMLDivElement>;
}

function AutosizeListItem({
  id,
  text,
  ref,
}: AutosizeListItemProps): JSX.Element {
  return (
    <div className={styles.row} ref={ref} key={id}>
      <Dotdotdot clamp={2}>{text}</Dotdotdot>
    </div>
  );
}
