import * as url from "url";
import { AuthResponse, AuthorizationTimeLeftResponse } from "@sm/types/admin";

import { axios, AxiosResponse } from "../lib/axios";

const BASE_URL = "/api/auth";

export class AuthApi {
  public static async getAuthorizedUser(): Promise<AuthResponse> {
    const res: AxiosResponse<AuthResponse> = await axios.get(BASE_URL, {
      headers: {
        "Cache-Control": "no-cache, no-store, must-reinvalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });

    return res.data;
  }

  public static async getTimeLeft(): Promise<number> {
    const res: AxiosResponse<AuthorizationTimeLeftResponse> = await axios.get(
      `${BASE_URL}/time-left`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-reinvalidate",
          Pragma: "no-cache",
          Expires: 0,
        },
      }
    );

    return res.data.timeLeft;
  }

  public static logout(): void {
    window.location.href = `${BASE_URL}/logout`;
  }

  public static login(from: string): void {
    const { protocol, host } = url.parse(window.location.href);
    window.location.href = url.format({
      protocol,
      host,
      pathname: `${BASE_URL}/login`,
      query: {
        from: encodeURIComponent(from),
      },
    });
  }
}
