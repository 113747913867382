import { CellPosition } from "../../types";

import * as React from "react";
import autobind from "autobind-decorator";

interface Props {
  position: CellPosition;
  getCellParams: (
    position: CellPosition,
    onCellParamsUpdateHandler: (cellParams: {
      component: React.ClassType<any, any, any>;
      cellProps: any;
    }) => void
  ) => {
    component: React.ClassType<any, any, any>;
    cellProps: any;
  };
}

interface State {
  component: React.ClassType<any, any, any>;
  cellProps: any;
}

export class CellBehaviour extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      component: null,
      cellProps: null,
    };

    this.state = props.getCellParams(props.position, this.onCellParamsUpdate);
  }

  public render(): JSX.Element {
    const { component, cellProps } = this.state;

    if (!component) {
      return null;
    }

    return React.createElement(component, cellProps);
  }

  @autobind
  protected onCellParamsUpdate(cellParams: {
    component: React.ClassType<any, any, any>;
    cellProps: any;
  }) {
    const { component, cellProps } = cellParams;

    this.setState({
      component,
      cellProps,
    });
  }
}
