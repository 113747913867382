// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__root{width:100%}.src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__nameInput{width:100%;margin-bottom:15px}.src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__checkboxWithTooltip{position:relative;display:grid;grid-template-columns:auto max-content;grid-column-gap:10px}\n", "",{"version":3,"sources":["webpack://./src/modules/BriefPage/Menu/ElementMenu/BlockMenu/BlockMenu.scss"],"names":[],"mappings":"AAAA,mEACI,UAAW,CACd,wEAGG,UAAW,CACX,kBAAmB,CACtB,kFAGG,iBAAkB,CAElB,YAAa,CAEb,sCAAuC,CACvC,oBAAqB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.nameInput {\n    width: 100%;\n    margin-bottom: 15px;\n}\n\n.checkboxWithTooltip {\n    position: relative;\n\n    display: grid;\n\n    grid-template-columns: auto max-content;\n    grid-column-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__root",
	"nameInput": "src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__nameInput",
	"checkboxWithTooltip": "src-modules-BriefPage-Menu-ElementMenu-BlockMenu-_BlockMenu__checkboxWithTooltip"
};
export default ___CSS_LOADER_EXPORT___;
