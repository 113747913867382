import * as React from "react";
import classnames from "classnames";
import { uniqBy } from "lodash";
import {
  WithGlobalPopup,
  AbsoluteLikePositionCalculator,
  CheckboxGroupFilter,
  FilterMenu as GeneralFilterMenu,
  FilterItem as GeneralFilterItem,
} from "sber-marketing-ui";

import {
  TableRow,
  ColumnKey,
  ColumnDescriptor,
  ColumnWidths,
  TableFilters,
  TableOrdering,
} from "../common";

import * as styles from "./TableHeaderCell.scss";

interface Props<R extends TableRow, C extends ColumnKey> {
  rows: R[];
  column: C;
  columnDescriptor: ColumnDescriptor<R, C>;
  filters: TableFilters<C>;
  setFilters: (filters: TableFilters<C>) => void;
  ordering: TableOrdering<C>;
  setOrdering: (ordering: TableOrdering<C>) => void;
  columnWidths: ColumnWidths<C>;
  setColumnWidths: (columnWidths: ColumnWidths<C>) => void;
  getFilterItem: (row: R, column: C) => GeneralFilterItem;
  filterRows: (
    rows: R[],
    fitlers: TableFilters<C>,
    ordering: TableOrdering<C>
  ) => R[];
}

export function TableHeaderCell<R extends TableRow, C extends ColumnKey>(
  props: Props<R, C>
): JSX.Element {
  const { column, columnDescriptor, columnWidths, ordering } = props;

  const ref = React.useRef<HTMLDivElement>();
  const [isFilterOpened, setIsFilterOpened] = React.useState(false);

  const filterIsActive = ordering.column === column;

  const isClickable = !(
    columnDescriptor.skipOrdering && columnDescriptor.skipFilter
  );

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        isClickable && styles.rootClickable,
        filterIsActive && styles.rootActive
      )}
      style={{ width: `${columnWidths[column]}px` }}
      onClick={() => {
        if (isClickable) {
          setIsFilterOpened(true);
        }
      }}
    >
      {columnDescriptor.title}

      {isFilterOpened && (
        <WithGlobalPopup
          container={ref}
          positionCalculator={AbsoluteLikePositionCalculator}
          onMaskClick={() => setIsFilterOpened(false)}
        >
          <FilterMenu {...props} />
        </WithGlobalPopup>
      )}
    </div>
  );
}

function useFilterMenuItemsFilter<R extends TableRow, C extends ColumnKey>({
  column,
  rows,
  filters,
  setFilters,
  ordering,
  getFilterItem,
  filterRows,
}: Props<R, C>) {
  // skip current column filter for items generation
  // (combines multiple column filters together)
  const filtersForItemsGeneration: TableFilters<C> = {
    ...filters,
    [column]: new Set<string[]>(),
  };
  const rowsToGenerateFilters = filterRows(
    rows,
    filtersForItemsGeneration,
    ordering
  );
  const itemsFilterItems: GeneralFilterItem[] = uniqBy(
    rowsToGenerateFilters.map((row) => getFilterItem(row, column)),
    (item) => item.title
  );

  const itemsFilterCheckedItemIds = [...filters[column].values()];

  function onItemsFilterChange(items: string[]) {
    setFilters({
      ...filters,
      [column]: new Set(items),
    });
  }

  return {
    itemsFilterItems,
    itemsFilterCheckedItemIds,
    onItemsFilterChange,
  };
}

function useFilterMenuOrderingFilter<R extends TableRow, C extends ColumnKey>({
  column,
  ordering,
  setOrdering,
}: Props<R, C>) {
  const orderingCheckboxSelectedItem =
    ordering.column === column ? ordering.order : null;
  const orderingCheckboxItems: GeneralFilterItem[] = React.useMemo(
    () => [
      { id: "asc", title: "По возрастанию" },
      { id: "desc", title: "По убыванию" },
    ],
    []
  );

  function onOrderingChange(value: "asc" | "desc") {
    const order = value || ordering.order; // to prevent from unchecking

    setOrdering({
      column,
      order,
    });
  }

  return {
    orderingCheckboxSelectedItem,
    orderingCheckboxItems,
    onOrderingChange,
  };
}

function FilterMenu<R extends TableRow, C extends ColumnKey>(
  props: Props<R, C>
): JSX.Element {
  const { columnDescriptor } = props;
  const {
    orderingCheckboxSelectedItem,
    orderingCheckboxItems,
    onOrderingChange,
  } = useFilterMenuOrderingFilter(props);
  const { itemsFilterItems, itemsFilterCheckedItemIds, onItemsFilterChange } =
    useFilterMenuItemsFilter(props);

  return (
    <div className={styles.filterMenu}>
      <div className={styles.filterMenuTitle}>{columnDescriptor.title}</div>

      {!columnDescriptor.skipOrdering && (
        <React.Fragment>
          <div className={styles.filterMenuHorizontalLine} />

          <CheckboxGroupFilter
            title="Сортировка"
            items={orderingCheckboxItems}
            checkedItemId={orderingCheckboxSelectedItem}
            onItemSelection={onOrderingChange}
          />
        </React.Fragment>
      )}

      {!columnDescriptor.skipFilter && (
        <React.Fragment>
          <div className={styles.filterMenuHorizontalLine} />

          <div className={styles.filterMenuContent}>
            <GeneralFilterMenu
              isExpanded
              filterTitle="Выбранные значения"
              items={itemsFilterItems}
              checkedItemsIds={itemsFilterCheckedItemIds}
              onItemSelection={onItemsFilterChange}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
