import * as React from "react";
import classNames from "classnames";
import * as lodash from "lodash";

import * as style from "./FiltersColumnHeader.scss";

import type {
  ColumnName,
  Filter,
  // SortingParams
} from "../../types";

import {
  Icon,
  IconType,
  BodyPortal,
  FilterItem,
  WithTooltip,
} from "sber-marketing-ui";
import { ColumnHeaderEditorWrapper } from "../ColumnHeaderEditorWrapper";
// import { SortingMenu } from './SortingMenu';
import { ColumnFixButton } from "./ColumnFixButton";
import { Filters } from "./Filters";
import { LineType } from "../../ColumnsConfig";

interface Props {
  title: string;
  columnName: ColumnName;
  disableSorting?: boolean;
  getFixedColumns: () => ColumnName[];
  setFixedColumns: (fixedColumns: ColumnName[]) => void;
  // getSortingParams: () => SortingParams;
  // setTableSorting: (sortingParams: SortingParams) => void;
  makeFilterItems: () => Promise<Record<LineType, FilterItem[]>>;
  getFilters: () => Filter[];
  setFilters: (filters: Filter[]) => void;
}

export const FiltersColumnHeader = ({
  title,
  columnName,
  disableSorting,
  getFixedColumns,
  setFixedColumns,
  // getSortingParams,
  // setTableSorting,
  makeFilterItems,
  getFilters,
  setFilters,
}: Props): JSX.Element => {
  const [isOpened, setOpenedStatus] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState(
    {} as Record<LineType, FilterItem[]>
  );

  const filters = getFilters() || [];

  React.useEffect(() => {
    if (isOpened) {
      updateFilterItems();
    }
  }, [isOpened]);

  async function updateFilterItems() {
    const newFilterItems = await makeFilterItems();

    setFilterItems(newFilterItems);
  }

  const onOpenerClick = React.useCallback(() => {
    setOpenedStatus(!isOpened);
  }, [isOpened]);

  const onMaskClick = React.useCallback(() => {
    setOpenedStatus(false);
  }, []);

  const fixedColumns = getFixedColumns() || [];

  const isFixed = fixedColumns.includes(columnName);

  const onFixButtonClick = React.useCallback(() => {
    const updatedFixedColumns = lodash.xor(fixedColumns, [columnName]);

    setFixedColumns(updatedFixedColumns);
  }, [fixedColumns]);

  const isFiltered = lodash.some(
    getFilters(),
    (filter) => filter.columnName === columnName
  );

  return (
    <div
      className={classNames(
        style.root,
        isOpened && style.opened,
        isFiltered && style.filtered
      )}
    >
      <div className={style.opener} onClick={onOpenerClick}>
        <WithTooltip className={style.openerContent} content={title}>
          <>
            <div className={style.openerTitle}>{title}</div>

            <div className={style.openerIcon}>
              <Icon
                type={isOpened ? IconType.ARROW8_UP : IconType.ARROW8_DOWN}
              />
            </div>
          </>
        </WithTooltip>
      </div>

      {isOpened && (
        <ColumnHeaderEditorWrapper>
          <div className={style.content}>
            <div className={style.title}>{title}</div>

            <div className={style.fixButton}>
              <ColumnFixButton
                isColumnFixed={isFixed}
                onClick={onFixButtonClick}
              />
            </div>

            <div className={style.separator} />

            {/* {!disableSorting &&
                            <>
                                <SortingMenu
                                    columnName={columnName}
                                    getSortingParams={getSortingParams}
                                    setTableSorting={setTableSorting}
                                />

                                <div className={style.separator} />
                            </>
                        } */}

            {!lodash.isEmpty(filterItems[LineType.Line]) && (
              <div className={style.filters}>
                <Filters
                  columnName={columnName}
                  items={filterItems[LineType.Line]}
                  filters={filters}
                  setFilters={setFilters}
                  isExpanded={lodash.isEmpty(filterItems[LineType.Line])}
                />
              </div>
            )}
          </div>
        </ColumnHeaderEditorWrapper>
      )}

      {isOpened && (
        <BodyPortal>
          <div className={style.mask} onClick={onMaskClick} />
        </BodyPortal>
      )}
    </div>
  );
};
