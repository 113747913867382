import * as React from "react";

import { Icon, IconType } from "sber-marketing-ui";
import * as styles from "./styles.scss";

export interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const RemoveButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick} title={"Удалить"}>
      <Icon className={styles.icon} type={IconType.TRASH_CAN} svgSize={20} />
    </div>
  );
};
