import * as React from "react";

import * as style from "./NotFound.scss";
import { Page } from "../common/Page";

export function NotFound(): JSX.Element {
  return (
    <Page withoutHeader>
      <div className={style.root}>
        <span className={style.code}>404</span>
        <span className={style.message}>Страница не найдена.</span>
      </div>
    </Page>
  );
}
