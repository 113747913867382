import * as React from "react";

import * as style from "./EditUserForm.scss";

import type { UserParams, DepartmentUserAttributes } from "@sm/types/admin";

import { Button, ButtonTheme } from "@sm/ui";
import { EditUserRow } from "./EditUserRow";

interface Props {
  title: string;
  buttonLabel: string;
  userIds: number[];
  isOnEdit?: boolean;
  onChange: (userId: number) => void;
  onSave: () => Promise<void>;
  availableUsers: UserParams[];
  departmentUsers: DepartmentUserAttributes[];
  handleCheckboxClick: (
    userId: number,
    id: number,
    title: string,
    isChecked: boolean
  ) => void;
}

export function EditUserForm({
  title,
  buttonLabel,
  userIds,
  availableUsers,
  departmentUsers,
  handleCheckboxClick,
  isOnEdit,
  onChange,
  onSave,
}: Props): JSX.Element {
  const canAddUsers = !isOnEdit && availableUsers.length > 0;

  return (
    <div className={style.root}>
      <div className={style.title}>{title}</div>

      <div className={style.addUserRows}>
        {userIds.map((id) => (
          <div className={style.addUserRow}>
            <EditUserRow
              key={id}
              userId={id}
              onChange={onChange}
              availableUsers={availableUsers}
              departmentUsers={departmentUsers}
              handleCheckboxClick={handleCheckboxClick}
            />
          </div>
        ))}

        {canAddUsers && (
          <div className={style.addUserRow}>
            <EditUserRow
              userId={null}
              onChange={onChange}
              availableUsers={availableUsers}
              departmentUsers={departmentUsers}
              handleCheckboxClick={handleCheckboxClick}
            />
          </div>
        )}
      </div>

      <div className={style.buttons}>
        <div className={style.button}>
          <Button theme={ButtonTheme.PrimaryMain} onClick={onSave}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}
