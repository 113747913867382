import * as Organization from "@sm/types/admin";

import {
  OrganizationState,
  AddOrganizationParams,
  UpdateOrganizationParams,
} from "../types";

import { Utils } from "../../../modules/common/Utils";

export class OrganizationReducer {
  public static load(
    state: OrganizationState,
    organizations: Organization.OrganizationParams[]
  ) {
    return {
      ...state,
      organizations,
    };
  }

  public static loadCurrentOrganization(
    state: OrganizationState,
    currentOrganization: Organization.OrganizationParams
  ) {
    return {
      ...state,
      currentOrganization,
    };
  }

  public static addOrganization(
    state: OrganizationState,
    newOrganization: AddOrganizationParams
  ) {
    const organizations = [
      ...state.organizations,
      {
        ...newOrganization,
        userCount: 0,
        activityCount: 0,
      },
    ];

    return {
      ...state,
      organizations,
    };
  }

  public static updateOrganization(
    state: OrganizationState,
    { id, ...params }: UpdateOrganizationParams
  ) {
    const organizations = id
      ? Utils.updateItemById(state.organizations, id, params)
      : state.organizations;

    const currentOrganization =
      state.currentOrganization.id == id
        ? { ...state.currentOrganization, ...params }
        : state.currentOrganization;

    return {
      ...state,
      organizations,
      currentOrganization,
    };
  }

  public static updateCurrentOrganization(
    state: OrganizationState,
    params: UpdateOrganizationParams
  ) {
    const currentOrganization = { ...state.currentOrganization, ...params };

    return {
      ...state,
      currentOrganization,
    };
  }

  public static removeOrganization(
    state: OrganizationState,
    organizationId: string
  ) {
    const organizations = state.organizations.filter(
      (organization) => organization.id != organizationId
    );

    return {
      ...state,
      organizations,
    };
  }

  public static setCurrentOrganizationId(
    state: OrganizationState,
    currentOrganizationId: string
  ) {
    return {
      ...state,
      isFormOpen: true,
      currentOrganizationId,
    };
  }

  public static resetCurrentOrganization(state: OrganizationState) {
    return {
      ...state,
      currentOrganization: {} as Organization.OrganizationParams,
      isFormOpen: false,
      currentOrganizationId: null as string,
    };
  }
}
