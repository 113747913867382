import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import * as lodash from "lodash";

import { DepartmentAttributes } from "@sm/types/admin";
import { StoreState } from "../../";

import { DepartmentListPageState } from "../types";
import { Utils } from "../../../modules/common/Utils";

export const getDepartmentListPageState = (
  state: StoreState
): DepartmentListPageState => state.departmentListPage;

export const getDepartmentList = createSelector(
  getDepartmentListPageState,
  (state: DepartmentListPageState): DepartmentAttributes[] => {
    return state.departments;
  }
);

export const getSelectedFilter = createSelector(
  getDepartmentListPageState,
  (state: DepartmentListPageState): string => {
    return state.selectedFilter;
  }
);

export const getFilteredDepartmentList = createSelector(
  [getDepartmentList, getSelectedFilter],
  (
    departments: DepartmentAttributes[],
    selectedFilter: string
  ): DepartmentAttributes[] => {
    switch (selectedFilter) {
      case "date":
        return lodash.sortBy(departments, [
          function (department: DepartmentAttributes) {
            return department.createTime;
          },
        ]);
      case "usersCount":
        return lodash.sortBy(departments, [
          function (department: DepartmentAttributes) {
            return -department.userCount;
          },
        ]);
      default:
        return departments;
    }
  }
);

export const getRootDepartment = createSelector(
  getDepartmentList,
  (departments: DepartmentAttributes[]): DepartmentAttributes => {
    return lodash.find(
      departments,
      (department) => department.parentDepartmentId == null
    );
  }
);

export const getDepartment = createCachedSelector(
  getDepartmentList,
  (state: StoreState, id: string): string => id,
  (departments: DepartmentAttributes[], id: string): DepartmentAttributes => {
    return Utils.getItemById(departments, id);
  }
)((state: StoreState, id: string): string => id);

export const getSiblingDepartmentsNames = createCachedSelector(
  // get department names by parent id
  getDepartmentList,
  (state: StoreState, parentId: string, id: string): string => parentId,
  (state: StoreState, parentId: string, id: string): string => id,
  (
    departments: DepartmentAttributes[],
    parentId: string,
    id: string
  ): string[] => {
    const exactDepartment = departments.find(
      (department) => department.id === id
    );

    return (
      exactDepartment
        ? departments.filter(
            (department) =>
              department.parentDepartmentId === parentId && department.id !== id
          )
        : departments.filter(
            (department) => department.parentDepartmentId === parentId
          )
    ).map((department) => department.name);
  }
)(
  (state: StoreState, parentId: string, id: string): string =>
    `${parentId} ${id}`
);
