import * as React from "react";
import { v4 } from "uuid";
import { includes, parseInt, sortBy } from "lodash";

import { useOutsideElementClick } from "../hooks";
import { Item } from "./List";

interface UseDropdownParams {
  items: Item[];
  selectedItemId?: string;
  selectedItemParentId?: string;
  onItemSelect?: (item: Item) => void;
}

export const useDropdown = ({
  items,
  selectedItemId: initSelectedItemId,
  selectedItemParentId,
  onItemSelect = () => {},
}: UseDropdownParams) => {
  const [selectedItemId, setSelectItemId] = React.useState(
    initSelectedItemId || null
  );

  const onOutsideElementClick = React.useCallback(() => {
    setActive(false);
  }, []);

  const { ref } = useOutsideElementClick(onOutsideElementClick);
  const [isActive, setActive] = React.useState(false);
  const { searchInputValue, setSearchInputValue, onSearchInputChange } =
    useSearchInput();

  const onSearchInputFocus = React.useCallback(() => {
    setActive(true);
  }, []);

  function onListItemSelect(item: Item) {
    setSelectItemId(item.id);
    onItemSelect(item);
    setActive(false);
  }

  React.useEffect(() => {
    if (isActive) {
      setSearchInputValue("");
    } else {
      let value: string;

      if (selectedItemParentId) {
        const parentItem = items.find(
          (item) => item.id === selectedItemParentId
        );
        const childItem = parentItem?.meta?.childrenItems?.find(
          (childItem) => childItem.id === selectedItemId
        );
        value = `${parentItem?.title || ""} (${childItem?.title || ""})`;
      } else {
        value = items.find((item) => item.id === selectedItemId)?.title || "";
      }

      setSearchInputValue(value);
    }
  }, [isActive]);

  const listItems = React.useMemo(
    () => orderListItem(filterListItem(items, searchInputValue)),
    [items, searchInputValue]
  );

  return {
    ref,
    isActive,
    selectedItemId,
    searchInputValue,
    listItems,
    onSearchInputChange,
    onSearchInputFocus,
    onListItemSelect,
  };
};

export const useSearchInput = () => {
  const [searchInputValue, setSearchInputValue] = React.useState("");

  const onSearchInputChange = React.useCallback((value) => {
    setSearchInputValue(value);
  }, []);

  return {
    searchInputValue,
    setSearchInputValue,
    onSearchInputChange,
  };
};

const orderListItem = (items: Item[]): Item[] => {
  return sortBy(items, (item) => item?.meta?.order || 0);
};

const filterListItem = (items: Item[], value: string): Item[] => {
  const filteredListItem = items.filter((item) => includes(item.title, value));
  const onlyNumbers =
    Math.floor(
      parseFloat(typeof value === "string" ? value.replace(",", ".") : "") * 100
    ) / 100;

  if (onlyNumbers) {
    return [
      {
        id: v4(),
        title: String(onlyNumbers),
        description: "Выбирите числовое значение",
        meta: {
          type: "new_const",
          order: 4,
        },
      },
      ...filteredListItem,
    ];
  }

  return filteredListItem;
};
