import * as React from "react";
import { ConnectDropTarget } from "react-dnd";
import * as classNames from "classnames";

import * as style from "./ParentBlockTarget.scss";

interface Props {
  connectDropTarget: ConnectDropTarget;
  isHidden: boolean;
  isOver: boolean;
}

export function ParentBlockTarget({
  connectDropTarget,
  isHidden,
  isOver,
}: Props): JSX.Element {
  return (
    <div
      className={classNames(
        style.root,
        isHidden && style.hidden,
        isOver && style.hovered
      )}
    >
      <div className={style.line}>
        {connectDropTarget(<div className={style.dropBox} />)}
      </div>
    </div>
  );
}
