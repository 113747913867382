import * as React from "react";
import classNames from "classnames";

import { ActionsSelector, Action } from "./ActionsSelector";
import * as styles from "./styles.scss";

export interface Props {
  items: Item[];
  selectedItemIndex: number;
  onItemClick: (index: number) => void;
  onAddButtonClick: () => void;
  onRemoveButtonClick: () => void;
}

export interface Item {
  title: string;
}

export const Selector: React.FC<Props> = ({
  items,
  selectedItemIndex,
  onItemClick,
  onAddButtonClick,
  onRemoveButtonClick,
}) => {
  const onActionClick = React.useCallback(
    (action: Action) => {
      if (action === Action.REMOVE) {
        onRemoveButtonClick();
      }
    },
    [onRemoveButtonClick]
  );

  return (
    <div className={styles.root}>
      {items.map((item, index) => (
        <ActionsSelector
          key={item.title}
          onClick={onActionClick}
          disable={items.length === 1}
        >
          <div
            className={classNames(
              styles.item,
              index === selectedItemIndex && styles.selectedItem
            )}
            onClick={() => onItemClick(index)}
          >
            {item.title}
          </div>
        </ActionsSelector>
      ))}

      <div className={styles.item} onClick={onAddButtonClick}>
        +
      </div>
    </div>
  );
};
