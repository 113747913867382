import * as React from "react";

interface OnOutsideElementClick {
  (): void;
}

export const useOutsideElementClick = (
  onOutsideElementClick: OnOutsideElementClick
) => {
  const ref = React.useRef(null);

  const handlerClick: EventListener = React.useCallback(
    (event) => {
      const isClickOutsideElement = !ref?.current?.contains(event.target);

      if (isClickOutsideElement) {
        onOutsideElementClick();
      }
    },
    [ref, onOutsideElementClick]
  );

  React.useEffect(() => {
    document.addEventListener<"click">("click", handlerClick);
    return () => document.removeEventListener<"click">("click", handlerClick);
  }, []);

  return {
    ref,
  };
};
