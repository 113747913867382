import * as React from "react";

import * as style from "./EditNameInput.scss";

import { AutoFocusedInput, InputThemes, Icon, IconType } from "@sm/ui";

interface Props {
  value: string;
  isOnEdit: boolean;
  onEditClick: () => void;
  onChange: (value: string) => void;
  onBlur: () => void;
}

export function EditNameInput({
  value,
  isOnEdit,
  onEditClick,
  onChange,
  onBlur,
}: Props): JSX.Element {
  return (
    <div className={style.root}>
      {isOnEdit ? (
        <AutoFocusedInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          theme={InputThemes.Simple}
        />
      ) : (
        <>
          <div className={style.valueWrapper}>{value}</div>

          <div className={style.iconWrapper} onClick={onEditClick}>
            <Icon type={IconType.PENCIL} />
          </div>
        </>
      )}
    </div>
  );
}
