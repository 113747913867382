import * as React from "react";
import autobind from "autobind-decorator";

import { TopLine } from "./TopLine";

interface Props {
  canBeUsersAdded: boolean;
  pageLabel: string;
  canBePlanBudgetAdded: boolean;
  canBeExecutionBudgetAdded: boolean;
  addPlanBudget: () => void;
  addExecutionBudget: () => void;
}

interface State {
  isAddUserOpen: boolean;
  isAddBudgetOpen: boolean;
}

export class TopLineContainer extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isAddUserOpen: false,
      isAddBudgetOpen: false,
    };
  }

  public render(): JSX.Element {
    return React.createElement(TopLine, {
      pageLabel: this.props.pageLabel,
      canBeUsersAdded: this.props.canBeUsersAdded,
      isAddUserOpen: this.state.isAddUserOpen,
      isAddBudgetOpen: this.state.isAddBudgetOpen,
      onAddUserButtonClick: this.handleToggleIsAddUserOpen,
      onAddBudgetButtonClick: this.handleToggleIsAddBudgetOpen,
      canBePlanBudgetAdded: this.props.canBePlanBudgetAdded,
      canBeExecutionBudgetAdded: this.props.canBeExecutionBudgetAdded,
      addPlanBudget: this.handleAddPlanBudget,
      addExecutionBudget: this.handleAddExecutionBudget,
    });
  }

  @autobind
  protected handleToggleIsAddUserOpen() {
    this.setState(({ isAddUserOpen }) => ({ isAddUserOpen: !isAddUserOpen }));
  }

  @autobind
  protected handleToggleIsAddBudgetOpen() {
    this.setState(({ isAddBudgetOpen }) => ({
      isAddBudgetOpen: !isAddBudgetOpen,
    }));
  }

  @autobind
  protected handleAddPlanBudget() {
    this.props.addPlanBudget();
    this.setState({ isAddBudgetOpen: false });
  }

  @autobind
  protected handleAddExecutionBudget() {
    this.props.addExecutionBudget();
    this.setState({ isAddBudgetOpen: false });
  }
}
