import { omit, isNil } from "lodash";
import { DepartmentUserAttributes } from "@sm/types/admin";
import { DepartmentRolePageState, UpdateUserRoleParams } from "../types";

import { Utils } from "../../../modules/common/Utils";

export class UsersReducer {
  public static loadUsers(
    state: DepartmentRolePageState,
    departmentUsers: DepartmentUserAttributes[]
  ) {
    return {
      ...state,
      departmentUsers,
    };
  }

  public static updateUserRole(
    state: DepartmentRolePageState,
    { userId, roleId }: UpdateUserRoleParams
  ) {
    const roleName = Utils.getItemById(state.roles, roleId).name;
    const departmentUsers = state.departmentUsers.map((user) =>
      user.id == userId
        ? { ...user, role: { id: roleId, name: roleName } }
        : user
    );

    return {
      ...state,
      departmentUsers,
    };
  }

  public static loadDepartmentUsers(
    state: DepartmentRolePageState,
    departmentUsers: DepartmentUserAttributes[]
  ) {
    return { ...state, departmentUsers };
  }

  public static removeUser(
    { departmentUsers, availableUsers, ...rest }: DepartmentRolePageState,
    userId: number
  ): DepartmentRolePageState {
    const newAvaialableUser = departmentUsers.find(({ id }) => id === userId);
    return {
      ...rest,
      departmentUsers: departmentUsers.filter((user) => user.id != userId),
      availableUsers: isNil(newAvaialableUser)
        ? availableUsers
        : [...availableUsers, omit<any>(newAvaialableUser, "departmentId")],
    };
  }

  public static removeAvailableUser(
    { availableUsers, ...rest }: DepartmentRolePageState,
    userId: number
  ) {
    return {
      ...rest,
      availableUsers: availableUsers.filter(({ id }) => id !== userId),
    };
  }
}
