import * as React from "react";
import classNames from "classnames";
import * as moment from "moment";
import { Icon, IconType } from "sber-marketing-ui";
import { isEmpty, memoize } from "lodash";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import * as styles from "./FileAssetWithPreview.scss";

import {
  FileAssetWithPreviewTemplateProps,
  FileAssetColor,
  TypeMarkProps,
} from "./types";

const ICON_COLOR = "#fff";

const DOWNLOAD_SIZE = 23;

const REMOVE_SIZE = 6;

const PROGRESS_BAR_STROKE_WIDTH = 6;

const PROGRESS_BAR_STYLES = buildStyles({
  pathColor: "#19bb4f",
  trailColor: "#dfdfdf",
});

/** "FileAssetWithPreview" template component */
export const FileAssetWithPreviewTemplate: React.SFC<FileAssetWithPreviewTemplateProps> =
  ({
    id,
    className,
    canDelete,
    disableDownload,
    originName,
    createdAt,
    type,
    previewUrl,
    borderColor,
    removeBackgroundColor,
    removeOpacity,
    previewShadowOpacity,
    downloadFileShadowOpacity,
    downloadDisplay,
    downloadOpacity,
    isLoading,
    documentWasEditedInR7,
    onRemoveClick,
    onDownloadClick,
    onPreviewMouseOver,
    onPreviewMouseLeave,
    onRemoveMouseOver,
    onRemoveMouseLeave,
    onDownloadMouseOver,
    onDownloadMouseLeave,
    loadingProgress,
    size,
    nameColor,
    downloadFileShadowColor,
    microTypeMarkColor,
  }: FileAssetWithPreviewTemplateProps): JSX.Element => {
    return (
      <div
        className={classNames(styles.root, className)}
        title={getTitle(originName, createdAt, type)}
      >
        <div
          className={styles.preview}
          style={getPreviewStyle(borderColor, previewUrl)}
          onMouseOver={onPreviewMouseOver}
          onMouseLeave={onPreviewMouseLeave}
        >
          <div
            className={styles.previewShadow}
            style={getOpacityStyles(previewShadowOpacity)}
          />

          <TypeMark type={type} previewUrl={previewUrl} />

          {canDelete && (
            <div
              className={classNames(
                styles.buttonShadow,
                styles.buttonShadowDownloadFileNoGallery
              )}
              style={getButtonShadowStyles(
                downloadFileShadowOpacity,
                downloadFileShadowColor
              )}
            />
          )}

          {!isEmpty(previewUrl) && (
            <div
              className={styles.microTypeMark}
              style={getBackgroundColor(microTypeMarkColor)}
            >
              {formatType(type)}
            </div>
          )}

          {documentWasEditedInR7 && (
            <div className={styles.documentWasEditedInR7Mark}>Изм</div>
          )}
        </div>

        {isLoading && (
          <div className={styles.preloaderWrapper}>
            <CircularProgressbar
              className={styles.preloader}
              value={loadingProgress}
              strokeWidth={PROGRESS_BAR_STROKE_WIDTH}
              styles={PROGRESS_BAR_STYLES}
            />
          </div>
        )}

        <div
          className={styles.name}
          style={getNameStyle(nameColor)}
          onClick={disableDownload ? undefined : onDownloadClick}
        >
          {originName}
        </div>

        <div className={styles.type}>{formatSize(size)}</div>

        {!(disableDownload || isLoading) && (
          <div
            className={classNames(
              styles.downloadFile,
              styles.downloadFileNoGallery
            )}
            onClick={onDownloadClick}
            onMouseOver={onDownloadMouseOver}
            onMouseLeave={onDownloadMouseLeave}
            {...{
              "qa-id": "taskCommentFormAssetItemDownloadButton",
            }}
          >
            <Icon
              style={getIconStyles(downloadDisplay, downloadOpacity)}
              type={IconType.DOWNLOAD_FILE}
              color={ICON_COLOR}
              svgSize={DOWNLOAD_SIZE}
            />
          </div>
        )}

        {canDelete && (
          <div
            className={styles.removeButton}
            onClick={onRemoveClick}
            onMouseOver={onRemoveMouseOver}
            onMouseLeave={onRemoveMouseLeave}
            style={getRemoveStyle(removeBackgroundColor, removeOpacity)}
            {...{
              "qa-id": "fileAssetRemoveButton",
            }}
          >
            <Icon type={IconType.REMOVE_ASSET} svgSize={REMOVE_SIZE} />
          </div>
        )}
      </div>
    );
  };

FileAssetWithPreviewTemplate.displayName = "FileAssetWithPreviewTemplate";

const multiArgsResolver = (...args: any[]): string => args.join(",");

const getTitle = (
  originName: string,
  createdAt: number,
  type?: string
): string =>
  isEmpty(type)
    ? `${originName} ${moment(createdAt).format("DD MMM")}`
    : `${originName}.${type} ${moment(createdAt).format("DD MMM")}`;

const getOpacityStyles = memoize(
  (opacity: number): React.CSSProperties => ({ opacity })
);

const getButtonShadowStyles = memoize(
  (opacity: number, backgroundColor: FileAssetColor): React.CSSProperties => ({
    opacity,
    backgroundColor,
  }),
  multiArgsResolver
);

const calcIconStyles = (
  display: string,
  opacity: number
): React.CSSProperties => ({ display, opacity });

const getIconStyles = memoize(calcIconStyles, multiArgsResolver);

const calcRemoveStyle = (
  backgroundColor: FileAssetColor,
  opacity: number
): React.CSSProperties => ({
  backgroundColor,
  opacity,
});

const getBackgroundColor = memoize(
  (backgroundColor: FileAssetColor): React.CSSProperties => ({
    backgroundColor,
  })
);

const getRemoveStyle = memoize(calcRemoveStyle, multiArgsResolver);

const calcPreviewStyle = (
  borderColor: FileAssetColor,
  previewUrl?: string
): React.CSSProperties => {
  const result: React.CSSProperties = { borderColor };

  if (!isEmpty(previewUrl)) {
    result.backgroundImage = `url(${previewUrl})`;
  } else {
    result.backgroundColor = "#eeeeee";
  }

  return result;
};

const getPreviewStyle = memoize(calcPreviewStyle, multiArgsResolver);

const calcNameStyle = (color: FileAssetColor): React.CSSProperties => ({
  color,
});

const getNameStyle = memoize(calcNameStyle, multiArgsResolver);

const TypeMark: React.SFC<TypeMarkProps> = ({
  previewUrl,
  type,
}: TypeMarkProps): JSX.Element => (
  <React.Fragment>
    {isEmpty(previewUrl) && (
      <div
        className={classNames(
          styles.typeMark,
          type.length > 4 && styles.typeMarkBigExtension
        )}
      >
        {formatType(type)}
      </div>
    )}
  </React.Fragment>
);

const formatType = (type?: string): string =>
  isEmpty(type) ? "?" : type.toUpperCase();

const KILOBYTE = 1024;

const MEGABYTE = 1024 * 1024;

const GIGABYTE = 1024 * 1024 * 1024;

const formatSize = (size = 0): string => {
  let result = "";

  try {
    if (size >= GIGABYTE) {
      result = `${formatNumber((size - (size % GIGABYTE)) / GIGABYTE)} гб`;
    } else if (size >= MEGABYTE && size < GIGABYTE) {
      result = `${formatNumber((size - (size % MEGABYTE)) / MEGABYTE)} мб`;
    } else if (size >= KILOBYTE && size < MEGABYTE) {
      result = `${formatNumber((size - (size % KILOBYTE)) / KILOBYTE)} кб`;
    } else {
      result = `${formatNumber(size)} байт`;
    }
  } catch (e) {}

  return result;
};

const formatNumber = (value: number): string => {
  return Number.isInteger(value) ? String(value) : Number(value).toPrecision(1);
};
