import * as lodash from "lodash";

import {
  DictionaryType,
  ActivityDictionaryType,
  BudgetItemDictionaryType,
  CalculatorDictionaryType,
  MiscDictionaryType,
  CreativeRequestDictionaryType,
} from "@sm/types/frontend";

export const DictionaryTypes = {
  [ActivityDictionaryType.Block]: "Блок | системный",
  [ActivityDictionaryType.ActivityType]: "Тип проекта | системный | BPM",
  [ActivityDictionaryType.Product]: "Продукт | системный",
  [ActivityDictionaryType.CalendarGroup]: "Группа в календаре | системный",
  [ActivityDictionaryType.Division]: "МВЗ / Дивизион",
  [BudgetItemDictionaryType.FunctionalBlock]: "Функциональный блок",
  [BudgetItemDictionaryType.CostCenter]: "Наименование ЦЗ (центр затрат)",
  [BudgetItemDictionaryType.Channel]: "Канал",
  [BudgetItemDictionaryType.CostDirection]: "Направление затрат",
  [BudgetItemDictionaryType.LocationDriver]: "Драйвер аллокации (Бизнес-блок)",
  [BudgetItemDictionaryType.IFKV]: "ИФКВ",
  [BudgetItemDictionaryType.Objective]: "Задача",
  [ActivityDictionaryType.Regionality]: "ЦА/ТБ (территория)",
  ["regionality_type"]: "Тип ЦА/ТБ (территория)",
  ["regionality_usecase"]: "Юзкейс ЦА/ТБ (территория)",
  [ActivityDictionaryType.Territory]: "Территория",
  [BudgetItemDictionaryType.ResourceUsage]: "Порядок использования ресурсов",
  [ActivityDictionaryType.Segment]: "Сегмент",
  [BudgetItemDictionaryType.Subcategory]: "Подкатегория",
  [BudgetItemDictionaryType.Direction]: "Направление",
  [BudgetItemDictionaryType.Tool]: "Инструмент",
  [BudgetItemDictionaryType.Item]: "Статья",
  [BudgetItemDictionaryType.Resource]: "Ресурс",
  [CalculatorDictionaryType.CALC_GROUP]: "Группа | Калькулятор",
  [CalculatorDictionaryType.CALC_MATERIAL]: "Услуга/материал | Калькулятор",
  [CalculatorDictionaryType.CALC_MATERIAL_RANGE]:
    "Услуга/материал (Диапазон) | Калькулятор",
  [CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY]:
    "Услуга/материал (Соответствие) | Калькулятор",
  [CalculatorDictionaryType.CALC_MATERIAL_TOTAL]:
    "Услуга/материал (Итог) | Калькулятор",
  [ActivityDictionaryType.StageTemplate]: "Шаблон этапов | BPM",
  [ActivityDictionaryType.Stage]: "Этап | BPM",
  [ActivityDictionaryType.Task]: "Шаблон задачи | BPM",
  [MiscDictionaryType.Tag]: "Тег",
  [CreativeRequestDictionaryType.CreativeRequestTariff]: "Тарифы для ЗНК",
  [CreativeRequestDictionaryType.CommissionAmount]: "Размер комиссии",
  [CreativeRequestDictionaryType.TransferredRightsValidityTerritory]:
    "Территория действия прав",
  [CreativeRequestDictionaryType.TransferredRightsType]:
    "Вид передаваемых прав",
  [MiscDictionaryType.Agency]: "Агенство",
};

export const DictionaryFormTypes = lodash.omit(DictionaryTypes, [
  CalculatorDictionaryType.CALC_MATERIAL_RANGE,
  CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY,
  CalculatorDictionaryType.CALC_MATERIAL_TOTAL,
]);

export const CalcMaterialDictionaryTypes = {
  [CalculatorDictionaryType.CALC_MATERIAL]: "Стандартный",
  [CalculatorDictionaryType.CALC_MATERIAL_RANGE]: "Диапазон",
  [CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY]: "Соответствие",
  [CalculatorDictionaryType.CALC_MATERIAL_TOTAL]: "Итог",
};

export function dictionaryIsCalcMaterialDictionary(
  dictionary: DictionaryType
): boolean {
  return (
    dictionary === CalculatorDictionaryType.CALC_MATERIAL ||
    dictionary === CalculatorDictionaryType.CALC_MATERIAL_RANGE ||
    dictionary === CalculatorDictionaryType.CALC_MATERIAL_CONFORMITY ||
    dictionary === CalculatorDictionaryType.CALC_MATERIAL_TOTAL
  );
}
