import * as React from "react";
import autobind from "autobind-decorator";
import { Dispatch, connect } from "react-redux";

import { PlainDictionary } from "@sm/types/frontend";
import { StoreState } from "../../../store";
import {
  SharingState,
  getCalendarSharingState,
  deleteExistingSharing,
} from "../../../store/calendarSharing";

import { SharingCard } from "./SharingCard";

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends SharingState {}

interface MapProps {
  availableActivityTypes: PlainDictionary[];
  availableBlocks: PlainDictionary[];
}

interface DispatchProps {
  deleteSharing: () => void;
}

interface State {
  showDeleteSharingPopup: boolean;
  showActivityTypesHover: boolean;
  showBlocksHover: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SharingCardContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      showDeleteSharingPopup: false,
      showActivityTypesHover: false,
      showBlocksHover: false,
    };
  }

  public render(): JSX.Element {
    const {
      organization: { name },
      availableActivityTypes,
      availableBlocks,
      activityTypes: { pending: selectedActivityTypes },
      blocks: { pending: selectedBlocks },
      deleteSharing,
    } = this.props;
    const { showDeleteSharingPopup, showActivityTypesHover, showBlocksHover } =
      this.state;

    return (
      <SharingCard
        showDeleteSharingPopup={showDeleteSharingPopup}
        showActivityTypesHover={showActivityTypesHover}
        showBlocksHover={showBlocksHover}
        title={name}
        availableActivityTypes={availableActivityTypes}
        selectedActivityTypes={selectedActivityTypes}
        availableBlocks={availableBlocks}
        selectedBlocks={selectedBlocks}
        onEditButtonClick={this.onEditButtonClick}
        onDeleteButtonClick={this.onDeleteButtonClick}
        closePopup={this.closePopup}
        deleteSharing={deleteSharing}
        onActivityTypesIconMouseEnter={this.onActivityTypesIconMouseEnter}
        onActivityTypesIconMouseLeave={this.onActivityTypesIconMouseLeave}
        onBlocksIconMouseEnter={this.onBlocksIconMouseEnter}
        onBlocksIconMouseLeave={this.onBlocksIconMouseLeave}
      />
    );
  }

  @autobind
  private onEditButtonClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    window.location.href = `/calendar-sharing-edit/${this.props.organization.id}`;
  }

  @autobind
  private onDeleteButtonClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    this.setState({
      showDeleteSharingPopup: true,
    });
  }

  @autobind
  private closePopup(): void {
    this.setState({
      showDeleteSharingPopup: false,
    });
  }

  @autobind
  private onActivityTypesIconMouseEnter(): void {
    this.setState({
      showActivityTypesHover: true,
    });
  }

  @autobind
  private onActivityTypesIconMouseLeave(): void {
    this.setState({
      showActivityTypesHover: false,
    });
  }

  @autobind
  private onBlocksIconMouseEnter(): void {
    this.setState({
      showBlocksHover: true,
    });
  }

  @autobind
  private onBlocksIconMouseLeave(): void {
    this.setState({
      showBlocksHover: false,
    });
  }
}

function mapStateToProps(state: StoreState): MapProps {
  const { availableActivityTypes, availableBlocks } =
    getCalendarSharingState(state);

  return {
    availableActivityTypes,
    availableBlocks,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<StoreState>,
  ownProps: OwnProps
): DispatchProps {
  const organizationId = ownProps.organization.id;

  return {
    deleteSharing: () => dispatch(deleteExistingSharing(organizationId)),
  };
}
