import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import type { PlainDictionary } from "@sm/types/budget";

import type { ActivityTypePageState } from "../types";
import type { StoreState } from "../../";

import { Utils } from "../../../modules/common/Utils";

export const getActivityTypePageState = (
  state: StoreState
): ActivityTypePageState => state.activityType;
