import * as React from "react";
import * as lodash from "lodash";

import * as style from "./TableView.scss";

import type {
  CellPosition,
  ColumnHeaderParams,
  ColumnName,
  ColumnWidths,
  LineHeights,
  LineId,
} from "../types";
import { CellEvent } from "../types";

import {
  CustomScrollbar_new as CustomScrollbar,
  ScrollbarComponent,
} from "sber-marketing-ui";
import { Cursor } from "./Cursor";
import { Cell } from "./Cell";

export const TABLE_BODY_MAX_HEIGHT = 610;

interface Props {
  allColumns: ColumnName[];
  columns: ColumnName[];
  leftFixedColumns: ColumnName[];
  rightFixedColumns: ColumnName[];
  readOnlyColumns: ColumnName[];
  lines: LineId[];
  cursorPosition: CellPosition;
  visibleColumnsIndexes: number[];
  visibleLinesIndexes: number[];
  columnWidths: ColumnWidths;
  lineHeights: LineHeights;
  columnsSumWidth: number;
  leftFixedSumWidth: number;
  rightFixedSumWidth: number;
  sumHeight: number;
  viewportRef: React.RefObject<HTMLDivElement>;
  tableHeaderRef: (component: ScrollbarComponent) => void;
  tableBodyRef: (component: ScrollbarComponent) => void;
  leftFixedColumnsRef: (component: ScrollbarComponent) => void;
  rightFixedColumnsRef: (component: ScrollbarComponent) => void;
  cursorRef: (component: Cursor) => void;
  getColumnHeader: (columnName: string) => ColumnHeaderParams;
  getCellParams: (
    position: CellPosition,
    onCellParamsUpdateHandler: (cellProps: any) => void
  ) => {
    component: React.ClassType<any, any, any>;
    cellProps: any;
  };
  selectCell: (position: CellPosition) => void;
  onBodyScroll: () => void;
  onLeftFixedColumnsScroll: () => void;
  onRightFixedColumnsScroll: () => void;
  onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableViewTemplate = ({
  allColumns,
  columns,
  leftFixedColumns,
  rightFixedColumns,
  readOnlyColumns,
  lines,
  cursorPosition,
  visibleColumnsIndexes,
  visibleLinesIndexes,
  columnWidths,
  lineHeights,
  columnsSumWidth,
  leftFixedSumWidth,
  rightFixedSumWidth,
  sumHeight,
  viewportRef,
  tableHeaderRef,
  tableBodyRef,
  leftFixedColumnsRef,
  rightFixedColumnsRef,
  cursorRef,
  getColumnHeader,
  getCellParams,
  selectCell,
  onBodyScroll,
  onLeftFixedColumnsScroll,
  onRightFixedColumnsScroll,
  onCellEvent,
}: Props): JSX.Element => {
  return (
    <div className={style.root}>
      <div className={style.tableHeader}>
        {!lodash.isEmpty(leftFixedColumns) && (
          <div
            className={style.leftFixedColumns}
            style={{ width: leftFixedSumWidth }}
          >
            {renderFixedColumnHeaders(leftFixedColumns)}
          </div>
        )}

        <CustomScrollbar
          scrollbarRef={tableHeaderRef}
          hideScrollX
          hideScrollY
          freezeScrollY
        >
          <div
            className={style.headScroller}
            style={{ width: columnsSumWidth }}
          >
            {renderColumnHeaders()}
          </div>
        </CustomScrollbar>

        {!lodash.isEmpty(rightFixedColumns) && (
          <div
            className={style.rightFixedColumns}
            style={{ width: rightFixedSumWidth }}
          >
            {renderFixedColumnHeaders(rightFixedColumns)}
          </div>
        )}
      </div>

      <div className={style.tableBody}>
        {!lodash.isEmpty(leftFixedColumns) && (
          <div
            className={style.leftFixedColumns}
            style={{ width: leftFixedSumWidth }}
          >
            <CustomScrollbar
              scrollbarRef={leftFixedColumnsRef}
              maxHeight={TABLE_BODY_MAX_HEIGHT}
              onScroll={onLeftFixedColumnsScroll}
              freezeScrollX
              hideScrollX
              hideScrollY
            >
              <div style={{ height: sumHeight }}>
                {renderFixedColumnsCells(leftFixedColumns)}
                {renderCursor(cursorPosition, leftFixedColumns)}
              </div>
            </CustomScrollbar>
          </div>
        )}

        <div className={style.viewport} ref={viewportRef}>
          <CustomScrollbar
            scrollbarRef={tableBodyRef}
            maxHeight={TABLE_BODY_MAX_HEIGHT}
            hideScrollY={!lodash.isEmpty(rightFixedColumns)}
            onScroll={onBodyScroll}
          >
            <div
              className={style.bodyScroller}
              style={{ width: columnsSumWidth, height: sumHeight }}
            >
              {renderCells()}
              {renderCursor(cursorPosition, columns)}
            </div>
          </CustomScrollbar>
        </div>

        {!lodash.isEmpty(rightFixedColumns) && (
          <div
            className={style.rightFixedColumns}
            style={{ width: rightFixedSumWidth }}
          >
            <CustomScrollbar
              scrollbarRef={rightFixedColumnsRef}
              maxHeight={TABLE_BODY_MAX_HEIGHT}
              onScroll={onRightFixedColumnsScroll}
              freezeScrollX
              hideScrollX
            >
              <div style={{ height: sumHeight }}>
                {renderFixedColumnsCells(rightFixedColumns)}
                {renderCursor(cursorPosition, rightFixedColumns)}
              </div>
            </CustomScrollbar>
          </div>
        )}
      </div>
    </div>
  );

  function renderColumnHeaders(): JSX.Element[] {
    const columnHeaders: JSX.Element[] = [];
    let sum = 0;

    if (!lodash.isEmpty(visibleColumnsIndexes)) {
      columns.forEach((columnName, columnIndex) => {
        if (visibleColumnsIndexes.includes(columnIndex)) {
          columnHeaders.push(
            <div
              key={columnName}
              className={style.columnHeader}
              style={{ left: sum, width: columnWidths[columnName] }}
            >
              {React.createElement(
                getColumnHeader(columnName).component as any,
                getColumnHeader(columnName).columnHeaderProps
              )}
            </div>
          );
        }

        sum += columnWidths[columnName] + 1;
      });
    }

    return columnHeaders;
  }

  function renderFixedColumnHeaders(fixedColumns: ColumnName[]): JSX.Element[] {
    const columnHeaders: JSX.Element[] = [];
    let sum = 0;

    fixedColumns.forEach((columnName) => {
      columnHeaders.push(
        <div
          key={columnName}
          className={style.columnHeader}
          style={{ left: sum, width: columnWidths[columnName] }}
        >
          {React.createElement(
            getColumnHeader(columnName).component as any,
            getColumnHeader(columnName).columnHeaderProps
          )}
        </div>
      );

      sum += columnWidths[columnName] + 1;
    });

    return columnHeaders;
  }

  function renderCells(): JSX.Element[] {
    const cells: JSX.Element[] = [];
    let heightSum = 0;
    let widthSum = 0;

    if (
      !lodash.isEmpty(visibleLinesIndexes) &&
      !lodash.isEmpty(visibleColumnsIndexes)
    ) {
      lines.forEach((lineId, lineIndex) => {
        if (visibleLinesIndexes.includes(lineIndex)) {
          widthSum = 0;

          columns.forEach((columnName, columnIndex) => {
            const isReadOnly = readOnlyColumns.includes(columnName);

            if (visibleColumnsIndexes.includes(columnIndex)) {
              cells.push(
                <div
                  className={style.cell}
                  key={`${columnName} ${lineId}`}
                  style={{
                    top: heightSum,
                    left: widthSum,
                    width: columnWidths[columnName],
                    height: lineHeights[lineId],
                  }}
                  onClick={
                    !isReadOnly
                      ? () =>
                          onCellEvent(CellEvent.Click, { lineId, columnName })
                      : null
                  }
                >
                  {(!cursorPosition ||
                    !(
                      cursorPosition.columnName === columnName &&
                      cursorPosition.lineId === lineId
                    )) && (
                    <Cell
                      position={{ columnName, lineId }}
                      getCellParams={getCellParams}
                    />
                  )}
                </div>
              );
            }

            widthSum += columnWidths[columnName] + 1;
          });
        }

        const lineHeight = lineHeights[lineId] || 0;

        heightSum += lineHeight + 1;
      });
    }

    return cells;
  }

  function renderFixedColumnsCells(fixedColumns: ColumnName[]): JSX.Element[] {
    const fixedCells: JSX.Element[] = [];
    let heightSum = 0;
    let widthSum = 0;

    if (!lodash.isEmpty(visibleLinesIndexes)) {
      lines.forEach((lineId, lineIndex) => {
        if (visibleLinesIndexes.includes(lineIndex)) {
          widthSum = 0;

          fixedColumns.forEach((columnName) => {
            const isReadOnly = readOnlyColumns.includes(columnName);

            fixedCells.push(
              <div
                className={style.cell}
                key={`${columnName} ${lineId}`}
                style={{
                  top: heightSum,
                  left: widthSum,
                  width: columnWidths[columnName],
                  height: lineHeights[lineId],
                }}
                onClick={
                  !isReadOnly
                    ? () => onCellEvent(CellEvent.Click, { lineId, columnName })
                    : null
                }
              >
                {(!cursorPosition ||
                  !(
                    cursorPosition.columnName === columnName &&
                    cursorPosition.lineId === lineId
                  )) && (
                  <Cell
                    position={{ columnName, lineId }}
                    getCellParams={getCellParams}
                  />
                )}
              </div>
            );

            widthSum += columnWidths[columnName] + 1;
          });
        }

        const lineHeight = lineHeights[lineId] || 0;

        heightSum += lineHeight + 1;
      });
    }

    return fixedCells;
  }

  function renderCursor(
    position: CellPosition,
    columns: ColumnName[]
  ): JSX.Element {
    if (position === null || !columns.includes(position.columnName)) {
      return null;
    }

    const { columnName, lineId } = position;

    return (
      <div
        className={style.cursor}
        style={{
          top: getItemPosition(lineId, lines, lineHeights),
          left: getItemPosition(columnName, columns, columnWidths),
          width: columnWidths[columnName],
          height: lineHeights[lineId],
        }}
      >
        <Cursor
          ref={cursorRef}
          position={position}
          columns={columns}
          allColumns={allColumns}
          readOnlyColumns={readOnlyColumns}
          lines={lines}
          getCellParams={getCellParams}
          selectCell={selectCell}
          onCellEvent={onCellEvent}
        />
      </div>
    );
  }
};

function getItemPosition<T extends React.ReactText>(
  itemName: T,
  items: T[],
  itemsSize: Record<T, number>
): number {
  let sum = 0;
  let index = 0;

  while (items[index] !== itemName && index < items.length + 1) {
    sum += itemsSize[items[index]] + 1 || 0;
    index += 1;
  }

  return sum;
}
