import { axios, AxiosResponse } from "../lib/axios";

import {
  Budget,
  BudgetListQueryParams,
  CreateBudgetForm,
  ChangeBudgetStatusForm,
} from "@mrm/budget";

const BASE_URL = "/api/budget";

export class BudgetApi {
  public static async getBudget(budgetId: string): Promise<Budget> {
    const res: AxiosResponse<Budget> = await axios.get(
      `${BASE_URL}/${budgetId}`
    );

    return res.data;
  }

  public static async getBudgetList(
    params?: BudgetListQueryParams
  ): Promise<Budget[]> {
    const res: AxiosResponse<Budget[]> = await axios.get(BASE_URL, { params });

    return res.data;
  }

  public static async createBudget(params: CreateBudgetForm): Promise<void> {
    await axios.post(BASE_URL, params);
  }

  public static async changeBudgetStatus(
    params: ChangeBudgetStatusForm
  ): Promise<void> {
    await axios.post(`${BASE_URL}/change-status`, params);
  }

  public static async getAll(): Promise<Budget[]> {
    const res: AxiosResponse<Budget[]> = await axios.get(`${BASE_URL}/all`);

    return res.data;
  }
}
